//Se exporta un objeto que contiene las propiedades necesarias para poder utilizar filtros, sorter y paginación en las custom Tables
export const defaulStateTable = {
    active: {}, //Objeto actual del array de datos de la tabla
    dataTable: [], //Array de datos de la tabla
    metadata: { limit: 5, quantity: 0 }, //Metadatos del endpoint para poder hacer la paginación
    currentParams: { //Parámetros que se le pueden pasar al endpoint de datos que ocupa la tabla para hacer sorters y buscar datos por una columna
        find_by: undefined,
        find_value: undefined,
        sort_by: undefined,
        sort_order: undefined,
        skip: 0,
    },
    currentFilters: {}, //Filtros actuales de la tabla donde se guardan para hacer verificaciones
    currentPage: 1, //Página actual en la que se encuentra la tabla
    lastSkip: 0 //Indica los items que ya han sido recolectados del endpoint
}