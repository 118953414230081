import { Tabs } from "antd";
import React from "react";
import "./style.css";

const CustomTabs = ({
  onChange,
  items,
  defaultActiveKey = "1",
  tabBarExtraContent,
  className = 'custom-tabs',
  disabled,
}) => {
  const { TabPane } = Tabs;

  const getCustomTabTitle = (title) => {
    return <span className="tab-title">{title}</span>;
  };

  return (
    <Tabs    
      tabBarExtraContent={tabBarExtraContent}
      defaultActiveKey={defaultActiveKey}
      className={className}
      onChange={onChange}      
    >
      {items?.map((item, index) => (
        <TabPane
          tab={getCustomTabTitle(item?.label)}
          key={item?.key || String(index)}
          disabled={disabled}
        >
          {item?.children}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default CustomTabs;
