import { useState } from "react"
import { useBookingContext } from "../context/BookingsContext"
import useBookingsServices from "./useBookingsServices"
import { bookingDetailTest } from "../mocks/bookingDetail"

//Custom hook que se utiliza en la pantalla de Solicitud de reservas
const useBookingRequest = () => {
    const { handleRedirectTo, param, setLoading, loading } = useBookingContext()
    const { getBookingDetailService, updateApprovedStatusBookingService, updateAwaitingPaymentStatusBookingService, updateCancelStatusBookingService } = useBookingsServices()
    const [bookingDetailsData, setBookingDetailsData] = useState(structuredClone(bookingDetailTest))

    const [showModal, setShowModal] = useState({
        cotizationModal: false,
        confirmModal: false,
        rejectedModal: false,
        detailCotizationModal: false
    })

    //Función para poder abrir o cerrar los modales de crear cotización, para aprobar la reserva o para rechazar la reserva
    const handleOpenCloseModal = ({ name, status }) => {
        setShowModal(prev => ({ ...prev, [name]: status }))
    }

    //Permite mandar la información de un formulario, dependiendo de si es para una nueva cotización, para aprobar o cancelar una reserva, se define un servicio
    //a partir de un objeto y este se manda a llamar y se le pasan los valores del formulario ya validado
    const onSubmitForm = async ({ values, name }) => {
        const services = {
            'cotization': updateAwaitingPaymentStatusBookingService,
            'approve': updateApprovedStatusBookingService,
            'canceled': updateCancelStatusBookingService,
        }
        //Para todos los casos se le añadie el id de reserva
        values.reservation_id = bookingDetailsData.reservation_id;
        if (name === 'cotization') {
            //Si es el formulario de cotización se parsea la fecha del objeto moment
            values.payment_deadline = values.payment_deadline.clone().format('YYYY-MM-DD')
        }
        const res = await services[name]({ formData: values, setLoading })
        setLoading && setLoading(prev => ({
            ...prev,
            [`${res?.name}`]: false
        }))
        handleRedirectTo(-1)
    }

    const getInitialData = async () => {
        if (param?.subId) {
            const data = await getBookingDetailService({ reservation_id: param.subId, returnPath: '/bookings', setLoading })
            //data.EVENT.reservation_status_code = 'approve'
            setBookingDetailsData(data)
            setLoading && setLoading(prev => ({
                ...prev,
                requestPage: false
            }))
        }
    }

    return { handleOpenCloseModal, handleRedirectTo, getInitialData, showModal, bookingDetailsData, loading, onSubmitForm }
}

export default useBookingRequest