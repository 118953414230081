import { Col, Row, Button, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "../../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { setAnnouncements } from "../../../../store/announcement/thunks";
import { shape20 } from "../../../../assets/images";

export const CreateAnnouncement = ({ users }) => {
	const dispatch = useDispatch();
	const [from_user, setFrom_user] = useState("");
	const [about, setAbout] = useState("");
	const [description, setDescription] = useState("");
	const [userSearchList, setUserSearchList] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	/* const { about, description, from_user, onInputChange, onResetForm } = useForm(
		{
			about: "",
			description: "",
			from_user: "",
		}
	); */
	const { Option } = Select;

	useEffect(() => {
		let options = [];
		users.forEach((item, index) => {
			let fullname = item.name + item.lastname;

			options.push(
				<Option key={index} value={fullname} label={fullname}>
					{fullname}
				</Option>
			);
			return item;
		});
		setUserSearchList(options);
	}, [users]);

	/* useEffect(() => {
		setSelectUsers([...userSearchList]);
	}, [userSearchList]); */

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onHandleSubmit = () => {
		if (!description) {
			toast.warn("Debe ingresar una descripción");
			return;
		}
		if (!about) {
			toast.warn("Debe ingresar un asunto");
			return;
		}
		if (!from_user) {
			toast.warn("Debe ingresar un usuario");
			return;
		}

		let data = {
			description,
			about,
			from_user,
		};

		dispatch(setAnnouncements(data));
		setIsModalVisible(false);
		setAbout("");
		setDescription("");
	};

	const onSelectChange = (value) => {
		setFrom_user(value);
	};

	const onTitleChange = (e) => {
		setAbout(e.target.value);
	};

	const onDescriptionChange = (e) => {
		setDescription(e.target.value);
	};

	return (
		<>
			<Button className="btn-primary-radio" onClick={showModal}>
				<img src={shape20} alt="add-icon" />
				NUEVA CONVOCATORIA
			</Button>

			<Modal
				className="primary-modal"
				title="NUEVA CONVOCATORIA"
				visible={isModalVisible}
				onCancel={() => handleCancel()}
				footer={null}
			>
				<form action="">
					<div className="form-group">
						<label htmlFor="">Usuario</label>
						{/* <input
							className="form-control"
							name="from_user"
							value={from_user}
							onChange={onInputChange}
						/> */}
						<Select
							name="from_user"
							placeholder="Buscar por nombre"
							style={{
								width: "100%",
							}}
							allowClear
							showSearch
							filterOption={(input, option) => {
								return (
									option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
								);
							}}
							onChange={onSelectChange}
						>
							{userSearchList}
						</Select>
					</div>

					<div className="form-group">
						<label htmlFor="">Asunto</label>
						<input
							className="form-control"
							name="about"
							value={about}
							onChange={onTitleChange}
						/>
					</div>

					<div className="form-group">
						<label htmlFor="">Descripción</label>
						<TextArea
							className="form-control"
							rows={6}
							name="description"
							value={description}
							onChange={onDescriptionChange}
						/>
					</div>
				</form>

				<div className="footer-buttons">
					<Button
						// disabled={requests.isSaving}
						className="btn-primary"
						type="primary"
						shape="round"
						style={{ width: "150px" }}
						onClick={onHandleSubmit}
					>
						Guardar
					</Button>
				</div>
			</Modal>
		</>
	);
};
