import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
	Spin,
} from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import FormItem from "antd/lib/form/FormItem";
import { MiddleNewsPage } from "./components/MiddleNewsPage";
import { useNavigate } from "react-router-dom";
import { getNewsHelper, getAllNewsList } from "../../../helpers/newsHelper";
import moment from "moment";

import { useSelector } from "react-redux";
import { iconEditar, plusIconWhite } from "../../../assets/images";

const NewsPage = () => {
	const [allNews, setAllNews] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [newPublicationModalActive, setNewPublicationModalActive] =
		useState(false);

	const { committee } = useSelector((state) => state.auth);

	const { TextArea } = Input;

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};
	const navigate = useNavigate();

	useEffect(() => {
		const getNews = async () => {
			var fechaInicio = moment().subtract(1, 'months')
			var fechaFinal = moment();

			// const news = await getNewsHelper(
			// 	"Yd5MV7jkA0MjQeCmzKrZ",
			// 	fechaInicio.toDate(),
			// 	fechaFinal.toDate()
			// );
			// setAllNews(news)

			const response = await getAllNewsList(
				committee.residential_id,
				fechaInicio.format("YYYY-MM-DD"),
				fechaFinal.format("YYYY-MM-DD")
			);
			const { data: newsList } = response
			setAllNews(newsList);
			setIsLoading(false)
		};

		getNews().catch(console.error);
	}, []);

	const buscarFechas = async (fechaInicio, fechaFin) => {
		setIsLoading(true);
		const response = await getAllNewsList(
			committee.residential_id,
			fechaInicio,
			fechaFin
		);
		const { data: newsList } = response
		setAllNews(newsList)
		setIsLoading(false)
	};
	const IconButtonEdit = () => (
		<img
			style={{ width: "16px", height: "16px", marginLeft: "15px" }}
			src={iconEditar} alt="edit-icon"
		/>
	);

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={20}>
								<h2 className="title-page">Noticias y Eventos</h2>
							</Col>
							<Col span={4}>
								<Button
									style={{
										borderColor: "#10b48c",
										background: "#10b48c",
										width: "80%",
									}}
									onClick={() => {
										navigate("/crear-noticia");
									}}
									shape="round"
								>
									<img
										src={plusIconWhite}
										alt="white-plus-icon"
										style={{
											width: "26px",
											height: "26px",
											marginRight: "20px",
										}}
									/>
									<span
										className="title"
										style={{
											color: "#FFFFFF",
											fontSize: "13px",
											fontWeight: "500",
										}}
									>
										NUEVA NOTICIA
									</span>
								</Button>
							</Col>
						</Row>

						{/*<Row>
							<Col>
								<Space size={'middle'}>
									<Select
										placeholder="País"
										style={{ width: "261px" }}
										options={[
											{
												value: "sv",
												label: "El Salvador",
											},
											{
												value: "gt",
												label: "Guatemala",
											},
										]}
									></Select>
								</Space>
							</Col>
							<Col  style={{marginRight: '8px', marginLeft: '8px'}}>
								<Space size={'middle'}>
									<Select
										placeholder="Tipo de Cuenta"
										style={{ width: "261px" }}
										options={[
											{
												value: "residential",
												label: "Residenciales",
											},
											{
												value: "condominio",
												label: "Condominios",
											},
										]}
									></Select>
								</Space>
							</Col>
							<Col>
								<Space size={'middle'}>
									<Select
										placeholder="Todas las cuentas"
										style={{ width: "261px" }}
										options={[
										]}
									></Select>
								</Space>
							</Col>
						</Row>*/}

						{isLoading ? <Row justify="center"><Spin size="large" /></Row> : <MiddleNewsPage buscarFechas={buscarFechas} data={allNews} />}
					</div>
				</Content>
			</Layout>
		</>
	);
};

export default NewsPage;
