import { useEffect } from "react"
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import { Row, Col, Button, Form } from 'antd'
import { CustomSpin } from "../../../../UI/CustomSpin"
import BookingDetails from "./components/BookingDetails/BookingDetails"
import Specifications from "./components/Specifications"
import Mobiliarity from "./components/Mobiliarity"
import NewCotizationModal from "./components/modals/NewCotizationModal"
import useBookingRequest from "../../hooks/useBookingRequest"
import CustomModal from "../../../../UI/CustomModal"
import RejectedBookingModal from "./components/modals/RejectedBookingModal"
import CotizationDetailsModal from './components/modals/CotizationDetailsModal'
import CanceledReason from "./components/CanceledReason"
import './styles.css'


//Componente principal donde se muestran los detalles de la solicitud de la reserva
const BookingRequest = () => {
  const { getInitialData, handleOpenCloseModal, onSubmitForm, showModal, bookingDetailsData, loading } = useBookingRequest()

  const [form] = Form.useForm()

  useEffect(() => {
    getInitialData()
  }, []);

  if (loading?.requestPage) return <CustomSpin spinning={loading?.requestPage} />

  return (
    <section className="section-wrapper">
      <header>
        <CustomNavigate title={'SOLICITUD DE RESERVA'}>
          {/**Se muestra el botón segun sea el estado de la reserva, que no sea en espera de envío de cotización o cancelada */}
          {
            bookingDetailsData?.EVENT?.reservation_status_code !== 'awaitingConfirmation' && bookingDetailsData?.EVENT?.reservation_status_code !== 'canceled' ? <Button onClick={() => handleOpenCloseModal({ name: 'detailCotizationModal', status: true })} className="btn-standard-white" shape="round">DETALLES DE LA COTIZACIÓN</Button> : <></>
          }
        </CustomNavigate>
      </header>
      <main style={{ marginTop: 32 }}>
        <Row gutter={48}>
          <Col span={12}>
            <BookingDetails data={{ event: bookingDetailsData?.EVENT, profile: bookingDetailsData?.PROFILE }} />
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column', gap: '24px' }} span={12}>
            <Specifications specificationsData={bookingDetailsData?.SPECIFICATIONS} />
            <Mobiliarity mobiliarytiData={bookingDetailsData?.SPECIFICATIONS?.forniture_items} />
            {
              bookingDetailsData.EVENT.reservation_status_code === 'approved' ? <></> :
                bookingDetailsData?.EVENT?.reservation_status_code === 'canceled' ?
                  <CanceledReason comment={bookingDetailsData?.COMMENTS?.cancelation_comment} /> : (
                    <Row justify="end" align="middle" style={{ gap: 16 }}>
                      <Button className="btn-just-icon" style={{ color: '#FF601C', fontWeight: 500 }} type="text" onClick={() => handleOpenCloseModal({ name: 'rejectedModal', status: true })} >RECHAZAR</Button>
                      {
                        bookingDetailsData?.EVENT?.reservation_status_code === 'awaitingPayment' ? <></> :
                          bookingDetailsData?.EVENT?.reservation_status_code === 'awaitingConfirmation'
                            ? <Button
                              onClick={() => {
                                form.setFieldsValue({
                                  reservation_invitee_cost: bookingDetailsData?.QUOTE?.reservation_invitee_cost ?? 0,
                                  reservation_forniture_cost: bookingDetailsData?.QUOTE?.reservation_forniture_cost ?? 0,
                                  reservation_security_guard_cost: bookingDetailsData?.QUOTE?.reservation_security_guard_cost ?? 0
                                })
                                handleOpenCloseModal({ name: 'cotizationModal', status: true })
                              }
                              }
                              className="btn-standard-green"
                              shape="round"
                            >
                              CREAR COTIZACIÓN
                            </Button>
                            : <Button onClick={() => handleOpenCloseModal({ name: 'confirmModal', status: true })} className="btn-standard-green" shape="round">APROBAR</Button>
                      }
                    </Row>
                  )
            }
          </Col>
        </Row>
      </main>

      {/**Se definen los modales para mostrar los detalles de una cotización, el formulario para una nueva cotización, para poder cancelar la reserva o para aprobarla respectivamente */}
      <CotizationDetailsModal
        bookingDetailsData={bookingDetailsData}
        handleOpenCloseModal={handleOpenCloseModal}
        showModal={showModal.detailCotizationModal}
      />

      <NewCotizationModal
        bookingDetailsData={bookingDetailsData}
        form={form}
        loading={loading?.cotizationModal}
        onSubmit={onSubmitForm}
        handleOpenCloseModal={handleOpenCloseModal}
        showModal={showModal.cotizationModal}
      />

      <RejectedBookingModal
        loading={loading?.rejectedModal}
        onSubmit={onSubmitForm}
        handleOpenCloseModal={handleOpenCloseModal}
        onCancel={handleOpenCloseModal}
        isOpenModal={showModal.rejectedModal}
      />

      <CustomModal
        loadingModalLayout={loading?.confirmModal}
        confirmModalLayout
        destroyOnClose
        onCancel={() => handleOpenCloseModal({ name: 'confirmModal', status: false })}
        isOpenModal={showModal.confirmModal}
        title={'APROBAR RESERVA'}
        description={'¿Seguro que desea aprobar el pago de reserva?'}
        onCancelButton={() => handleOpenCloseModal({ name: 'confirmModal', status: false })}
        onConfirmButton={() => onSubmitForm({ values: { reservation_id: bookingDetailsData.reservation_id }, name: 'approve' })}
      />
    </section>
  )
}

export default BookingRequest