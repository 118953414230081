export const submenuHelper = {
	messages: {
		title: "Mensajería",
		items: [
			{
				name: "Alertas recibidas",
				url: "/alertas-recibidas",
			},
			/* {
				name: "Solicitudes de reserva",
				url: "/solicitudes-de-reserva",
			}, */
			/* {
				name: "Paquetes en recepción",
				url: "/paquetes-en-recepcion",
			},
			{
				name: "Objetos perdidos",
				url: "/objetos-perdidos",
			}, */
			{
				name: "Publicación de noticias",
				url: "/noticias",
			},
			{
				name: "Envío de convocatorias",
				url: "/convocatorias",
			},
			{
				name: "Encuestas",
				url: "/encuestas",
			},
		],
	},
	access: {
		title: "Accesos",
		items: [
			{
				name: "Accesos",
				url: "/accesos",
			},
			{
				name: "Monitor de Contratistas",
				url: "/access/monitoring-contrators",
			},
		],
	},
	users: {
		title: "Usuarios",
		items: [
			{
				name: "Perfiles de usuarios",
				url: "/usuarios",
			},
			{
				name: "Usuarios desactivados",
				url: "/usuarios-desactivados",
			},
		],
	},
	payments: {
		title: "Cobros y Pagos",
		items: [
			{
				name: "Pagos recibidos",
				url: "/pagos",
			},
			{
				name: "Admón. de cobros",
				url: "/admin-cobros",
			},
			{
				name: "Usuarios en mora",
				url: "/usuarios-en-mora",
			},
		],
	},
	settings: {
		title: "Ajustes",
		items: [
			{
				name: "Usuarios",
				url: "/usuarios",
			},
			/* {
				name: "Usuarios desactivados",
				url: "/usuarios-desactivados",
			},
			{
				name: "Admón. de Amenidades",
				url: "/admin-amenidades",
			}, */
			{
				name: "Reservas",
				url: "/bookings",
			},
			{
				name: "Admón. Amenidades",
				url: "/settings/managment-amenities",
			},
			{
				name: "Junta Directiva",
				url: "/junta-directiva",
			},
			{
				name: "Agentes de seguridad",
				url: "/agentes-seguridad",
			},
			{
				name: "Turnos de vigilancia",
				url: "/turnos-vigilancia",
			},
			{
				name: "Configuración inicial",
				url: "/configuracion-inicial",
			},
		],
	},
	settingsOficina: {
		title: "Ajustes",
		items: [
			{
				name: "Zonas",
				url: "/zonas",
			},
			{
				name: "Junta Directiva",
				url: "#",
			},
			{
				name: "Configuración inicial",
				url: "#",
			},
		],
	},
};
