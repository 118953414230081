import { Col, Divider, Drawer, Image, Row } from "antd"
import phoneIcon from '../../assets/phoneIcon.svg'
import profileIcon from '../../assets/profileIcon.svg'
import { phoneFormat } from "../../../../helpers/phoneFormat"
import moment from "moment"

export const ContratorsDetailDrawer = ({ handleOpenCloseDrawer, visibleDrawer, data }) => {
    return (
        <Drawer className="drawer-contrator" visible={visibleDrawer} onClose={handleOpenCloseDrawer} title={'PERFIL DE CONTRATISTA'} width={550}>
            <Col className="image-container">
                <Image width={80} height={80} src={data.image ? data.image : profileIcon} />
                <strong>{data.firstName} {data.lastName}</strong>
                <Row>
                    <Image src={phoneIcon} preview={false} />
                    <span>({data.phoneArea}){phoneFormat({ string: data.phoneNumber })}</span>
                </Row>
            </Col>
            <Divider />
            <div className="main-details">
                <Col className="detail-container" style={{ marginBottom: 32 }}>
                    <small>RESIDENCIAL:</small>
                    <span>{data.residential}</span>
                </Col>
                <Row gutter={48}>
                    <Col span={12} className="detail-container">
                        <small>INICIO DEL PROYECTO:</small>
                        <span>{moment(data.dateBegin).format('DD/MM/YYYY')}</span>
                    </Col>
                    <Col span={12} className="detail-container">
                        <small>FINALIZACIÓN DEL PROYECTO:</small>
                        <span>{moment(data.dateEnd).format('DD/MM/YYYY')}</span>
                    </Col>
                </Row>
            </div>
            <Divider />
        </Drawer>
    )
}

export default ContratorsDetailDrawer