import {
	collection,
	query,
	where,
	getDocs,
	doc,
	getDoc,
	orderBy,
} from "firebase/firestore";
import { FirebaseDB } from "../firebase/config";
import moment from "moment";
import {
	getQueryDocByCollection,
	getThreeQueryDocByCollection,
} from "./globalHelper";
import { getColumnSearchProps } from "../utils/tables";

export const getAccessHelper = async (
	residential_id,
	fechaInicio,
	fechaFinal
) => {
	const collectionRef = collection(FirebaseDB, `/resident_records`);

	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(23, 59, 59, 0);

	// Obtenemos solo las activas
	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("created_at", ">=", new Date(dateStart)),
		where("created_at", "<=", new Date(dateEnd)),
		orderBy("created_at", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = await parseAccessData({ ...data, id: document.id });
		list.push(itemData);
	});

	return list;
};

export const getAccessActiveVisitors = async (residential_id, fechaDia) => {
	const collectionRef = collection(FirebaseDB, `/resident_records`);

	const dateStart = fechaDia.setHours(0, 0, 0, 0);
	const dateEnd = fechaDia.setHours(23, 59, 59, 0);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("date_arrive", ">=", new Date(dateStart)),
		where("date_arrive", "<=", new Date(dateEnd)),
		where("leave", "==", false),
		orderBy("date_arrive", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = await parseAccessData({ ...data, id: document.id });

		list.push(itemData);
	});

	return list;
};

export const getAccessHistorialByProfileId = async (profile_id) => {
	let records = [];
	if (profile_id) {
		let dataRecords = await getQueryDocByCollection(
			"resident_records",
			"profile_id",
			"==",
			profile_id
		);
		dataRecords.forEach(async (documment) => {
			let itemData = await parseAccessData(documment);
			itemData.house_id = itemData.house_id ? itemData.house_id.id : null;

			itemData.date_action = itemData.date_arrive
				? itemData.date_arrive
				: itemData.date_leave;
			itemData.date_time_actions = itemData.time_arrive
				? itemData.time_arrive
				: itemData.time_leave;
			records.push(itemData);
		});
	}

	return records;
};

export const getAccessHistorialByProfileIdAndDate = async (
	profile_id,
	fechaInicio,
	fechaFinal
) => {
	let records = [];
	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(23, 59, 59, 0);
	if (profile_id) {
		let dataRecords = await getThreeQueryDocByCollection(
			"resident_records",
			"profile_id",
			"==",
			profile_id,
			"created_at",
			">=",
			new Date(dateStart),
			"created_at",
			"<=",
			new Date(dateEnd)
		);
		dataRecords.forEach(async (documment) => {
			let itemData = await parseAccessData(documment);
			itemData.house_id = itemData.house_id ? itemData.house_id.id : null;

			itemData.date_action = itemData.date_arrive
				? itemData.date_arrive
				: itemData.date_leave;
			itemData.date_time_actions = itemData.time_arrive
				? itemData.time_arrive
				: itemData.time_leave;
			records.push(itemData);
		});
	}

	return records;
};

export const parseAccessData = async (data) => {
	// Definimos los datos a obtener
	let itemData = {
		id: data.id,
		active: data.active,
		created_at: moment(data.created_at.toDate()).format("Y-MM-DD"),
		created_time: moment(data.created_at.toDate()).format("HH:mm"),
		date_leave:
			data.date_leave && moment(data.date_leave.toDate()).format("Y-MM-DD"),
		time_leave:
			data.date_leave && moment(data.date_leave.toDate()).format("HH:mm"),
		leave: data.leave,
		date_arrive:
			data.date_arrive && moment(data.date_arrive.toDate()).format("Y-MM-DD"),
		time_arrive:
			data.date_arrive && moment(data.date_arrive.toDate()).format("HH:mm"),
		dateVisit: moment(data.created_at.toDate()).format("DD/MM/YY"),
		timeVisit: moment(data.created_at.toDate()).format("HH:mm"),
		leave: data.leave == undefined ? false : true,
		arrive: data.arrive == undefined ? false : true,
		name: data.name,
		type: data.type,
		vehicle: data.vehicle,
		vehicle_plate: data.vehicle_plate,
		profile_id: data.profile_id,
		house_id: data.house ? data.house : null,
		residential_id: data.residential_id ? data.residential_id : null,
		reference_document: data.reference_document,
		vehicle_plate: data.vehicle_plate,
	};

	return itemData;
};

export const getScheduleVisitorsHelper = async (
	residential_id,
	fechaInicio,
	fechaFinal
) => {
	const dateStart = new Date(
		fechaInicio.getTime() - fechaInicio.getTimezoneOffset() * 60 * 1000
	);
	const dateEnd = new Date(
		fechaFinal.getTime() - fechaFinal.getTimezoneOffset() * 60 * 1000
	);

	const collectionRef = collection(FirebaseDB, `/events`);
	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("date", ">=", dateStart),
		where("date", "<=", dateEnd),
		orderBy("date", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);

	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();

		// Definimos los datos a obtener
		let itemData = {
			id: documment.id,
			active: data.active,
			created_at: moment(data.date.toDate()).format("Y-MM-DD"),
			created_time: moment(data.date.toDate()).format("HH:mm"),
			category: data.category,
			event_date: data.date && moment(data.date.toDate()).format("Y-MM-DD"),
			event_time: data.date && moment(data.date.toDate()).format("HH:mm"),
			drinks: data.drinks,
			electricEquipment: data.electricEquipment,
			food: data.food,
			comment: data.comment,
			checkin: data.checkin,
			name: data.name,
			payment_event: data.payment_event,
			pets: data.pets,
			residential_id: data.residential_id,
			status: data.status,
			type: data.type,
			time_begin: data.time_begin,
			time_end: data.time_end,
			user_id: data.user_id,
			house: data.house,
			event_type: ""
		};

		switch (itemData.type) {
			case 1:
				itemData.event_type = "Residente";
				break;
			case 2:
				itemData.event_type = "Delivery";
				itemData.name = data.visitor;
				itemData.houseShow = data.name;
				break;
			case 3:
				itemData.event_type = "Visita programada";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 4:
				itemData.event_type = "Proveedor o Empleado";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 5:
				itemData.event_type = "Oficina";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 6:
				itemData.event_type = "Familiar y amigos";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 7:
				itemData.event_type = "Documento";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 9:
				itemData.event_type = "Link Acceso";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			default:
				break
		}

		list.push(itemData);
	});

	return list;
};

export const getScheduleVisitorsByUserHelper = async (
	residential_id,
	user_id,
	fechaInicio,
	fechaFinal
) => {
	const dateStart = new Date(
		fechaInicio.getTime() - fechaInicio.getTimezoneOffset() * 60 * 1000
	);
	const dateEnd = new Date(
		fechaFinal.getTime() - fechaFinal.getTimezoneOffset() * 60 * 1000
	);

	const collectionRef = collection(FirebaseDB, `/events`);
	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id), //CAMBIAR A residential_id
		where("user_id", "==", user_id),
		where("date", ">=", dateStart),
		where("date", "<=", dateEnd),
		orderBy("date", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);

	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();

		// Definimos los datos a obtener
		let itemData = {
			id: documment.id,
			active: data.active,
			created_at: moment(data.date.toDate()).format("Y/MM/DD"),
			created_time: moment(data.date.toDate()).format("HH:mm"),
			category: data.category,
			event_date: data.date && moment(data.date.toDate()).format("Y/MM/DD"),
			event_time: data.date && moment(data.date.toDate()).format("HH:mm"),
			drinks: data.drinks,
			electricEquipment: data.electricEquipment,
			food: data.food,
			comment: data.comment,
			checkin: data.checkin,
			name: data.name,
			payment_event: data.payment_event,
			pets: data.pets,
			residential_id: data.residential_id,
			status: data.status,
			type: data.type,
			time_begin: data.time_begin,
			time_end: data.time_end,
			user_id: data.user_id,
			house: data.house,
		};

		switch (itemData.type) {
			case 1:
				itemData.event_type = "Residente";
				break;
			case 2:
				itemData.event_type = "Delivery";
				itemData.name = data.visitor;
				itemData.houseShow = data.name;
				break;
			case 3:
				itemData.event_type = "Visita programada";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 4:
				itemData.event_type = "Proveedor o Empleado";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 5:
				itemData.event_type = "Oficina";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 6:
				itemData.event_type = "Familiar y amigos";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 7:
				itemData.event_type = "Documento";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
		}

		list.push(itemData);
	});
	return list;
};

export const getScheduleVisitorsHelperDay = async (
	residential_id,
	fechaInicio,
	fechaFinal
) => {
	var days = [
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
		"sunday",
	];
	var daysSpanish = [
		"Lunes",
		"Martes",
		"Miercoles",
		"Jueves",
		"Viernes",
		"Sabado",
		"Domingo",
	];
	var newDateMain = new Date(
		fechaInicio.getTime() - fechaInicio.getTimezoneOffset() * 60 * 1000
	);
	var d = newDateMain;
	var dayName = days[d.getDay()];
	//var activoDia = prf[dayName]

	const collectionRef = collection(FirebaseDB, `/events`);
	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("event_by", "==", "week")
	);
	const querySnapshot = await getDocs(queryMessages);

	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		var diasAcceso = "";
		for (var z = 0; z < parseInt(days.length); z++) {
			if (data[days[z]] == true) {
				diasAcceso =
					diasAcceso == ""
						? diasAcceso + "" + daysSpanish[z]
						: diasAcceso + " - " + daysSpanish[z];
			}
		}

		// Definimos los datos a obtener
		let itemData = {
			id: documment.id,
			active: data.active,
			created_at: "",
			created_time: "",
			category: data.category,
			event_date: diasAcceso,
			event_time: "",
			drinks: data.drinks,
			electricEquipment: data.electricEquipment,
			food: data.food,
			comment: data.comment,
			checkin: data.checkin,
			name: data.name,
			payment_event: data.payment_event,
			pets: data.pets,
			residential_id: data.residential_id,
			status: data.status,
			type: data.type,
			time_begin: data.time_begin,
			time_end: data.time_end,
			user_id: data.user_id,
			house: data.house,
		};

		switch (itemData.type) {
			case 1:
				itemData.event_type = "Residente";
				break;
			case 2:
				itemData.event_type = "Delivery";
				itemData.name = data.visitor;
				itemData.houseShow = data.name;
				break;
			case 3:
				itemData.event_type = "Visita programada";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 4:
				itemData.event_type = "Proveedor o Empleado";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 5:
				itemData.event_type = "Oficina";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 6:
				itemData.event_type = "Familiar y amigos";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
			case 7:
				itemData.event_type = "Documento";
				itemData.event_time = data.time_begin + " - " + data.time_end;
				break;
		}

		list.push(itemData);
	});

	return list;
};

export const columnsAccessScheduleVisitorTable = () => {
	return [
		{
			title: "Día",
			dataIndex: "event_date",
			key: "event_date",
		},
		{
			title: "Hora",
			dataIndex: "event_time",
			key: "event_time",
		},
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("name"),
		},
		{
			title: "Residencia",
			dataIndex: "houseShow",
			key: "houseShow",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("houseShow"),
		},
		{
			title: "Tipo de visita",
			dataIndex: "event_type",
			key: "event_type",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			onCell: (text, record) => record.event_type,
			filters: [
				{
					text: "Proveedor o Empleado",
					value: "Proveedor o Empleado",
				},
				{
					text: "Delivery",
					value: "Delivery",
				},
				{
					text: "Familiar y amigos",
					value: "Familiar",
				},
			],
			onFilter: (value, record) =>
				record.event_type
					? record.event_type
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
					: "",
			/* onFilter: (value, record) => record.event_type.indexOf(value) === 0, */
		},
	];
};

export const columnsAccessResidentsTable = () => {
	return [
		/* {
			title: "Fecha",
			dataIndex: "dateVisit",
			key: "dateVisit",
			...getColumnSearchProps("dateVisit"),
		}, */
		{
			title: "Hora",
			dataIndex: "timeVisit",
			key: "timeVisit",
			...getColumnSearchProps("timeVisit"),
		},
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("name"),
		},
		{
			title: "Acción",
			dataIndex: "action_user",
			key: "action_user",
		},
		{
			title: "Teléfono",
			dataIndex: "phone",
			key: "phone",
			...getColumnSearchProps("phone"),
		},
		{
			title: "Historial",
			dataIndex: "detail",
			key: "detail",
		},
	];
};

export const columnsAccessVisitorsTable = () => {
	return [
		/* {
			title: "Fecha",
			dataIndex: "dateVisit",
			key: "dateVisit",
			...getColumnSearchProps("dateVisit"),
		}, */
		{
			title: "Hora",
			dataIndex: "timeVisit",
			key: "timeVisit",
		},
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("name"),
		},
		{
			title: "Acción",
			dataIndex: "action_user",
			key: "action_user",
		},
		{
			title: "Tipo de visita",
			dataIndex: "visit_type",
			key: "visit_type",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			onCell: (text, record) => record.visit_type,
			filters: [
				{
					text: "Proveedor o Empleado",
					value: "Proveedor o Empleado",
				},
				{
					text: "Delivery",
					value: "Delivery",
				},
				{
					text: "Familiar y amigos",
					value: "Familiar",
				},
			],
			onFilter: (value, record) =>
				record.visit_type
					? record.visit_type
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
					: "",

			width: 180,
		},
		{
			title: "Residencia",
			dataIndex: "home_residence",
			key: "home_residence",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("home_residence"),
		},
		{
			title: "Teléfono",
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: "Historial",
			dataIndex: "detail",
			key: "detail",
		},
	];
};

export const columnsHistorialAccessTable = () => {
	return [
		{
			title: "Fecha",
			dataIndex: "date_action",
			key: "date_action",
			render: (text, record) => (
				<>{moment(record.date_action).format("YY/MM/D")}</>
			),
		},
		{
			title: "Hora",
			dataIndex: "date_time_actions",
			key: "date_time_actions",
		},
		{
			title: "Entrada/Salida",
			dataIndex: "action_user_history",
			key: "action_user_history",
		},
	];
};

export const columnsProfileHistorialAccessTable = () => {
	return [
		{
			title: "Fecha",
			dataIndex: "date_action",
			key: "date_action",
			align: "center",
			render: (text, record) => (
				<>{moment(record.date_action).format("YY/MM/D")}</>
			),
		},
		{
			title: "Hora",
			dataIndex: "date_time_actions",
			key: "date_time_actions",
			align: "center",
		},
		{
			title: "Entrada/Salida",
			dataIndex: "action_user",
			key: "action_user",
			align: "center",
			/* render: (userData) => (
				<>
					{userData.arrive ? (
						<>
							<img src="/assets/images/shape12.png" />
							<span style={{ marginLeft: "10px" }}>Entró</span>
						</>
					) : (
						<>
							<img src="/assets/images/shape13.png" />
							<span style={{ marginLeft: "10px" }}>Salió</span>
						</>
					)}
				</>
			), */
		},
	];
};
