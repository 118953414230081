import React, { useEffect, useState, useRef } from "react";
import {
	Col,
	Row,

	Button,
	Modal,
	Card,
	Form,
	Input,
	Select,
} from "antd";
import { AgentSchedule } from "./AgentSchedule";
import moment from "moment";
import { onUpdateDocWithRefInfo } from "../../../../helpers/globalHelper";
import {
	getAgentTurnsById,
} from "../../../../helpers/agentsHelper";
import { editGreen, grayUser, horario, phone } from "../../../../assets/icons";
import { shape08, userProfile } from "../../../../assets/images";
export const AgentContainer = ({ data, nomen, nomenOptions, shouldReload }) => {
	const [securityAgent, setSecurityAgent] = useState([]);
	const [agentTurns, setAgentTurns] = useState([]);
	const [scheduleModalActive, setScheduleModalActive] = useState(false);
	const [profileModalActive, setProfileModalActive] = useState(false);
	const [editModalActive, setEditModalActive] = useState(false);
	const [editAgentLoadButton, setEditAgentLoadButton] = useState(false);
	const [nomenclatures, setNomenclatures] = useState([]);
	const [nomenclaturesOptions, setNomenclaturesOptions] = useState([]);
	const [selectedNomenclatures, setSelectedNomenclatures] = useState([]);
	const [loadButton, setLoadButton] = useState(false);
	const [loadEliminationButton, setLoadEliminationButton] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const editFormRef = useRef();

	useEffect(() => {
		setSecurityAgent(data);
	}, [data]);

	useEffect(() => {
		getTurns(securityAgent.id);
	}, [securityAgent]);

	useEffect(() => {
		setNomenclatures(nomen);
	}, [nomen]);

	useEffect(() => {
		setNomenclaturesOptions(nomenOptions);
	}, [nomenOptions]);

	const getTurns = async (id) => {
		const getTurn = await getAgentTurnsById("Yd5MV7jkA0MjQeCmzKrZ", id).then(
			(result) => setAgentTurns(result)
		);
	};

	const handleNomenclatureSelect = (e) => {
		let selected = [];
		let array = [...e];
		let count = e.length;

		for (let i = 0; i < count; i++) {
			selected.push(nomenclatures[array[i]].id);
		}

		setSelectedNomenclatures(selected);
	};

	const handleScheduleModal = (statusModal, index = null) => {
		if (index != null) {
		}

		setTimeout(() => {
			setScheduleModalActive(statusModal);
		}, 50);
	};

	const handleProfileModal = (statusModal, index = null) => {
		if (index != null) {
		}

		setTimeout(() => {
			setProfileModalActive(statusModal);
		}, 50);
	};

	const handleEditModal = (statusModal, index = null) => {
		if (index != null) {
		}

		setTimeout(() => {
			setEditModalActive(statusModal);
		}, 50);
	};

	const handleAgentActivationService = async (isActive) => {
		setLoadButton(true);
		const modifyService = {
			active_service: isActive,
		};
		const id = securityAgent.id;
		const collectionRef = `/agents/${id}`;
		const editAgent = await onUpdateDocWithRefInfo(
			collectionRef,
			modifyService
		);
		setLoadButton(false);
		shouldReload(true);
	};

	const handleAgentElimination = async () => {
		setLoadEliminationButton(true);
		const modifyService = {
			active: false,
			active_service: false,
		};
		const id = securityAgent.id;
		const collectionRef = `/agents/${id}`;
		const editAgent = await onUpdateDocWithRefInfo(
			collectionRef,
			modifyService
		);
		handleProfileModal(false);
		setLoadEliminationButton(false);
		shouldReload(true);
	};

	const initialFormValues = {
		name: securityAgent.name,
		lastname: securityAgent.lastname,
		active_service: securityAgent.active_service,
		code: securityAgent.code,
		company: securityAgent.company,
		contract_type: securityAgent.contract_type,
		document: securityAgent.document,
		phone: securityAgent.phone,
		user: securityAgent.user,
	};

	const handleEditAgentForm = async (e) => {
		setEditAgentLoadButton(true);
		const modifiedAgent = {
			active: true,
			active_service: isActive,
			created_at: moment(securityAgent.created_at).toDate(),
			code: e.code,
			name: e.name,
			lastname: e.lastname,
			company: e.company,
			contract_type: e.contract_type,
			document: e.document,
			phone: e.phone,
			nomenclatures: selectedNomenclatures,
			user: e.user,
		};
		const id = securityAgent.id;
		const collectionRef = `/agents/${id}`;
		const editAgent = await onUpdateDocWithRefInfo(
			collectionRef,
			modifiedAgent
		);

		setEditAgentLoadButton(false);
		handleEditModal(false);
		handleProfileModal(false);
		setSelectedNomenclatures([]);
		editFormRef.current.resetFields();
		shouldReload(true);
	};

	const handleChangeActive = (value) => {
		setIsActive(value);
	};

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	return (
		<>
			{" "}
			{securityAgent ? (
				<>
					<Card
						style={{
							width: "100%",
							borderRadius: "10px",
							boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
							marginTop: "30px",
							marginBottom: "30px",
						}}
						bordered
					>
						<Row style={{}}>
							<Col span={11}>
								<div
									style={{
										marginLeft: "3rem",
										display: "flex",
									}}
								>
									<img src={grayUser} alt="gray-user-color" />
									<span style={{ marginLeft: "3rem" }}>
										{securityAgent.name} {securityAgent.lastname}
									</span>
								</div>
							</Col>
							<Col span={6}>
								<div style={{ display: "flex" }}>
									<span>
										{securityAgent.phone ? securityAgent.phone : "N/A"}
									</span>
								</div>
							</Col>

							<Col span={5}>
								{/* {committe.data ? ():()} */}
								<Button
									onClick={() => {
										handleScheduleModal(true);
									}}
									modalvisible="true"
									shape="round"
									style={{ width: "120px", borderColor: "#10b48c" }}
								>
									<div
										style={{
											display: "flex",
											textAlign: "center",
											color: "#404040",
										}}
									>
										<img
											className="iconButton"
											src={horario}
											alt="schedule-icon"
											style={{
												marginTop: "2px",
												marginLeft: "5px",
												width: "16px",
												height: "16px",
											}}
										/>
										<span
											style={{
												fontWeight: "400",
												fontSize: "12px",
												marginLeft: "10px",
											}}
										>
											Turnos
										</span>
									</div>
								</Button>
							</Col>
							<Col span={2}>
								<img
									className="iconButton"
									src={shape08}
									alt="credential-security-icon"
									onClick={() => {
										handleProfileModal(true);
									}}
									style={{ cursor: "pointer" }}
								/>
							</Col>
						</Row>
					</Card>

					{/* MODAL PERFIL AGENTE */}
					<Modal
						className="side-modal"
						title="Datos de agente de seguridad"
						style={{ top: 0, right: 0 }}
						visible={profileModalActive}
						onCancel={() => handleProfileModal(false)}
						footer={null}
						bodyStyle={{
							overflowY: "auto",
							maxHeight: "calc(100vh - 100px)",
						}}
						width={600}
					>
						<>
							<Row className="section-modal" style={{ paddingTop: "0px" }}>
								<Col span={24} align="middle">
									<img
										className="user-image"
										src={userProfile}
										alt="profile-avatar"
										style={{ marginTop: "1rem" }}
									/>
								</Col>
								<Col span={24} align="middle">
									<h2 style={{ color: "#232C4A", marginTop: "1rem" }}>
										{securityAgent.name} {securityAgent.lastname}
									</h2>
								</Col>
								<Col span={24} align="middle">
									<div>
										<span
											style={{
												color: "#10B48C",
												margin: "0px",
											}}
										>
											<img src={phone} alt="phone-icon" />

											<span style={{ marginLeft: "15x" }}>
												{/* {userActive.phone ? userActive.phone : ""} */}
												{securityAgent.phone ? securityAgent.phone : "N/A"}
											</span>

											<img
												style={{ marginLeft: "15px", cursor: "pointer" }}
												src={editGreen}
												alt="green-edit-icon"
												onClick={() => {
													handleEditModal(true);
												}}
											/>
										</span>
									</div>
								</Col>
							</Row>
							<Row className="section-modal">
								<Col
									span={24}
									style={{
										paddingTop: "1rem",
										paddingBottom: "2rem",
										paddingLeft: "3rem",
									}}
								>
									<div>
										<span>CARGO:</span>
										<p>
											{securityAgent.roleData
												? securityAgent.roleData[0].name
												: "N/A"}
										</p>
									</div>
									<div style={{ marginTop: "2rem" }}>
										<span>TIPO DE CONTRATO:</span>
										<p>{securityAgent.contract_type}</p>
									</div>
									<div style={{ marginTop: "2rem" }}>
										<span>EMPRESA DE SEGURIDAD:</span>
										<p>{securityAgent.company}</p>
									</div>
								</Col>
							</Row>
							<Row>
								<Col
									span={24}
									style={{
										paddingTop: "2rem",
										paddingBottom: "2rem",
									}}
									align="middle"
								>
									{securityAgent.active_service == true ? (
										<Button
											style={{
												color: "#CEB457",
												background: "#CEB457",
												paddingBottom: "15px",
												width: "240px",
											}}
											shape="round"
											size="large"
											loading={loadButton}
											onClick={() => {
												handleAgentActivationService(false);
											}}
										>
											<span
												className="title"
												style={{
													marginLeft: "10px",
													color: "#FFFFFF",
													fontWeight: "500",
													fontSize: "14px",
												}}
											>
												INCAPACITAR
											</span>
										</Button>
									) : (
										<Button
											style={{
												color: "#10B48C",
												background: "#10B48C",
												paddingBottom: "15px",
												width: "240px",
											}}
											shape="round"
											size="large"
											loading={loadButton}
											onClick={() => {
												handleAgentActivationService(true);
											}}
										>
											<span
												className="title"
												style={{
													marginLeft: "10px",
													color: "#FFFFFF",
													fontWeight: "500",
													fontSize: "14px",
												}}
											>
												ACTIVAR
											</span>
										</Button>
									)}

									<br />
									<br />
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#FC4850",
											background: "#FFFFFF",
											paddingBottom: "15px",
											width: "240px",
										}}
										shape="round"
										size="large"
										loading={loadEliminationButton}
										onClick={() => {
											handleAgentElimination();
										}}
									>
										<span
											className="title"
											style={{
												marginLeft: "10px",
												color: "#FC4850",
												fontWeight: "500",
												fontSize: "14px",
											}}
										>
											DAR DE BAJA
										</span>
									</Button>
								</Col>
							</Row>
						</>
					</Modal>

					{/* MODAL EDITAR AGENTE */}
					<Modal
						className="primary-modal"
						visible={editModalActive}
						onCancel={() => handleEditModal(false)}
						footer={null}
						width={650}
					>
						<>
							<Row className="section-modal" style={{ textAlign: "center" }}>
								<Col span={24}>
									<h2 style={{ color: "#232C4A" }}>EDITAR AGENTE</h2>
								</Col>
							</Row>
							<Row className="section-modal" style={{ marginTop: "1rem" }}>
								<Col
									span={24}
									style={{
										width: "90%",
										paddingLeft: "2rem",
										paddingRight: "2rem",
									}}
								>
									<Form
										ref={editFormRef}
										name="edit_agent_form"
										onFinish={handleEditAgentForm}
										initialValues={initialFormValues}
									>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>Nombres del agente:</p>
										</div>
										<Form.Item name="name" {...config}>
											<Input maxLength={50} />
										</Form.Item>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>Apellidos del agente:</p>
										</div>
										<Form.Item name="lastname" {...config}>
											<Input maxLength={50} />
										</Form.Item>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>¿Agente activo?:</p>
										</div>
										<Form.Item name="active_service" {...config}>
											<Select
												onChange={handleChangeActive}
												options={[
													{
														value: true,
														label: "Si",
													},
													{
														value: false,
														label: "No",
													},
												]}
											/>
										</Form.Item>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>Código:</p>
										</div>
										<Form.Item name="code" {...config}>
											<Input maxLength={10} type="number" />
										</Form.Item>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>Compañía:</p>
										</div>
										<Form.Item name="company" {...config}>
											<Input maxLength={50} />
										</Form.Item>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>Tipo contratación:</p>
										</div>
										<Form.Item name="contract_type" {...config}>
											<Input maxLength={50} />
										</Form.Item>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>Número DUI:</p>
										</div>
										<Form.Item name="document" {...config}>
											<Input maxLength={50} />
										</Form.Item>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>Télefono:</p>
										</div>
										<Form.Item name="phone" {...config}>
											<Input maxLength={10} type="number" />
										</Form.Item>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>Asignar nomenclaturas:</p>
										</div>
										<Form.Item name="nomenclatures" {...config}>
											{nomenclaturesOptions ? (
												<Select
													mode="multiple"
													placeholder="Buscar por nombre"
													style={{
														width: "100%",
													}}
													allowClear
													showSearch
													filterOption={(input, option) => {
														return (
															option.label
																.toLowerCase()
																.indexOf(input.toLowerCase()) >= 0
														);
													}}
													onChange={handleNomenclatureSelect}
												>
													{nomenclaturesOptions}
												</Select>
											) : null}
										</Form.Item>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>Nombre usuario:</p>
										</div>
										<Form.Item name="user" {...config}>
											<Input maxLength={50} />
										</Form.Item>

										<Row>
											<Col span={24} align="middle">
												<Button
													htmlType="submit"
													style={{
														borderColor: "#10b48c",
														background: "#10b48c",
														paddingBottom: "15px",
														width: "250px",
													}}
													shape="round"
													size="large"
													loading={editAgentLoadButton}
												>
													<span className="title" style={{ color: "#fff" }}>
														MODIFICAR AGENTE
													</span>
												</Button>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</>
					</Modal>

					{/* MODAL TURNOS AGENTE */}
					<Modal
						className="side-modal"
						title="Turno de servicio"
						style={{ top: 0, right: 0 }}
						visible={scheduleModalActive}
						onCancel={() => handleScheduleModal(false)}
						footer={null}
						bodyStyle={{
							overflowY: "auto",
							maxHeight: "calc(100vh - 100px)",
						}}
						width={750}
					>
						<>
							<Row className="section-modal" style={{ paddingTop: "0px" }}>
								<Col span={4} align="middle">
									<img
										className="user-image"
										src={userProfile}
										alt="profile-avatar"
										style={{ marginTop: "1rem" }}
									/>
								</Col>
								<Col span={20}>
									<h2 style={{ color: "#232C4A", marginTop: "2rem" }}>
										{securityAgent.name} {securityAgent.lastname}
									</h2>
								</Col>
							</Row>
							<Row className="section-modal">
								<Col span={24}>
									<p>TURNOS DEL AGENTE</p>
									<AgentSchedule turns={agentTurns} />

									{/* <ShiftsTable turns={agentTurns} /> */}
								</Col>
							</Row>
							{/* <Row>
								<Col
									span={24}
									style={{
										paddingTop: "2rem",
										paddingBottom: "2rem",
									}}
									align="middle"
								>
									<Button
										style={{
											color: "#10b48c",
											background: "#10b48c",
											paddingBottom: "15px",
											width: "240px",
										}}
										shape="round"
										size="large"
										disabled
									>
										<span
											className="title"
											style={{
												marginLeft: "10px",
												color: "#FFFFFF",
												fontWeight: "500",
												fontSize: "14px",
											}}
										>
											GUARDAR CAMBIOS
										</span>
									</Button>
								</Col>
							</Row> */}
						</>
					</Modal>
				</>
			) : null}
		</>
	);
};
