import { getAllAmenities } from "./getAllAmenities";
import { getAllBookings } from "./getAllBookings";
import { getBookingDetail } from "./getBookingDetail";
import { getBookingStatuses } from "./getBookingStatuses";
import { getProfilesByCompany } from "./getProfilesByCompany";

export {
    getAllAmenities,
    getAllBookings,
    getBookingDetail,
    getBookingStatuses,
    getProfilesByCompany
}