import { getTableDataHelper } from "../../../../helpers/getTableDataHelper"
import verifyResponse from "../../../../helpers/verifyResponse"
import { useMonitoringContratorsContext } from "../../contexts/MonitoringContratorsContext"
import { getProvidersByAccessAndAccessDate } from "../../services/GET/getProvidersByAccessAndAccessDate"

export const useProvidersServices = () => {

    const { auth } = useMonitoringContratorsContext()

    const getProvidersByAccessAndAccessDateService = async ({ access_id, access_date, limit, skip, find_by, find_value, sort_by, sort_order, last, currentPage, previousData, setLoading }) => {
        setLoading && setLoading(true)
        const res = await getProvidersByAccessAndAccessDate({ token: auth.idToken, access_id, access_date, limit, skip, find_by, find_value, sort_by, sort_order })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('getProvidersByAccessAndAccessDateService')
        }
        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, skip, last, actualLimit: limit, currentPage, previousData })
        setLoading && setLoading(false)
        return { data, metadata }

    }
    return {
        getProvidersByAccessAndAccessDateService
    }
}

export default useProvidersServices