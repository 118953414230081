import { Col, Row, Button, Modal, Form } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getSurveys,
	setActiveSurveys,
	setSurveys,
	getAllUser,
} from "../../../store/surveys";
import "./style.css";
import { useForm } from "../../../hooks";
import TextArea from "antd/lib/input/TextArea";
import { toast } from "react-toastify";
import { Select } from "antd";
import { DatePicker, Space } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { utils, writeFile } from "xlsx";
import { async } from "@firebase/util";
import $ from "jquery";
import { basura, botonAgregar, botonAgregarSmall, shape20, shape22, shape23, shape25, shape34, shape35, shape36, sheets } from "../../../assets/images";

//CFB, SSF, parse_xlscfb, parse_zip, read, readFile, readFileSync, set_cptable, set_fs, stream, utils, version, write, writeFile, writeFileAsync, writeFileSync, writeFileXLSX, writeXLSX)

const { Option } = Select;
const { RangePicker } = DatePicker;

export const SurveyPage = () => {
	const [surveysList, setSurveysList] = useState([]);
	const [opcionesGrafica, setOpcionesGrafica] = useState([]);
	const [surveysDetail, setSurveysDetail] = useState(null);
	const { surveys } = useSelector((state) => state.surveys);
	const [isModalVisiblePasoUno, setIsModalVisiblePasoUno] = useState(false);
	const [isModalVisiblePasoDos, setIsModalVisiblePasoDos] = useState(false);
	const [isModalVisiblePasoTres, setIsModalVisiblePasoTres] = useState(false);
	const [listPreguntas, setListPreguntas] = useState([]);
	const [listPreguntasEliminadas, setListPreguntasEliminadas] = useState([]);
	const [tipoEncuesta, setTipoEncuesta] = useState("obligatoria");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [modalVisible, setModalVisible] = useState(false);
	const [loadMoreData, setLoadMoreData] = useState(false);
	const [handleSelectTabs, setHandleSelectTabs] = useState(false);
	const [idBorrado, setIdBorrado] = useState("");
	const [loadSendData, setLoadSendData] = useState(false);
	const timeRef = useRef(null);
	const [form] = Form.useForm();
	const [dateRange, setDateRange] = useState(null);
	const dayFormat = "'YYYY/MM/DD'";
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;

	const {
		about,
		descripcion,
		name,
		resetValuesInput,
		onInputChange,
		onResetForm,
	} = useForm({
		about: "",
		descripcion: "",
		name: "",
	});

	const resetRangePicker = () => {
		setDateRange(null);
	};

	const dispatch = useDispatch();

	const handleChange = (value, key) => {
		let newArr = [...listPreguntas]; // copying the old datas array
		newArr[key].question = value;

		setListPreguntas(newArr);
	};

	const handleStatusModal = (statusModal, index = null) => {
		setTimeout(() => {
			setModalVisible(statusModal);
		}, 50);
	};

	useEffect(() => {
		dispatch(getSurveys());
	}, []);

	useEffect(() => {
		if (surveys.data.length > 0) {
			setSurveysList(surveys.data);
		}
		setIsModalVisiblePasoTres(false);
		setIsModalVisiblePasoDos(false);
		setLoadSendData(false);
		onResetForm();
		var listadoPreguntasDefault = [];
		listadoPreguntasDefault.push({
			idR: 0,
			question: "",
			type: "commentary",
			posicion: listadoPreguntasDefault.length,
		});
		setListPreguntas(listadoPreguntasDefault);
		setEndDate("");
		setStartDate("");
	}, [surveys.data]);

	const descargarEncuesta = () => {

		var i = 0;
		var wb = utils.book_new();
		surveysDetail.preguntas.map((item, index) => {
			const dataSet = [];
			if (item.type === "qualify" || item.type === "option") {
				opcionesGrafica[index]?.map((itemP, index) => {
					dataSet.push({
						nombre: itemP.titulo,
						cantidad: itemP.cantidad,
						porcentaje: itemP.porcentaje ? itemP.porcentaje + "%" : "0%",
					});
				});
			} else if (item.type === "commentary") {
				opcionesGrafica[index]?.map((itemP, index) => {
					dataSet.push({
						respuesta: itemP.response,
					});
				});
			}

			var ws = utils.json_to_sheet([{}], {
				header: [item.question],
			});
			utils.sheet_add_json(ws, dataSet, { origin: "A3" });
			utils.book_append_sheet(wb, ws, "Pregunta " + (index + 1));
		});

		writeFile(wb, surveysDetail.name + ".xlsx");
	};

	const handleCancelPasoUno = () => {
		setIsModalVisiblePasoUno(false);
	};

	const handleCancelPasoDos = () => {
		setIsModalVisiblePasoDos(false);
	};

	const handleCancelPasoTres = () => {
		setIsModalVisiblePasoTres(false);
	};

	useEffect(() => {
		setSurveysDetail(surveys.active);
	}, [surveys.active]);

	const handleActiveDetail = (data) => {
		dispatch(setActiveSurveys(data));
	};

	const handleEdit = (data) => {
		var formInfo = { name: data.name, descripcion: data.descripcion };
		resetValuesInput(formInfo);

		let newArr = [];
		var i = 0;
		while (i < data.preguntas.length) {
			var item = data.preguntas[i];
			var itemNew = {
				idR: item.idR,
				question: item.question,
				type: item.type,
				posicion: newArr.length,
			};

			if (item.type === "option") {
				var j = 0;
				var opciones = [];
				while (j < item.option.length) {
					opciones.push({ posicion: j, valor: item.option[j] });
					++j;
				}

				itemNew.opciones = opciones;
			}

			newArr.push(itemNew);
			++i;
		}

		setListPreguntas(newArr);
		setStartDate("");
		setEndDate("");
		setIdBorrado(data.id);

		setIsModalVisiblePasoUno(true);
	};

	const agregarPregunta = () => {
		let newArr = [...listPreguntas];
		newArr.push({
			idR: 0,
			question: "",
			type: "commentary",
			posicion: newArr.length,
		});
		setListPreguntas(newArr);
	};

	const agregarOpcion = (key) => {
		let newArr = [...listPreguntas];
		newArr[key].opciones.push({
			posicion: newArr[key].opciones.length,
			valor: "",
		});
		setListPreguntas(newArr);
	};

	const eliminarPregunta = (posicion) => {
		let newArr = [...listPreguntas];
		let newArrEliminadas = [...listPreguntasEliminadas];
		newArrEliminadas.push(newArr[posicion]);
		delete newArr[posicion];

		var newArrPosition = [];
		var i = 0;
		while (i < newArr.length) {
			if (typeof newArr[i] !== "undefined") {
				newArr[i].posicion = newArrPosition.length;
				newArrPosition.push(newArr[i]);
			}
			++i;
		}

		setListPreguntas(newArrPosition);
		setListPreguntasEliminadas(newArrEliminadas);
	};

	const eliminarOpcion = (key, posicion) => {
		let newArr = [...listPreguntas];
		delete newArr[key].opciones[posicion];
		var newArrPosition = [];
		var i = 0;
		while (i < newArr[key].opciones.length) {
			if (typeof newArr[key].opciones[i] !== "undefined") {
				newArrPosition.push({
					posicion: newArrPosition.length,
					valor: newArr[key].opciones[i].valor,
				});
			}
			++i;
		}
		newArr[key].opciones = [];
		newArr[key].opciones = newArrPosition;
		setListPreguntas(newArr);
	};

	const onChangeFecha = (date, dateString) => {
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		//const [startDate, setstartDate] = useState("");
		//const [endDate, setendDate] = useState("");
	};

	const mostrarEstadisticas = async () => {
		var calcularPreguntas = surveysDetail;
		var i = 0;
		let newArr = [];
		while (i < calcularPreguntas.preguntas.length) {
			if (calcularPreguntas.preguntas[i].type === "qualify") {
				var totalRespuestas = calcularPreguntas.respuesta.filter(
					(item) => item.question_id === calcularPreguntas.preguntas[i].idR
				);

				var totalCincoEstrellas = totalRespuestas.filter(
					(item) => item.response === 5.0
				);
				var totalCuatroEstrellas = totalRespuestas.filter(
					(item) => item.response === 4.0
				);
				var totalTresEstrellas = totalRespuestas.filter(
					(item) => item.response === 3.0
				);
				var totalDosEstrellas = totalRespuestas.filter(
					(item) => item.response === 2.0
				);
				var totalUnoEstrellas = totalRespuestas.filter(
					(item) => item.response === 1.0
				);

				var opcionesGraficaItem = [];
				opcionesGraficaItem.push({
					titulo: "5 Estrellas",
					cantidad: totalCincoEstrellas.length,
					porcentaje: (
						(totalCincoEstrellas.length / totalRespuestas.length) *
						100
					).toFixed(2),
				});

				opcionesGraficaItem.push({
					titulo: "4 Estrellas",
					cantidad: totalCuatroEstrellas.length,
					porcentaje: (
						(totalCuatroEstrellas.length / totalRespuestas.length) *
						100
					).toFixed(2),
				});

				opcionesGraficaItem.push({
					titulo: "3 Estrellas",
					cantidad: totalTresEstrellas.length,
					porcentaje: (
						(totalTresEstrellas.length / totalRespuestas.length) *
						100
					).toFixed(2),
				});

				opcionesGraficaItem.push({
					titulo: "2 Estrellas",
					cantidad: totalDosEstrellas.length,
					porcentaje: (
						(totalDosEstrellas.length / totalRespuestas.length) *
						100
					).toFixed(2),
				});

				opcionesGraficaItem.push({
					titulo: "1 Estrella",
					cantidad: totalUnoEstrellas.length,
					porcentaje: (
						(totalUnoEstrellas.length / totalRespuestas.length) *
						100
					).toFixed(2),
				});

				newArr.push(opcionesGraficaItem);
				//newArr[newArr.length - 1].push(opcionesGraficaItem)
				setOpcionesGrafica(newArr);

			} else if (calcularPreguntas.preguntas[i].type === "option") {
				var totalRespuestas = calcularPreguntas.respuesta.filter(
					(item) => item.question_id === calcularPreguntas.preguntas[i].idR
				);
				var j = 0;
				var opcionesGraficaItem = [];

				while (j < calcularPreguntas.preguntas[i].option.length) {
					var totalEstrellas = totalRespuestas.filter(
						(item) => item.response === calcularPreguntas.preguntas[i].option[j]
					);
					opcionesGraficaItem.push({
						titulo: calcularPreguntas.preguntas[i].option[j],
						cantidad: totalEstrellas.length,
						porcentaje: (
							(totalEstrellas.length / totalRespuestas.length) *
							100
						).toFixed(2),
					});
					++j;
				}

				newArr.push(opcionesGraficaItem);
				setOpcionesGrafica(newArr);
			} else {
				var totalRespuestas = calcularPreguntas.respuesta.filter(
					(item) => item.question_id === calcularPreguntas.preguntas[i].idR
				);
				var respuestaInfo = totalRespuestas.filter(
					(item) => item.response !== ""
				);
				newArr.push(respuestaInfo);
				//newArr[newArr.length - 1].push(opcionesGraficaItem)
				setOpcionesGrafica(newArr);
			}
			++i;
		}

		setModalVisible(true);
	};

	const onHandleSubmit = () => {
		if (!name) {
			toast.warn("Debe ingresar titulo de la encuesta");
			return;
		}

		//dispatch( setAnnouncements(data) );
		setIsModalVisiblePasoUno(false);
		if (listPreguntas.length === 0) {
			var listadoPreguntasDefault = [];
			listadoPreguntasDefault.push({
				idR: 0,
				question: "",
				type: "commentary",
				posicion: listadoPreguntasDefault.length,
			});
			setListPreguntas(listadoPreguntasDefault);
		}

		setIsModalVisiblePasoDos(true);
		//onResetForm();
	};

	const onHandleSubmitValidarPreguntas = async () => {
		var i = 0;
		while (i < listPreguntas.length) {
			var item = listPreguntas[i];
			if (item.question === "") {
				toast.warn("Debe ingresar la pregunta " + (i + 1));
				return;
			} else if (item.type === "option") {
				var j = 0;
				while (j < item.opciones.length) {
					var valor = item.opciones[j].valor;
					if (valor === "") {
						toast.warn(
							"Debe ingresar todas las opciones de la pregunta " + (i + 1)
						);
						return;
					}
					++j;
				}
			}
			++i;
		}

		//aqui
		setIsModalVisiblePasoDos(false);
		setIsModalVisiblePasoTres(true);
	};

	const onHandleSubmitValidar = async (eraser) => {
		var i = 0;
		while (i < listPreguntas.length) {
			var item = listPreguntas[i];
			if (item.question === "") {
				toast.warn("Debe ingresar la pregunta " + (i + 1));
				return;
			} else if (item.type === "option") {
				var j = 0;
				while (j < item.opciones.length) {
					var valor = item.opciones[j].valor;
					if (valor === "") {
						toast.warn(
							"Debe ingresar todas las opciones de la pregunta " + (i + 1)
						);
						return;
					}
					++j;
				}
			}
			++i;
		}

		if (eraser === false) {
			if (startDate === "") {
				toast.warn("Debe ingresar fecha de inicio de la encuesta ");
				return;
			}

			if (endDate === "") {
				toast.warn("Debe ingresar fecha de finalización de la encuesta ");
				return;
			}
		}
		setLoadSendData(true);
		var active = true;
		let data = {
			descripcion,
			name,
			tipoEncuesta,
			active,
			listPreguntas,
			listPreguntasEliminadas,
		};

		data.eraser = eraser;
		if (eraser === false) {
			data.startDate = startDate;
			data.endDate = endDate;
		}

		data.id = idBorrado;

		dispatch(setSurveys(data));
	};

	return (
		<>
			<div className="wrapper-page">
				<h2 className="title-page">Administración de encuestas</h2>

				<Row>
					<Col lg={10} sm={24}>
						<Button
							className="btn-primary-radio"
							onClick={() => {
								resetRangePicker();
								onResetForm();
								setIdBorrado("");
								setListPreguntas([]);
								setListPreguntasEliminadas([]);
								setIsModalVisiblePasoUno(true);
								$(".ant-picker-input :input").val("");
							}}
						>
							<img alt="add-plus-icon" src={shape20} />
							NUEVA ENCUESTA
						</Button>

						<Tabs style={{ marginTop: "10px" }} className="tabsMovil">
							<TabList>
								<Tab tabfor={0}>Finalizadas</Tab>
								<Tab tabfor={1}>En Borrador</Tab>
							</TabList>

							<TabPanel tabid={0}>
								<ul className="primary-card-list">
									{surveysList.map((survey, key) => {
										if (survey.eraser === false) {
											return (
												<li
													key={key}
													onClick={() => {
														handleActiveDetail(survey);
													}}
													className={
														surveysDetail == null
															? "primary-card-card"
															: surveysDetail.id === survey.id
																? "primary-card-card selectedSurvery"
																: "primary-card-card"
													}
												>
													<img
														className="icon"
														src={shape23}
														alt="chat-message-icon"
													/>

													<div className="primary-card-info">
														<span>ENCUESTA SOBRE:</span>
														<br />
														<h3>{survey.name}</h3>
													</div>

													<img
														className="shape-next"
														src={shape22}
														alt="rigth-arrow"
													/>
												</li>
											);
										}
									})}
								</ul>
							</TabPanel>
							<TabPanel tabid={1}>
								<ul className="primary-card-list">
									{surveysList.map((survey, key) => {
										if (survey.eraser === true) {
											return (
												<li
													key={key}
													onClick={() => {
														handleEdit(survey);
													}}
													className={
														surveysDetail == null
															? "primary-card-card"
															: surveysDetail.id === survey.id
																? "primary-card-card selectedSurvery"
																: "primary-card-card"
													}
												>
													<img
														className="icon"
														src={shape23}
														alt="chat-message-icon"
													/>

													<div className="primary-card-info">
														<span>ENCUESTA SOBRE:</span>
														<br />
														<h3>{survey.name}</h3>
													</div>

													<img
														className="shape-next"
														src={shape22}
														alt="right-arrow-icon"
													/>
												</li>
											);
										}
									})}
								</ul>
							</TabPanel>
						</Tabs>
					</Col>

					<Col lg={{ span: 12, offset: 2 }} sm={24}>
						{surveysDetail == null ? (
							<div></div>
						) : (
							<div className="primary-card-detail survey-detail">
								<div
									className="primary-card-section"
									style={{ lineHeight: "16px" }}
								>
									<h4>Encuesta sobre</h4>
									<h2>{surveysDetail.name}</h2>
									<span style={{ color: "#10B48C" }}>
										ID: {surveysDetail.id}
									</span>
								</div>

								<div className="primary-card-section">
									<Row style={{ display: "flex", alignItems: "center" }}>
										<Col
											lg={12}
											style={{ display: "flex", alignItems: "center" }}
										>
											<div className="answered-surveys">
												{surveysDetail.encuestasContestadas}
											</div>
											<h4 style={{ marginLeft: "10px" }}>
												Encuestas <br /> contestadas
											</h4>
										</Col>

										<Col lg={12} className="contCreacion">
											<h4>Creación:</h4>
											<p>{surveysDetail.created_at}</p>
										</Col>
									</Row>

									<Row
										style={{
											borderTop: "1px solid #ccc",
											borderBottom: "1px solid #ccc",
										}}
									>
										<Col lg={12}>
											<div style={{ paddingLeft: "50px" }}>
												<h4>Inicio</h4>
												<p style={{ fontWeight: "bold" }}>
													{surveysDetail.startDate}
												</p>
											</div>
										</Col>
										<Col lg={12}>
											<div style={{ paddingLeft: "50px" }}>
												<h4>Expiro</h4>
												<p style={{ fontWeight: "bold" }}>
													{surveysDetail.endDate}
												</p>
											</div>
										</Col>
									</Row>

									<Row
										style={{
											textAlign: "center",
											margin: "auto",
											display: "block",
										}}
									>
										<Button
											onClick={() => {
												mostrarEstadisticas();
											}}
											modalVisible
											className="btn-primary-radio"
										>
											<div style={{ display: "flex" }}>
												<img src={shape25} alt="eye-scanner-icon" />
												Ver resultados
												{loadMoreData ? (
													<div style={{ marginLeft: 20 }}>
														<Spinner />
													</div>
												) : null}
											</div>
										</Button>
									</Row>
								</div>
							</div>
						)}
					</Col>
				</Row>

				<Modal
					className="primary-modal"
					title="NUEVA ENCUESTA"
					visible={isModalVisiblePasoUno}
					onCancel={() => handleCancelPasoUno()}
					footer={null}
				>
					<form action="">
						<div className="pasos">PASO 1 DE 3</div>
						<div className="form-group">
							<label htmlFor="">TITULO DE LA ENCUESTA:</label>
							<input
								className="form-control"
								name="name"
								value={name}
								onChange={onInputChange}
							/>
						</div>

						<div className="form-group">
							<label htmlFor="">DESCRIPCIÓN CORTA:</label>
							<TextArea
								className="form-control"
								rows={6}
								name="descripcion"
								value={descripcion}
								onChange={onInputChange}
							/>
						</div>
					</form>

					<div className="footer-buttons">
						<Button
							// disabled={requests.isSaving}
							className="btn-primary"
							type="primary"
							shape="round"
							style={{ width: "150px" }}
							onClick={onHandleSubmit}
						>
							CONTINUAR
						</Button>
					</div>
				</Modal>

				<Modal
					className="primary-modal"
					title="NUEVA ENCUESTA"
					visible={isModalVisiblePasoDos}
					onCancel={() => handleCancelPasoDos()}
					footer={
						<Row>
							<Col span={6} style={{ textAlign: "left" }}>
								<Button
									className="btn-cancel"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setIsModalVisiblePasoDos(false);
										setIsModalVisiblePasoUno(true);
									}}
								>
									REGRESAR
								</Button>
							</Col>

							<Col span={12} style={{ textAlign: "center" }}>
								<Button
									// disabled={requests.isSaving}
									className="btn-borrador"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										onHandleSubmitValidar(true);
									}}
								>
									{loadSendData ? (
										<div className="centerSpinner">
											{" "}
											<Spinner />
										</div>
									) : (
										"GUARDAR EN BORRADOR"
									)}
								</Button>
							</Col>

							<Col span={6} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										onHandleSubmitValidarPreguntas();
									}}
								>
									CONTINUAR
								</Button>
							</Col>
						</Row>
					}
				>
					<form action="">
						<div className="pasos">PASO 2 DE 3</div>
						{listPreguntas.map((pregunta, key) => {
							return (
								<div className="form-group itemPregunta">
									<label htmlFor="" style={{ color: "#10B48C" }}>
										PREGUNTA #{pregunta.posicion + 1}&nbsp;&nbsp;&nbsp;&nbsp;
										<Select
											id={"id" + key}
											defaultValue={pregunta.type}
											style={{
												width: 200,
											}}
											onChange={(value) => {
												let newArr = [...listPreguntas]; // copying the old datas array
												newArr[key].type = value;
												if (value == "option") {
													newArr[key].opciones = [];
													newArr[key].opciones.push({ posicion: 0, valor: "" });
												} else {
													newArr[key].opciones = [];
												}

												setListPreguntas(newArr);
											}}
										>
											<Option value="qualify">
												<img
													alt="start-icon"
													style={{ paddingRight: "7px" }}
													src={shape34}
												/>
												De valoración
											</Option>
											<Option value="option">
												<img
													alt="multi-questions-icon"
													style={{ paddingRight: "7px" }}
													src={shape35}
												/>
												Opción múltiples
											</Option>
											<Option value="commentary">
												<img
													alt="comments-icon"
													style={{ paddingRight: "7px" }}
													src={shape36}
												/>
												Respuesta breve
											</Option>
										</Select>
										{key !== 0 ? (
											<img
												className="eliminarPregunta"
												onClick={() => {
													eliminarPregunta(pregunta.posicion);
												}}
												src={basura}
												alt="trash-icon"
											/>
										) : (
											<img
												className="eliminarPregunta"
												style={{ visibility: "hidden" }}
												src={basura}
												alt="trash-icon"
											/>
										)}
									</label>

									<input
										className="form-control"
										name="titulo"
										value={pregunta.question}
										onChange={(text) => {
											handleChange(text.target.value, key);
										}}
									/>

									{pregunta.type === "option" ? (
										<div>
											{pregunta.opciones.map((opcion, keyMain) => {
												return (
													<div className="contOpcion">
														<label>
															Opción #{opcion.posicion + 1}
															&nbsp;&nbsp;&nbsp;&nbsp;
															<input
																className="form-control"
																name="titulo"
																value={opcion.valor}
																onChange={(text) => {
																	//aquiiii
																	let newArr = [...listPreguntas];
																	newArr[key].opciones[opcion.posicion].valor =
																		text.target.value;
																	setListPreguntas(newArr);
																}}
															/>
															{keyMain !== 0 ? (
																<img
																	className="agregarPregunta"
																	onClick={() => {
																		eliminarOpcion(key, opcion.posicion);
																	}}
																	src={basura}
																	alt="trash-icon"
																/>
															) : (
																<img
																	className="agregarPregunta"
																	style={{ visibility: "hidden" }}
																	src={basura}
																	alt="trash-icon"
																/>
															)}
														</label>
													</div>
												);
											})}

											<div className="contOpcion">
												<img
													className="agregarPregunta"
													onClick={() => {
														agregarOpcion(key);
													}}
													src={botonAgregar}
													alt="add-question-icon"
												/>
											</div>
										</div>
									) : null}
								</div>
							);
						})}

						<div
							className="contPreguntaAgregar"
							onClick={() => {
								agregarPregunta();
							}}
						>
							<img
								className="agregarPregunta"
								src={botonAgregarSmall}
								alt="add-question-icon"
							/>
							<span>Agregar otra pregunta</span>
						</div>
					</form>
				</Modal>

				<Modal
					className="primary-modal"
					title="NUEVA ENCUESTA"
					visible={isModalVisiblePasoTres}
					onCancel={() => handleCancelPasoTres()}
					footer={
						<Row>
							<Col span={6} style={{ textAlign: "left" }}>
								<Button
									className="btn-cancel"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setIsModalVisiblePasoTres(false);
										setIsModalVisiblePasoDos(true);
									}}
								>
									REGRESAR
								</Button>
							</Col>

							<Col span={6} style={{ textAlign: "center" }}></Col>

							<Col span={12} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										onHandleSubmitValidar(false);
									}}
								>
									{loadSendData ? (
										<div className="centerSpinner">
											{" "}
											<Spinner />
										</div>
									) : (
										"PUBLICAR ENCUESTA"
									)}
								</Button>
							</Col>
						</Row>
					}
				>
					<form action="">
						<div className="pasos">PASO 3 DE 3</div>
						<div className="form-group">
							<label htmlFor="">TIPO DE ENCUESTA:</label>
							<Select
								id={"idTipo"}
								defaultValue={tipoEncuesta}
								style={{
									width: "100%",
								}}
								onChange={(value) => {
									setTipoEncuesta(value);
								}}
							>
								<Option value="obligatoria">Obligatoria</Option>
								<Option value="opcional">Opcional</Option>
							</Select>
						</div>

						<div className="form-group">
							<Row>
								<Col span={24} style={{ textAlign: "left" }}>
									<label htmlFor="">FECHA DE PUBLICACIÓN Y EXPIRACIÓN</label>
									<RangePicker
										value={dateRange}
										format={customFormat}
										locale={locale}
										style={{
											width: "100%",
										}}
										onChange={(date, dateString) => {
											onChangeFecha(date, dateString);
											setDateRange(date);
										}}
									/>
								</Col>
							</Row>
						</div>
					</form>
					{/* <Form
						ref={timeRef}
						name="time"
						initialValues={{
							range_picker: [null, null],
						}}
					>
						<div className="pasos">PASO 3 DE 3</div>
						<div className="form-group">
							<label htmlFor="">TIPO DE ENCUESTA:</label>
							<Select
								id={"idTipo"}
								defaultValue={tipoEncuesta}
								style={{
									width: "100%",
								}}
								onChange={(value) => {
									setTipoEncuesta(value);
								}}
							>
								<Option value="obligatoria">Obligatoria</Option>
								<Option value="opcional">Opcional</Option>
							</Select>
						</div>

						<div className="form-group">
							<Row>
								<Col span={24} style={{ textAlign: "left" }}>
									<label htmlFor="">FECHA DE PUBLICACIÓN Y EXPIRACIÓN</label>
									<Form.Item name="range_picker">
										<RangePicker
											format={customFormat}
											locale={locale}
											style={{
												width: "100%",
											}}
											onChange={onChangeFecha}
										/>
									</Form.Item>
								</Col>
							</Row>
						</div>
					</Form> */}
				</Modal>

				{surveysDetail == null ? null : (
					<Modal
						className="side-modal alert-modal modalEncuesta"
						bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
						title={
							<Row
								className="encabezado"
								style={{ textAlign: "left", paddingLeft: "25px" }}
							>
								<Col span={15}>
									<div>NOMBRE DE ENCUESTA</div>
									<h2>{surveysDetail.name}</h2>
									<Row>
										<Col span={8}>
											<strong>INICIO:</strong> {surveysDetail.startDate}
										</Col>
										<Col span={8}>
											<strong>FINALIZACIÓN:</strong> {surveysDetail.endDate}
										</Col>
										<Col span={8}>
											<strong>NÚMERO DE PREGUNTAS:</strong>{" "}
											{surveysDetail.preguntas.length}
										</Col>
									</Row>
								</Col>
								<Col span={3} style={{ display: "flex" }}>
									<div className="bloqueEncuesta">
										ENCUESTAS
										<br />
										CONTESTADAS:
										<div className="numeroEncuesta">
											{surveysDetail.encuestasContestadas}
										</div>
									</div>
								</Col>
								<Col span={3} style={{ display: "flex" }}>
									<div className="bloqueEncuesta">
										PORCENTAJE DE
										<br />
										EFECTIVIDAD:
										<div className="numeroEncuesta">
											{surveysDetail.encuestasContestadas > 0
												? (
													surveysDetail.encuestasContestadas /
													surveysDetail.allUserResidential
												).toFixed(2) * 100
												: "0"}{" "}
											%
										</div>
									</div>
								</Col>
								<Col span={3} style={{ display: "flex" }}>
									<div className="bloqueEncuesta">
										<div
											className="descargar"
											onClick={() => {
												descargarEncuesta();
											}}
										>
											<img alt="sheets-img" className="sheets" src={sheets} />
											EXPORTAR ENCUESTA
										</div>
									</div>
								</Col>
							</Row>
						}
						style={{ top: 0, right: 0, width: "100%" }}
						visible={modalVisible}
						onCancel={() => handleStatusModal(false)}
						footer={null}
						width={"95%"}
					>
						<div className="scroll">
							{surveysDetail && (
								<>
									{surveysDetail.preguntas.map((item, index) => {
										return (
											<div className="contPregunta">
												<div className="preguntaHeader">
													Pregunta {index + 1}
												</div>
												<div className="preguntaItem">{item.question}</div>
												{item.type === "qualify" || item.type === "option" ? (
													<div>
														{opcionesGrafica[index]?.map((itemP, index) => {
															return (
																<div className="itemRespuesta">
																	<div className="respuesta">
																		{itemP.titulo}
																	</div>
																	<div className="grafica">
																		{itemP.porcentaje === "NaN" ? null : (
																			<div
																				className="graficaVerde"
																				style={{
																					width: itemP.porcentaje + "%",
																				}}
																			></div>
																		)}
																	</div>
																	<div className="cantidad">
																		{itemP.cantidad} -
																		{itemP.porcentaje === "NaN" ? (
																			"0%"
																		) : (
																			<span className="porcentaje">
																				{itemP.porcentaje}%
																			</span>
																		)}
																	</div>
																</div>
															);
														})}
													</div>
												) : null}

												{item.type === "commentary" ? (
													<div>
														{opcionesGrafica[index]?.map((itemP, index) => {
															return (
																<div className="itemRespuesta">
																	<div className="respuestaCommentary">
																		{itemP.response}
																	</div>
																</div>
															);
														})}
													</div>
												) : null}
											</div>
										);
									})}
								</>
							)}
						</div>
					</Modal>
				)}
			</div>
		</>
	);
};
