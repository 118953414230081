import {
	collection,
	query,
	where,
	getDocs,
	doc,
	orderBy,
	arrayUnion,
} from "firebase/firestore";
import { FirebaseDB, FirebaseStorage } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import {
	serializeUserData,
} from "./globalHelper";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
	onCreateOrUpdate,
	onUpdateDocWithRef,
	onUpdateDocWithRefInfo,
} from "./globalHelper";

export const subirArchivoExcel = async (file) => {
	//const storageRef = FirebaseStorage.ref(`/excel/${file.name}`)

	const imageRef = ref(FirebaseStorage, "excel/" + file.name);
	uploadBytesResumable(imageRef, file)
		.then((snapshot) => {
			// Let's get a download URL for the file.
			getDownloadURL(snapshot.ref).then((url) => {
				// ...
			});
		})
		.catch((error) => {
			// ...
		});
};

export const updateInfoUser = async (data, id) => {
	try {
		await onUpdateDocWithRefInfo(`/profile/${id}`, data);
	} catch (error) {
	}
};

export const findNumber = async (phone) => {
	const collectionRef = collection(FirebaseDB, `/profile`);
	// const queryMessages = query(collectionRef, where("active", "==", true));
	const queryMessages = query(collectionRef, where("phone", "==", phone));

	const querySnapshot = await getDocs(queryMessages);

	var list = false;
	if (querySnapshot.size !== 0) {
		list = true;
	}

	return list;
};

export const subriCasa = async (data) => {
	try {
		// residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/3B0vUfiKpRYJmT8HYwWZ/houses/ Villa Tuscania Sur
		// /residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/lJL3PjszcPNAHXZMVaE5/houses/ Valle Tuscania Poligono A
		// /residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/1l7H9YULxC7m2Ruy5RPK poligono B

		const paymentTemplate = await onCreateOrUpdate(
			`/residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/mn4v2eDGxAqfRmr9SqwE/houses/`,
			data
		);
		var idPaymentTemplate = paymentTemplate.id;
	} catch (error) {
	}
};
export const relationHouse = async (house, idProfile) => {
	try {
		var dataSave = {};
		dataSave.idOwners = arrayUnion(idProfile);

		await onUpdateDocWithRefInfo(`/${house}`, dataSave);
	} catch (error) {
	}
};
export const saveHouse = async (data, idProfile) => {
	try {
		const collectionHouse = doc(FirebaseDB, data.house);

		var dataSave = data;
		dataSave.house = collectionHouse;

		var dataSend = {};
		dataSend.residentials = arrayUnion(dataSave);

		await onUpdateDocWithRefInfo(`/profile/${idProfile}`, dataSend);

	} catch (error) {
	}
};

export const saveUserTuscania = async (data, id) => {
	try {
		const collectionHouse = doc(FirebaseDB, data.residentials[0].house);

		var dataSave = data;
		dataSave.residentials[0].house = collectionHouse;

		await onUpdateDocWithRef(`/profile/${id}`, dataSave);
	} catch (error) {
	}
};

export const crearZonaTus = async (data) => {
	try {
		const paymentTemplate = await onCreateOrUpdate(
			`/residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/`,
			data
		);
		var idPaymentTemplate = paymentTemplate.id;
	} catch (error) {
	}
};

export const getUsersHelper = async (residential_id) => {
	const collectionRef = collection(FirebaseDB, `/profile`);
	// const queryMessages = query(collectionRef, where("active", "==", true));
	const queryMessages = query(
		collectionRef,
		//REVISAR BUG OBTIENE ID CDI
		where("residentialsIds", "array-contains", "Yd5MV7jkA0MjQeCmzKrZ"),
		orderBy("name")
		//,limit(1)
	);

	//const queryMessages = query(collectionRef);
	const querySnapshot = await getDocs(queryMessages);

	const list = [];
	querySnapshot.forEach(async (documment, index) => {
		let data = documment.data();
		let itemData = await serializeUserData(data, documment.id);


		if (data.residentials) {
			let userResidential = false;
			data.residentials.map((residential) => {
				if (residential.idResidential === "Yd5MV7jkA0MjQeCmzKrZ") {
					userResidential = true;
				}
			});

			if (userResidential) {
				list.push({
					id: documment.id,
					...itemData,
				});
			}
		}
	});
	return list;
};

export const getUsersWithMoraHelper = async (residential_id) => {
	const collectionRef = collection(FirebaseDB, `/profile`);
	// const queryMessages = query(collectionRef, where("active", "==", true));
	const queryMessages = query(
		collectionRef,
		//REVISAR BUG OBTIENE ID CDI
		where("residentialsIds", "array-contains", "Yd5MV7jkA0MjQeCmzKrZ"),
		where("mora", ">", "0"),
		orderBy("mora")
		//,limit(1)
	);

	//const queryMessages = query(collectionRef);
	const querySnapshot = await getDocs(queryMessages);

	const list = [];
	querySnapshot.forEach(async (documment, index) => {
		let data = documment.data();
		let itemData = await serializeUserData(data, documment.id);


		if (data.residentials) {
			let userResidential = false;
			data.residentials.map((residential) => {
				if (residential.idResidential === "Yd5MV7jkA0MjQeCmzKrZ") {
					userResidential = true;
				}
			});

			if (userResidential) {
				list.push({
					id: documment.id,
					...itemData,
				});
			}
		}
	});
	return list;
};

export const updateInfoUserAprobar = async (idProfile, uid, observaciones) => {
	var dataSave = {};
	dataSave.verified = true;
	dataSave.verifiedBy = uid;
	dataSave.observations = observaciones;

	dataSave.verifiedCreatedAt = moment(new Date(), "DD/MM/YYYY").toDate();

	await onUpdateDocWithRefInfo(`/profile/${idProfile}`, dataSave);
};

export const columnsUsersTable = () => {
	return [
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Teléfono",
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: "Perfil",
			dataIndex: "profile",
			key: "profile",
		},
		{
			title: "Actividad",
			dataIndex: "activity",
			key: "activity",
		},
	];
};
