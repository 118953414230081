import { useEffect } from "react"
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import CustomTabs from "../../../../UI/CustomTabs/CustomTabs"
import useMonitoringContrators from "../../hooks/useMonitoringContrators/useMonitoringContrators"
import useMonitoringContratorsTable from "../../hooks/useMonitoringContrators/useMonitoringContratorsTable"
import ContratorsDetailDrawer from "./ContratorsDetailDrawer"
import './styles.css'

export const MonitoringContrators = () => {

  const { visibleDrawer, handleOpenCloseDrawer } = useMonitoringContrators()
  const { tabItems, monitoringContratorsTable, handleChangeTabs, getInitialData } = useMonitoringContratorsTable()

  useEffect(() => {
    getInitialData({})
  }, []);

  return (
    <div className="section-wrapper">
      <CustomNavigate title={<>
        ADMINISTRACIÓN DE CONTRATISTAS
        <small style={{ fontWeight: 500, fontSize: 14 }}>CONTROL DE INGRESOS Y SALIDAS DE TRABAJADORES Y PROVEEDORES</small>
      </>} />
      <CustomTabs defaultActiveKey="active" className="custom-tabs-variant-1 monitoring-tabs" items={tabItems} onChange={handleChangeTabs} />
      <ContratorsDetailDrawer data={monitoringContratorsTable.active} visibleDrawer={visibleDrawer} handleOpenCloseDrawer={handleOpenCloseDrawer} />
    </div>
  )
}

export default MonitoringContrators