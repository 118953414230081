import React, { useEffect, useState } from "react";
import { Content, Footer } from "antd/lib/layout/layout";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import moment from "moment";

import FormItem from "antd/lib/form/FormItem";
import { MemberContainer } from "./components/MemberContainer";
import { getMembersHelper } from "../../../helpers/membersBoardHelper";
import { getUsersHelper } from "../../../helpers/usersHelper";
import { getRolesHelper } from "../../../helpers/roleHelper";
import {
	getUserDataProfileById,
	onCreateOrUpdate,
} from "../../../helpers/globalHelper";
import { plusIcon } from '../../../assets/icons'

const MembersBoardPage = () => {
	const { TabPane } = Tabs;
	const { TextArea } = Input;
	const [newMemberModalActive, setNewMemberModalActive] = useState(false);
	const [members, setMembers] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [allUsersOptions, setAllUsersOptions] = useState([]);
	const [selectedUser, setSelectedUser] = useState([]);
	const [allRoles, setAllRoles] = useState([]);
	const [allRolesOptions, setAllRolesOptions] = useState([]);
	const [selectedRole, setselectedRole] = useState([]);
	const [newRoleModalActive, setNewRoleModalActive] = useState(false);
	const [profileMembers, setProfileMembers] = useState([]);

	useEffect(() => {
		getAllData();
	}, []);

	const getAllData = () => {
		setMembers([]);
		getMembers();
		getAllUsers();
		getAllRoles();
	};

	useEffect(() => {
		const userOptions = allUsers.map((item, index) => {
			let fullName = item.name + " " + item.lastname;

			return (
				<Option key={index} value={index} label={fullName}>
					{fullName}
				</Option>
			);
		});
		const roleOptions = allRoles.map((item, index) => {
			const name = item.name;
			return (
				<Option key={index} value={index} label={name}>
					{name}
				</Option>
			);
		});
		setAllRolesOptions(roleOptions);
		setAllUsersOptions(userOptions);
	}, [allUsers]);

	/* useEffect(() => {
		getMembersProfiles(members);
	}, [members]);

	const getMembersProfiles = async (members) => {
		const mapData = members.map(async (item, index) => {
			let data = { ...item };
			if (data.profile_id) {
				const addData = await getUserDataProfileById(data.profile_id).then(
					(result) => {
						data.profileData = result;
					}
				);
			}
			return data;
		});

		Promise.all(mapData).then((data) => {
			setProfileMembers(data);
		});
	}; */

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const getAllUsers = async () => {
		const getUsers = await getUsersHelper("Yd5MV7jkA0MjQeCmzKrZ").then(
			(result) => setAllUsers(result)
		);
	};
	const getAllRoles = async () => {
		const getRoles = await getRolesHelper().then((result) =>
			setAllRoles(result)
		);
	};
	const getMembers = async () => {
		const allMembers = await getMembersHelper("Yd5MV7jkA0MjQeCmzKrZ").then(
			(result) => setMembers(result)
		);
	};

	const handleNewMemberAssignment = (statusModal, index = null) => {
		if (index != null) {
		}

		setTimeout(() => {
			setNewMemberModalActive(statusModal);
		}, 50);
	};
	const handleNewRoleAssignment = (statusModal, index = null) => {
		setTimeout(() => {
			setNewRoleModalActive(statusModal);
		}, 50);
	};

	const { Option } = Select;

	const handleUserOnChange = (index) => {
		setSelectedUser(allUsers[index]);
	};

	const handleRoleOnChange = (index) => {
		setselectedRole(allRoles[index]);
	};

	const onFinishNewMemberForm = async (e) => {
		const user = selectedUser;
		const role = selectedRole;

		const memberData = {
			active: true,
			admin: false,
			created_at: moment(new Date(), "DD/MM/YYYY").toDate(),
			lastname: user.lastname,
			name: user.name,
			position: role.id,
			position_name: role.name,
			residential_id: "Yd5MV7jkA0MjQeCmzKrZ",
			profile_id: user.id,
		};

		await onCreateOrUpdate("/committee/", memberData);

		handleNewMemberAssignment(false);
		getAllData();
	};

	const reloadPage = () => {
		/* navigate(`/reporte-actividad/${id}`, { replace: false, key: "test" }); */
		window.location = `/junta-directiva`;
	};

	const onFinishNewRoleForm = async (e) => {
		const roleName = e.name;
		const roleDescription = e.description;

		const data = {
			name: roleName,
			description: roleDescription,
		};

		const createRole = await onCreateOrUpdate("/committee_roles", data);

		handleNewRoleAssignment(false);
		getAllRoles();
	};

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={20}>
								<h2 className="title-page">Junta directiva</h2>
							</Col>
							<Col span={4} align="right">
								<div style={{ marginTop: "10px" }}>
									<Button
										style={{
											borderColor: "#FFFFFF",
											background: "#FFFFFF",
											width: "80%",
										}}
										onClick={() => {
											handleNewMemberAssignment(true);
										}}
										shape="round"
									>
										<img
											src={plusIcon}
											alt="add-plus-icon"
											style={{
												width: "26px",
												height: "26px",
												marginRight: "20px",
											}}
										/>
										<span
											className="title"
											style={{
												color: "#10b48c",
												fontSize: "13px",
												fontWeight: "500",
											}}
										>
											NUEVO MIEMBRO
										</span>
									</Button>
								</div>
							</Col>
						</Row>

						<Tabs className="primary-tabs" defaultActiveKey="1">
							<TabPane tab="Miembros" key="1">
								<Row>
									<Col span={24}>
										{members ? (
											<ul className="primary-card-list">
												{members.map((item, index) => {
													return (
														<li key={index}>
															<MemberContainer
																data={item}
																fullUsers={allUsers}
																userOptions={allUsersOptions}
																fullRoles={allRoles}
																roleOptions={allRolesOptions}
															/>
														</li>
													);
												})}
											</ul>
										) : null}
									</Col>
								</Row>
							</TabPane>
						</Tabs>
						{/* MODAL NUEVO MIEMBRO */}
						<Modal
							className="primary-modal"
							visible={newMemberModalActive}
							onCancel={() => handleNewMemberAssignment(false)}
							footer={null}
							width={550}
						>
							<>
								<Row className="section-modal" style={{ textAlign: "center" }}>
									<Col span={24}>
										<h2 style={{ color: "#232C4A" }}>NUEVO MIEMBRO DE JUNTA</h2>
									</Col>
								</Row>
								<Row className="section-modal" style={{ marginTop: "1rem" }}>
									<Col
										span={24}
										style={{
											width: "90%",
											paddingLeft: "2rem",
											paddingRight: "2rem",
										}}
									>
										<Form
											name="remember_user_form"
											onFinish={onFinishNewMemberForm}
										>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Seleccionar usuario:</p>
											</div>
											<FormItem name="user" {...config}>
												{/* <Input
													style={{
														backgroundColor: "#F2F2F2",
														borderRadius: "5px",
													}}
												/> */}
												{allUsersOptions ? (
													<Select
														placeholder="Buscar por nombre"
														style={{
															width: "100%",
														}}
														allowClear
														showSearch
														filterOption={(input, option) => {
															return (
																option.label
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															);
														}}
														onChange={(index) => {
															handleUserOnChange(index);
														}}
													>
														{allUsersOptions}
													</Select>
												) : null}
											</FormItem>

											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Seleccionar rol:</p>
											</div>

											<FormItem name="role" {...config}>
												{allRolesOptions ? (
													<Select
														placeholder="Buscar por rol"
														style={{
															width: "100%",
														}}
														allowClear
														showSearch
														filterOption={(input, option) => {
															return (
																option.label
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															);
														}}
														onChange={(index) => {
															handleRoleOnChange(index);
														}}
													>
														{allRolesOptions}
													</Select>
												) : null}
											</FormItem>

											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>
													¿No encuentra el rol? puede crearlo dando click en el
													siguiente botón
												</p>
											</div>
											<Row>
												<Col span={24} align="middle">
													<Button
														onClick={() => {
															handleNewRoleAssignment(true);
														}}
														modalvisible="true"
														shape="round"
														style={{ borderColor: "#10b48c" }}
													>
														<div
															style={{
																display: "flex",
																textAlign: "center",
																color: "#404040",
															}}
														>
															Crear rol
														</div>
													</Button>
												</Col>
											</Row>

											<br />
											<br />
											<Row>
												<Col span={24} align="middle">
													<Button
														htmlType="submit"
														style={{
															borderColor: "#10b48c",
															background: "#10b48c",
															paddingBottom: "15px",
															width: "250px",
														}}
														shape="round"
														size="large"
													>
														<span className="title" style={{ color: "#fff" }}>
															CREAR MIEMBRO
														</span>
													</Button>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							</>
						</Modal>

						{/* MODAL NUEVO CARGO */}
						<Modal
							className="primary-modal"
							visible={newRoleModalActive}
							onCancel={() => handleNewRoleAssignment(false)}
							footer={null}
							width={550}
						>
							<>
								<Row className="section-modal" style={{ textAlign: "center" }}>
									<Col span={24}>
										<h2 style={{ color: "#232C4A" }}>NUEVO ROL DE JUNTA</h2>
									</Col>
								</Row>
								<Row className="section-modal" style={{ marginTop: "1rem" }}>
									<Col
										span={24}
										style={{
											width: "90%",
											paddingLeft: "2rem",
											paddingRight: "2rem",
										}}
									>
										<Form
											name="remember_user_form"
											onFinish={onFinishNewRoleForm}
										>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>NOMBRE DEL ROL:</p>
											</div>

											<FormItem name="name" {...config}>
												<Input
													style={{
														width: "90%",
														backgroundColor: "#F2F2F2",
														borderRadius: "5px",
													}}
												/>
											</FormItem>

											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>DESCRIPCIÓN CORTA DE LAS FUNCIONES:</p>
											</div>
											<FormItem name="description" {...config}>
												<TextArea
													rows={4}
													style={{
														backgroundColor: "#F2F2F2",
														borderRadius: "5px",
													}}
												/>
											</FormItem>
											<Row>
												<Col span={24} align="middle">
													<Button
														htmlType="submit"
														style={{
															borderColor: "#10b48c",
															background: "#10b48c",
															paddingBottom: "15px",
															width: "250px",
														}}
														shape="round"
														size="large"
													>
														<span className="title" style={{ color: "#fff" }}>
															CREAR ROL
														</span>
													</Button>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							</>
						</Modal>
					</div>
				</Content>
			</Layout>
		</>
	);
};

export default MembersBoardPage;
