import React from "react";
import {
	Col,
	Modal,
	Row,
	Table,
	Button,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setUserActive,
} from "../../../../store/users/usersSlice";
import {
	getUsersAprobar,
	guardarUser,
} from "../../../../store/users/thunks";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import { getColumnSearchProps } from "../../../../utils/tables";
import { getQueryDocByCollection } from "../../../../helpers/globalHelper";
import { getDataByNomenclatureIdAndHouseIdHelper } from "../../../../helpers/nomenclaturasHelper";
import UserProfileModal from "../../../../components/UserProfileModal";
import { shape08, shape25 } from "../../../../assets/images";
import { shape15 } from "../../../../assets/icons";

export const UsersTab = () => {
	const [usersList, setUsersList] = useState([]);
	const dispatch = useDispatch();
	const { users } = useSelector((state) => state.users);
	const [userModalActive, setUserModalActive] = useState(false);
	// const [visitorModalActive, setVisitorModalActive] = useState(false);
	const [dataExcelCuotas, setDataExcelCuotas] = useState(null);
	const [dataExcelContactos, setDataExcelContactos] = useState(null);
	const [modalExcelCuotas, setModalExcelCuotas] = useState(null);
	const [modalExcelContactos, setModalExcelContactos] = useState(null);
	const [sortedInfo, setSortedInfo] = useState({});
	const [filteredInfo, setFilteredInfo] = useState({});

	const IconButtonReport = () => <img src={shape25} alt="eye-scanner-icon" />;

	const handleChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters);
		setSortedInfo(sorter);
	};

	const [userActive, seUserActive] = useState(null);

	useEffect(() => {
		dispatch(getUsersAprobar());
	}, [dispatch]);

	useEffect(() => {
		seUserActive(users.active);
		if (!users.active) {
			handleStatusResidentsModal(false);
		}
	}, [users.active]);

	useEffect(() => {
		let _data = users.data.map((item, index) => {
			let data = { ...item };
			data.profile = (
				<img
					className="iconPerfil"
					src={shape08}
					style={{ cursor: "pointer" }}
					alt="credential-security-icon"
					onClick={() => handleStatusResidentsModal(true, index)}
				/>
			);
			data.activity = (
				<Link to={`/reporte-actividad/${data.id}`}>
					<img
						src={shape15}
						style={{ cursor: "pointer" }}
						alt="blue-report-icon"
					/>
				</Link>
			);

			return data;
		});

		setUsersList(_data);
	}, [users.data]);

	const handleStatusResidentsModal = async (statusModal, index = null) => {
		if (index !== null) {
			let active_user = users.data[index];
			if (active_user.ownerHome) {
				// Agregamosal detalle los dependientes de los socios
				let relativesWithAccess = await getQueryDocByCollection(
					"/family_access",
					"idProfileMain",
					"==",
					active_user.id
				);
				
				let nomenclaturePath = active_user.residentials[0]?.house["path"];
				nomenclaturePath = nomenclaturePath.substring(47, 67);
				let housePath = active_user.residentials[0]?.house["path"];
				housePath = housePath.substring(75, 95);

				let houseData = await getDataByNomenclatureIdAndHouseIdHelper(
					"Yd5MV7jkA0MjQeCmzKrZ",
					nomenclaturePath,
					housePath
				); /* .then((houseD) => {
					active_user.houseData = houseD;
				}); */
				active_user = {
					...active_user,
					houseData: houseData,
					relativesWithAccess: relativesWithAccess,
				};
			}
			dispatch(setUserActive(active_user));
		} else {
			dispatch(setUserActive(null));
		}

		setTimeout(() => {
			setUserModalActive(statusModal);
		}, 50);

		/* setTimeout(() => {
			users.data[index].residentials
				? setUserModalActive(statusModal)
				: setVisitorModalActive(statusModal);
		}, 50); */
	};

	// const asignarResidenciales = async () => {
		//obtener residenciales
				/*for( var z = 0; z < usersList.length; z++){
                        var residentials =  usersList[z].residentials
            for( var j = 0; j < residentials.length; j++){
              //if(z == 0){
                dispatch( relacionarResidencial(residentials[j].house.path,usersList[z].id) )
              //}
             

            }
            
        }*/
	// };

	const onChange = (e) => {
		const [file] = e.target.files;
		// const fileSubir = e.target.files[1];

		/*var dataZona = {
        active: true,
        created_at:moment(new Date(), 'DD/MM/YYYY').toDate(),
        end_house: 3,
        name:"Valle Tuscania Poligono I",
        number_residential: 3,
        start_house: 1,
        updated_at: moment(new Date(), 'DD/MM/YYYY').toDate()
      }

      dispatch( crearZonaTuscania(dataZona) )*/

		//dispatch( subirArchivo(fileSubir) );

		const reader = new FileReader();

		reader.onload = (evt) => {
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: "binary" });

			//segunda hoja
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

			//registro de casa
			/*var dataCasa = []
        var c = 1;
        var casaAnterior = ""
        while (c < data.length) {
          
          var item = data[c]
          if(item != ""){
            if(casaAnterior != item[2]){
              var dataCasa = {
                active: true,
                name: item[2]+"",
                id_profile_titular: "",
                titular_name: "",
                use_titular: "",
                address: item[13] == undefined ? "" : item[13],
                areaM2: item[15] == undefined ? 0 : item[15],
                areaV2: item[16] == undefined ? 0 : item[16],
                category: item[17] == undefined ? 0 : item[17],
                maintenanceFee: item[18] == undefined ? 0 : item[18],
                constructionFee: item[19] == undefined ? 0 : item[19],
                residenceFee: item[20] == undefined ? 0 : item[20],
                total: item[22] == undefined ? 0 : item[22],
                alcaldia: item[21] == undefined ? 0 : item[21],
                observations: item[23] == undefined ? "" : item[23],
              }

              
              dispatch( guardarCasa(dataCasa) )
            }else{
            }

            casaAnterior = item[2]
            
          }
          
          ++c;
        }*/

			//registro de casa hoja 2
			/*var dataCasa = []
        var c = 1;
        var casaAnterior = ""
        var contadorPoligonos = 1

        while (c < data.length) {
          
          var item = data[c]
          if(item != ""){
            if(casaAnterior != item[1]){

              if(item[0] == "poligono"){
                contadorPoligonos++
              }

              if(contadorPoligonos == 10){
                var dataCasa = {
                  active: true,
                  name: item[1]+"",
                  id_profile_titular: "",
                  titular_name: "",
                  use_titular: "",
                  address: item[13] == undefined ? "" : item[13],
                  areaM2: item[15] == undefined ? 0 : item[15],
                  areaV2: item[16] == undefined ? 0 : item[16],
                  category: item[17] == undefined ? 0 : item[17],
                  maintenanceFee: item[18] == undefined ? 0 : item[18],
                  constructionFee: item[19] == undefined ? 0 : item[19],
                  residenceFee: item[20] == undefined ? 0 : item[20],
                  total: item[22] == undefined ? 0 : item[22],
                  alcaldia: item[21] == undefined ? 0 : item[21],
                  observations: item[23] == undefined ? "" : item[23],
                }
  
                                dispatch( guardarCasa(dataCasa) )
              }

            }else{
            }

            casaAnterior = item[1]
            
          }
          
          ++c;
        }*/

			
			//registro User
			var dataUser = [];
			var j = 1;
			var contadorPoligonos = 1;
			while (j < data.length) {
								var item = data[j];
				if (item !== "") {
					/*if(item[0] != undefined){
              //villa
              var userSave ={
                active: true,
                area: 503,
                code: "SV",
                created_at:moment(new Date(), 'DD/MM/YYYY').toDate(),
                updated_at:moment(new Date(), 'DD/MM/YYYY').toDate(),
                email: item[10] == undefined ? "" : item[10],
                emailTwo: item[11] == undefined ? "" : item[11],
                image: "",
                imageDUI: "",
                mora: 0,
                name: item[2],
                lastname: item[3] == undefined ? "" : item[3],
                phone: item[6] == undefined ? "" : item[6]+"",
                phoneTwo: item[7] == undefined ? "" : item[7]+"",
                phoneThree: item[8] == undefined ? "" : item[8]+"",
                phoneFour: item[9] == undefined ? "" : item[9]+"",
                residentials: [{homePrincipal:true,house:"/residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/3B0vUfiKpRYJmT8HYwWZ/houses/"+item[23],idResidential:"Yd5MV7jkA0MjQeCmzKrZ",type:item[4] == undefined ? "" : item[4]}],
                residentialsIds: ["Yd5MV7jkA0MjQeCmzKrZ"],
                stateDUI: 0,
                tokenUserTas: "",
                verified: true,
  
              }

              dispatch( guardarUser(userSave,item[0]) )
            }*/

					//valle

					if (item[0] !== undefined) {
						if (item[0] === "poligono") {
							contadorPoligonos++;
						}

						if (contadorPoligonos === 10) {
							var userSave = {
								active: true,
								area: 503,
								code: "SV",
								created_at: moment(new Date(), "DD/MM/YYYY").toDate(),
								updated_at: moment(new Date(), "DD/MM/YYYY").toDate(),
								email: item[11] === undefined ? "" : item[11],
								emailTwo: item[12] === undefined ? "" : item[12],
								image: "",
								imageDUI: "",
								mora: 0,
								name: item[3],
								lastname: item[4] === undefined ? "" : item[4],
								phone: item[7] === undefined ? "" : item[7] + "",
								phoneTwo: item[8] === undefined ? "" : item[8],
								phoneThree: item[9] === undefined ? "" : item[9],
								phoneFour: item[10] === undefined ? "" : item[10],
								residentials: [
									{
										homePrincipal: true,
										house:
											"/residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/mn4v2eDGxAqfRmr9SqwE/houses/" +
											item[24],
										idResidential: "Yd5MV7jkA0MjQeCmzKrZ",
										type: item[5] === undefined ? "" : item[5],
									},
								],
								residentialsIds: ["Yd5MV7jkA0MjQeCmzKrZ"],
								stateDUI: 0,
								tokenUserTas: "",
								verified: true,
							};

														dispatch(guardarUser(userSave, item[0]));
						}
					}

										//if(j == 1){
					//dispatch( guardarUser(userSave,item[0]) )
					//}
				}

				++j;
			}

			/*var dataContactos = []
        var i = 1;
        while (i < data.length) {
                    var item = data[i]
          if(item != ""){
            dataContactos.push({
              idUser: item[0],
              lote: item[1],
              poligono: item[2],
              propietario: item[3],
              comentario: item[4],
              telefono: item[5],
              celular: item[6],
              email: item[7],
              address: item[8],
            })
          }
          
          ++i;
        }*/

			
			//primera hoja
			/*const wsnamePrimero = wb.SheetNames[0];
      const wsPrimero = wb.Sheets[wsnamePrimero];
      const dataPrimero = XLSX.utils.sheet_to_json(wsPrimero, { header: 1 });
      var dataCuotas = []
      var j = 2;
      while (j < dataPrimero.length) {
        var item = dataPrimero[j]
        if(item != ""){
          dataCuotas.push({
            comentarios: item[0],
            nombreCliente: item[2],
            dui: item[3],
            nit: item[4],
            firmaCompraventa: item[5],
            terreno: item[6],
            poligono: item[7],
            aream: item[8],
            areav: item[9],
            categoria: item[10],
            cuotaMantenimiento: item[11],
            cuotaConstruccion: item[12],
            cuotaResidencia: item[13],
            tasaRecoleccion: item[14],
            total: item[15]
          })
        }
        
        ++j;
      }*/

			//setDataExcelContactos(dataContactos)
			//setDataExcelCuotas(dataCuotas)
			//setModalExcelContactos(true)
			e.target.value = null;

			//dataExcelContactos
		};
		reader.readAsBinaryString(file);
	};

	const columnsUsuarios = [
		{
			title: "Id User",
			dataIndex: "idUser",
			key: "idUser",
		},
		{
			title: "No. Lote",
			dataIndex: "lote",
			key: "lote",
		},
		{
			title: "Nombre",
			dataIndex: "propietario",
			key: "propietario",
		},
		{
			title: "Apellido",
			dataIndex: "apellido",
			key: "apellido",
		},
		{
			title: "Tipo",
			dataIndex: "tipo",
			key: "tipo",
		},
		{
			title: "Calificacion",
			dataIndex: "calificacion",
			key: "calificacion",
		},
		{
			title: "Celular 1",
			dataIndex: "celularUno",
			key: "celularUno",
		},
		{
			title: "Celular 2",
			dataIndex: "celularDos",
			key: "celularDos",
		},
		{
			title: "Telefono 1",
			dataIndex: "telefonoUno",
			key: "telefonoUno",
		},
		{
			title: "Telefono 2",
			dataIndex: "telefonoDos",
			key: "telefonoDos",
		},
		{
			title: "E-mail",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "E-mail 1",
			dataIndex: "emailDos",
			key: "emailDos",
		},
		{
			title: "Dirección",
			dataIndex: "address",
			key: "address",
		},
	];

	const columnsCuotas = [
		{
			title: "Comentarios",
			dataIndex: "comentarios",
			key: "comentarios",
		},
		{
			title: "Nombre del Cliente",
			dataIndex: "nombreCliente",
			key: "nombreCliente",
		},
		{
			title: "DUI",
			dataIndex: "dui",
			key: "dui",
		},
		{
			title: "NIT",
			dataIndex: "nit",
			key: "nit",
		},
		{
			title: "Fecha firma de compraventa",
			dataIndex: "firmaCompraventa",
			key: "firmaCompraventa",
		},
		{
			title: "No Terreno",
			dataIndex: "terreno",
			key: "terreno",
		},
		{
			title: "Poligono",
			dataIndex: "poligono",
			key: "poligono",
		},
		{
			title: "Area (M2)",
			dataIndex: "aream",
			key: "aream",
		},
		{
			title: "Area (V2)",
			dataIndex: "areav",
			key: "areav",
		},
		{
			title: "Categoría",
			dataIndex: "categoria",
			key: "categoria",
		},
		{
			title: "Cuota de mantenimiento solo terreno",
			dataIndex: "cuotaMantenimiento",
			key: "cuotaMantenimiento",
		},
		{
			title: "Cuota de mantenimiento construccion",
			dataIndex: "cuotaConstruccion",
			key: "cuotaConstruccion",
		},
		{
			title: "Cuota de mantenimiento con residencia",
			dataIndex: "cuotaResidencia",
			key: "cuotaResidencia",
		},
		{
			title: "Tasa recolección alcaldia Zaragoza",
			dataIndex: "tasaRecoleccion",
			key: "tasaRecoleccion",
		},
		{
			title: "Total cobrar",
			dataIndex: "total",
			key: "total",
		},
	];

	const getUserName = () => {
		const localities = [];
		usersList.map((d) => {
			localities.push({
				text: d.name,
				value: d.name,
			});
		});
		return localities;
	};

	const columns = [
		/* {
			title: "ID Socio",
			dataIndex: "origin_fam",
			key: "origin_fam",
			width: "15%",
			...getColumnSearchProps("origin_fam"),
		}, */
		{
			title: "Nombre Completo",
			dataIndex: "name",
			key: "name",
			width: "50%",
			render: (text, record) => (
				<>
					{record.name} {record.lastname}
				</>
			),
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("name"),
			//filters: getUserName(),
			//filteredValue: filteredInfo.name || null,
			//onFilter: (value, record) => record.name.includes(value),
			//sorter: (a, b) => a.name.length - b.name.length,
			//sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
			//ellipsis: true,
		},
		/* {
			title: "Residencia",
			dataIndex: "address",
			key: "address",
			width: "15%",
		}, */
		{
			title: "Teléfono",
			dataIndex: "phone",
			key: "phone",
			width: "20%",
		},
		{
			title: "Perfil",
			dataIndex: "profile",
			key: "profile",
			width: "15%",
		},
		{
			title: "Actividad",
			dataIndex: "activity",
			key: "activity",
			width: "15%",
		},
	];

	return (
		<>
			<div className="contSubir">
				<span>Subir Información</span>
				<input
					type="file"
					accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					onChange={onChange}
				/>
			</div>
			<div>
				<Table
					pagination={{ pageSize: 100, simple: true }}
					scroll={{ y: "calc(100vh - 19em)" }}
					className="primary-table"
					onChange={handleChange}
					columns={columns}
					dataSource={usersList}
				/>
			</div>

			{/* <VisitorProfileModal
				userActive={userActive}
				userModalActive={visitorModalActive}
				handleStatusResidentsModal={handleStatusResidentsModal}
			/> */}

			<UserProfileModal
				userActive={userActive}
				userModalActive={userModalActive}
				handleStatusResidentsModal={handleStatusResidentsModal}
			/>

			{dataExcelContactos == null ? null : (
				<Modal
					className="side-modal alert-modal modalEncuesta"
					bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
					visible={modalExcelContactos}
					title={
						<Row
							className="encabezado"
							style={{ textAlign: "left", paddingLeft: "25px" }}
						>
							<Col span={20}>
								<div>USUARIOS A REGISTRAR</div>
							</Col>
						</Row>
					}
					style={{ top: 0, right: 0, width: "100%" }}
					onCancel={() => setModalExcelContactos(false)}
					footer={
						<Row className="widthFooter">
							<Col span={6} style={{ textAlign: "left" }}>
								<Button
									className="btn-cancel"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setModalExcelContactos(false);
									}}
								>
									CANCELAR
								</Button>
							</Col>

							<Col span={12} style={{ textAlign: "center" }}></Col>

							<Col span={6} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setModalExcelContactos(false);
										setModalExcelCuotas(true);
									}}
								>
									SIGUIENTE
								</Button>
							</Col>
						</Row>
					}
					width={"95%"}
				>
					<div style={{ height: "85vh" }}>
						<Table
							className="primary-table"
							columns={columnsUsuarios}
							dataSource={dataExcelContactos}
							pagination={{ defaultPageSize: 10 }}
						/>
					</div>
				</Modal>
			)}

			{dataExcelCuotas == null ? null : (
				<Modal
					className="side-modal alert-modal modalEncuesta"
					bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
					visible={modalExcelCuotas}
					title={
						<Row
							className="encabezado"
							style={{ textAlign: "left", paddingLeft: "25px" }}
						>
							<Col span={20}>
								<div>CUOTAS</div>
							</Col>
						</Row>
					}
					style={{ top: 0, right: 0, width: "100%" }}
					onCancel={() => setModalExcelCuotas(false)}
					footer={
						<Row className="widthFooter">
							<Col span={6} style={{ textAlign: "left" }}>
								<Button
									className="btn-cancel"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setModalExcelCuotas(false);
										setModalExcelContactos(true);
									}}
								>
									REGRESAR
								</Button>
							</Col>

							<Col span={12} style={{ textAlign: "center" }}></Col>

							<Col span={6} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setModalExcelCuotas(false);
										setModalExcelContactos(false);
										setDataExcelContactos(null);
										setDataExcelCuotas(null);
										toast.success("Datos enviados");
									}}
								>
									GUARDAR
								</Button>
							</Col>
						</Row>
					}
					width={"100%"}
				>
					<div style={{ height: "85vh" }}>
						<Table
							className="primary-table"
							columns={columnsCuotas}
							dataSource={dataExcelCuotas}
							pagination={{ defaultPageSize: 10 }}
						/>
					</div>
				</Modal>
			)}
		</>
	);
};
