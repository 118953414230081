import { CustomDatePicker } from "../../../../../../UI/CustomDatePicker/CustomDatePicker"
import { CustomInput } from "../../../../../../UI/CustomInput"
import { Form, Row, Col, Button } from 'antd'
import CustomModal from "../../../../../../UI/CustomModal"

//Componente para poder renderizar un modal para colorcar información para una nueva cotización en la solicitud de reserva y lo envía como un formulario
const NewCotizationModal = ({
  form,
  handleOpenCloseModal,
  onSubmit,
  showModal,
  loading,
  bookingDetailsData,
}) => {
  const config = [
    { required: true, message: 'El campo es necesario' }
  ]

  return (
    <CustomModal
      width={905}
      className="custom-modal cotization-booking"
      destroyOnClose={true}
      title={<span style={{ width: '100%', display: 'block', textAlign: 'center', fontSize: 20, fontWeight: 600 }}>
        CREAR COTIZACIÓN</span>
      }
      onCancel={() => handleOpenCloseModal({ name: 'cotizationModal', status: false })}
      isOpenModal={showModal}
    >
      <Form layout="vertical" name="cotization-booking" form={form} onFinish={(values) => onSubmit({ values, name: 'cotization' })}>
        <Row gutter={40}>
          <Col span={12}>
            <Form.Item rules={[...config]} label={'COSTO POR USO DE AMENIDAD'} name={'reservation_invitee_cost'}>
              <CustomInput style={{ height: 'auto', width: '100%' }} precision={2} prefix="$" type="NUMBER" min={0} step={0.01} />
            </Form.Item>
            <ul /**Se muestra los datos de espacios e invitados que salen en el detalle de la solicitud de reserva */>
              <li>
                <strong>ESPACIOS:</strong> <span>{bookingDetailsData?.SPECIFICATIONS?.reservation_places?.join(', ')}</span>
              </li>
              <li>
                <strong>INVITADOS:</strong> <span>{bookingDetailsData?.EVENT?.reservation_invitee_quantity}</span>
              </li>
            </ul>
          </Col>
          <Col span={12}>
            <Form.Item rules={[...config]} label={'COSTO POR MOBILIARIO'} name={'reservation_forniture_cost'}>
              <CustomInput style={{ height: 'auto', width: '100%' }} precision={2} prefix="$" type="NUMBER" min={0} step={0.01} />
            </Form.Item>
            <ul /**Se muestra los datos de mobiliario que sale en el detalle de la solicitud de reserva */>
              <li style={{ color: '#10B48C' }}>
                <strong>MOBILIARIO EXTRA:</strong>
                {bookingDetailsData?.SPECIFICATIONS?.forniture_items.length !== 0
                  ? <ul>
                    {bookingDetailsData?.SPECIFICATIONS?.forniture_items?.map((forniture, index) => (
                      <li key={`${forniture?.name}-${index}`}>
                        <span>{forniture?.quantity}</span>
                        {' '}
                        <span>{forniture?.name}</span>
                      </li>
                    ))}
                  </ul>
                  : <span>{' '}No hay mobilario extra agregado</span>
                }
              </li>
            </ul>
          </Col>
          <Col span={24}>
            <Form.Item rules={[...config]} label={'COSTO POR VIGILANCIA'} name={'reservation_security_guard_cost'}>
              <CustomInput style={{ height: 'auto', width: '100%' }} precision={2} prefix="$" type="NUMBER" min={0} step={0.01} />
            </Form.Item>
          </Col>
          {bookingDetailsData?.EVENT?.reservation_payment_method_code === "tuscaniaPaymentLink"
            ? <Col span={24}>
              <Form.Item rules={[...config]} label={'LINK DE PAGO'} name={'payment_link'}>
                <CustomInput type="TEXT" />
              </Form.Item>
            </Col>
            : <Col span={24}>
              <span style={{ fontSize: 12 }}>TIPO DE PAGO:</span>
              {' '}
              <span style={{ fontSize: 12, textTransform: 'uppercase', color: '#10B48C', fontWeight: 500 }}>
                {bookingDetailsData?.EVENT?.reservation_payment_method}
              </span>
            </Col>
          }
          <Col span={12} style={{ marginTop: bookingDetailsData?.EVENT?.reservation_payment_method_code !== "tuscaniaPaymentLink" ? 25 : 0 }}>
            <Form.Item rules={[...config]} label={'FECHA MÁXIMA DE PAGO'} name={'payment_deadline'}>
              <CustomDatePicker disabledDateBeforeToday={true} height={'40px'} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={'REQUISITOS U OTRAS INDICAIONES'} name={'comment'}>
              <CustomInput type="AREA" autoSize={{ minRows: 4 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" justify="center">
          <Button loading={loading} className="btn-standard-green" shape="round" htmlType="submit"> ENVIAR COTIZACIÓN</Button>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewCotizationModal
