import { axiosCreate } from "../../../../../config";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const BOOKING_CANCELED_STATUS_PATH = 'reservation/status/canceled'

//Endpoint de topia con el que se cambia el estado de una reserva a cancelado enviando adicionalmente un comentario
export const updateCancelStatusBooking = async ({
    token,
    formData
}) => {

    try {
                
        const res = await axiosCreate.put(`${BOOKING_CANCELED_STATUS_PATH}`, formData, {
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }

}