export const uniqueItems = ({ array = [], prop = 'id' }) => {
    const uniqueItems = []
    const uniquesIds = new Set()
    array.forEach(item => {
        if (!item[prop]) {
            uniqueItems.push(item)
        }
        if (item[prop] && !uniquesIds.has(item.id)) {
            uniquesIds.add(item[prop])
            uniqueItems.push(item)
        }
    })
    return uniqueItems
}