import { Upload, Typography } from 'antd'
import useUploadFile from './hooks/useUploadFile'
import NoFiles from './components/NoFiles'
import FilesList from './components/FilesList'
import './styles.css'

const { Dragger } = Upload
const { Text } = Typography

export const CustomUploadFile = ({ onChange, value, width = 396, maxSizeInBytes = (5 * 1024 * 1024), multipleFiles = false, verticalUpload = true, maxCount = 5, justImages = false, height = 400, minHeight = 320, fileList, setFileList, acceptFilesFormat }) => {

    const { uploadElementRef, acceptFiles, acceptFilesText, handleOnChange, handleOpenDialogFile, handleRemoveFile, handleTextChange, itemRender, getFileSize } = useUploadFile({ fileList, setFileList, justImages, maxCount, maxSizeInBytes, multipleFiles, acceptFilesFormat })

    return (
        <Dragger
            name='files'
            style={{ maxHeight: height, width, minHeight, height }}
            accept={acceptFiles}
            fileList={fileList}
            beforeUpload={() => { return false }}
            onChange={handleOnChange}
            showUploadList={false}
            className='custom-upload-file'
            multiple={multipleFiles}
            openFileDialogOnClick={false}
        >
            {
                !fileList ? <Text>SE NECESITA UN ESTADO AL MENOS CON ARRAY VACIO </Text> :

                    <div style={{ flexDirection: verticalUpload ? 'column' : 'row', gap: 16 }}>
                        <input // Input escondido que realmente abre el diálogo de selección de archivos
                            type="file"
                            multiple={multipleFiles}
                            ref={uploadElementRef}
                            style={{ display: 'none' }}
                            onChange={handleOnChange}
                            accept={acceptFiles}
                        />
                        {
                            fileList?.length ? <FilesList itemRender={itemRender} handleOpenDialogFile={handleOpenDialogFile} handleRemoveFile={handleRemoveFile} handleTextChange={handleTextChange} justImages={justImages} getFileSize={getFileSize} maxSizeInBytes={maxSizeInBytes} multipleFiles={multipleFiles} fileList={fileList} verticalUpload={verticalUpload} /> :
                                <NoFiles handleOpenDialogFile={handleOpenDialogFile} maxSizeInBytes={maxSizeInBytes} acceptFilesText={acceptFilesText} justImages={justImages} getFileSize={getFileSize} />
                        }
                    </div>
            }
        </Dragger>
    )
}

export default CustomUploadFile