import { Select } from "antd";
import { useSelect } from "../hooks/useCustomSelect";

export const CustomSelect = ({
  id,
  svgIcon = false,
  value,
  dataName,
  onChange,
  onSelect,
  onSearch,
  defaultValue,
  suffixIcon,
  placeholder,
  onPopupScroll,
  options = [],
  width = "100%",
  disabled,
  loading,
  allowClear,
  showSearch = true,
  className,
  propsFilter,
  filters = true,
  service,
  isAsync = false,
  setCustomData,
  customData,
  itemsCount,
  delaySearch,
}) => {
  const { onMoreSelectItems, onSearchItems, selectLoading, customSelectData } = useSelect({ service, setCustomData, itemsCount, delaySearch })

  const selectOptions = [
    ...options?.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
      disabled: item?.disabled || null,
      selected: item?.selected || null,
    })),
  ];

  //Funcion que aplica solo en las tablas de antDesign para los filtros internos que requieran select
  const onChangeTableFilter = (data) => {
    //Llamar aqui al servicio para buscar datos?
    if (!data) return
    propsFilter.setSelectedKeys([data]);
    propsFilter.handleSearch(propsFilter.confirm)();
  };

  return (
    <>
      <Select
        id={id}
        onPopupScroll={(e) => isAsync ? onMoreSelectItems({ e }) : onPopupScroll({ e, dataName })}
        onSearch={showSearch ? (value) => isAsync ? onSearchItems({ value }) : (onSearch ? onSearch({ value, dataName }) : undefined) : undefined}
        onClear={propsFilter ? async () => {
          propsFilter.handleReset(propsFilter.confirm, propsFilter.clearFilters)
          if (isAsync) {
            await onSearchItems({ value: '' })
          }
        } : () => { }}
        allowClear={allowClear}
        className={className || "custom-select"}
        loading={isAsync ? selectLoading : loading}
        disabled={disabled}
        value={propsFilter ? propsFilter.selectedKeys : value}
        defaultValue={propsFilter ? propsFilter.selectedKeys : defaultValue ? defaultValue : value}
        suffixIcon={
          suffixIcon && !svgIcon ? <img alt="suffix-icon" src={suffixIcon} /> : suffixIcon && svgIcon ? suffixIcon : undefined
        }
        showSearch={showSearch}
        style={{
          width,
        }}
        placeholder={placeholder || "Seleccionar"}
        optionFilterProp="label"
        filterOption={(input, option) =>
          filters && (option?.label.toLowerCase() ?? "").includes(input.toLowerCase()) && !option.disabled
        }
        filterSort={(optionA, optionB) =>
          filters && (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={isAsync ? (customData ? customData : customSelectData?.dataTable?.filter(item => item.value)) : selectOptions}
        onSelect={onSelect}
        onChange={propsFilter ? onChangeTableFilter : onChange}
      />
    </>
  );
};