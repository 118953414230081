import React, { useState, useEffect } from 'react';

const country = ""
const urlBase =  "https://domo.topiaapp.com/api"

class Http {
    static instance = new Http()


    get = async(url) => {
        try {
            let req = await fetch(urlBase + country + "/" + url);

            let json = await req.json();

            return json;
        } catch (err) {
            return { "errorHttp": err };
            //throw Error(err);
        }
    }

    post = async(url, body, dominio) => {
        try {
            let req = await fetch((typeof dominio !== 'undefined' ? dominio : urlBase + country + "/") + url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: body
            });


            let json = await req.json();

            return json;
        } catch (err) {
            //showError()
            return { "errorHttp": err };
            //throw Error(err);
        }
    }

    delete = async(url) => {
        try {
            let req = await fetch(url, {
                method: "DELETE"
            });

            let json = await req.json();

            return json;
        } catch (err) {
            //throw Error(err);
        }
    }

    put = async(url, body) => {
        try {
            let req = await fetch(url, {
                method: "PUT",
                body
            });

            let json = await req.json();

            return json;
        } catch (err) {
            //throw Error(err);
        }
    }

}

export default Http;