import { Tabs, DatePicker } from "antd";
import { AlertsTab } from "./AlertsTab";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";

const { RangePicker } = DatePicker;

export const ContainerTabs = (props) => {
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;

	const { buscarFechas, loadingBt } = props;

	const onChangeFecha = (date, dateString) => {
		if (dateString[0] && dateString[1]) buscar(dateString[0], dateString[1]);
	};

	const buscar = async (start, end) => {
		var fechaI = new Date(moment(start, "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(end, "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
		buscarFechas(fechaIn, fechaFi);
	};

	const { TabPane } = Tabs;
	return (
		<>
			<h2 className="title-page">Alertas Recibidas</h2>
			<Tabs className="primary-tabs" defaultActiveKey="1">
				<TabPane tab="Alertas" key="1">
					<AlertsTab />
				</TabPane>
			</Tabs>
			<div className="contBusqueda">
				<RangePicker
					defaultValue={[
						moment(new Date(), dateFormat),
						moment(new Date(), dateFormat),
					]}
					format={customFormat}
					locale={locale}
					style={{
						width: "270px",
					}}
					onCalendarChange={onChangeFecha}
				/>
			</div>
		</>
	);
};
