import { Image, Text, View } from "@react-pdf/renderer"
import CustomFilePdf from "../../../../../../UI/CustomFiles/CustomFilePdf"
import { styles } from "./styles"
import moment from "moment"
import tuscaniaLogo from '../../../../assets/logoTuscania.png'
import { truncateText } from "../../../../helpers/stringHelper"

export const PDFReport = ({ data = { table: [], general: {} }, typeReport }) => {

    const providers_count = data.general.providerAccess
    const workers_count = data.general.workerAccess

    return (
        <CustomFilePdf size='letter' multiplePages footer footerBar={true}>
            <View fixed style={styles.headerInfo}>
                <View style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={styles.headerInfoTitle}>
                        REPORTE DE INGRESOS Y SALIDAS DE {typeReport === 'workers' ? 'TRABAJADORES' : 'PROVEEDORES'}
                    </Text>
                    <Text style={styles.headerInfoDetails}>
                        Residencia: {data.general.contractor.residential}
                    </Text>
                    <Text style={styles.headerInfoDetails}>
                        Contratista: {data.general.contractor.firstName} {data.general.contractor.lastName}
                    </Text>
                    <Text style={styles.headerInfoDetails}>
                        Fecha: {data.general.accessDate}
                    </Text>
                    <Text style={styles.headerInfoDetails}>
                        {typeReport === 'providers' ? `Número de proveedores que ingresaron: ${providers_count}` : `Ingresaron: ${workers_count} trabajador${workers_count > 1 ? 'es' : ''}`}
                    </Text>
                    {
                        typeReport === 'workers' && <Text style={styles.headerInfoDetails}>
                            Ingreso de Contratista: {data.general.contractorAccess ? 'SI' : 'NO'}
                        </Text>
                    }
                </View>
                <Image style={{ width: 200 }} src={tuscaniaLogo} />
            </View>
            {data && data.table.length ? <>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: typeReport === 'workers' ? '30%' : '15%', }}>
                            <Text style={{ ...styles.tableCell, textAlign: 'left', paddingLeft: 16 }}>{typeReport === 'workers' ? 'TRABAJADOR' : 'PROVEEDOR'}</Text>
                        </View>
                        {
                            typeReport === 'providers' && <>
                                <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '20%' }}>
                                    <Text style={styles.tableCell}>TRABAJADOR</Text>
                                </View>
                                <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '15%' }}>
                                    <Text style={styles.tableCell}>DOCUMENTO</Text>
                                </View>
                                <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '20%' }}>
                                    <Text style={styles.tableCell}>ACOMPAÑANTES</Text>
                                </View>
                            </>
                        }
                        <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: typeReport === 'workers' ? '15%' : '20%' }}>
                            <Text style={styles.tableCell}>HORA DE INGRESO</Text>
                        </View>
                        <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: typeReport === 'workers' ? '15%' : '20%' }}>
                            <Text style={styles.tableCell}>HORA DE SALIDA</Text>
                        </View>
                        {
                            typeReport === 'workers' && <>
                                <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '20%' }}>
                                    <Text style={styles.tableCell}>FECHA DE ALTA</Text>
                                </View>
                                <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '20%' }}>
                                    <Text style={styles.tableCell}>FECHA DE BAJA</Text>
                                </View>
                            </>
                        }
                    </View>
                    {/**DATOS */}
                    {
                        data.table.map((worker, index) => (
                            <View break={index % 14 === 0} key={index} style={{ ...styles.tableRow, backgroundColor: index % 2 === 0 && '#F5F9F9' }}>
                                <View style={{ ...styles.tableCol, width: typeReport === 'workers' ? '30%' : '15%' }}>
                                    <Text style={{ ...styles.tableCell, textAlign: 'left', paddingLeft: 16, color: worker.deleted_at && typeReport === 'workers' && '#EB5973', textDecoration: worker.deleted_at && typeReport === 'workers' && 'line-through' }}>{truncateText({ text: worker.name, maxLength: 12 })}</Text>
                                </View>
                                {
                                    typeReport === 'providers' && <>
                                        <View style={{ ...styles.tableCol, width: '20%' }}>
                                            <Text style={{ ...styles.tableCell, textAlign: 'left', paddingLeft: 16 }}>{truncateText({ text: worker.worker, maxLength: 17 })}</Text>
                                        </View>
                                        <View style={{ ...styles.tableCol, width: '15%' }}>
                                            <Text style={{ ...styles.tableCell, textAlign: 'left', paddingLeft: 16 }}>{worker.document}</Text>
                                        </View>
                                        <View style={{ ...styles.tableCol, width: '20%' }}>
                                            <Text style={{ ...styles.tableCell }}>{worker.companions}</Text>
                                        </View>
                                    </>
                                }
                                <View style={{ ...styles.tableCol, width: typeReport === 'workers' ? '15%' : '20%' }}>
                                    <Text style={styles.tableCell}>{worker.accessTimeEntered ? moment(worker.accessTimeEntered, 'HH:mm:ss:SSS').format('h:mm a') : '---'}</Text>
                                </View>
                                <View style={{ ...styles.tableCol, width: typeReport === 'workers' ? '15%' : '20%' }}>
                                    <Text style={styles.tableCell}>{worker.accessTimeLeft ? moment(worker.accessTimeLeft, 'HH:mm:ss:SSS').format('h:mm a') : '---'}</Text>
                                </View>
                                {
                                    typeReport === 'workers' && <>
                                        <View style={{ ...styles.tableCol, width: '20%' }}>
                                            <Text style={styles.tableCell}>{moment(worker.accessDate).format('DD/MM/YYYY')}</Text>
                                        </View>
                                        <View style={{ ...styles.tableCol, width: '20%' }}>
                                            <Text style={{ ...styles.tableCell, color: worker.deleted_at && '#EB5973' }}>{worker.deleted_at ? moment(worker.deleted_at, 'YYYY-MM-DD').format('DD/MM/YYYY') : '---'}</Text>
                                        </View>
                                    </>
                                }
                            </View>
                        ))
                    }
                </View>
            </> : <View />
            }
            <Image src={tuscaniaLogo} fixed style={{ position: 'absolute', height: 400, top: '25%', left: '25%', width: 450, opacity: 0.10, transform: 'translate(-50%,-50%)' }} /> {/*MARCA DE AGUAR DE TUSCANIA */}
            <View fixed style={{ backgroundColor: '#8EB051', height: '10px', position: 'absolute', top: 16, width: 560, marginHorizontal: 24 }} />
            <View fixed style={{ position: 'absolute', height: '10px', backgroundColor: '#8EB051', bottom: 16, width: 560, marginHorizontal: 24 }} />
        </CustomFilePdf>
    )
}

export default PDFReport