import { axiosCreate } from "../../../../../config";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const BOOKING_DETAIL_PATH = 'reservation/tuscania/detail/full'

//Endpoint de topia con el que obtienen los detalles completos de una reserva a partir del id 
//como la información de perfil del solicitante, los detalles especificos del evento, las especificaciones, mobiliario y los costes de estos
export const getBookingDetail = async ({
    token,
    reservation_id
}) => {

    try {
        const params = {
            reservation_id,
        }

        const res = await axiosCreate.get(`${BOOKING_DETAIL_PATH}`, {
            params,
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }

}