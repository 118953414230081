import {
	collection,
	query,
	where,
	getDocs,
	doc,
	orderBy,
} from "firebase/firestore";
import { FirebaseDB } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import { getDocByCollectionAnId } from "./globalHelper";

export const getAlertsHelper = async (
	residential_id,
	fechaInicio,
	fechaFinal
) => {
	const collectionResidentialRef = doc(
		FirebaseDB,
		"/residentials/" + residential_id
	);

	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(0, 0, 0, 0);

	const collectionRef = collection(FirebaseDB, `/messages`);
	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential", "==", collectionResidentialRef),
		where("created_at", ">=", new Date(dateStart)),
		where("created_at", "<=", new Date(dateEnd)),
		orderBy("created_at", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);

	const alerts = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();

		var status = "";
		if (data.answer === undefined) {
			status = "Activa";
		} else if (data.archivar === true) {
			status = "Finalizada";
		} else if (data.answer !== undefined) {
			status = "En proceso";
		}

		// Definimos los datos a obtener
		let alertData = {
			active: data.active,
			created_at: moment(data.created_at.toDate()).format("Y-MM-D"),
			created_time: moment(data.created_at.toDate()).format("HH:mm"),
			address: data.address,
			contact_phone: data.contact_phone,
			id_profile: data.id_profile,
			message: data.message,
			nameUser: data.nameUser,
			answer: data.answer,
			imageOne: data.imageOne,
			imageTwo: data.imageTwo,
			notice_type: data.notice_type ? data.notice_type.id : null,
			residential: data.residential ? data.residential.id : null,
			user_from: data.user_from,
			status: status,
			vehicle_plate: data.vehicle_plate,
			visible: data.visible,
		};

		alerts.push(alertData);
	});

	return alerts;
};

export const getAlertsHelperByUserId = async (
	residential_id,
	fechaInicio,
	fechaFinal,
	user_id
) => {
	const collectionResidentialRef = doc(
		FirebaseDB,
		"/residentials/" + residential_id
	);

	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(0, 0, 0, 0);

	const collectionRef = collection(FirebaseDB, `/messages`);
	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential", "==", collectionResidentialRef),
		where("user_from", "==", user_id),
		where("created_at", ">=", new Date(dateStart)),
		where("created_at", "<=", new Date(dateEnd)),
		orderBy("created_at", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);

	const alerts = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();

		var status = "";
		if (data.answer === undefined) {
			status = "Activa";
		} else if (data.archivar === true) {
			status = "Finalizada";
		} else if (data.answer !== undefined) {
			status = "En proceso";
		}

		// Definimos los datos a obtener
		let alertData = {
			active: data.active,
			created_at: moment(data.created_at.toDate()).format("Y-MM-D"),
			created_time: moment(data.created_at.toDate()).format("HH:mm"),
			address: data.address,
			contact_phone: data.contact_phone,
			id_profile: data.id_profile,
			message: data.message,
			nameUser: data.nameUser,
			answer: data.answer,
			imageOne: data.imageOne,
			imageTwo: data.imageTwo,
			notice_type: data.notice_type ? data.notice_type.id : null,
			residential: data.residential ? data.residential.id : null,
			user_from: data.user_from,
			status: status,
			vehicle_plate: data.vehicle_plate,
			visible: data.visible,
		};

		alerts.push(alertData);
	});

	return alerts;
};

export const getNoticeTypeDataOfMessage = async (id) => {
	if (!id) return null;

	const data = await getDocByCollectionAnId("notice_type", id);
	return data;
};

export const getResidentialsOfMessage = async (id) => {
	if (!id) return null;

	const data = await getDocByCollectionAnId("residentials", id);
	if (data) {
		let residentialData = { ...data };
		residentialData.created_at = moment(data.created_at).format("Y-MM-DD");
		residentialData.created_time = moment(data.created_at).format("HH:mm");
		residentialData.updated_at = null;
		residentialData.geolocation = null;
		residentialData.entity_type = null;

		return residentialData;
	}
	return null;
};

export const columnsAlertTable = () => {
	return [
		{
			title: "Fecha",
			dataIndex: "created_at",
			key: "created_at",
		},
		{
			title: "Hora",
			dataIndex: "created_time",
			key: "created_time",
		},
		{
			title: "Nombre",
			dataIndex: "nameResidente",
			key: "nameResidente",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
		},
		{
			title: "Tipo de alerta",
			dataIndex: "alert_type",
			key: "alert_type",
		},
		{
			title: "Detalle",
			dataIndex: "detail",
			key: "detail",
		},
		{
			title: "Estado",
			dataIndex: "status",
			key: "status",
		},
	];
};
