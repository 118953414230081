import { Row } from 'antd'
import { CustomDatePicker } from '../../../../../../../UI/CustomDatePicker/CustomDatePicker'

//Subcomponente que simplemente incluye un CustomDatePicker con tipo RANGER para poder filtrar por fecha de inicio y fecha fin los datos de la tabla utilizando el handle del prop
const FilterTable = ({ handleChangeDatePickerFilter }) => (
  <Row>
    <CustomDatePicker onChange={handleChangeDatePickerFilter} type={'RANGER'} allowClear={true} placeholder={['Fecha inicio', 'Fecha fin']} width='300px' height={'40px'} />
  </Row>
)

export default FilterTable
