import React from "react";
import {
	Button,
	Table,
	Spin,
	Modal,
	Col,
	Row,
	Form,
	Input,
	Select,
	Space,
	InputNumber,
	message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserActive } from "../../../../../store/users/usersSlice";
import {
	getUsers,
	agregarHouse,
	actualizarInfoUser,
	aprobarUser,
} from "../../../../../store/users/thunks";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import moment from "moment";
import { getColumnSearchProps } from "../../../../../utils/tables";
import "../style.css";
import { useForm } from "../../../../../hooks";
import { Spinner } from "react-activity";
import { getReferenceData } from "../../../../../helpers/globalHelper";
import { getNomenclaturaByIdHelper } from "../../../../../helpers/nomenclaturasHelper";
import { aprobar, avatarUser, hojaExcel, mensaje, phone, shape08, shape15, shape17, shape18 } from "../../../../../assets/images";

export const AprobacionUserTab = () => {
	const [form] = Form.useForm();
	const [formObservaciones] = Form.useForm();

	const [usersList, setUsersList] = useState([]);
	const dispatch = useDispatch();
	const { users } = useSelector((state) => state.users);
	const [userModalActive, setUserModalActive] = useState(false);
	const [modalInfoUser, setModalInfoUser] = useState(false);
	const [sendingResidencial, handleSendingResidencial] = useState(false);
	const [sendingAprobar, handleSendingAprobar] = useState(false);
	const [loadSendData, setLoadSendData] = useState(false);
	const [exportModalActive, setExportModalActive] = useState(false);
	const [importModalActive, setImportModalActive] = useState(false);
	const { TextArea } = Input;

	const [userActive, seUserActive] = useState(null);

	useEffect(() => {
		dispatch(getUsers());
	}, [dispatch]);

	useEffect(() => {
		seUserActive(users.active);
		if (!users.active) {
			handleStatusResidentsModal(false);
		}
	}, [users.active]);

	useEffect(() => {
		setModalInfoUser(false);
		if (users.show != 0) {
			toast.success("Datos guardados");
		}
		handleSendingResidencial(false);
	}, [users.show]);

	useEffect(() => {
		setModalInfoUser(false);
		if (users.aprobado != 0) {
			handleSendingAprobar(false);
			toast.success("Usuario aprobado");
		}
	}, [users.aprobado]);

	useEffect(() => {
		if (users.errorNumero != 0) {
			toast.error("El numero de celular ingresado ya existe");
			handleSendingResidencial(false);
		}
	}, [users.errorNumero]);

	useEffect(() => {
		let _data = users.data.map((item, index) => {
			let data = { ...item };
			data.profile = (
				<img
					className="iconPerfil"
					src={shape08}
					style={{ cursor: "pointer" }}
					alt="credential-security-icon"
					onClick={() => handleStatusResidentsModal(true, index)}
				/>
			);
			data.activity = (
				<img
					src={shape15}
					style={{ cursor: "pointer" }}
					alt="blue-report-icon"
					onClick={() => { }}
				/>
			);
			if (item.residentials) {
				let path = item.residentials[0]?.house["path"];
				path = path.substring(47, 67);

				const nomenclature = getNomenclaturaByIdHelper(
					"Yd5MV7jkA0MjQeCmzKrZ",
					path
				).then((nomen) => {
					data.nomenclature = nomen;
				});
			}
			/* path = path.substring(47, 67); */
			return data;
		});

		setUsersList(_data);
	}, [users.data]);

	const agregar = async (idProfile) => {
		var residencial = {
			homePrincipal: true,
			idResidential: "Yd5MV7jkA0MjQeCmzKrZ",
			house:
				"/residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/RizHyM4rzyxRouj8yOZh/houses/Eedw4adswcXn7yPYVr4j",
			type: "P",
		};
		dispatch(agregarHouse(residencial, idProfile));
	};

	const mostrarDatosUser = async (idProfile) => {
		const newValues = {
			nombre: userActive.name,
			apellido: userActive.lastname,
			sociedadAnonima: userActive.sociedadAnonima,
			celularUno: userActive.phone,
			celularDos: userActive.phoneTwo,
			telefonoUno: userActive.phoneThree,
			telefonoDos: userActive.phoneFour,
			email: userActive.email,
			emailDos: userActive.emailTwo,
		};
		form.setFieldsValue(newValues);

		setModalInfoUser(true);
	};

	const aprobarUsuario = async () => {
		const values = await formObservaciones.validateFields();
		var observaciones = values.observaciones;
		if (observaciones === undefined) {
			observaciones = "";
		}
		handleSendingAprobar(true);
		dispatch(aprobarUser(userActive.id, observaciones));
	};

	const handleStatusResidentsModal = async (statusModal, index = null) => {
		if (index != null) {
			var item = users.data[index];
			var listOfResidentialsData = [];
			if (item.residentials) {
				if (item.residentials.length > 0) {
					var contador = item.residentials.length;
					var contadorActual = 0;

					item.residentials.forEach(async (houseDetail, index) => {
						if (houseDetail.house) {
							let houseData = await getReferenceData(houseDetail.house.path);

							if (houseData) {
								try {
									listOfResidentialsData = [
										...listOfResidentialsData,
										houseData,
									];
									contadorActual++;
									if (contador === contadorActual) {
										let data = {
											...item,
											listOfResidentialsData,
										};

										dispatch(setUserActive(data));
									}
								} catch (error) {
								}
							}
						} else {
							contadorActual++;
							if (contador === contadorActual) {
								let data = {
									...item,
									listOfResidentialsData,
								};

								dispatch(setUserActive(data));
							}
						}
					});

				}
			}
		} else {
			dispatch(setUserActive(null));
		}

		setTimeout(() => {
			setUserModalActive(statusModal);
		}, 50);
	};

	const onHandleGuardarResidencial = async () => {
		const values = await form.validateFields();
		if (values.nombre === "") {
			toast.warn("Debe ingresar nombre");
			return;
		} else if (values.apellido === "") {
			toast.warn("Debe ingresar apellido");
			return;
		} else if (values.celularUno === "") {
			toast.warn("Debe ingresar celular");
			return;
		} else {
			var dataCasa = {
				name: values.nombre,
				lastname: values.apellido,
				phone: values.celularUno + "",
				phoneTwo: values.celularDos + "",
				phoneThree: values.telefonoUno + "",
				phoneFour: values.telefonoDos + "",
				email: values.email,
				emailTwo: values.emailDos,
				sociedadAnonima: values.sociedadAnonima,
			};

			handleSendingResidencial(true);
			dispatch(actualizarInfoUser(dataCasa, userActive));

			//var path = `/residentials/${committee.residential_id}/nomenclature/${nomenclaturaActive.id}/houses/${residencialActive.id}`

			//handleSendingResidencial(true)
			//dispatch( saveResidencial(dataCasa,path,residencialActive.id) )
		}
	};

	const columnsUsuarios = [
		{
			title: "Id User",
			dataIndex: "idUser",
			key: "idUser",
		},
		{
			title: "No. Lote",
			dataIndex: "lote",
			key: "lote",
		},
		{
			title: "Nombre",
			dataIndex: "propietario",
			key: "propietario",
		},
		{
			title: "Apellido",
			dataIndex: "apellido",
			key: "apellido",
		},
		{
			title: "Tipo",
			dataIndex: "tipo",
			key: "tipo",
		},
		{
			title: "Calificacion",
			dataIndex: "calificacion",
			key: "calificacion",
		},
		{
			title: "Celular 1",
			dataIndex: "celularUno",
			key: "celularUno",
		},
		{
			title: "Celular 2",
			dataIndex: "celularDos",
			key: "celularDos",
		},
		{
			title: "Telefono 1",
			dataIndex: "telefonoUno",
			key: "telefonoUno",
		},
		{
			title: "Telefono 2",
			dataIndex: "telefonoDos",
			key: "telefonoDos",
		},
		{
			title: "E-mail",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "E-mail 1",
			dataIndex: "emailDos",
			key: "emailDos",
		},
		{
			title: "Dirección",
			dataIndex: "address",
			key: "address",
		},
	];

	const columnsCuotas = [
		{
			title: "Comentarios",
			dataIndex: "comentarios",
			key: "comentarios",
		},
		{
			title: "Nombre del Cliente",
			dataIndex: "nombreCliente",
			key: "nombreCliente",
		},
		{
			title: "DUI",
			dataIndex: "dui",
			key: "dui",
		},
		{
			title: "NIT",
			dataIndex: "nit",
			key: "nit",
		},
		{
			title: "Fecha firma de compraventa",
			dataIndex: "firmaCompraventa",
			key: "firmaCompraventa",
		},
		{
			title: "No Terreno",
			dataIndex: "terreno",
			key: "terreno",
		},
		{
			title: "Poligono",
			dataIndex: "poligono",
			key: "poligono",
		},
		{
			title: "Area (M2)",
			dataIndex: "aream",
			key: "aream",
		},
		{
			title: "Area (V2)",
			dataIndex: "areav",
			key: "areav",
		},
		{
			title: "Categoría",
			dataIndex: "categoria",
			key: "categoria",
		},
		{
			title: "Cuota de mantenimiento solo terreno",
			dataIndex: "cuotaMantenimiento",
			key: "cuotaMantenimiento",
		},
		{
			title: "Cuota de mantenimiento construccion",
			dataIndex: "cuotaConstruccion",
			key: "cuotaConstruccion",
		},
		{
			title: "Cuota de mantenimiento con residencia",
			dataIndex: "cuotaResidencia",
			key: "cuotaResidencia",
		},
		{
			title: "Tasa recolección alcaldia Zaragoza",
			dataIndex: "tasaRecoleccion",
			key: "tasaRecoleccion",
		},
		{
			title: "Total cobrar",
			dataIndex: "total",
			key: "total",
		},
	];

	const getUserName = () => {
		const localities = [];
		usersList.map((d) => {
			localities.push({
				text: d.name,
				value: d.name,
			});
		});
		return localities;
	};

	const columns = [
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			width: 300,
			...getColumnSearchProps("name"),
		},
		{
			title: "Apellido",
			dataIndex: "lastname",
			key: "lastname",
			width: 300,
			...getColumnSearchProps("lastname"),
		},
		{
			title: "Teléfono",
			dataIndex: "phone",
			key: "phone",
			...getColumnSearchProps("phone"),
			width: "160px",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			...getColumnSearchProps("email"),
		},
		{
			title: "Observaciones",
			dataIndex: "observations",
			key: "observations",
			...getColumnSearchProps("observations"),
			render: (id, record, index) => (
				<div className="observations">{record.observations}</div>
			),
		},
		{
			title: "Estado",
			dataIndex: "verified",
			key: "verified",
			...getColumnSearchProps("verified"),
			width: "150px",
		},
		{
			title: "Perfil",
			dataIndex: "profile",
			key: "profile",
			width: "100px",
		},
	];
	const IconButtonReport = () => (
		<img
			alt="hoja-excel"
			style={{ width: "25px", height: "25px" }}
			src={hojaExcel}
		/>
	);
	const handleOnExportVilla = () => {
		let data = [];
		usersList.map((item, index) => {
			/* let data =[{ID: item.created_at, }] */
			if (item.nomenclature[0].type === "villa") {
				data.push({
					type: item.nomenclature[0].type,
					id: item.id,
					created_at: item.created_at,
					created_time: item.created_time,
					birthdate: item.birthdate,
					updated_at: item.updated_at,
					observations: item.observations,
					mora: item.mora,
					name: item.name,
					lastname: item.lastname,
					origin_fam: item.origin_fam,
					phone: item.phone,
					phoneTwo: item.phoneTwo,
					phoneThree: item.phoneThree,
					phoneFour: item.phoneFour,
					verified: item.verified,
					pet: item.pet,
					number_children: item.number_children,
					number_senior_citizens: item.number_senior_citizens,
					diseases: item.diseases,
					hasChildren: item.hasChildren,
					email: item.email,
					emailTwo: item.emailTwo,
					home_location: item.home_location,
					gender: item.gender,
					role_id: item.role_id,
					vehicle: item.vehicle,
					address: item.address,
					contact_phone: item.contact_phone,
					ownerHome: item.ownerHome,
					key: item,
					activity: item.activity,
				});
			}
		});

		let Heading = [
			[
				"Tipo",
				"ID",
				"Fecha creación",
				"Hora creación",
				"Fecha Nacimiento",
				"Fecha actualización",
				"Observaciones",
				"Mora",
				"Nombres",
				"Apellidos",
				"Código familiar",
				"1er Teléfono",
				"2do teléfono",
				"3er Teléfono",
				"4to Teléfono",
				"Estado verificación",
				"Mascota",
				"Número Hijos",
				"Número adultos mayores",
				"Enfermedades",
				"Hijos",
				"Correo Electrónico",
				"2do Correo Electrónico",
				"Ubicación en casa",
				"Género",
				"ID Rol",
				"Vehiculo",
				"Dirección",
				"Teléfono Contacto",
				"Dueño casa",
				"Llave",
				"Actividad",
			],
		];

		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_aoa(ws, Heading);
		XLSX.utils.sheet_add_json(ws, data, {
			origin: "A2",
			skipHeader: true,
		});
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "Perfiles Villa Tuscania.xlsx");
		/* var wb = XLSX.utils.book_new(),
			ws = XLSX.utils.json_to_sheet(data);

		XLSX.utils.book_append_sheet(wb, ws, "Perfiles test");

		XLSX.writeFile(wb, "Perfiles test.xlsx"); */
	};
	const handleOnExportValle = () => {
		let data = [];
		usersList.map((item, index) => {
			/* let data =[{ID: item.created_at, }] */
			if (item.nomenclature[0].type === "valle") {
				data.push({
					type: item.nomenclature[0].type,
					id: item.id,
					created_at: item.created_at,
					created_time: item.created_time,
					birthdate: item.birthdate,
					updated_at: item.updated_at,
					observations: item.observations,
					mora: item.mora,
					name: item.name,
					lastname: item.lastname,
					origin_fam: item.origin_fam,
					phone: item.phone,
					phoneTwo: item.phoneTwo,
					phoneThree: item.phoneThree,
					phoneFour: item.phoneFour,
					verified: item.verified,
					pet: item.pet,
					number_children: item.number_children,
					number_senior_citizens: item.number_senior_citizens,
					diseases: item.diseases,
					hasChildren: item.hasChildren,
					email: item.email,
					emailTwo: item.emailTwo,
					home_location: item.home_location,
					gender: item.gender,
					role_id: item.role_id,
					vehicle: item.vehicle,
					address: item.address,
					contact_phone: item.contact_phone,
					ownerHome: item.ownerHome,
					key: item,
					activity: item.activity,
				});
			}
		});

		let Heading = [
			[
				"Tipo",
				"ID",
				"Fecha creación",
				"Hora creación",
				"Fecha Nacimiento",
				"Fecha actualización",
				"Observaciones",
				"Mora",
				"Nombres",
				"Apellidos",
				"Código familiar",
				"1er Teléfono",
				"2do teléfono",
				"3er Teléfono",
				"4to Teléfono",
				"Estado verificación",
				"Mascota",
				"Número Hijos",
				"Número adultos mayores",
				"Enfermedades",
				"Hijos",
				"Correo Electrónico",
				"2do Correo Electrónico",
				"Ubicación en casa",
				"Género",
				"ID Rol",
				"Vehiculo",
				"Dirección",
				"Teléfono Contacto",
				"Dueño casa",
				"Llave",
				"Actividad",
			],
		];

		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_aoa(ws, Heading);
		XLSX.utils.sheet_add_json(ws, data, {
			origin: "A2",
			skipHeader: true,
		});
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "Perfiles Valle Tuscania.xlsx");
		/* var wb = XLSX.utils.book_new(),
			ws = XLSX.utils.json_to_sheet(data);

		XLSX.utils.book_append_sheet(wb, ws, "Perfiles test");

		XLSX.writeFile(wb, "Perfiles test.xlsx"); */
	};

	const handleExportModal = async (statusModal) => {
		setTimeout(() => {
			setExportModalActive(statusModal);
		}, 50);
	};
	const handleImportModal = async (statusModal) => {
		setTimeout(() => {
			setImportModalActive(statusModal);
		}, 50);
	};

	return (
		<>
			<Row>
				<Col span={6}>
					<h2 className="title-page">Perfiles de usuarios</h2>
				</Col>
				<Col span={6}></Col>
				<Col span={6} type="flex" align="right">
					{/* <Button
						style={{
							color: "#10b48c",
							borderColor: "#10b48c",
							background: "white",
							paddingBottom: "15px",
						}}
						shape="round"
						icon={<IconButtonReport />}
						size="large"
						onClick={() => {
							handleImportModal(true);
						}}
					>
						<span className="title" style={{ marginLeft: "10px" }}>
							Importar Datos
						</span>
					</Button> */}
				</Col>
				<Col span={6} type="flex" align="middle">
					<Button
						style={{
							color: "#10b48c",
							borderColor: "#10b48c",
							background: "white",
							paddingBottom: "15px",
						}}
						shape="round"
						icon={<IconButtonReport />}
						size="large"
						onClick={() => {
							handleExportModal(true);
						}}
					>
						<span className="title" style={{ marginLeft: "10px" }}>
							Exportar Datos
						</span>
					</Button>
				</Col>
			</Row>

			<div>
				<Table
					pagination={{ pageSize: 100, simple: true }}
					scroll={{ y: "calc(100vh - 13em)" }}
					className="primary-table"
					columns={columns}
					dataSource={usersList}
				/>
			</div>

			{/* Modal para exportar */}
			<Modal
				className="primary-modal"
				title="Exportar datos a Excel"
				visible={exportModalActive}
				onCancel={() => handleExportModal(false)}
				width={600}
				footer={null}
			>
				<>
					<Row justify="space-around">
						<Col span={12} type="flex" align="middle">
							<Button
								style={{
									color: "#10b48c",
									borderColor: "#10b48c",
									background: "white",
									paddingBottom: "15px",
								}}
								shape="round"
								icon={<IconButtonReport />}
								size="large"
								onClick={handleOnExportValle}
							>
								<span className="title" style={{ marginLeft: "10px" }}>
									Exportar Datos Valle
								</span>
							</Button>
						</Col>
						<Col span={12} type="flex" align="middle">
							<Button
								style={{
									color: "#10b48c",
									borderColor: "#10b48c",
									background: "white",
									paddingBottom: "15px",
								}}
								shape="round"
								icon={<IconButtonReport />}
								size="large"
								onClick={handleOnExportVilla}
							>
								<span className="title" style={{ marginLeft: "10px" }}>
									Exportar Datos Villa
								</span>
							</Button>
						</Col>
					</Row>
				</>
			</Modal>

			{/* Modal para importar */}
			<Modal
				className="primary-modal"
				title="Importar datos a Excel"
				visible={importModalActive}
				onCancel={() => handleImportModal(false)}
				width={600}
				footer={null}
			>
				<>
					<Row justify="space-around">
						<Col span={12} type="flex" align="middle">
							<Button
								style={{
									color: "#10b48c",
									borderColor: "#10b48c",
									background: "white",
									paddingBottom: "15px",
								}}
								shape="round"
								icon={<IconButtonReport />}
								size="large"
							>
								<span className="title" style={{ marginLeft: "10px" }}>
									Importar Datos Valle
								</span>
							</Button>
						</Col>
						<Col span={12} type="flex" align="middle">
							<Button
								style={{
									color: "#10b48c",
									borderColor: "#10b48c",
									background: "white",
									paddingBottom: "15px",
								}}
								shape="round"
								icon={<IconButtonReport />}
								size="large"
							>
								<span className="title" style={{ marginLeft: "10px" }}>
									Importar Datos Villa
								</span>
							</Button>
						</Col>
					</Row>
				</>
			</Modal>

			<Modal
				className="side-modal"
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh)" }}
				title="Datos del Residente"
				style={{ top: 0, right: 0 }}
				visible={userModalActive}
				onCancel={() => handleStatusResidentsModal(false)}
				footer={null}
			>
				{userActive ? (
					<>
						<div>
							<Row className="section-modal">
								<Col span={24}>
									<div className="user-data">
										{userActive.image ? (
											<div
												style={{
													backgroundImage: `url(` + userActive.image + `)`,
												}}
												className="user-image avatarUser"
												src={userActive.image}
												alt="Topia"
											/>
										) : (
											<img
												className="user-image"
												src={avatarUser}
												alt="avatar-user-icon"
											/>
										)}

										<div className="user-info">
											<span className="user-name">
												{userActive.name} {userActive.lastname}
											</span>
											<span className="user-address">
												{" "}
												{userActive?.address}{" "}
											</span>
											<Row>
												<Col span={12}>
													<div
														style={{
															color: "#10B48C",
															display: "flex",
															alignItems: "flex-start",
															margin: "0px",
														}}
													>
														<img
															className="iconoPerfil"
															src={phone}
															alt="phone-icon"
														/>

														<span style={{ marginLeft: "5x" }}>
															{userActive.phone}
															{userActive.phoneTwo !== ""
																? "\n" + userActive.phoneTwo
																: ""}
															{userActive.phoneThree !== ""
																? "\n" + userActive.phoneThree
																: ""}
															{userActive.phoneFour !== ""
																? "\n" + userActive.phoneFour
																: ""}
														</span>
													</div>
												</Col>
												<Col span={12}>
													<div
														className="contEmail"
														style={{
															color: "#10B48C",
															display: "flex",
															alignItems: "flex-start",
															margin: "0px",
														}}
													>
														<img
															className="iconoPerfil"
															src={mensaje}
															alt="message-icon"
														/>
														<div className="contEmails">
															<span className="emails">{userActive.email}</span>
															<span className="emails">
																{userActive.emailTwo}
															</span>
														</div>
													</div>
												</Col>
											</Row>

											<Button
												className="btn-primary-radio btEditar"
												onClick={() => {
													mostrarDatosUser();
												}}
												type="primary"
												shape="round"
												editarDatosUser
											>
												Editar
											</Button>
										</div>
									</div>
								</Col>
							</Row>

							<Row className="section-modal">
								{userActive.listOfResidentialsData.map((item, key) => {
									return (
										<Col span={24} key={key}>
											<div className="alert-modal-header linea">
												<Row>
													<Col span={12}>
														<span
															className="title"
															style={{ fontSize: "10px" }}
														>
															<img
																style={{
																	marginRight: "10px",
																	display: "inline",
																}}
																src={shape17}
																alt="grey-keys-icon"
															/>
															Tipo de residente
														</span>
														<p className="detalleP">
															{userActive.residentials[key].type === "P"
																? "Propietario"
																: userActive.residentials[key].type === "C"
																	? "Contacto"
																	: ""}
														</p>
													</Col>

													<Col span={12}>
														<span
															className="title"
															style={{ fontSize: "10px" }}
														>
															<img
																style={{
																	marginRight: "10px",
																	display: "inline",
																}}
																src={shape18}
																alt="pin-location-icon"
															/>
															Dirección de residencia
														</span>
														<p className="detalleP">{item.address}</p>
													</Col>
													<Col span={12}>
														<p className="detalleP">Número casa: {item.name}</p>
													</Col>
													<Col span={12}>
														<p className="detalleP">
															Categoría: {item.category}
														</p>
													</Col>
													<Col span={12}>
														<p className="detalleP">Area M2: {item.areaM2}</p>
													</Col>
													<Col span={12}>
														<p className="detalleP">
															Area V2: {parseFloat(item.areaV2).toFixed(2)}
														</p>
													</Col>
													<Col span={12}>
														<p className="detalleP">
															Cuota de mantenimiento:
															<br />$
															{parseFloat(item.maintenanceFee).toFixed(2)}
														</p>
													</Col>
													<Col span={12}>
														<p className="detalleP">
															Cuota construcción:
															<br />$
															{parseFloat(item.constructionFee).toFixed(2)}
														</p>
													</Col>
													<Col span={12}>
														<p className="detalleP">
															Cuota de residencia:
															<br />${parseFloat(item.residenceFee).toFixed(2)}
														</p>
													</Col>
													<Col span={12}>
														<p className="detalleP">
															Alcaldia / recoleccion basura:
															<br />${parseFloat(item.alcaldia).toFixed(2)}
														</p>
													</Col>
													<Col span={12}>
														<p className="detalleP">
															Total a pagar: $
															{parseFloat(item.total).toFixed(2)}
														</p>
													</Col>
												</Row>
											</div>
										</Col>
									);
								})}
							</Row>

							{userActive.observations !== "" ? (
								<Row className="section-modal">
									<Col span={24}>
										<h3
											style={{
												textTransform: "uppercase",
												color: "#232C4A",
												fontSize: "14px",
											}}
										>
											Observaciones
										</h3>

										<span style={{ color: "#10B48C", fontSize: "12px" }}>
											{userActive.observations}
										</span>
										<div className="espacioO"></div>
									</Col>
								</Row>
							) : null}

							{userActive.relativesWithAccess.length > 0 && (
								<Row className="section-modal">
									<Col span={24}>
										<h3
											style={{
												textTransform: "uppercase",
												color: "#232C4A",
												fontSize: "14px",
											}}
										>
											Familiares con acceso
										</h3>

										<div style={{ marginLeft: "20px" }}>
											<Row>
												{userActive.relativesWithAccess.map((relative, key) => {
													return (
														<Col span={12} key={key}>
															<span
																style={{ color: "#10B48C", fontSize: "12px" }}
															>
																{relative.name} ({relative.relationship})
															</span>
														</Col>
													);
												})}
											</Row>
										</div>
									</Col>
								</Row>
							)}

							{userActive.verified === "Pendiente" ? (
								<div style={{ textAlign: "center", marginTop: "20px" }}>
									<Form
										form={formObservaciones}
										layout="vertical"
										requiredMark={true}
									>
										<Form.Item
											label={<span>Observaciones:</span>}
											name="observaciones"
											className="form-group"
										>
											<TextArea
												rows={4}
												size="large"
												className="inputFormControl"
											/>
										</Form.Item>
										<Button
											loading={sendingAprobar}
											className="btn-primary-radio btEditar btCenter"
											onClick={() => {
												aprobarUsuario();
											}}
											type="primary"
											shape="round"
											editarDatosUser
										>
											<img
												alt="save-approved-icon"
												src={aprobar}
												className="aprobar"
											/>{" "}
											Guardar y Aprobar
										</Button>
									</Form>
								</div>
							) : null}
						</div>
					</>
				) : (
					<>
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					</>
				)}
			</Modal>

			{userActive ? (
				<Modal
					className="primary-modal"
					title={"Datos de residente"}
					visible={modalInfoUser}
					style={{ top: 20 }}
					onCancel={() => {
						setModalInfoUser(false);
					}}
					footer={
						<Row>
							<Col span={6} style={{ textAlign: "left" }}></Col>

							<Col span={12} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									loading={sendingResidencial}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "100%" }}
									onClick={() => {
										onHandleGuardarResidencial();
									}}
								>
									{loadSendData ? (
										<div className="centerSpinner">
											{" "}
											<Spinner />
										</div>
									) : (
										"GUARDAR"
									)}
								</Button>
							</Col>
						</Row>
					}
				>
					{
						<Row className="section-modal">
							<Col span={24}>
								<Form form={form} layout="vertical" requiredMark={true}>
									<Form.Item
										label={<span>Nombre:</span>}
										name="nombre"
										className="form-group"
										rules={[{ required: true, message: "Ingrese nombre" }]}
									>
										<Input size="large" className="inputFormControl" />
									</Form.Item>
									<div className="espacio"></div>

									<Form.Item
										label={<span>Apellido:</span>}
										name="apellido"
										className="form-group"
										rules={[{ required: true, message: "Ingrese apellido" }]}
									>
										<Input size="large" className="inputFormControl" />
									</Form.Item>
									<div className="espacio"></div>

									<Form.Item
										label={<span>Sociedad anónima:</span>}
										name="sociedadAnonima"
										className="form-group"
									>
										<Input size="large" className="inputFormControl" />
									</Form.Item>

									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Celular Acceso:</span>}
												name="celularUno"
												className="form-group"
												rules={[{ required: true, message: "Ingrese Celular" }]}
											>
												<InputNumber
													size="large"
													min={0}
													max={100000000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Celular Dos:</span>}
												name="celularDos"
												className="form-group"
											>
												<InputNumber
													size="large"
													min={0}
													max={100000000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>

									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Teléfono:</span>}
												name="telefonoUno"
												className="form-group"
											>
												<InputNumber
													size="large"
													min={0}
													max={100000000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Teléfono Dos:</span>}
												name="telefonoDos"
												className="form-group"
											>
												<InputNumber
													size="large"
													min={0}
													max={100000000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>

									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Email:</span>}
												name="email"
												className="form-group"
											>
												<Input size="large" className="inputFormControl" />
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Email Dos:</span>}
												name="emailDos"
												className="form-group"
											>
												<InputNumber
													size="large"
													min={0}
													max={100}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					}
				</Modal>
			) : null}
		</>
	);
};
