import React, { useState, useEffect } from 'react';
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	TimePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
	Checkbox,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { AppBanner } from "../AppBanner";
import ColorPicker from "../ColorPicker";
import ImagePicker from "../ImagePicker";

import { 
	addNewNewsEntry,
	updateNewsEntry
} from "../../../../../helpers/newsHelper";
import { useSelector } from "react-redux";

import "./NewsDetailForm.css";

const config = {
	rules: [
		{ required: true, message: "Por favor llenar el campo" }
	],
};
const format = "HH:mm";

const eventTypeName = (eventType) => {
		if (eventType === "event") {
			return "Evento";
		}
		if (eventType === "news") {
			return "Noticia";
		}
		if (eventType === "notification") {
			return "Notificación";
		}
		return "Noticia";
	};

export default ({name = "create_news", type, data}) => {
	const { TextArea } = Input;
	const navigate = useNavigate();

	const [color, setColor] = useState("#10B48C");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [icon, setIcon] = useState(null);
	const [iconFile, setIconFile] = useState(null);
	const [image, setImage] = useState(null);
	const [imageFile, setImageFile] = useState(null);
	const [showBanner, setShowBanner] = useState(true);
	const [newsId, setNewsId] = useState();
	const [published, setPublished] = useState(false);
	const [newsType, setNewsType] = useState("news");
	const [loadingDraftButton, setLoadingDraftButton] = useState(false);
	const [loadingSaveButton, setLoadingSaveButton] = useState(false);

	const { committee } = useSelector((state) => state.auth);

	useEffect(() => {
		if (!data) {
			return;
		}
		setNewsType(data?.type);
		setTitle(data?.NEWS_DETAIL?.title);
		setDescription(data?.NEWS_DETAIL?.subtitle);
		setColor(data?.NEWS_DETAIL?.color);
		setIcon(data?.NEWS_DETAIL?.icon);
		setImage(data?.NEWS_DETAIL?.image);
		setShowBanner(data?.showBanner);
		setNewsId(data?.id);
	}, []);

	useEffect(() => {
		if(!type) {
			return;
		}
		setNewsType(type);
	}, [type]);

	const initialFormValues = {
		title: data ? data?.NEWS_DETAIL?.title : "",
		subtitle: data ? data?.NEWS_DETAIL?.subtitle : "",
		description: data ? data?.NEWS_DETAIL?.description : "",
		color: data ? data?.NEWS_DETAIL?.color : "#10B48C",
		image: data ? data?.NEWS_DETAIL?.image : "",
		date: data ? moment(data?.NEWS_INVITE?.date, "YYYY-MM-DD") : "",
		time: data ? moment(data?.NEWS_INVITE?.time, "HH:mm") : "",
		place: data ? data?.NEWS_INVITE?.place : "",
		inviteFrom: data ? data?.NEWS_INVITE?.inviteFrom : "",
		requiredInscription: data ? data?.NEWS_INVITE?.requiredInscription : "",
	};

	const onChangeTitle = (e) => {
		setTitle(e.target.value);
	};
	const onChangeDescription = (e) => {
		setDescription(e.target.value);
	};
	const onSelectedImage = (files) => {
		const file = files[0];
		const reader = new FileReader();

		reader.onloadend = function () {
			setImage(reader.result);
		};

		if (file) {
			setImageFile(file);
			reader.readAsDataURL(file);
		}
	}

	const onSelectedIcon = (files) => {
		const reader = new FileReader();

		reader.onloadend = function () {
			setIcon(reader.result)
		};

		if (files.length === 1) {
			setIconFile(files[0]);
			reader.readAsDataURL(files[0]);
		}
	}

	const onChangeDate = (date, dateString) => {
	};

	const EventImage = () => {
		return (
			<div className="create-news" style={{ marginTop: "2rem" }}>
				<p>IMAGEN PRINCIPAL</p>
				<div
					style={{
						backgroundColor: "#F5F5F5",
						width: "320px",
						height: "240px",
						border: "1px solid #E8E8E8",
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
						position: "relative"
					}}>
					<img
						id="image-preview"
						src={image}
						alt="Preview"
						style={{ 
							width: "100%", 
							height: "100%", 
							display: image != null ? "block" : "none",
							position: "absolute",
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
						}}
					/>
					<ImagePicker 
						id={'image'}
						label={!image ? "Seleccionar imagen" : "Cambiar imagen"} 
						accept="image/jpeg,image/jpg,image/png"
						buttonWidth={"200px"}
						onPick={onSelectedImage}
						/>
				</div>
				<span style={{
					fontWeight: "500", 
					fontSize: "12px", 
					textAlign: "center", 
					width: "320px", 
					display: "block",
					marginTop: "8px"
				}}>(Formatos jpg o png de 230x295px)</span>
				
			</div>
		);
	}

	const EventDatePlaceForm = () => {
		return (
			<>
				<div className="create-news">
					<Row>
						<Col span={12}>
							<p>FECHA:</p>
							<FormItem name="date" {...config}>
								<DatePicker
									onChange={onChangeDate}
									style={{ width: "90%" }}
								></DatePicker>
							</FormItem>
						</Col>
						<Col span={12}>
							<p>HORA:</p>
							<FormItem name="time" {...config}>
								<TimePicker format={format} />
							</FormItem>
						</Col>
					</Row>
				</div>
				<div className="create-news">
					<p>LUGAR:</p>
					<FormItem name="place" {...config}>
						<Input></Input>
					</FormItem>
				</div>
			</>
		)
	}

	const EventInviteFromForm = () => {
		return (
			<>
				<div className="create-news">
					<p>¿QUIÉN INVITA?:</p>
					<FormItem name="inviteFrom" {...config}>
						<Input></Input>
					</FormItem>
				</div>
				<div className="create-news">
					<Card
						style={{
							width: "100%",
							borderRadius: "10px",
						}}
					>
						<p>REQUIERE INSCRIPCIÓN</p>

						<FormItem name="requiredInscription" {...config}>
							<Radio.Group>
								<Space>
									<Radio value={true}>SI</Radio>
									<Radio value={false}>NO</Radio>
								</Space>
							</Radio.Group>
						</FormItem>
					</Card>
				</div>
			</>
		)
	}

	const onFinishCreateNewsForm = async (e) => {
		const { residential_id: residentialId } = committee;
		if(!published) {
			setLoadingDraftButton(true);
		} else {
			setLoadingSaveButton(true);
		}

		const formData = new FormData();
		formData.append("accounts[]", residentialId);
		formData.append("show_banner", showBanner);
		formData.append("icon", iconFile);
		if(!!imageFile) {
			formData.append("image", imageFile);
		}
		formData.append("active", true);
		formData.append("published", published);
		formData.append("type", type);
		formData.append("all_accounts", false);
		
		for (const [key, value] of Object.entries(e)) {
			if(key === "time") {
				formData.append("time", moment(value).format("HH:mm"));
				continue;
			}
			if(key === "date") {
				formData.append("date", moment(value).format("YYYY-MM-DD"));
				continue;
			}
			formData.append(key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase(), value);
		}

		if(!newsId) {
			// Save
			const response = await addNewNewsEntry(formData);
		}
		else {
			// update
			const response = await updateNewsEntry(newsId, formData);			

		}
		setLoadingDraftButton(false);
		setLoadingSaveButton(false);
		navigate(`/noticias`, {
			replace: false,
		});
	}

	return (
		<Form
			name={name}	
			onFinish={onFinishCreateNewsForm}
			initialValues={initialFormValues}
		>
			<Row className={{ margin: "10px" }}>
				<Col span={12}>
					<div className="create-news">
						<p>TÍTULO:</p>

						<FormItem name="title" {...config}>
							<Input onChange={onChangeTitle} maxLength={21}></Input>
						</FormItem>
					</div>
					<div className="create-news">
						<p>SUBTITULO:</p>
						<FormItem name="subtitle" {...config}>
							<Input onChange={onChangeDescription} maxLength={70}></Input>
						</FormItem>
					</div>
					{newsType === "event" && (<EventDatePlaceForm />)}
					<div className="create-news">
						<p>DESCRIPCIÓN:</p>
						<FormItem name="description" {...config}>
							<TextArea rows={8}></TextArea>
						</FormItem>
					</div>
					{newsType === "event" && (<EventInviteFromForm />)}
				</Col>

				<Col span={12}>
					<div style={{ marginTop: "0rem", marginLeft: "4rem" }}>
						<div className="create-news">
							<p>PREVIEW EN BANNER:</p>
							<AppBanner
								icon={icon}
								bannerColor={color}
								title={title}
								description={description}
							/>
						</div>
						<div className="create-news" style={{ marginTop: "2rem" }}>
							<p>COLOR:</p>
							<FormItem name="color" {...config}>
								<ColorPicker onPick={(color) => setColor(color)} defaultColor={color}/>
							</FormItem>
							<ImagePicker 
								id="icon"
								label={"Seleccionar icono"} 
								accept="image/jpeg,image/jpg,image/png"
								onPick={onSelectedIcon}
								/>

							<div style={{marginTop: "20px",}}>
								<Checkbox 
									defaultChecked={showBanner}
									onChange={({target}) => setShowBanner(target.checked)}
								>
									MOSTRAR BANNER
								</Checkbox>
							</div>
							{newsType !== "notification" && (<EventImage />)}
						</div>
					</div>
				</Col>

				<Col span={24} align="middle">
					<div style={{ marginTop: "3rem" }}>
						<Button
							htmlType="submit"
							loading={loadingDraftButton}
							style={{
								marginRight: "1rem",
								borderColor: "#CEB457",
								background: "#CEB457",
								paddingBottom: "15px",
								width: "300px",
							}}
							shape="round"
							onClick={() => setPublished(false)}
						>
							<span className="title" style={{ color: "#fff" }}>
								GUARDAR Y CONTINUAR DESPUÉS
							</span>
						</Button>
						<Button
							htmlType="submit"
							loading={loadingSaveButton}
							style={{
								marginLeft: "1rem",
								borderColor: "#10b48c",
								background: "#10b48c",
								paddingBottom: "15px",
								width: "250px",
							}}
							shape="round"
							onClick={() => setPublished(true)}
						>
							<span
								className="title"
								style={{ color: "#fff", textTransform: "uppercase" }}
							>
								PUBLICAR {eventTypeName(newsType)}
							</span>
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	)
}