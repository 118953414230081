import React from "react";
import {
	Col,
	Modal,
	Row,
	Table,
	Button,
	Switch,
	Form,
	Input,
	Radio,
	Space,
	Descriptions,
} from "antd";
import AgentMap from "./AgentMap";

const AgentTrackMapModal = ({
	agents,
	agentModalActive,
	handleModalAgentActive,
}) => {
	return (
		<>
			<Modal
				className="primary-modal"
				title="TRACK DE VIGILANCIA"
				visible={agentModalActive}
				onCancel={() => handleModalAgentActive(false)}
				footer={null}
				width={600}
			>
				<AgentMap agentsData={agents} />
			</Modal>
		</>
	);
};

export default AgentTrackMapModal;
