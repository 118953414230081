
import { getZonasHelper, getNumeroEmpleados,getAllUserResidential } from "../../helpers/zonasHelper";
import { onCreateOrUpdate,onUpdateDocWithRef,onDeleteDoc} from "../../helpers/globalHelper";
import { createZona } from "./zonasSlice";
import moment from 'moment';

import { 
    setZonasData,
    setEmpleadosData
} from '.';



export const getZonas = () => {
    return async( dispatch, getState ) => {
        
        const { committee } = getState().auth;
        const zonasData = await getZonasHelper(committee.residential_id);

        let zonasList = zonasData.map( async (item, index) =>{
            let users = await getNumeroEmpleados(item.residentialId,item.id)
            return {
                ...zonasData[index],
                users,
            }
        });

        Promise.all(zonasList).then( data => {
            dispatch(setZonasData(data))
        })

    }
}

export const getUser = () => {
    return async( dispatch, getState ) => {
        
        const { committee } = getState().auth;
        const userData = await getAllUserResidential(committee.residential_id);

        Promise.all(userData).then( data => {
            dispatch(setEmpleadosData(data))
        })

    }
}


export const setZonas = (data) => {
    return async( dispatch, getState ) => {
        if(data.zonaActive == null){
            var item = {}
            item.name = data.name
            item.active = true
            item.code = data.code
            item.created_at = moment(new Date(), 'DD/MM/YYYY').toDate();
            item.id_profile_titular = ''

            const { committee } = getState().auth;

            const createdZona = await onCreateOrUpdate(`/residentials/${committee.residential_id}/nomenclature/oficina/houses/`, item);
            item.id = createdZona.id
            item.created_at = moment(item.created_at).format("Y-MM-DD")
            item.residentialId = committee.residential_id
            item.users = []

            dispatch( createZona(item));
        }else{
            var item = {}
            item.name = data.name
            item.code = data.code
            await onUpdateDocWithRef(`/residentials/${data.zonaActive.residentialId}/nomenclature/oficina/houses/${data.zonaActive.id}`, item);
            var zonaActive = {...data.zonaActive}
            zonaActive.name = data.name
            zonaActive.code = data.code
            dispatch( createZona(zonaActive));
        }

        /*var item = {}
        item.name = data.name
        await onUpdateDocWithRef(`/residentials/${data.zonaActive.residentialId}/nomenclature/oficina/houses/${data.zonaActive.id}`, item);
        var zonaActive = {... data.zonaActive}
        zonaActive.name = data.name
        dispatch( createZona(zonaActive));*/

    }
}

export const setZonasPermisos = (data) => {
    return async( dispatch, getState ) => {

         //eliminar permisos
         var z = 0;
         while (z < data.usuariosEliminar.length) {
            await onDeleteDoc(`/permissions`,data.usuariosEliminar[z].id);
            ++z;
        }

        //agrego nuevos
        var i = 0;
        while (i < data.usuarioPermisoNuevos.length) {
            
          var itemPermiso = {}
          itemPermiso.house = "/residentials/"+data.zonaActive.residentialId+"/nomenclature/oficina/houses/"+data.zonaActive.id
          itemPermiso.idProfile = data.usuarioPermisoNuevos[i].id
          itemPermiso.idResidencial = data.zonaActive.residentialId
          await onCreateOrUpdate(`/permissions/`, itemPermiso);

          ++i;
        }

       
        let users = await getNumeroEmpleados(data.zonaActive.residentialId,data.zonaActive.id)
        var zonaActive = {...data.zonaActive}
        zonaActive.users = users

       
        dispatch( createZona(zonaActive));



    }
}