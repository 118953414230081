import { useContext, createContext, useState } from "react"
import { useAppSelector } from "../../../../hooks/store"
import useCurrentLocation from "../../../hooks/useCurrentLocation"
import useRedirectTo from "../../../hooks/useRedirectTo"

//Define previamente las propiedades que existen en el contexto 
const BookingsContext = createContext({
  auth: null, param: null,
  loading: null, setLoading: () => { },
  handleRedirectTo: () => { }
})

//Se exporta el contexto para las reservas para poder compartir los datos en todo el flujo en un mismo lugar
export const BookingsContextProvider = ({ children }) => {
  const auth = useAppSelector(state => state.auth)
  const { param } = useCurrentLocation()
  const { handleRedirectTo } = useRedirectTo()
  const [loading, setLoading] = useState({
    cotizationModal: false,
    requestPage: false,
    confirmModal: false,
    rejectedModal: false,
  })

  const values = {
    auth, param,
    handleRedirectTo,
    loading, setLoading
  }

  return (
    <BookingsContext.Provider value={values}>
      {children}
    </BookingsContext.Provider>
  )
}

export const useBookingContext = () => useContext(BookingsContext)