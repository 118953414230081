import { Row, Col } from 'antd'
import CustomUploadFile from '../../../../../../UI/CustomUploadFile/CustomUploadFile'
import { CustomInput } from '../../../../../../UI/CustomInput'

//1° Seccion del formulario donde se define los datos generales del formulario, como el nombre y la descripción de la amenidad
//además de colocar un CustomUploadFile para subir una imagen referente a la amenidad, y se valida si hay o no en el formulario
//se le añaden las reglas basicas de validacion a los items
const GeneralInfo = ({ Form, fileList, form, setFileList, generalRules }) => (
  <section className="booking-form-section">
    <h2>DATOS GENERALES DE LA AMENIDAD</h2>
    <Row className='general-info' style={{ marginTop: 32 }} gutter={40}>
      <Col span={12}>
        <Form.Item rules={[{ ...generalRules }]} name={'name'} label={'NOMBRE DE AMENIDAD'}>
          <CustomInput placeholder={'Escribir nombre de la amenidad'} />
        </Form.Item>
        <Form.Item rules={[{ ...generalRules }]} name={'description'} label={'DESCRIPCIÓN'}>
          <CustomInput placeholder={'Puedes escribir aquí una descripción de la amenidad'} type='AREA' autoSize={{ minRows: 5, maxRows: 5 }} />
        </Form.Item>
      </Col>
      <Col span={12}>        
        <Form.Item name={'image'} rules={[{
          validator: async (_, value) => {
            if (!fileList?.length) {
              return Promise.reject(new Error('Se requiere una imagen de portada'))
            }
            return Promise.resolve()
          }
        }]} label={'IMAGEN DE PORTADA'}>
          <CustomUploadFile width={'100%'} minHeight={230} height={230} fileList={fileList} setFileList={setFileList} />
        </Form.Item>
      </Col>
    </Row>
  </section>
)

export default GeneralInfo
