import React from "react";
import { Tabs, Space } from "antd";
import { AlertsTab } from "../../alertsReceived/components/AlertsTab";
import { AllPaymentTab } from "../../payments/components/AllPaymentTab";
import { RequestTab } from "../../reservationsRequest/components/RequestTab";
import { useDispatch, useSelector } from "react-redux";
import { shape01, shape03 } from "../../../../assets/images";

export const ImportantTabPane = () => {
	const { TabPane } = Tabs;

	return (
		<Tabs
			defaultActiveKey="1"
			tabBarGutter={400}
			className="general-tabs"
			tabBarStyle={{ justifyContent: "space-between" }}
		>
			<TabPane
				tab={
					<>
						<img src={shape01} alt="alert-shape" />
						Alertas
					</>
				}
				key="1"
			>
				<AlertsTab />
			</TabPane>

			{/* <TabPane
				tab={
					<>
						<img src="/assets/images/shape02.png" alt="Topia" />
						Solicitudes
						<span className="alert green-alert">2 nuevos</span>
					</>
				}
				key="2"
			>
				<RequestTab></RequestTab>
			</TabPane> */}

			<TabPane
				tab={
					<>
						<img src={shape03} alt="credits-card-icon" />
						Pagos recibidos
						{/* {<span className="alert blue-alert">2 nuevos</span>} */}
					</>
				}
				key="3"
			>
				<AllPaymentTab></AllPaymentTab>
			</TabPane>

			{/* <TabPane
				tab={
					<>
						<img src="/assets/images/shape04.png" alt="Topia" />
						Mensajes
						<span className="alert yellow-alert">2 nuevos</span>
					</>
				}
				key="4"
			>
				Tab 4
			</TabPane> */}
		</Tabs>
	);
};
