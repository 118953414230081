import { axiosCreate } from "../../../../../config";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const BOOKING_AWAITING_PAYMENT_STATUS_PATH = 'reservation/tuscania/status/awaiting-payment'

//Endpoint de topia con el que se cambia el estado de una reserva de tuscania a en espera de pago, pasando un formData que incluye el id de la residencial, los costo de invitados
//los costos de vigilancia, de mobilario, el link de pago, la fecha limite de pago y un comentario
export const updateAwaitingPaymentStatusBooking = async ({
    token,
    formData
}) => {

    try {

        const res = await axiosCreate.put(`${BOOKING_AWAITING_PAYMENT_STATUS_PATH}`, formData, {
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }

}