import React from "react";
import { Table, Modal, Col, Row, Spin } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnsAlertTable } from "../../../../helpers/alertHelper";
import { getAlerts, setActiveAlert } from "../../../../store/alerts";
import UserProfileModal from "../../../../components/UserProfileModal";
import { getQueryDocByCollection } from "../../../../helpers/globalHelper";
import { getDataByNomenclatureIdAndHouseIdHelper } from "../../../../helpers/nomenclaturasHelper";
import { shape05, shape06, shape08, shape09, } from '../../../../assets/images'

export const AlertsTab = () => {
	const dispatch = useDispatch();
	const { alerts } = useSelector((state) => state.alerts);
	const [alertList, setAlertList] = useState([]);
	const [alertActive, setAlertActive] = useState([]);
	const [profileModalActive, setProfileModalActive] = useState(false);
	const [userProfileActive, setUserProfileActive] = useState([]);
	// Filtro de botones
	const [filteredInfo, setFilteredInfo] = useState({});
	const [sortedInfo, setSortedInfo] = useState({});
	const [modalVisible, setModalVisible] = useState(false);
	const columns = columnsAlertTable();
	const [loading, setLoading] = useState(false);

	/* useEffect(() => {
		dispatch(getAlerts());
	}, [dispatch]); */

	useEffect(() => {
		setLoading(true);
		setAlertActive(alerts.active);
	}, [alerts.active]);

	useEffect(() => {
		if (alertList.length > 0) {
			setLoading(false);
		}
	}, [alertList]);

	useEffect(() => {
		let dataAlertTable = alerts.data.map((item, index) => {
			let data = { ...item };
			data.key = index;
			data.nameResidente =
				item.user_from_data?.name + " " + item.user_from_data?.lastname;
			data.alert_type = item.notice_type_data?.name.replace("\\n", " ");
			data.detail = (
				<img
					src={shape05}
					style={{ cursor: "pointer" }}
					alt="alert-icon"
					onClick={() => handleStatusModal(true, index)}
				/>
			);
			data.descart = (
				<img
					src={shape06}
					style={{ cursor: "pointer" }}
					alt="white-flag"
				/>
			);

			return data;
		});

		setAlertList(dataAlertTable);
	}, [alerts.data]);

	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};

	const handleChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters);
		setSortedInfo(sorter);
	};

	const clearFilters = () => {
		setFilteredInfo({});
	};

	const clearAll = () => {
		setFilteredInfo({});
		setSortedInfo({});
	};

	const setAgeSort = () => {
		setSortedInfo({
			order: "descend",
			columnKey: "age",
		});
	};

	const handleStatusModal = (statusModal, index = null) => {
		if (index != null) {
			dispatch(setActiveAlert(alerts.data[index]));
		} else {
			dispatch(setActiveAlert(null));
		}

		setTimeout(() => {
			setModalVisible(statusModal);
		}, 50);
	};

	const handleProfileModal = async (statusModal, index = null) => {
		let active_user = alertActive.user_from_data;
		if (active_user.ownerHome) {
			// Agregamosal detalle los dependientes de los socios
			let relativesWithAccess = await getQueryDocByCollection(
				"/family_access",
				"idProfileMain",
				"==",
				active_user.id
			);
			active_user = {
				...active_user,
				relativesWithAccess: relativesWithAccess,
			};
			/* if (active_user.origin_fam) {
				const billingDataHistory = await getPaymentsHelperByAccount(
					active_user.origin_fam
				).then((billingD) => {
					active_user.billingData = billingD;
				});
			} */
			if (active_user.residentials) {
				let nomenclaturePath = active_user.residentials[0]?.house["path"];
				nomenclaturePath = nomenclaturePath.substring(47, 67);

				let housePath = active_user.residentials[0]?.house["path"];
				housePath = housePath.substring(75, 95);

				const houseData = await getDataByNomenclatureIdAndHouseIdHelper(
					"Yd5MV7jkA0MjQeCmzKrZ",
					nomenclaturePath,
					housePath
				).then((houseD) => {
					active_user.houseData = houseD;
				});
			}

			setUserProfileActive(active_user);
		}

		setTimeout(() => {
			setProfileModalActive(statusModal);
		}, 50);
	};

	return (
		<>
			<Table
				columns={columns}
				loading={loading}
				dataSource={alertList}
				onChange={handleChange}
				pagination={{ defaultPageSize: 15, simple: true }}
				locale={locale}
			/>
			<Modal
				className="side-modal alert-modal"
				title="Datos de alerta"
				style={{ top: 0, right: 0 }}
				visible={modalVisible}
				onCancel={() => handleStatusModal(false)}
				footer={null}
			>
				{alertActive && (
					<>
						<Row className="section-modal">
							<Col span={14} style={{ display: "flex" }}>
								<div>
									<img
										src={shape05}
										style={{ cursor: "pointer" }}
										alt="alert-icon"
									/>
								</div>
								<div className="alert-modal-header">
									<span className="title">Alerta</span>
									<h2>
										{alertActive.notice_type_data?.name.replace("\\n", " ")}
									</h2>
								</div>
							</Col>
							<Col span={10}>
								<span className="title">Recibida</span>
								<p className="datetime">
									{alertActive.created_time} - {alertActive.created_at}
								</p>
							</Col>
						</Row>

						<Row className="section-modal">
							<Col span={16}>
								<span className="title">Usuario que reporta:</span>
								<div className="user-data">
									{alertActive.user_from_data?.image && (
										<img
											className="user-image"
											src={alertActive.user_from_data?.image}
											alt="Topia"
										/>
									)}

									<div className="user-info">
										<span className="user-name">
											{alertActive.user_from_data?.name}{" "}
											{alertActive.user_from_data?.lastname}
											<img
												style={{ marginLeft: "10px", cursor: "pointer" }}
												className="iconPerfil"
												src={shape08}
												alt="user-credential-icon"
												onClick={() => handleProfileModal(true)}
											/>
										</span>
										<span className="user-address">
											{" "}
											{alertActive.residential_data?.address}{" "}
										</span>
									</div>
								</div>
							</Col>
							<Col span={8}>
								<div>
									{alertActive.contact_phone && (
										<span
											style={{
												color: "#10B48C",
												position: "relative",
												top: "40px",
											}}
										>
											<img
												style={{ marginLeft: "10px" }}
												src={shape09}
												alt="phone-icon"
											/>

											<span style={{ marginLeft: "5x" }}>
												{alertActive.user_from_data?.phone}
											</span>
										</span>
									)}
								</div>
							</Col>
						</Row>

						<Row className="section-modal">
							<Col span={24}>
								<span className="title">Mensaje: </span>
								<p>{alertActive.message}</p>
							</Col>
						</Row>

						<Row className="section-modal">
							<Col span={24}>
								<span className="title">Respuesta: </span>
								<p>
									{alertActive.answer == undefined ? "-" : alertActive.answer}
								</p>
							</Col>
						</Row>
					</>
				)}
			</Modal>
			{/* MODAL PARA PERFIL SOCIO */}
			<UserProfileModal
				userActive={userProfileActive}
				userModalActive={profileModalActive}
				handleStatusResidentsModal={handleProfileModal}
			/>
		</>
	);
};
