import { Row, Button } from 'antd'
import RangeItem from './components/RangeItem'

//Subcomponente de la configuración de costes donde se verifica si el check de costo por rango de invitados está activo para habilitar la lista y valida si es necesario o no
const InviteesRange = ({ Form, form }) => (
  <Form.Item noStyle shouldUpdate>
    {
      ({ getFieldValue }) => {
        const isEnabled = getFieldValue('active_invitee_cost')
        const cost_options = getFieldValue('invitee_cost_options')
        const disableElement = cost_options !== 'range_cost_invitee' || !isEnabled

        return (
          <Form.List rules={[{
            validator: async (_, value) => {
              if ((value?.length && cost_options === 'range_cost_invitee' && isEnabled) || (isEnabled && cost_options !== 'range_cost_invitee') || !isEnabled) return Promise.resolve()
              return Promise.reject(new Error(`El campo es necesario`))
            }
          }]} name='range_invitees' initialValue={[]}>
            {
              (fields, { add, remove }, { errors }) => (
                <div style={{ overflowY: 'auto', maxHeight: '600px', overflowX: 'hidden', padding: '0 24px' }}>
                  <h3>Define tus rangos</h3>
                  {
                    fields.map(field => ( //Hace un map de los item de rango del Form.List
                      <RangeItem disableElement={disableElement} remove={remove} form={form} field={field} key={field?.key} Form={Form} />
                    ))
                  }
                  <Row justify='center' style={{ marginTop: 16 }} /**Botón que permite añadir nuevos rangos */ >
                    <Button disabled={disableElement} onClick={() => add()} className='btn-standard-white' shape='round' style={{ width: '229px' }}>AGREGAR RANGO</Button>
                  </Row>
                  <Form.ErrorList errors={errors} />
                </div>
              )
            }
          </Form.List>
        )
      }
    }
  </Form.Item>
)

export default InviteesRange
