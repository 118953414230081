import {
	collection,
	query,
	where,
	getDocs,
	doc,
	getDoc,
	orderBy,
} from "firebase/firestore";
import { FirebaseDB } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import { getColumnSearchProps } from "../utils/tables";

export const getCardsByProfileIdHelper = async (profile_id) => {
	const profileRef = doc(FirebaseDB, "profile", profile_id);
	const collectionRef = collection(FirebaseDB, `/profile_tokens`);

	const queryMessages = query(
		collectionRef,
		where("idProfile", "==", profileRef)
	);

	const querySnapshot = await getDocs(queryMessages);
	const list = await cardBody(querySnapshot);
	return list;
};

const cardBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = {
			id: documment.id,
			cardName: data.cardName,
			type: data.ccType,
			lastNumbers: data.ccVal,
			preference: data.preference ? data.preference : "",
		};

		list.push(itemData);
	});

	return list;
};
