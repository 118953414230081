import {
	collection,
	query,
	where,
	getDocs,
	doc,
	getDoc,
	orderBy,
} from "firebase/firestore";
import { FirebaseDB } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import { getColumnSearchProps } from "../utils/tables";

export const getMembersHelper = async (residential_id) => {
	const collectionRef = collection(FirebaseDB, `/committee`);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id)
	);

	const querySnapshot = await getDocs(queryMessages);
	const list = await membersBody(querySnapshot);

	return list;
};

const membersBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (document) => {
		let data = document.data();
		let itemData = {
			id: document.id,
			active: data.active,
			admin: data.admin,
			created_at: moment(data.created_at.toDate()).format("Y-MM-DD"),
			created_time: moment(data.created_at.toDate()).format("HH:mm"),
			lastname: data.lastname,
			name: data.name,
			position: data.position,
			position_name: data.position_name,
			residential_id: data.residential_id,
			profile_id: data.profile_id ? data.profile_id : null,
		};

		list.push(itemData);
	});

	return list;
};
