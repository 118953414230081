import { useEffect } from "react"
import useManagmentAmenities from "../../hooks/useManagmentAmenities"
import HeaderManagmentAmenities from "./components/Header/HeaderManagmentAmenities"
import CustomTable from "../../../../UI/CustomTable/CustomTable"

//Componente principal de la página de Admón. de amenidades donde se coloca un header y se recuperan los datos iniciales de la tabla que se muestra en el main
const MainManagmentAmenities = () => {

  const { getInitialData, handleRedirectTo, managmentAmenitiesTable, initialColumns, loading, handleChangeTable } = useManagmentAmenities()

  useEffect(() => {
    getInitialData()
  }, []);

  return (
    <section className="section-wrapper">
      <HeaderManagmentAmenities handleRedirectTo={handleRedirectTo} />
      <main>
        <CustomTable className="custom-table-variant-3 managment-amenities-table" onChange={handleChangeTable} columns={initialColumns} loading={loading} dataSource={managmentAmenitiesTable.dataTable}
          pagination={{
            pageSize: managmentAmenitiesTable.metadata.limit,
            total: managmentAmenitiesTable.metadata.quantity,
            defaultPageSize: managmentAmenitiesTable.metadata.limit,
            current: managmentAmenitiesTable.currentPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            position: ["bottomRight"]
          }}
        />
      </main>
    </section>
  )
}

export default MainManagmentAmenities
