import React, { useEffect, useState } from "react";
import {
	Space,
} from "antd";
import './ImagePicker.css';

export default ({
	id,
	buttonWidth = "320px",
	label = "Seleccionar imagen",
	multiple = false,
	accept = "image/*",
	onPick = () => {},
}) => {

	const [selectedFiles, setSelectedFiles] = useState();

	useEffect(() => {
		if(!selectedFiles || selectedFiles.length <= 0) {
			return;
		}

		onPick(selectedFiles);
	}, [selectedFiles])

	const onFileChange = async ({target}) => {
		const { files } = target;
		setSelectedFiles(files);
	}

	return (
		<div className="button-picker">
			<input
				id={`image-picker-${id}`}
				className="image-picker"
				type="file"
				accept={accept}
				capture="camera"
				multiple={multiple}
				onChange={onFileChange}
        	/>
        	<label className="ant-btn ant-btn-default" htmlFor={`image-picker-${id}`} style={{width: buttonWidth}}>
        		{label}
        	</label>
		</div>
	)
}