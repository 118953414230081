import React from "react";
import { Col, Modal, Row, Table, Button, Switch, Form, Input, Radio, Space, Descriptions, } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getMoraByProfileIdHelper } from "../helpers/paymentsHelper";
import { checkGris, checkRojo, mensaje, reporte, shape17, shape18, shape19, shape25 } from '../assets/icons'
import { avatarUser, phone } from '../assets/images'

const { TextArea } = Input;

const UserProfileModal = ({
	userActive,
	userModalActive,
	handleStatusResidentsModal,
}) => {
	const [user, setUser] = useState([]);
	const [admonishModalActive, setAdmonishModalActive] = useState(false);
	const [eliminateModalActive, setEliminateModalActive] = useState(false);
	const [userActivationModalActive, setUserActivationModalActive] =
		useState(false);
	const [moras, setMoras] = useState([]);

	useEffect(() => {
		if (userActive?.length !== 0) {
			getMoras(userActive);
		}
	}, []);

	const getMoras = async (userActive) => {
		let mora = await getMoraByProfileIdHelper(
			userActive?.id,
			"Yd5MV7jkA0MjQeCmzKrZ"
		).then((result) => (userActive.moras = result));
	};

	const countMoras = (moras) => {
		let total = 0;
		if (moras) {
			moras.map(async (item, index) => {
				total += +parseFloat(item.amount).toFixed(2);
			});
		}
		return total;
	};

	const IconButtonReport = () => <img src={shape25} alt="eye-scanner-icon" />;

	//Modal functions
	const handleAdmonishModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setAdmonishModalActive(statusModal);
		}, 50);
	};

	const handleEliminateModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setEliminateModalActive(statusModal);
		}, 50);
	};

	const handleActivateModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setUserActivationModalActive(statusModal);
		}, 50);
	};

	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	//Form functions
	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const onFinishAdmonishForm = (e) => {
		const admonishDescription = e.admonishDescription;
	};

	const onFinishEliminateForm = (e) => {
		const eliminateOptions = {
			description: e.eliminateDescription,
			option: e.eliminateOption,
		};
	};

	const onFinishActivateForm = (e) => {
		const activateDescription = e.activateDescription;
	};

	const navigate = useNavigate();

	return (
		<>
			<Modal
				className="side-modal"
				title="Datos de usuario"
				style={{ top: 0, right: 0 }}
				visible={userModalActive}
				onCancel={() => handleStatusResidentsModal(false)}
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
				footer={
					<Row>
						<Col span={18}></Col>

						<Col span={6}>
							<Button
								style={{
									color: "#10b48c",
									borderColor: "#10b48c",
									background: "white",
								}}
								onClick={() => {
									handleEliminateModal(true);
								}}
							>
								<span className="title">Eliminar usuario</span>
							</Button>
						</Col>
					</Row>
				}
				width={600}
			>
				{userActive ? (
					<>
						<div
							className="section-modal-active"
							style={{ marginBottom: "0px" }}
						>
							<p>Activo</p>
							<Switch
								defaultChecked
								style={{ backgroundColor: "#16A07F" }}
								onChange={(checked) => {
									!checked && handleActivateModal(true);
								}}
							/>
						</div>
						<Row className="section-modal" style={{ paddingTop: "0px" }}>
							<Col span={24} style={{ paddingLeft: "20px" }}>
								<div className="user-data">
									{userActive.image ? (
										<div
											style={{
												backgroundImage: `url(` + userActive.image + `)`,
											}}
											className="user-image avatarUser"
											src={userActive.image}
											alt="Topia"
										/>
									) : (
										<img
											className="user-image"
											src={avatarUser}
											alt="avatarUser"
										/>
									)}

									<div className="user-info" style={{ paddingLeft: "20px" }}>
										<span className="user-name">
											{userActive.name} {userActive.lastname}
										</span>
										<span
											className="user-address"
											style={{ paddingTop: "5px", paddingBottom: "5px" }}
										>
											{" Junta directiva: "}
											{userActive.role_id ? userActive.role_id : "N/A"}
										</span>
										{/* <span className="user-address">
											{" "}
											{userActive?.address}{" "}
										</span> */}
										<div style={{ display: "flex" }}>
											<span
												style={{
													color: "#10B48C",
													display: "flex",
													alignItems: "flex-start",
													margin: "0px",
												}}
											>
												<img
													className="iconoPerfil"
													src={phone}
													alt="phone-icon"
												/>

												<span style={{ marginLeft: "5x" }}>
													{userActive.phone ? userActive.phone : ""}
												</span>
											</span>

											<span className="contEmail">
												<img
													className="iconoPerfil"
													src={mensaje}
													alt="message-icon"
												/>
												<div className="contEmails">
													<span className="emails">{userActive.email}</span>
													<span className="emails">{userActive.emailTwo}</span>
												</div>
											</span>
										</div>
									</div>
								</div>
							</Col>
							<Col span={24}>
								<div>
									<img
										style={{ marginLeft: "12px" }}
										src={checkRojo}
										alt="red-check"
									/>
									<img src={checkGris} alt="gray-check-icon" />
									<img src={checkGris} alt="gray-check-icon" />
								</div>
							</Col>
							<Col span={24}>
								<div style={{ paddingTop: "15px", paddingLeft: "0px" }}>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
										}}
										size="small"
										shape="round"
										onClick={() => {
											handleAdmonishModal(true);
										}}
									>
										<span className="title"> Amonestar</span>
									</Button>
								</div>
							</Col>
						</Row>

						<Row className="section-modal">
							<Col span={24}>
								<div className="alert-modal-header">
									<Row>
										<Col span={8}>
											<span className="title" style={{ fontSize: "10px" }}>
												<img
													style={{ marginRight: "10px", display: "inline" }}
													src={shape17}
													alt="keys-icon"
												/>
												Tipo de residente
											</span>
											<div className="alert-modal-text">
												<p>
													{userActive.ownerHome
														? "Propietario"
														: "Familiar o amigo"}
												</p>
											</div>
										</Col>

										<Col span={16}>
											<span className="title" style={{ fontSize: "10px" }}>
												<img
													style={{ marginRight: "10px", display: "inline" }}
													src={shape18}
													alt="pin-location-icon"
												/>
												Dirección de residencia
											</span>
											{/* <p>{userActive.listOfResidentialsData[0]?.address}</p> */}
										</Col>
										{userActive.vehicles_list ? (
											userActive.vehicles_list_data.map((item, key) => {
												return (
													<Col span={6} key={key}>
														<span
															className="title"
															style={{ fontSize: "10px" }}
														>
															<img
																style={{
																	marginRight: "10px",
																	display: "inline",
																}}
																src={shape19}
																alt="car-icon"
															/>
															Auto {key + 1}
														</span>
														<p>
															{item.model} ({item.color}) <br />
															{item.vehicle}
														</p>
													</Col>
												);
											})
										) : (
											<Col span={6}>
												<span className="title" style={{ fontSize: "10px" }}>
													<img
														style={{ marginRight: "10px", display: "inline" }}
														src={shape19}
														alt="car-icon"
													/>
													Auto:
												</span>
												<div
													className="alert-modal-text"
													style={{ marginLeft: "5px" }}
												>
													<p>N/A</p>
												</div>
											</Col>
										)}
										<Col span={18} style={{ paddingLeft: "100px" }}>
											<Button
												style={{
													color: "#10b48c",
													borderColor: "#10b48c",
													background: "white",
												}}
												shape="round"
												icon={<IconButtonReport />}
												onClick={() => {
													navigate(`/reporte-actividad/${userActive.id}`, {
														replace: false,
													});
												}}
											>
												<span className="title" style={{ marginLeft: "10px" }}>
													{" "}
													REPORTE DE ACTIVIDAD
												</span>
											</Button>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>

						<Row className="section-modal">
							<Col span={24}>
								<div className="alert-modal-header">
									<p
										className="user-name"
										style={{ textTransform: "uppercase" }}
									>
										Familiares con acceso
									</p>
								</div>
							</Col>

							{userActive.relativesWithAccess ? (
								<div style={{ marginLeft: "20px" }}>
									<Row>
										{userActive.relativesWithAccess.map((relative, key) => {
											return (
												<Col span={12} key={key}>
													<span style={{ color: "#10B48C", fontSize: "12px" }}>
														{relative.name} ({relative.relationship})
													</span>
												</Col>
											);
										})}
									</Row>
								</div>
							) : (
								<>
									<Col span={24} style={{ textAlign: "center" }}>
										<b>Socios dependientes no encontrados.</b>
									</Col>
								</>
							)}
						</Row>

						<Row className="section-modal">
							<Col span={12}>
								<div className="alert-modal-header">
									<p
										className="user-name"
										style={{ textTransform: "uppercase" }}
									>
										Pagos
									</p>
								</div>

								{userActive.houseData ? (
									<Descriptions
										bordered
										column={1}
										style={{ border: "none" }}
										size="small"
									>
										<Descriptions.Item
											label="Mantenimiento"
											style={{
												width: "auto",
												fontSize: "12px",
												fontWeight: "400",
												color: "#232C4A",
												backgroundColor: "#fff",
												border: "1px solid #fff",
											}}
										>
											${" "}
											{parseFloat(userActive.houseData.maintenanceFee).toFixed(
												2
											)}
										</Descriptions.Item>
										<Descriptions.Item
											label="Construcción"
											style={{
												width: "auto",
												fontSize: "12px",
												fontWeight: "400",
												color: "#232C4A",
												backgroundColor: "#fff",
												border: "1px solid #fff",
											}}
										>
											${" "}
											{parseFloat(userActive.houseData.constructionFee).toFixed(
												2
											)}
										</Descriptions.Item>
										<Descriptions.Item
											label="Residencia"
											style={{
												width: "auto",
												fontSize: "12px",
												fontWeight: "400",
												color: "#232C4A",
												backgroundColor: "#fff",
												border: "1px solid #fff",
											}}
										>
											${" "}
											{parseFloat(userActive.houseData.residenceFee).toFixed(2)}
										</Descriptions.Item>
										<Descriptions.Item
											label="Desechos sólidos"
											style={{
												width: "auto",
												fontSize: "12px",
												fontWeight: "400",
												color: "#232C4A",
												backgroundColor: "#fff",
												border: "1px solid #fff",
											}}
										>
											$ {parseFloat(userActive.houseData.alcaldia).toFixed(2)}
										</Descriptions.Item>
										<Descriptions.Item
											label="Agua"
											style={{
												width: "auto",
												fontSize: "12px",
												fontWeight: "400",
												color: "#232C4A",
												backgroundColor: "#fff",
												border: "1px solid #fff",
											}}
										>
											$ 0.00
										</Descriptions.Item>
										<Descriptions.Item
											label="Energía"
											style={{
												width: "auto",
												fontSize: "12px",
												fontWeight: "400",
												color: "#232C4A",
												backgroundColor: "#fff",
												border: "1px solid #fff",
											}}
										>
											$ 0.00
										</Descriptions.Item>
										{/* <Descriptions.Item
											label="Cobro especial"
											style={{
												width: "auto",
												fontSize: "12px",
												fontWeight: "400",
												color: "#232C4A",
												backgroundColor: "#fff",
												border: "1px solid #fff",
											}}
										>
											$ 0.00
										</Descriptions.Item> */}
										<Descriptions.Item
											label="TOTAL PAGOS"
											style={{
												width: "auto",
												fontSize: "12px",
												fontWeight: "600",
												color: "#232C4A",
												backgroundColor: "#fff",
												border: "1px solid #fff",
												borderTop: "1px dashed #black",
											}}
										>
											$ {parseFloat(userActive.houseData.total).toFixed(2)}
										</Descriptions.Item>
									</Descriptions>
								) : (
									<>
										<Row className="section-modal">
											<Col
												span={24}
												style={{
													textTransform: "uppercase",
													textAlign: "center",
												}}
											>
												<b>Datos de perfil no encontrados</b>
											</Col>
										</Row>
									</>
								)}
							</Col>
							<Col span={12}>
								<div className="alert-modal-header">
									<p
										className="user-name"
										style={{
											textTransform: "uppercase",
											borderBottom: "dashed",
											borderWidth: "1px",
										}}
									>
										Saldo en mora:
									</p>
								</div>
								<div
									className="alert-modal-text"
									style={{
										paddingTop: "1rem",
									}}
								>
									<p>
										Mora total:{" "}
										{userActive.moras
											? countMoras(userActive.moras).toFixed(2).toString()
											: "0.00"}
										<img
											src={reporte}
											alt="report-icon"
											style={{ marginLeft: "5px" }} //Mofificar cuando se agregue el estado de cuenta
										/>
									</p>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
											marginLeft: "3rem",
										}}
										shape="round"
									>
										<span className="title">Enviar recordatorio</span>
									</Button>
								</div>
							</Col>
						</Row>
					</>
				) : (
					<>
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					</>
				)}
			</Modal>

			{/* MODAL PARA AMONESTAR */}

			<Modal
				className="primary-modal"
				title="AMONESTACIÓN"
				visible={admonishModalActive}
				onCancel={() => handleAdmonishModal(false)}
				width={600}
				footer={null}
			>
				<>
					{userActive ? (
						<Form name="admonish_user_form" onFinish={onFinishAdmonishForm}>
							<Row className="section-modal" style={{ fontSize: "14px" }}>
								<Col span={24}>
									<div className="alert-modal-header">
										<span className="title">MOTIVO DE LA AMONESTACIÓN:</span>
									</div>
									<div
										className="alert-modal-header"
										style={{ marginTop: "5px" }}
									>
										<FormItem name="admonishDescription" {...config}>
											<TextArea rows={4} />
										</FormItem>
									</div>
									<div
										className="alert-modal-header"
										style={{
											textAlign: "center",
											textTransform: "uppercase",
											marginTop: "50px",
										}}
									>
										<span className="title">
											¿REALMENTE DESEAS AMONESTAR A {userActive.name}{" "}
											{userActive.lastname}?
										</span>
									</div>
								</Col>
								<Col span={7}></Col>
								<Col span={10} style={{ marginTop: "20px" }}>
									<FormItem>
										<Button
											// disabled={requests.isSaving}
											className="btn-primary"
											type="primary"
											htmlType="submit"
											shape="round"
											style={{ width: "100%", fontSize: "14px" }}
											onClick={() => { }}
										>
											APLICAR AMONESTACIÓN
										</Button>
									</FormItem>
								</Col>

								<Col span={7}></Col>
							</Row>
						</Form>
					) : (
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					)}
				</>
			</Modal>

			{/* MODAL PARA ELIMINAR */}

			<Modal
				className="primary-modal"
				title="ELIMINAR USUARIO"
				visible={eliminateModalActive}
				onCancel={() => handleEliminateModal(false)}
				width={600}
				footer={null}
			>
				<>
					{userActive ? (
						<Form name="eliminate_user_form" onFinish={onFinishEliminateForm}>
							<Row className="section-modal" style={{ fontSize: "14px" }}>
								<Col span={24}>
									<div className="alert-modal-header">
										<FormItem name="eliminateOption" {...config}>
											<Radio.Group>
												<Space direction="vertical">
													<Radio style={{ fontSize: "14px" }} value={1}>
														Eliminar cuenta principal y cuentas de familiares
													</Radio>
													<Radio style={{ fontSize: "14px" }} value={2}>
														Eliminar únicamente el usuario y reasignar cuenta
														principal
													</Radio>
												</Space>
											</Radio.Group>
										</FormItem>
									</div>
									<div className="alert-modal-header">
										<span className="title">MOTIVO:</span>
									</div>
									<div
										className="alert-modal-header"
										style={{ marginTop: "5px" }}
									>
										<FormItem name="eliminateDescription" {...config}>
											<TextArea rows={4} />
										</FormItem>
										<FormItem name="id" hidden={true}>
											{userActive.id}
										</FormItem>
									</div>
									<div
										className="alert-modal-header"
										style={{
											textAlign: "center",
											textTransform: "uppercase",
											marginTop: "50px",
										}}
									>
										<span className="title">
											¿REALMENTE DESEAS ELIMINAR LA CUENTA DE {userActive.name}{" "}
											{userActive.lastname}?
										</span>
									</div>
								</Col>
								<Col span={7}></Col>
								<Col span={10} style={{ marginTop: "20px" }}>
									<FormItem>
										<Button
											// disabled={requests.isSaving}

											type="primary"
											danger
											htmlType="submit"
											shape="round"
											style={{ width: "100%", fontSize: "14px" }}
											onClick={() => { }}
										>
											ELIMINAR
										</Button>
									</FormItem>
								</Col>

								<Col span={7}></Col>
							</Row>
						</Form>
					) : (
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					)}
				</>
			</Modal>

			{/* MODAL PARA DESACTIVAR/ACTIVAR */}

			<Modal
				className="primary-modal"
				title="ACTIVAR/DESACTIVAR USUARIO TEMPORALMENTE"
				visible={userActivationModalActive}
				onCancel={() => handleActivateModal(false)}
				width={600}
				footer={null}
			>
				<>
					{userActive ? (
						<Form name="admonish_user_form" onFinish={onFinishActivateForm}>
							<Row className="section-modal" style={{ fontSize: "14px" }}>
								<Col span={24}>
									<div className="alert-modal-header">
										<span className="title">MOTIVO DE LA DESACTIVACIÓN:</span>
									</div>
									<div
										className="alert-modal-header"
										style={{ marginTop: "5px" }}
									>
										<FormItem name="admonishDescription" {...config}>
											<TextArea rows={4} />
										</FormItem>
									</div>
									<div
										className="alert-modal-header"
										style={{
											textAlign: "center",
											textTransform: "uppercase",
											marginTop: "50px",
										}}
									>
										<span className="title">
											¿REALMENTE DESEAS DESACTIVAR A {userActive.name}{" "}
											{userActive.lastname}?
										</span>
									</div>
								</Col>
								<Col span={7}></Col>
								<Col span={10} style={{ marginTop: "20px" }}>
									<FormItem>
										<Button
											// disabled={requests.isSaving}
											className="btn-primary"
											type="primary"
											htmlType="submit"
											shape="round"
											style={{ width: "100%", fontSize: "14px" }}
											onClick={() => { }}
										>
											DESACTIVAR
										</Button>
									</FormItem>
								</Col>

								<Col span={7}></Col>
							</Row>
						</Form>
					) : (
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					)}
				</>
			</Modal>
		</>
	);
};

export default UserProfileModal;
