import { axiosCreate } from "../../../../../config";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const CONTRATOR_PATH = 'access/by/invitations/report/detail/dependents'

export const getWorkersByAccessAndAccessDate = async ({
    token,
    access_id,
    access_date,
    limit = 5,
    skip = 0,
    find_by,
    find_value,
    sort_by,
    sort_order
}) => {

    try {
        const params = {
            access_id,
            access_date,
            limit,
            skip,
            find_by,
            find_value,
            sort_by,
            sort_order
        }

        const res = await axiosCreate.get(`${CONTRATOR_PATH}`, {
            params,
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }

}