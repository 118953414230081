import { CustomSidebar } from "./UI/CustomSidebar";
import { useSidebar } from "./hooks/useSidebar";

export const Sidebar = ({ rol }) => {
  const {template} = useSidebar();

  return (
    <CustomSidebar rol={rol}>
      {template}
    </CustomSidebar>
  );
};
