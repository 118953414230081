import { useEffect } from 'react'
import { Row, Button } from 'antd'
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import BookingsSettings from "./components/BookingSettings/BookingsSettings"
import CostSettings from "./components/CostSettings/CostSettings"
import GeneralInfo from "./components/GeneralInfo/GeneralInfo"
import MobiliarityInfo from "./components/MobiliarityInfo/MobiliarityInfo"
import Restrictions from "./components/Restrictions/Restrictions"
import ScheduleInfo from "./components/ScheduleInfo/ScheduleInfo"
import useNewAmenity from '../../hooks/useNewAmenity'
import './styles.css'
import BankSettings from './components/BankSettings/BankSettings'

//Componente que renderiza la pantalla inicial de una nueva/editar amenidad
const NewAmenity = () => {

  const { getInitialData, handleDeleteItem, handleChangeTimeDay, onSubmit, onSubmitModal, onFailedSubmit, handleRedirectTo, handleChangeDay, handleOpenCloseModal, handleEnableDisableFormItem, setFileList, fileList, generalRules, showModal, form, Form, mobiliaryData, restrictionsData } = useNewAmenity()

  useEffect(() => {
    getInitialData()
  }, []);

  return (
    <section className="section-wrapper">
      <header>
        <CustomNavigate title={<>
          NUEVA AMENIDAD
          <small style={{ fontSize: 14, fontWeight: 400 }}>Aquí podrás configurar todos los aspectos de tu amenidad</small>
        </>} />
      </header>
      <main style={{ marginTop: 32, paddingBottom: 32 }}
      /**Se define un formulario en este punto que abarca multiples secciones donde se pasa las reglas generales, y otros handles más específicos para cada sección  
       * además de colocar los botones para enviar o cancelar el formulario
      */>
        <Form name='amenity-form' className='amenity-form' layout='vertical' form={form} onFinishFailed={onFailedSubmit} onFinish={onSubmit}>
          <GeneralInfo handleEnableDisableFormItem={handleEnableDisableFormItem} generalRules={generalRules} Form={Form} form={form} setFileList={setFileList} fileList={fileList} />
          <ScheduleInfo handleChangeTimeDay={handleChangeTimeDay} handleEnableDisableFormItem={handleEnableDisableFormItem} generalRules={generalRules} Form={Form} form={form} handleChangeDay={handleChangeDay} />
          <BookingsSettings handleEnableDisableFormItem={handleEnableDisableFormItem} generalRules={generalRules} Form={Form} form={form} />
          <MobiliarityInfo handleDeleteItem={handleDeleteItem} generalRules={generalRules} onSubmit={onSubmitModal} mobiliaryData={mobiliaryData} showModal={showModal.mobiliarityModal.status} handleOpenCloseModal={handleOpenCloseModal} data={showModal.mobiliarityModal.data} Form={Form} form={form} />
          <CostSettings handleEnableDisableFormItem={handleEnableDisableFormItem} generalRules={generalRules} Form={Form} form={form} />
          <BankSettings Form={Form} form={form} generalRules={generalRules} />
          <Restrictions handleDeleteItem={handleDeleteItem} generalRules={generalRules} onSubmit={onSubmitModal} restrictionsData={restrictionsData} showModal={showModal.restrictionModal.status} handleOpenCloseModal={handleOpenCloseModal} data={showModal.restrictionModal.data} Form={Form} form={form} />
          <Row justify='end' style={{ gap: 16 }}>
            <Button onClick={() => handleRedirectTo(-1)} className='btn-standard-white' style={{ width: 179 }} shape='round'>REGRESAR</Button>
            <Button htmlType='submit' className='btn-standard-green' shape='round'>GUARDAR</Button>
          </Row>
        </Form>
      </main>
    </section>
  )
}

export default NewAmenity
