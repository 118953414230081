import React from 'react'
import { Table, Modal, Row, Col, Button,Form,InputNumber } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, setActiveRequest, setEditingRequest, updateRequest,updateRequestAprobado } from '../../../../store/requests';
import { columnsRequestTable } from '../../../../helpers/requestHelper';
import { useForm } from '../../../../hooks';
import TextArea from 'antd/lib/input/TextArea';
import { toast } from 'react-toastify';
import { shape08, shape09, shape10 } from '../../../../assets/images';

export const RequestTab = () => {

  const [form] = Form.useForm();
  const columns = columnsRequestTable();
  const dispatch = useDispatch();
  const { requests } = useSelector( state => state.requests );
  const [ requestList, setRequestList ] = useState([]);
  const [ requestActive, setrequestActive ] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalVisibleReject, setIsModalVisibleReject] = useState(false);
  const [isModalVisibleAprobar, setIsModalVisibleAprobar] = useState(false);

  const { description, onInputChange } = useForm({
    description: '',
    precio:''
  });
  

  useEffect(() => {

    dispatch( getRequest() );    

  }, [ dispatch ]);

  useEffect( ()=>{

    setrequestActive(requests.active);
    if(!requests.active){
      handleStatusModal(false);
    }

  }, [ requests.active ]);

  useEffect( ()=>{

    if(!requests.editing) handleCancelReject() ;
    if(!requests.editing) handleCancelAprobar() ;

  }, [ requests.editing ]);
  
  useEffect(() => {
    
    let _request = requests.data.map( (item, index) => {
      let data = { ...item }
      data.key = index;
      data.action = ( <img src={shape10} style={{ cursor: 'pointer',width:"24px" }} alt="hands-options-shape" onClick={() => handleStatusModal(true, index)} />);
      data.user_request = item.user_who ? item.user_who?.name : ""
      return data;
    });

    setRequestList( _request )

  }, [ requests.data ]);



  const handleChange = (pagination, filters, sorter) => {
  };



  const handleStatusModal = (statusModal, index = null) => {
    
    if(index != null){
      dispatch( setActiveRequest( requests.data[index] ) );
    }else{
      dispatch( setActiveRequest( null ) );
    }

    
    setTimeout(() => {
      setModalVisible(statusModal);
    }, 50);

  }





  // Modal Cancelar

  const showModalReject = () => {
    dispatch( setEditingRequest(requests.active) );

    handleStatusModal(false);
    setIsModalVisibleReject(true);
  };

  const handleOkReject = () => {
    setIsModalVisibleReject(false);
  };

  const handleCancelReject = () => {
    setIsModalVisibleReject(false);
  };

  const handleCancelAprobar = () => {
    setIsModalVisibleAprobar(false)
  };

  const handleApprovedRequest = () => {
    dispatch( setEditingRequest(requests.active) );

    handleStatusModal(false);
    setIsModalVisibleAprobar(true);


    /*let data = {...requests.active};
    data.status = "approved";

    dispatch( setEditingRequest(data) );
    dispatch( updateRequest() );*/

  }

  const onHandleSubmitRequest = () => {
    if(!description){
      toast.warn("Debe ingresar una descripción");
      return;
    }

    
    let data = {...requests.editing};
    data.comment = description;
    data.status = "reject";

    dispatch( setEditingRequest(data) );

    dispatch( updateRequest() );
  }

  const onHandleSubmitRequestAprobar = async() => {
    const values = await form.validateFields();
    let data = {...requests.editing};
    data.amount = values.precio == undefined ? 0 : values.precio
    data.status = "";

    dispatch( setEditingRequest(data) );
    dispatch( updateRequestAprobado() );
  }

  return (
    <>
        
      <Table columns={columns} dataSource={requestList} onChange={handleChange} pagination={{ defaultPageSize: 7 }} />

          
            
      <Modal
      className='side-modal alert-modal'
      title="Datos de solicitud"
      style={{ top: 0, right:0 }}
      visible={modalVisible}
      onCancel={() => handleStatusModal(false)}
      footer = { null }
      >
      {
          requestActive && 
          <>
            <Row className='section-modal'>
                <Col span={14} style={{display: "flex"}}>

                  <div className='alert-modal-header'>
                      <span className='title'>Amenidad solicitada</span>
                      <h2>{ requestActive.area_name }</h2>
                  </div>

                </Col>
                <Col span={10}>
                </Col>
            </Row>

            <Row className='section-modal'>
                <Col span={16}>
                <span className='title'>Usuario que solicita:</span>
                <div className='user-data'>
                    { requestActive.user_who?.image && <img  className='user-image' src={ requestActive.user_who?.image } alt="Topia" /> }
                    
                    <div className='user-info'>
                    <span className='user-name'> 
                        { requestActive.user_who?.name } 
                        <img style={{ marginLeft: "10px" }} className="iconPerfil"  src={shape08} alt="security-credentials-icon" />
                        </span>
                    <span className='user-address'> { requestActive.residential_data?.address } </span>
                    </div>
                </div>

                </Col>
                <Col span={8}>
                  
                  <div>
                        
                    { requestActive.contact_phone &&
                        <span style={{ color: "#10B48C", position: "relative", top: "40px" }}>
                            <img 
                            style={{ marginLeft: "10px" }} 
                            src={shape09}
                            alt="phone-icon" />
                            
                            <span style={{ marginLeft: "5x" }}>
                            {requestActive.user_from_data?.phone}
                            </span>
                        </span>}

                  
                  </div>

                </Col>
            </Row>

            
            <Row className='section-modal'>
                <Col span={12} style={{display: "flex"}}>

                  <div className='alert-modal-header'>
                      <span className='title'>Fecha y hora solicitada</span>
                      <p>{ requestActive.requestDate } - de { requestActive.time_requested }</p>
                  </div>

                  

                </Col>
                <Col span={12}>
                  <div className='alert-modal-header'>
                      <span className='title'>Aproximado de invitados</span>
                      <p>{ requestActive.num_invites}</p>
                  </div>
                </Col>
            </Row>

            
              
            <Row className='section-modal'>
                <Col span={14} style={{display: "flex"}}>

                  <div className='alert-modal-header'>
                      <span className='title'>Estado</span>
                      <p>{ requestActive.status}</p>
                  </div>

                </Col>
                <Col span={10}>
                </Col>
            </Row>
            
            
            
            { requestActive.status == "Rechazado"  && (              
              <Row className='section-modal'>
                  <Col span={14} style={{display: "flex"}}>

                    <div className='alert-modal-header'>
                        <span className='title'>Comentario</span>
                        <p>{ requestActive.comment }</p>
                    </div>

                  </Col>
                  <Col span={10}>
                  </Col>
              </Row>
            )} 

            { requestActive.status == "Pendiente"  && (
              <Row>
                <Col span={24} className="footer-modal">
                  <Button disabled={requests.isSaving} className='btn-deny' type="primary" shape="round" style={{ width: "150px" }} onClick={showModalReject}>
                      Denegar
                  </Button>
                  <Button disabled={requests.isSaving} onClick={handleApprovedRequest} className='btn-primary' type="primary" shape="round" style={{ width: "150px" }}>
                      Aprobar
                  </Button>
                </Col>
              </Row>
            )} 
            

          </>
      }

      </Modal>


      
      <Modal 
        className='primary-modal'
        title="DENEGAR SOLICITUD" 
        visible={isModalVisibleReject}
        onCancel={() => handleCancelReject()}
        footer = { null }>

        <form action="">
          <div className="form-group">
              <label htmlFor="">Motivo de la negación</label>
              <TextArea 
                rows={4} 
                name="description" 
                placeholder="Descripción" 
                value={ description }
                onChange={ onInputChange }/>
          </div>
        </form>

        <div className='footer-buttons'>          
          <Button disabled={requests.isSaving} className='btn-primary' type="primary" shape="round" style={{ width: "150px" }} onClick={onHandleSubmitRequest}>
              Enviar
          </Button>
        </div>
      </Modal>

      <Modal 
        className='primary-modal'
        title="PRECIO DE RESERVA" 
        visible={isModalVisibleAprobar}
        onCancel={() => handleCancelAprobar()}
        footer = { null }>


            <Form
              form={form}
              layout="vertical"
              requiredMark={true}
              >
              
              <Form.Item
                label={
                  <span>
                      Precio:
                  </span>
                }
                name="precio"
                className="form-group">
                 <InputNumber size="large" min={0} max={100000000000} className='inputFormControl'/>
              
              </Form.Item>

            </Form>

        

        <div className='footer-buttons'>          
          <Button disabled={requests.isSaving} className='btn-primary' type="primary" shape="round" style={{ width: "150px" }} onClick={onHandleSubmitRequestAprobar}>
              Enviar
          </Button>
        </div>
      </Modal>
    </>
  )
}
