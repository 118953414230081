import { toast } from "react-toastify";

export const verifyResponse = ({
  dataResponse,
  statusResponse,
  message,
  showToast = true,
  showErrorToast = true,
}) => {
  switch (statusResponse) {
    case 200:
      if (showToast) toast.success(message);
      return true;
    case 201:
      if (showToast) toast.success(message);
      return true;
    case 204:
      if (showToast) toast.success(message);
      return true;
    case 400:
      const missingElement = dataResponse.errors[0].path;
      const finalMessage = dataResponse.errors[0].msg.replace(
        "Elemento",
        missingElement.charAt(0).toUpperCase() + missingElement.slice(1)
      );
      toast.warn(finalMessage);
      return false;
    case "ECONNABORTED":
      if (showErrorToast)
        toast.warn("Tiempo de espera agotado. Intente de nuevo.");
      return false;
    case "ERR_NETWORK":
      if (showErrorToast) toast.error("Error al conectarse al servidor.");
      return false;
    default:
      toast.error(
        dataResponse.errors[0].msg || "Hubo un error, intente de nuevo"
      );

      return false;
  }
};

export default verifyResponse;
