//Puede usarse para transformar un string en un número con formato 0000-0000
export const phoneFormat = ({e, string = ''}) => {
  //Se puede usar en un CustomInput en el evento  onInput o pasar simplemente una cadena de texto
  //Si se pasa un evento "e" tendrá prioridad
  const maxLength = 8
  let value = e ? e.target.value.replace(/\D/g, '') : string.replace(/\D/g, '')

  value = value.slice(0, maxLength)

  //Antes del  5° caracter se añade un "-" 
  if (value.length > 4) {
    value = value.replace(/^(\d{4})/, '$1-')
  }

  //Se retorna el valor ya sea para el input o la cadena en sí dependiendo lo que se le pase
  return e ? e.target.value = value : string = value
}