import { BookingApprovedStatusIcon, CancelledStatusIcon, ComprobantReceivedIcon, ReviewStatusIcon, WaitingStatusIcon } from "../assets"

//Objeto que permite exportar información a partir del estado de una reserva, para dar estilos a un badge que incluye el color de fondo, el color del dot, el color de la letra y el nombre en el badge
export const bookingStatuses = {
    "Espera de cotización": {
        backgroundColor: '#FEEDBE',
        dotColor: '#F6B500',
        color: '#695212',
        name: 'Espera de cotización',
    },
    "En espera de pago": {
        backgroundColor: '#BEFAFE',
        dotColor: '#00BAC6',
        color: '#387377',
        name: 'Espera de pago',
    },
    "En revisión de pago": {
        backgroundColor: '#F1C2F2',
        dotColor: '#DE14FF',
        color: '#591072',
        name: 'En revisión de pago',
    },
    "Aprobada": {
        backgroundColor: '#C2F2DF',
        dotColor: '#10725A',
        color: '#10725A',
        name: 'Aprobada',
    },
    "Cancelada": {
        backgroundColor: '#F2C2C2',
        dotColor: '#FF0A0A',
        color: '#DD3737',
        name: 'Cancelada',
    },
}

//Se exporta un objeto para conocer los estados de la solicitud de reserva
//Incluye el color de fondo, el nombre en el badge, el color del texto y el ícono a mostrar
export const bookingRequestStatuses = {
    awaitingConfirmation: {
        backgroundColor: '#FEEDBE',
        text: 'ESPERA DE ENVÍO DE COTIZACIÓN',
        color: '#695212',
        icon: <WaitingStatusIcon />
    },
    awaitingPayment: {
        backgroundColor: '#FEEDBE',
        text: 'ESPERA DE PAGO',
        color: '#695212',
        icon: <WaitingStatusIcon />
    },
    onPaymentReview: {
        backgroundColor: '#F1C2F2',
        text: 'PAGO EN REVISIÓN',
        color: '#581072',
        icon: <ReviewStatusIcon />
    },
    approved: {
        backgroundColor: '#C2F2DF',
        text: 'RESERVA APROBADA',
        color: '#10725A',
        icon: <BookingApprovedStatusIcon />
    },
    canceled: {
        backgroundColor: '#F2C2C2',
        color: '#DD3737',
        icon: <CancelledStatusIcon />,
        text: 'CANCELADA',
    },
    awaitingComprobant: {
        backgroundColor: '#FEEDBE',
        text: 'ESPERA DE COMPROBRANTE',
        color: '#695212',
        icon: <WaitingStatusIcon />
    },
    comprobantReceived: {
        backgroundColor: '#C2ECF2',
        text: 'COMPROBANTE RECIBIDO',
        color: '#104972',
        icon: <ComprobantReceivedIcon />
    },
}