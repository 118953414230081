import moment from "moment";
import { DatePicker } from "antd";
import calendarIcon from "./assets/calendarIcon.svg";

const { RangePicker } = DatePicker;

const dateFormat = "MMM DD, YYYY";

export const CustomDatePicker = ({
  allowClear,
  className = 'custom-date-picker',
  format,
  id,
  defaultValue,
  value,
  onChange,
  disabled,
  minDate,
  maxDate,
  disabledDatePreviousToday,
  disabledDatePreviousWeek,
  disabledDateBeforeToday,
  disabledDateDependsOnStartDate,
  disabledBeforeCurrentDate,
  disabledRangerStartDate,
  disabledRangerEndDate,
  type,
  width = '100%',
  height,
  placeholder,
  suffixIcon,
  locale,
}) => {
  // Se guarda en una bandera global el tipo de componente que quieren renderizar
  const isRanger = type === 'RANGER'
  // Se guarda la referencia y ruta del componente
  const ComponentDate = isRanger ? RangePicker : DatePicker
  // Se guardan las common props y posteriormente se agregan las demas props dependiendo del type
  const commonProps = {
    locale: locale,
    id: id,
    allowClear: allowClear,
    className: className || "custom-ranger-picker",
    placeholder: placeholder,
    value: value,
    style: { width, height },
    onChange: onChange,
    suffixIcon: <img src={calendarIcon} alt="suffix-icon" />,
    defaultValue: defaultValue,
    format: format || dateFormat,
    disabled: disabled,

  }

  const disabledRangerDate = (current) => {
    return current < moment(disabledRangerStartDate).startOf('day') || current > moment(disabledRangerEndDate).endOf('day')
  }

  const disabledDate = (current) => {
    if (disabledDatePreviousToday) return current && current < moment();
    if (disabledDatePreviousWeek) return current && current < moment().startOf('week').add(7, "days");

    if (disabledDateDependsOnStartDate) return current < disabledDateDependsOnStartDate

    if (disabledBeforeCurrentDate) return moment(current).isBefore(moment())

    if (disabledDateBeforeToday) {
      const todayDate = new Date().setHours(0, 0, 0, 0)
      const momentToJavascriptDate = current.toDate()
      // Can not select days before today
      return current && momentToJavascriptDate < todayDate
    }
  }

  const allProps = isRanger ? {
    ...commonProps,
    minDate,
    maxDate,
    separator: <span style={{ fontSize: 16, padding: 8 }}>-</span>,
    disabledDate: disabledRangerStartDate && disabledRangerEndDate && disabledRangerDate
  } : {
    ...commonProps,
    disabledDate: disabledDate
  }

  return <ComponentDate {...allProps} />
}
