import { collection, query, where, getDocs, orderBy, getDoc } from "firebase/firestore";
import { FirebaseDB } from '../firebase/config';
import moment from 'moment';
import 'moment-timezone';
import { getDocByCollectionAnId } from "./globalHelper";


export const getZonasHelper = async (residential_id) => {

    
   // const collectionResidentialRef = doc( FirebaseDB, '/residentials/'+residential_id );
    const collectionRef = collection( FirebaseDB, '/residentials/'+residential_id+'/nomenclature/oficina/houses/' );
    const queryMessages = query(collectionRef, where("active", "==", true));
    const querySnapshot = await getDocs(queryMessages);
    
    const alerts = [];

    querySnapshot.forEach( async (documment) => {
        let data = documment.data();

        // Definimos los datos a obtener
        let zonaData = {
            active: data.active,
            created_at: moment(data.created_at.toDate()).format("D-MM-Y"),
            created_time: moment(data.created_at.toDate()).format("HH:mm"),
            name: data.name,
            code: data.code,
            residentialId:residential_id,
            id:documment.id
        }

        alerts.push(zonaData);
    });

    return alerts;
}

export const getAllUserResidential = async (residential_id) => {

  const collectionRef = collection( FirebaseDB, `/profile` );
  const queryCollection = query(collectionRef, 
    where("active", "==", true), 
    where('residentialsIds', "array-contains", residential_id),
    orderBy("name")
  );

  const querySnapshot =  await getDocs(queryCollection);
  
  var users = [];

  querySnapshot.forEach( async (documment) => {
      let data = documment.data();
      data.id = documment.id
      users.push(data);
  });

  return users;
}

export const getNumeroEmpleados = async (idResidencial,idHouse) => {
  if(!idResidencial) return null;

  const collectionRef = collection( FirebaseDB, '/permissions/');
  const queryMessages = query(collectionRef, 
    where("idResidencial", "==", idResidencial), 
    where("house", "==", "/residentials/"+idResidencial+"/nomenclature/oficina/houses/"+idHouse) 
  );
  const querySnapshot = await getDocs(queryMessages);

  var users = [];

  querySnapshot.forEach( async (documment) => {
      let data = documment.data();
      data.id = documment.id

      users.push(data);
  });

  return users;

}

export const columnsAlertTable = () => {
    return [
        {
          title: 'Fecha',
          dataIndex: 'created_at',
          key: 'created_at',
        },
        {
          title: 'Nombre',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '',
          dataIndex: 'userZona',
          key: 'userZona',
          width: '180px',
        },
        {
          title: '',
          dataIndex: 'edit',
          key: 'edit',
          width: '150px',
        },
      ];
}