import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Button,
	DatePicker,
	Tabs,
	Modal,
	Form,
	Table,
	Layout,
	Input,
	Select,
} from "antd";
import FormItem from "antd/lib/form/FormItem";

import { Content, Footer } from "antd/lib/layout/layout";
import moment from "moment";
import {
	getMorasHelper,
	getPaymentById,
} from "../../../helpers/paymentsHelper";
import { getUserDataProfileById } from "../../../helpers/globalHelper";
import { mensaje, moneyPhone, phone, send } from "../../../assets/icons";
import { avatarUser } from "../../../assets/images";
const { Option } = Select;

const UsuariosMoraPage = () => {
	const [moraModalActive, setMoraModalActive] = useState(false);
	const [rememberModalActive, setRememberModalActive] = useState(false);
	const [button1, setButton1] = useState(false);
	const [button2, setButton2] = useState(false);
	const [button3, setButton3] = useState(false);
	const [users, setUsers] = useState([]);
	const [moras, setMoras] = useState([]);
	const [userActive, setUserActive] = useState([]);
	const { RangePicker } = DatePicker;
	const { TabPane } = Tabs;
	const dateFormat = "DD/MM/YYYY";
	const { TextArea } = Input;
	//const { users } = useSelector((state) => state.users);

	let userSelectReport = [];

	useEffect(() => {
		/* const getUsers = async () => {
			const user = await getUsersWithMoraHelper("Yd5MV7jkA0MjQeCmzKrZ").then(
				(result) => setUsers(result)
			);
		};
		const getMoras = async () => {
			const mora = await getMorasHelper("Yd5MV7jkA0MjQeCmzKrZ").then((result) =>
				setMoras(result)
			);
		};

		getUsers().catch(console.error); */
		const getMoras = async () => {
			const mora = await getMorasHelper("Yd5MV7jkA0MjQeCmzKrZ").then((result) =>
				setMoras(result)
			);
		};
		getMoras().catch(console.error);
	}, []);

	useEffect(() => {
		const getProfilesForMoras = async () => {
			const _data = moras.map(async (item, index) => {
				let data = { ...item };
				const profile = await getUserDataProfileById(data.profileId.id).then(
					(result) => (data.profileData = result)
				);
				if (data.payments) {
					let totalPayments = 0;
					let totalMoraAmount = 0;
					const totalPaymentsMora = data.payments.map((item) => {
						totalPayments += +parseFloat(item.amount).toFixed(2);
						totalMoraAmount += +parseFloat(item.mora).toFixed(2);
					});
					data.amountWithoutMora = parseFloat(totalPayments).toFixed(2);
					data.amountMora = parseFloat(totalMoraAmount).toFixed(2);

					const paymentsData = data.payments.map(async (item, index) => {
						const paymentsData = await getPaymentById(item.paymentID.id).then(
							(result) => (data.payments[index].data = result)
						);
					});
				}

				return data;
			});

			Promise.all(_data).then((data) => {
				setUsers(data);
			});
		};
		getProfilesForMoras().catch(console.error);
	}, [moras]);

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const IconButtonReport = () => (
		<img
			alt="send-report-icon"
			style={{ width: "20px", height: "20px" }}
			src={send}
		/>
	);
	const customFormat = (value) => `${value.format(dateFormat)}`;

	const onFinishRememberForm = (e) => {
		const title = e.title;
		const mora = e.valorMora;
		const description = e.description;
	};

	const handleMoraModal = (statusModal, record = null) => {
		if (record != null) {
			let active_user = record;

			setUserActive(active_user);
		}
		setTimeout(() => {
			setMoraModalActive(statusModal);
		}, 50);
	};

	const handleRememberModal = (statusModal, record = null) => {
		if (record != null) {
			let active_user = record;

			setUserActive(active_user);
		}
		setTimeout(() => {
			setRememberModalActive(statusModal);
		}, 50);
	};

	const handleSelectedButtom = (button) => {
		if (button === 1) {
			setButton1(true);
			setButton2(false);
			setButton3(false);
		}
		if (button === 2) {
			setButton1(false);
			setButton2(true);
			setButton3(false);
		}
		/* if (button === 3) {
			setButton1(false);
			setButton2(false);
			setButton3(true);
		} */
		if (button > 2 || button < 1) {
			setButton1(false);
			setButton2(false);
			setButton3(false);
		}
	};

	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	/* const modifyMoraType = (value, options) => {
		if (value == 1)
		{
			const newOptions = options.reduce(function (filtered, option) {
				if ()
			})
		}
	} */

	const columns = [
		{
			title: "USUARIO",
			dataIndex: "profileData.name",
			key: "profileData.name",
			align: "center",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			render: (text, record) => (
				<>{record.profileData?.name + " " + record.profileData?.lastname}</>
			),
		},
		{
			title: "PAGOS EN MORA",
			dataIndex: "payments",
			key: "payments",
			align: "center",
			sorter: (a, b) => a.payments.length - b.payments.length,
			render: (id, record, index) => <>{record.payments.length}</>,
		},
		{
			title: "SALDO EN MORA",
			dataIndex: "saldo",
			key: "saldo",
			align: "center",
			sorter: (a, b) => a.amountWithoutMora - b.amountWithoutMora,
			render: (id, record, index) => (
				<>{currencyFormat(record.amountWithoutMora)}</>
			),
		},
		{
			title: "INTERÉSES",
			dataIndex: "interest",
			key: "interest",
			align: "center",
			sorter: (a, b) => a.amountMora - b.amountMora,
			render: (id, record, index) => (
				<div className="">{currencyFormat(record.amountMora)}</div>
			),
		},
		{
			title: "TOTAL",
			dataIndex: "amount",
			key: "amount",
			align: "center",
			sorter: (a, b) => a.amount - b.amount,
			render: (id, record, index) => (
				<>{currencyFormat(parseFloat(record.amount).toFixed(2).toString())}</>
			),
		},
		{
			title: "DETALLE",
			dataIndex: "detail",
			key: "detail",
			align: "center",
			render: (id, record) => (
				<div>
					<img
						className="iconoPerfil"
						src={moneyPhone}
						style={{ cursor: "pointer", width: "26px", height: "26px" }}
						alt="money-phone-icon"
						onClick={() => handleMoraModal(true, record)}
					/>
				</div>
			),
		},
		{
			title: "",
			dataIndex: "detail",
			key: "detail",
			align: "center",
			render: (id, record) => (
				<>
					<Button
						style={{
							color: "#10b48c",
							borderColor: "#10b48c",
							background: "white",
							paddingBottom: "15px",
							marginLeft: "15px",
							marginRight: "15px",
						}}
						shape="round"
						size="small"
						onClick={() => {
							handleRememberModal(true, record);
						}}
						icon={<IconButtonReport />}
					>
						<span className="title" style={{ marginLeft: "10px" }}>
							Enviar recordatorio
						</span>
					</Button>
				</>
			),
		},
	];
	const columnsMora = [
		{
			title: "PERIODO",
			dataIndex: "data.created_at",
			key: "data.created_at",
			align: "center",
			render: (text, record) => (
				<>{moment(record.data.created_at.toDate()).format("Y-MM-DD")}</>
			),
		},
		{
			title: "MONTO",
			dataIndex: "amount",
			key: "amount",
			align: "center",
			render: (text, record) => (
				<>{currencyFormat(parseFloat(record.amount).toFixed(2).toString())}</>
			),
		},
		{
			title: "INTERÉS",
			dataIndex: "address",
			key: "address",
			align: "center",
			render: (text, record) => (
				<>
					{currencyFormat(parseFloat(record.mora).toFixed(2).toString())}{" "}
					{"(" + record.data.porcentaje_mora + "%)"}
					{/* {currencyFormat(record.userProfile?.name.toString())} {record.userProfile?.lastname} */}
				</>
			),
		},
		{
			title: "TOTAL",
			dataIndex: "total",
			key: "total",
			align: "center",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.mora + record.amount)
							.toFixed(2)
							.toString()
					)}
				</>
			),
			width: 120,
		},
	];
	return (
		<div className="wrapper-page">
			<Layout>
				<Content>
					<Row>
						<Col span={24}>
							<h2 className="title-page">Usuarios en mora</h2>
						</Col>
					</Row>

					<>
						<Row>
							<Col span={20}>
								<div>
									<Button
										style={{
											color: button1 ? "#FFFFFF" : "#ADB9BB",
											background: button1 ? "#10B48C" : "white",
											border: "10px",
											fontSize: "11px",
											fontWeight: "500px",
											width: "120px",
										}}
										onClick={() => {
											handleSelectedButtom(1);
										}}
										size="small"
									>
										<span className="title">TODOS</span>
									</Button>
									<Button
										style={{
											color: button2 ? "#FFFFFF" : "#ADB9BB",
											background: button2 ? "#10B48C" : "white",
											marginLeft: "20px",
											border: "none",
											fontSize: "11px",
											fontWeight: "500px",
											width: "120px",
										}}
										onClick={() => {
											handleSelectedButtom(2);
										}}
										size="small"
									>
										<span className="title">MORA AÑO +</span>
									</Button>
									{/* <Button
										style={{
											color: button3 ? "#FFFFFF" : "#ADB9BB",
											background: button3 ? "#10B48C" : "white",
											marginLeft: "20px",
											border: "none",
											fontSize: "11px",
											fontWeight: "500px",
											width: "120px",
										}}
										onClick={() => {
											handleSelectedButtom(3);
										}}
										size="small"
									>
										<span className="title">EN PLAN DE PAGOS</span>
									</Button> */}
								</div>
							</Col>
							<Col span={4} align="right">
								<Select
									placeholder="Seleccione un filtro"
									style={{ width: "80%", fontSize: "12px" }}
									allowClear
									showSearch
									defaultActiveFirstOption={true}
									defaultValue={0}
									onChange={(index) => {
										//setSelectOption(nomenclaturaFull[index]);
									}}
								>
									<Option key={0} value={0} label={"Mantenimiento"}>
										{"Mantenimiento"}
									</Option>
									<Option key={1} value={1} label={"Agua"}>
										{"Agua"}
									</Option>
									<Option key={2} value={2} label={"Energía Eléctrica"}>
										{"Energía Eléctrica"}
									</Option>
								</Select>
							</Col>
							<Col span={24} style={{ marginTop: "2rem" }}>
								<Table
									className="primary-table"
									columns={columns}
									dataSource={users}
									//scroll={{ y: "calc(100vh - 19em)" }}
									//onChange={handleChange}
									rowKey="id"
									pagination={{ pageSize: 20, simple: true }}
								/>
							</Col>
						</Row>
						<Modal
							className="side-modal"
							title="Detalle del pago"
							style={{ top: 0, right: 0 }}
							visible={moraModalActive}
							onCancel={() => handleMoraModal(false)}
							footer={null}
							bodyStyle={{
								overflowY: "auto",
								maxHeight: "calc(100vh - 100px)",
							}}
							width={600}
						>
							{userActive ? (
								<>
									<Row className="section-modal" style={{ paddingTop: "0px" }}>
										<Col span={24}>
											<p>USUARIO</p>
										</Col>
										<Col span={18}>
											<h2>{userActive.profileData?.name}</h2>
										</Col>
										<Col span={6} align="middle">
											<p>TOTAL:</p>
											<h3 style={{ color: "#10B48C" }}>
												{currencyFormat(
													parseFloat(userActive.amount).toFixed(2).toString()
												)}
											</h3>
										</Col>
									</Row>
									<Row className="section-modal" style={{ paddingTop: "0px" }}>
										<Col span={24}>
											<Table
												className="primary-table"
												dataSource={userActive.payments}
												columns={columnsMora}
												pagination={{ defaultPageSize: 8, simple: true }}
												rowKey="paymentID.id"
											/>
										</Col>
										<Col
											span={24}
											align="middle"
											style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
										>
											<Button
												style={{
													color: "#10b48c",
													borderColor: "#10b48c",
													background: "white",
													paddingBottom: "15px",
													marginLeft: "15px",
													marginRight: "15px",
												}}
												shape="round"
												size="large"
												onClick={() => {
													handleRememberModal(true, userActive);
												}}
												icon={<IconButtonReport />}
											>
												<span className="title" style={{ marginLeft: "10px" }}>
													Enviar recordatorio
												</span>
											</Button>
										</Col>
										{/* <Col span={22}>
											<h2>Plan de pagos</h2>
										</Col>
										<Col span={2}>
											<Switch
												defaultChecked
												style={{ backgroundColor: "#16A07F" }}
												onChange={(checked) => {
													
												}}
											/>
										</Col> */}
									</Row>
								</>
							) : (
								<>Datos no encontrados</>
							)}
						</Modal>
						<Modal
							className="primary-modal"
							title={
								<Row className="section-modal" style={{ textAlign: "center" }}>
									<Col span={24}>
										<h2 style={{ color: "#232C4A" }}>RECORDATORIO DE PAGO</h2>
									</Col>
								</Row>
							}
							visible={rememberModalActive}
							onCancel={() => handleRememberModal(false)}
							footer={null}
							width={600}
						>
							{userActive ? (
								<>
									<Row style={{ height: "100px" }}>
										<Col span={3}></Col>
										<Col span={3}>
											<img
												className="user-image"
												src={avatarUser}
												alt="avatar-user-icon"
											/>
										</Col>
										<Col span={15}>
											<div className="user-info">
												<h3 className="user-name" style={{ color: "#232C4A" }}>
													{userActive.profileData?.name}
												</h3>
											</div>
											<div className="user-info">
												<span
													style={{
														color: "#10B48C",

														margin: "0px",
													}}
												>
													<img src={phone} alt="phone-icon" />

													<span style={{ marginLeft: "5x" }}>
														{userActive.profileData?.phone}
													</span>
												</span>
												<img
													style={{ marginLeft: "3rem" }}
													src={mensaje}
													alt="message-icon"
												/>
												<span className="" style={{}}>
													{userActive.profileData?.email}
												</span>
											</div>
										</Col>
										<Col span={3}></Col>
									</Row>
									<Row
										className="section-modal"
										style={{ paddingTop: "0px" }}
									></Row>
									<Row className="section-modal">
										<Col span={5}></Col>
										<Col span={14} style={{ width: "90%" }}>
											<Form
												name="remember_user_form"
												onFinish={onFinishRememberForm}
											>
												<div
													className="section-modal"
													style={{ paddingLeft: "5px" }}
												>
													<p>TITULO:</p>
												</div>
												<FormItem name="title" {...config}>
													<Input
														placeholder="Recordatorio de pago"
														style={{
															backgroundColor: "#F2F2F2",
															borderRadius: "5px",
														}}
													/>
												</FormItem>
												<div
													className="section-modal"
													style={{ paddingLeft: "5px" }}
												>
													<p>VALOR EN MORA:</p>
												</div>
												<FormItem name="valorMora" {...config}>
													<Input
														prefix={"$"}
														style={{
															backgroundColor: "#F2F2F2",
															borderRadius: "5px",
														}}
													/>
												</FormItem>
												<div
													className="section-modal"
													style={{ paddingLeft: "5px" }}
												>
													<p>MENSAJE ADICIONAL:</p>
												</div>
												<FormItem name="description" {...config}>
													<TextArea
														rows={4}
														style={{
															backgroundColor: "#F2F2F2",
															borderRadius: "5px",
														}}
													/>
												</FormItem>
											</Form>
										</Col>
										<Col span={5}></Col>
										<Col span={24} align="middle">
											<Button
												style={{
													borderColor: "#10b48c",
													background: "#10b48c",
													paddingBottom: "15px",
													width: "250px",
												}}
												shape="round"
												size="large"
											>
												<span className="title" style={{ color: "#fff" }}>
													ENVIAR
												</span>
											</Button>
										</Col>
									</Row>
								</>
							) : (
								<>Datos no encontrados</>
							)}
						</Modal>
					</>
				</Content>
				<Footer></Footer>
			</Layout>
		</div>
	);
};

export default UsuariosMoraPage;
