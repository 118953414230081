import { collection, query, where, getDocs, doc, getDoc,orderBy } from "firebase/firestore";
import { FirebaseDB } from '../firebase/config';
import moment from 'moment';
import 'moment-timezone';
import {getColumnSearchProps} from '../utils/tables';

export const getRequestHelper = async (residential_id) => {
    const collectionRef = collection( FirebaseDB, `/booking` );
    const queryMessages = query(collectionRef, 
      where("active", "==", true), 
      where("residential_id", "==", residential_id),
      orderBy("date", "desc")
      );
    const querySnapshot = await getDocs(queryMessages);
    
    const alerts = [];
    querySnapshot.forEach( async (documment) => {
        let data = documment.data();
        var status = ""
        if(data.status == "pending" && data.payment == "pending"){
          status = "Pendiente Pago"
        }else if(data.status == "pending"){
          status = "Pendiente"
        }else if(data.status == "approved"){
          status = "Aprobado"
        }else  if(data.status == "reject"){
          status = "Rechazado"
        }

        let alertData = {
            id: documment.id,
            active: data.active,
            created_at: data.created_at && moment(data.created_at.toDate()).format("Y-MM-DD"),
            created_time: data.created_at && moment(data.created_at.toDate()).format("HH:mm"),
            requestDate: data.date && moment(data.date.toDate()).format("Y-MM-DD"),
            residential_id: data.residential_id ? data.residential_id  : null,
            request_who_id: data.request_who ? data.request_who  : null,
            payment: data.payment,
            status: status,
            time_begin: data.time_begin,
            time_end: data.time_end,
            time_requested: `${data.time_begin} - ${data.time_end}`,
            num_invites: data.num_invites,
            event: data.event,
            area_name: data.area_name,
            area_id: data.area_id,
            comment: data.comment
        }

        alerts.push(alertData);
    });

    return alerts;
}



export const columnsRequestTable = () => {
    return [
      {
        title: 'Fecha solicitada',
        dataIndex: 'requestDate',
        key: 'requestDate',
        ...getColumnSearchProps('requestDate'),
      },
        
      {
        title: 'Hora solicitada',
        dataIndex: 'time_requested',
        key: 'time_requested',
        ...getColumnSearchProps('time_requested'),
      },
        {
          title: 'Amenidad Solicitada',
          dataIndex: 'area_name',
          key: 'area_name',
          ...getColumnSearchProps('area_name'),
        },
        {
          title: 'Quién solicita',
          dataIndex: 'user_request',
          key: 'user_request',
          ...getColumnSearchProps('user_request'),
        },
        {
          title: 'Estado',
          dataIndex: 'status',
          key: 'status',
          ...getColumnSearchProps('status'),
        },
        {
          title: 'Acción',
          dataIndex: 'action',
          key: 'action',
        },
      ];
}