import { collection, query, where, orderBy,getDocs, doc, getDoc } from "firebase/firestore";
import { FirebaseDB } from '../firebase/config';
import moment from 'moment';
import 'moment-timezone';


export const getSurveysHelper = async (residential_id) => {
    const collectionRef = collection( FirebaseDB, `/surveys` );
    const queryCollection = query(collectionRef, where("active", "==", true), where('residential_id', "==", residential_id), orderBy("created_at"));
    const querySnapshot = await getDocs(queryCollection);
    
    const dataList = [];
    querySnapshot.forEach( async (documment) => {
        let data = documment.data();
        data.id = documment.id;
        data.created_at = data.created_at == null ? "" : moment(data.created_at.toDate()).format("Y-MM-DD")
        data.startDate = data.startDate == null ? "" : moment(data.startDate.toDate()).format("Y-MM-DD")
        data.endDate = data.endDate == null ? "" : moment(data.endDate.toDate()).format("Y-MM-DD")

        var respuesta = []
        /*const collectionRefS = collection( FirebaseDB, `/surveys_response` );
        const queryCollectionS = query(collectionRefS, 
          where("surveys_id", "==", documment.id), 
          where('residential_id', "==", residential_id)
        );
        const querySnapshotS =  await getDocs(queryCollectionS);
        querySnapshotS.forEach( async (docummentR) => {
          let dataR = docummentR.data();
          dataR.idR = docummentR.id;
          respuesta.push(dataR)
        }*/

        data.respuesta = respuesta

        dataList.push(data);
    });

    return dataList;
}


export const getSurveysResponseHelper = async (residential_id,surveys_id) => {

  var respuesta = []
  const collectionRefS = collection( FirebaseDB, `/surveys_response` );
  const queryCollectionS = query(collectionRefS, 
    where("surveys_id", "==", surveys_id), 
    where('residential_id', "==", residential_id),
    orderBy("profile_id")
  );

  const querySnapshotS =  await getDocs(queryCollectionS);
  querySnapshotS.forEach( async (docummentR) => {

    let dataR = docummentR.data();
    dataR.idR = docummentR.id;
    respuesta.push(dataR)
  })


  return respuesta;
}

export const getSurveysQuestionHelper = async (surveys_id) => {

  var respuesta = []
  const collectionRefS = collection( FirebaseDB, `/surveys/`+surveys_id+`/questions` );
  const queryCollectionS = query(collectionRefS);

  const querySnapshotS =  await getDocs(queryCollectionS);
  querySnapshotS.forEach( async (docummentR) => {

    let dataR = docummentR.data();
    dataR.idR = docummentR.id;
    respuesta.push(dataR)
  })

  return respuesta;
}

export const getAllUserResidential = async (residential_id) => {

  var totalUser = 0
  const collectionRef = collection( FirebaseDB, `/profile` );
  const queryCollection = query(collectionRef, 
    where("active", "==", true), 
    where('residentialsIds', "array-contains", residential_id),
  );

  const querySnapshot =  await getDocs(queryCollection);
  totalUser = querySnapshot.size
  return totalUser;
}

export const columnsAlertTable = () => {
    return [
        {
          title: 'Fecha',
          dataIndex: 'created_at',
          key: 'created_at',
        },
        {
          title: 'Nombre',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Fecha Inicio',
          dataIndex: 'start_date',
          key: 'start_date'
        },
        {
          title: 'Fecha Fin',
          dataIndex: 'end_date',
          key: 'end_date',
        },
        {
          title: 'Tipo Encuesta',
          dataIndex: 'survey_type',
          key: 'survey_type',
        },
      ];
}