import { Row, Col } from 'antd'
import ScheduleDays from './components/ScheduleDays/ScheduleDays'
import ScheduleDetails from './components/ScheduleDetails'

//2° Sección del formulario donde se muestra el horario para las reservas, para ver los días de los horarios, y otras configuraciónes al respecto
const ScheduleInfo = ({ Form, form, handleChangeDay, handleEnableDisableFormItem, generalRules, handleChangeTimeDay }) => (
  <section className='booking-form-section'>
    <h2>HORARIOS</h2>
    <Row style={{ margin: '32px 0' }} gutter={16}>
      <Col span={12} style={{ borderRight: '1px solid #969696' }}>
        <ScheduleDays handleChangeTimeDay={handleChangeTimeDay} generalRules={generalRules} handleEnableDisableFormItem={handleEnableDisableFormItem} handleChangeDay={handleChangeDay} form={form} Form={Form} />
      </Col>
      <Col span={12} style={{ paddingLeft: 48 }}>
        <ScheduleDetails generalRules={generalRules} form={form} Form={Form} />
      </Col>
    </Row>
  </section>
)

export default ScheduleInfo