import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
	TimePicker,
} from "antd";
import "./styles.css";

export const ScheduleButton = ({ dayButton, setDayButton, day }) => {
	/* const [dayButton, setDayButton] = useState([]); */
	return (
		<Space>
			<Button
				style={{
					color: dayButton ? "#FFFFFF" : "#232C4A",
					background: dayButton ? "#10B48C" : "#EEEEEE",
					border: "10px",
					fontSize: "11px",
					fontWeight: "500px",
					width: "40px",
					height: "40px",
				}}
				onClick={() => {
					setDayButton(!dayButton);
				}}
			>
				<Row>
					<Col span={24} align="middle">
						<span style={{ textAlign: "center", alignContent: "center" }}>
							{day}
						</span>
					</Col>
				</Row>
			</Button>
			<div className="rangepicker">
				<TimePicker.RangePicker
					use12Hours
					format="HH:mm"
					disabled={!dayButton}
				/>
			</div>
		</Space>
	);
};
