import {
	collection,
	query,
	where,
	getDocs,
	doc,
	getDoc,
	orderBy,
} from "firebase/firestore";
import { FirebaseDB } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import { getColumnSearchProps } from "../utils/tables";

export const getAgentsHelper = async (residential_id) => {
	const collectionRef = collection(FirebaseDB, `/agents`);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("idResidential", "==", residential_id)
	);

	const querySnapshot = await getDocs(queryMessages);
	const list = await agentBody(querySnapshot);
	return list;
};
export const getAgentByIdHelper = async (agent_id) => {
	const collectionRef = doc(FirebaseDB, `/agents/` + agent_id);

	const docSnap = await getDoc(collectionRef);

	const data = docSnap.data();
	const agent = {
		id: docSnap.id,
		active: data.active,
		active_service: data.active_service,
		code: data.code,
		company: data.company,
		contract_type: data.contract_type,
		document: data.document,
		residential_id: data.idResidential ? data.idResidential : null,
		lastname: data.lastname,
		name: data.name,
		nomenclatures: data.nomenclatures,
		phone: data.phone,
		role_id: data.role_id,
		token: data.token,
		user: data.user,
		user_id: data.user_id,
	};

	return agent;
};

export const getRoleById = async (role_id) => {
	const collectionResidentialRef = doc(FirebaseDB, "/roles/" + role_id);

	const docSnap = await getDoc(collectionResidentialRef);
	const data = docSnap.data();
	const role = [
		{
			activeApp: data.activeApp,
			description: data.description ? data.description : "",
			name: data.name,
		},
	];

	return role;
};

export const getAgentScheduleById = async (id) => {
	const collectionResidentialRef = doc(FirebaseDB, "/security_turn/" + id);

	const docSanp = await getDoc(collectionResidentialRef);
	const data = docSanp.data();
	const schedule = [
		{
			active: data.active,
			days: data.days,
			list_security: data.list_security,
			name: data.name,
			residential_id: data.residential_id,
			time_begin: data.time_begin,
			time_end: data.time_end,
		},
	];

	return schedule;
};

export const getAgentsSchedules = async (residential_id) => {
	const collectionRef = collection(FirebaseDB, "/security_turn");

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id)
	);

	const querySnapshot = await getDocs(queryMessages);
	const list = await scheduleBody(querySnapshot);
	return list;
};

export const getAgentTurnsById = async (residential_id, id) => {
	const collectionRef = collection(FirebaseDB, "/security_turn");

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("list_security", "array-contains", id)
	);

	const querySnapshot = await getDocs(queryMessages);
	const list = await scheduleBody(querySnapshot);
	return list;
};

const scheduleBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (document) => {
		let data = document.data();
		let itemData = {
			id: document.id,
			active: data.active,
			days: data.days,
			list_security: data.list_security,
			name: data.name,
			residential_id: data.residential_id,
			time_begin: data.time_begin,
			time_end: data.time_end,
			created_at: moment(data.created_at.toDate()).format("Y-MM-DD"),
			updated_at: moment(data.updated_at.toDate()).format("Y-MM-DD"),
		};
		list.push(itemData);
	});
	return list;
};

const agentBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = {
			id: documment.id,
			active: data.active,
			active_service: data.active_service,
			code: data.code,
			company: data.company,
			contract_type: data.contract_type,
			document: data.document,
			residential_id: data.idResidential ? data.idResidential : null,
			lastname: data.lastname,
			name: data.name,
			nomenclatures: data.nomenclatures,
			phone: data.phone,
			role_id: data.role_id,
			token: data.token,
			user: data.user,
			user_id: data.user_id,
		};

		list.push(itemData);
	});

	return list;
};
