import { Row, Col } from 'antd'
import CustomItemForm from '../../../../UI/CustomItemForm'

//3° sección del formuilario de una amenidad para relizar la configuración de las reservas
//donde se coloca el tiempo mínimo para una reserva, la cantidad máxima de invitados (opcional), los días de anticipación para la reserva (opcional) y el periodo anticipado para envío de recordatorio
const BookingsSettings = ({ Form, handleEnableDisableFormItem, generalRules, options = [] }) => (
  <section className="booking-form-section">
    <h2>CONFIGURACIÓN DE RESERVAS</h2>
    <Row style={{ margin: '32px 0' }} gutter={16}>
      <Col span={12} style={{ borderRight: '1px solid #969696' }}>
        <Row>
          <CustomItemForm handleEnableDisableFormItem={handleEnableDisableFormItem} Form={Form} switchContainer={false} inputName={'min_time_amenity'} inputLabelName={'TIEMPO MÍNIMO PARA UNA RESERVA'} />
          <CustomItemForm handleEnableDisableFormItem={handleEnableDisableFormItem} Form={Form} switchContainer switchName={'can_have_invitees'} inputName={'max_invitees'} switchLabel={'Los residentes podrán tener invitados'} inputLabelName={'CANTIDAD MÁXIMA DE INVITADOS'} />
        </Row>
      </Col>
      <Col span={12} style={{ paddingLeft: 48 }}>
        <CustomItemForm handleEnableDisableFormItem={handleEnableDisableFormItem} Form={Form} switchContainer switchName={'required_advanced'} inputName={'anticipate_days'} switchLabel={'Las reservas requieren anticipación'} inputLabelName={'Días de anticipación para hacer una reserva'} />
        <CustomItemForm marginTop={40} handleEnableDisableFormItem={handleEnableDisableFormItem} Form={Form} switchContainer={false} inputName={'period_sending_reminder'} inputLabelName={<span>Periodo anticipado para envío de recordatorio</span>} selectItem selectOptions={[{ id: '1', name: '1 día' }]} />
      </Col>
    </Row>
  </section>
)

export default BookingsSettings
