import { ReactComponent as DefaultIcon } from '../assets/defaulDoc.svg'
import { ReactComponent as WordIcon } from '../assets/filesIcon/docxIcon.svg'
import { ReactComponent as ExcelIcon } from '../assets/filesIcon/xlsxIcon.svg'
import { ReactComponent as PowerPointIcon } from '../assets/filesIcon/pptxIcon.svg'
import { ReactComponent as PDFIcon } from '../assets/filesIcon/pdfIcon.svg'

export const typeFiles = {
    docx: <WordIcon />,
    doc: <WordIcon />,
    xlsx: <ExcelIcon />,
    xls: <ExcelIcon />,
    pptx: <PowerPointIcon />,
    ppt: <PowerPointIcon />,
    pdf: <PDFIcon />,
    default: <DefaultIcon />
}