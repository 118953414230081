import { Col, Row } from "antd"
import { CustomNavigate } from "../../../../../UI/CustomNavigate"
import useHistoryAccess from "../../../hooks/useHistoryAccess/useHistoryAccess"
import { CustomDatePicker } from "../../../../../UI/CustomDatePicker/CustomDatePicker"
import CustomTable from "../../../../../UI/CustomTable/CustomTable"
import calendarSuffixIcon from '../../../assets/calendarSuffixIcon.svg'
import { useEffect } from "react"
import './styles.css'
import moment from "moment"
import { Navigate } from "react-router-dom"

export const HistoryAccess = () => {

  const { monitoringContratorsTable, historyAccessTable, columns, loading, date_from, date_to, getInitialData, handleChangeDatePickerFilter, handleChangeTable } = useHistoryAccess()

  useEffect(() => {
    getInitialData()
  }, []);

  //Si no hay un objeto activo que proviene de la pantalla inicial de Monitoreo de contratista, se retorna a dicha pantalla
  if(!Object.keys(monitoringContratorsTable.active).length) return <Navigate to={'/access/monitoring-contrators'} />

  return (
    <div className="section-wrapper">
      <CustomNavigate title={<span>PROYECTO: <span style={{ color: '#0D9C7A' }}> {monitoringContratorsTable.active.place}</span></span>} />
      <Row justify="end" style={{ marginBottom: 12 }}>
        <Col offset={16} span={6}>
          <CustomDatePicker defaultValue={[moment(date_from), moment(date_to)]} disabledRangerStartDate={date_from} disabledRangerEndDate={date_to} allowClear={false} onChange={handleChangeDatePickerFilter} type={'RANGER'} height={40} suffixIcon={calendarSuffixIcon} />
        </Col>
      </Row>
      <CustomTable rowKey={'key'} onChange={handleChangeTable} loading={loading} className="custom-table-variant-1 history-access-table" columns={columns} dataSource={historyAccessTable.dataTable}
        pagination={{
          pageSize: historyAccessTable.metadata.limit || undefined,
          total: historyAccessTable.metadata.quantity || undefined,
          defaultPageSize: historyAccessTable.metadata.limit || undefined,
          current: historyAccessTable.currentPage || undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          position: ["bottomRight"]
        }} />
    </div>
  )
}

export default HistoryAccess