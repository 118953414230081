import { Row, Button } from 'antd'
import RestrictionItem from './components/RestrictionItem'
import NewRestrictionModal from './components/NewRestrictionModal'

//7° sección del formulario de amenidad que muestra las restricciones informativas para dicha amenidad y además de tener la posibilidad de añadir/editar o eliminar una restricción
const Restrictions = ({ Form, form, handleOpenCloseModal, showModal, onSubmit, data, restrictionsData, handleDeleteItem, generalRules }) => (
    <section className="booking-form-section">
        <Row align='middle' justify='space-between'>
            <div>
                <h2 style={{ margin: 0 }}>RESTRICCIONES INFORMATIVAS</h2>
                <small>Aquí podrás gestionar restricciones adicionales que desees agregar como que "No se permiten mascotas" o "Solo mayores de edad"</small>
            </div>
            <Button onClick={/*Se coloca la función para abrir el modal de una restricción*/
            () => handleOpenCloseModal({ name: 'restrictionModal', value: true })} className='btn-standard-white' shape='round' style={{ width: 179 }}>AGREGAR</Button>
        </Row>
        <div className='mobiliarity-items'>
            <Form.List name={'mobiliary_data'} rules={[{
                validator: async (_, value) => {
                    if (!restrictionsData?.length) { //Valida si hay al menos una restricción añadida
                        return Promise.reject(new Error('Se requiere al menos una restricción'))
                    }
                    return Promise.resolve()
                }
            }]} >
                {
                    (fields, { add, remove }, { errors }) => (
                        <>
                            {
                                restrictionsData.map(item => (
                                    <RestrictionItem key={item?.id} handleOpenCloseModal={handleOpenCloseModal} handleDeleteItem={handleDeleteItem} itemData={item} />
                                ))
                            }
                            <Form.ErrorList errors={errors} />
                        </>
                    )
                }

            </Form.List>
        </div>
        {/**Se coloca aquí el modal de restricción para ser abierto o cerrado */}
        <NewRestrictionModal generalRules={generalRules} handleOpenCloseModal={handleOpenCloseModal} showModal={showModal} onSubmit={onSubmit} data={data} />
    </section>
)

export default Restrictions
