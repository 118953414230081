import { useRef, useEffect, useCallback } from 'react';

export const useDebounce = (callback, delay) => {
  // Usar useRef para almacenar el último timeout id
  const timeoutRef = useRef(null);

  // Esta es la función que se devuelve y que puede ser llamada múltiples veces
  const debouncedCallback = useCallback((...args) => {
    // Si ya hay un temporizador en marcha, lo cancelamos
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Establecer un nuevo temporizador
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]); // Se volverá a crear si callback o delay cambian

  // Limpieza si el componente se desmonta o si delay cambia
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [delay]);

  return debouncedCallback;
};
