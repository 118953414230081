
//Componente que renderiza el motivo de cancelación de una reserva, en lugar de mostrar los botones para crear cotizacion o aprobar o rechazar una reserva
const CanceledReason = ({ comment }) => (
  <section style={{ border: '1px solid #FF9393', backgroundColor: '#FFEDED', padding: '16px 32px', marginBottom: 32 }} className='section-request-booking'>
    <h4>Motivo de cancelación</h4>
    <span>{comment ? comment : 'No se especificó un motivo de cancelación.'}</span>
  </section>
)

export default CanceledReason
