import { Divider } from 'antd'
import BookingStatus from "./components/BookingStatus"
import EventDetail from "./components/EventDetail"
import UserDetail from "./components/UserDetail"

//Componente que renderiza en la parte derecha de la página los detalla principales de la solicitud de la reserva que se divide en 3 subcomponentes
const BookingDetails = ({ data }) => (
  <section className='section-request-booking'>
    <UserDetail profileData={data?.profile} />
    <Divider />
    <EventDetail eventData={data?.event} />
    <Divider />
    <BookingStatus
      statusCode={data?.event?.reservation_status_code}
      statusName={data?.event?.reservation_status_name}
      paymentImage={data?.event?.reservation_payment_asset}
      paymentDate={data?.event?.reservation_payment_creation}
    />
  </section>
)

export default BookingDetails
