import { Col, Row } from 'antd';
import { UsersTabs } from './components/UsersTabs';

export const UsersPage = () => {

  


    return (
        <>
    
          <div className="wrapper-page">
            <h2 className='title-page'>
                Perfiles de usuarios
            </h2>
    
              <Row>
    
                <Col span={24}>
                   
                    <UsersTabs></UsersTabs>
                </Col>
    
    
              </Row>
          </div>
        </>
        
    )
}


