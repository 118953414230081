import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import {
	getQueryDocByCollection,
	getUserDataProfileById,
	onUpdateDocWithRef,
	onUpdateDocWithRefInfo,
} from "../../../../helpers/globalHelper";
import moment from "moment";
import UserProfileModal from "../../../../components/UserProfileModal";
import { getDataByNomenclatureIdAndHouseIdHelper } from "../../../../helpers/nomenclaturasHelper";

export const MemberContainer = ({
	data,
	fullUsers,
	userOptions,
	fullRoles,
	roleOptions,
}) => {
	const [committee, setCommittee] = useState([]);
	const [assignRoleModalActive, setAssignRoleModalActive] = useState(false);
	const [editRoleModalActive, setEditRoleModalActive] = useState(false);
	const [newMemberModalActive, setNewMemberModalActive] = useState(false);
	const [newRoleModalActive, setNewRoleModalActive] = useState(false);
	const [allUsersOptions, setAllUsersOptions] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [allRoles, setAllRoles] = useState([]);
	const [allRolesOptions, setAllRolesOptions] = useState([]);
	const [selectedUser, setSelectedUser] = useState([]);
	const [selectedRole, setselectedRole] = useState([]);
	const [fullCommittee, setFullCommittee] = useState([]);
	const [profileModalActive, setProfileModalActive] = useState(false);
	const [userProfileActive, setUserProfileActive] = useState([]);
	const [fullCommitteeButton, setFullCommitteeButton] = useState([]);

	const { TextArea } = Input;

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	useEffect(() => {
		setCommittee(data);
		setAllUsers(fullUsers);
		setAllRoles(fullRoles);
		setAllRolesOptions(roleOptions);
		setAllUsersOptions(userOptions);
	}, []);

	useEffect(() => {
		addProfileButton(committee);
	}, [committee]);

	useEffect(() => {
		let data = { ...fullCommittee };
		data.profile = (
			<img
				className="iconPerfil"
				src="/assets/icons/shape08.svg"
				style={{ cursor: "pointer" }}
				alt="Topia"
				onClick={() => handleProfileModal(true, data.profileData)}
			/>
		);
		setFullCommitteeButton(data);
	}, [fullCommittee]);

	const addProfileButton = async (member) => {
		let data = { ...member };
		/* data.profile = (
			<img
				className="iconPerfil"
				src="/assets/icons/shape08.svg"
				style={{ cursor: "pointer" }}
				alt="Topia"
				onClick={() => handleProfileModal(true)}
			/>
		); */
		const addData = await getUserDataProfileById(data.profile_id).then(
			(result) => {
				data.profileData = result;
			}
		);
		setFullCommittee(data);
		return;
	};

	const handleProfileModal = async (statusModal, record) => {
		if (record) {
			let active_user = record;

			if (active_user.ownerHome) {
				// Agregamosal detalle los dependientes de los socios
				let relativesWithAccess = await getQueryDocByCollection(
					"/family_access",
					"idProfileMain",
					"==",
					active_user.id
				);
				active_user = {
					...active_user,
					relativesWithAccess: relativesWithAccess,
				};

				if (active_user.residentials) {
					let nomenclaturePath = active_user.residentials[0]?.house["path"];
					nomenclaturePath = nomenclaturePath.substring(47, 67);

					let housePath = active_user.residentials[0]?.house["path"];
					housePath = housePath.substring(75, 95);

					const houseData = await getDataByNomenclatureIdAndHouseIdHelper(
						"Yd5MV7jkA0MjQeCmzKrZ",
						nomenclaturePath,
						housePath
					).then((houseD) => {
						active_user.houseData = houseD;
					});
				}

				setUserProfileActive(active_user);
			}
		}
		setTimeout(() => {
			setProfileModalActive(statusModal);
		}, 50);
	};
	const { Option } = Select;
	const getUserOptionFromProfileID = (users, profileID) => {
		let option = null;
		users.map((item, index) => {
			if (item.id === profileID) {
				let fullName = item.name + " " + item.lastname;
				option = (
					<Option key={index} value={index} label={fullName}>
						{fullName}
					</Option>
				);
			}
			return option;
		});
		return option;
	};
	const getRoleOptionFromProfileID = (roles, position_name) => {
		let option = null;
		roles.map((item, index) => {
			if (item.name === position_name) {
				let name = item.name;
				option = (
					<Option label={name} key={index} value={index}>
						{name}
					</Option>
				);
			}

			return option;
		});
		return option;
	};

	const initialFormValues = {
		user: getUserOptionFromProfileID(fullUsers, fullCommitteeButton.profile_id),
		role: getRoleOptionFromProfileID(
			fullRoles,
			fullCommitteeButton.position_name
		),
	};

	const handleUserOnChange = (index) => {
		setSelectedUser(allUsers[index]);
	};

	const handleRoleOnChange = (index) => {
		setselectedRole(allRoles[index]);
	};

	const handleEditMemberAssignment = (statusModal, index = null) => {
		if (index != null) {
		}

		setTimeout(() => {
			setNewMemberModalActive(statusModal);
		}, 50);
	};

	const onFinishEditMemberForm = async (e) => {
		const user = selectedUser;
		const role = selectedRole;

		const id = fullCommittee.id;

		const memberData = {
			active: true,
			admin: false,
			created_at: moment(new Date(), "DD/MM/YYYY").toDate(),
			lastname: user.lastname ? user.lastname : "",
			name: user.name,
			position: role.id,
			position_name: role.name,
			residential_id: "Yd5MV7jkA0MjQeCmzKrZ",
			profile_id: user.id,
		};
		const collectionRef = `/committee/${id}`;
		const updateMember = await onUpdateDocWithRefInfo(
			collectionRef,
			memberData
		);
		handleEditMemberAssignment(false);
		const newCommittee = {
			id: id,
			active: true,
			admin: false,
			created_at: moment(new Date(), "DD/MM/YYYY").toDate(),
			lastname: user.lastname,
			name: user.name,
			position: role.id,
			position_name: role.name,
			residential_id: "Yd5MV7jkA0MjQeCmzKrZ",
			profile_id: user.id,
		};
		setCommittee(newCommittee);
	};

	const getPhoneNumber = (member, users) => {
		let phone = "";
		users.map((item, index) => {
			if (member.profile_id === item.id) {
				phone = item.phone;
			}
			return phone;
		});
		return phone;
	};

	return (
		<>
			<Card
				style={{
					width: "100%",
					borderRadius: "10px",
					boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
					marginTop: "30px",
					marginBottom: "30px",
				}}
				bordered
			>
				<Row>
					<Col span={6}>
						<div
							style={{
								marginLeft: "2rem",
								fontWeight: "600",
								textTransform: "uppercase",
								color: "#707070",
							}}
						>
							<p>{fullCommitteeButton.position_name}</p>
						</div>
					</Col>
					<Col span={10}>
						<div
							style={{
								textTransform: "uppercase",
								fontWeight: "400",
								color: "#707070",
							}}
						>
							{fullCommitteeButton.profile}
							<span style={{ marginLeft: "5px" }}>
								{fullCommitteeButton.name} {fullCommitteeButton.lastname}
							</span>
						</div>
					</Col>
					<Col span={6}>
						<div
							style={{
								textTransform: "uppercase",
								fontWeight: "400",
								color: "#707070",
							}}
						>
							<p>{fullCommitteeButton.profileData?.phone}</p>
						</div>
					</Col>
					<Col span={2}>
						{/* {committe.data ? ():()} */}
						<Button
							onClick={() => {
								handleEditMemberAssignment(true);
							}}
							modalvisible="true"
							shape="round"
							style={{ width: "100%", borderColor: "#10b48c" }}
						>
							<div
								style={{
									display: "flex",
									textAlign: "center",
									color: "#404040",
								}}
							>
								<img
									className="iconButton"
									src="/assets/icons/small-edit.svg"
									alt=""
									style={{
										marginLeft: "20%",
										marginTop: "2px",
										width: "16px",
										height: "16px",
									}}
								/>
								<span style={{ marginLeft: "3px" }}>Editar</span>
							</div>
						</Button>
					</Col>
				</Row>
			</Card>

			<UserProfileModal
				userActive={userProfileActive}
				userModalActive={profileModalActive}
				handleStatusResidentsModal={handleProfileModal}
			/>

			{/* MODAL EDITAR MIEMBRO */}
			<Modal
				className="primary-modal"
				visible={newMemberModalActive}
				onCancel={() => handleEditMemberAssignment(false)}
				footer={null}
				width={550}
			>
				<>
					<Row className="section-modal" style={{ textAlign: "center" }}>
						<Col span={24}>
							<h2 style={{ color: "#232C4A" }}>EDITAR MIEMBRO DE JUNTA</h2>
						</Col>
					</Row>
					<Row className="section-modal" style={{ marginTop: "1rem" }}>
						<Col
							span={24}
							style={{
								width: "90%",
								paddingLeft: "2rem",
								paddingRight: "2rem",
							}}
						>
							<Form name="remember_user_form" onFinish={onFinishEditMemberForm}>
								<div className="section-modal" style={{ paddingLeft: "5px" }}>
									<p>Seleccionar usuario:</p>
								</div>
								<FormItem name="user" {...config}>
									{/* <Input
													style={{
														backgroundColor: "#F2F2F2",
														borderRadius: "5px",
													}}
												/> */}
									{userOptions ? (
										<Select
											placeholder="Buscar por nombre"
											style={{
												width: "100%",
											}}
											allowClear
											showSearch
											value={initialFormValues.user}
											filterOption={(input, option) => {
												return (
													option.label
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												);
											}}
											onChange={(index) => {
												handleUserOnChange(index);
											}}
										>
											{userOptions}
										</Select>
									) : null}
								</FormItem>

								<div className="section-modal" style={{ paddingLeft: "5px" }}>
									<p>Seleccionar rol:</p>
								</div>

								<FormItem name="role" {...config}>
									{roleOptions ? (
										<Select
											placeholder="Buscar por rol"
											style={{
												width: "100%",
											}}
											allowClear
											showSearch
											filterOption={(input, option) => {
												return (
													option.label
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												);
											}}
											onChange={(index) => {
												handleRoleOnChange(index);
											}}
										>
											{roleOptions}
										</Select>
									) : null}
								</FormItem>
								<br />
								<br />
								<Row>
									<Col span={24} align="middle">
										<Button
											htmlType="submit"
											style={{
												borderColor: "#10b48c",
												background: "#10b48c",
												paddingBottom: "15px",
												width: "250px",
											}}
											shape="round"
											size="large"
										>
											<span className="title" style={{ color: "#fff" }}>
												EDITAR MIEMBRO
											</span>
										</Button>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</>
			</Modal>
		</>
	);
};
