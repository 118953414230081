import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import FormItem from "antd/lib/form/FormItem";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getNewsByIdHelper, getNewsById } from "../../../../helpers/newsHelper";

import NewsDetailForm from "../components/NewsDetailForm";

export const EditNewsPagePrincipal = () => {
	const [news, setNews] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};
	const { id } = useParams();

	const navigate = useNavigate();

	useEffect(() => {
		// getNewsById(id).catch(console.error);
		(async () => {
			const { data } = await getNewsById(id);
			setNews(data);
			setIsLoading(false);
		})();
	}, []);

	// const getNewsById = async (newsId) => {
	// 	const getNews = await getNewsByIdHelper(newsId).then((result) =>
	// 		setNews(result)
	// 	);
		
	// };=
	const showCategory = (category) => {
		let name = "";
		if (category === "event") {
			name = "Evento";
		}
		if (category === "news") {
			name = "Noticia";
		}
		if (category === "notification") {
			name = "Notificación";
		}
		return name;
	};
	return (
		<>
			{news ? (
				<Layout>
					<Content>
						<div className="wrapper-page">
							<Row>
								<Col span={24}>
									<h2 className="title-page">
										Editar {showCategory(news.type)}
									</h2>
								</Col>
								<Col span={24}>
									<Card
										style={{
											width: "100%",
											borderRadius: "10px",
											boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
											marginTop: "30px",
											marginBottom: "30px",
										}}
										bordered
									>
										<NewsDetailForm data={news}/>
									</Card>
								</Col>
							</Row>
						</div>
					</Content>
				</Layout>
			) : (
				<></>
			)}
		</>
	);
};
