import axios from "axios"

const createPaymentsByExcel = async (fileExcel) => {
  try {
    const response = await axios.post('https://domo.topiaapp.com/api/payment/tuscania/create/excel', fileExcel, {
      headers: {
        "Content-type": "multipart/form-data"
      }
    })

    return {
      success: true,
      ...response
    }
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response
    }
  }
}

export default createPaymentsByExcel
