import axios from "axios"

const getRolesByUserLogged = async (idToken) => {
  try {
    const response = await axios.get('https://domo.topiaapp.com/api/role/profile',
      { headers: ({ Authorization: `Bearer ${idToken}` }) }
    )

    return {
     success: true,
     ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getRolesByUserLogged
