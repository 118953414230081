import React from 'react'
import { Tabs } from 'antd';
import { ZonasTab } from './ZonasTab';

export const ContainerTabs = ({residentialData}) => {
  
    const { TabPane } = Tabs;
    return (
        <>
            <Tabs className='primary-tabs' defaultActiveKey="1">                
                <TabPane tab="ZONAS PRINCIPALES" key="1">
                    <ZonasTab residentialData={residentialData} />
                </TabPane>
            </Tabs>
        
        </>
    )
}
