import { Button, Row } from "antd"
import { CustomNavigate } from "../../../../../UI/CustomNavigate"
import { ReactComponent as ExportIcon } from '../../../assets/exportReportIcon.svg'
import { useEffect } from "react"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { Navigate } from "react-router-dom"
import PDFReport from "./components/PDFReport"
import CustomTable from "../../../../../UI/CustomTable/CustomTable"
import useProviders from "../../../hooks/useProviders/useProviders"
import './styles.css'

export const Providers = () => {

  const { getInitialData, handleChangeTable, columns, loading, historyAccessTable, providersTable, access_date } = useProviders()

  useEffect(() => {
    getInitialData()
  }, []);

  //Si no hay un objeto activo que proviene de la pantalla de Historial de Accesos, se retorna a dicha pantalla inicial de Monitoreo de contratista
  if (!Object.keys(historyAccessTable.active).length || !access_date) return <Navigate to={'/access/monitoring-contrators'} />

  return (
    <div className="section-wrapper">
      <CustomNavigate title={<span>INGRESO PROVEEDORES: <span style={{ color: "#0D9C7A" }}>{historyAccessTable.active.accessDate}</span></span>}>
        <Row>
          <PDFDownloadLink document={<PDFReport data={{ table: providersTable.dataTable, general: historyAccessTable.active }} typeReport={'providers'} />} fileName={`REPORTE DE INGRESO Y SALIDA DE PROVEEDORES - ${historyAccessTable.active.accessDate}`}>
            <Button className="btn-export" icon={<ExportIcon />}>EXPORTAR REPORTE</Button>
          </PDFDownloadLink>
        </Row>
      </CustomNavigate>
      <CustomTable loading={loading} dataSource={providersTable.dataTable} columns={columns} onChange={handleChangeTable} className="custom-table-variant-1 providers-table"
        pagination={{
          pageSize: providersTable.metadata.limit || undefined,
          total: providersTable.metadata.quantity || undefined,
          defaulPageSize: providersTable.metadata.limit || undefined,
          current: providersTable.currentPage || undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          position: ["bottomRight"]
        }}
      />
    </div>
  )
}

export default Providers