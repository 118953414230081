import { CustomNavigate } from "../../../../../../UI/CustomNavigate"
import FilterTable from "./components/FilterTable"

//Es el header de la página de reservas que incluye un filtro para la tabla con un CustomDataPicker 
const HeaderBookings = ({ handleChangeDatePickerFilter }) => (
  <header>
    <CustomNavigate title={'RESERVAS DE AMENIDADES'}>
      <FilterTable handleChangeDatePickerFilter={handleChangeDatePickerFilter} />
    </CustomNavigate>
  </header>
)

export default HeaderBookings
