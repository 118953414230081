import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Space, Button, DatePicker, Tabs } from "antd";
import { getPaymentsNormal } from "../../../../store/payments/thunks";
import { pdf, print, sheets } from "../../../../assets/icons";

export const PaymentFooter = () => {
	const [paymentList, setPaymentList] = useState([]);
	const { payments } = useSelector((state) => state.payments);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPaymentsNormal());
	}, [dispatch]);

	const totalChargesPay = (payments) => {
		let total = 0;
		payments.map(async (item, index) => {
			if (item.subpayment) {
				item.subpayment.map((item) => {
					total += +parseFloat(item.total).toFixed(2);
				});
			}
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};
	const totalTransactions = (payments) => {
		let total = 0;
		total = payments.length;
		return total;
	};
	const totalLiqPay = (payments) => {
		let total = 0;
		payments.map(async (item, index) => {
			if (item.subpayment) {
				item.subpayment.map((item) => {
					total += +parseFloat(item.total).toFixed(2);
				});
			}
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	return (
		<>
			<Row>
				<Col span={6}>
					<div className="footer-content">
						<p>Transacciones: {2319}</p>
					</div>
				</Col>
				<Col span={6}>
					<div
						className="footer-content"
						style={{ borderLeft: "1px solid #58689B" }}
					>
						<p>Cobros: $ {currencyFormat("2840.0")}</p>
					</div>
				</Col>
				<Col span={6}>
					<div className="footer-content">
						<p style={{ color: "#10b48c" }}>
							Liquidación: $ {currencyFormat("20446.0")}
						</p>
					</div>
				</Col>
				<Col span={6}>
					<div
						className="footer-content"
						style={{ borderLeft: "1px solid #58689B" }}
					>
						<Space>
							<img src={sheets} alt="sheets" />
							<img src={pdf} alt="pdf" />
							<img src={print} alt="print" />
						</Space>
					</div>
				</Col>
			</Row>
		</>
	);
};
