import React, {useEffect } from "react";
import {
	GoogleMap,
	LoadScript,
	Marker,
	InfoWindow,
} from "@react-google-maps/api";
import { useState } from "react";
import { Col, Row } from "antd";
import "./style.css";
import { getAgentProfileData } from "../helpers/agentTrackerHelper";

const AgentMap = ({ agentsData }) => {
	const [agents, setAgents] = useState([]);
	const [fullMarkers, setFullMarkers] = useState([]);
	const [fullMarkersWithData, setFullMarkersWithData] = useState([]);
	const [activeInfoWindow, setActiveInfoWindow] = useState(false);

	let markers = [];

	useEffect(() => {
		setAgents(agentsData);
	}, [agentsData]);

	useEffect(() => {
		const createMarkers = (agents) => {
			const addMarker = agents.map((item, index) => {
				let data = { ...item };
				let markerData = {};
				markerData.position = data.coords;
				markerData.label = { color: "white", text: "A" + index };
				markerData.draggable = false;
				markerData.battery = data.battery ? data.battery : "N/A";
				markerData.id = data.idProfile;
				markers.push(markerData);
			});
			setFullMarkers(markers);
		};
		createMarkers(agents);
	}, [agents]);

	useEffect(() => {
		fullMarkers.map(async (item, index) => {
			const profileData = await getAgentProfileData(item.id).then(
				(result) => (item.profileData = result)
			);
		});
		setFullMarkersWithData(fullMarkers);
	}, [fullMarkers]);

	const getProfileData = async (marker) => {
		const addProfileData = await getAgentProfileData(marker.id).then(
			(result) => (marker.profileData = result)
		);
	};

	const containerStyle = {
		width: "100%",
		height: "400px",
	};
	const center = {
		lat: 13.6158329,
		lng: -89.287219,
	};
	const markerClicked = (marker, index) => {
		setActiveInfoWindow(index);
	};

	const Icon = () => (
		<img
			alt="alfiler-img"
			style={{ width: "20px", height: "20px" }}
			src="/assets/images/alfiler.png"
		/>
	);

	return (
		<>
			<LoadScript googleMapsApiKey="AIzaSyDcHXx5bT0lelig3L2bzK7sBcQUYFaLxoc">
				<GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
					{/* <Marker
						key={0}
						position={{ lat: 13.6158329, lng: -89.287219 }}
					></Marker> */}

					{fullMarkersWithData.map((mark, index) => (
						<div key={index}>
							<Marker
								key={index}
								position={mark.position}
								label={mark.label}
								draggable={mark.draggable}
								onClick={() => markerClicked(mark, !activeInfoWindow)}
							>
								{activeInfoWindow ? (
									<InfoWindow
										position={mark.position}
										onCloseClick={() => {
											markerClicked(mark, false);
										}}
									>
										<Row className="info-window">
											<Col span={24} align="middle">
												<span className="title">
													{mark.profileData.name} {mark.profileData.lastname}
												</span>
											</Col>
											<Col span={24} align="middle">
												<span className="description">
													{mark.battery}% de batería
												</span>
											</Col>
										</Row>
									</InfoWindow>
								) : null}
							</Marker>
						</div>
					))}
				</GoogleMap>
			</LoadScript>
		</>
	);
};

export default AgentMap;
