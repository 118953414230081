import React from 'react'
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { Input, InputNumber, Row } from "antd";

const { TextArea } = Input;

export const CustomInput = ({
  id,
  value,
  onChange,
  type = 'TEXT',
  allowClear = true,
  prefix,
  placeholder,
  prefixIcon,
  suffixIcon,
  disabled = false,
  defaultValue,
  maxLength = 256,
  inputNumberText,
  nullInputNumberText = 'Sin ocurrencias',
  className = "custom-input",
  rows,
  autoSize = { minRows: 1, maxRows: 10 },
  min,
  max,
  step,
  hidden,
  style
}) => {

  const props = {
    id,
    defaultValue,
    value,
    min,
    max,
    step,
    onChange,
    disabled,
    allowClear,
    prefixIcon,
    suffixIcon,
    placeholder,
    maxLength,
    className,
    rows,
    autoSize,
    hidden,
  }

  //Funciones para el input Number si se quiere colocar un string
  const handleFormatter = (value) => {
    if (!value) return `${nullInputNumberText}`
    return `${value}${inputNumberText ? ` ${inputNumberText}` : ""}`;
  };

  const handleParser = (value) => {
    const numericValue = parseFloat(value, 10);
    return isNaN(numericValue) ? 0 : numericValue;
  };

  const verifyTypeInputs = (type, {
    id,
    defaultValue,
    value,
    min,
    max,
    step,
    onChange,
    disabled,
    allowClear,
    prefixIcon,
    suffixIcon,
    placeholder,
    maxLength,
    className,
    rows,
    autoSize,
    hidden,
  }) => {
    const commonProps = {
      id,
      value,
      onChange,
      disabled,
      className,
      hidden
    }

    const isNumberProps = type === 'NUMBER' ? {
      ...commonProps,
      prefix,
      controls: {
        upIcon: <CaretUpFilled />,
        downIcon: <CaretDownFilled />,
      },
      formatter: handleFormatter,
      parser: handleParser,
      defaultValue: defaultValue || null,
      min,
      max,
      step,
    } : {
      ...commonProps,
      allowClear,
      prefix: prefixIcon ? <img alt="prefix-icon" src={prefixIcon} /> : undefined,
      suffix: suffixIcon ? <img alt="suffix-icon" src={suffixIcon} /> : undefined,
      placeholder,
      maxLength,
      rows,
      hidden,
    };

    if (type === 'AREA') {
      isNumberProps.autoSize = autoSize
    }

    let objInputsTypes = {
      "TEXT": Input,
      "AREA": TextArea,
      "NUMBER": InputNumber,
      "DEFAULT": null
    }

    let Component = objInputsTypes[type] || objInputsTypes["DEFAULT"]

    return <Component style={style} {...isNumberProps} />
  }

  return (
    <Row>
      {verifyTypeInputs(type, props)}
    </Row>
  )
}