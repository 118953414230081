import { Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getAnnouncements,
	setActiveAnnouncement,
} from "../../../store/announcement";
import { AnnouncementDetail } from "./components/AnnouncementDetail";
import { AnnouncentCard } from "./components/AnnouncentCard";
import { CreateAnnouncement } from "./components/CreateAnnouncement";
import { getQueryDocByCollection } from "../../../helpers/globalHelper";

export const AnnouncementPage = () => {
	const [users, setUsers] = useState([]);

	const [announcementList, setAnnouncementList] = useState([]);
	const [announcementDetail, setAnnouncementDetail] = useState(null);
	const { announcement } = useSelector((state) => state.announcement);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAnnouncements());
	}, []);

	useEffect(() => {
		if (announcement.data.length > 0) {
			setAnnouncementList(announcement.data);
		}
	}, [announcement.data]);

	useEffect(() => {
		setAnnouncementDetail(announcement.active);
	}, [announcement.active]);

	const handleActiveDetail = (data) => {
		dispatch(setActiveAnnouncement(data));
	};

	useEffect(() => {
		const userProfiles = getQueryDocByCollection(
			"profile",
			"residentialsIds",
			"array-contains",
			"Yd5MV7jkA0MjQeCmzKrZ" // Tuscania ID
		).then((searchBarUsers) => setUsers(searchBarUsers));
	}, []);
	const { Option } = Select;

	return (
		<>
			<div className="wrapper-page">
				<h2 className="title-page">Envío de convocatoria</h2>

				<Row>
					<Col lg={9} sm={24}>
						<CreateAnnouncement users={users}></CreateAnnouncement>

						<AnnouncentCard
							announcements={announcementList}
							handleActiveDetail={handleActiveDetail}
						></AnnouncentCard>
					</Col>

					<Col lg={{ span: 14, offset: 1 }} sm={24}>
						<AnnouncementDetail
							announcementDetail={announcementDetail}
						></AnnouncementDetail>
					</Col>
				</Row>
			</div>
		</>
	);
};
