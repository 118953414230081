import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { FirebaseDB } from '../firebase/config';
import moment from 'moment';
import 'moment-timezone';
import { getDocByCollectionAnId, onCreateOrUpdate } from "./globalHelper";


export const getAnnouncementsHelper = async (residential_id) => {
    const collectionRef = collection( FirebaseDB, `/announcement` );
    const queryCollection = query(collectionRef, where("active", "==", true), where('residential_id', "==", residential_id));
    const querySnapshot = await getDocs(queryCollection);
    
    const dataList = [];
    querySnapshot.forEach( async (documment) => {
        let data = documment.data();
        data.id = document.id;
        

        dataList.push(data);
    });

    return dataList;
}


export const columnsAlertTable = () => {
    return [
        {
          title: 'Fecha',
          dataIndex: 'created_at',
          key: 'created_at',
        },
        {
          title: 'Hora',
          dataIndex: 'created_time',
          key: 'created_time'
        },
        {
          title: 'Nombre',
          dataIndex: 'nameUser',
          key: 'nameUser'
        },
        {
          title: 'Tipo de alerta',
          dataIndex: 'alert_type',
          key: 'alert_type'
        },
        {
          title: 'Detalle',
          dataIndex: 'detail',
          key: 'detail',
        },
        {
          title: 'Descartar',
          dataIndex: 'descart',
          key: 'descart',
        },
      ];
}