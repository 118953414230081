import React from "react";
import ReactDOM from "react-dom/client";
import { AppRouter } from "./routes/AppRouter";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import "./index.css";
import "./antdStyle.css"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<ToastContainer />
		<AppRouter></AppRouter>
	</Provider>
);
