import React from "react";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import {
	Col,
	Row,
	Layout,
	Button,
	DatePicker,
	Descriptions,
	Modal,
	Select,
	Radio,
	Space,
	Form,
	Input,
	Table,
	Switch,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import { ReportLiquidacionContainer } from "./components/ReportLiquidacionContainer";
import { Content, Footer } from "antd/lib/layout/layout";
import { ReportLiquidacionFooter } from "./components/ReportLiquidacionFooter";
import {
	getPaymentsHelper,
	getTransactionsHelper,
	editPaymentsByTransfer,
	getPaymentsByBillingNumberAndStatus,
} from "../../../helpers/paymentsHelper";
import * as XLSX from "xlsx";
import {
	onCreateOrUpdate,
	onUpdateDocWithRefInfo,
} from "../../../helpers/globalHelper";
import { getColumnSearchProps } from "../../../utils/tables";
import { toast } from "react-toastify";
import { extractNumberFromString } from "../../../helpers/generalHelper";
import { plusIconWhite } from "../../../assets/images";

const ReportLiquidacionPage = () => {
	const [loadingBt, setLoadBt] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [fullArray, setFullArray] = useState([]);
	const [newPaymentsModalActive, setNewPaymentsModalActive] = useState(false);
	const newFormRef = useRef();
	const [fileData, setFileData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [flagUpload, setFlagUpload] = useState(false);
	const [resultsModalActive, setResultsModalActive] = useState(false);
	const [confirmed, setConfirmed] = useState(0);
	const [failed, setFailed] = useState(0);
	const [failedPayments, setFailedPayments] = useState([]);
	const [fileName, setFileName] = useState("");

	//INICIO VARIABLES PARA FECHA AHORA
	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(23, 59, 59, 0));

		let trans = getPaymentsHelper(
			"Yd5MV7jkA0MjQeCmzKrZ",
			fechaInicio,
			fechaFinal
		).then((transactions) => setTransactions(transactions));

		setLoadBt(true);
	}, []);

	useEffect(() => {
		const constructArray = (transactions) => {
			let arr = [];
			let data = {};
			const array = transactions.map(async (item, index) => {
				if (index === 0) {
					let data = {};
					data.id = item.payment_date.replace("-", "");
					data.date = moment(item.payment_date).format("DD/MM/YYYY");
					data.linkDate = moment(item.payment_date).format("DD-M-YYYY");
					data.data = [];
					transactions.forEach((item2, index2) => {
						if (item2.payment_date.replace("-", "") === data.id) {
							data.data.push(item2);
						}
					});

					arr.push(data);
				}
				if (index > 0) {
					if (
						transactions[index].payment_date.replace("-", "") !=
						transactions[index - 1].payment_date.replace("-", "")
					) {
						let data = {};
						data.id = item.payment_date.replace("-", "");
						data.date = moment(item.payment_date).format("DD/MM/YYYY");
						data.linkDate = moment(item.payment_date).format("DD-M-YYYY");
						data.data = [];
						transactions.forEach((item2, index2) => {
							if (item2.payment_date.replace("-", "") === data.id) {
								data.data.push(item2);
							}
						});

						arr.push(data);
					}
				}

				/* let data = {};
				data.id = item.created_at.replace("-", "");
				data.date = moment(item.created_at).format("DD/MM/YYYY");
				data.data = [];
				transactions.forEach((item2, index2) => {
					if (item2.created_at.replace("-", "") === data.id) {
						data.data.push(item2);
						
					}
				});
				
				arr.push(data); */
				/* if (index == 0) {
					data.id = item.created_at.replace("-", "");
					data.date = moment(item.created_at).format("DD/MM/YYYY");
				}
				if (index > 1) {
					if (
						item.created_at.replace("-", "") !=
						transactions[index - 1].created_at.replace("-", "")
					) {
						data.id = item.created_at.replace("-", "");
						data.date = moment(item.created_at).format("DD/MM/YYYY");
					}
				}

				data.data = [];
				data.data.push(item);
				if (index == 0) {
					arr.push(data);
				}
				if (index > 1) {
					if (
						item.created_at.replace("-", "") !=
						transactions[index - 1].created_at.replace("-", "")
					) {
						arr.push(data);
					}
				} */

				/* let data = {};
				data.id = item.created_at.replace("-", "");
				data.date = moment(item.created_at).format("DD/MM/YYYY");
				data.data = [];
				data.data.push(item);
				if (index == 0) {
					arr.push(data);
				} else {
					if (
						item.created_at.replace("-", "") !=
						transactions[index - 1].created_at.replace("-", "")
					) {
						arr.push(data);
					}
				} */
			});
			setFullArray(arr);
			return arr;
		};
		constructArray(transactions);
	}, [transactions]);

	//BUSCA FECHA INICIO
	const buscarFechas = async (fechaInicio, fechaFin) => {
		setLoadBt(true);

		let trans = await getPaymentsHelper(
			"Yd5MV7jkA0MjQeCmzKrZ",
			fechaInicio,
			fechaFin
		).then((transactions) => setTransactions(transactions));
	};

	const handleNewPaymentsModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setNewPaymentsModalActive(statusModal);
		}, 50);
	};

	const handleResultsModal = async (statusModal, count = null) => {
		if (count) {
			setFailedPayments(count);
		}

		setTimeout(() => {
			setResultsModalActive(statusModal);
		}, 50);
	};

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const handleExcel = async (e) => {
		/* const file = e.target.files[0];
		setFileName(file.name);
		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data);

		const worksheet = workbook.Sheets[workbook.SheetNames[0]];
		const jsonData = XLSX.utils.sheet_to_json(worksheet, {
			raw: false,
		}); */
		const file = e.target.files[0];
		setFileName(file.name);
		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data);

		const worksheet = workbook.Sheets[workbook.SheetNames[0]];

		const range = XLSX.utils.decode_range(worksheet["!ref"]);

		const columnIndexToIgnore = XLSX.utils.decode_col("N") - 1;

		range.e.c -= 1;

		for (let row = range.s.r; row <= range.e.r; row++) {
			for (let col = columnIndexToIgnore; col < range.e.c; col++) {
				const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
				delete worksheet[cellAddress];
			}
		}

		worksheet["!ref"] = XLSX.utils.encode_range(range);

		const jsonData = XLSX.utils.sheet_to_json(worksheet, {
			raw: false,
		});
		let renamedData = [];
		jsonData.map((item, index) => {
			renamedData.push({
				idClient: item["CodigoCliente"],
				name: item["Cliente"],
				houseNumber: item["Lote"],
				billingNumber: item["Factura"],
				date: item["Fecha"],
				concept: item["Servicio"],
				total: extractNumberFromString(item["Total"]),
				accFee: item["Comision"],
				taxes: item["Impuesto"],
				topiaFee: "0.00",
				settlement: item["Liquido"],
			});
		});
		setFileData(renamedData);
		//editPaymentsByTransfer(renamedData);
	};

	const onFinishNewPaymentsForm = async () => {
		setLoading(true);
		setConfirmed(0);
		setFailed(0);
		let paymentSync = [];
		const promise = fileData.map(async (item, index) => {
			const payment = await getPaymentsByBillingNumberAndStatus(
				item.billingNumber,
				"Pendiente"
			);
			paymentSync.push({
				foundPaymentId: payment.length > 0 ? payment[0].id : "",
				...item,
			});
		});
		await Promise.all(promise);

		const count = await editPayments(paymentSync);

		//newFormRef.current.resetFields();
		setLoading(false);
		setFileData([]);
		handleNewPaymentsModal(false);
		setFileName("");
		if (count.length > 0) {
			handleResultsModal(true, count);
		} else {
			toast.success("Pagos con transferencias sincronizados correctamente");
		}
	};

	const editPayments = (payments) => {
		let totalConfirmed = 0;
		let totalFailed = 0;
		let failedPayments = [];

		payments.map(async (item, index) => {
			if (item.foundPaymentId) {
				totalConfirmed++;
				const edit = {
					status: "Pagado",
					payment_date: moment(item.date, "MM/DD/YYYY").toDate(),
					payment_details: {
						accFee: parseFloat(item.accFee),
						taxFee: parseFloat(item.taxes),
						topiaFee: parseFloat(item.topiaFee),
					},
					num_auth: "TRANSF",
					num_ref: "TRANSF",
				};
				const id = item.foundPaymentId;
				const collectionRef = `/payments/${id}`;
				const editPayment = await onUpdateDocWithRefInfo(collectionRef, edit);
			} else {
				totalFailed++;
				failedPayments.push(item);
			}
		});
		let count = [totalConfirmed, totalFailed];

		return failedPayments;
	};

	const columns = [
		{
			title: "CODIGO",
			dataIndex: "idClient",
			key: "idClient",
			width: 150,
			align: "center",
			...getColumnSearchProps("idClient"),
		},
		{
			title: "CLIENTE",
			dataIndex: "name",
			key: "name",
			width: 300,
			align: "center",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("name"),
		},
		{
			title: "LOTE",
			dataIndex: "houseNumber",
			key: "houseNumber",
			width: 150,
			align: "center",
			...getColumnSearchProps("houseNumber"),
		},
		{
			title: "N_FACTURA",
			dataIndex: "billingNumber",
			key: "billingNumber",
			width: 150,
			align: "center",
			...getColumnSearchProps("billingNumber"),
		},
		{
			title: "FECHA",
			dataIndex: "date",
			key: "date",
			width: 120,
			align: "center",
		},
		{
			title: "SERVICIO",
			dataIndex: "concept",
			key: "concept",
			width: 150,
			align: "center",
			...getColumnSearchProps("concept"),
		},
		{
			title: "COMISION",
			dataIndex: "accFee",
			key: "accFee",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.accFee
						? currencyFormat(parseFloat(record.accFee).toFixed(2).toString())
						: ""}
				</>
			),
		},
		{
			title: "IMPUESTO",
			dataIndex: "taxes",
			key: "taxes",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.taxes
						? currencyFormat(parseFloat(record.taxes).toFixed(2).toString())
						: ""}
				</>
			),
		},
		{
			title: "TOTAL",
			dataIndex: "total",
			key: "total",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.total
						? currencyFormat(parseFloat(record.total).toFixed(2).toString())
						: ""}
				</>
			),
		},
		{
			title: "LIQUIDO",
			dataIndex: "settlement",
			key: "settlement",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.settlement
						? currencyFormat(
								parseFloat(record.settlement).toFixed(2).toString()
						  )
						: ""}
				</>
			),
		},
	];

	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={4}>
								<h2 className="title-page">Pagos recibidos</h2>
							</Col>
							<Col span={20} align="left">
								<Button
									style={{
										borderColor: "#10b48c",
										background: "#10b48c",
										width: "300px",
									}}
									onClick={() => {
										handleNewPaymentsModal(true);
									}}
									shape="round"
								>
									<img
										src={plusIconWhite}
										alt="white-add-plus-icon"
										style={{
											width: "26px",
											height: "26px",
											marginRight: "20px",
										}}
									/>
									<span
										className="title"
										style={{
											color: "#FFFFFF",
											fontSize: "13px",
											fontWeight: "500",
										}}
									>
										AGREGAR PAGOS
									</span>
								</Button>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={24}>
								<ReportLiquidacionContainer
									buscarFechas={buscarFechas}
									loadingBt={loadingBt}
									data={fullArray}
								/>
							</Col>
						</Row>
					</div>
				</Content>
				<Footer
					style={{
						textAlign: "center",
						backgroundColor: "#232C4A",
						height: "75px",
					}}
				>
					<ReportLiquidacionFooter
						transactions={transactions}
						fullArray={fullArray}
					/>
				</Footer>
			</Layout>

			{/* MODAL PARA AGREGAR PAGOS */}
			<Modal
				className="primary-modal"
				visible={newPaymentsModalActive}
				onCancel={() => handleNewPaymentsModal(false)}
				footer={null}
				width={550}
			>
				<>
					<Row className="section-modal" style={{ textAlign: "center" }}>
						<Col span={24}>
							<h2 style={{ color: "#232C4A" }}>
								EDITAR PAGOS POR TRANSFERENCIAS
							</h2>
						</Col>
					</Row>
					<Row className="section-modal" style={{ marginTop: "1rem" }}>
						<Col
							span={24}
							style={{
								width: "90%",
								paddingLeft: "2rem",
								paddingRight: "2rem",
							}}
						>
							<form action="">
								{/* <div className="section-modal" style={{ paddingLeft: "5px" }}>
									<p>PAGO:</p>
								</div>
								<Form.Item name="cardName" {...config}>
									<div style={{ textTransform: "uppercase" }}>
										<Input size="large" maxLength={30} />
									</div>
								</Form.Item> */}
								<div className="section-modal" style={{ paddingLeft: "5px" }}>
									<p>SELECCIONAR ARCHIVO EXCEL CON DATOS DE TRANSFERENCIAS:</p>
								</div>
								<input
									required
									className="form-control"
									type="file"
									name="file"
									id="file"
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									onChange={(e) => handleExcel(e)}
									style={{
										backgroundColor: "#F2F2F2",
										width: "0.1px",
										height: "0.1px",
										opacity: "0",
										overflow: "0",
										position: "absolute",
										zIndex: "-1",
									}}
								/>
								<label
									htmlFor="file"
									className="form-control"
									style={{
										backgroundColor: "#F2F2F2",
										textAlign: "center",
										cursor: "pointer",
									}}
								>
									{fileName ? fileName : "Seleccionar archivo excel"}
								</label>

								<br />
								<br />
								<Row>
									<Col span={24} align="middle">
										<Button
											style={{
												borderColor: "#10b48c",
												background: "#10b48c",
												paddingBottom: "15px",
												width: "250px",
											}}
											shape="round"
											size="large"
											loading={loading}
											onClick={() => {
												onFinishNewPaymentsForm();
												setFlagUpload(true);
											}}
										>
											<span className="title" style={{ color: "#fff" }}>
												AGREGAR PAGOS
											</span>
										</Button>
									</Col>
								</Row>
							</form>
						</Col>
					</Row>
				</>
			</Modal>

			{/* MODAL PARA RESULTADOS */}
			<Modal
				className="side-modal alert-modal modalEncuesta"
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 170px)" }}
				visible={resultsModalActive}
				onCancel={() => handleResultsModal(false)}
				title={
					<Row className="section-modal" style={{ textAlign: "center" }}>
						<Col span={24}>
							<h2 style={{ color: "#232C4A" }}>PAGOS FALLIDOS</h2>
						</Col>
					</Row>
				}
				footer={
					<Row>
						<Col span={24} align="middle">
							<Button
								style={{
									borderColor: "#10b48c",
									background: "#10b48c",
									paddingBottom: "15px",
									width: "250px",
								}}
								shape="round"
								size="large"
								loading={loading}
								onClick={() => {
									handleResultsModal(false);
								}}
							>
								<span className="title" style={{ color: "#fff" }}>
									Cerrar
								</span>
							</Button>
						</Col>
					</Row>
				}
				style={{ top: 0, right: 0, width: "100%" }}
				width={"95%"}
			>
				<>
					<div style={{ height: "80vh" }}>
						<Table
							className="primary-table"
							columns={columns}
							dataSource={failedPayments}
							pagination={{ defaultPageSize: 10, simple: true }}
							rowKey="idClient"
							scroll={{ x: 1620, y: "calc(100vh - 19em)" }}
						/>
					</div>
				</>
			</Modal>
		</>
	);
};

export default ReportLiquidacionPage;
