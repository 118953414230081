import { Col, Row } from "antd"
import CustomModal from "../../../../../../UI/CustomModal"

//Componente que renderiza un modal para mostrar los detalles de la cotización si la reserva no está en estado de espera de confirmación o cancelada
const CotizationDetailsModal = ({
  bookingDetailsData,
  handleOpenCloseModal,
  showModal,
}) => {
  const isFornitureCost = bookingDetailsData?.QUOTE?.reservation_forniture_cost
    ? `$${bookingDetailsData.QUOTE.reservation_forniture_cost}`
    : "No hay mobilario agregado"

  const isSecuriryGuardCost = (bookingDetailsData?.QUOTE?.reservation_security_guard_cost)
    ? `$${bookingDetailsData.QUOTE.reservation_security_guard_cost}`
    : "No hay vigilancia agregado"

  const isPaymentCreation = (bookingDetailsData?.EVENT?.reservation_payment_creation)
    ? bookingDetailsData.EVENT.reservation_payment_creation
    : "Aun no existe fecha de pago"

  const isReservationQuote = (bookingDetailsData?.QUOTE?.reservation_quote_comment)
    ? bookingDetailsData.QUOTE.reservation_quote_comment
    : "No se agregaron comentarios o requisitos"

  return (
    <CustomModal
      width={572}
      destroyOnClose
      className="custom-modal-variant-2"
      isOpenModal={showModal}
      title={<>
        <span style={{ fontSize: 20 }}>
          DETALLES DE LA COTIZACIÓN
        </span>
        <span style={{ fontSize: 14, fontWeight: 400, marginTop: 12 }}>
          {bookingDetailsData?.SPECIFICATIONS?.reservation_places?.join(', ')}
        </span>
      </>}
      onCancel={() => handleOpenCloseModal({ name: 'detailCotizationModal', status: false })}
    >
      <Row>
        <Col style={{ display: 'flex', flexDirection: 'column' }} span={10}>
          <span style={{ color: '#626262' }}>Costo por amenidad:</span>
          <span style={{ color: '#252525' }}>${bookingDetailsData?.QUOTE?.reservation_invitee_cost}</span>
        </Col>
        <Col span={10} offset={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <span style={{ color: '#626262' }}>Costo por mobiliario extra:</span>
          <span style={{ color: '#252525' }}>{isFornitureCost}</span>
        </Col>
      </Row>

      <Row style={{ marginTop: 32 }}>
        <Col style={{ display: 'flex', flexDirection: 'column' }} span={10}>
          <span style={{ color: '#626262' }}>Costo por vigilancia:</span>
          <span style={{ color: '#252525' }}>{isSecuriryGuardCost}</span>
        </Col>

        <Col span={10} offset={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <span style={{ color: '#626262' }}>Fecha de pago:</span>
          <span style={{ color: '#252525' }}>{isPaymentCreation}</span>
        </Col>
      </Row>

      <Row style={{ marginTop: 32 }}>
        <Col style={{ display: 'flex', flexDirection: 'column' }} span={10}>
          <span style={{ color: '#626262' }}>Método de pago:</span>
          <span style={{ color: '#252525' }}>{bookingDetailsData?.EVENT?.reservation_payment_method}</span>
        </Col>
        <Col span={10} offset={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <span style={{ color: '#626262' }}>Requisitos u otras indicaciones:</span>
          <span style={{ color: '#252525' }}>{isReservationQuote}</span>
        </Col>
      </Row>
    </CustomModal>
  )
}

export default CotizationDetailsModal
