import {
	collection,
	query,
	where,
	getDocs,
	doc,
	getDoc,
	orderBy,
} from "firebase/firestore";
import { FirebaseDB } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import { getColumnSearchProps } from "../utils/tables";
import { onCreateOrUpdate } from "./globalHelper";

export const getPaymentsHelper = async (
	residential_id,
	fechaInicio,
	fechaFinal
) => {
	const collectionRef = collection(FirebaseDB, `/payments`);

	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(23, 59, 59, 0);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("status", "==", "Pagado"),
		where("payment_date", ">=", new Date(dateStart)),
		where("payment_date", "<=", new Date(dateEnd)),
		orderBy("payment_date", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = await paymentBody(querySnapshot);

	return list;
};

export const getAllPaymentsHelper = async (
	profile_id,
	residential_id,
	date_start,
	date_end
) => {
	const collectionRef = collection(FirebaseDB, `/payments`);

	const dateStart = date_start.setHours(0, 0, 0, 0);
	const dateEnd = date_end.setHours(23, 59, 59, 0);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("profile_id", "==", profile_id),
		where("residential_id", "==", residential_id),
		where("payment_date", ">=", new Date(dateStart)),
		where("payment_date", "<=", new Date(dateEnd)),
		orderBy("payment_date", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = await paymentBody(querySnapshot);

	return list;
};

export const getPaymentById = async (id) => {
	const collectionResidentialRef = doc(FirebaseDB, "/payments/" + id);

	const docSanp = await getDoc(collectionResidentialRef);
	const data = docSanp.data();

	return data;
};

/* export const getMorasHelper = async (residential_id) => {
	const collectionRef = collection(FirebaseDB, `/payments`);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("status", "==", "Pendiente")
		
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = await paymentBody(querySnapshot);

	return list;
}; */
export const getMorasHelper = async (residential_id) => {
	const collectionRef = collection(FirebaseDB, `/profile_accounting`);

	const residentialDocRef = doc(FirebaseDB, "residentials", residential_id);

	const queryMessages = query(
		collectionRef,
		where("idResidential", "==", residentialDocRef)
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = await moraBody(querySnapshot);

	return list;
};

export const getMoraByProfileIdHelper = async (profile_id, residential_id) => {
	const collectionRef = collection(FirebaseDB, `/profile_accounting`);

	const profileDocRef = doc(FirebaseDB, "profile", profile_id);

	const residentialDocRef = doc(FirebaseDB, "residentials", residential_id);

	const queryMessages = query(
		collectionRef,
		where("idProfile", "==", profileDocRef),
		where("idResidential", "==", residentialDocRef)
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = await moraBody(querySnapshot);

	return list;
};

export const getPaymentsHelperByAccount = async (origin_fam) => {
	const collectionRef = collection(FirebaseDB, `/profile_billing`);
	const queryMessages = query(
		collectionRef,
		where("origin_fam", "==", origin_fam),
		orderBy("invoice_date", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = await paymentAccountBody(querySnapshot);

	return list;
};

export const getPaymentsPendingByTypeAndStatusHelper = async (
	residential_id,
	type,
	status
) => {
	const collectionRef = collection(FirebaseDB, `/payments`);
	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("type", "==", type),
		where("status", "==", status)
	);
	const querySnapshot = await getDocs(queryMessages);

	const list = await paymentBody(querySnapshot);

	return list;
};

export const getPaymentsHelperByDateAndProfileId = async (
	residential_id,
	fechaInicio,
	fechaFinal,
	profileId
) => {
	const collectionRef = collection(FirebaseDB, `/payments`);

	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(0, 0, 0, 0);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("profile_id", "==", profileId),
		where("status", "==", "Pagado"),
		where("payment_date", ">=", new Date(dateStart)),
		where("payment_date", "<=", new Date(dateEnd)),
		orderBy("payment_date", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = await paymentBody(querySnapshot);

	return list;
};

export const getPaymentsHelperByTemplateId = async (tempId) => {
	const collectionRef = collection(FirebaseDB, `/payments`);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("payment_tempId", "==", tempId)
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		list.push(data);
	});

	return list;
};

export const getPaymentsByBillingNumberAndStatus = async (
	billingNumber,
	status
) => {
	const collectionRef = collection(FirebaseDB, `/payments`);
	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("billingNumber", "==", billingNumber),
		where("status", "==", status)
	);
	const querySnapshot = await getDocs(queryMessages);

	const list = await paymentBody(querySnapshot);

	return list;
};

export const editPaymentsByTransfer = async (payments) => {
	payments.map(async (item) => {
		let data = { ...item };
		/* const payType = await getPaymentType("4ll4c5y5pxn9AkukQx7S");
		data.payment_type = payType; */

		const paymentTemplate = await onCreateOrUpdate(`/payments/`, data);
	});
};

export const getArchivedPaymentsHelper = async (residential_id, profile_id) => {
	const collectionRef = collection(FirebaseDB, `/profile_billing`);
	const profileDocRef = doc(FirebaseDB, "profile", profile_id);

	const queryMessages = query(
		collectionRef,
		where("residentialID", "==", residential_id),
		where("profile", "==", profileDocRef)
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = await archivedBody(querySnapshot);

	return list;
};

export const uploadArchivedPaymentsHelper = async (payments, profile_id) => {
	const collectionRef = "profile_billing";
	const profileDocRef = doc(FirebaseDB, "profile", profile_id);

	const uploadPayments = await payments.map(async (item, index) => {
		const payment = {
			tuscania_id: item.idClient,
			alias: item.houseNumber,
			concept: item.concept,
			type: item.service,
			invoice_date: moment(item.date_invoice, "MM/DD/YYYY").toDate(),
			billingNumber: item.billingNumber,
			amount: Number(parseFloat(item.amount).toFixed(2)),
			invoice_total: Number(parseFloat(item.total).toFixed(2)),
			payment_date: moment(item.date_payment, "MM/DD/YYYY").toDate(),
			payment_total: Number(parseFloat(item.amount_payment).toFixed(2)),
			status: "Pagada",
			profile: profileDocRef,
			residentialID: "Yd5MV7jkA0MjQeCmzKrZ",
			diferencia:
				item.difference === "-"
					? 0.0
					: Number(parseFloat(item.difference).toFixed(2)),
			formaPago: item.method,
			totalPagar: Number(parseFloat(item.final_amount)),
			saldo_actual: 0,
			origin_fam: "",
			movtype: "Abono",
			invoicerel_cdi: ""
		};
		const createPayment = await onCreateOrUpdate(collectionRef, payment);
	});
};

const paymentBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = {
			id: documment.id,
			active: data.active,
			amount: data.amount,
			automatic_payment: data.automatic_payment,
			card: data.card,
			concept: data.concept,
			created_at: moment(data.created_at.toDate()).format("Y-MM-DD"),
			created_time: moment(data.created_at.toDate()).format("HH:mm"),
			details: data.details,
			payment_details: data.payment_details ? data.payment_details : null,
			expiration_date:
				data.expiration_date &&
				moment(data.expiration_date.toDate()).format("Y-MM-DD"),
			expiration_time:
				data.expiration_date &&
				moment(data.expiration_date.toDate()).format("HH:mm"),
			/* payment_date:
				data.payment_date &&
				moment(data.payment_date.toDate()).format("Y-MM-DD"),
			payment_date_time:
				data.payment_date && moment(data.payment_date.toDate()).format("HH:mm"), */
			mora: data.mora,
			num_auth: data.num_auth,
			num_ref: data.num_ref,
			payment_date: data.payment_date
				? moment(data.payment_date.toDate()).format("Y-MM-DD")
				: "",
			payment_time: data.payment_date
				? moment(data.payment_date.toDate()).format("HH:mm")
				: "",
			payment_type: data.payment_type,
			porcentaje_mora: data.porcentaje_mora,
			profile_id: data.profile_id ? data.profile_id : null,
			residential_id: data.residential_id ? data.residential_id : null,

			status: data.status,
			type: data.type,
			user_name: data.user_name,
		};

		list.push(itemData);
	});

	return list;
};
const archivedBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = {
			id: documment.id,
			houseNumber: data.alias,
			amount: Number(parseFloat(data.amount).toFixed(2)),
			billingNumber: data.billingNumber,
			concept: data.concept,
			invoice_date: moment(data.invoice_date.toDate()).format("Y-MM-DD"),
			invoice_time: moment(data.invoice_date.toDate()).format("HH:mm"),
			invoice_total: Number(parseFloat(data.invoice_total).toFixed(2)),
			diferencia: Number(parseFloat(data.diferencia).toFixed(2)),
			formaPago: data.formaPago,
			totalPagar: Number(parseFloat(data.totalPagar).toFixed(2)),
			payment_date:
				data.payment_date &&
				moment(data.payment_date.toDate()).format("Y-MM-DD"),
			payment_time:
				data.payment_date && moment(data.payment_date.toDate()).format("HH:mm"),
			payment_total: Number(parseFloat(data.payment_total).toFixed(2)),
			profile_ref: data.profile,
			residentialId: data.residentialID,
			status: data.status,
			tuscania_id: data.tuscania_id,
			type: data.type,
		};

		list.push(itemData);
	});

	return list;
};

const moraBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = {
			id: documment.id,
			amount: data.SaldoFecha,
			payments: data.details,
			updated_at: moment(data.fechaActualizacion.toDate()).format("Y-MM-DD"),
			updated_time: moment(data.fechaActualizacion.toDate()).format("HH:mm"),
			created_at: moment(data.fechaCreacion.toDate()).format("Y-MM-DD"),
			created_time: moment(data.fechaCreacion.toDate()).format("HH:mm"),
			profileId: data.idProfile,
			residentialId: data.idResidential,
			houseNumber: data.noCasa,
		};

		list.push(itemData);
	});

	return list;
};

const paymentAccountBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = {
			id: documment.id,
			concept: data.concept,
			invoice_cdi: data.invoice_cdi,
			invoice_date: moment(data.invoice_date.toDate()).format("Y-MM-DD"),
			invoice_time: moment(data.invoice_date.toDate()).format("HH:mm"),
			invoice_total: data.invoice_total,
			movtype: data.movtype,
			origin_fam: data.origin_fam,
			saldo_actual: data.saldo_actual,
			//payment_date: data.payment_date && moment(data.payment_date.toDate()).format("Y-MM-DD"),
			//payment_date_time: data.payment_date && moment(data.payment_date.toDate()).format("HH:mm"),
			//residential_id: data.residential_id ? data.residential_id  : null,
			//profile_id: data.profile_id ? data.profile_id  : null,
			status: data.status,
			suc_cdi: data.suc_cdi,
			type: data.type,
			vnd_cdi: data.vnd_cdi,
			//user_name: data.user_name,
			//payment_type: data.payment_type
		};

		list.push(itemData);
	});

	return list;
};

export const columnsAllPaymentsTable = () => {
	return [
		{
			title: "Fecha",
			dataIndex: "created_at",
			key: "created_at",
			...getColumnSearchProps("created_at"),
		},
		{
			title: "Nombre",
			dataIndex: "user_name",
			key: "user_name",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("user_name"),
		},
		{
			title: "Concepto",
			dataIndex: "concept",
			key: "concept",
			...getColumnSearchProps("concept"),
		},
		{
			title: "Monto",
			dataIndex: "amount",
			key: "amount",
			...getColumnSearchProps("amount"),
		},
		/* {
			title: "Estado",
			dataIndex: "status",
			key: "status",
			...getColumnSearchProps("status"),
		}, */
		{
			title: "Detalle",
			dataIndex: "action",
			key: "action",
		},
	];
};
