import { getColumnsFilter } from '../../../../helpers/customTableHelper'
import { useMonitoringContratorsContext } from "../../contexts/MonitoringContratorsContext"
import { workersTableColumnsPrev, workersTableFilters } from "../../helpers/workersTableHelper"
import { useCustomSearch } from '../../../../hooks/useCustomTable'
import { changeTableParams } from '../../../../helpers/customTableHelper'
import useWorkersServices from './useWorkersServices'
import { defaulStateTable } from '../../mocks/defaultStates'
import moment from 'moment'

export const useWorkers = () => {
    const { historyAccessTable, workersTable, loading, param, setLoading, setWorkersTable, handleRedirectTo } = useMonitoringContratorsContext()
    const { getWorkersByAccessAndAccessDateService } = useWorkersServices()
    const { handleReset, handleSearch } = useCustomSearch()

    const serviceParams = { previousData: workersTable.dataTable, actualLimit: workersTable.metadata.limit, limit: workersTable.metadata.limit, currentPage: workersTable.currentPage, skip: 0 }
    const access_date = historyAccessTable.active?.accessDate && moment(historyAccessTable.active.accessDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

    const getInitialData = async () => {
        if (!historyAccessTable.active || !access_date) return
        setLoading(true)
        const { data, metadata } = await getWorkersByAccessAndAccessDateService({ setLoading, access_id: param.accessId, limit: 10, previousData: workersTable.dataTable, currentPage: workersTable.currentPage, access_date })
        setWorkersTable({
            ...structuredClone(defaulStateTable),
            dataTable: data.dataSource,
            metadata
        })
        setLoading(false)
    }

    const handleChangeTable = async ({ pagination, sorter, filters }) => {
        const tableParams = changeTableParams({ serviceParams, pagination, filters, sorter, setState: setWorkersTable, state: workersTable })
        if (!tableParams) return
        setLoading(true)

        const { data, metadata } = await getWorkersByAccessAndAccessDateService({ setLoading, ...tableParams.finalParams, access_id: param.accessId, access_date })
        setWorkersTable(prev => ({
            ...prev,
            dataTable: data.dataSource,
            metadata,
            currentParams: { ...tableParams.newParams },
            currentFilters: tableParams.actualFilters,
            lastSkip: tableParams.finalParams.skip
        }))
        setLoading(false)
    }

    const columns = workersTableColumnsPrev().map(column => ({
        ...column,
        ...getColumnsFilter(column.dataIndex, workersTableFilters({ currentFilters: workersTable.currentFilters, currentParams: workersTable.currentParams, handleReset, handleSearch }))
    }))


    return {
        getInitialData, handleChangeTable, handleRedirectTo,
        columns, workersTable, loading, historyAccessTable, access_date
    }
}

export default useWorkers