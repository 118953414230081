import { Row, Col } from 'antd'
import { CustomCheckbox } from '../../../../../../../../UI/CustomCheckbox'
import DayItem from './components/DayItem'
import { daysAmenity } from '../../../../../../constants/daysAmenityData'

//Componente para renderizar la lista de días en que  estará disponible la reserva 
//Admeás de tener los checkbox para indicar si se seleccionan todos los día o si las horas para los días seleccionados es la misma 
const ScheduleDays = ({ Form, form, handleChangeDay, handleChangeTimeDay, handleEnableDisableFormItem, generalRules }) => {
    const days = structuredClone(daysAmenity)
    return (
        <article>
            <h3>Días en los que estará disponible la amenidad</h3>
            <Row>
                <Col span={24}>
                    <Form.Item name={'days_options'}>
                        <CustomCheckbox onChange={(value) => handleEnableDisableFormItem({ value, name: 'days_options' })} horizontal className='custom-checkbox schedule-options-checkbox' options={[{ label: 'Seleccionar todos', value: 'all' }, { label: 'Horario universal', value: 'universal' }]} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.List name='days' initialValue={days} rules={[{
                validator: async (_, value) => {
                    if (value.every(item => item.status === 'unchecked')) { //Valida que al menos un día esté chequeado
                        return Promise.reject(new Error('Se necesita al menos un día activo para la disponibilidad de la amenidad'))
                    }
                    const days = form?.getFieldValue('days')
                    let error = null
                    days.forEach(item => { //Verifica que en todos los días las horas tengan un valor y que además la hora de inicio no sea después de la hora final
                        const time_begin = item.time_begin
                        const time_end = item.time_end
                        if (!time_begin || !time_end) {
                            return error = Promise.reject(new Error('Se necesitan corregin las horas de un día en específico'))
                        }
                        if (time_begin?.clone()?.isSameOrAfter(time_end?.clone())) {
                            return error = Promise.reject(new Error('Las horas no pueden solaparse en uno de los días'))
                        }
                    })
                    if (error) return error
                    return Promise.resolve()
                }
            }]}>
                {
                    (fields, { add, remove }, { errors }) => (
                        <>
                            { //Se renderiza la lista de los items de días y sus inputs para las horas
                                fields.map(field => (
                                    <DayItem handleChangeTimeDay={handleChangeTimeDay} handleEnableDisableFormItem={handleEnableDisableFormItem} handleChangeDay={handleChangeDay} form={form} field={field} key={field?.key} Form={Form} />
                                ))
                            }
                            <Form.ErrorList errors={errors} />
                        </>
                    )
                }
            </Form.List>
        </article>
    )
}

export default ScheduleDays
