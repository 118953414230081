import { Row, Button } from 'antd'
import { EditGreen } from '../../../../assets/icons';
import CustomSwitch from '../../../UI/CustomSwitch';

//Array de columnas a utilizar en la tabla de Admón de Amenidades
export const managmentAmenitiesTableColumnsPrev = ({ handleChangeAmenityStatus, handleEditAmenity }) => ([
    {
        title: 'NOMBRE DE AMENIDAD',
        className: 'name-column',
        align: 'left',
        ellipsis: true,
        dataIndex: 'name',
        key: 'name',
        width: '75%',
    },
    {
        title: '',
        ellipsis: true,
        dataIndex: 'actions',
        key: 'actions',
        width: '15%',
        render: (text, record) => (
            <Row align='middle' style={{ gap: 8 }}>
                <Button style={{ width: '110px', height: '25px' }} shape='round' onClick={() => handleEditAmenity(record)} className='btn-standard-white' icon={<EditGreen />}>Editar</Button>
            </Row>
        )
    },
    {
        title: 'ESTADO',
        ellipsis: true,
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        render: (text, record) => ( //Renderiza un switch para activar o desactivar la amenidad en el endpoint
            <CustomSwitch onClick={() => handleChangeAmenityStatus(record)} />
        )
    },
])