import RobotoLight from './Roboto/Roboto-Light.ttf'
import RobotoRegular from './Roboto/Roboto-Regular.ttf'
import RobotoMedium from './Roboto/Roboto-Medium.ttf'
import RobotoBold from './Roboto/Roboto-Bold.ttf'
import PoppinsLight from './Poppins/Poppins-Light.ttf'
import PoppinsRegular from './Poppins/Poppins-Regular.ttf'
import PoppinsMedium from './Poppins/Poppins-Medium.ttf'
import PoppinsSemiBold from './Poppins/Poppins-SemiBold.ttf'
import PoppinsBold from './Poppins/Poppins-Bold.ttf'

export {
  RobotoLight,
  RobotoRegular,
  RobotoMedium,
  RobotoBold,
  PoppinsBold,
  PoppinsLight,
  PoppinsMedium,
  PoppinsRegular,
  PoppinsSemiBold,
}