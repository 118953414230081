import React, { useMemo } from 'react'
import '../style/login.css'
import { Button, Input } from 'antd';
import { UserOutlined, UnlockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks';
import { startLoginWithEmailPassword } from '../../store/auth';
import { logoLogin } from '../../assets/logos'


export const Login = () => {


    const { status, errorMessage } = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const { email, password, onInputChange } = useForm({
        email: '',
        password: ''
    });

    const isAuthenticating = useMemo(() => status === 'checking', [status]);


    const handleLogin = () => {
        dispatch(startLoginWithEmailPassword({ email, password }));
    }

    return (
        <div className='wrapper-login'>

            <div className='login-box'>

                <img className='logo' src={logoLogin} alt="Topia - Logo" />
                <h2 id="login-slogan">
                    La nueva forma <br />
                    de <span>convivir</span>
                </h2>


                <form action="">
                    <div className="form-group">
                        <Input
                            size="large"
                            name="email"
                            value={email}
                            onChange={onInputChange}
                            placeholder="Usuario"
                            prefix={<UserOutlined />} />
                    </div>

                    <div className="form-group">
                        <Input.Password
                            type="password"
                            size="large"
                            placeholder="Contraseña"
                            name="password"
                            value={password}
                            onChange={onInputChange}
                            prefix={<UnlockOutlined />} />
                    </div>

                    <div className="form-group">
                        <Button loading={isAuthenticating} className='btn-login' type="primary" shape="round" onClick={handleLogin} disabled={isAuthenticating}>
                            Ingresar
                        </Button>
                    </div>

                </form>

                <p className='copyright'>
                    Derechos Reservados Topia {new Date().getFullYear()}. IT Latinoamérica
                    <br />
                    <span>soporte@topiapp.com</span>
                </p>
            </div>

        </div>
    )
}
