import { useState } from "react"
import useMonitoringContratorsServices from "./useMonitoringContratorsServices"
import { useMonitoringContratorsContext } from "../../contexts/MonitoringContratorsContext"

export const useMonitoringContrators = () => {
    const { getAllContratorAccessServices } = useMonitoringContratorsServices()
    const { visibleDrawer, handleOpenCloseDrawer } = useMonitoringContratorsContext()


    return { visibleDrawer, handleOpenCloseDrawer }
}

export default useMonitoringContrators