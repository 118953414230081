import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import FormItem from "antd/lib/form/FormItem";
import { useNavigate } from "react-router-dom";
import NewsDetailForm from "./NewsDetailForm";

const CreateNewsPage = () => {
	const [type, setType] = useState("news");
	const [title, setTitle] = useState("");
	const [subTitle, setSubTitle] = useState("");
	const [date, setDate] = useState("");
	const [time, setTime] = useState("");
	const [location, setLocation] = useState("");
	const [description, setDescription] = useState("");
	const [provider, setProvider] = useState("");
	const [inscription, setInscription] = useState(true);
	const [color, setColor] = useState("green");
	const [imgPath, setImgPath] = useState("");
	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};
	const { TextArea } = Input;

	const navigate = useNavigate();

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={24}>
								<h2 className="title-page">Nueva noticia</h2>
							</Col>
							<Col span={24}>
								<Card
									style={{
										width: "100%",
										borderRadius: "10px",
										boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
										marginTop: "30px",
										marginBottom: "30px",
									}}
									bordered
								>
									<Col span={24} style={{ marginBottom: "2rem" }}>
										<div className="create-news">
											<p>TIPO:</p>

											<Radio.Group
												onChange={(e) => {
													setType(e.target.value);
												}}
												value={type}
											>
												<Space>
													<Radio value={"news"}>NOTICIA</Radio>
													<Radio value={"event"}>EVENTO</Radio>
													<Radio value={"notification"}>NOTIFICACIÓN</Radio>
													{/* <Radio value={4}>INFORMACIÓN</Radio> */}
												</Space>
											</Radio.Group>
										</div>
									</Col>
									<NewsDetailForm type={type}/>
								</Card>
							</Col>
						</Row>
					</div>
				</Content>
			</Layout>
		</>
	);
};

export default CreateNewsPage;
