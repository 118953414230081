import { Row, Col } from 'antd'

//Componente que renderiza los detalles de los eventos de la solicitud de la reserva como el tipo de evento, la fecha, la hora, la cantidad de invitados, la edad, el agasajado y el método de pago
const EventDetail = ({ eventData }) => (
  <div className='event-details'>
    <h4>Datos del evento</h4>
    <Row gutter={40}>
      <Col span={16}>
        <div className='event-item' >
          <small>Tipo de evento:</small>
          <span>{eventData?.reservation_user_type}</span>
        </div>
        <div className='event-item' >
          <small>Fecha:</small>
          <span>{eventData?.reservation_date}</span>
        </div>
        <div className='event-item' >
          <small>Hora:</small>
          <span>{eventData?.reservation_time}</span>
        </div>
        <div className='event-item' >
          <small>Agosajado:</small>
          <span>{eventData?.reservation_celebrant_name}</span>
        </div>
        <div className='event-item' >
          <small>Edad:</small>
          <span>{eventData?.reservation_celebrant_age}</span>
        </div>
      </Col>
      <Col span={8}>
        <div className='event-item' >
          <small>Invitados:</small>
          <span>{eventData?.reservation_invitee_quantity}</span>
        </div>
        <div className='event-item' >
          <small>Tipo de evento*:</small>
          <span>{eventData?.reservation_type}</span>
        </div>
        <div className='event-item' >
          <small>Método de Pago:</small>
          <span style={{ color: '#10b48c' }}>{eventData?.reservation_payment_method}</span>
        </div>
      </Col>
    </Row>
  </div>
)

export default EventDetail
