import { testComprobant } from "../assets";

export const bookingDetailTest = {
    "reservation_id": 527,
    "PROFILE": {
        "profile_id": "8ekJW08IO213zygpnpYZ",
        "profile_name": "Jorge Eduardo  Salume Dahuabe"
    },
    "EVENT": {
        "reservation_type": "Boda",
        "reservation_user_type": "Evento de propietario",
        "reservation_user_comment": "lorem ipsum",
        "reservation_date": "jueves, 9 de mayo de 2024",
        "reservation_time": "De 10:00 AM a 11:00 AM",
        "reservation_celebrant_name": "Tyler",
        "reservation_celebrant_age": "40 años",
        "reservation_invitee_quantity": 45,
        "reservation_payment_method": "Link de pago",
        "reservation_status_code": "awaitingPayment",
        "reservation_status_name": "Espera de cotización",
        "reservation_payment_asset": testComprobant,
        "reservation_payment_creation": "01/06/2024 - 8:00 PM",
        "reservation_time_arriving": "8:00 AM"
    },
    "SPECIFICATIONS": {
        "animation_items": [
            "Payaso / Animador",
            "DJ / Disco"
        ],
        "reservation_places": [
            "Terraza",
            "Jardin terraza",
            "Capilla"
        ],
        "aditional_area": [
            "Cancha de futbol"
        ],
        "reservation_place_name": "Casa club villa",
        "forniture_items": [
            {
                "name": "Silla tipo Lifestyle",
                "quantity": 1
            },
            {
                "name": "Mesa redonda para 8 personas tipo Lifestyle",
                "quantity": 1
            }
        ]
    },
    "QUOTE": {
        "reservation_invitee_cost": 170,
        "reservation_security_guard_cost": null,
        "reservation_forniture_cost": null,
        "reservation_quote_comment": null
    }
}