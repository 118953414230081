import { Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { BookingDetailIcon, ProfileIcon } from '../assets'
import { bookingStatuses } from '../constants/statuses'
import { AutoCompleteFilter } from '../UI/AutoCompleteFilter'

//Objeto que exporta las columnas como valor y donde la clave es la que coincide con la key para las tabs donde se utilizan las tablas
export const keyColumns = {
    received: ['place_id', 'profile_id', 'reservation_date', 'reservation_time', 'status_name', 'details'],
    approved: ['place_id', 'profile_id', 'reservation_date', 'payment_method', 'total_quantity', 'status_name', 'last_update', 'details'],
    canceled: ['place_id', 'profile_id', 'reservation_date', 'reservation_time', 'status_name', 'details'],
}

//Se exporta el array de las columnas para la tabla
export const bookingTableColumnsPrev = ({ handleBookingDetails }) => ([
    {
        title: 'AMENIDAD',
        className: 'amenity_name-column',
        align: 'center',
        ellipsis: true,
        dataIndex: 'amenity_name',
        key: 'place_id',
        place: 'amenity_name',
        width: '15%',
    },
    {
        title: 'SOLICITANTE',
        ellipsis: true,
        dataIndex: 'profile_name',
        key: 'profile_id',
        place: 'profile_name',
        width: '15%',
        render: (text, record) => ( //Renderiza un componente custom
            <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                <ProfileIcon style={{ width: '32px' }} />
                <span style={{ width: 'calc(100% - 32px)' }}> {record?.profile_name}</span>
            </div>
        )
    },
    {
        title: 'FECHA SOLICITADA',
        ellipsis: true,
        dataIndex: 'reservation_date',
        key: 'reservation_date',
        place: 'reservation_date',
        width: '15%',
    },
    {
        title: 'HORA SOLICITADA',
        ellipsis: true,
        dataIndex: 'reservation_time',
        key: 'reservation_time',
        place: 'reservation_time',
        width: '15%',
    },
    {
        title: 'MÉTODO DE PAGO',
        ellipsis: true,
        dataIndex: 'payment_method',
        key: 'payment_method',
        place: 'payment_method',
        width: '13%',
    },
    {
        title: 'MONTO',
        ellipsis: true,
        dataIndex: 'total_quantity',
        key: 'total_quantity',
        place: 'total_quantity',
        width: '12%',
    },
    {
        title: 'ESTADO',
        ellipsis: true,
        dataIndex: 'status_name',
        key: 'status_name',
        place: 'status_name',
        width: '12%',
        render: (text, record) => (
            <div className='statusTable-item' style={{ backgroundColor: bookingStatuses[record?.status_name]?.backgroundColor }}>
                <div className='dot-icon' style={{ backgroundColor: bookingStatuses[record?.status_name]?.dotColor }} /> <span style={{ color: bookingStatuses[record?.status_name]?.color }}>{bookingStatuses[record?.status_name]?.name}</span>
            </div>
        )
    },
    {
        title: 'APROBACIÓN',
        ellipsis: true,
        dataIndex: 'last_update',
        key: 'last_update',
        place: 'last_update',
        width: '10%',
    },
    {
        title: 'DETALLE',
        ellipsis: true,
        dataIndex: 'details',
        key: 'details',
        place: 'details',
        width: '10%',
        render: (text, record) => (
            <Button onClick={() => handleBookingDetails({ record })} style={{ border: 'none', backgroundColor: 'transparent', boxShadow: 'none' }} icon={<BookingDetailIcon />}></Button>
        )
    },
])

//Exporta los filtros que se van a utilizan en las columnas de la tabla
export const bookingsTableFilters = ({ currentParams, currentFilters, handleSearch, handleReset, amenityService, profileService }) => {
    return {
        default: () => ({}),
        amenity_name: () => ({
            filterIcon: () => <SearchOutlined />, //Icono del filtro en la columna
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (<AutoCompleteFilter //Componente que va a aparece cuando se clickee el icono del filtro
                placeholder={'Seleccionar amenidad'}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                clearFilters={clearFilters}
                confirm={confirm}
                handleReset={handleReset}
                handleSearch={handleSearch}
                isAsync={true}
                service={amenityService} />),
            filteredValue: currentFilters?.place_id || '',

        }),
        profile_name: () => ({
            filterIcon: () => <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (<AutoCompleteFilter //Componente que va a aparece cuando se clickee el icono del filtro
                placeholder={'Seleccionar usuario'}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                clearFilters={clearFilters}
                confirm={confirm}
                handleReset={handleReset}
                handleSearch={handleSearch}
                isAsync={true}
                service={profileService} />),
            filteredValue: currentFilters?.profile_id || '',
            sorter: (a, b) => { },
            sortDirection: ["descend", "ascend"],
            sortOrder: currentParams?.sort_by === 'profile_name' ? (currentParams?.sort_order === 'asc' ? 'ascend' : 'descend') : null
        }),
        reservation_date: () => ({
            sorter: (a, b) => { },
            sortDirection: ["descend", "ascend"],
            sortOrder: currentParams?.sort_by === 'reservation_date' ? (currentParams?.sort_order === 'asc' ? 'ascend' : 'descend') : null
        }),
        reservation_time: () => ({
            sorter: (a, b) => { },
            sortDirection: ["descend", "ascend"],
            sortOrder: currentParams?.sort_by === 'reservation_time' ? (currentParams?.sort_order === 'asc' ? 'ascend' : 'descend') : null
        }),
    }
}