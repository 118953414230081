import React from "react";
import { Table, Button, Input, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getPaymentsHelper,
} from "../../../../helpers/paymentsHelper";
import { setActivePayment } from "../../../../store/payments/paymentsSlice";
import { getUserDataProfileById } from "../../../../helpers/globalHelper";
import { SearchOutlined } from "@ant-design/icons";
import { printIcon } from "../../../../assets/icons";
import { shape14 } from "../../../../assets/images";

export const AllPaymentTab = () => {
	const [paymentList, setPaymentList] = useState([]);
	const { payments } = useSelector((state) => state.payments);
	const [loading, setLoading] = useState(true);

	const [paymentDetailModal, setPaymentDetailModal] = useState(false);
	const [paymentActive, sePaymentActive] = useState([]);
	const [fullTransactions, setFullTransactions] = useState([]);
	const IconButtonReport = () => (
		<img
			alt="print-icon"
			style={{ width: "20px", height: "20px" }}
			src={printIcon}
		/>
	);
	const dispatch = useDispatch();

	/* useEffect(() => {
		dispatch(getPayments());
	}, [dispatch]);
 */
	/* useEffect(() => {
		sePaymentActive(payments.active);
		if (!payments.active) {
			setPaymentDetailModal(false);
		}
	}, [payments.active]); */
	useEffect(() => {
		let today = new Date();
		let prevDate = new Date(Date.parse("1/01/2020"));
		var fechaInicio = new Date(prevDate.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(24, 0, 0, 0));

		let trans = getPaymentsHelper(
			"Yd5MV7jkA0MjQeCmzKrZ",
			fechaInicio,
			fechaFinal
		).then((transactions) => sePaymentActive(transactions));
	}, []);

	useEffect(() => {
		if (fullTransactions.length > 0) {
			setLoading(false);
		}
	}, [fullTransactions]);

	useEffect(() => {
		const getDataForTransactions = async () => {
			const _data = paymentActive?.map(async (item, index) => {
				let data = { ...item };
				const profile = await getUserDataProfileById(data.profile_id).then(
					(result) => (data.profileData = result)
				);
				return data;
			});

			Promise.all(_data).then((data) => {
				setFullTransactions(data);
			});
		};
		getDataForTransactions().catch(console.error);
	}, [paymentActive]);

	useEffect(() => {
		let _data = payments.data.map((item, index) => {
			let data = { ...item };
			data.action = (
				<img
					src={shape14}
					style={{ cursor: "pointer", width: "24px" }}
					alt="phone-money-icon"
					onClick={() => handleStatusVisitorModal(true, index)}
				/>
			);
			data.amount = `$${data.amount}`;
			return data;
		});

		setPaymentList(_data);
	}, [payments.data]);

	const handleStatusVisitorModal = (statusModal, index = null) => {
		if (index != null) {
			dispatch(setActivePayment(payments.data[index]));
		} else {
			dispatch(setActivePayment(null));
		}

		setTimeout(() => {
			setPaymentDetailModal(statusModal);
		}, 50);
	};

	const countSingleLiquidation = (record) => {
		let total = 0;
		let totalDiscount = 0;
		total = Number(record.amount) + record.mora;
		totalDiscount += +(
			record.payment_details.accFee +
			record.payment_details.taxFee +
			record.payment_details.topiaFee
		);
		total = parseFloat(total).toFixed(2) - parseFloat(totalDiscount).toFixed(2);
		return total;
	};

	const columns = [
		{
			title: "FECHA",
			dataIndex: "payment_date",
			key: "payment_date",
			align: "center",
			/* sorter: (a, b) => a.constructionFee - b.constructionFee,
                render: (text, record) => (
                    <>{moment(record.date_action).format("YY/MM/D")}</>
                ), */
		},
		{
			title: "RESIDENTE",
			dataIndex: "profileData.name",
			key: "profileData.name",
			align: "left",
			render: (text, record) => (
				<>
					{record?.profileData?.name} {record?.profileData?.lastname}
				</>
			),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<div style={{ padding: 8 }}>
							<Input
								autoFocus
								placeholder=""
								value={selectedKeys[0]}
								onChange={(e) => {
									setSelectedKeys(e.target.value ? [e.target.value] : []);
									confirm({ closeDropdown: false });
								}}
								onPressEnter={() => {
									confirm();
								}}
								onBlur={() => {
									confirm();
								}}
								style={{ marginBottom: 8, display: "block" }}
							></Input>
							<Space>
								<Button
									icon={<SearchOutlined />}
									onClick={() => {
										confirm();
									}}
									type="primary"
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => {
										clearFilters();
									}}
									size="small"
									style={{ width: 90 }}
								>
									Limpiar
								</Button>
							</Space>
						</div>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.profileData?.name
					.toLowerCase()
					.includes(value.toLowerCase());
			},
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "TIPO DE OPERACIÓN",
			dataIndex: "type",
			key: "type",
			//onCell: (record) => ({ className: "table-name-cell-invited" }),
			/* sorter: (a, b) => a.type - b.type, */
		},
		{
			title: "Cobro",
			dataIndex: "amount",
			key: "amount",
			sorter: (a, b) => Number(a.amount + a.mora) - Number(b.amount + b.mora),
			render: (text, record) => (
				<>
					{currencyFormat(
						(Number(parseFloat(record.amount)) + record.mora)
							.toFixed(2)
							.toString()
					)}
				</>
			),
		},
		{
			title: "IMPUESTOS",
			dataIndex: "payment_details.taxFee",
			key: "payment_details.taxFee",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.payment_details.taxFee).toFixed(2).toString()
					)}
				</>
			),
			sorter: (a, b) => a.payment_details.taxFee - b.payment_details.taxFee,
		},
		{
			title: "COMISIÓN",
			dataIndex: "payment_details.accFee",
			key: "payment_details.accFee",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.payment_details.accFee).toFixed(2).toString()
					)}
				</>
			),
			sorter: (a, b) => a.payment_details.accFee - b.payment_details.accFee,
		},
		{
			title: "FEE TOPIA",
			dataIndex: "payment_details.topiaFee",
			key: "payment_details.topiaFee",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.payment_details.topiaFee).toFixed(2).toString()
					)}
				</>
			),
			sorter: (a, b) => a.payment_details.topiaFee - b.payment_details.topiaFee,
		},
		{
			title: "LIQUIDACIÓN",
			dataIndex: "closeout",
			key: "closeout",
			render: (text, record) => (
				<>
					{currencyFormat(countSingleLiquidation(record).toFixed(2).toString())}
				</>
			),
			//sorter: (a, b) => a.closeout - b.closeout,
		},
		/* {
			title: "",
			dataIndex: "action_user",
			key: "action_user",
			render: (text, record) => (
				<>
					<img
						src="/assets/icons/money_phone.svg"
						alt="money_phone"
						style={{ cursor: "pointer" }}
						onClick={() => {
							handleReportModal(true, record);
						}}
					/>
				</>
			),
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		}, */
	];

	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	return (
		<>
			<Table
				className="primary-table"
				loading={loading}
				columns={columns}
				dataSource={fullTransactions}
				pagination={{ defaultPageSize: 15, simple: true }}
				rowKey={"id"}
			/>

			{/* <Modal
				className="side-modal"
				title="Datos de pago"
				style={{ top: 0, right: 0 }}
				visible={paymentDetailModal}
				onCancel={() => handleStatusVisitorModal(false)}
				footer={null}
			>
				{paymentActive ? (
					<>
						<Row className="section-modal">
							<Col span={16}>
								<span className="title">Usuario</span>
								<div className="user-data" style={{ marginTop: "0px" }}>
									<div className="user-info">
										<span className="user-name" style={{ marginLeft: "0px" }}>
											{paymentActive.profile?.name}
											<img
												style={{ marginLeft: "10px" }}
												className="iconPerfil"
												src="/assets/images/shape08.png"
												alt="Topia"
											/>
										</span>
									</div>
								</div>
							</Col>
							<Col span={8}>
								<div>
									{paymentActive.profile?.contact_phone && (
										<span
											style={{
												color: "#10B48C",
												position: "relative",
												top: "25px",
											}}
										>
											<img
												style={{ marginLeft: "10px" }}
												src="/assets/images/shape09.png"
												alt="Topia"
											/>

											<span style={{ marginLeft: "5x" }}>
												{paymentActive.profile?.phone}
											</span>
										</span>
									)}
								</div>
							</Col>

							<Col style={{ marginTop: "10px" }}>
								{paymentActive.payment_date && (
									<div
										className="alert-modal-header"
										style={{ paddingLeft: "0px" }}
									>
										<span className="title">
											Fecha de pago: &nbsp; {paymentActive.payment_date}
										</span>
									</div>
								)}
							</Col>
						</Row>

						<Row className="section-modal">
							<Col span={24} style={{ display: "flex" }}>
								<div className="alert-modal-header">
									<span className="title">Concepto:</span>
									<p>{paymentActive.concept}</p>
								</div>
							</Col>
							<Col span={24} style={{ display: "flex" }}>
								<div className="alert-modal-header">
									<span className="title">Monto:</span>
									<p>${paymentActive.amount}</p>
								</div>
							</Col>
							<Col span={24} style={{ display: "flex" }}>
								<div className="alert-modal-header">
									<span className="title">Estado:</span>
									<p>{paymentActive.status}</p>
								</div>
							</Col>
							<Col span={24} align="middle">
								<br />
								<br />
								<Button
									style={{
										color: "#10b48c",
										borderColor: "#10b48c",
										background: "white",
										paddingBottom: "15px",
										marginLeft: "15px",
										marginRight: "15px",
									}}
									shape="round"
									size="large"
									icon={<IconButtonReport />}
								>
									<span className="title" style={{ marginLeft: "10px" }}>
										IMPRIMIR
									</span>
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<>
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					</>
				)}
			</Modal> */}
		</>
	);
};
