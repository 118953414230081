import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    headerInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginTop:8,
        marginBottom:32
    },
    headerInfoTitle: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        fontWeight: 'semibold',
        fontSize: 12,
        color: '#444444',
        paddingTop: 24,
        paddingBottom: 12
    },
    headerInfoDetails: {
        fontFamily: 'Poppins',
        fontSize: 10,
        paddingBottom: 4
    },
    table: {
        display: 'flex',
        width: 'auto',
        borderRadius:4,        
        color:'#676767'      
    },
    tableRow: {
        width:'100%',
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCol: {
        width: '20%',
    },
    tableCell: {
        fontFamily:'Poppins',        
        textAlign:'center', 
        paddingVertical: 10,        
        fontSize: 9,
    }
})