import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const submenuSlice = createSlice({
    name: 'submenu',
    initialState: {
        menu: {
            title: "",
            items: []
        },
        errorMessage: null
    },
    reducers: {
        setSubmenu: ( state, { payload } ) => {
            state.menu.title = payload?.title;
            state.menu.items = payload?.items;
        },
        clearSubmenu: ( state, { payload } ) => {
            state.menu.title = "";
            state.menu.items = undefined;
        },
    }
});


export const { 
    setSubmenu,
    clearSubmenu
} = submenuSlice.actions;