import { loginWithEmailPassword, registerUserWithEmailPassword, singInWithGoogle, logoutFirebase } from '../../firebase/authProvider';
import { toast } from 'react-toastify'
import { setSidebarTheme } from '../sidebar/thunks';
import { checkingCredentials, logout, login } from './';

export const checkingAuthentication = () => {
    return async (dispatch) => {

        dispatch(checkingCredentials());

    }
}


export const startGoogleSignIn = () => {
    return async (dispatch) => {

        dispatch(checkingCredentials());

        const result = await singInWithGoogle();
        if (!result.ok) return dispatch(logout(result.errorMessage));

        dispatch(login(result))

    }
}


export const startCreatingUserWithEmailPassword = ({ email, password, displayName }) => {
    return async (dispatch) => {

        dispatch(checkingCredentials());

        const result = await registerUserWithEmailPassword({ email, password, displayName });
        if (!result.ok) return dispatch(logout(result.errorMessage));

        dispatch(login(result))

    }

}


export const startLoginWithEmailPassword = ({ email, password, idTemplate }) => {
    return async (dispatch) => {
        const result = await loginWithEmailPassword({ email, password });
        if (!result.ok) {
            toast.error('Error en las credenciales al iniciar sesión, intentalo de nuevo.')
            return dispatch(logout(result));
        }
        dispatch(checkingCredentials({}));

        //Al autenticarse se ejecutará el useCheckAuth debido al onAuthStateChanged de Firebase
        // por lo que no tiene sentido llamar lo mismo aqui

        //Se debería llamar al servicio de getTemplate.js en este lugar? Pero aqui si esperar el endpoint
        await dispatch(setSidebarTheme({ idTheme: idTemplate, login: true }))
        //Id del template o tema del sidebar proveniente de los datos del usuario
        return "default"
    };
};

export const startLogout = () => {
    return async (dispatch) => {
        await logoutFirebase();
        localStorage.removeItem('currentRol')
        localStorage.removeItem('companyName')
        localStorage.removeItem('typeRol')
        localStorage.removeItem('currentResidential')
        localStorage.removeItem('currentEmployee')
        localStorage.removeItem('employees')
        dispatch(logout());
    };
};