import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import moment from 'moment';

export const surveysSlice = createSlice({
    name: 'surveys',
    initialState: {
        surveys: {
            data: [],
            active: null
        },
        errorMessage: null
    },
    reducers: {
        setSurvey: ( state, { payload } ) => {
            state.surveys.data = payload;
        },
        createSurveys: ( state, { payload } ) => {
            var surversList = state.surveys.data

            payload.created_at = payload.created_at == null ? "" : moment(payload.created_at).format("Y-MM-DD")
            payload.startDate = payload.startDate == null ? "" : moment(payload.startDate).format("Y-MM-DD")
            payload.endDate = payload.endDate == null ? "" : moment(payload.endDate).format("Y-MM-DD")
            payload.encuestasContestadas = 0

            var indexEncuesta = surversList.findIndex((item) => item.id === payload.id)
            if(indexEncuesta !== -1){
                surversList[indexEncuesta] = payload
                state.surveys.data = surversList
            }else{
                state.surveys.data.unshift(payload);
            }

            

            //state.surveys.data.unshift(payload);
            toast.success("Encuesta guardada");
        },
        setActiveSurveys: (state, { payload })=>{
            state.surveys.active = payload
        }
    }
});


export const { 
    createSurveys,
    setSurvey,
    setActiveSurveys,
} = surveysSlice.actions;