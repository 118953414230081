import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { ImportantTabPane } from "./components/ImportantTabPane";
import moment from "moment";
import { useDispatch } from "react-redux";
import "./style.css";
import { getAlerts } from "../../../store/alerts";

export const ImportantPage = () => {
	const dispatch = useDispatch();

	/* useEffect(() => {
		let today = new Date();
		let prevDate = new Date(Date.parse("1/01/2020"));
		var fechaInicio = new Date(prevDate.setHours(0, 0, 0, 0));

		var fechaFinal = new Date(today.setHours(24, 0, 0, 0));

		dispatch(getPayments(fechaInicio, fechaFinal));
	}, [dispatch]); */

	useEffect(() => {
		let today = new Date();
		let prevDate = new Date(Date.parse("1/01/2020"));
		var fechaInicio = new Date(prevDate.setHours(0, 0, 0, 0));

		var fechaFinal = new Date(today.setHours(24, 0, 0, 0));

		dispatch(getAlerts(fechaInicio, fechaFinal));
	}, [dispatch]);

	const getDate = () => {
		return moment().format("Y-MM-D");
	};

	const onPanelChange = (value, mode) => {
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<Row>
						<Col span={24} lg={24} xs={24} className="header-date">
							<div className="wrapper">
								<div className="container-date">
									<span className="title">Inicio</span>
									<span className="date"> {getDate()} </span>
								</div>
							</div>
						</Col>

						{/*<Col span={ 4 } lg={ 4 } xs={0} className="header-alert">      
    <div className='wrapper'>
      <span className='alert-side'>
        Alertas
      </span>
    </div>
  </Col>*/}
					</Row>

					<Row>
						<Col span="20" lg={24} xs={24}>
							<div className="wrapper" style={{ marginTop: "30px" }}>
								<ImportantTabPane></ImportantTabPane>
							</div>
						</Col>
						{/*
  <Col span={4} lg={ 4 } xs={0} className="side-alert-wrapper">
  <div className="side-alert-container">

    <div className="alert-list">
        Alertas
    </div>
    
    <div className="event-list">
        <div className="header-title">
          Eventos
        </div>
        <div className="event-container">
          Lista de eventos
        </div>

    </div>

  </div>
</Col>
*/}
					</Row>
				</Col>
				{/* <Col span={5}>
					<Row>
						<Col
							span={24}
							lg={24}
							xs={24}
							className="header-date-side"
							align="middle"
							style={{ height: "61.42px" }}
						>
							<div className="wrapper">
								<div className="container-date-side">
									<span className="titleDate">Calendario de reservas</span>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<div className="side-wrapper">
								<br />
								<br />
								<div className="site-calendar-demo-card">
									<Calendar fullscreen={false} onPanelChange={onPanelChange} />
								</div>
								<br />
								<div style={{ paddingLeft: "20px" }}>
									<span
										style={{
											fontSize: "12px",
											fontWeight: "500",
											color: "#10b48c",
										}}
									>
										Jueves 17 de Enero del 2023
									</span>
									<br />
									<br />
									<span
										style={{
											fontSize: "14px",
											fontWeight: "500",
											color: "#232c4a",
										}}
									>
										Salón Multiusos
									</span>
									<br />
									<span
										style={{
											fontSize: "12px",
											fontWeight: "400",
											color: "#232c4a",
											paddingLeft: "10px",
										}}
									>
										<img
											src="/assets/icons/Reservation_icon_2.svg"
											alt="reservacionicono"
											style={{ paddingRight: "5px" }}
										/>
										Salón Multiusos
									</span>
								</div>
							</div>
						</Col>
						<Col span={24}></Col>
					</Row>
				</Col> */}
			</Row>
		</>
	);
};
