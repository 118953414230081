import { Col, Row } from "antd";
import { AprobacionUserTab } from "./components/AprobacionUserTab";

export const AprobacionUsersPage = () => {
	return (
		<>
			<div className="wrapper-page">
				<Row>
					<Col span={24}>
						<AprobacionUserTab></AprobacionUserTab>
					</Col>
				</Row>
			</div>
		</>
	);
};
