import { Popconfirm, Popover } from "antd"

const CustomPopoverConfirm = ({
  popConfirm,
  children,
  content,
  trigger,
  placement,
  overlayClassName,
  destroyTooltipOnHide,
  onConfirm,
  title,
  okText,
  cancelText
}) => {
  if (popConfirm) return (
    <Popconfirm
      onConfirm={onConfirm}
      overlayClassName={overlayClassName}
      title={title}
      okText={okText}
      cancelText={cancelText}
      placement={placement}
    >
      {children}
    </Popconfirm>
  )

  return (
    <Popover   
      destroyTooltipOnHide={destroyTooltipOnHide}
      overlayClassName={overlayClassName}
      placement={placement}
      trigger={trigger}
      content={content}
    >
      {children}
    </Popover>
  )
}

export default CustomPopoverConfirm;