import { ReactComponent as ArrowLeft } from './icons/arrowLeft.svg';
import arrowLeft from './icons/arrowLeft.svg';
import { ReactComponent as EditMenuIcon } from './icons/editMenuIcon.svg';
import editMenuIcon from './icons/editMenuIcon.svg';
import { ReactComponent as MenuOptionsIcon } from './icons/menuOptionsIcon.svg';
import menuOptionsIcon from './icons/menuOptionsIcon.svg';

export {
  arrowLeft, ArrowLeft,
  editMenuIcon, EditMenuIcon,
  menuOptionsIcon, MenuOptionsIcon,
};
