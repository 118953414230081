import React from "react";

export const AnnouncementDetail = ({ announcementDetail }) => {
	return (
		<>
			{announcementDetail && (
				<>
					<div className="primary-card-detail">
						<div className="primary-card-section from">
							<h4>PARA:</h4>
							<span>{announcementDetail.from_user}</span>
						</div>

						<div className="primary-card-section">
							<h4>FECHA DE ENVÍO:</h4>
							<p>{announcementDetail.created_at}</p>
						</div>
						<div className="primary-card-section">
							<h4>ASUNTO:</h4>
							<p>{announcementDetail.about}</p>
						</div>
						<div className="primary-card-section">
							<h4>MENSAJE:</h4>
							<p>{announcementDetail.description}</p>
						</div>
					</div>
				</>
			)}
		</>
	);
};
