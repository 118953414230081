import React from "react";
import {
	Col,
	Row,
	Button,
	Modal,
	Table,
	Descriptions,
} from "antd";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getColumnSearchProps } from "../../../utils/tables";
import * as XLSX from "xlsx";
import { getPaymentsHelper } from "../../../helpers/paymentsHelper";
import {
	getQueryDocByCollection,
	getUserDataProfileById,
} from "../../../helpers/globalHelper";
import UserProfileModal from "../../../components/UserProfileModal";
import { getDataByNomenclatureIdAndHouseIdHelper } from "../../../helpers/nomenclaturasHelper";
import { backIcon, moneyPhone, phone, send, sheets } from "../../../assets/icons";
import { shape08 } from "../../../assets/images";

const PerfilLiquidacionPage = () => {
	const { date } = useParams();
	const [userProfileActive, setUserProfileActive] = useState([]);
	const [idForTable, setIdForTable] = useState([]);
	const [transactions, setTransactions] = useState([]);
	const [fullTransactions, setFullTransactions] = useState([]);
	const [profileModalActive, setProfileModalActive] = useState(false);
	const [transactionModalActive, setTransactionModalActive] = useState(false);
	const [reportModalActive, setReportModalActive] = useState(false);
	const [reportData, setReportData] = useState([]);
	const navigate = useNavigate();
	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};
	const IconButtonReport = () => (
		<img
			alt="send-icon"
			style={{ width: "20px", height: "20px" }}
			src={send}
		/>
	);

	useEffect(() => {
		const getPayments = async () => {
			let day = date.slice(0, 2);
			let month = parseInt(date.slice(3, 5)) - 1;

			let year = date.slice(5, 10);
			let newDate = date + " GMT";
			newDate = new Date(year, month, day, 0, 0);
			var fechaInicio = new Date(newDate.setHours(0, 0, 0, 0));
			var fechaFinal = new Date(newDate.setHours(23, 59, 59, 59));
			const paymentByDate = await getPaymentsHelper(
				"Yd5MV7jkA0MjQeCmzKrZ",
				fechaInicio,
				fechaFinal
			).then((transactions) => setTransactions(transactions));
		};
		getPayments();
	}, []);

	useEffect(() => {
		const getDataForTransactions = async () => {
			const _data = transactions?.map(async (item, index) => {
				let data = { ...item };
				const profile = await getUserDataProfileById(data.profile_id).then(
					(result) => (data.profileData = result)
				);
				return data;
			});

			Promise.all(_data).then((data) => {
				setFullTransactions(data);
			});
		};
		getDataForTransactions().catch(console.error);
	}, [transactions]);

	const handleProfileModal = async (statusModal, index = null) => {
		let active_user = reportData.profileData;
		if (active_user.ownerHome) {
			// Agregamosal detalle los dependientes de los socios
			let relativesWithAccess = await getQueryDocByCollection(
				"/family_access",
				"idProfileMain",
				"==",
				active_user.id
			);
			active_user = {
				...active_user,
				relativesWithAccess: relativesWithAccess,
			};
			/* if (active_user.origin_fam) {
				const billingDataHistory = await getPaymentsHelperByAccount(
					active_user.origin_fam
				).then((billingD) => {
					active_user.billingData = billingD;
				});
			} */
			if (active_user.residentials) {
				let nomenclaturePath = active_user.residentials[0]?.house["path"];
				nomenclaturePath = nomenclaturePath.substring(47, 67);

				let housePath = active_user.residentials[0]?.house["path"];
				housePath = housePath.substring(75, 95);

				const houseData = await getDataByNomenclatureIdAndHouseIdHelper(
					"Yd5MV7jkA0MjQeCmzKrZ",
					nomenclaturePath,
					housePath
				).then((houseD) => {
					active_user.houseData = houseD;
				});
			}

			setUserProfileActive(active_user);
		}

		setTimeout(() => {
			setProfileModalActive(statusModal);
		}, 50);
	};

	const countAllCharges = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			const amm = Number(parseFloat(item.amount).toFixed(2));
			total += +(amm + item.mora);
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllTaxes = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.payment_details.taxFee).toFixed(2);
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllComissions = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.payment_details.accFee).toFixed(2);
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllTopiaFee = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.payment_details.topiaFee).toFixed(2);
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllLiquidations = (transData) => {
		let total = 0;
		let totalDiscount = 0;
		transData.map(async (item, index) => {
			const amm = Number(parseFloat(item.amount).toFixed(2));
			total += +(amm + item.mora);
			totalDiscount += +(
				item.payment_details.accFee +
				item.payment_details.taxFee +
				item.payment_details.topiaFee
			);
		});

		total = parseFloat(total).toFixed(2) - parseFloat(totalDiscount).toFixed(2);
		return total;
	};

	const countSingleLiquidation = (record) => {
		let total = 0;
		let totalDiscount = 0;
		total = Number(record.amount) + record.mora;
		totalDiscount += +(
			record.payment_details.accFee +
			record.payment_details.taxFee +
			record.payment_details.topiaFee
		);
		total = parseFloat(total).toFixed(2) - parseFloat(totalDiscount).toFixed(2);
		return total;
	};

	const formatDate = (date) => {
		const dateString = date;
		const dateParts = dateString.split("-");

		const day = dateParts[0];
		const month = getMonthName(dateParts[1]);
		const year = dateParts[2];

		const formattedDate = `${day} de ${month} de ${year}`;

		return formattedDate;
	};
	function getMonthName(monthNumber) {
		const months = [
			"enero",
			"febrero",
			"marzo",
			"abril",
			"mayo",
			"junio",
			"julio",
			"agosto",
			"septiembre",
			"octubre",
			"noviembre",
			"diciembre",
		];
		return months[monthNumber - 1];
	}

	const transactionColumns = [
		{
			title: "HORA",
			dataIndex: "payment_time",
			key: "payment_time",
			align: "center",
			/* sorter: (a, b) => a.constructionFee - b.constructionFee,
                render: (text, record) => (
                    <>{moment(record.date_action).format("YY/MM/D")}</>
                ), */
		},
		{
			title: "RESIDENTE",
			dataIndex: "profileData.name",
			key: "profileData.name",
			align: "center",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			render: (text, record) => (
				<>
					{record.profileData?.name} {record.profileData?.lastname}
				</>
			),
			...getColumnSearchProps("profileData.name"),
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "TIPO DE OPERACIÓN",
			dataIndex: "type",
			align: "center",
			key: "type",
			//onCell: (record) => ({ className: "table-name-cell-invited" }),
			/* sorter: (a, b) => a.type - b.type, */
		},
		{
			title: "Cobro",
			dataIndex: "amount",
			key: "amount",
			align: "center",
			sorter: (a, b) => Number(a.amount + a.mora) - Number(b.amount + b.mora),
			render: (text, record) => (
				<>
					{currencyFormat(
						(Number(parseFloat(record.amount)) + record.mora)
							.toFixed(2)
							.toString()
					)}
				</>
			),
		},
		{
			title: "IMPUESTOS",
			dataIndex: "payment_details.taxFee",
			key: "payment_details.taxFee",
			align: "center",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.payment_details.taxFee).toFixed(2).toString()
					)}
				</>
			),
			sorter: (a, b) => a.payment_details.taxFee - b.payment_details.taxFee,
		},
		{
			title: "COMISIÓN",
			dataIndex: "payment_details.accFee",
			key: "payment_details.accFee",
			align: "center",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.payment_details.accFee).toFixed(2).toString()
					)}
				</>
			),
			sorter: (a, b) => a.payment_details.accFee - b.payment_details.accFee,
		},
		{
			title: "FEE TOPIA",
			dataIndex: "payment_details.topiaFee",
			key: "payment_details.topiaFee",
			align: "center",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.payment_details.topiaFee).toFixed(2).toString()
					)}
				</>
			),
			sorter: (a, b) => a.payment_details.topiaFee - b.payment_details.topiaFee,
		},
		{
			title: "LIQUIDACIÓN",
			dataIndex: "closeout",
			key: "closeout",
			align: "center",
			render: (text, record) => (
				<>
					{currencyFormat(countSingleLiquidation(record).toFixed(2).toString())}
				</>
			),
			//sorter: (a, b) => a.closeout - b.closeout,
		},
		{
			title: "",
			dataIndex: "action_user",
			key: "action_user",
			align: "center",
			render: (text, record) => (
				<>
					<img
						src={moneyPhone}
						alt="money_phone"
						style={{ cursor: "pointer" }}
						onClick={() => {
							handleReportModal(true, record);
						}}
					/>
				</>
			),
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
	];

	//Modal functions
	const handleReportModal = async (statusModal, record = null) => {
		if (record != null) {
			let activeTransaction = record;
			setReportData(activeTransaction);
		}
		setTimeout(() => {
			setReportModalActive(statusModal);
		}, 50);
	};
	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}
	const handleExportExcel = () => {
		let data = [];
		fullTransactions.map((item) => {
			data.push({
				time: item.created_time,
				phone: item.profileData?.phone,
				name: item.profileData?.name + " " + item.profileData?.lastname,
				type: item.type,
				concept: item.concept,
				reservDate: item.type == "Reserva" ? "--" : null,
				charge: parseFloat(item.amount).toFixed(2),
				taxFee: parseFloat(item.payment_details.taxFee).toFixed(2),
				accFee: parseFloat(item.payment_details.accFee).toFixed(2),
				topiaFee: parseFloat(item.payment_details.topiaFee).toFixed(2),
				liq: countSingleLiquidation(item),
			});
		});
		let Heading = [
			[
				"Hora creación",
				"Número residente",
				"Nombre residente",
				"Tipo de operación",
				"Concepto",
				"Fecha de reservación",
				"Cobro",
				"Impuestos",
				"Comisión",
				"Fee Topia",
				"Liquidación",
			],
		];
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_aoa(ws, Heading);
		XLSX.utils.sheet_add_json(ws, data, {
			origin: "A2",
			skipHeader: true,
		});
		XLSX.utils.book_append_sheet(wb, ws, "Hoja 1");
		XLSX.writeFile(wb, "Transacción.xlsx");
	};
	return (
		<>
			{fullTransactions ? (
				<div>
					<Row className="profile-user-data">
						<Col span={5}>
							<div className="profile-user-data-container">
								<h2
									className="title-page"
									style={{ color: "#232c4a", textTransform: "uppercase" }}
								>
									<img
										src={backIcon}
										alt="back-icon"
										style={{ paddingRight: "12px", cursor: "pointer" }}
										onClick={() => {
											navigate("/pagos");
										}}
									/>
									{formatDate(date)}
								</h2>
							</div>
						</Col>
						<Col span={6} align="left">
							<p
								style={{
									paddingTop: "2.4rem",
									paddingBottom: "2rem",
									color: "#10b48c",
								}}
							>
								Transacciones: {fullTransactions?.length}
							</p>
						</Col>
						<Col span={7}></Col>
						<Col span={6}>
							<div className="profile-user-data-container">
								<img
									src={sheets}
									alt="sheets"
									onClick={handleExportExcel}
									style={{ cursor: "pointer" }}
								/>
								{/* <img src="/assets/icons/pdf.svg" alt="pdf" />
									<img src="/assets/icons/print.svg" alt="print" /> */}
							</div>
						</Col>

						<Col span={4} align="center">
							<div className="header-text-report">
								<h4>
									COBROS:{" "}
									{fullTransactions
										? currencyFormat(
												countAllCharges(fullTransactions).toString()
										  )
										: "N/A"}
								</h4>
							</div>
						</Col>
						<Col span={4} align="center">
							<div className="header-text-report">
								<h4>
									IMPUESTO:{" "}
									{fullTransactions
										? currencyFormat(countAllTaxes(fullTransactions).toString())
										: "N/A"}
								</h4>
							</div>
						</Col>
						<Col span={6} align="center">
							<div className="header-text-report">
								<h4>
									COMISIÓN BANCARIA:{" "}
									{fullTransactions
										? currencyFormat(
												countAllComissions(fullTransactions).toString()
										  )
										: "N/A"}
								</h4>
							</div>
						</Col>
						<Col span={5} align="center">
							<div className="header-text-report">
								<h4>
									FEE TOPIA:{" "}
									{fullTransactions
										? currencyFormat(
												countAllTopiaFee(fullTransactions).toString()
										  )
										: "N/A"}
								</h4>
							</div>
						</Col>
						<Col span={5} align="center">
							<div className="header-text-report">
								<h4 style={{ color: "#10b48c" }}>
									LIQUIDACIÓN:{" "}
									{fullTransactions
										? currencyFormat(
												countAllLiquidations(fullTransactions)
													.toFixed(2)
													.toString()
										  )
										: "N/A"}
								</h4>
							</div>
						</Col>
					</Row>
					<div className="wrapper-page">
						<Table
							className="primary-table"
							pagination={{ defaultPageSize: 20, simple: true }}
							columns={transactionColumns}
							dataSource={fullTransactions}
							loading="true"
							rowKey={"id"}
							locale={locale}
						/>
						{/* <Button
							style={{
								color: "#ADB9BB",
								background: "white",
								border: "10px",
								fontSize: "11px",
								fontWeight: "500px",
								width: "104px",
							}}
							onClick={() => {
								handleReportModal(true);
							}}
							size="small"
						>
							<span className="title">Abrir modal</span>
						</Button> */}
					</div>
				</div>
			) : (
				"no hay"
			)}

			{/* MODAL REPORTE */}
			<Modal
				className="side-modal"
				title="Detalle del pago"
				style={{ top: 0, right: 0 }}
				visible={reportModalActive}
				onCancel={() => handleReportModal(false)}
				footer={null}
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
				width={600}
			>
				<>
					{reportData ? (
						<>
							<Row className="section-modal" style={{ paddingTop: "0px" }}>
								<Col span={24}>
									<p>USUARIO</p>
								</Col>
								<Col span={17}>
									<h2>
										{reportData.profileData?.name}{" "}
										<img
											className="iconPerfil"
											src={shape08}
											alt="credential-security-icon"
											style={{ cursor: "pointer", marginLeft: "5px" }}
											onClick={() => handleProfileModal(true)}
										/>
									</h2>
								</Col>
								<Col span={7}>
									<h3 style={{ color: "#10B48C", paddingTop: "5px" }}>
										<img
											className="iconoPerfil"
											src={phone}
											alt="phone-icon"
										/>
										{reportData.profileData?.phone}
									</h3>
								</Col>
								<Col span={24}>
									<p>FECHA DE PAGO: {reportData?.payment_date}</p>
								</Col>
								<br />
								<br />
								<br />
								<Col span={24}>
									<p>CONCEPTO</p>
								</Col>
								<Col span={24} style={{ fontWeight: "bold" }}>
									<p>{reportData?.concept}</p>
								</Col>
								{/* <Col span={24}>
									<p>FECHA DE RESERVA</p>
								</Col> */}
								{/* <Col span={24} style={{ fontWeight: "bold" }}>
									<p>{reportData?.futureDate}</p>
								</Col> */}
								<br />
								<br />
								<br />
							</Row>
							<Row className="section-modal">
								<Col span={24} align="middle">
									{reportData.payment_details ? (
										<Descriptions
											bordered
											column={1}
											style={{ textAlign: "center" }}
											size="small"
										>
											<Descriptions.Item label="Cobro">
												{reportData.amount
													? currencyFormat(
															parseFloat(reportData.amount)
																.toFixed(2)
																.toString()
													  )
													: ""}
											</Descriptions.Item>
											<Descriptions.Item label="IMPUESTO">
												{reportData.payment_details.taxFee
													? currencyFormat(
															parseFloat(reportData.payment_details.taxFee)
																.toFixed(2)
																.toString()
													  )
													: ""}
											</Descriptions.Item>
											<Descriptions.Item label="COMISIÓN">
												{reportData.payment_details.accFee
													? currencyFormat(
															parseFloat(reportData.payment_details.accFee)
																.toFixed(2)
																.toString()
													  )
													: ""}
											</Descriptions.Item>
											<Descriptions.Item label="FEE TOPIA">
												{reportData.payment_details.topiaFee
													? currencyFormat(
															parseFloat(reportData.payment_details.topiaFee)
																.toFixed(2)
																.toString()
													  )
													: ""}
											</Descriptions.Item>
											<Descriptions.Item label="LIQUIDACIÓN">
												{reportData
													? currencyFormat(
															countSingleLiquidation(reportData)
																.toFixed(2)
																.toString()
													  )
													: ""}
											</Descriptions.Item>
										</Descriptions>
									) : (
										"datos no encontrados"
									)}
								</Col>
								<Col span={24} align="middle">
									<br />
									<br />
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
											paddingBottom: "15px",
											marginLeft: "15px",
											marginRight: "15px",
										}}
										shape="round"
										size="large"
										icon={<IconButtonReport />}
									>
										<span
											className="title"
											style={{ marginLeft: "10px", color: "#10b48c" }}
										>
											ENVIAR
										</span>
									</Button>
								</Col>
							</Row>
						</>
					) : (
						<></>
					)}
				</>
			</Modal>
			{/* MODAL PARA PERFIL SOCIO */}
			<UserProfileModal
				userActive={userProfileActive}
				userModalActive={profileModalActive}
				handleStatusResidentsModal={handleProfileModal}
			/>
		</>
	);
};

export default PerfilLiquidacionPage;
