import { getColumnsFilter } from "../../../../helpers/customTableHelper"
import { useMonitoringContratorsContext } from "../../contexts/MonitoringContratorsContext"
import { monitoringContratorsTableColumnsPrev, monitoringContratorsTableFilters } from "../../helpers/monitoringContratorTableHelper"
import { useCustomSearch } from "../../../../hooks/useCustomTable"
import { changeTableParams } from '../../../../helpers/customTableHelper'
import { defaulStateTable } from "../../mocks/defaultStates"
import { getContratorProfile } from "../../services/GET/getContratorProfile"
import { CustomDatePicker } from "../../../../UI/CustomDatePicker/CustomDatePicker"
import { Row } from "antd"
import CustomTable from "../../../../UI/CustomTable/CustomTable"
import useMonitoringContratorsServices from "./useMonitoringContratorsServices"
import moment from "moment"

export const useMonitoringContratorsTable = () => {

    const { setMonitoringContratorsTable, monitoringContratorsTable, loading, setLoading, handleOpenCloseDrawer, handleRedirectTo } = useMonitoringContratorsContext()
    const { handleReset, handleSearch } = useCustomSearch()
    const { getAllContratorAccessServices } = useMonitoringContratorsServices()

    const getInitialData = async ({ status }) => {
        setLoading(true)
        const { data, metadata } = await getAllContratorAccessServices({ setLoading, status, previousData: monitoringContratorsTable.dataTable, currentPage: monitoringContratorsTable.currentPage, limit: 10 })
        const defaultData = structuredClone(defaulStateTable)
        setMonitoringContratorsTable({
            ...defaultData,
            dataTable: data.dataSource,
            metadata,
        })
        setLoading(false)
    }

    const serviceParams = { previousData: monitoringContratorsTable.dataTable, actualLimit: monitoringContratorsTable.metadata.limit, limit: monitoringContratorsTable.metadata.limit, currentPage: monitoringContratorsTable.currentPage, skip: 0 }

    const handleChangeTabs = async (key) => {
        await getInitialData({ status: key })
    }

    const handleAccess = ({ record }) => {
        setMonitoringContratorsTable(prev => ({ ...prev, active: record }))
        handleRedirectTo(`history/${record.id}`)
    }

    const handleContratorDetail = async ({ record }) => {
        setLoading(true)
        const data = await getContratorProfile({ profile_id: record.contractor.profileId })
        setLoading(false)
        setMonitoringContratorsTable(prev => ({ ...prev, active: { ...record.contractor, image: data.image } }))
        handleOpenCloseDrawer()
    }

    const handleChangeDatePickerFilter = async (values) => {

        const date_from = values ? moment(values[0]).format('YYYY-MM-DD') : undefined
        const date_to = values ? moment(values[1]).format('YYYY-MM-DD') : undefined

        setLoading(true)
        const { data, metadata } = await getAllContratorAccessServices({ setLoading, ...monitoringContratorsTable.currentParams, date_from, date_to, status: 'canceled', limit: 10 })

        setMonitoringContratorsTable((prev) => ({
            ...prev,
            currentPage: 1,
            dataTable: data.dataSource,
            metadata,
            currentParams: {
                ...prev.currentParams,
                date_to,
                date_from
            },
        }))
        setLoading(false)
    }

    const columns = monitoringContratorsTableColumnsPrev({ handleAccess, handleContratorDetail }).map(column => ({
        ...column,
        ...getColumnsFilter(column.dataIndex, monitoringContratorsTableFilters({ currentFilters: monitoringContratorsTable.currentFilters, currentParams: monitoringContratorsTable.currentParams, handleReset, handleSearch }))
    }))

    const handleChangeTable = async ({ pagination, filters, sorter, tableName }) => {

        const otherParams = {
            date_from: monitoringContratorsTable.currentParams.date_from,
            date_to: monitoringContratorsTable.currentParams.date_to,
        }

        const tableParams = changeTableParams({ serviceParams, pagination, filters, sorter, otherParams, state: monitoringContratorsTable, setState: setMonitoringContratorsTable })

        if (!tableParams) return

        setLoading(true)
        const { data, metadata } = await getAllContratorAccessServices({ ...tableParams.finalParams, setLoading, status: tableName === 'table-active' ? 'active' : 'canceled' })

        setMonitoringContratorsTable(prev => ({
            ...prev,
            dataTable: data.dataSource,
            metadata,
            currentParams: tableParams.newParams,
            currentFilters: tableParams.actualFilters,
        }))
        setLoading(false)
    }

    const children = (tableName) => (<CustomTable columns={columns} loading={loading} dataSource={monitoringContratorsTable.dataTable} tableName={tableName} className="custom-table-variant-1 monitor-table" onChange={handleChangeTable}
        pagination={{
            pageSize: monitoringContratorsTable.metadata.limit || undefined,
            total: monitoringContratorsTable.metadata.quantity || undefined,
            defaultPageSize: monitoringContratorsTable.metadata.limit || undefined,
            current: monitoringContratorsTable.currentPage || undefined,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            position: ["bottomRight"]
        }} />)

    const tabItems = [{
        label: 'PROYECTOS ACTIVOS',
        key: 'active',
        children: children('table-active')
    },
    {
        label: 'PROYECTOS FINALIZADOS',
        key: 'canceled',
        children: <>
            <Row justify="end"><CustomDatePicker allowClear={true} onChange={handleChangeDatePickerFilter} width="300px" type={'RANGER'} /></Row>
            {children('table-canceled')}
        </>
    },]

    return {
        tabItems,
        monitoringContratorsTable,
        handleChangeTabs,
        handleChangeDatePickerFilter,
        getInitialData,
    }
}

export default useMonitoringContratorsTable