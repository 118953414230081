import { Row, Button } from 'antd'
import MobiliaryItem from "./components/MobiliaryItem"
import NewMobiliaryModal from './components/NewMobiliaryModal'

//4° sección del formulario para poder detallar el mobiliario de la amenidad y añadir, editar o eliminar si se desea
const MobiliarityInfo = ({ Form, showModal, handleOpenCloseModal, handleDeleteItem, onSubmit, mobiliaryData, data }) => (
    <section className="booking-form-section">
        <Row align='middle' justify='space-between'>
            <h2>MOBILIARIO</h2>
            {/**Con este botón se abre un modal para añadir un nuevo mobiliario mediante un formulario */}
            <Button onClick={() => handleOpenCloseModal({ name: 'mobiliarityModal', value: true })} className='btn-standard-white' shape='round' style={{ width: 179 }}>AGREGAR</Button>
        </Row>
        <div className='mobiliarity-items'>
            <Form.List name={'mobiliary_data'} rules={[{
                validator: async (_, value) => {
                    if (!mobiliaryData?.length) { //Valida si hay almenos un mobilario almacenado en el estado
                        return Promise.reject(new Error('Se requiere al menos un mobiliario'))
                    }
                    return Promise.resolve()
                }
            }]} >
                {
                    (fields, { add, remove }, { errors }) => (
                        <>
                            {
                                mobiliaryData.map(item => ( //Se renderiza la lista de mobiliarios 
                                    <MobiliaryItem key={item.id} handleOpenCloseModal={handleOpenCloseModal} handleDeleteItem={handleDeleteItem} itemData={item} />
                                ))
                            }
                            <Form.ErrorList errors={errors} />
                        </>
                    )
                }

            </Form.List>
        </div>
        {/**Se coloca aquí el modal para abrir o cerrar para crear/editar el mobiliario */}
        <NewMobiliaryModal data={data} onSubmit={onSubmit} showModal={showModal} handleOpenCloseModal={handleOpenCloseModal} />
    </section >
)

export default MobiliarityInfo
