import { useEffect } from "react"
import { CustomInput } from "../../../../../../../UI/CustomInput"
import CustomModal from "../../../../../../../UI/CustomModal"
import { Form, Row, Button } from 'antd'

//Modal para poder crear/editar una restricción de la amenidad que incluye campos como el título y descrición
const NewRestrictionModal = ({ showModal, handleOpenCloseModal, onSubmit, data, generalRules }) => {

  const [form] = Form.useForm()

  //Hook para establecer la data en caso que se esté editando una restricción al montar el modal
  useEffect(() => {
    form.setFieldsValue(data)
  }, [form, data]);

  return (
    <dialog>
      <CustomModal title={<small style={{ display: 'block', width: '100%', textAlign: 'center', fontSize: 22, fontFamily: 'Poppins' }}>{data ? 'EDITAR' : 'AGREGAR'} RESTRICCIÓN</small>} destroyOnClose={true} className="custom-modal amenity-modal" isOpenModal={showModal} onCancel={() => handleOpenCloseModal({ name: 'restrictionModal', value: false })} >
        <Form layout='vertical' name="restriction-form" form={form} onFinish={(values) => onSubmit({ values, name: 'restrictionModal', form })}>
          <Form.Item name={'id'} noStyle>
            <CustomInput hidden={true} />
          </Form.Item>
          <Form.Item rules={[{...generalRules}]} name={'restriction_name'} label={<small>TÍTULO</small>}>
            <CustomInput />
          </Form.Item>
          <Form.Item rules={[{...generalRules}]} name={'restriction_description'} label={<small>DESCRIPCIÓN</small>}>
            <CustomInput type="AREA" autoSize={{ minRows: 2 }} />
          </Form.Item>
          <Row justify="center" style={{ paddingBottom: 32 }}>
            <Button htmlType="submit" shape="round" className="btn-standard-green">AGREGAR</Button>
          </Row>
        </Form>
      </CustomModal>
    </dialog>
  )
}

export default NewRestrictionModal
