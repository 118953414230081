import { useState } from "react"
import { useManagmentAmenitiesContext } from "../context/ManagmentAmenitiesContext"
import { managmentAmenitiesTableColumnsPrev } from "../helpers/managmentAmenitiesTableHelper"
import { defaulStateTable } from "../constants/defaultStates"
import { changeTableParams } from "../../../helpers/customTableHelper"

//Custom hook a utilizar en la página de Admón de Amenidades
const useManagmentAmenities = () => {

    const { handleRedirectTo, setLoading, loading } = useManagmentAmenitiesContext()

    const [managmentAmenitiesTable, setManagmentAmenitiesTable] = useState(structuredClone(defaulStateTable))

    //Define los parámetros a utilizar en la función que llama al servicio de datos
    const serviceParams = { previousData: managmentAmenitiesTable.dataTable, actualLimit: managmentAmenitiesTable.metadata.limit, limit: managmentAmenitiesTable.metadata.limit, currentPage: managmentAmenitiesTable.currentPage, skip: 0 }


    //Handle que permite modificar el estado de la amenidad, donde se llamará a una función que llame al servicio para actualizar los datos
    const handleChangeAmenityStatus = async (record) => {
        console.log(record)
    }

    //Handle para poder editar los datos de una amenidad seleccionada en la tabla
    const handleEditAmenity = async (record) => {
        console.log(record)
        handleRedirectTo('edit/1')
    }

    //Obtiene en un array las columnas a utilizar en la tabla de amenidades
    const initialColumns = managmentAmenitiesTableColumnsPrev({ handleChangeAmenityStatus, handleEditAmenity })

    //Handle que se utiliza en la tabla de amenidades para manipular la paginación, filtros y ordenamientos
    const handleChangeTable = async ({ pagination, filters, sorter, tableName }) => {
        const otherParams = {}
        //A partir de la función se recuperan los parámetros cuando sea posible para mandar los datos al servicio
        const tableParams = changeTableParams({ serviceParams, pagination, filters, sorter, setState: setManagmentAmenitiesTable, state: managmentAmenitiesTable, otherParams })

        //Si no hay parametros no hace nada
        if (!tableParams) return

        setLoading(true)

        //const {data, metadata } = await someService()

        // setmanagmentAmenitiesTable(prev => ({
        //     ...prev,
        //     dataTable: data.dataSource,
        //     metadata,
        //     currentParams: tableParams.newParams,
        //     currentFilters: tableParams.actualFilters,
        //     lastSkip: tableParams.finalParams.skip
        // }))
        setLoading(false)

    }

    //Función que recuperará los datos para mostrar las amenidades disponibles en la tabla
    const getInitialData = async () => {
        setManagmentAmenitiesTable(prev => ({
            ...prev,
            dataTable: structuredClone([{ name: 'Casa Club Villa', key: 0 }, { name: 'Casa Club Valle', key: 1 },]),
            metadata: { ...prev.metadata, limit: 10 }
        }))
    }

    return { initialColumns, managmentAmenitiesTable, loading, getInitialData, handleChangeTable, handleRedirectTo }
}

export default useManagmentAmenities
