export const defaulStateTable = Object.freeze({
    active: {},
    dataTable: [],
    metadata: { limit: 25, quantity: 0 },
    currentParams: {
        find_by: undefined,
        find_value: undefined,
        sort_by: undefined,
        sort_order: undefined,
        skip: 0,
    },
    currentFilters: {},
    currentPage: 1,
})