import { Row, Button } from 'antd'
import CustomDropdown from '../../../../../../../UI/CustomDropdown/CustomDropdown'
import { EditMenuIcon, MenuOptionsIcon } from '../../../../../assets'
import '../../../styles.css'
import { DeleteIcon1 } from '../../../../../../../../assets/icons'

//Subcomponente de la lista de mobiliario de una amenidad que muestra un dropdown para poder abrir un modal para editar la información del mobiliario o eliminar este
//además de mostrar el nombre del mobiliario la cantidad máxima y el costo
const MobiliaryItem = ({ name, itemData, handleDeleteItem, handleOpenCloseModal }) => (
  <div className="mobiliarity-item">
    <Row justify='space-between' style={{ borderBottom: '0.15em dashed #D3D3D3', paddingBottom: 4 }}>
      <strong>{itemData?.mobiliary_name}</strong>
      <CustomDropdown arrow={false} overlayClassName='custom-dropdown-overlay mobiliary-dropdown' menuOptions={[
        {
          onClick: () => handleDeleteItem({ actualItem: itemData, name: 'mobiliarityModal' }),
          value: 'delete', label: <Row align='middle' style={{ gap: 8 }}>
            <DeleteIcon1 width={16} height={16} />
            <span>Eliminar</span>
          </Row>
        }, {
          onClick: () => handleOpenCloseModal({ data: itemData, name: 'mobiliarityModal', value: true }),
          value: 'edit', label: <Row align='middle' style={{ gap: 8 }}>
            <EditMenuIcon />
            <span>Editar</span>
          </Row>
        },
      ]}>
        {/**Boton que abrirá el dropdown */}
        <Button icon={<MenuOptionsIcon />} style={{ border: 'none', backgroundColor: 'transparent', boxShadow: 'none' }} />
      </CustomDropdown>
    </Row>
    <Row justify='space-between' style={{ marginTop: 8 }}>
      <span>Cantidad máximo por reserva</span> <span>{itemData?.max_quantity}</span>
    </Row>
    <Row justify='space-between' style={{ marginTop: 8 }}>
      <span>Costo unitario</span> <span>${itemData?.price}</span>
    </Row>
  </div>
)

export default MobiliaryItem
