import { ReactComponent as DeleteIcon1 } from './Actions/deleteIcon1.svg';
import deleteIcon1 from './Actions/deleteIcon1.svg';
import { ReactComponent as Edit } from './Actions/edit.svg';
import edit from './Actions/edit.svg';
import { ReactComponent as EditGreen } from './Actions/editGreen.svg';
import editGreen from './Actions/editGreen.svg';
import { ReactComponent as PlusIcon } from './Actions/plusIcon.svg';
import plusIcon from './Actions/plusIcon.svg';
import { ReactComponent as PlusIconWhite } from './Actions/plusIconWhite.svg';
import plusIconWhite from './Actions/plusIconWhite.svg';
import { ReactComponent as SmallEdit } from './Actions/smallEdit.svg';
import smallEdit from './Actions/smallEdit.svg';
import { ReactComponent as Trash } from './Actions/trash.svg';
import trash from './Actions/trash.svg';
import { ReactComponent as BackIcon } from './Arrows/backIcon.svg';
import backIcon from './Arrows/backIcon.svg';
import { ReactComponent as GrayUser } from './Avatar/grayUser.svg';
import grayUser from './Avatar/grayUser.svg';
import { ReactComponent as Auto } from './Common/auto.svg';
import auto from './Common/auto.svg';
import { ReactComponent as Reporte } from './Common/reporte.svg';
import reporte from './Common/reporte.svg';
import { ReactComponent as ReservationIcon2 } from './Common/reservationIcon2.svg';
import reservationIcon2 from './Common/reservationIcon2.svg';
import { ReactComponent as Shape15 } from './Common/shape15.svg';
import shape15 from './Common/shape15.svg';
import { ReactComponent as Shape19 } from './Common/shape19.svg';
import shape19 from './Common/shape19.svg';
import { ReactComponent as Shape25 } from './Common/shape25.svg';
import shape25 from './Common/shape25.svg';
import { ReactComponent as ExportSheets } from './Documents/exportSheets.svg';
import exportSheets from './Documents/exportSheets.svg';
import { ReactComponent as Pdf } from './Documents/pdf.svg';
import pdf from './Documents/pdf.svg';
import { ReactComponent as Sheets } from './Documents/sheets.svg';
import sheets from './Documents/sheets.svg';
import { ReactComponent as AppBanner } from './Feedback/appBanner.svg';
import appBanner from './Feedback/appBanner.svg';
import { ReactComponent as GreenLike } from './Feedback/greenLike.svg';
import greenLike from './Feedback/greenLike.svg';
import { ReactComponent as HandIcon } from './Feedback/handIcon.svg';
import handIcon from './Feedback/handIcon.svg';
import { ReactComponent as LaptopExcel } from './Feedback/laptopExcel.svg';
import laptopExcel from './Feedback/laptopExcel.svg';
import { ReactComponent as ServicioAlCliente } from './Feedback/servicioAlCliente.svg';
import servicioAlCliente from './Feedback/servicioAlCliente.svg';
import { ReactComponent as CheckedServiceIcon } from './Form/checkedServiceIcon.svg';
import checkedServiceIcon from './Form/checkedServiceIcon.svg';
import { ReactComponent as CheckGris } from './Form/checkGris.svg';
import checkGris from './Form/checkGris.svg';
import { ReactComponent as CheckRojo } from './Form/checkRojo.svg';
import checkRojo from './Form/checkRojo.svg';
import { ReactComponent as CheckServiceIcon } from './Form/checkServiceIcon.svg';
import checkServiceIcon from './Form/checkServiceIcon.svg';
import { ReactComponent as GreenCheck } from './Form/greenCheck.svg';
import greenCheck from './Form/greenCheck.svg';
import { ReactComponent as GreyCheck } from './Form/greyCheck.svg';
import greyCheck from './Form/greyCheck.svg';
import { ReactComponent as MoneyPhone } from './Money/moneyPhone.svg';
import moneyPhone from './Money/moneyPhone.svg';
import { ReactComponent as Visa } from './Money/visa.svg';
import visa from './Money/visa.svg';
import { ReactComponent as Llaves } from './Security/llaves.svg';
import llaves from './Security/llaves.svg';
import { ReactComponent as Shape08 } from './Security/shape08.svg';
import shape08 from './Security/shape08.svg';
import { ReactComponent as Shape17 } from './Security/shape17.svg';
import shape17 from './Security/shape17.svg';
import { ReactComponent as Shape18 } from './Security/shape18.svg';
import shape18 from './Security/shape18.svg';
import { ReactComponent as Ubicacion3 } from './Security/ubicacion3.svg';
import ubicacion3 from './Security/ubicacion3.svg';
import { ReactComponent as Pool } from './Shapes/pool.svg';
import pool from './Shapes/pool.svg';
import { ReactComponent as Print } from './Shapes/print.svg';
import print from './Shapes/print.svg';
import { ReactComponent as PrintIcon } from './Shapes/printIcon.svg';
import printIcon from './Shapes/printIcon.svg';
import { ReactComponent as AccessIcon } from './Sidebar/accessIcon.svg';
import accessIcon from './Sidebar/accessIcon.svg';
import { ReactComponent as ConfigIcon } from './Sidebar/configIcon.svg';
import configIcon from './Sidebar/configIcon.svg';
import { ReactComponent as HomeIcon } from './Sidebar/homeIcon.svg';
import homeIcon from './Sidebar/homeIcon.svg';
import { ReactComponent as MessagesIcon } from './Sidebar/messagesIcon.svg';
import messagesIcon from './Sidebar/messagesIcon.svg';
import { ReactComponent as PaymentsIcon } from './Sidebar/paymentsIcon.svg';
import paymentsIcon from './Sidebar/paymentsIcon.svg';
import { ReactComponent as ReporteIcon } from './Sidebar/reporteIcon.svg';
import reporteIcon from './Sidebar/reporteIcon.svg';
import { ReactComponent as UsersIcon } from './Sidebar/usersIcon.svg';
import usersIcon from './Sidebar/usersIcon.svg';
import { ReactComponent as Mensaje } from './Social/mensaje.svg';
import mensaje from './Social/mensaje.svg';
import { ReactComponent as Phone } from './Social/phone.svg';
import phone from './Social/phone.svg';
import { ReactComponent as Send } from './Social/send.svg';
import send from './Social/send.svg';
import { ReactComponent as Telefono } from './Social/telefono.svg';
import telefono from './Social/telefono.svg';
import { ReactComponent as Text } from './Social/text.svg';
import text from './Social/text.svg';
import { ReactComponent as CalendarIcon } from './Time/calendarIcon.svg';
import calendarIcon from './Time/calendarIcon.svg';
import { ReactComponent as Calendario } from './Time/calendario.svg';
import calendario from './Time/calendario.svg';
import { ReactComponent as ClockIcon } from './Time/clockIcon.svg';
import clockIcon from './Time/clockIcon.svg';
import { ReactComponent as GreenCalendar } from './Time/greenCalendar.svg';
import greenCalendar from './Time/greenCalendar.svg';
import { ReactComponent as Horario } from './Time/horario.svg';
import horario from './Time/horario.svg';

export {
  deleteIcon1, DeleteIcon1,
  edit, Edit,
  editGreen, EditGreen,
  plusIcon, PlusIcon,
  plusIconWhite, PlusIconWhite,
  smallEdit, SmallEdit,
  trash, Trash,
  backIcon, BackIcon,
  grayUser, GrayUser,
  auto, Auto,
  reporte, Reporte,
  reservationIcon2, ReservationIcon2,
  shape15, Shape15,
  shape19, Shape19,
  shape25, Shape25,
  exportSheets, ExportSheets,
  pdf, Pdf,
  sheets, Sheets,
  appBanner, AppBanner,
  greenLike, GreenLike,
  handIcon, HandIcon,
  laptopExcel, LaptopExcel,
  servicioAlCliente, ServicioAlCliente,
  checkedServiceIcon, CheckedServiceIcon,
  checkGris, CheckGris,
  checkRojo, CheckRojo,
  checkServiceIcon, CheckServiceIcon,
  greenCheck, GreenCheck,
  greyCheck, GreyCheck,
  moneyPhone, MoneyPhone,
  visa, Visa,
  llaves, Llaves,
  shape08, Shape08,
  shape17, Shape17,
  shape18, Shape18,
  ubicacion3, Ubicacion3,
  pool, Pool,
  print, Print,
  printIcon, PrintIcon,
  accessIcon, AccessIcon,
  configIcon, ConfigIcon,
  homeIcon, HomeIcon,
  messagesIcon, MessagesIcon,
  paymentsIcon, PaymentsIcon,
  reporteIcon, ReporteIcon,
  usersIcon, UsersIcon,
  mensaje, Mensaje,
  phone, Phone,
  send, Send,
  telefono, Telefono,
  text, Text,
  calendarIcon, CalendarIcon,
  calendario, Calendario,
  clockIcon, ClockIcon,
  greenCalendar, GreenCalendar,
  horario, Horario,
};
