import { useAppSelector } from '../../../../hooks/store'
import { getTableDataHelper } from '../../../helpers/getTableDataHelper'
import { getAllAmenities, getAllBookings, getBookingDetail, getBookingStatuses, getProfilesByCompany } from '../services/GET'
import { updateApprovedStatusBooking, updateAwaitingPaymentStatusBooking, updateCancelStatusBooking } from '../services/PUT'
import verifyResponse from '../../../helpers/verifyResponse'
import useRedirectTo from '../../../hooks/useRedirectTo'
import { getProfileData } from '../services/GET/getProfileData'

//Custom hook donde se van a definir la logica de las funciones al consultar los servicios de topia tanto para recuperar datos de las reservas como para actualizar datos de estas
const useBookingsServices = () => {
    const { idToken, currentRol } = useAppSelector(state => state.auth)
    const { handleRedirectTo } = useRedirectTo()

    const getAllAmenitiesService = async ({ find_by, find_value, limit, skip = 0, customSelectData }) => {
        const res = await getAllAmenities({ token: idToken, company_id: currentRol, find_by, find_value, limit, skip })
        const resStatus = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
        if (!resStatus) throw new Error('Error in getAllAmenitiesService ')
        if (customSelectData) {
            const newRes = {
                ...res,
                data: {
                    ...res.data,
                    data: {
                        dataSource: res.data.data?.map(item => ({
                            ...item,
                            id: item?.place_id,
                            name: item?.name
                        }))
                    }
                }
            }
            return newRes
        }
        return res.data.data
    }

    const getAllBookingsService = async ({
        reservation_status_code,
        place_id,
        profile_id,
        date_begin,
        date_end,
        sort_order,
        sort_value,
        limit,
        skip,
        last,
        previousData,
        currentPage,
        actualLimit,
    }) => {
        const res = await getAllBookings({ token: idToken, company_id: currentRol, reservation_status_code, place_id, profile_id, date_begin, date_end, sort_order, sort_value, limit, skip })
        const resStatus = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
        if (!resStatus) {
            throw new Error('Error in getAllBookingsService ')
        }
        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, skip, last, previousData, currentPage, actualLimit })
        return { data, metadata }
    }

    const getBookingDetailService = async ({ reservation_id, returnPath, setLoading }) => {
        setLoading && setLoading(prev => ({
            ...prev,
            requestPage: true
        }))
        const res = await getBookingDetail({ token: idToken, reservation_id })
        const resStatus = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
        if (!resStatus) {
            setLoading && setLoading(prev => ({
                ...prev,
                requestPage: false
            }))
            handleRedirectTo(returnPath)
            throw new Error('Error in getAllBookingsService ')
        }
        const imageProfile = await getProfileData({ profile_id: res.data.data.PROFILE.profile_id })
        if (!imageProfile) {
            setLoading && setLoading(prev => ({
                ...prev,
                requestPage: false
            }))
            handleRedirectTo(returnPath)
            throw new Error('Error in getAllBookingsService ')
        }
        if (imageProfile) {
            res.data.data.PROFILE.image = imageProfile.image
        }
        return res.data.data
    }

    const getBookingStatusesService = async ({ date_begin, date_end }) => {
        const res = await getBookingStatuses({ token: idToken, company_id: currentRol, date_begin, date_end })
        const resStatus = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
        if (!resStatus) throw new Error('Error in getBookingStatusesService ')
        return res.data.data
    }
    const getProfilesByCompanyService = async ({ limit, skip, find_by, find_value, sort_by, sort_order, customSelectData }) => {
        const res = await getProfilesByCompany({ token: idToken, company_id: currentRol, find_by, find_value, limit, skip, sort_by, sort_order })
        const resStatus = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
        if (!resStatus) throw new Error('Error in getProfilesByCompanyService ')
        if (customSelectData) {
            //Se crea un nuevo res a retorna para ser reconocido por el CustomSelect que utilice este servicio
            const newRes = {
                ...res,
                data: {
                    ...res.data,
                    data: {
                        dataSource: res.data.data?.map(item => ({
                            ...item,
                            id: item?.profileId,
                            name: `${item?.firstName} ${item?.lastName}`
                        }))
                    }
                }
            }
            return newRes
        }
        return res.data.data
    }

    const updateApprovedStatusBookingService = async ({ formData, setLoading }) => {
        setLoading && setLoading(prev => ({
            ...prev,
            confirmModal: true
        }))
        const res = await updateApprovedStatusBooking({ token: idToken, formData })
        const resStatus = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message:'La reserva fue aprobada' })
        if (!resStatus) {
            setLoading && setLoading(prev => ({
                ...prev,
                confirmModal: false
            }))
            throw new Error('Error in updateApprovedStatusBookingService ')
        }
        return { data: res.data.data, name: 'confirmModal' }
    }

    const updateAwaitingPaymentStatusBookingService = async ({ formData, setLoading }) => {
        setLoading && setLoading(prev => ({
            ...prev,
            cotizationModal: true
        }))
        const res = await updateAwaitingPaymentStatusBooking({ token: idToken, formData })
        const resStatus = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message:'Cotización para la reserva creada' })
        if (!resStatus) {
            setLoading && setLoading(prev => ({
                ...prev,
                cotizationModal: false
            }))
            throw new Error('Error in updateAwaitingPaymentStatusBookingService ')
        }
        return { data: res.data.data, name: 'cotizationModal' }
    }
    const updateCancelStatusBookingService = async ({ formData, setLoading }) => {
        setLoading && setLoading(prev => ({
            ...prev,
            rejectedModal: true
        }))
        const res = await updateCancelStatusBooking({ token: idToken, formData })
        const resStatus = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message:'La reserva fue rechazada' })
        if (!resStatus) {
            setLoading && setLoading(prev => ({
                ...prev,
                rejectedModal: false
            }))
            throw new Error('Error in updateCancelStatusBookingService ')
        }
        return { data: res.data.data, name: 'rejectedModal' }
    }


    return {
        getAllAmenitiesService,
        getAllBookingsService,
        getBookingDetailService,
        getBookingStatusesService,
        getProfilesByCompanyService,
        updateApprovedStatusBookingService,
        updateAwaitingPaymentStatusBookingService,
        updateCancelStatusBookingService
    }
}

export default useBookingsServices