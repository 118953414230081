import React, { useRef } from 'react';
import { Table, Modal, Col, Row,Button, Input, Space,  } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { columnsAlertTable} from "../../../../helpers/zonasHelper";
import { getZonas, setActiveZona, getUser,setZonasPermisos,setZonas} from '../../../../store/zonas';
import Highlighter from 'react-highlight-words';
import '../style.css'
import { Spinner } from "react-activity";
import { useForm } from '../../../../hooks';
import { toast } from 'react-toastify';
import { iconEditar, iconEquipoTrabajo } from '../../../../assets/images';

export const ZonasTab = ({residentialData}) => {

  
  const dispatch = useDispatch();
  const { zonas } = useSelector( state => state.zonas );
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [ zonaList, setZonaList ] = useState([]);
  const [ userList, setUserList ] = useState([]);
  const [ zonaActive, setZonaActive ] = useState(null);

  // Filtro de botones
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleZona, setModalVisibleZona] = useState(false);
  const columns = columnsAlertTable();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadSendData, setLoadSendData] = useState(false);

  const { name,code, resetValuesInput,onInputChange, onResetForm } = useForm({
    name: '',
    code: ''
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar por nombre`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columnsTableUser = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    }
  ];

  useEffect(() => {

    dispatch( getZonas() );    
    dispatch( getUser() );    

  }, [ dispatch ]);



  useEffect( ()=>{

    setZonaActive(zonas.active);

  }, [ zonas.active ]);
  
  useEffect(() => {
    
    let dataAlertTable = zonas.data.map( (item, index) => {
      let data = { ...item }
      data.key = index;
      data.alert_type = item.notice_type_data?.name;
      data.userZona = ( <div className='linkEditar' onClick={() => handleStatusModal(true, index)}><img src={iconEquipoTrabajo} alt='team-work-icon' /> {item.users.length} usuarios</div>);
      data.edit = (<div className='linkEditar' onClick={() => handleStatusModalZona(true, index)}><img src={iconEditar} alt='edit-icon' /> Editar</div>);

      return data;
    });

    setZonaList( dataAlertTable );
    setLoadSendData(false)
    handleStatusModal(false)
    setZonaActive(null)
    setSelectedRowKeys([]);
    setUserList( [] );
    setModalVisibleZona(false)

  }, [ zonas.data ]);



  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'age',
    });
  };


  const handleStatusModalZona = (statusModal, index = null) => {
    
    if(index != null){
      var formInfo = {"name":zonas.data[index].name,"code":zonas.data[index].code}
      resetValuesInput(formInfo)

      dispatch( setActiveZona( zonas.data[index] ) );

    }else{
      dispatch( setActiveZona( null ) );
    }

    
    setTimeout(() => {
      setModalVisibleZona(statusModal)
    }, 50);

  }

  const handleStatusModal = (statusModal, index = null) => {
    
    if(index != null){
      dispatch( setActiveZona( zonas.data[index] ) );
      
      //data de listado de usuarios
      let selectData = [];

      let dataAlertTable = zonas.user.map( (item, indexChild) => {
        let data = {}
        data.key = indexChild;
        data.id = item.id;
        data.name = item.name;
        data.idRegistro = "";

        let findUser = zonas.data[index].users.find( request => (request.idProfile === item.id));
        if(typeof findUser !== 'undefined'){
          selectData.push(indexChild)
          data.idRegistro = findUser.id
        }
        return data;
      });

      setSelectedRowKeys(selectData);
      setUserList( dataAlertTable );

    }else{
      dispatch( setActiveZona( null ) );
    }

    
    setTimeout(() => {
      setModalVisible(statusModal);
    }, 50);

  }

  const onHandleZona = () =>{
    if(!name){
      toast.warn("Debe ingresar nombre de zona");
      return;
    }

    setLoadSendData(true)

    let data = {
      name,
      code,
      zonaActive
    };

    dispatch( setZonas(data) );
  }

  
  const onHandlePermisos = () =>{

    setLoadSendData(true)

    //verificar permisos nuevos
    var usuarioPermisoNuevos = []
    var i = 0;
    while (i < selectedRowKeys.length) {
        var usuariosTabla = userList
        if(usuariosTabla[selectedRowKeys[i]].idRegistro === ""){
          usuarioPermisoNuevos.push(usuariosTabla[selectedRowKeys[i]])
        }
        ++i;
    }

    //verificar permisos a eliminar
    var usuariosEliminar = [...zonaActive.users]
    var j = 0;
    while (j < selectedRowKeys.length) {
      var itemUser = userList[selectedRowKeys[j]]
      let findUser = usuariosEliminar.find( request => (request.idProfile === itemUser.id));
      if(typeof findUser !== 'undefined'){
        let indexUser = usuariosEliminar.indexOf(findUser)
        if(indexUser !== -1){
          for( var z = 0; z < usuariosEliminar.length; z++){ 
            if ( z === indexUser) { 
              usuariosEliminar.splice(z, 1); 
            }
          }
        }
      }
      ++j;
    }

    let data = {
      usuariosEliminar,
      usuarioPermisoNuevos,
      zonaActive
    };

    dispatch( setZonasPermisos(data) );
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
        <div className="contBoton">
          <Button className='btn-primary-radio'  onClick={() => {
              onResetForm()
              handleStatusModalZona(true)

              

              }}>
                <img src='/assets/images/shape20.png' />
                NUEVA ZONA
          </Button>
        </div>
        
        <Table columns={columns} dataSource={zonaList} onChange={handleChange} pagination={{ defaultPageSize: 7 }} />
        
    
      
        <Modal
        className='primary-modal'
        style={{ top: 20}}
        title={zonaActive == null ? "" : zonaActive.name}
        visible={modalVisible}
        onCancel={() => {
          handleStatusModal(false)
          setZonaActive(null)
          setSelectedRowKeys([]);
        }}
        footer = { <Row>

          <Col span={6} style={{textAlign:"left"}}>
            
          </Col>

          <Col span={12} style={{textAlign:"right"}}>
            <Button 
                // disabled={requests.isSaving} 
                className='btn-primary' 
                type="primary" 
                shape="round" 
                style={{ width: "100%" }} 
                onClick={() => {
                  onHandlePermisos()
                  //onHandleSubmitValidarPreguntas()
                }}
                >
                  {
                    loadSendData ?
                    <div className='centerSpinner'> <Spinner /></div>
                    
                    :
                    "GUARDAR"
                  }
                
            </Button>
          </Col>
        </Row> }
        >
        {
            zonaActive && <>
            <Row className='section-modal'>
                <Col span={24}>

                    <span
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      {hasSelected ? `Seleccionados ${selectedRowKeys.length}` : ''}
                    </span>
                    <div className="scrollContModal">
                    
                      <Table pagination={false} style={{width:"100%"}} columns={columnsTableUser} dataSource={userList} rowSelection={rowSelection}  />
                    </div>

                </Col>
            </Row>
            </>
        }
        
        </Modal>


        <Modal
        className='primary-modal'
        title={"ZONA"}
        visible={modalVisibleZona}
        onCancel={() => {
          setModalVisibleZona(false)
        }}
        footer = { <Row>

          <Col span={6} style={{textAlign:"left"}}>
            
          </Col>

          <Col span={12} style={{textAlign:"right"}}>
            <Button 
                // disabled={requests.isSaving} 
                className='btn-primary' 
                type="primary" 
                shape="round" 
                style={{ width: "100%" }} 
                onClick={() => {
                  onHandleZona()
                  //onHandlePermisos()
                  //onHandleSubmitValidarPreguntas()
                }}
                >
                  {
                    loadSendData ?
                    <div className='centerSpinner'> <Spinner /></div>
                    
                    :
                    "GUARDAR"
                  }
                
            </Button>
          </Col>
        </Row> }
        >
        {
           
            <Row className='section-modal'>
                <Col span={24}>

                <form action="">
                    <div className="form-group">
                        <label htmlFor="">NOMBRE DE LA ZONA:</label>
                        <input 
                            className='form-control'
                            name="name" 
                            value={ name }
                            onChange={ onInputChange }/>
                    </div>
                    {
                      residentialData.access == "TAS" ?
                      <div className="form-group">
                        <label htmlFor="">CÓDIGO ACCESO:</label>
                        <input 
                            className='form-control'
                            name="code"
                            value={ code }
                            onChange={ onInputChange }/>
                      </div>

                      : null
                    }
                    

                    
                </form>

                </Col>
            </Row>
            
        }
        
        </Modal>
    </>
  )
}
