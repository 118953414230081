import { ReactComponent as ContratorIcon } from '../assets/contratorIcon.svg'
import { ReactComponent as HistoryIcon } from '../assets/historyIcon.svg'
import { Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { CustomSearchInputFilter } from '../../../UI/CustomSearchInputFilter/CustomSearchInputFilter'
import moment from 'moment'

export const monitoringContratorsTableColumnsPrev = ({ handleContratorDetail, handleAccess }) => ([
    {
        title: 'UBICACIÓN',
        dataIndex: 'place',
        key: 'place',
        width: '17%',
        align: 'left'
    },
    {
        title: 'RESIDENTE',
        dataIndex: 'resident',
        className: 'left-column',
        key: 'resident',
        width: '16%',
        align: 'left'
    },
    {
        title: 'NOMBRE DE EVENTO',
        dataIndex: 'eventName',
        className: 'left-column',
        key: 'eventName',
        width: '17%',
        align: 'left'
    },
    {
        title: 'INICIO',
        dataIndex: 'dateBegin',
        key: 'dateBegin',
        width: '12.5%',
        align: 'center',
        render: (text, record) => {
            return record.dateBegin && <span>{moment(record.dateBegin).format('DD/MM/YYYY')}</span>
        }
    },
    {
        title: 'FINALIZACIÓN',
        dataIndex: 'dateEnd',
        key: 'dateEnd',
        width: '12.5%',
        align: 'center',
        render: (text, record) => {
            return record.dateEnd && <span>{moment(record.dateEnd).format('DD/MM/YYYY')}</span>
        }
    },
    {
        title: 'CONTRATISTA',
        dataIndex: 'contrator',
        key: 'contrator',
        width: '12.5%',
        align: 'center',
        render: (text, record) => {
            return <Button style={{ border: 'none', backgroundColor: 'transparent' }} className='btn-standard-green' shape='round' onClick={() => handleContratorDetail({ record })} icon={<ContratorIcon />} />
        }
    },
    {
        title: 'HISTORIAL DE ACCESO',
        dataIndex: 'contrator',
        key: 'contrator',
        width: '12.5%',
        align: 'center',
        render: (text, record) => {
            return <Button style={{ border: 'none', backgroundColor: 'transparent' }} className='btn-standard-green' shape='round' onClick={() => handleAccess({ record })} icon={<HistoryIcon />} />
        }
    },
])

export const monitoringContratorsTableFilters = ({ currentParams, currentFilters, handleSearch, handleReset }) => ({
    place: () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (<CustomSearchInputFilter dataIndex={'place'} placeholder={'Buscar'} setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm} clearFilters={clearFilters} handleReset={handleReset} handleSearch={handleSearch} />),
        filterIcon: () => <SearchOutlined />,
        filteredValue: currentFilters?.place || "",
        sorter: (a, b) => { },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'place' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    resident: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'resident' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    eventName: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'eventName' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    dateBegin: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'dateBegin' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    dateEnd: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'dateEnd' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    default: () => ({}),
})