import { useContext, createContext, useState } from 'react'
import { useAppSelector } from '../../../../hooks/store'
import { defaulStateTable } from '../mocks/defaultStates'
import useCurrentLocation from '../../../hooks/useCurrentLocation'
import useRedirectTo from '../../../hooks/useRedirectTo'

const MonitoringContratorsContext = createContext({
    auth: null, param: null,
    handleOpenCloseDrawer: () => { }, handleRedirectTo: () => { },
    loading: false, setLoading: () => { },
    visibleDrawer: false, setVisibleDrawer: () => { },
    historyAccessTable: defaulStateTable, setHistoryAccessTable: () => { },
    monitoringContratorsTable: defaulStateTable, setMonitoringContratorsTable: () => { },
    workersTable: defaulStateTable, setWorkersTable: () => { },
    providersTable: defaulStateTable, setProvidersTable: () => { },
})

export const MonitoringContratorsContextProvider = ({ children }) => {
    const auth = useAppSelector(state => state.auth)
    const { param } = useCurrentLocation()
    const { handleRedirectTo } = useRedirectTo()
    const [monitoringContratorsTable, setMonitoringContratorsTable] = useState(structuredClone(defaulStateTable))
    const [historyAccessTable, setHistoryAccessTable] = useState(structuredClone(defaulStateTable))
    const [workersTable, setWorkersTable] = useState(structuredClone(defaulStateTable))
    const [providersTable, setProvidersTable] = useState(structuredClone(defaulStateTable))
    const [visibleDrawer, setVisibleDrawer] = useState(false)

    const [loading, setLoading] = useState(false)

    const handleOpenCloseDrawer = () => {
        setVisibleDrawer(prev => !prev)
    }

    const values = {
        auth, param, handleOpenCloseDrawer, handleRedirectTo,
        loading, setLoading,
        monitoringContratorsTable, setMonitoringContratorsTable,
        historyAccessTable, setHistoryAccessTable,
        workersTable, setWorkersTable,
        providersTable, setProvidersTable,
        visibleDrawer, setVisibleDrawer
    }

    return (
        <MonitoringContratorsContext.Provider value={values}>
            {children}
        </MonitoringContratorsContext.Provider>
    )
}

export const useMonitoringContratorsContext = () => useContext(MonitoringContratorsContext)