import { axiosCreate } from "../../../../../config";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const BOOKING_STATUS_PATH = 'reservation/tuscania/status/list/short'

//Endpoint de topia con el que se recuperan los estados más básicos de las reservas de una cuenta, mostrando el nombre del estado y el código de este
export const getBookingStatuses = async ({
    token,
    company_id,
    date_begin,
    date_end
}) => {

    try {
        const params = {
            company_id,
            date_begin,
            date_end
        }

        const res = await axiosCreate.get(`${BOOKING_STATUS_PATH}`, {
            params,
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }

}