import { useEffect } from "react"
import CustomTabs from "../../../../UI/CustomTabs/CustomTabs"
import useBookings from "../../hooks/useBookings"
import HeaderBookings from "./components/Header/HeaderBookings"

//Componente principal donde se renderiza la tabla de reservas
const MainBookings = () => {

    const { getInitialData, handleChangeTabs, handleChangeDatePickerFilter, tabItems } = useBookings()

    //Hook para recuperar los datos iniciales para la tabla (aun no esta funcionando)
    useEffect(() => {
        getInitialData()
    }, []);

    return (
        <section className="section-wrapper">
            <HeaderBookings handleChangeDatePickerFilter={handleChangeDatePickerFilter} /**Se le pasa el handle para el filtro por rango de fechas */ />
            <main>
                <CustomTabs defaultActiveKey="all" className="custom-tabs-variant-1" items={tabItems} onChange={handleChangeTabs} />
            </main>
        </section>
    )
}

export default MainBookings