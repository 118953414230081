export const getColumnsFilter = (dataIndex, columnsFiltersObject = { 'default': () => ({}) }) => {
  let col = columnsFiltersObject[dataIndex] || columnsFiltersObject['default']
  return col()
}

export const getColumnsWith = (dataIndex, columnsWithsObject = { 'default': () => ({}) }) => {
  let colWith = columnsWithsObject[dataIndex] || columnsWithsObject['default']
  return colWith()
}

export const verifyStatusColors = (status) => {
  let objStatusColors = {
    'schedule': () => '#59ADFF',
    'done': () => '#16A07F',
    'inProgress': () => '#E4B228',
    'canceled': () => '#E82A2A',
    'default': () => '#252525',
  }

  let colors = objStatusColors[status] || objStatusColors['default']
  return colors()
}

//Esta función retornara falso si al menos uno de las propiedades en el objeto no coincide con el nuevo, de lo contrario siempre será verdadero
export const checkParams = ({ actualParams, newParams, difKey }) => {
  if (!actualParams || !Object.keys(actualParams).length) return false
  for (const key in actualParams) {
    if (key !== difKey && actualParams[key] !== newParams[key]) return false;
  }
  return true;
};

//Con este helpers se buscar verificar dos objetos, que son filtros de un CustomTable de tal forma que se compruebe si hay cambios entre el actual y el nuevo filtro
//Cabe aclarar que esto solo altera un filtro en la tabla, si hay más de uno se tomará el último filtro modificado
export const compareFiltersTable = ({ object1 = {}, object2, ommitKey = 'state', customFilters }) => {

  //Se verifica si el filtro actual no existe o si en el nuevo filtro sus propiedades son nulas, si es asi es porque no existe ningún filtro
  if (!object1 || Object.values(object2).every(value => value === null)) return undefined

  for (const key in object2) {
    //Se itera sobre los nuevos filtros y se verifica primero si una clave es igual a una clave que se quiera omitir, si la hay solo se itera la siguiente clave
    if (key === ommitKey || object2[key] === null) continue
    const value1 = object1[key]
    const value2 = object2[key]

    //Si en una clave (o filtro) el valor actual existe pero es diferente al valor nuevo se retorna un objeto con el nuevo filtro
    if (value1 && value1 !== value2) return { [key]: value2 }

    //Si no existe un valor actual en el filtro pero si existe uno nuevo, igual se retorna un objeto con el nuevo filtro
    if (!value1 && value2) return { [key]: value2 }
    //Si el valor actual del filtro es el mismo que en el nuevo, se retorna el mismo objeto del filtro
    if (value1 === value2 && !customFilters) return { [key]: value2 }
  }
  return undefined
};

//Este helper lo que hace es simplificar el onChange de un customTable si se quiere usar para la paginación, filtror y ordenacion de columnas
//Se deben pasar los parametros del evento onChangle, además de los serviceParams, que son necesarios para formar los parámetros finales que se pasan al servicio
//Igual pasar el state y el setState para actualizar la paginación de la tabla
//Other params hace referencia a parámetros que se envian al endpoint pero que son externos a la tabla, como tener un search externo, un datepicker o rangerpicker, etc
export const changeTableParams = ({ serviceParams, pagination, filters, sorter, state, setState, otherParams, customFilters }) => {

  //Se comparan los filtros que actuales de la tabla y los que estaban guardados en el estado de la tabla si hay modificaciones
  const filter = compareFiltersTable({ object1: state.currentFilters, object2: filters, customFilters })
  //Si se devuelve un objeto, es porque un filtro ha tenido un cambio respecto al estado anterior, por lo que se añade en el find_by y el find_value
  // Si devuelve undefined es porque no hay un filtro activo
  const find_by = filter && Object.keys(filter)[0]
  const find_value = filter && filter[Object.keys(filter)[0]]

  //Se obtiene de los parámetros de la tabla la informacion del sorter, si no existe uno seleccionado irán undefined estos parámetros
  const sort_order = sorter.order === 'descend' ? 'desc' : sorter.order === 'ascend' ? 'asc' : undefined
  const sort_by = sorter.column?.key

  //Se obtiene la paginación actual de la tabla, para así calcular el nuevo skip que se pasará en la nueva petición al endpoint
  const pageValue = pagination.current || state.currentPage
  const newSkip = (pageValue * state.metadata.limit) - state.metadata.limit

  //Se hace una copia liviana de los parámetros actuales en el estado y se crea otro objeto que incluye los nuevos parámetros
  const actualParams = { ...state.currentParams }
  //En los nuevos parámetros se agregan los parámetros externos a la tabla
  const newParams = {
    ...otherParams,
    find_by,
    find_value,
    sort_order,
    sort_by,
    skip: 0
  }

  const finalParams = { ...serviceParams, ...newParams }

  //Se hace una nueva copia del los filtros actuales o se crea un objeto vacío si no existe
  //Si find_by no fue undefined entonces se guardará un nuevo objeto en actualFilters, reemplazando el filtro anteriormente guardado, si es undefined será siempre un objeto vacío
  // Esto es para que se eliminen también en la tabla además de no pasarse en los endpoints

  let actualFilters = { ...state.currentFilters } ?? {}
  find_by ? actualFilters[find_by] = find_value : actualFilters = {}
  actualFilters = customFilters ? filters : actualFilters

  //Se comparan los parámetros actuales con los nuevos que se van a enviar para comprobar si tienen cambios, a excepción del skip
  //Si es verdadero es porque no hay cambios en los parámetros, y se actualiza la página actual de la tabla
  //Si es falso es porque si los hay y se resetea la página actual de la tabla a la primera 
  if (checkParams({ actualParams, newParams, difKey: 'skip' })) {
    setState(prev => ({ ...prev, currentPage: pageValue }))
    //Si el nuevo skip es mayor al skip actual se actualizará el objeto finalParams con el propósito de obtener nuevos datos
    //Si no es mayor se retorna null indicando que la data ya está en local y no se necesita llamar de nuevo al endpoint
    finalParams.skip = newSkip
  } else setState(prev => ({ ...prev, currentPage: 1 }))

  return { finalParams, newParams, actualFilters }
}