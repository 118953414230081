import React from 'react'
import { Tabs } from 'antd';
import { RequestTab } from './RequestTab';
import { UnApprovedRequestTab } from './UnApprovedRequestTab';
import { DeniedRequestTab } from './DeniedRequestTab';
import { RequestPendingPaymentsTab } from '../../payments/components/RequestPendingPaymentsTab';
import { RequestPaidTab } from '../../payments/components/RequestPaidTab';

export const ContainerTabs = () => {
  
    const { TabPane } = Tabs;
    return (
        <>
            <Tabs className='primary-tabs' defaultActiveKey="1">                
                <TabPane tab="Todo" key="1">
                    <RequestTab/>
                </TabPane>
                
                <TabPane tab="Solicitudes sin aprobar" key="2">
                    <UnApprovedRequestTab/>
                </TabPane>
                
                <TabPane tab="Pendientes de pago" key="3">
                    <RequestPendingPaymentsTab></RequestPendingPaymentsTab>
                </TabPane>
                
                <TabPane tab="Pago Recibido" key="4">
                    <RequestPaidTab></RequestPaidTab>
                </TabPane>

                
                <TabPane tab="Denegadas" key="5">
                    <DeniedRequestTab/>
                </TabPane>
            </Tabs>
        
        </>
    )
}
