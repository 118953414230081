import {
	collection,
	query,
	where,
	getDocs,
	doc,
	getDoc,
	orderBy,
} from "firebase/firestore";
import { FirebaseDB } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import { getColumnSearchProps } from "../utils/tables";

export const getMobileStatus = async () => {
	const collectionRef = collection(FirebaseDB, `/mobile_status`);
	/* let today = new Date(Date.now() - 5 * 60 * 60 * 1000); */
	let today = new Date();
	today.setHours(0, 0, 0, 0);

	const queryMessages = query(
		collectionRef,
		where("updated_at", ">=", today),
		orderBy("updated_at", "desc")
	);

	const querySnapshot = await getDocs(queryMessages);
	const list = await agentCoordsBody(querySnapshot);

	return list;
};

export const getAgentProfileData = async (id) => {
	const collectionRef = doc(FirebaseDB, "/agents/" + id);
	const docSnap = await getDoc(collectionRef);
	const data = docSnap.data();

	return data;
};

const agentCoordsBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (document) => {
		let data = document.data();
		let itemData = {
			battery: data.battery,
			coords: data.coords,
			idProfile: data.idProfile,
			imei: data.imei,
			updated_at: moment(data.updated_at.toDate()).format("Y-MM-DD"),
			updated_time: moment(data.updated_at.toDate()).format("HH:mm"),
		};

		list.push(itemData);
	});

	return list;
};
