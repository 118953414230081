import { Col, Row } from 'antd';
import { ContainerTabs } from './components/ContainerTabs';


export const ZonasReceivedPage = ({residentialData}) => {




    return (
        <>
    
          <div className="wrapper-page">
            <h2 className='title-page'>
              Administración de Zonas
            </h2>
    
              <Row>
    
                <Col span={24}>
                  <ContainerTabs residentialData={residentialData}></ContainerTabs>
                </Col>
    
    
              </Row>
          </div>
        </>
        
    )
}


