import { Row, Col, Radio } from 'antd'
import CustomItemForm from '../../../../UI/CustomItemForm'
import { CustomInput } from '../../../../../../UI/CustomInput'
import { CustomRadioButton } from '../../../../../../UI/CustomRadio/CustomRadioButton'
import InviteesRange from './components/InviteesRange'

//5° Sección del formulario para configurar los costes en la amenidad, que incluye colocar el costo por amenidad, el costo por tiempo, el costo por invitados o el costo por rango de invitados
const CostSettings = ({ Form, form }) => (
  <section className="booking-form-section">
    <h2>CONFIGURACIÓN DE COSTES</h2>
    <Row style={{ margin: '32px 0' }} gutter={16}>
      <Col span={12} style={{ borderRight: '1px solid #969696' }}>
        <CustomItemForm Form={Form} switchLabel={'Definir costo estándar por amenidad'} switchName={'active_amenity_cost'} inputLabelName={'COSTE POR AMENIDAD'} inputName={'amenity_cost'} />
        <CustomItemForm Form={Form} switchLabel={'Definir costo por tiempo reservado'} switchName={'active_reserved_time'} >
          <Form.Item noStyle shouldUpdate>
            {
              ({ getFieldValue }) => { //Valida si el switch está activo o no para validar el item
                const isEnabled = getFieldValue('active_reserved_time')
                return (
                  <Row gutter={40}>
                    <Col span={12}>
                      <Form.Item rules={[{
                        validator: async (_, value) => {
                          if ((value && isEnabled) || !isEnabled) return Promise.resolve()
                          return Promise.reject(new Error(`El campo es necesario`))
                        }
                      }]} name='reserved_time' label={'TIEMPO'}>
                        <CustomInput nullInputNumberText='0' disabled={!isEnabled} type='NUMBER' />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item rules={[{
                        validator: async (_, value) => {
                          if ((value && isEnabled) || !isEnabled) return Promise.resolve()
                          return Promise.reject(new Error(`El campo es necesario`))
                        }
                      }]} name='reserved_cost' label={'COSTE'}>
                        <CustomInput nullInputNumberText='0' disabled={!isEnabled} type='NUMBER' />
                      </Form.Item>
                    </Col>
                  </Row>
                )
              }
            }
          </Form.Item>
        </CustomItemForm>
        <CustomItemForm Form={Form} switchLabel={'Definir costo por invitado'} switchName={'active_invitee_cost'} >
          <Form.Item noStyle shouldUpdate>
            {
              ({ getFieldValue }) => { //Valida si el switch está activo o no para validar el item y además cual radio button está activo, para activar el item de costo por invitado o el Form.List de rangos
                const isEnabled = getFieldValue('active_invitee_cost')
                const cost_options = getFieldValue('invitee_cost_options')

                return (
                  <>
                    <Form.Item rules={[{
                      validator: async (_, value) => {
                        if ((value && isEnabled) || !isEnabled) return Promise.resolve()
                        return Promise.reject(new Error(`El campo es necesario`))
                      }
                    }]} name='invitee_cost_options'>
                      <CustomRadioButton disabled={!isEnabled}>
                        <Radio className='amenity-radio-item' value={'individual_cost_invitee'}>
                          <span>Definir costo individual por invitados</span>
                          <Form.Item rules={[{
                            validator: async (_, value) => {
                              //Valida si hay un valor seleccionado en el check y este sea el de costo por invitado cuando el switch está activo
                              //o si el switch esta activo y la opcion no es la de costo por invitado o si el switch no está activo, si ninguna se cumple dará error
                              if ((value && cost_options === 'individual_cost_invitee' && isEnabled) || (isEnabled && cost_options !== 'individual_cost_invitee') || !isEnabled) return Promise.resolve()
                              return Promise.reject(new Error(`El campo es necesario`))
                            }
                          }]} style={{ marginTop: 24 }} name='invitee_cost' label={'COSTE POR INVITADO'}>
                            <CustomInput nullInputNumberText='0' disabled={cost_options !== 'individual_cost_invitee' || !isEnabled} type='NUMBER' />
                          </Form.Item>
                        </Radio>
                        <Radio value={'range_cost_invitee'}>Definir costo por rango de invitados</Radio>
                      </CustomRadioButton>
                    </Form.Item>
                  </>
                )
              }
            }
          </Form.Item>
        </CustomItemForm>
      </Col>
      <Col span={12} style={{ paddingLeft: 24 }}>
        <InviteesRange Form={Form} form={form} /**Se coloca la lista de rangos  */ />
      </Col>
    </Row>
  </section>
)

export default CostSettings
