import React from "react";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { greenCheck, greyCheck } from "../../../../assets/icons";

export const ReportLiquidacionTable = ({ transactionsData }) => {
	const [transactionsFull, setTransactionsFull] = useState([]);
	const [transactions, setTransactions] = useState([]);
	/* const transactions = transactionsData; */
	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};

	useEffect(() => {
		setTransactions(transactionsData);
	}, [transactionsData]);

	useEffect(() => {
		let _data = transactions.map((item, index) => {
			let data = { ...item };
			data.depositSymbol = data.deposit ? (
				<>
					<img alt="green-check" src={greenCheck} />
				</>
			) : (
				<>
					<img alt="gray-check" src={greyCheck} />
				</>
			);
			return data;
		});
		setTransactionsFull(_data);
	}, [transactions]);

	const countTransactionsByDate = (transData) => {
		let total = 0;
		total = transData.length;
		return total;
	};

	const countBookingsByDate = (transData) => {
		let total = 0;
		if (transData) {
			transData.map(async (item, index) => {
				if (item.payment_type.id === "8IpSBC0R7dC27IKfbtEp") {
					total++;
				}
			});
		}
		return total;
	};

	const countPaymentsByDate = (transData) => {
		let total = 0;
		if (transData) {
			transData.map(async (item, index) => {
				if (
					item.payment_type.id === "OOCwFcoQ0HSNgnZ7BdQH" ||
					item.payment_type.id === "4ll4c5y5pxn9AkukQx7S"
				) {
					total++;
				}
			});
		}
		return total;
	};

	const countChargesByDate = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			if (item.amount) {
				const amm = Number(parseFloat(item.amount).toFixed(2));
				total += +(amm + item.mora);
			}
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countLiquidationsByDate = (transData) => {
		let total = 0;
		let totalDiscount = 0;
		transData.map(async (item, index) => {
			if (item.amount) {
				const amm = Number(parseFloat(item.amount).toFixed(2));
				total += +(amm + item.mora);
				totalDiscount += +(
					item.payment_details.accFee +
					item.payment_details.taxFee +
					item.payment_details.topiaFee
				);
			}
		});
		total = parseFloat(total).toFixed(2) - parseFloat(totalDiscount).toFixed(2);
		return total;
	};

	const transactionColumns = [
		{
			title: "Fecha",
			dataIndex: "date",
			key: "date",
			align: "center",

			render: (text, record) => (
				<>
					<Link to={`/reporte-liquidaciones/${record.linkDate}`}>
						{record.date}
					</Link>
				</>
			),
		},
		{
			title: "Transacciones",
			dataIndex: "data",
			key: "data",
			align: "center",
			render: (text, record) => <>{record.data.length}</>,
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "Reservas",
			dataIndex: "reserve",
			key: "reserve",
			align: "center",
			render: (text, record) => <>{countBookingsByDate(record.data)}</>,
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "Pagos",
			dataIndex: "payments",
			key: "payments",
			align: "center",
			render: (text, record) => <>{countPaymentsByDate(record.data)}</>,
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "Cobros",
			dataIndex: "charges",
			key: "charges",
			align: "center",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(countChargesByDate(record.data)).toFixed(2).toString()
					)}
				</>
			),
		},
		{
			title: "Liquidación",
			dataIndex: "closeouts",
			key: "closeouts",
			align: "center",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(countLiquidationsByDate(record.data))
							.toFixed(2)
							.toString()
					)}
				</>
			),
		},
		{
			title: "Deposito",
			dataIndex: "depositSymbol",
			key: "depositSymbol",
			align: "center",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
	];
	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}
	return (
		<>
			<br />
			<Table
				className="primary-table"
				columns={transactionColumns}
				dataSource={transactionsFull}
				pagination={{ defaultPageSize: 20, simple: true }}
				loading="true"
				rowKey={"id"}
				locale={locale}
			/>
		</>
	);
};
