import React from "react";
import { Col, Row, Table, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import { getColumnSearchProps } from "../../../../utils/tables";

export const UserProfilePayments = ({ paymentData }) => {
	const [payments, setPayments] = useState([]);
	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};
	useEffect(() => {
		setPayments(paymentData);
	}, [paymentData]);

	const columns = [
		{
			title: "HORA",
			dataIndex: "created_time",
			key: "created_time",
			align: "center",
			/* sorter: (a, b) => a.constructionFee - b.constructionFee,
                render: (text, record) => (
                    <>{moment(record.date_action).format("YY/MM/D")}</>
                ), */
		},
		/* {
			title: "RESIDENTE",
			dataIndex: "profileData.name",
			key: "profileData.name",
			align: "left",
			render: (text, record) => (
				<>
					{record.profileData.name} {record.profileData.lastname}
				</>
			),
			...getColumnSearchProps("profileData.name"),
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		}, */
		{
			title: "TIPO DE OPERACIÓN",
			dataIndex: "type",
			key: "type",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			/* sorter: (a, b) => a.type - b.type, */
		},
		{
			title: "Cobro",
			dataIndex: "amount",
			key: "amount",
			sorter: (a, b) => a.amount - b.amount,
			render: (text, record) => (
				<>{currencyFormat(parseFloat(record.amount).toFixed(2).toString())}</>
			),
		},
		{
			title: "IMPUESTOS",
			dataIndex: "payment_details.taxFee",
			key: "payment_details.taxFee",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.payment_details.taxFee).toFixed(2).toString()
					)}
				</>
			),
			sorter: (a, b) => a.payment_details.taxFee - b.payment_details.taxFee,
		},
		{
			title: "COMISIÓN",
			dataIndex: "payment_details.accFee",
			key: "payment_details.accFee",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.payment_details.taxFee).toFixed(2).toString()
					)}
				</>
			),
			sorter: (a, b) => a.payment_details.taxFee - b.payment_details.taxFee,
		},
		{
			title: "FEE TOPIA",
			dataIndex: "payment_details.topiaFee",
			key: "payment_details.topiaFee",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.payment_details.topiaFee).toFixed(2).toString()
					)}
				</>
			),
			sorter: (a, b) => a.payment_details.topiaFee - b.payment_details.topiaFee,
		},
		{
			title: "LIQUIDACIÓN",
			dataIndex: "closeout",
			key: "closeout",
			render: (text, record) => (
				<>{currencyFormat(countSingleLiquidation(record).toString())}</>
			),
			//sorter: (a, b) => a.closeout - b.closeout,
		},
		/* {
			title: "",
			dataIndex: "action_user",
			key: "action_user",
			render: (text, record) => (
				<>
					<img
						src="/assets/icons/money_phone.svg"
						alt="money_phone"
						style={{ cursor: "pointer" }}
						onClick={() => {
							handleReportModal(true, record);
						}}
					/>
				</>
			),
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		}, */
	];
	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}
	const countSingleLiquidation = (record) => {
		let total = 0;
		let totalDiscount = 0;
		total = record.amount;
		totalDiscount += +(
			record.payment_details.accFee +
			record.payment_details.taxFee +
			record.payment_details.topiaFee
		);
		total = parseFloat(total).toFixed(2) - parseFloat(totalDiscount).toFixed(2);
		return total.toFixed(2);
	};
	return (
		<>
			<Table
				className="primary-table"
				pagination={{ defaultPageSize: 7, simple: true }}
				columns={columns}
				dataSource={payments}
				loading="true"
				rowKey="id"
				locale={locale}
			/>
		</>
	);
};
