import {
	Col,
	Row,
	Dropdown,
	Menu,
	Space,
	Select,
} from "antd";

import { DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { startLogout } from "../../store/auth";
import { useState, useEffect } from "react";
import {
	getQueryDocByCollection,
} from "../../helpers/globalHelper";
import AdministratorProfileModal from "../../components/AdministratorProfileModal";
import AgentTrackMapModal from "../../components/AgentTrackMapModal";
import { getMobileStatus } from "../../helpers/agentTrackerHelper";
import { getAccessActiveVisitors } from "../../helpers/accessHelper";
import { trackingIcon, userProfile } from "../../assets/images";

export const NavBar = () => {
	const [searchBarUsers, setSearchBarUsers] = useState([]);
	const [userSearchList, setUserSearchList] = useState([]);
	const [adminModalActive, setAdminModalActive] = useState(false);
	const [agentsModalActive, setAgentsModalActive] = useState(false);
	const [activeVisitorList, setActiveVisitorList] = useState([]);
	const today = new Date();
	const [agentData, setAgentData] = useState([]);
	const [adminData, setAdminData] = useState({
		name: "diego",
		lastname: "sandoval",
		phone: "79140324",
		email: "diego.sandoval@dotlinksystems.com",
	});

	useEffect(() => {
		/* const getAdminData = async (id) => {
			const adminD = await getUserDataProfileById(id).then((user) => setAdminData(user))
		} */

		getAgentData();
		getActiveVisitorList();
		//getAdminData(id);
	}, []);
	const getActiveVisitorList = async () => {
		const getVisitors = await getAccessActiveVisitors(
			"Yd5MV7jkA0MjQeCmzKrZ",
			today
		).then((result) => setActiveVisitorList(result));
	};

	const getAgentData = async () => {
		const agentD = await getMobileStatus().then((result) =>
			setAgentData(result)
		);
	};
	setInterval(getActiveVisitorList, 1 * 60 * 60 * 1000);
	setInterval(getAgentData, 5 * 60 * 60 * 1000);

	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(startLogout());
	};

	const handleAdminModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setAdminModalActive(statusModal);
		}, 50);
	};

	const handleAgentsModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setAgentsModalActive(statusModal);
		}, 50);
	};

	const { Option } = Select;

	useEffect(() => {
		let reportUserProfiles = getQueryDocByCollection(
			"profile",
			"residentialsIds",
			"array-contains",
			"Yd5MV7jkA0MjQeCmzKrZ" // Tuscania ID
		).then((searchBarUsers) => setSearchBarUsers(searchBarUsers));
	}, []);

	searchBarUsers.map(async (item, index) => {
		/* userSearchList.push({ label: item.name, value: item.id }); */
		let fullname = item.name + " " + item.lastname;

		userSearchList.push(
			<Option key={index} value={item.id} label={fullname}>
				{/* <a href={"/reporte-actividad/" + item.id}>{fullname}</a> */}
				{fullname}
			</Option>
		);
		return item;
	});

	const menu = (
		<Menu
			items={[
				// {
				// 	key: "1",
				// 	danger: false,
				// 	label: (
				// 		<a
				// 			target="_blank"
				// 			rel="noopener noreferrer"
				// 			onClick={() => {
				// 				handleAdminModal(true);
				// 			}}
				// 		>
				// 			Perfil
				// 		</a>
				// 	),
				// },
				{
					key: "2",
					danger: true,
					label: (
						<a target="_blank" rel="noopener noreferrer" onClick={handleLogout}>
							Salir
						</a>
					),
				},

				// {
				//   key: '2',
				//   label: (
				//     <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
				//       2nd menu item (disabled)
				//     </a>
				//   ),
				//   icon: <SmileOutlined />,
				//   disabled: true,
				// },
				// {
				//   key: '3',
				//   label: (
				//     <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
				//       3rd menu item (disabled)
				//     </a>
				//   ),
				//   disabled: true,
				// },
				// {
				//   key: '4',
				//   danger: true,
				//   label: 'a danger item',
				// },
			]}
		/>
	);

	const goToUser = (id) => {
		/* navigate(`/reporte-actividad/${id}`, { replace: false, key: "test" }); */
		window.location = `/reporte-actividad/${id}`;
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<div className="navbar">
						<Row>
							<Col span={11} sm={5} className="search-navbar">
								<div className="container-search">
									{/* <AutoComplete
											style={{
												width: "100%",
											}}
											listHeight={10}
											placeholder="Buscar por nombre"
											options={userSearchList}
											prefix={<SearchOutlined />}
										/> */}

									<Select
										placeholder="Buscar por nombre"
										style={{
											width: "100%",
										}}
										allowClear
										showSearch
										filterOption={(input, option) => {
											return (
												option.label
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											);
										}}
										onChange={(id) => {
											goToUser(id);
										}}
									>
										{userSearchList}
									</Select>
								</div>
							</Col>
							<Col span={4} sm={6} className="option-navbar visited">
								<span className="item">
									Visitantes
									{activeVisitorList ? (
										<span className="counter" style={{ display: "" }}>
											{activeVisitorList.length}
										</span>
									) : (
										<span className="counter" style={{ display: "" }}>
											0
										</span>
									)}
								</span>
							</Col>
							<Col span={4} sm={7} className="option-navbar track">
								<span
									className="item"
									style={{ cursor: "pointer" }}
									onClick={() => {
										handleAgentsModal(true);
									}}
								>
									<img
										className="tracking"
										src={trackingIcon}
										alt="pin-location-icon"
									/>
									Track seguridad
								</span>
							</Col>
							<Col span={5} sm={6} className="option-navbar">
								<span className="item">
									<Dropdown overlay={menu}>
										<a onClick={(e) => e.preventDefault()}>
											<Space>
												Administrador
												<img
													className="img-profile"
													src={userProfile}
													alt="avatar-user"
												/>
												<DownOutlined />
											</Space>
										</a>
									</Dropdown>
								</span>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
			<AgentTrackMapModal
				agents={agentData}
				agentModalActive={agentsModalActive}
				handleModalAgentActive={handleAgentsModal}
			/>
			<AdministratorProfileModal
				userActive={adminData}
				userModalActive={adminModalActive}
				handleStatusAdminModal={handleAdminModal}
			/>
		</>
	);
};
