import { useSelector } from "react-redux";
import {
	getReferenceData,
	getResidentialsById,
	getUserDataProfileById,
} from "../../helpers/globalHelper";
import {
	getPaymentsPendingByTypeAndStatusHelper,
	getPaymentsHelper,
} from "../../helpers/paymentsHelper";
import {
	setAmenityPaymentsPaidData,
	setAmenityPaymentsPendingData,
	setPaymentsData,
} from "./paymentsSlice";

export const getPayments = (fechaInicio, fechaFinal) => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		const dataRaw = await getPaymentsHelper(
			committee.residential_id,
			fechaInicio,
			fechaFinal
		);
		let paymentList = dataRaw.map(async (item, index) => {
			let type =
				item.payment_type && (await getReferenceData(item.payment_type.path));
			let residential = await getResidentialsById(item.residential_id);
			let profile = await getUserDataProfileById(item.profile_id);
			let dataItem = { ...item };
			dataItem.payment_type = null;
			return {
				...dataItem,
				key: index,
				payment_type_data: type,
				profile: profile,
				residential_data: residential,
			};
		});

		Promise.all(paymentList).then((data) => {
			dispatch(setPaymentsData(data));
		});
	};
};

export const getPaymentsNormal = () => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		const dataRaw = await getPaymentsHelper(committee.residential_id);
		let paymentList = dataRaw.map(async (item, index) => {
			let type =
				item.payment_type && (await getReferenceData(item.payment_type.path));
			let residential = await getResidentialsById(item.residential_id);
			let profile = await getUserDataProfileById(item.profile_id);
			let dataItem = { ...item };
			dataItem.payment_type = null;
			return {
				...dataItem,
				key: index,
				payment_type_data: type,
				profile: profile,
				residential_data: residential,
			};
		});

		Promise.all(paymentList).then((data) => {
			dispatch(setPaymentsData(data));
		});
	};
};

export const getPaymentsByTypePending = (type) => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		const dataRaw = await getPaymentsPendingByTypeAndStatusHelper(
			committee.residential_id,
			type,
			"Pendiente"
		);
		let paymentList = dataRaw.map(async (item, index) => {
			let type =
				item.payment_type && (await getReferenceData(item.payment_type.path));
			let residential = await getResidentialsById(item.residential_id);
			let profile = await getUserDataProfileById(item.profile_id);
			let dataItem = { ...item };
			dataItem.payment_type = null;
			return {
				...dataItem,
				key: index,
				payment_type_data: type,
				profile: profile,
				residential_data: residential,
			};
		});

		Promise.all(paymentList).then((data) => {
			dispatch(setAmenityPaymentsPendingData(data));
		});
	};
};

export const getPaymentsByTypePaid = (type) => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		const dataRaw = await getPaymentsPendingByTypeAndStatusHelper(
			committee.residential_id,
			type,
			"Pagado"
		);
		let paymentList = dataRaw.map(async (item, index) => {
			let type =
				item.payment_type && (await getReferenceData(item.payment_type.path));
			let residential = await getResidentialsById(item.residential_id);
			let profile = await getUserDataProfileById(item.profile_id);
			let dataItem = { ...item };
			dataItem.payment_type = null;
			return {
				...dataItem,
				key: index,
				payment_type_data: type,
				profile: profile,
				residential_data: residential,
			};
		});

		Promise.all(paymentList).then((data) => {
			dispatch(setAmenityPaymentsPaidData(data));
		});
	};
};
