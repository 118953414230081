import { SearchOutlined } from "@ant-design/icons";
import { CustomSelect } from "../../../UI/CustomSelect";

//Componente que se utiliza para renderizar un input select para poder filtrar datos que provienen de un filtro de la tabla de reservas
export const AutoCompleteFilter = ({
  disabled,
  disabledPlaceholder,
  placeholder,
  options, //Opciones por la cual se quiere filtrar
  selectedKeys,
  setSelectedKeys,
  clearFilters,
  handleSearch,
  handleReset,
  confirm,
  isAsync,
  service
}) => {

  const propsFilter = { //Propiedades que vienen exclusivamente de los filtros de la tabla
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    handleSearch,
    handleReset,
    confirm,
  };

  return (
    <div
      className="custom-table__filter-container" //Estilos personalizados para el contenedor del select
      onKeyDown={(e) => e.stopPropagation()}
    >
      <CustomSelect
        allowClear={true}
        disabled={disabled}
        placeholder={disabled ? 'Seleccionar' : placeholder}
        width={250}
        options={options}
        propsFilter={propsFilter}
        isAsync
        service={service}
        suffixIcon={<SearchOutlined />}
        svgIcon
      />
    </div>
  );
};