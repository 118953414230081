import * as XLSX from "xlsx";

const ExportHousesExcel = () => {
	const ExportHousesToExcel = (houseList) => {
		let data = [];
		houseList.map((item, index) => {
			data.push({
				name: item.userProfile.name + item.userProfile.lastname,
				number: item.name,
				areaM2: item.areaM2,
				areaV2: item.areaV2,
				houseType: item.houseType,
				category: item.category,
				maintenanceFee: parseFloat(item.maintenanceFee).toFixed(2),
				constructionFee: parseFloat(item.constructionFee).toFixed(2),
				residenceFee: parseFloat(item.residenceFee).toFixed(2),
				alcaldia: parseFloat(item.alcaldia).toFixed(2),
				total: parseFloat(item.total).toFixed(2),
			});
		});
		let Heading = [
			[
				"Nombre titular",
				"#",
				"Area M2",
				"Area V2",
				"Propiedad",
				"Categoria",
				"Mantenimiento",
				"Construcción",
				"Residencia",
				"Desechos Sólidos",
				"Total",
			],
		];
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_aoa(ws, Heading);
		XLSX.utils.sheet_add_json(ws, data, {
			origin: "A2",
			skipHeader: true,
		});
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "Datos Casa.xlsx");
	};
	return { ExportHousesToExcel };
};

export default ExportHousesExcel;
