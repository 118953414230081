import { Content, Footer } from "antd/lib/layout/layout";
import {
	Col,
	Modal,
	Row,
	Table,
	Button,
	Switch,
	Form,
	Input,
	Radio,
	Space,
	Select,
	Layout,
} from "antd";
import { Checkbox } from "antd";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { ShiftsTable } from "./components/ShiftsTable";
import { TimePicker } from "antd";
import {
	getAgentByIdHelper,
	getAgentsHelper,
	getAgentsSchedules,
} from "../../../helpers/agentsHelper";
import moment from "moment";
import {
	onCreateOrUpdate,
	onUpdateDocWithRefInfo,
} from "../../../helpers/globalHelper";
import "./components/styles.css";
import { plusIcon } from "../../../assets/icons";
import { aprobar, horario } from "../../../assets/images";

const SurveillanceShiftsPage = () => {
	const [agents, setAgents] = useState([]);
	const [selectedAgent, setSelectedAgent] = useState([]);
	const [securityTurns, setSecurityTurns] = useState([]);
	const [allAgentOptions, setAllAgentOptions] = useState([]);
	const [newTurnModalActive, setNewTurnModalActive] = useState(false);
	const [newTurnLoadButton, setNewTurnLoadButton] = useState(false);
	const [editTurnLoadButton, setEditTurnLoadButton] = useState(false);
	const [turnsTable, setTurnsTable] = useState([]);
	const [showTable, setShowTable] = useState([]);
	const [editModalActive, setEditModalActive] = useState(false);
	const [selectedTurn, setSelectedTurn] = useState([]);
	const { Option } = Select;
	const editFormRef = useRef();
	const newFormRef = useRef();
	const format = "HH:mm";
	useEffect(() => {
		getAgentData().catch(console.error);
		getSecurityTurns().catch(console.error);
	}, []);

	useEffect(() => {
		let agentOptions = [];
		agents.map((item, index) => {
			let fullName = item.name + " " + item.lastname;

			agentOptions.push(
				<Option key={index} value={index} label={fullName}>
					{fullName}
				</Option>
			);
			return item;
		});
		setAllAgentOptions(agentOptions);
	}, [agents]);

	useEffect(() => {
		getProfiles(securityTurns);
	}, [securityTurns]);

	const handleEditModal = (statusModal, index = null) => {
		if (index != null) {
			setSelectedTurn(index);
		}
		setTimeout(() => {
			setEditModalActive(statusModal);
		}, 50);
	};
	const getProfiles = async (turns) => {
		const mapData = turns.map(async (item, index) => {
			let data = { ...item };
			data.profileData = [];
			const secondMap = item.list_security.map(async (item2) => {
				const getdata = await getAgentByIdHelper(item2).then((result) =>
					data.profileData.push(result)
				);
			});
			return data;
		});
		Promise.all(mapData).then((data) => {
			setTurnsTable(data);
			setShowTable(data);
		});
	};

	const getAgentData = async () => {
		const securityAgents = await getAgentsHelper("Yd5MV7jkA0MjQeCmzKrZ").then(
			(result) => setAgents(result)
		);
	};

	const getSecurityTurns = async () => {
		const getTurns = await getAgentsSchedules("Yd5MV7jkA0MjQeCmzKrZ").then(
			(result) => {
				setSecurityTurns(result);
			}
		);
	};

	const handleNewTurnModal = (statusModal, index = null) => {
		setTimeout(() => {
			setNewTurnModalActive(statusModal);
		}, 50);
	};

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};
	const options = [
		{ label: "Lunes", value: "Lu" },
		{ label: "Martes", value: "Ma" },
		{ label: "Miércoles", value: "Mi" },
		{ label: "Jueves", value: "Jue" },
		{ label: "Viernes", value: "Vi" },
		{ label: "Sábado", value: "Sa" },
		{ label: "Domingo", value: "Do" },
	];

	const handleAgentSelect = (e) => {
		let selected = [];
		let array = [...e];
		/* if (e.length == 1) {
			selected.push(agents[e]);
		} */
		let count = e.length;

		for (let i = 0; i < count; i++) {
			selected.push(agents[array[i]].id);
		}

		setSelectedAgent(selected);
	};
	const handleNewTurnForm = async (e) => {
		setNewTurnLoadButton(true);
		const newTurn = {
			active: true,
			created_at: moment(new Date(), "DD/MM/YYYY").toDate(),
			days: formatDays(e.days),
			list_security: selectedAgent,
			name: e.name,
			residential_id: "Yd5MV7jkA0MjQeCmzKrZ",
			time_begin: moment(e.start).format("HH:mm"),
			time_end: moment(e.end).format("HH:mm"),
			updated_at: moment(new Date(), "DD/MM/YYYY").toDate(),
		};

		const collectionRef = "security_turn";
		const createTurn = await onCreateOrUpdate(collectionRef, newTurn);
		setNewTurnLoadButton(false);
		handleNewTurnModal(false);
		getSecurityTurns().catch(console.error);
		setSelectedAgent([]);
		newFormRef.current.resetFields();
	};
	const handleEditTurnForm = async (e) => {
		setEditTurnLoadButton(true);
		const newTurn = {
			active: true,
			created_at: moment(selectedTurn.created_at).toDate(),
			days: formatDays(e.days),
			list_security: selectedAgent,
			name: e.name,
			residential_id: "Yd5MV7jkA0MjQeCmzKrZ",
			time_begin: moment(e.start).format("HH:mm"),
			time_end: moment(e.end).format("HH:mm"),
			updated_at: moment(new Date(), "DD/MM/YYYY").toDate(),
		};
		const id = selectedTurn.id;

		const collectionRef = `/security_turn/${id}`;
		const editTurn = await onUpdateDocWithRefInfo(collectionRef, newTurn);
		setEditTurnLoadButton(false);
		handleEditModal(false);
		getSecurityTurns().catch(console.error);
		setSelectedAgent([]);
		editFormRef.current.resetFields();
	};

	const showTurns = (index = null) => {
		if (index != null) {
			setShowTable([turnsTable[index]]);
		} else {
			getSecurityTurns().catch(console.error);
		}
	};

	const formatDays = (dayArray) => {
		const string = dayArray.join(",");
		return string;
	};

	const initialFormValues = {
		name: selectedTurn.name,
		days: selectedTurn.days,
	};
	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={16}>
								<h2 className="title-page">turnos de vigilancia</h2>
								<Button
									style={{
										borderColor: "#FFFFFF",
										background: "#FFFFFF",
										width: "20%",
									}}
									onClick={() => {
										handleNewTurnModal(true);
									}}
									shape="round"
								>
									<img
										src={plusIcon}
										alt="add-plus-icon"
										style={{
											width: "26px",
											height: "26px",
											marginRight: "20px",
										}}
									/>
									<span
										className="title"
										style={{
											color: "#10b48c",
											fontSize: "13px",
											fontWeight: "500",
										}}
									>
										NUEVO TURNO
									</span>
								</Button>
							</Col>
							<Col span={8}>
								<div className="button-container">
									<Space direction="horizontal" align="start">
										<Button
											style={{
												background: "#10B48C",
												borderRadius: "10px",
												width: "166px",
												height: "60px",
											}}
											onClick={() => {
												showTurns();
											}}
											shape="square"
										>
											<Row>
												<Col span={24}>
													<span className="button-name-principal">
														VISTA COMPLETA
													</span>
												</Col>
											</Row>
										</Button>
										{turnsTable.map((item, index) => {
											return (
												<Row key={index}>
													<Col span={24}>
														<Button
															style={{
																borderColor: "#10B48C",
																borderRadius: "10px",
																background: "#FFFFFF",
																width: "200px",
																height: "60px",
															}}
															onClick={() => {
																showTurns(index);
															}}
															shape="square"
														>
															<img
																src={horario}
																alt="schedule-icon"
																style={{
																	width: "24px",
																	height: "24px",
																}}
															/>
															<div>
																<span className="button-name">{item.name}</span>
															</div>
														</Button>
													</Col>
													<Col span={24} align="right">
														<Button
															type="text"
															onClick={() => {
																handleEditModal(true, item);
															}}
														>
															<span>Editar </span>
															<img
																src={aprobar}
																alt="edit-approved-icon"
																style={{
																	width: "16px",
																	height: "16px",
																	marginLeft: "5px",
																}}
															/>
														</Button>
													</Col>
												</Row>
											);
										})}
									</Space>
								</div>
							</Col>
							<Col span={4}></Col>
							<Col span={20}></Col>
						</Row>
						<Row>
							<Col span={24} style={{ marginTop: "3rem" }}>
								<ShiftsTable turns={showTable} />
							</Col>
						</Row>

						{/* crear turno */}
						<Modal
							className="primary-modal"
							visible={newTurnModalActive}
							onCancel={() => handleNewTurnModal(false)}
							footer={null}
							width={650}
						>
							<>
								<Row className="section-modal" style={{ textAlign: "center" }}>
									<Col span={24}>
										<h2 style={{ color: "#232C4A" }}>NUEVO TURNO</h2>
									</Col>
								</Row>
								<Row className="section-modal" style={{ marginTop: "1rem" }}>
									<Col
										span={24}
										style={{
											width: "90%",
											paddingLeft: "2rem",
											paddingRight: "2rem",
										}}
									>
										<Form
											ref={newFormRef}
											name="new_turn_form"
											onFinish={handleNewTurnForm}
										>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Nombre del turno:</p>
											</div>
											<Form.Item name="name" {...config}>
												<Input maxLength={30} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Seleccionar días:</p>
											</div>
											<Form.Item name="days" {...config}>
												<Checkbox.Group
													options={options}
													style={{ display: "flex", flexDirection: "column" }}
												/>
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Seleccionar cuando empieza el turno:</p>
											</div>
											<Form.Item name="start" {...config}>
												<TimePicker format={format} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Seleccionar cuando termina el turno:</p>
											</div>
											<Form.Item name="end" {...config}>
												<TimePicker format={format} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Asignar agentes de seguridad:</p>
											</div>

											<Form.Item name="agents" {...config}>
												{allAgentOptions ? (
													<Select
														mode="multiple"
														placeholder="Buscar por nombre"
														style={{
															width: "100%",
														}}
														allowClear
														showSearch
														filterOption={(input, option) => {
															return (
																option.label
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															);
														}}
														onChange={handleAgentSelect}
													>
														{allAgentOptions}
													</Select>
												) : null}
											</Form.Item>
											<Row>
												<Col span={24} align="middle">
													<Button
														htmlType="submit"
														style={{
															borderColor: "#10b48c",
															background: "#10b48c",
															paddingBottom: "15px",
															width: "250px",
														}}
														shape="round"
														size="large"
														loading={newTurnLoadButton}
													>
														<span className="title" style={{ color: "#fff" }}>
															CREAR TURNO
														</span>
													</Button>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							</>
						</Modal>

						{/* editar turno */}
						<Modal
							className="primary-modal"
							visible={editModalActive}
							onCancel={() => handleEditModal(false)}
							footer={null}
							width={650}
						>
							<>
								<Row className="section-modal" style={{ textAlign: "center" }}>
									<Col span={24}>
										<h2 style={{ color: "#232C4A" }}>EDITAR TURNO</h2>
									</Col>
								</Row>
								<Row className="section-modal" style={{ marginTop: "1rem" }}>
									<Col
										span={24}
										style={{
											width: "90%",
											paddingLeft: "2rem",
											paddingRight: "2rem",
										}}
									>
										<Form
											ref={editFormRef}
											name="edit_turn_form"
											onFinish={handleEditTurnForm}
											initialValues={initialFormValues}
										>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Nombre del turno:</p>
											</div>
											<Form.Item name="name" {...config}>
												<Input maxLength={30} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Seleccionar días:</p>
											</div>
											<Form.Item name="days" {...config}>
												<Checkbox.Group
													options={options}
													style={{ display: "flex", flexDirection: "column" }}
												/>
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Seleccionar cuando empieza el turno:</p>
											</div>
											<Form.Item name="start" {...config}>
												<TimePicker format={format} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Seleccionar cuando termina el turno:</p>
											</div>
											<Form.Item name="end" {...config}>
												<TimePicker format={format} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Asignar agentes de seguridad:</p>
											</div>

											<Form.Item name="agents" {...config}>
												{allAgentOptions ? (
													<Select
														mode="multiple"
														placeholder="Buscar por nombre"
														style={{
															width: "100%",
														}}
														allowClear
														showSearch
														filterOption={(input, option) => {
															return (
																option.label
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															);
														}}
														onChange={handleAgentSelect}
													>
														{allAgentOptions}
													</Select>
												) : null}
											</Form.Item>
											<Row>
												<Col span={24} align="middle">
													<Button
														htmlType="submit"
														style={{
															borderColor: "#10b48c",
															background: "#10b48c",
															paddingBottom: "15px",
															width: "250px",
														}}
														shape="round"
														size="large"
														loading={editTurnLoadButton}
													>
														<span className="title" style={{ color: "#fff" }}>
															MODIFICAR TURNO
														</span>
													</Button>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							</>
						</Modal>
					</div>
				</Content>
			</Layout>
		</>
	);
};

export default SurveillanceShiftsPage;
