import React, { useEffect, useState, useRef } from "react";
import { Content, Footer } from "antd/lib/layout/layout";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import moment from "moment";
import FormItem from "antd/lib/form/FormItem";
import { AgentContainer } from "./components/AgentContainer";
import { getAgentsHelper, getRoleById } from "../../../helpers/agentsHelper";
import { getNomenclaturasHelper } from "../../../helpers/nomenclaturasHelper";
import { onCreateOrUpdate } from "../../../helpers/globalHelper";
import { plusIcon } from "../../../assets/icons";

const SecuritytAgentsPage = () => {
	const [agents, setAgents] = useState([]);
	const [agentsFull, setAgentsFull] = useState([]);
	const [newAgentModalActive, setNewAgentModalActive] = useState(false);
	const [nomenclatures, setNomenclatures] = useState([]);
	const [nomenclaturesOptions, setNomenclaturesOptions] = useState([]);
	const [selectedNomenclatures, setSelectedNomenclatures] = useState([]);
	const [isActive, setIsActive] = useState(false);
	const [newAgentLoadButton, setNewAgentLoadButton] = useState(false);
	const [shouldReload, setShouldReload] = useState(false);
	const { TabPane } = Tabs;
	const { Option } = Select;
	const { TextArea } = Input;
	const newFormRef = useRef();

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	useEffect(() => {
		getAgentData().catch(console.error);
	}, []);
	useEffect(() => {
		if (shouldReload == true) {
			getAgentData().catch(console.error);
			setShouldReload(false);
		}
	}, [shouldReload]);

	useEffect(() => {
		const getRole = async () => {
			const _data = agents.map(async (item, index) => {
				let data = { ...item };
				const role = await getRoleById(data.role_id).then((result) => {
					data.roleData = result;
				});
				return data;
			});

			Promise.all(_data).then((data) => {
				setAgentsFull(data);
			});
		};

		getRole().catch(console.error);
	}, [agents]);

	useEffect(() => {
		getNomenclatures();
	}, []);

	const getAgentData = async () => {
		const securityAgents = await getAgentsHelper("Yd5MV7jkA0MjQeCmzKrZ").then(
			(result) => setAgents(result)
		);
	};

	const getNomenclatures = async () => {
		const nomenclatures = await getNomenclaturasHelper(
			"Yd5MV7jkA0MjQeCmzKrZ"
		).then((result) => setNomenclatures(result));
	};

	useEffect(() => {
		let nomenclatureOptions = [];
		nomenclatures.map((item, index) => {
			let id = item.id;
			nomenclatureOptions.push(
				<Option key={index} value={index} label={item.name}>
					{item.name}
				</Option>
			);
			return item;
		});
		setNomenclaturesOptions(nomenclatureOptions);
	}, [nomenclatures]);

	const handleNewAgentModal = (statusModal, index = null) => {
		if (index != null) {
		}

		setTimeout(() => {
			setNewAgentModalActive(statusModal);
		}, 50);
	};

	const handleNomenclatureSelect = (e) => {
		let selected = [];
		let array = [...e];
		let count = e.length;

		for (let i = 0; i < count; i++) {
			selected.push(nomenclatures[array[i]].id);
		}

		setSelectedNomenclatures(selected);
	};

	const onFinishNewAgentForm = async (e) => {
		setNewAgentLoadButton(true);
		const newAgent = {
			active: true,
			active_service: isActive,
			created_at: moment(new Date(), "DD/MM/YYYY").toDate(),
			code: e.code,
			name: e.name,
			lastname: e.lastname,
			company: e.company,
			contract_type: e.contract_type,
			idResidential: "Yd5MV7jkA0MjQeCmzKrZ",
			document: e.document,
			phone: e.phone,
			nomenclatures: selectedNomenclatures,
			user: e.user,
			role_id: "MbMiIzhX0hlNpmCZ4w4V", //agente seguridad
		};
		const collectionRef = "agents";
		const createAgent = await onCreateOrUpdate(collectionRef, newAgent);
		setNewAgentLoadButton(false);
		handleNewAgentModal(false);
		setSelectedNomenclatures([]);
		newFormRef.current.resetFields();
		getAgentData().catch(console.error);
	};

	const handleChangeActive = (value) => {
		setIsActive(value);
	};

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={20}>
								<h2 className="title-page">Agentes de seguridad</h2>
							</Col>
							<Col span={4} align="right">
								<div style={{ marginTop: "10px" }}>
									<Button
										style={{
											borderColor: "#FFFFFF",
											background: "#FFFFFF",
											width: "80%",
										}}
										onClick={() => {
											handleNewAgentModal(true);
										}}
										shape="round"
									>
										<img
											src={plusIcon}
											alt="add-plus-icon"
											style={{
												width: "26px",
												height: "26px",
												marginRight: "20px",
											}}
										/>
										<span
											className="title"
											style={{
												color: "#10b48c",
												fontSize: "13px",
												fontWeight: "500",
											}}
										>
											NUEVO AGENTE
										</span>
									</Button>
								</div>
							</Col>
						</Row>

						<Tabs className="primary-tabs" defaultActiveKey="1">
							<TabPane tab="AGENTES" key="1">
								<Row>
									{agentsFull ? (
										<>
											<Col span={24}>
												{agentsFull.map((item, index) => {
													return (
														<AgentContainer
															key={index}
															data={item}
															nomen={nomenclatures}
															nomenOptions={nomenclaturesOptions}
															shouldReload={setShouldReload}
														/>
													);
												})}
											</Col>
										</>
									) : null}
								</Row>
							</TabPane>
						</Tabs>
						{/* MODAL NUEVO AGENTE */}
						<Modal
							className="primary-modal"
							visible={newAgentModalActive}
							onCancel={() => handleNewAgentModal(false)}
							footer={null}
							width={550}
						>
							<>
								<Row className="section-modal" style={{ textAlign: "center" }}>
									<Col span={24}>
										<h2 style={{ color: "#232C4A" }}>NUEVO AGENTE</h2>
									</Col>
								</Row>
								<Row className="section-modal" style={{ marginTop: "1rem" }}>
									<Col
										span={24}
										style={{
											width: "90%",
											paddingLeft: "2rem",
											paddingRight: "2rem",
										}}
									>
										<Form
											ref={newFormRef}
											name="edit_agent_form"
											onFinish={onFinishNewAgentForm}
										>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Nombres del agente:</p>
											</div>
											<Form.Item name="name" {...config}>
												<Input maxLength={50} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Apellidos del agente:</p>
											</div>
											<Form.Item name="lastname" {...config}>
												<Input maxLength={50} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>¿Agente activo?:</p>
											</div>
											<Form.Item name="active_service" {...config}>
												<Select
													onChange={handleChangeActive}
													options={[
														{
															value: true,
															label: "Si",
														},
														{
															value: false,
															label: "No",
														},
													]}
												/>
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Código:</p>
											</div>
											<Form.Item name="code" {...config}>
												<Input maxLength={10} type="number" />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Compañía:</p>
											</div>
											<Form.Item name="company" {...config}>
												<Input maxLength={50} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Tipo contratación:</p>
											</div>
											<Form.Item name="contract_type" {...config}>
												<Input maxLength={50} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Número DUI:</p>
											</div>
											<Form.Item name="document" {...config}>
												<Input maxLength={50} />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Télefono:</p>
											</div>
											<Form.Item name="phone" {...config}>
												<Input maxLength={10} type="number" />
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Asignar nomenclaturas:</p>
											</div>
											<Form.Item name="nomenclatures" {...config}>
												{nomenclaturesOptions ? (
													<Select
														mode="multiple"
														placeholder="Buscar por nombre"
														style={{
															width: "100%",
														}}
														allowClear
														showSearch
														filterOption={(input, option) => {
															return (
																option.label
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															);
														}}
														onChange={handleNomenclatureSelect}
													>
														{nomenclaturesOptions}
													</Select>
												) : null}
											</Form.Item>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>Nombre usuario:</p>
											</div>
											<Form.Item name="user" {...config}>
												<Input maxLength={50} />
											</Form.Item>

											<Row>
												<Col span={24} align="middle">
													<Button
														htmlType="submit"
														style={{
															borderColor: "#10b48c",
															background: "#10b48c",
															paddingBottom: "15px",
															width: "250px",
														}}
														shape="round"
														size="large"
														loading={newAgentLoadButton}
													>
														<span className="title" style={{ color: "#fff" }}>
															CREAR AGENTE
														</span>
													</Button>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							</>
						</Modal>
					</div>
				</Content>
			</Layout>
		</>
	);
};

export default SecuritytAgentsPage;
