import { useSelector } from "react-redux";
import {
	getQueryDocByCollection,
	getReferenceData,
} from "../../helpers/globalHelper";
import {
	getUsersHelper,
	subirArchivoExcel,
	subriCasa,
	crearZonaTus,
	saveUserTuscania,
	relationHouse,
	saveHouse,
	updateInfoUser,
	findNumber,
	updateInfoUserAprobar,
} from "../../helpers/usersHelper";
import {
	setUsersData,
	setUsersDataAprobado,
	setErrorNumero,
	setUsersAprobado,
} from "./usersSlice";

export const getUsers = () => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		//CAMBIAR A COMITTE CUANDO SE ARREGLE BUG, SE ASIGNA RES_ID DE CDI EN VEZ DE TUSC
		const dataRaw = await getUsersHelper("Yd5MV7jkA0MjQeCmzKrZ");

		let list = dataRaw.map(async (item, index) => {
			// let type = item.payment_type && await getReferenceData(item.payment_type.path);
			// let residential = await getResidentialsById(item.residential_id);
			// let profile = await getUserDataProfileById(item.profile_id);

			/*let vehicles = [];
            if(item.user_id){
                vehicles = await getQueryDocByCollection('/vehicles_plates', "id_profile", "==", item.id);
            }
            
            let relativesWithAccess = [];
            if(item.ownerHome){        
                relativesWithAccess = await getQueryDocByCollection('/family_access', 'idProfileMain', "==", item.id);
            }*/

			let vehicles = [];
			let relativesWithAccess = [];
			let listOfResidentialsData = [];
			let data = {
				...item,
				relativesWithAccess,
				listOfResidentialsData,
				vehicles_list_data: vehicles,
			};
			data.key = index;
			return data;
		});

		Promise.all(list).then((data) => {
			dispatch(setUsersData(data));
		});
	};
};

export const getUsersAprobar = () => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		const dataRaw = await getUsersHelper(committee.residential_id);

		let list = dataRaw.map(async (item, index) => {
			// let type = item.payment_type && await getReferenceData(item.payment_type.path);
			// let residential = await getResidentialsById(item.residential_id);
			// let profile = await getUserDataProfileById(item.profile_id);

			let data = {
				...item,
			};
			data.key = index;
			return data;
		});

		Promise.all(list).then((data) => {
			dispatch(setUsersData(data));
		});
	};
};

export const aprobarUser = (idProfile, observaciones) => {
	return async (dispatch, getState) => {
		const { committee, uid } = getState().auth;

		await updateInfoUserAprobar(idProfile, uid, observaciones);
		var data = {};
		data.idProfile = idProfile;
		data.observaciones = observaciones;

		dispatch(setUsersAprobado(data));
	};
};

export const actualizarInfoUser = (data, userActive) => {
	return async (dispatch, getState) => {
		//verificamos si cambio de numero para validar que este disponible
		if (data.phone != userActive.phone) {
			var findNum = await findNumber(data.phone);
			if (findNum == true) {
				dispatch(setErrorNumero());
			} else {
				await updateInfoUser(data, userActive.id);
				var dataSend = data;
				dataSend.id = userActive.id;
				dispatch(setUsersDataAprobado(dataSend));
			}
		} else {
			await updateInfoUser(data, userActive.id);
			var dataSend = data;
			dataSend.id = userActive.id;
			dispatch(setUsersDataAprobado(dataSend));
		}
	};
};

export const subirArchivo = (file) => {
	return async (dispatch, getState) => {
		await subirArchivoExcel(file);
	};
};

export const guardarCasa = (data) => {
	return async (dispatch, getState) => {
		await subriCasa(data);
	};
};

export const guardarUser = (data, id) => {
	return async (dispatch, getState) => {
		await saveUserTuscania(data, id);
	};
};

export const agregarHouse = (data, id) => {
	return async (dispatch, getState) => {
		await saveHouse(data, id);
	};
};

export const relacionarResidencial = (house, idProfile) => {
	return async (dispatch, getState) => {
		await relationHouse(house, idProfile);
	};
};

export const crearZonaTuscania = (data) => {
	return async (dispatch, getState) => {
		await crearZonaTus(data);
	};
};
