import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
  name: "sidebarTheme",
  initialState: {
    //Definir los estilos por defecto de adminTopia...
    themeId: "",
    sidebarlogo: "",
    sidebarBackground: "",
    items: [
      {
        itemName: "",
        image: "",
        activeImage: "",
        backgroundColor: "",
        activeBackgroundColor: "",
        color: "",
        activeColor: "",
        name: "",
      },      
    ],
    submenuStyle: {
      backgroundColor: "",
      titleColor: "",
      color: "",
    },
  },
  reducers: {
    setTheme: (state, { payload }) => {
      //De acuerdo con lo del servicio esto va a cambiar
      const { data } = payload;
      return data;
    },
  },
});

export const { setTheme } = sidebarSlice.actions;
