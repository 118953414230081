import {
	collection,
	query,
	where,
	getDocs,
	orderBy,
	getDoc,
	doc,
} from "firebase/firestore";
import { FirebaseDB } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import { getDocByCollectionAnId } from "./globalHelper";

export const getNomenclaturasHelper = async (residential_id) => {
	// const collectionResidentialRef = doc( FirebaseDB, '/residentials/'+residential_id );
	const collectionRef = collection(
		FirebaseDB,
		"/residentials/" + residential_id + "/nomenclature/"
	);
	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		orderBy("name", "asc")
	);
	const querySnapshot = await getDocs(queryMessages);

	const nomenclatura = [];

	querySnapshot.forEach(async (documment) => {
		let data = documment.data();

		// Definimos los datos a obtener
		let zonaData = {
			active: data.active,
			created_at: moment(data.created_at.toDate()).format("D-MM-Y"),
			created_time: moment(data.created_at.toDate()).format("HH:mm"),
			start_house: data.start_house,
			end_house: data.end_house,
			name: data.name,
			number_residential: data.number_residential,
			residentialId: residential_id,
			id: documment.id,
			type: data.type,
		};

		nomenclatura.push(zonaData);
	});

	return nomenclatura;
};

export const getNomenclaturaByIdHelper = async (
	residential_id,
	nomenclature_id
) => {
	// const collectionResidentialRef = doc( FirebaseDB, '/residentials/'+residential_id );
	/* const collectionRef = collection(
		FirebaseDB,
		"/residentials/" + residential_id + "/nomenclature/"
	);
	const queryMessages = query(
		collectionRef,
		where(FirebaseDB.FieldPath.documentId(), "==", nomenclature_id)
	);
  const querySnapshot = await getDocs(queryMessages); */
	const collectionResidentialRef = doc(
		FirebaseDB,
		"/residentials/" + residential_id + "/nomenclature/" + nomenclature_id
	);

	const docSnap = await getDoc(collectionResidentialRef);
	const data = docSnap.data();
	const nomenclatura = [
		{
			active: data.active,
			created_at: moment(data.created_at.toDate()).format("D-MM-Y"),
			start_house: data.start_house,
			end_house: data.end_house,
			name: data.name,
			number_residential: data.number_residential,
			id: docSnap.id,
			type: data.type,
		},
	];

	/* querySnapshot.forEach(async (documment) => {
		let data = documment.data();

		// Definimos los datos a obtener
		let zonaData = {
			active: data.active,
			created_at: moment(data.created_at.toDate()).format("D-MM-Y"),
			created_time: moment(data.created_at.toDate()).format("HH:mm"),
			start_house: data.start_house,
			end_house: data.end_house,
			name: data.name,
			number_residential: data.number_residential,
			residentialId: residential_id,
			id: documment.id,
			type: data.type,
		};

		nomenclatura.push(zonaData);
	}); */

	return nomenclatura;
};

export const getNomenclaturasCasasHelper = async (
	residential_id,
	nomenclatura_id
) => {
	// const collectionResidentialRef = doc( FirebaseDB, '/residentials/'+residential_id );
	const collectionRef = collection(
		FirebaseDB,
		"/residentials/" +
			residential_id +
			"/nomenclature/" +
			nomenclatura_id +
			"/houses/"
	);
	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		orderBy("name", "asc")
	);

	const querySnapshot = await getDocs(queryMessages);

	const casas = [];

	/* let sorted = querySnapshot.data();
	sorted.sort((a, b) => a.name - b.name); */

	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		data.id = documment.id;
		// Definimos los datos a obtener
		let casaData = {
			active: data.active,
			id_profile_titular: data.id_profile_titular,
			name: data.name,
			titular_name: data.titular_name,
			use_titular: data.use_titular,
			residentialId: residential_id,
			nomenclatura_id: nomenclatura_id,
			id: documment.id,

			houseType: data.houseType,
		};

		casas.push(data);
	});

	return casas;
};

export const getDataByNomenclatureIdAndHouseIdHelper = async (
	residential_id,
	nomenclature_id,
	house_id
) => {
	const collectionResidentialRef = doc(
		FirebaseDB,
		"/residentials/" +
			residential_id +
			"/nomenclature/" +
			nomenclature_id +
			"/houses/" +
			house_id
	);

	const docSnap = await getDoc(collectionResidentialRef);
	const data = docSnap.data();
	/* const nomenclatura = [
		{
			active: data.active,
			created_at: moment(data.created_at.toDate()).format("D-MM-Y"),
			start_house: data.start_house,
			end_house: data.end_house,
			name: data.name,
			number_residential: data.number_residential,
			id: docSnap.id,
			type: data.type,
		},
	]; */

	return data;
};

export const getProfileByHouseIdHelper = async (profile_id) => {
	// const collectionResidentialRef = doc( FirebaseDB, '/residentials/'+residential_id );
	const collectionRef = doc(FirebaseDB, "/profile/" + profile_id);
	const docSnap = await getDoc(collectionRef);
	const data = docSnap.data();

	return data;
};

export const getAllUserResidential = async (residential_id) => {
	const collectionRef = collection(FirebaseDB, `/profile`);
	const queryCollection = query(
		collectionRef,
		where("active", "==", true),
		where("residentialsIds", "array-contains", residential_id),
		orderBy("name")
	);

	const querySnapshot = await getDocs(queryCollection);

	var users = [];

	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		data.id = documment.id;
		users.push(data);
	});

	return users;
};

export const getNumeroEmpleados = async (idResidencial, idHouse) => {
	if (!idResidencial) return null;

	const collectionRef = collection(FirebaseDB, "/permissions/");
	const queryMessages = query(
		collectionRef,
		where("idResidencial", "==", idResidencial),
		where(
			"house",
			"==",
			"/residentials/" +
				idResidencial +
				"/nomenclature/oficina/houses/" +
				idHouse
		)
	);
	const querySnapshot = await getDocs(queryMessages);

	var users = [];

	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		data.id = documment.id;

		users.push(data);
	});

	return users;
};

export const columnsAlertTable = () => {
	return [
		{
			title: "Fecha",
			dataIndex: "created_at",
			key: "created_at",
		},
		{
			title: "NOMBRE DE ZONAS",
			dataIndex: "name",
			key: "name",
			onCell: (record) => ({ className: "table-name-cell-invited-no-padding" }),
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "# DE RESIDENCIAS",
			dataIndex: "number_residential",
			key: "number_residential",
		},
		{
			title: "",
			dataIndex: "edit",
			key: "edit",
			width: "150px",
		},
		{
			title: "",
			dataIndex: "verCasas",
			key: "verCasas",
			width: "150px",
		},
	];
};

export const columnsCasaTable = () => {
	return [
		{
			title: "# DE RESIDENCIA",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "RESIDENTE",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "TELÉFONO",
			dataIndex: "phone",
			key: "phone",
		},
	];
};
