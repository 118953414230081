import { Col, Row } from "antd"
import { CustomInput } from "../../../../../../UI/CustomInput"
import { CustomSelect } from "../../../../../../UI/CustomSelect"
import { CaretDownOutlined } from "@ant-design/icons"

//6° sección del formulario de amenidad para agregar los datos bancarios para los pagos, como el banco, el número de cuenta asociado dicho banco y el tipo de cuenta, 
//además de incluir las validaciones básicas para los Inputs
const BankSettings = ({ Form, form, generalRules }) => (
    <section className="booking-form-section">
        <h2>DATOS BANCARIOS</h2>
        <Row gutter={16}>
            <Col span={8}>
                <Form.Item label={'BANCO'}>
                    <CustomSelect rules={[{ ...generalRules }]} placeholder={'Banco agrícola'} svgIcon suffixIcon={<CaretDownOutlined />} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item label={'NÚMERO DE CUENTA'}>
                    <CustomInput rules={[{ ...generalRules }]} placeholder={'000000000'}/>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item label={'TIPO DE CUENTA'}>
                    <CustomSelect rules={[{ ...generalRules }]} placeholder={'Selecciona un tipo de cuenta'} options={[{ id: 'corriente', name: 'Cuenta Corriente' }, { id: 'ahorro', name: 'Cuenta de Ahorro' }]} svgIcon suffixIcon={<CaretDownOutlined />} />
                </Form.Item>
            </Col>
        </Row>
    </section>
)

export default BankSettings
