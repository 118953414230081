import { SearchOutlined } from '@ant-design/icons'
import { CustomSearchInputFilter } from '../../../UI/CustomSearchInputFilter/CustomSearchInputFilter'
import moment from 'moment'

export const providersTableColumnsPrev = () => ([
    {
        title: 'PROVEEDOR',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        align: 'left'
    },
    {
        title: 'TRABAJADOR',
        dataIndex: 'worker',
        className:'left-column',
        key: 'worker',
        width: '20%',
        align: 'left'
    },
    {
        title: 'DOCUMENTO',
        dataIndex: 'document',
        key: 'document',
        width: '20%',
        align: 'left'
    },
    {
        title: 'ACOMPAÑANTES',
        dataIndex: 'companions',
        key: 'companions',
        width: '10%',
        align: 'center'
    },
    {
        title: 'HORA DE INGRESO',
        dataIndex: 'accessTimeEntered',
        key: 'accessTimeEntered',
        width: '15%',
        align: 'center',
        render: (text, record) => {
            return <span>{record.accessTimeEntered ? moment(record.accessTimeEntered, 'HH:mm:ss:SSS').format('h:mm a') : '---'}</span>
        }
    },
    {
        title: 'HORA DE SALIDA',
        dataIndex: 'accessTimeLeft',
        key: 'accessTimeLeft',
        width: '15%',
        align: 'center',
        render: (text, record) => {
            return <span>{record.accessTimeLeft ? moment(record.accessTimeLeft, 'HH:mm:ss:SSS').format('h:mm a') : '---'}</span>
        }
    },
])

export const providersTableFilters = ({ currentParams, currentFilters, handleSearch, handleReset }) => ({
    name: () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (<CustomSearchInputFilter dataIndex={'name'} placeholder={'Buscar'} setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm} clearFilters={clearFilters} handleReset={handleReset} handleSearch={handleSearch} />),
        filterIcon: () => <SearchOutlined />,
        filteredValue: currentFilters?.name || "",
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'name' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    worker: () => ({        
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'worker' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    default: () => ({}),
})