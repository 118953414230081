import { useContext, createContext, useState } from "react"
import { useAppSelector } from "../../../../hooks/store"
import useRedirectTo from "../../../hooks/useRedirectTo"
import useCurrentLocation from "../../../hooks/useCurrentLocation"

const ManagmentAmenities = createContext({
  auth: null, param: null, handleRedirectTo: () => { },
  loading: false, setLoading: () => { },
  amenityData: {}, setAmenityData: () => { },
  showModal: { mobiliarityModal: {status: false, data: {}}, restrictionModal: {status: false, data: {}} }, setShowModal: () => { },
  fileList: [], setFileList: () => { },
  mobiliaryData: [], setMobiliaryData: () => { },
  restrictionsData: [], setRestrictionsData: () => { },
})

export const ManagmentAmenitiesProvider = ({ children }) => {
  const auth = useAppSelector(state => state.auth)
  const { handleRedirectTo } = useRedirectTo()
  const { param } = useCurrentLocation()
  const [loading, setLoading] = useState(false)
  const [amenityData, setAmenityData] = useState({})
  const [fileList, setFileList] = useState([])
  const [mobiliaryData, setMobiliaryData] = useState([])
  const [restrictionsData, setRestrictionsData] = useState([])
  const [showModal, setShowModal] = useState({ mobiliarityModal: {status: false, data: {}}, restrictionModal: {status: false, data: {}} })

  const values = {
    auth, param, handleRedirectTo,
    loading, setLoading,
    amenityData, setAmenityData,
    showModal, setShowModal,
    fileList, setFileList,
    mobiliaryData, setMobiliaryData,
    restrictionsData, setRestrictionsData
  }

  return (
    <ManagmentAmenities.Provider value={values}>
      {children}
    </ManagmentAmenities.Provider>
  )
}

export const useManagmentAmenitiesContext = () => useContext(ManagmentAmenities)