import { useEffect } from "react"
import { CustomInput } from "../../../../../../../UI/CustomInput"
import CustomModal from "../../../../../../../UI/CustomModal"
import { Form, Row, Col, Button } from 'antd'

//Componente que se utiliza para crear o editar un mobiliario del formulario de amenidad incluyendo el nombre, cantidad máxima por reserva y costo por mobiliario
const NewMobiliaryModal = ({ showModal, handleOpenCloseModal, onSubmit, data }) => {

    const [form] = Form.useForm()

    //Hook para establecer datos en el formulario al montar el modal 
    useEffect(() => {
        form.setFieldsValue(data)
    }, [form, data]);

    return (
        <dialog>
            <CustomModal title={<small style={{ display: 'block', width: '100%', textAlign: 'center', fontSize: 22, fontFamily: 'Poppins' }}>{data ? 'EDITAR' : 'AGREGAR'} MOBILIARIO</small>} destroyOnClose={true} className="custom-modal amenity-modal" isOpenModal={showModal} onCancel={() => { form.resetFields(); handleOpenCloseModal({ name: 'mobiliarityModal', value: false }) }} >
                <Form layout='vertical' name="mobiliary_form" form={form} onFinish={(values) => onSubmit({ values, name: 'mobiliarityModal', form })}>
                    <Form.Item name={'id'} noStyle>
                        <CustomInput hidden={true} />
                    </Form.Item>
                    <Form.Item name={'mobiliary_name'} label={<small>NOMBRE</small>}>
                        <CustomInput />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name={'max_quantity'} label={<small>CANTIDAD MÁXIMA POR RESERVA</small>}>
                                <CustomInput type="NUMBER" nullInputNumberText="0" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={'price'} label={<small>COSTO UNITARIO</small>}>
                                <CustomInput type="NUMBER" nullInputNumberText="0" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center" style={{ paddingBottom: 32 }}>
                        <Button htmlType="submit" shape="round" className="btn-standard-green">AGREGAR</Button>
                    </Row>
                </Form>
            </CustomModal>
        </dialog>
    )
}

export default NewMobiliaryModal
