import { getColumnsFilter } from '../../../../helpers/customTableHelper'
import { useMonitoringContratorsContext } from "../../contexts/MonitoringContratorsContext"
import { providersTableColumnsPrev, providersTableFilters } from "../../helpers/providerTableHelper"
import { useCustomSearch } from '../../../../hooks/useCustomTable'
import { changeTableParams } from '../../../../helpers/customTableHelper'
import { defaulStateTable } from '../../mocks/defaultStates'
import useProvidersServices from './useProvidersServices'
import moment from 'moment'

export const useProviders = () => {
    const { historyAccessTable, providersTable, loading, param, setLoading, setProvidersTable } = useMonitoringContratorsContext()
    const { getProvidersByAccessAndAccessDateService } = useProvidersServices()
    const { handleReset, handleSearch } = useCustomSearch()

    const serviceParams = { previousData: providersTable.dataTable, actualLimit: providersTable.metadata.limit, limit: providersTable.metadata.limit, currentPage: providersTable.currentPage, skip: 0 }
    const access_date = historyAccessTable.active?.accessDate && moment(historyAccessTable.active.accessDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

    const getInitialData = async () => {
        if (!historyAccessTable?.active || !access_date) return
        const { data, metadata } = await getProvidersByAccessAndAccessDateService({ setLoading, access_id: param.accessId, limit: 10, previousData: providersTable.dataTable, currentPage: providersTable.currentPage, access_date })
        setProvidersTable({
            ...structuredClone(defaulStateTable),
            dataTable: data.dataSource,
            metadata
        })
    }

    const handleChangeTable = async ({ pagination, sorter, filters }) => {
        const tableParams = changeTableParams({ serviceParams, pagination, filters, sorter, setState: setProvidersTable, state: providersTable })
        if (!tableParams) return

        const { data, metadata } = await getProvidersByAccessAndAccessDateService({ setLoading, ...tableParams.finalParams, access_id: param.accessId, limit: 25, previousData: providersTable.dataTable, currentPage: providersTable.currentPage, access_date })
        setProvidersTable(prev => ({
            ...prev,
            dataTable: data.dataSource,
            metadata,
            currentParams: { ...tableParams.newParams },
            currentFilters: tableParams.actualFilters,
            lastSkip: tableParams.finalParams.skip
        }))
    }

    const columns = providersTableColumnsPrev().map(column => ({
        ...column,
        ...getColumnsFilter(column.dataIndex, providersTableFilters({ currentFilters: providersTable.currentFilters, currentParams: providersTable.currentParams, handleReset, handleSearch }))
    }))


    return {
        getInitialData, handleChangeTable,
        columns, providersTable, loading, historyAccessTable, access_date
    }
}

export default useProviders