import { Row, Button, Typography } from 'antd'
import CustomDropdown from '../../../../../../../UI/CustomDropdown/CustomDropdown'
import { EditMenuIcon, MenuOptionsIcon } from '../../../../../assets'
import { DeleteIcon1 } from '../../../../../../../../assets/icons'

const { Paragraph } = Typography

//Subcomponente para mostrar la información de una restricción, donde tiene un dropdown que permite abrir un modal para editar la restricción o eliminarla directamente
const RestrictionItem = ({ itemData, handleDeleteItem, handleOpenCloseModal }) => (
  <div className="mobiliarity-item">
    <Row justify='space-between' style={{ paddingBottom: 4 }}>
      <strong>{itemData?.restriction_name}</strong>
      <CustomDropdown arrow={false} overlayClassName='custom-dropdown-overlay mobiliary-dropdown' menuOptions={[
        {
          onClick: () => handleDeleteItem({ actualItem: itemData, name: 'restrictionModal' }),
          value: 'delete', label: <Row align='middle' style={{ gap: 8 }}>
            <DeleteIcon1 />
            <span>Eliminar</span>
          </Row>
        }, {
          onClick: () => handleOpenCloseModal({ data: itemData, name: 'restrictionModal', value: true }),
          value: 'edit', label: <Row align='middle' style={{ gap: 8 }}>
            <EditMenuIcon />
            <span>Editar</span>
          </Row>
        },
      ]}>
        <Button icon={<MenuOptionsIcon />} style={{ border: 'none', backgroundColor: 'transparent', boxShadow: 'none' }} />
      </CustomDropdown>
    </Row>
    <small>Descripción</small>
    <Paragraph style={{ marginTop: 8, fontSize: 12, overflowY: 'auto' }} ellipsis={{ rows: 3 }} /**Se muestra la descripción de la restricción en un máximo de 3 líneas */>
      {itemData?.restriction_description}
    </Paragraph>
  </div>
)

export default RestrictionItem
