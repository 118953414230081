import * as XLSX from "xlsx";
import moment from "moment";

const ExportCobroToExcel = () => {
	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}
	const ExportCobro = (cobros) => {
		const data = cobros.map((item, index) => ({
			idCliente: item.profileData
				? item.profileData.name + " " + item.profileData.lastname
				: item.profile_id,
			emision: moment(item.created_at.toDate()).format("Y-MM-DD"),
			expiration_date: moment(item.expiration_date.toDate()).format(
				"Y-MM-DD"
			),
			medidorPotable: item.details.medidorPotable,
			medidorRiego: item.details.medidorRiego,
			tarifaPotable: item.details.tarifaPotable,
			fechaInicioCobro: item.details.fechaInicioCobro,
			fechaFinCobro: item.details.fechaFinCobro,
			potableInicial: item.details.potableInicial,
			potableFinal: item.details.potableFinal,
			riegoInicial: item.details.riegoInicial,
			riegoFinal: item.details.riegoFinal,
			amount: currencyFormat(parseFloat(item.amount).toFixed(2).toString()),
		}));


		const Heading = [
			[
				"CLIENTE",
				"EMISION",
				"VENCIMIENTO",
				"MEDIDOR_AP",
				"MEDIDOR_RG",
				"TARIFA_AP",
				"COBRO_INICIO",
				"COBRO_FIN",
				"AP_INICIO",
				"AP_FIN",
				"RG_INICIO",
				"RG_FIN",
				"TOTAL_PAGAR",
			],
		];
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_aoa(ws, Heading);
		XLSX.utils.sheet_add_json(ws, data, {
			origin: "A2",
			skipHeader: true,
		});
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "Datos Casa.xlsx");
	};
	return { ExportCobro };
};

export default ExportCobroToExcel;
