import React from "react";
import { Table, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveResident } from "../../../../store/access";
import { columnsAccessScheduleVisitorTable } from "../../../../helpers/accessHelper";

export const AccessScheduleVisitorsTab = () => {
	const [scheduleVisitorList, setSheduleVisitorList] = useState([]);
	const dispatch = useDispatch();
	const { scheduledVisitors } = useSelector((state) => state.access);
	const columns = columnsAccessScheduleVisitorTable();
	const [loading, setLoad] = useState(true);

	useEffect(() => {
		// setActiveResident(scheduledVisitors.active);
	}, [scheduledVisitors.active]);

	useEffect(() => {
		setLoad(scheduledVisitors.loadResidente);
	}, [scheduledVisitors.loadResidente]);

	useEffect(() => {
		setSheduleVisitorList(scheduledVisitors.data);
	}, [scheduledVisitors.data]);

	const handleStatusModal = (statusModal, index = null) => {
		// if(index != null){
		//   dispatch( setActiveAlert( alerts.data[index] ) );
		// }else{
		//   dispatch( setActiveAlert( null ) );
		// }
		// setTimeout(() => {
		//   setModalVisible(statusModal);
		// }, 50);
	};

	return (
		<>
			{loading ? (
				<div className={"loader"}>
					<Spin size="large" />
				</div>
			) : (
				<div>
					<Table
						className="primary-table"
						columns={columns}
						dataSource={[...scheduleVisitorList]}
						pagination={{ defaultPageSize: 15, simple: true }}
					/>
				</div>
			)}
		</>
	);
};
