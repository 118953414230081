import { Row, Col, } from 'antd'
import CustomSwitch from '../../../UI/CustomSwitch'
import { CustomSelect } from '../../../UI/CustomSelect'
import { CustomInput } from '../../../UI/CustomInput'
import {CaretDownOutlined} from '@ant-design/icons'

//Componente que puede reutilizarse en multiples componentes del formulario de crear una nueva amenidad
//Que permite definir un switch para habilitar o deshabilitar el form item como un CustomInput o un CustomSelect
//Igual permite recibir un children para personalizar el elemento que esta dentro y se quiere habilitar o deshabilitar con el switch
const CustomItemForm = ({ Form, switchContainer = true, switchLabel, marginTop = 0, switchName, inputName, inputLabelName, selectItem = false, selectOptions = [], children }) => {
    return (
        <Col span={23} style={{ marginBottom: 16, marginTop: marginTop }}>
            { //El booleano debe de ser true para mostrar el switch y el label de este, de lo contrario no lo renderiza
                switchContainer ? <Row align='middle' justify='space-between' style={{ marginBottom: 24 }}>
                    <strong style={{ overflow: 'hidden', wordBreak: 'break-all', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '80%' }}>{switchLabel}</strong>
                    <Form.Item name={switchName} style={{ marginBottom: 0 }}  >
                        <CustomSwitch />
                    </Form.Item>
                </Row> : <></>
            }
            {
                children ? children : ( //Si no hay un children entonces va a rendreizar un Form.Item que valida además si tiene datos o no cuando el switch está activo o si este no está renderizado
                    <Form.Item noStyle shouldUpdate>
                        {
                            ({ getFieldValue }) => {
                                const isEnabled = getFieldValue(switchName)
                                return (
                                    <Col>
                                        <Form.Item rules={[{
                                            validator: async (_, value) => {
                                                if ((value && switchContainer && isEnabled) || (!switchContainer && value) || (switchContainer && !isEnabled)) { 
                                                    //Valida si el switch esta activo y hay un valor en el input o si no hay switch y hay un valor o si hay un switch y está desactivado
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(new Error(`El campo es necesario`))
                                            }
                                        }]} name={inputName} label={inputLabelName} >
                                            { //Renderiza un CustomSelect o un CustomInput dependiendo del booleano
                                            selectItem ? <CustomSelect svgIcon suffixIcon={<CaretDownOutlined />} disabled={switchContainer && !isEnabled} options={selectOptions} /> : <CustomInput nullInputNumberText='0' disabled={switchContainer && !isEnabled} type='NUMBER' />
                                            }
                                        </Form.Item>
                                    </Col>
                                )
                            }
                        }
                    </Form.Item>
                )
            }
        </Col>
    )
}

export default CustomItemForm
