import { ReactComponent as BookingApprovedStatusIcon } from './icons/bookingApprovedStatusIcon.svg';
import bookingApprovedStatusIcon from './icons/bookingApprovedStatusIcon.svg';
import { ReactComponent as BookingDetailIcon } from './icons/bookingDetailIcon.svg';
import bookingDetailIcon from './icons/bookingDetailIcon.svg';
import { ReactComponent as CancelledStatusIcon } from './icons/cancelledStatusIcon.svg';
import cancelledStatusIcon from './icons/cancelledStatusIcon.svg';
import { ReactComponent as ComprobantReceivedIcon } from './icons/comprobantReceivedIcon.svg';
import comprobantReceivedIcon from './icons/comprobantReceivedIcon.svg';
import { ReactComponent as PlusIcon } from './icons/plusIcon.svg';
import plusIcon from './icons/plusIcon.svg';
import { ReactComponent as ProfileIcon } from './icons/profileIcon.svg';
import profileIcon from './icons/profileIcon.svg';
import { ReactComponent as ReviewStatusIcon } from './icons/reviewStatusIcon.svg';
import reviewStatusIcon from './icons/reviewStatusIcon.svg';
import { ReactComponent as WaitingStatusIcon } from './icons/waitingStatusIcon.svg';
import waitingStatusIcon from './icons/waitingStatusIcon.svg';
import testAvatar from './images/testAvatar.jpg';
import { ReactComponent as TestComprobant } from './images/testComprobant.svg';
import testComprobant from './images/testComprobant.svg';

export {
  bookingApprovedStatusIcon, BookingApprovedStatusIcon,
  bookingDetailIcon, BookingDetailIcon,
  cancelledStatusIcon, CancelledStatusIcon,
  comprobantReceivedIcon, ComprobantReceivedIcon,
  plusIcon, PlusIcon,
  profileIcon, ProfileIcon,
  reviewStatusIcon, ReviewStatusIcon,
  waitingStatusIcon, WaitingStatusIcon,
  testAvatar,
  testComprobant, TestComprobant,
};
