import React from "react";
import {
	Col,
	Row,
	Button,
	Modal,
	Space,
	Table,
	Badge,
	Descriptions,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./style.css";
import { backIcon, pdf, phone, print, printIcon, sheets } from "../../../../assets/icons";
import { shape08 } from "../../../../assets/images";

export const SpecificPaymentTab = () => {
	const [transactions, setTransactions] = useState([]);
	const [transactionModalActive, setTransactionModalActive] = useState(false);
	const [reportModalActive, setReportModalActive] = useState(false);
	const [reportData, setReportData] = useState([]);
	const navigate = useNavigate();
	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};
	const IconButtonReport = () => (
		<img
			style={{ width: "20px", height: "20px" }}
			src={printIcon}
			alt="print-shape"
		/>
	);

	const handleTransactionModal = async (statusModal, index = null) => {
		/* let active_user = user;
		if (active_user.ownerHome) {
			// Agregamosal detalle los dependientes de los socios
			let relativesWithAccess = await getQueryDocByCollection(
				"/family_access",
				"idProfileMain",
				"==",
				active_user.id
			);
			active_user = {
				...active_user,
				relativesWithAccess: relativesWithAccess,
			};
			if (active_user.origin_fam) {
				const billingDataHistory = await getPaymentsHelperByAccount(
					active_user.origin_fam
				).then((billingD) => {
					active_user.billingData = billingD;
				});
			}
			setUserProfileActive(active_user);
		} */

		setTimeout(() => {
			setTransactionModalActive(statusModal);
		}, 50);
	};
	const transactionColumns = [
		{
			title: "HORA",
			dataIndex: "date_time",
			key: "date_time",
			align: "center",
			/* sorter: (a, b) => a.constructionFee - b.constructionFee,
                render: (text, record) => (
                    <>{moment(record.date_action).format("YY/MM/D")}</>
                ), */
		},
		{
			title: "RESIDENTE",
			dataIndex: "name",
			key: "name",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "TIPO DE OPERACIÓN",
			dataIndex: "reservas",
			key: "reservas",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "Cobro",
			dataIndex: "charges",
			key: "charges",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
			/* render: (id, record, index) => (
                    <div className="">$ {parseFloat(record.total).toFixed(2)}</div>
                ), */
		},
		{
			title: "IMPUESTOS",
			dataIndex: "payments",
			key: "payments",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "COMISIÓN",
			dataIndex: "action_user",
			key: "action_user",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "FEE TOPIA",
			dataIndex: "action_user",
			key: "action_user",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "LIQUIDACIÓN",
			dataIndex: "phone",
			key: "phone",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
			/* render: (id, record, index) => (
                    <div className="">$ {parseFloat(record.total).toFixed(2)}</div>
                ), */
		},
		{
			dataIndex: "action_user",
			key: "action_user",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
	];

	//Modal functions
	const handleReportModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setReportModalActive(statusModal);
		}, 50);
	};

	return (
		<>
			<div>
				<Row className="profile-user-data">
					<Col span={7}>
						<div className="profile-user-data-container">
							<h2 className="title-page" style={{ color: "#232c4a" }}>
								<img
									src={backIcon}
									alt="left-arrow-icon"
									style={{ paddingRight: "12px", cursor: "pointer" }}
									onClick={() => {
										navigate("/reporte-liquidaciones");
									}}
								/>
								31 DE ENERO DE 2023
							</h2>
						</div>
					</Col>
					<Col span={4} align="left">
						<p
							style={{
								paddingTop: "2.4rem",
								paddingBottom: "2rem",
								color: "#10b48c",
							}}
						>
							Transacciones: 62
						</p>
					</Col>
					<Col span={7}></Col>
					<Col span={6}>
						<div className="profile-user-data-container">
							<Space size={25}>
								<img src={sheets} alt="sheets" />
								<img src={pdf} alt="pdf" />
								<img src={print} alt="print" />
							</Space>
						</div>
					</Col>

					<Col span={4} align="center">
						<div className="header-text-report">
							<h4>COBROS: $ 440.00</h4>
						</div>
					</Col>
					<Col span={4} align="center">
						<div className="header-text-report">
							<h4>IMPUESTO: $ 360.00</h4>
						</div>
					</Col>
					<Col span={6} align="center">
						<div className="header-text-report">
							<h4>COMISIÓN BANCARIA: $ 360.00</h4>
						</div>
					</Col>
					<Col span={5} align="center">
						<div className="header-text-report">
							<h4>FEE TOPIA: $ 360.00</h4>
						</div>
					</Col>
					<Col span={5} align="center">
						<div className="header-text-report">
							<h4 style={{ color: "#10b48c" }}>LIQUIDACIÓN: $ 360.00</h4>
						</div>
					</Col>
				</Row>
				<div className="wrapper-page">
					<Table
						className="primary-table"
						pagination={{ defaultPageSize: 20, simple: true }}
						columns={transactionColumns}
						dataSource={transactions}
						loading="true"
						//rowKey="id"
						locale={locale}
					/>
					<Button
						style={{
							color: "#ADB9BB",
							background: "white",
							border: "10px",
							fontSize: "11px",
							fontWeight: "500px",
							width: "104px",
						}}
						onClick={() => {
							handleReportModal(true);
						}}
						size="small"
					>
						<span className="title">Abrir modal</span>
					</Button>
				</div>
			</div>

			{/* MODAL REPORTE */}
			<Modal
				className="side-modal"
				title="Detalle del pago"
				style={{ top: 0, right: 0 }}
				visible={reportModalActive}
				onCancel={() => handleReportModal(false)}
				footer={null}
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
				width={600}
			>
				<>
					<Row className="section-modal" style={{ paddingTop: "0px" }}>
						<Col span={24}>
							<p>USUARIO</p>
						</Col>
						<Col span={18}>
							<h2>
								Felipe Gilberto Hernández{" "}
								<img
									className="iconPerfil"
									src={shape08}
									alt="credential-security-icon"
									style={{ cursor: "pointer" }}
								/>
							</h2>
						</Col>
						<Col span={6}>
							<h3 style={{ color: "#10B48C" }}>
								<img
									className="iconoPerfil"
									src={phone}
									alt="phone-icon"
								/>
								7808-9532
							</h3>
						</Col>
						<Col span={24}>
							<p>FECHA DE PAGO: 31/01/23</p>
						</Col>
						<br />
						<br />
						<br />
						<Col span={24}>
							<p>CONCEPTO</p>
						</Col>
						<Col span={24} style={{ fontWeight: "bold" }}>
							<p>Reserva de amenidad - Salón Multiusos</p>
						</Col>
						<Col span={24}>
							<p>FECHA DE RESERVA</p>
						</Col>
						<Col span={24} style={{ fontWeight: "bold" }}>
							<p>Viernes 17 de enero de 2023. De 3:00pm a 6:00pm</p>
						</Col>
						<br />
						<br />
						<br />
					</Row>
					<Row className="section-modal">
						<Col span={24} align="middle">
							<Descriptions
								bordered
								column={1}
								style={{ textAlign: "center" }}
								size="small"
							>
								<Descriptions.Item label="Cobro">$100.00</Descriptions.Item>
								<Descriptions.Item label="IMPUESTO">$2.03</Descriptions.Item>
								<Descriptions.Item label="COMISIÓN">$1.14</Descriptions.Item>
								<Descriptions.Item label="FEE TOPIA">$1.06</Descriptions.Item>
								<Descriptions.Item label="LIQUIDACIÓN">
									$95.77
								</Descriptions.Item>
							</Descriptions>
						</Col>
						<Col span={24} align="middle">
							<br />
							<br />
							<Button
								style={{
									color: "#10b48c",
									borderColor: "#10b48c",
									background: "white",
									paddingBottom: "15px",
									marginLeft: "15px",
									marginRight: "15px",
								}}
								shape="round"
								size="large"
								icon={<IconButtonReport />}
							>
								<span
									className="title"
									style={{ marginLeft: "10px", color: "#10b48c" }}
								>
									IMPRIMIR
								</span>
							</Button>
						</Col>
					</Row>
				</>
			</Modal>
		</>
	);
};
