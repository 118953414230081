import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import "./styles.css";
import {
	getNomenclaturas,
	setActiveNomenclatura,
	setNomenclaturas,
	getNomenclaturasCasas,
	saveResidencial,
} from "../../../../../store/nomenclaturas";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Space, Button, DatePicker, Tabs } from "antd";
import { getNomenclaturasCasasHelper } from "../../../../../helpers/nomenclaturasHelper";

export const NomenclaturaFooter = () => {
	const dispatch = useDispatch();
	const { nomenclaturas } = useSelector((state) => state.nomenclaturas);
	const [nomenclaturaList, setNomenclaturaList] = useState([]);
	const [nomenclaturaFull, setNomenclaturaFull] = useState([]);
	const [nomenclaturaActive, setNomenclaturaActive] = useState(null);
	useEffect(() => {
		dispatch(getNomenclaturas());
		//dispatch( getUser() );
	}, [dispatch]);

	useEffect(() => {
		let houses = nomenclaturas.data.map(async (item, index) => {
			let data = { ...item };
			let houses = await getNomenclaturasCasasHelper(
				"Yd5MV7jkA0MjQeCmzKrZ",
				nomenclaturas.data[index].id
			).then((houseData) => {
				data.houseData = houseData;
			});
			/* data.houses = await getNomenclaturasCasas(nomenclaturas.data[index].id); */
			return data;
		});
		Promise.all(houses).then((data) => {
			setNomenclaturaFull(data);
		});
	}, [nomenclaturas.data]);

	const totalHousePay = (nomenclatures) => {
		let total = 0;
		nomenclatures.map(async (item, index) => {
			if (item.houseData) {
				item.houseData.map((item) => {
					total += +parseFloat(item.total).toFixed(2);
				});
			}
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};
	const totalNomenclatures = (nomenclatures) => {
		let total = 0;
		total = nomenclatures.length;
		return total;
	};
	const totalHouses = (houseData) => {
		let total = 0;
		houseData.map(async (item, index) => {
			if (item.houseData) {
				total += +item.houseData.length;
			}
		});

		return total;
	};
	/* const checkHouses = (nomenclature) => {
		let isHouse= false;
		nomenclature.map((item)=>{
			item.houseData? isHouse=true : isHouse=false;
		})
		return isHouse;
	} */
	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}
	return (
		<>
			<Row>
				<Col span={6}>
					<div className="footer-content">
						<p>
							Zonas:{" "}
							{nomenclaturaFull ? totalNomenclatures(nomenclaturaFull) : "N/A"}
						</p>
					</div>
				</Col>
				<Col span={6}>
					<div className="footer-content">
						<p>
							Total casas:{" "}
							{nomenclaturaFull ? totalHouses(nomenclaturaFull) : "N/A"}
						</p>
					</div>
				</Col>
				<Col span={6}>
					<div className="footer-content">
						<p style={{ color: "#10b48c" }}>
							Total de cobro:{" "}
							{nomenclaturaFull
								? currencyFormat(totalHousePay(nomenclaturaFull))
								: "N/A"}
						</p>
					</div>
				</Col>
				<Col span={6}></Col>
			</Row>
		</>
	);
};
