import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import "./styles.css";
import { Col, Row, Space, Button, DatePicker, Tabs } from "antd";
import * as XLSX from "xlsx";
import { sheets } from "../../../../assets/icons";

export const ReportLiquidacionFooter = ({ transactions, fullArray }) => {
	const [allPayments, setAllPayments] = useState([]);
	const [fullPayments, setfullPayments] = useState([]);

	useEffect(() => {
		setAllPayments(transactions);
	}, [transactions]);

	useEffect(() => {
		setfullPayments(fullArray);
	}, [fullArray]);

	const countTransactions = (transData) => {
		let total = 0;
		total = transData.length;
		return total;
	};

	const countBookingsByDate = (transData) => {
		let total = 0;
		if (transData) {
			transData.map(async (item, index) => {
				if (item.payment_type.id === "8IpSBC0R7dC27IKfbtEp") {
					total++;
				}
			});
		}
		return total;
	};

	const countPaymentsByDate = (transData) => {
		let total = 0;
		if (transData) {
			transData.map(async (item, index) => {
				if (
					item.payment_type.id === "OOCwFcoQ0HSNgnZ7BdQH" ||
					item.payment_type.id === "4ll4c5y5pxn9AkukQx7S"
				) {
					total++;
				}
			});
		}
		return total;
	};

	const countCharges = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			if (item.amount) {
				const amm = Number(parseFloat(item.amount).toFixed(2));
				total += +(amm + item.mora);
			}
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countLiquidations = (transData) => {
		let total = 0;
		let totalDiscount = 0;
		transData.map(async (item, index) => {
			if (item.amount) {
				const amm = Number(parseFloat(item.amount).toFixed(2));
				total += +(amm + item.mora);
				totalDiscount += +(
					item.payment_details.accFee +
					item.payment_details.taxFee +
					item.payment_details.topiaFee
				);
			}
		});
		total = parseFloat(total).toFixed(2) - parseFloat(totalDiscount).toFixed(2);
		return total;
	};

	const countPaymentsByField = (transData, field) => {
		let total = 0;
		if (transData) {
			transData.map(async (item, index) => {
				if (item.details.medioPago === field) {
					total++;
				}
			});
		}
		return total;
	};

	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	const handleExportExcel = () => {
		let data = [];
		fullPayments.map((item) => {
			data.push({
				date: item.date,
				transactions: countTransactions(item.data),
				reserve: countBookingsByDate(item.data),
				payments: countPaymentsByField(item.data),
				charge: countCharges(item.data),
				liquidation: countLiquidations(item.data),
			});
		});
		let Heading = [
			["Día", "Transacciones", "Reservas", "Pagos", "Cobro", "Liquidación"],
		];
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_aoa(ws, Heading);
		XLSX.utils.sheet_add_json(ws, data, {
			origin: "A2",
			skipHeader: true,
		});
		XLSX.utils.book_append_sheet(wb, ws, "Hoja 1");
		XLSX.writeFile(wb, "Transacciónes por fecha.xlsx");
	};
	return (
		<>
			<Row>
				<Col span={6}>
					<div className="footer-content">
						<p>
							Transacciones:{" "}
							{allPayments ? countTransactions(allPayments) : "N/A"}
						</p>
					</div>
				</Col>
				<Col span={6}>
					<div className="footer-content">
						<p>
							Cobros:{" "}
							{allPayments
								? currencyFormat(countCharges(allPayments).toString())
								: "N/A"}
						</p>
					</div>
				</Col>
				<Col span={6}>
					<div className="footer-content">
						<p style={{ color: "#10b48c" }}>
							Liquidación:{" "}
							{allPayments
								? currencyFormat(
										countLiquidations(allPayments).toFixed(2).toString()
								  )
								: "N/A"}
						</p>
					</div>
				</Col>
				<Col span={6}>
					<Space>
						<img
							src={sheets}
							alt="sheets"
							onClick={handleExportExcel}
							style={{ cursor: "pointer" }}
						/>
						{/* <img src="/assets/icons/pdf.svg" alt="pdf" />
						<img src="/assets/icons/print.svg" alt="print" /> */}
					</Space>
				</Col>
			</Row>
		</>
	);
};
