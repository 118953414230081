import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Card,
} from "antd";
import { handIcon } from '../../../../assets/icons'

export const AppBanner = ({ bannerColor, title, description, icon }) => {
	const [bannerTitle, setBannerTitle] = useState("");
	const [bannerDescription, setBannerDescription] = useState("");
	const [bannerIcon, setBannerIcon] = useState(handIcon);
	useEffect(() => {
		setBannerTitle(title);
		setBannerDescription(description);
		if (!!icon) {
			setBannerIcon(icon);
		}

	}, []);

	useEffect(() => {
		if (!icon) {
			return;
		}
		setBannerIcon(icon);
	}, [icon]);
	/* useEffect(() => {
		setColor(banner);
	}, [banner]); */
	return (
		<>
			<div style={{ height: "96px", width: "320px" }}>
				<Card
					bodyStyle={{
						borderRadius: "16px",
						backgroundColor: bannerColor,
						height: "96px",
						width: "320px",
						margin: "0px",
						padding: "0px",
					}}
				>
					<Row>
						<Col span={6}>
							<div>
								<img alt="banner-icon" style={{ height: "80px", margin: "8px" }} src={bannerIcon} />
							</div>
						</Col>
						<Col
							span={18}
							style={{ maxWidth: "230px", display: "inline-block" }}
						>
							<div
								style={{
									marginTop: "20px",
									marginLeft: "20px",
									marginRight: "5px",
								}}
							>
								<span
									style={{
										fontSize: "14px",
										fontWeight: "400",
										lineHeight: "16px",
										textAlign: "right",
										color: "white",
									}}
								>
									{title}
								</span>
								<br />
								<span
									style={{
										fontSize: "11px",
										fontWeight: "300",
										lineHeight: "8px",
										textAlign: "right",
										color: "white",
										textTransform: "uppercase",
									}}
								>
									{description}
								</span>
							</div>
						</Col>
					</Row>
				</Card>
			</div>
		</>
	);
};
