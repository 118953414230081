import React from "react";
import {
	Col,
	Row,
	Button,
	DatePicker,
	Tabs,
	Modal,
	Select,
	Radio,
	Space,
	Form,
	Table,
} from "antd";
import moment from "moment";
import { PackagesTab } from "./components/PackagesTab";

const PackagesPage = () => {
	const { RangePicker } = DatePicker;
	const { TabPane } = Tabs;
	const dateFormat = "DD/MM/YYYY";

	const customFormat = (value) => `${value.format(dateFormat)}`;
	return (
		<div className="wrapper-page">
			<Row>
				<Col span={12}>
					<h2 className="title-page">Recepción de paquetes</h2>
				</Col>
				<Col span={12}>
					<div className="wrapper-page">
						<div className="contBusqueda">
							<RangePicker
								defaultValue={[
									moment(new Date(), dateFormat),
									moment(new Date(), dateFormat),
								]}
								format={customFormat}
								style={{
									width: "270px",
								}}
							/>
						</div>
					</div>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Tabs className="primary-tabs" defaultActiveKey="1">
						<TabPane tab="Paquetes recibidos" key="1">
							<PackagesTab />
						</TabPane>
						<TabPane tab="Historial" key="2"></TabPane>
					</Tabs>
				</Col>
			</Row>
		</div>
	);
};

export default PackagesPage;
