import useCurrentLocation from "../../../../hooks/useCurrentLocation"
import HistoryAccess from "./components/HistoryAccess"
import Providers from "./components/Providers"
import Workers from "./components/Workers"

export const HistoryAccessPage = () => {

  const { param } = useCurrentLocation()
  const pages = {
    providers: <Providers />,
    workers: <Workers />,
  }

  return (
    param?.historyPage ? pages[param.historyPage] : <HistoryAccess />
  )
}

export default HistoryAccessPage