import { getTableDataHelper } from "../../../../helpers/getTableDataHelper"
import verifyResponse from "../../../../helpers/verifyResponse"
import { useMonitoringContratorsContext } from "../../contexts/MonitoringContratorsContext"
import { getWorkersByAccessAndAccessDate } from "../../services/GET/getWorkersByAccessAndAccessDate"

export const useWorkersServices = () => {

    const { auth } = useMonitoringContratorsContext()

    const getWorkersByAccessAndAccessDateService = async ({ access_id, access_date, limit, skip, find_by, find_value, sort_by, sort_order, last, currentPage, previousData, setLoading }) => {
        const res = await getWorkersByAccessAndAccessDate({ token: auth.idToken, access_id, access_date, limit, skip, find_by, find_value, sort_by, sort_order })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('getWorkersByAccessAndAccessDateService')
        }
        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, skip, last, actualLimit: limit, currentPage, previousData })
        return { data, metadata }

    }
    return {
        getWorkersByAccessAndAccessDateService
    }
}

export default useWorkersServices