import React from "react";
import {
	Button,
	Table,
	Spin,
	Descriptions,
	Modal,
	Col,
	Row,
	Form,
	Input,
	Select,
	Space,
	InputNumber,
	message,
	Switch,
	Radio,
} from "antd";

import { SearchOutlined } from "@ant-design/icons";
import { Spinner } from "react-activity";
import { useNavigate } from "react-router-dom";
import ExportHousesExcel from "../../configuracionInicial/nomenclatura/components/ExportHousesExcel";
import { useEffect, useState } from "react";
import { getColumnSearchProps } from "../../../../utils/tables";
import { useDispatch, useSelector } from "react-redux";
import { getNomenclaturas } from "../../../../store/nomenclaturas";
import {
	getDataByNomenclatureIdAndHouseIdHelper,
	getNomenclaturasCasasHelper,
} from "../../../../helpers/nomenclaturasHelper";
import { getProfileByHouseIdHelper } from "../../../../helpers/nomenclaturasHelper";
import { toast } from "react-toastify";
import { useForm } from "../../../../hooks";
import { saveResidencial } from "../../../../store/nomenclaturas";
import { getQueryDocByCollection } from "../../../../helpers/globalHelper";

import FormItem from "antd/lib/form/FormItem";
import UserProfileModal from "../../../../components/UserProfileModal";
import { shape25, shape08 } from '../../../../assets/images'

export const AdminCobrosTuscaniaTable = ({
	reload,
	index,
	nomenclatura,
	id,
}) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [residencialActive, setResidencialActive] = useState(null);
	const [modalResidencialVisible, setModalResidencialVisible] = useState(false);
	const [nomenclaturaFull, setNomenclaturaFull] = useState([]);
	const [houseDataFull, setHouseDataFull] = useState([]);
	const [sendingResidencial, handleSendingResidencial] = useState(false);
	const [loadSendData, setLoadSendData] = useState(false);
	const { TextArea } = Input;
	const [userActive, setUserActive] = useState([]);
	const [userModalActive, setUserModalActive] = useState(false);
	const IconButtonReport = () => <img src={shape25} alt='eye-scanner-icon' />;

	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};

	const houses = nomenclatura;
	const ind = index;

	useEffect(() => {
		if (houses) {
			let sortedHouses = houses.sort(function (a, b) {
				var aInt = parseInt(a.name);
				var bInt = parseInt(b.name);
				return aInt - bInt;
			});
			let profile = sortedHouses.map(async (item, index) => {
				let data = { ...item };
				if (data.idOwners) {
					const profile = await getProfileByHouseIdHelper(
						data.idOwners[0]
					).then((pro) => {
						data.userProfile = pro;
					});
				}
				/* data.profile = (
					<img
						className="iconoPerfil"
						src="/assets/icons/Security/shape08.svg"
						style={{ cursor: "pointer" }}
						alt="Topia"
						onClick={() => handleStatusResidentsModal(true, index)}
					/>
				); */
				return data;
			});
			Promise.all(profile).then((data) => {
				setHouseDataFull(data);
			});
		}
	}, [houses]);

	//Modal functions

	const handleStatusResidentsModal = async (statusModal, record = null) => {
		if (record != null) {
			{
				let active_user = record.userProfile;
				active_user.maintenanceFee = parseFloat(record.maintenanceFee).toFixed(
					2
				);
				active_user.constructionFee = parseFloat(
					record.constructionFee
				).toFixed(2);
				active_user.residenceFee = parseFloat(record.residenceFee).toFixed(2);
				active_user.alcaldia = parseFloat(record.alcaldia).toFixed(2);
				active_user.total = parseFloat(record.total).toFixed(2);
				if (active_user) {
					let id = record.idOwners[0];
					active_user = {
						...active_user,
						id: id,
					};
				}
				if (active_user.ownerHome) {
					// Agregamosal detalle los dependientes de los socios
					let relativesWithAccess = await getQueryDocByCollection(
						"/family_access",
						"idProfileMain",
						"==",
						active_user.id
					);

					let nomenclaturePath = active_user.residentials[0]?.house["path"];
					nomenclaturePath = nomenclaturePath.substring(47, 67);
					let housePath = active_user.residentials[0]?.house["path"];
					housePath = housePath.substring(75, 95);

					let houseData = await getDataByNomenclatureIdAndHouseIdHelper(
						"Yd5MV7jkA0MjQeCmzKrZ",
						nomenclaturePath,
						housePath
					);

					active_user = {
						...active_user,
						houseData: houseData,
						relativesWithAccess: relativesWithAccess,
					};
				}
				setUserActive(active_user);
			}
		}
		setTimeout(() => {
			setUserModalActive(statusModal);
		}, 50);
	};

	const columnsCasa = [
		{
			title: "Nombre titular",
			dataIndex: ["userProfile", "name"],
			key: "userProfileName",
			width: 250,
			fixed: "left",
			render: (text, record) => (
				<>
					{record.userProfile?.name} {record.userProfile?.lastname}
				</>
			),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<div style={{ padding: 8 }}>
							<Input
								autoFocus
								placeholder=""
								value={selectedKeys[0]}
								onChange={(e) => {
									setSelectedKeys(e.target.value ? [e.target.value] : []);
									confirm({ closeDropdown: false });
								}}
								onPressEnter={() => {
									confirm();
								}}
								onBlur={() => {
									confirm();
								}}
								style={{ marginBottom: 8, display: "block" }}
							></Input>
							<Space>
								<Button
									icon={<SearchOutlined />}
									onClick={() => {
										confirm();
									}}
									type="primary"
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => {
										clearFilters();
									}}
									size="small"
									style={{ width: 90 }}
								>
									Limpiar
								</Button>
							</Space>
						</div>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.userProfile?.name
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			title: "Alias",
			dataIndex: "name",
			key: "name",
			width: 80,
			fixed: "left",
			...getColumnSearchProps("name"),
		},
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: 150,

			...getColumnSearchProps("id"),
		},

		{
			title: "Perfil",
			dataIndex: "profile",
			key: "profile",
			align: "center",
			width: 100,
			render: (id, record) => (
				<img
					className="iconoPerfil"
					src={shape08}
					style={{ cursor: "pointer" }}
					alt="Topia"
					onClick={() => handleStatusResidentsModal(true, record)}
				/>
			),
		},
		/* {
			title: "Dirección",
			dataIndex: "address",
			key: "address",
			width: 300,
			...getColumnSearchProps("address"),
		}, */

		{
			title: "Area M2",
			dataIndex: "areaM2",
			key: "areaM2",
			width: 120,
			sorter: (a, b) => a.areaM2 - b.areaM2,
		},
		{
			title: "Area V2",
			dataIndex: "areaV2",
			key: "areaV2",
			width: 120,
			sorter: (a, b) => a.areaV2 - b.areaV2,
			render: (id, record, index) => (
				<div className="">{parseFloat(record.areaV2).toFixed(2)}</div>
			),
		},
		{
			title: "Propiedad",
			dataIndex: "houseType",
			key: "houseType",
			width: 120,
		},
		{
			title: "Categoría",
			dataIndex: "category",
			key: "category",
			width: 120,
			sorter: (a, b) => a.category - b.category,
		},
		{
			title: "Mantenimiento",
			dataIndex: "maintenanceFee",
			key: "maintenanceFee",
			width: 200,
			sorter: (a, b) => a.maintenanceFee - b.maintenanceFee,
			render: (id, record, index) => (
				<div className="">$ {parseFloat(record.maintenanceFee).toFixed(2)}</div>
			),
		},
		{
			title: "Construcción",
			dataIndex: "constructionFee",
			key: "constructionFee",
			width: 200,
			sorter: (a, b) => a.constructionFee - b.constructionFee,
			render: (id, record, index) => (
				<div className="">
					$ {parseFloat(record.constructionFee).toFixed(2)}
				</div>
			),
		},
		{
			title: "Residencia",
			dataIndex: "residenceFee",
			key: "residenceFee",
			width: 200,
			sorter: (a, b) => a.residenceFee - b.residenceFee,
			render: (id, record, index) => (
				<div className="">$ {parseFloat(record.residenceFee).toFixed(2)}</div>
			),
		},
		{
			title: "Desechos Sólidos",
			dataIndex: "alcaldia",
			key: "alcaldia",
			width: 200,
			sorter: (a, b) => a.alcaldia - b.alcaldia,
			render: (id, record, index) => (
				<div className="">$ {parseFloat(record.alcaldia).toFixed(2)}</div>
			),
		},
		{
			title: "Total",
			dataIndex: "total",
			key: "total",
			width: 120,
			render: (id, record, index) => (
				<div className="">$ {parseFloat(record.total).toFixed(2)}</div>
			),
			sorter: (a, b) => a.total - b.total,
		},
		// {
		// 	title: "Editar",
		// 	dataIndex: "edit",
		// 	key: "edit",
		// 	align: "center",
		// 	width: 100,
		// 	render: (id, record) => (
		// 		<div className="edit" onClick={() => openEditor(record)}>
		// 			<Edit />
		// 		</div>
		// 	),
		// },
	];
	const openEditor = (item) => {
		setResidencialActive(item);
		const newValues = {
			direccion: item.address,
			numeroCasa: item.name,
			categoria: item.category,
			name: item.name,
			aream2: item.areaM2,
			areav2: parseFloat(item.areaV2).toFixed(2),
			mantenimiento: parseFloat(item.maintenanceFee).toFixed(2),
			construccion: parseFloat(item.constructionFee).toFixed(2),
			residencia: parseFloat(item.residenceFee).toFixed(2),
			alcaldia: parseFloat(item.alcaldia).toFixed(2),
			total: parseFloat(item.total).toFixed(2),
			propiedad: item.houseType,
		};
		form.setFieldsValue(newValues);

		setModalResidencialVisible(true);
	};
	const onHandleGuardarResidencial = async () => {
		const values = await form.validateFields();
		if (values.numeroCasa === "") {
			toast.warn("Debe ingresar número de casa");
			return;
		} else if (values.categoria === "") {
			toast.warn("Debe ingresar categoría");
			return;
		} else if (values.aream2 === "") {
			toast.warn("Debe ingresar area m2");
			return;
		} else if (values.areav2 === "") {
			toast.warn("Debe ingresar area v2");
			return;
		} else if (values.mantenimiento === "") {
			toast.warn("Debe ingresar cuota de mantenimiento");
			return;
		} else if (values.construccion === "") {
			toast.warn("Debe ingresar cuota de construccion " + values.construccion);
			return;
		} else if (values.residencia === "") {
			toast.warn("Debe ingresar cuota de residencia");
			return;
		} else if (values.alcaldia === "") {
			toast.warn("Debe ingresar cuota de alcaldia");
			return;
		} else if (values.total === "") {
			toast.warn("Debe ingresar total a pagar");
			return;
		} else if (values.houseType === "") {
			toast.warn("Debe ingresar el tipo de propiedad");
			return;
		} else {
			var dataCasa = {
				name: values.numeroCasa + "",
				address: values.direccion,
				areaM2: values.aream2,
				areaV2: values.areav2,
				category: values.categoria,
				maintenanceFee: values.mantenimiento,
				constructionFee: values.construccion,
				residenceFee: values.residencia,
				total: values.total,
				alcaldia: values.alcaldia,
				houseType: values.propiedad,
			};
			//ARREGAR BUG ID
			var path = `/residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/${id}/houses/${residencialActive.id}`;

			handleSendingResidencial(true);
			dispatch(saveResidencial(dataCasa, path, residencialActive.id));
			setModalResidencialVisible(false);
			handleSendingResidencial(false);
			reload(ind);
		}
	};

	return (
		<>
			<Table
				className="primary-table"
				dataSource={[...houseDataFull]}
				columns={columnsCasa}
				pagination={{ pageSize: 20, simple: true }}
				rowKey="id"
				scroll={{ x: 2110, y: "calc(100vh - 19em)" }}
			/>
			{/* MODAL USUARIO */}
			<UserProfileModal
				userActive={userActive}
				userModalActive={userModalActive}
				handleStatusResidentsModal={handleStatusResidentsModal}
			/>

			{/* MODAL PARA EDITAR CASA */}
			{residencialActive ? (
				<Modal
					className="primary-modal"
					title={"RESIDENCIAL"}
					visible={modalResidencialVisible}
					onCancel={() => {
						setModalResidencialVisible(false);
					}}
					footer={
						<Row>
							<Col span={6} style={{ textAlign: "left" }}></Col>

							<Col span={12} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									loading={sendingResidencial}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "100%" }}
									onClick={() => {
										onHandleGuardarResidencial();
									}}
								>
									{loadSendData ? (
										<div className="centerSpinner">
											{" "}
											<Spinner />
										</div>
									) : (
										"GUARDAR"
									)}
								</Button>
							</Col>
						</Row>
					}
				>
					{
						<Row className="section-modal">
							<Col span={24}>
								<Form
									form={form}
									layout="vertical"
									requiredMark={true}
									onValuesChange={async (changedValues, allValues) => {
										const mantenimiento = parseFloat(
											allValues.mantenimiento
										).toFixed(2);
										const construccion = parseFloat(
											allValues.construccion
										).toFixed(2);
										const residencia = parseFloat(allValues.residencia).toFixed(
											2
										);
										const alcaldia = parseFloat(allValues.alcaldia).toFixed(2);
										const values = [
											parseFloat(mantenimiento).toFixed(2),
											parseFloat(construccion).toFixed(2),
											parseFloat(residencia).toFixed(2),
											parseFloat(alcaldia).toFixed(2),
										];
										const mixedSum = (arr = []) => {
											let sum = 0;
											for (let i = 0; i < arr.length; i++) {
												const el = parseFloat(arr[i]).toFixed(2);
												sum += +el;
											}
											return parseFloat(sum).toFixed(2);
										};
										let total = mixedSum(values);
										form.setFieldsValue({ total: total });

									}}
								>
									<Form.Item
										label={<span>Dirección:</span>}
										name="direccion"
										className="form-group"
									>
										<TextArea
											rows={4}
											size="large"
											className="inputFormControl"
										/>
									</Form.Item>

									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Número Casa:</span>}
												name="numeroCasa"
												className="form-group"
												rules={[
													{ required: true, message: "Ingrese número de casa" },
												]}
											>
												<Input size="large" className="inputFormControl" />
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Categoría:</span>}
												name="categoria"
												className="form-group"
												rules={[
													{ required: true, message: "Ingrese categoría" },
												]}
											>
												<InputNumber
													size="large"
													min={0}
													max={100}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Area M2:</span>}
												name="aream2"
												className="form-group"
												rules={[{ required: true, message: "Ingrese Area M2" }]}
											>
												<InputNumber
													size="large"
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Area V2:</span>}
												name="areav2"
												className="form-group"
												rules={[{ required: true, message: "Ingrese Area V2" }]}
											>
												<InputNumber
													size="large"
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Cuota de Mantenimiento:</span>}
												name="mantenimiento"
												className="form-group"
												rules={[
													{
														required: true,
														message: "Ingrese cuota mantenimiento",
													},
												]}
											>
												<InputNumber
													size="large"
													precision={2}
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Cuota Construcción:</span>}
												name="construccion"
												className="form-group"
												rules={[
													{
														required: true,
														message: "Ingrese cuota mantenimiento",
													},
												]}
											>
												<InputNumber
													size="large"
													precision={2}
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Cuota de Residencia:</span>}
												name="residencia"
												className="form-group"
												rules={[
													{
														required: true,
														message: "Ingrese cuota residencia",
													},
												]}
											>
												<InputNumber
													size="large"
													precision={2}
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Alcaldia / Recoleccion basura:</span>}
												name="alcaldia"
												className="form-group"
												rules={[
													{ required: true, message: "Ingrese alcaldia" },
												]}
											>
												<InputNumber
													size="large"
													precision={2}
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={24}>
											<Form.Item
												label={<span>Total a pagar:</span>}
												name="total"
												className="form-group"
												rules={[
													{ required: true, message: "Ingrese total a pagar" },
												]}
											>
												<InputNumber
													size="large"
													disabled
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={20}>
										<Col span={24}>
											<Form.Item
												label={
													<span>Seleccione el estado de la propiedad:</span>
												}
												name="propiedad"
												className="form-group"
												rules={[
													{
														required: true,
														message: "Ingrese el estado de la propiedad",
													},
												]}
											>
												<Radio.Group>
													<Space>
														<Radio value={"Lote"}>Lote</Radio>
														<Radio value={"Residencia"}>Residencia</Radio>
														<Radio value={"Construccion"}>Construcción</Radio>
													</Space>
												</Radio.Group>
											</Form.Item>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					}
				</Modal>
			) : null}
		</>
	);
};
