import React from "react";
import {
	Table,
} from "antd";
import { useEffect, useState } from "react";
import { getColumnSearchProps } from "../../../../utils/tables";
import { getMembersHelper } from "../../../../helpers/membersBoardHelper";
import {
	getQueryDocByCollection,
	getUserDataProfileById,
} from "../../../../helpers/globalHelper";
import { Link } from "react-router-dom";
import { getDataByNomenclatureIdAndHouseIdHelper } from "../../../../helpers/nomenclaturasHelper";
import UserProfileModal from "../../../../components/UserProfileModal";
import { shape15 } from "../../../../assets/icons";
import { shape08 } from "../../../../assets/images";
export const BoardDirectorsTab = () => {
	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};

	const [members, setMembers] = useState([]);
	const [profileMembers, setProfileMembers] = useState([]);
	const [userProfileModalActive, setUserProfileModalActive] = useState(false);
	const [userProfileActive, setUserProfileActive] = useState([]);

	useEffect(() => {
		const getMembers = async () => {
			const allMembers = await getMembersHelper("Yd5MV7jkA0MjQeCmzKrZ").then(
				(result) => setMembers(result)
			);
		};
		getMembers();
	}, []);

	useEffect(() => {
		const mapData = members.map(async (item, index) => {
			let data = { ...item };
			const addData = await getUserDataProfileById(data.profile_id).then(
				(result) => {
					data.profileData = result;
				}
			);
			/* data.profile = (
				<img
					className="iconPerfil"
					src="/assets/icons/shape08.svg"
					style={{ cursor: "pointer" }}
					alt="Topia"
					onClick={() => handleProfileModal(true, index)}
				/>
			); */
			data.activity = (
				<Link to={`/reporte-actividad/${data.profile_id}`}>
					<img
						src={shape15}
						style={{ cursor: "pointer" }}
						alt="blue-report-icon"
					/>
				</Link>
			);
			return data;
		});

		Promise.all(mapData).then((data) => {
			setProfileMembers(data);
		});
	}, [members]);

	const handleProfileModal = async (statusModal, record) => {
		if (record != null) {
			let active_user = record.profileData;
			if (active_user.ownerHome) {
				// Agregamosal detalle los dependientes de los socios
				let relativesWithAccess = await getQueryDocByCollection(
					"/family_access",
					"idProfileMain",
					"==",
					active_user.id
				);
				active_user = {
					...active_user,
					relativesWithAccess: relativesWithAccess,
				};
				/* if (active_user.origin_fam) {
				const billingDataHistory = await getPaymentsHelperByAccount(
					active_user.origin_fam
				).then((billingD) => {
					active_user.billingData = billingD;
				});
			} */
				if (active_user.residentials) {
					let nomenclaturePath = active_user.residentials[0]?.house["path"];
					nomenclaturePath = nomenclaturePath.substring(47, 67);

					let housePath = active_user.residentials[0]?.house["path"];
					housePath = housePath.substring(75, 95);

					const houseData = await getDataByNomenclatureIdAndHouseIdHelper(
						"Yd5MV7jkA0MjQeCmzKrZ",
						nomenclaturePath,
						housePath
					).then((houseD) => {
						active_user.houseData = houseD;
					});
				}

				setUserProfileActive(active_user);
			}
		}

		setTimeout(() => {
			setUserProfileModalActive(statusModal);
		}, 50);
	};

	const boardDirectosData = [];
	const columns = [
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			width: "50%",
			render: (text, record) => (
				<>
					{record.name} {record.lastname}
				</>
			),
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("name"),
		},
		{
			title: "Teléfono",
			dataIndex: "phone",
			key: "phone",
			render: (text, record) => <>{record.profileData.phone}</>,
			width: "20%",
		},
		{
			title: "Posición",
			dataIndex: "position_name",
			key: "position_name",
			width: "20%",
		},
		{
			title: "Perfil",
			dataIndex: "profile",
			key: "profile",
			width: "15%",
			render: (text, record) => (
				<>
					<img
						className="iconPerfil"
						src={shape08}
						style={{ cursor: "pointer" }}
						alt="credential-security-icon"
						onClick={() => handleProfileModal(true, record)}
					/>
				</>
			),
		},
		{
			title: "Actividad",
			dataIndex: "activity",
			key: "activity",
			width: "15%",
		},
	];
	return (
		<>
			<Table
				className="primary-table"
				pagination={{ defaultPageSize: 15, simple: true }}
				columns={columns}
				dataSource={profileMembers}
				loading="true"
				rowKey="id"
				locale={locale}
			/>
			<UserProfileModal
				userActive={userProfileActive}
				userModalActive={userProfileModalActive}
				handleStatusResidentsModal={handleProfileModal}
			/>
		</>
	);
};
