import { Navigate } from "react-router-dom"
import useCurrentLocation from "../../hooks/useCurrentLocation"
import MainManagmentAmenities from "./components/MainManagmentAmenities/MainManagmentAmenities"
import NewAmenity from "./components/NewAmenity/NewAmenity"
import { ManagmentAmenitiesProvider } from "./context/ManagmentAmenitiesContext"
import './styles.css'

//Componente principal que funciona para enrutar y proporcionar un contexto a varias páginas  renderizando un componente u otro dependiendo si en los parámetros de la ruta viene un valor o no
const ManagmentAmenitiesPage = () => {

  const { param } = useCurrentLocation()

  const pages = { //Se define las páginas a utilizar según el valor del amenityId, dicho valor debe coincidir con la clave del objeto
    new: <NewAmenity />,
    edit: <NewAmenity />,
  }

  const renderComponent = () => {
    if (param?.amenityId) { 
      //Si viene un parámetro verifica si existe esa página en el objeto y si es así la retorna, si no redirigirá a la ruta de la página inicial del flujo
      if (pages[param?.amenityId]) return pages[param.amenityId]
      return <Navigate to={'/settings/managment-amenities'} />
    }
    //Si no viene un parámetro entonce se renderiza la página inicial del flujo
    return <MainManagmentAmenities />
  }

  return (
    <ManagmentAmenitiesProvider>
      {renderComponent()}
    </ManagmentAmenitiesProvider>
  )
}

export default ManagmentAmenitiesPage