import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAlerts } from "../../../store/alerts";
import { ContainerTabs } from "./components/ContainerTabs";

export const AlertsReceivedPage = () => {
	const [loadingBt, setLoadBt] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(23, 59, 59, 0));

		dispatch(getAlerts(fechaInicio, fechaFinal));
		setLoadBt(true);
	}, [dispatch]);

	const buscarFechas = async (fechaInicio, fechaFin) => {
		setLoadBt(true);
		dispatch(getAlerts(fechaInicio, fechaFin));
		setLoadBt(false)
	};

	return (
		<>
			<div className="wrapper-page">
				<Row>
					<Col span={24}>
						<ContainerTabs
							buscarFechas={buscarFechas}
							loadingBt={loadingBt}
						></ContainerTabs>
					</Col>
				</Row>
			</div>
		</>
	);
};
