import { useEffect } from 'react'
import { Button } from 'antd'
import { CustomNavigate } from '../../../../../UI/CustomNavigate'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ReactComponent as ExportIcon } from '../../../assets/exportReportIcon.svg'
import { Navigate } from 'react-router-dom'
import CustomTable from '../../../../../UI/CustomTable/CustomTable'
import useWorkers from '../../../hooks/useWorkers/useWorkers'
import PDFReport from './components/PDFReport'
import './styles.css'

export const Workers = () => {

  const { columns, loading, workersTable, historyAccessTable, access_date, getInitialData, handleChangeTable } = useWorkers()

  useEffect(() => {
    getInitialData()
    return () => {

    }
  }, []);

  //Si no hay un objeto activo que proviene de la pantalla de Historial de Accesos, se retorna a dicha pantalla inicial de Monitoreo de contratista
  if (!Object.keys(historyAccessTable.active).length || !access_date) return <Navigate to={'/access/monitoring-contrators'} />

  return (
    <div className="section-wrapper">
      <CustomNavigate title={<span>TRABAJADORES: <span style={{ color: '#0D9C7A' }}>{historyAccessTable.active.accessDate}</span> </span>}>
        <PDFDownloadLink document={<PDFReport data={{ table: workersTable.dataTable, general: historyAccessTable.active }} typeReport={'workers'} />} fileName={`REPORTE DE INGRESO Y SALIDAS DE TRABAJADORES - ${historyAccessTable.active.accessDate}`}>
          <Button className='btn-export' icon={<ExportIcon />}>EXPORTAR REPORTE</Button>
        </PDFDownloadLink>
      </CustomNavigate>
      <CustomTable loading={loading} columns={columns} dataSource={workersTable.dataTable} onChange={handleChangeTable} className='custom-table-variant-1 workers-table'
        pagination={{
          pageSize: workersTable.metadata.limit || undefined,
          total: workersTable.metadata.quantity || undefined,
          defaulPageSize: workersTable.metadata.limit || undefined,
          current: workersTable.currentPage || undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          position: ["bottomRight"]
        }} />
    </div>
  )
}

export default Workers