import React from "react";
import { useParams } from "react-router-dom";
import {
	getUserDataProfileById,
	getQueryDocByCollection,
	onCreateOrUpdate,
} from "../../../helpers/globalHelper";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Col,
	Row,
	Button,
	DatePicker,
	Descriptions,
	Modal,
	Select,
	Radio,
	Space,
	Form,
	Input,
	Table,
	Switch,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import {
	getAccessHistorialByProfileIdAndDate,
	getScheduleVisitorsByUserHelper,
} from "../../../helpers/accessHelper";
import "./style.css";
import { UserProfileContainerTabs } from "./components/UserProfileContainerTabs";
import moment from "moment";
import {
	getAllPaymentsHelper,
	getArchivedPaymentsHelper,
	getMoraByProfileIdHelper,
	getPaymentsHelperByAccount,
	getPaymentsHelperByDateAndProfileId,
	uploadArchivedPaymentsHelper,
} from "../../../helpers/paymentsHelper";
import { getDataByNomenclatureIdAndHouseIdHelper } from "../../../helpers/nomenclaturasHelper";
import UserProfileModal from "../../../components/UserProfileModal";

import { getAlertsByProfileId, getAlertsByUserId } from "../../../store/alerts";
import { getColumnSearchProps } from "../../../utils/tables";
import * as XLSX from "xlsx";
import { getHouseById } from "../../../helpers/adminCobrosHelper";
import { getCardsByProfileIdHelper } from "../../../helpers/cardsHelper";
import { toast } from "react-toastify";
import { extractNumberFromString } from "../../../helpers/generalHelper";
import { shape08, shape17, shape19, telefonoNegro } from "../../../assets/images";
import { calendario, reporte } from "../../../assets/icons";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const UserProfilePage = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [loadingBt, setLoadBt] = useState(false);
	const [profileModalActive, setProfileModalActive] = useState(false);
	const [accountStatementModalActive, setAccountStatementeModalActive] =
		useState(false);
	const [accountActive, setAccountActive] = useState([]);
	const [user, setUser] = useState({});
	const [userProfileActive, setUserProfileActive] = useState([]);
	const [permissionData, setPermissionData] = useState([]);
	const [userHistoricalData, setUserHistoricalData] = useState([]);
	const [paymentData, setPaymentData] = useState([]);
	const [cardData, setCardData] = useState([]);
	const [moras, setMoras] = useState([]);
	const [loadingNewCard, setLoadingNewCard] = useState(false);
	const [newCardModalActive, setNewCardModalActive] = useState(false);
	const [shouldReloadCards, setShouldReloadCards] = useState(false);
	const newFormRef = useRef();
	const [Cname, setCname] = useState("");
	const [val, setVal] = useState("");
	const [cvv, setCvv] = useState("");
	const [exp, setExp] = useState("");
	const [statementPayments, setStatementPayments] = useState([]);
	const [showOldPayments, setShowOldPayments] = useState(false);
	const [fileName, setFileName] = useState("");
	const [fileData, setFileData] = useState([]);
	const [loadingPaymentUpload, setLoadingPaymentUpload] = useState(false);
	const [archivedPaymentsModalActive, setArchivedPaymentsModalActive] =
		useState(false);

	const IconButtonReport = () => <img src="/assets/icons/shape25.svg" />;

	//INICIO VARIABLES PARA FECHA AHORA
	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(23, 59, 59, 0));

		let history = getAccessHistorialByProfileIdAndDate(
			id,
			fechaInicio,
			fechaFinal
		).then((userHistoricalData) => setUserHistoricalData(userHistoricalData));

		let permData = getScheduleVisitorsByUserHelper(
			"Yd5MV7jkA0MjQeCmzKrZ", //cambiar a la id residencial
			id, //User_id que tiene eventos
			fechaInicio,
			fechaFinal
		).then((permissionData) => setPermissionData(permissionData));

		let payData = getPaymentsHelperByDateAndProfileId(
			"Yd5MV7jkA0MjQeCmzKrZ",
			fechaInicio,
			fechaFinal,
			id
		).then((result) => setPaymentData(result));

		searchCards(id);

		let mora = getMoraByProfileIdHelper(id, "Yd5MV7jkA0MjQeCmzKrZ").then(
			(result) => setMoras(result)
		);

		setLoadBt(true);
	}, []);

	const searchCards = (id) => {
		let cardData = getCardsByProfileIdHelper(id).then((result) =>
			setCardData(result)
		);
		return;
	};
	useEffect(() => {
		searchCards(id);
		setShouldReloadCards(false);
	}, [shouldReloadCards]);

	//BUSCA FECHA INICIO
	const buscarFechas = async (fechaInicio, fechaFin) => {
		setLoadBt(true);
		let history = getAccessHistorialByProfileIdAndDate(
			"Yd5MV7jkA0MjQeCmzKrZ", //ID que tiene historial de salidas, cambiar a id
			fechaInicio,
			fechaFin
		).then((userHistoricalData) => setUserHistoricalData(userHistoricalData));
		let permData = getScheduleVisitorsByUserHelper(
			"Yd5MV7jkA0MjQeCmzKrZ", //cambiar a la id residencial
			id, //User_id que tiene eventos
			fechaInicio,
			fechaFin
		).then((permissionData) => setPermissionData(permissionData));
		let payData = getPaymentsHelperByDateAndProfileId(
			"Yd5MV7jkA0MjQeCmzKrZ",
			fechaInicio,
			fechaFin,
			id
		).then((result) => setPaymentData(result));

		dispatch(getAlertsByUserId(fechaInicio, fechaFin, id));
	};

	//PARA DISPATCH ALERT
	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(23, 59, 59, 0));

		dispatch(getAlertsByUserId(fechaInicio, fechaFinal, id));
	}, [user]);

	useEffect(() => {
		let profile = getUserDataProfileById(id).then((user) => setUser(user));
	}, []);

	const countMoras = (moras) => {
		let total = 0;
		if (moras) {
			moras.map(async (item, index) => {
				total += +parseFloat(item.amount).toFixed(2);
			});
		}
		return total;
	};
	const handleNewCardModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setNewCardModalActive(statusModal);
		}, 50);
	};

	const handleProfileModal = async (statusModal, index = null) => {
		let active_user = user;
		if (active_user.ownerHome) {
			// Agregamosal detalle los dependientes de los socios
			let relativesWithAccess = await getQueryDocByCollection(
				"/family_access",
				"idProfileMain",
				"==",
				active_user.id
			);
			active_user = {
				...active_user,
				relativesWithAccess: relativesWithAccess,
			};

			if (active_user.residentials) {
				let nomenclaturePath = active_user.residentials[0]?.house["path"];
				nomenclaturePath = nomenclaturePath.substring(47, 67);

				let housePath = active_user.residentials[0]?.house["path"];
				housePath = housePath.substring(75, 95);

				const houseData = await getDataByNomenclatureIdAndHouseIdHelper(
					"Yd5MV7jkA0MjQeCmzKrZ",
					nomenclaturePath,
					housePath
				).then((houseD) => {
					active_user.houseData = houseD;
				});
			}

			setUserProfileActive(active_user);
		}

		setTimeout(() => {
			setProfileModalActive(statusModal);
		}, 50);
	};
	const handleAccountStatementModal = async (statusModal, index = null) => {
		let active_user = user;

		let dateEnd = new Date();
		var date_end = new Date(dateEnd.setHours(23, 59, 59, 0));

		const dateString = active_user.created_at;
		const dateParts = dateString.split("-");
		const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

		const payments = await getPayments(
			active_user.id,
			"Yd5MV7jkA0MjQeCmzKrZ",
			date,
			date_end
		);
		active_user.payments = [];
		const sortedPayments = separatePayments(payments);
		active_user.payments = [...sortedPayments];

		active_user.houseData = await getHouse(active_user.residentials);

		setAccountActive(active_user);
		setTimeout(() => {
			setAccountStatementeModalActive(statusModal);
		}, 50);
	};

	useEffect(() => {
		getArchivedPayments();
	}, []);

	const getArchivedPayments = async () => {
		const newData = await getArchivedPaymentsHelper("Yd5MV7jkA0MjQeCmzKrZ", id);
		await Promise.all(newData);

		setStatementPayments(newData);
	};

	const accountColumns = [
		{
			title: "Fecha",
			dataIndex: "invoice_date",
			key: "invoice_date",
			width: "9%",
			render: (text, record) => (
				<>{record.isMora ? record.payment_date : record.created_at}</>
			),
		},
		{
			title: "Tipo",
			dataIndex: "movtype",
			key: "movtype",
			width: "9%",
			render: (text, record) => <>{record.type}</>,
		},
		{
			title: "Documento / Abono",
			dataIndex: "id",
			key: "id",
			width: "25%",
			...getColumnSearchProps,
		},
		{
			title: "Concepto / Observaciones",
			dataIndex: "concept",
			key: "concept",
			width: "25%",
			...getColumnSearchProps,
		},
		//amount
		{
			title: "Monto",
			dataIndex: "invoice_total",
			key: "invoice_total",
			width: "8%",
			render: (text, record) => (
				<>{currencyFormat(parseFloat(record.amount).toFixed(2).toString())}</>
			),
		},
		//mora
		{
			title: "Cargo",
			dataIndex: "invoice_total",
			key: "invoice_total",
			width: "8%",
			render: (text, record) => (
				<>{currencyFormat(parseFloat(record.mora).toFixed(2).toString())}</>
			),
		},
		//si es mora tiene que ser 0, sino el pago total
		{
			title: "Abono",
			dataIndex: "payment",
			key: "payment",
			width: "8%",
			render: (text, record) => (
				<>
					{record.isMora
						? "$0.00"
						: currencyFormat(parseFloat(record.amount).toFixed(2).toString())}
				</>
			),
		},
		//si es mora tiene que aparecer el cobro total sino cero
		{
			title: "Saldo",
			dataIndex: "saldo_actual",
			key: "saldo_actual",
			width: "8%",
			render: (text, record) => (
				<>
					{record.isMora
						? currencyFormat(sumPayment(record).toString())
						: "$0.00"}
				</>
			),
		},
	];
	const archivedColumns = [
		{
			title: "Fecha Factura",
			dataIndex: "invoice_date",
			key: "invoice_date",
			width: "8%",
			align: "center",
		},
		{
			title: "Fecha Pago",
			dataIndex: "payment_date",
			key: "payment_date",
			width: "8%",
			align: "center",
		},
		{
			title: "Código Cliente",
			dataIndex: "tuscania_id",
			key: "tuscania_id",
			width: "9%",
			align: "center",
			...getColumnSearchProps,
		},
		{
			title: "Lote",
			dataIndex: "houseNumber",
			key: "houseNumber",
			width: "8%",
			align: "center",
		},
		{
			title: "Tipo",
			dataIndex: "type",
			key: "type",
			width: "9%",
			align: "center",
		},
		{
			title: "Concepto",
			dataIndex: "concept",
			key: "concept",
			width: "10%",
			align: "center",
			...getColumnSearchProps,
		},
		{
			title: "Número Factura",
			dataIndex: "billingNumber",
			key: "billingNumber",
			width: "15%",
			align: "center",
			...getColumnSearchProps,
		},
		{
			title: "Monto",
			dataIndex: "amount",
			key: "amount",
			width: "7%",
			align: "center",
			render: (text, record) => (
				<>{currencyFormat(parseFloat(record.amount).toFixed(2).toString())}</>
			),
		},
		{
			title: "Pago Total",
			dataIndex: "payment_total",
			key: "payment_total",
			width: "7%",
			align: "center",
			render: (text, record) => (
				<>
					{currencyFormat(
						parseFloat(record.payment_total).toFixed(2).toString()
					)}
				</>
			),
		},
		{
			title: "Diferencia",
			dataIndex: "diferencia",
			key: "diferencia",
			width: "7%",
			align: "center",
			render: (text, record) => (
				<>
					{record.diferencia
						? currencyFormat(
								parseFloat(record.diferencia).toFixed(2).toString()
						  )
						: "$0.00"}
				</>
			),
		},
		{
			title: "Forma pago",
			dataIndex: "formaPago",
			key: "formaPago",
			width: "10%",
			align: "center",
			...getColumnSearchProps,
		},
		{
			title: "Total diferencia",
			dataIndex: "totalPagar",
			key: "totalPagar",
			width: "7%",
			align: "center",
			render: (text, record) => (
				<>
					{record.totalPagar
						? currencyFormat(
								parseFloat(record.totalPagar).toFixed(2).toString()
						  )
						: "$0.00"}
				</>
			),
		},
	];
	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	const getPayments = async (
		profile_id,
		residential_id,
		date_start,
		date_end
	) => {
		let payments = [];
		const promise = await getAllPaymentsHelper(
			profile_id,
			residential_id,
			date_start,
			date_end
		);
		payments = promise;

		/* payments.push({promise}); */

		await Promise.all(promise);

		return payments;
	};

	const separatePayments = (payments) => {
		const separate = payments.map((item, index) => {
			item.isMora = false;
			if (item.mora > 0) {
				item.isMora = true;
			}
			return item;
		});
		return separate;
	};

	const sumPayment = (payment) => {
		let total = 0;
		let amount = 0;
		let mora = 0;
		amount = parseFloat(payment.amount).toFixed(2);
		mora = parseFloat(payment.mora).toFixed(2);
		total += parseFloat(amount) + parseFloat(mora);
		return total.toFixed(2);
	};

	const sumAllMoras = (payments) => {
		let total = 0;
		let amount = 0;
		let mora = 0;
		if (payments) {
			const sum = payments.map((item, index) => {
				if (item.isMora) {
					mora = parseFloat(item.mora).toFixed(2);
					amount = parseFloat(item.amount).toFixed(2);
				} else {
					mora = 0;
					amount = 0;
				}

				total += parseFloat(amount) + parseFloat(mora);
				return item;
			});
		}

		return total.toFixed(2);
	};

	const getHouse = async (residentials) => {
		let data = [];
		const house = await residentials.map(async (item, index) => {
			if (
				item.idResidential === "Yd5MV7jkA0MjQeCmzKrZ" &&
				item.homePrincipal === true
			) {
				let housePath = item.house.path;
				const startIndex = housePath?.indexOf("nomenclature/") + 13;
				const endIndex = housePath?.indexOf("/", startIndex);
				const nomenclatureId = housePath.substring(startIndex, endIndex);
				const lastIndex = housePath?.lastIndexOf("/");
				const houseId = housePath.substring(lastIndex + 1);
				const houseData = await getDataByNomenclatureIdAndHouseIdHelper(
					"Yd5MV7jkA0MjQeCmzKrZ",
					nomenclatureId,
					houseId
				);
				data.push(houseData);
			}
		});
		await Promise.all(data);

		return data;
	};

	const handleOnExport = (payments) => {
		let data = [];
		payments.map((item, index) => {
			data.push({
				date: item.isMora ? item.payment_date : item.created_at,
				type: item.type,
				id: item.id,
				concept: item.concept,
				amount: currencyFormat(parseFloat(item.amount).toFixed(2).toString()),
				charge: currencyFormat(parseFloat(item.mora).toFixed(2).toString()),
				payment: item.isMora
					? "$0.00"
					: currencyFormat(parseFloat(item.amount).toFixed(2).toString()),
				mora: item.isMora
					? currencyFormat(sumPayment(item).toString())
					: "$0.00",
			});
		});
		let Heading = [
			[
				"Fecha",
				"Tipo",
				"Documento / Abono",
				"Concepto / Observaciones",
				"Monto",
				"Cargo",
				"Abono",
				"Saldo",
			],
		];
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_aoa(ws, Heading);
		XLSX.utils.sheet_add_json(ws, data, {
			origin: "A2",
			skipHeader: true,
		});
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "Estado de cuenta.xlsx");
	};

	const expInputRef = useRef(null);
	const cvvInputRef = useRef(null);

	const onChange = (e) => {
		setVal(clearNumber(e.target.value));
		if (e.target.value.length === e.target.maxLength) {
			expInputRef.current.focus();
		}
	};

	const onChangeName = (e) => {
		setCname(e.target.value.toUpperCase());
	};

	function cc_format(value) {
		const v = clearNumber(value.toString());
		let formatted = "";
		for (let i = 0; i < v.length; i++) {
			if (i % 4 === 0 && i > 0) {
				formatted += " "; // add space every 4 characters
			}
			formatted += v[i];
		}
		return formatted;
	}

	const onFinishNewCardForm = async (e) => {
		setLoadingNewCard(true);
		const cardValues = {
			cardName: e.cardName.toUpperCase(),
			numbers: e.cardNumbers.replace(/\s/g, ""),
			exp: formatCardDate(e.expDate),
			cvv: e.cvv,
		};
		const addCard = await saveCard(cardValues, id);

		setCname("");
		setVal("");
		setCvv("");
		setExp("");
		newFormRef.current.resetFields();
		setLoadingNewCard(false);
		handleNewCardModal(false);
		searchCards(id);
	};

	const saveCard = async (cardArray, id) => {
		const url = "https://domodev.topiaapp.com/api/guardar-tarjeta";
		const data_raw = JSON.stringify({
			profileID: id,
			cardName: cardArray.cardName,
			tarjeta: cardArray.numbers,
			vencimiento: cardArray.exp,
			cc_ccv: cardArray.cvv,
		});

		const requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: data_raw,
		};

		const res = await fetch(url, requestOptions);
		const data = await res.json();
		if (data.status === "success") {
			searchCards(id);
			return data;
		} else {
			searchCards(id);
			return data;
		}
	};

	const formatCardDate = (exp) => {
		const oldExp = exp.replace("/", "");
		const newExp = oldExp.slice(2) + oldExp.slice(0, 2);
		return newExp;
	};

	function exp_format(value) {
		const clearValue = clearNumber(value);

		if (clearValue.length >= 3) {
			return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
		}

		return clearValue;
	}
	const onCvvChange = (e) => {
		setCvv(clearNumber(e.target.value));
	};

	const onExpChange = (e) => {
		setExp(e.target.value);
		if (e.target.value.length === e.target.maxLength) {
			cvvInputRef.current.focus();
		}
	};

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const configExp = {
		rules: [
			{ required: true, message: "Por favor llenar el campo" },
			{ len: 5, message: "Por favor completar el campo" },
		],
	};
	const configCvv = {
		rules: [
			{ required: true, message: "Por favor llenar el campo" },
			{ len: 3, message: "Por favor completar el campo" },
		],
	};

	function cvv_format(value) {
		const numberValue = clearNumber(value);
		return numberValue;
	}

	function clearNumber(value = "") {
		return value.replace(/\D+/g, "");
	}

	const initialValues = {
		prefered_payment: [
			{
				value: "mantenimiento",
				label: "Mantenimiento",
			},
		],
	};

	const handlePaymentSelectChange = async (value, data) => {
		/* if (value === "recents") {
			setStatementPayments(data);
		} else if (value === "archived") {
			const newData = await getArchivedPaymentsHelper("Yd5MV7jkA0MjQeCmzKrZ",id)
			await Promise.all(newData);

			setStatementPayments(newData);
		} */
		if (value === "recents") {
			setShowOldPayments(false);
		} else if (value === "archived") {
			setShowOldPayments(true);
		}
	};

	const handleNewPaymentsModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setArchivedPaymentsModalActive(statusModal);
		}, 50);
	};

	const handleExcel = async (e) => {
		/* const file = e.target.files[0];
		setFileName(file.name);
		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data);

		const worksheet = workbook.Sheets[workbook.SheetNames[0]];
		const jsonData = XLSX.utils.sheet_to_json(worksheet, {
			raw: false,
		}); */
		/* const file = e.target.files[0];
		setFileName(file.name);
		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data);

		const worksheet = workbook.Sheets[workbook.SheetNames[0]];
		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e.r -= 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);

		const jsonData = XLSX.utils.sheet_to_json(worksheet, {
			raw: false,
		}); */
		const file = e.target.files[0];
		setFileName(file.name);
		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data);

		const worksheet = workbook.Sheets[workbook.SheetNames[0]];

		const range = XLSX.utils.decode_range(worksheet["!ref"]);

		const columnIndexToIgnore = XLSX.utils.decode_col("O") - 1;

		range.e.c -= 1;
		range.e.r -= 1;

		for (let row = range.s.r; row <= range.e.r; row++) {
			for (let col = range.s.c; col <= range.e.c; col++) {
				if (col === columnIndexToIgnore || row === range.e.r) {
					const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
					delete worksheet[cellAddress];
				}
			}
		}

		worksheet["!ref"] = XLSX.utils.encode_range(range);

		const jsonData = XLSX.utils.sheet_to_json(worksheet, {
			raw: false,
		});
		let renamedData = [];
		jsonData.map((item, index) => {
			renamedData.push({
				idClient: item["CODIGO_CLIENTE"],
				name: item["NOMBRE"],
				houseNumber: item["LOTE"],
				concept: item["CONCEPTO"],
				service: item["Servicio"],
				date_invoice: item["FECHA_FACTURA"],
				billingNumber: item["N_FACTURA"],
				amount: item["MONTO"],
				total: item["TOTAL"],
				date_payment: item["FECHA_PAGO"],
				amount_payment: extractNumberFromString(item["MONTO_PAGO"]),
				difference: item["DIFERENCIA"],
				method: item["FORMA_PAGO"],
				final_amount: item["TOTAL_PAGAR"],
			});
		});
		setFileData(renamedData);
	};

	const onFinishArchivedPaymentsForm = async () => {
		setLoadingPaymentUpload(true);

		const userId = id;
		await uploadArchivedPaymentsHelper(fileData, userId);
		toast.success("Pagos agregados correctamente");
		getArchivedPayments();
		setLoadingPaymentUpload(false);
		handleNewPaymentsModal(false);
		setFileData([]);
		setFileName("");
	};

	return (
		<>
			<div>
				<div className="wrapper-page">
					<h2 className="title-page">
						{user.name} {user.lastname}
						<img
							className="iconPerfil"
							src={shape08}
							alt="credential-security-icon"
							style={{ marginLeft: "15px", cursor: "pointer" }}
							onClick={() => handleProfileModal(true)}
						/>
					</h2>

					<Row className="profile-user-data">
						<Col span={8}>
							<div className="profile-user-data-container">
								<div>
									<span className="title" style={{ fontSize: "14px" }}>
										<img
											src={shape17}
											alt="gray-keys-icon"
											style={{ marginRight: "5px" }}
										/>
										Tipo de socio:{" "}
										{user.residentials
											? user.residentials[0].type === "P"
												? "Propietario"
												: "N/A"
											: "N/A"}
									</span>
								</div>
								<div style={{ paddingTop: "25px" }}>
									<span className="title" style={{ fontSize: "14px" }}>
										<img
											src={calendario}
											alt="calendar-icon"
											style={{ marginRight: "5px" }}
										/>
										Socio desde: {moment(user.updated_at).format("YY/MM/D")}
									</span>
								</div>
								<div>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
											marginTop: "20px",
										}}
										shape="round"
										onClick={() => handleNewCardModal(true)}
									>
										<span
											className="title"
											style={{ marginLeft: "25px", marginRight: "25px" }}
										>
											{" "}
											AGREGAR TARJETA
										</span>
									</Button>
								</div>
							</div>
						</Col>
						<Col span={8}>
							<div className="profile-user-data-container">
								<div>
									<span className="title" style={{ fontSize: "14px" }}>
										<img
											src={telefonoNegro}
											alt="black-phone-icon"
											style={{
												marginLeft: "2px",
												marginRight: "7px",
												color: "black",
											}}
										/>
										Teléfono: {user.phone ? user.phone : "N/A"}
									</span>
								</div>
								<div style={{ paddingTop: "25px" }}>
									<span className="title" style={{ fontSize: "14px" }}>
										<img
											src={shape19}
											alt="car-icon"
											style={{ marginRight: "5px" }}
										/>
										Placa de auto: {user.vehicle ? user.vehicle : "N/A"}
									</span>
								</div>
							</div>
						</Col>
						<Col span={8}>
							<div className="profile-user-data-container">
								<div>
									<span
										className="user-name"
										style={{ borderBottom: "dashed", borderWidth: "1px" }}
									>
										COBROS:
									</span>
								</div>
								<div style={{ paddingTop: "15px" }}>
									<span className="title" style={{ fontSize: "14px" }}>
										Pagos en mora:{" "}
										{currencyFormat(countMoras(moras).toFixed(2).toString())}
									</span>
									<img
										src={reporte}
										alt="report-icon"
										style={{ marginLeft: "5px", cursor: "pointer" }}
										onClick={() => handleAccountStatementModal(true)}
									/>									
								</div>
								<div style={{ paddingTop: "10px" }}>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
											marginTop: "20px",
										}}
										shape="round"
									>
										<span
											className="title"
											style={{ marginLeft: "25px", marginRight: "25px" }}
										>
											{" "}
											ENVIAR RECORDATORIO
										</span>
									</Button>
								</div>
							</div>
						</Col>
					</Row>

					{/* MODAL PARA GUARDAR TARJETAS */}
					<Modal
						className="primary-modal"
						visible={newCardModalActive}
						onCancel={() => handleNewCardModal(false)}
						footer={null}
						width={550}
					>
						<>
							<Row className="section-modal" style={{ textAlign: "center" }}>
								<Col span={24}>
									<h2 style={{ color: "#232C4A" }}>NUEVA TARJETA</h2>
								</Col>
							</Row>
							<Row className="section-modal" style={{ marginTop: "1rem" }}>
								<Col
									span={24}
									style={{
										width: "90%",
										paddingLeft: "2rem",
										paddingRight: "2rem",
									}}
								>
									<Form
										ref={newFormRef}
										name="new_card_form"
										onFinish={onFinishNewCardForm}
										initialValues={initialValues}
									>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>NOMBRE EN LA TARJETA:</p>
										</div>
										<Form.Item name="cardName" {...config}>
											<div style={{ textTransform: "uppercase" }}>
												<Input
													size="large"
													value={Cname}
													onChange={onChangeName}
													maxLength={30}
												/>
											</div>
										</Form.Item>
										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>NÚMERO DE LA TARJETA:</p>
										</div>
										<Form.Item name="cardNumbers" {...config}>
											<div>
												<Input
													type="tel"
													id="numInput"
													placeholder="0000 0000 0000 0000"
													size="large"
													value={cc_format(val)}
													onChange={onChange}
													maxLength={19}
												/>
											</div>
										</Form.Item>
										<Row>
											<Col span={12}>
												<p style={{ textAlign: "left" }}>
													FECHA DE EXPIRACIÓN:
												</p>
												<FormItem name="expDate" {...configExp}>
													<div className="small-input">
														<Input
															type="tel"
															ref={expInputRef}
															placeholder="MM / AA"
															size="large"
															value={exp_format(exp)}
															onChange={onExpChange}
															maxLength={5}
														/>
													</div>
												</FormItem>
											</Col>
											<Col span={1}></Col>
											<Col span={11}>
												<p style={{ textAlign: "left" }}>CVV:</p>
												<FormItem
													name="cvv"
													{...configCvv}
													rules={[
														{
															required: true,
															message: "Por favor llenar el campo",
														},
														{
															pattern: /^(?:\d*)$/,
															message: "Solo se aceptan números",
														},
														{
															len: 3,
															message: "Por favor completar el campo",
														},
													]}
												>
													<div className="small-input">
														<Input
															type="tel"
															ref={cvvInputRef}
															id="cvvInput"
															placeholder="000"
															size="large"
															maxLength={3}
															value={cvv_format(cvv)}
															onChange={onCvvChange}
														/>
													</div>
												</FormItem>
											</Col>
										</Row>

										<div
											className="section-modal"
											style={{ paddingLeft: "5px" }}
										>
											<p>TIPO DE PAGO DE PREFERENCIA:</p>
										</div>

										<Form.Item name="prefered_payment" {...config}>
											<Select
												mode="multiple"
												options={[
													{
														value: "mantenimiento",
														label: "Mantenimiento",
													},
													{
														value: "agua",
														label: "Servicio de Agua",
													},
													{
														value: "electricidad",
														label: "Servicio de Electricidad",
													},
												]}
											/>
										</Form.Item>

										<Row>
											<Col span={24} align="middle">
												<Button
													htmlType="submit"
													style={{
														borderColor: "#10b48c",
														background: "#10b48c",
														paddingBottom: "15px",
														width: "250px",
													}}
													shape="round"
													size="large"
													loading={loadingNewCard}
												>
													<span className="title" style={{ color: "#fff" }}>
														GUARDAR TARJETA
													</span>
												</Button>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</>
					</Modal>

					{/* MODAL PARA PERFIL SOCIO */}
					<UserProfileModal
						userActive={userProfileActive}
						userModalActive={profileModalActive}
						handleStatusResidentsModal={handleProfileModal}
					/>
					{/* MODAL PARA ESTADO CUENTA SOCIO */}
					<Modal
						className="side-modal alert-modal modalEncuesta "
						title={
							accountActive ? (
								<>
									<Row
										className="encabezado"
										style={{ textAlign: "left", paddingLeft: "25px" }}
									>
										<Col span={8}>
											<div>NOMBRE DEL RESIDENTE</div>
											<h2>
												{accountActive.name} {accountActive.lastname}
											</h2>
											<Row style={{ marginTop: "2rem" }}>
												<Col span={8}>
													<h6 style={{ fontWeight: "bold" }}>
														Desde:{" "}
														{moment(accountActive.created_at).format("Y-MM-DD")}
													</h6>
												</Col>
												<Col span={8}>
													<h6 style={{ fontWeight: "bold" }}>
														Hasta: {moment(new Date()).format("Y-MM-DD")}
													</h6>
												</Col>
											</Row>
										</Col>
										<Col span={4}>
											<Select
												style={{ width: "150px", marginTop: "1rem" }}
												onChange={(e) => {
													handlePaymentSelectChange(e, accountActive.payments);
												}}
												defaultValue={{
													value: "recents",
													label: "Recientes",
												}}
												options={[
													{
														value: "recents",
														label: "Recientes",
													},
													{
														value: "archived",
														label: "Archivados",
													},
												]}
											/>
										</Col>
										<Col
											span={3}
											style={{ display: "flex", marginTop: "1rem" }}
										>
											<div className="bloqueEncuesta">
												N. CASA:
												<div className="numeroEncuesta">
													{accountActive.houseData?.map((item, index) => {
														return <p key={index}>{item.alias}</p>;
													})}
												</div>
											</div>
										</Col>
										<Col
											span={3}
											style={{ display: "flex", marginTop: "1rem" }}
										>
											<div className="bloqueEncuesta">
												SALDO:
												<div className="numeroEncuesta">
													{currencyFormat(
														sumAllMoras(accountActive.payments).toString()
													)}
												</div>
											</div>
										</Col>
										<Col
											span={6}
											style={{
												display: "flex",
												marginTop: "1rem",
											}}
										>
											<div className="bloqueEncuesta">
												{accountActive.payments ? (
													<Button
														style={{
															color: "#10b48c",
															borderColor: "#10b48c",
															background: "white",
															paddingBottom: "15px",
														}}
														shape="round"
														icon={<IconButtonReport />}
														size="large"
														onClick={() =>
															handleOnExport(accountActive.payments)
														}
													>
														<span
															className="title"
															style={{ marginLeft: "10px" }}
														>
															Exportar estado de cuenta
														</span>
													</Button>
												) : null}
												<br />
												<br />
												<Button
													style={{
														color: "#10b48c",
														borderColor: "#10b48c",
														background: "white",
														paddingBottom: "15px",
													}}
													shape="round"
													size="large"
													onClick={() => handleNewPaymentsModal(true)}
												>
													<span
														className="title"
														style={{ marginLeft: "10px" }}
													>
														Agregar pagos archivados
													</span>
												</Button>
											</div>
										</Col>
										{/* <Col
											span={6}
											style={{ display: "flex", marginTop: "1rem" }}
										>
											<div className="bloqueEncuesta">
												<Button
													style={{
														color: "#10b48c",
														borderColor: "#10b48c",
														background: "white",
														paddingBottom: "15px",
													}}
													shape="round"
													size="large"
													onClick={() => handleNewPaymentsModal(true)}
												>
													<span
														className="title"
														style={{ marginLeft: "10px" }}
													>
														Agregar pagos archivados
													</span>
												</Button>
											</div>
										</Col> */}
									</Row>
								</>
							) : (
								<>
									<Row className="section-modal">
										<Col
											span={24}
											style={{
												textTransform: "uppercase",
												textAlign: "center",
											}}
										>
											<b>Datos de perfil no encontrados</b>
										</Col>
									</Row>
								</>
							)
						}
						style={{ top: 0, right: 0, width: "100%" }}
						visible={accountStatementModalActive}
						onCancel={() => setAccountStatementeModalActive(false)}
						footer={null}
						bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 170px)" }}
						width={"95%"}
					>
						{accountActive ? (
							<div className="scroll">
								<Row>
									<Col span={24}>
										{showOldPayments === false ? (
											<Table
												className="primary-table"
												pagination={{ defaultPageSize: 10, simple: true }}
												columns={accountColumns}
												loading="true"
												rowKey="id"
												bodyStyle={{
													overflowY: "auto",
													maxHeight: "calc(100vh - 100px)",
												}}
												dataSource={accountActive.payments}
											/>
										) : (
											<Table
												className="primary-table"
												pagination={{ defaultPageSize: 10, simple: true }}
												columns={archivedColumns}
												loading="true"
												rowKey="id"
												bodyStyle={{
													overflowY: "auto",
													maxHeight: "calc(100vh - 100px)",
												}}
												dataSource={statementPayments}
											/>
										)}
									</Col>
								</Row>
							</div>
						) : (
							<>
								<Row className="section-modal">
									<Col
										span={24}
										style={{ textTransform: "uppercase", textAlign: "center" }}
									>
										<b>Datos de perfil no encontrados</b>
									</Col>
								</Row>
							</>
						)}
					</Modal>

					<Row>
						<Col span={24}>
							{/* <UserProfilePageData id={id} data={userHistoricalData} /> */}
							<UserProfileContainerTabs
								buscarFechas={buscarFechas}
								loadingBt={loadingBt}
								data={userHistoricalData}
								permissionData={permissionData}
								paymentData={paymentData}
								cardData={cardData}
								setShouldReloadCards={setShouldReloadCards}
							/>
						</Col>
					</Row>
				</div>
			</div>
			{/* MODAL PARA AGREGAR PAGOS */}
			<Modal
				className="primary-modal"
				visible={archivedPaymentsModalActive}
				onCancel={() => handleNewPaymentsModal(false)}
				footer={null}
				width={550}
			>
				<>
					<Row className="section-modal" style={{ textAlign: "center" }}>
						<Col span={24}>
							<h2 style={{ color: "#232C4A" }}>AGREGAR PAGOS ARCHIVADOS</h2>
						</Col>
					</Row>
					<Row className="section-modal" style={{ marginTop: "1rem" }}>
						<Col
							span={24}
							style={{
								width: "90%",
								paddingLeft: "2rem",
								paddingRight: "2rem",
							}}
						>
							<form action="">
								<div className="section-modal" style={{ paddingLeft: "5px" }}>
									<p>SELECCIONAR ARCHIVO EXCEL CON DATOS DE PAGOS:</p>
								</div>
								<input
									required
									className="form-control"
									type="file"
									name="file"
									id="file"
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									onChange={(e) => handleExcel(e)}
									style={{
										backgroundColor: "#F2F2F2",
										width: "0.1px",
										height: "0.1px",
										opacity: "0",
										overflow: "0",
										position: "absolute",
										zIndex: "-1",
									}}
								/>
								<label
									htmlFor="file"
									className="form-control"
									style={{
										backgroundColor: "#F2F2F2",
										textAlign: "center",
										cursor: "pointer",
									}}
								>
									{fileName ? fileName : "Seleccionar archivo excel"}
								</label>

								<br />
								<br />
								<Row>
									<Col span={24} align="middle">
										<Button
											style={{
												borderColor: "#10b48c",
												background: "#10b48c",
												paddingBottom: "15px",
												width: "250px",
											}}
											shape="round"
											size="large"
											loading={loadingPaymentUpload}
											onClick={() => {
												onFinishArchivedPaymentsForm();
											}}
										>
											<span className="title" style={{ color: "#fff" }}>
												AGREGAR PAGOS
											</span>
										</Button>
									</Col>
								</Row>
							</form>
						</Col>
					</Row>
				</>
			</Modal>
		</>
	);
};

export default UserProfilePage;
