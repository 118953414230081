import { useState } from "react"
import { useBookingContext } from "../context/BookingsContext"
import { bookingTableColumnsPrev, bookingsTableFilters, keyColumns } from "../helpers/bookingTableHelper"
import { changeTableParams, getColumnsFilter } from "../../../helpers/customTableHelper"
import { defaulStateTable } from "../constants/defaultStates"
import { useCustomSearch } from "../../../hooks/useCustomTable"
import { filterItems } from "../helpers/arrayHelper"
import CustomTable from "../../../UI/CustomTable/CustomTable"
import useBookingsServices from "./useBookingsServices"
import moment from "moment"

//Custom hook para poder utilizar diferentes funciones en la pantalla inicial de las reserva
const useBookings = () => {
    const { handleReset, handleSearch } = useCustomSearch()
    const { getAllBookingsService, getAllAmenitiesService, getBookingStatusesService, getProfilesByCompanyService } = useBookingsServices()
    const { loading, setLoading, handleRedirectTo } = useBookingContext()
    const [bookingTable, setBookingTable] = useState(structuredClone(defaulStateTable))
    const [bookingStatuses, setBookingStatuses] = useState([])
    const [columnKey, setColumnKey] = useState('received')
    const serviceParams = { reservation_status_code: columnKey, previousData: bookingTable.dataTable, actualLimit: bookingTable.metadata.limit, limit: bookingTable.metadata.limit, currentPage: bookingTable.currentPage, skip: 0 }
    //Función para poder cambiar a la pantalla para poder ver una solicitud de reserva cuando se hace click en el botón de detalles de la table
    const handleBookingDetails = async ({ record }) => {
        handleRedirectTo(`request/${record?.reservation_id}`)
    }

    //Se define el array final de columnas iniciales donde se muestran las columnas para todas la solicitudes
    const initialColumns = bookingTableColumnsPrev({ handleBookingDetails }).map(column => ({
        ...column,
        ...getColumnsFilter(column.dataIndex, bookingsTableFilters({ currentFilters: bookingTable.currentFilters, currentParams: bookingTable.currentParams, handleReset, handleSearch, amenityService: getAllAmenitiesService, profileService: getProfilesByCompanyService }))
    }));

    //Handle para poder cambiar de panel en las tabs, y poder renderizar las columnas respectivas para cada panel
    const handleChangeTabs = async (key) => {
        setLoading(true)
        setColumnKey(key)
        const { data, metadata } = await getAllBookingsService({ ...serviceParams, reservation_status_code: key, date_begin: bookingTable.currentParams.date_begin, date_end: bookingTable.currentParams.date_end, currentPage: 1 })
        setLoading(false)
        setBookingTable(({
            ...structuredClone(defaulStateTable),
            currentParams: {
                ...defaulStateTable.currentParams,
                date_begin: bookingTable.currentParams.date_begin,
                date_end: bookingTable.currentParams.date_end,
            },
            dataTable: data.dataSource,
            metadata,
        }))
    }

    //Handle que se utiliza para poder filtrar las tablas por un rango de fechas, sin importar el panel en que se encuentre
    const handleChangeDatePickerFilter = async (values) => {
        const date_begin = values ? moment(values[0]).format('YYYY-MM-DD') : moment().subtract(2, 'years').format('YYYY-MM-DD')
        const date_end = values ? moment(values[1]).format('YYYY-MM-DD') : moment().add(2, 'years').format('YYYY-MM-DD')
        setLoading(true)
        const statuses = await getBookingStatusesService({ date_begin, date_end })
        const { data, metadata } = await getAllBookingsService({ reservation_status_code: 'received', ...serviceParams, ...bookingTable.currentParams, sort_value: bookingTable.currentParams.sort_by, date_begin, date_end, skip: 0 })
        setLoading(false)
        setBookingStatuses(statuses)
        setBookingTable(prev => ({
            ...prev,
            currentPage: 1,
            dataTable: data.dataSource,
            metadata,
            currentParams: {
                ...prev.currentParams,
                date_begin,
                date_end
            }
        }))
    }

    //Handle para poder cambiar de página de la tabla, aplicar filtros y  sorters 
    const handleChangeTable = async ({ pagination, filters, sorter, tableName }) => {
        const otherParams = { place_id: filters?.place_id?.[0], profile_id: filters?.profile_id?.[0], date_begin: bookingTable?.currentParams?.date_begin, date_end: bookingTable?.currentParams?.date_end, }
        const tableParams = changeTableParams({ serviceParams, pagination, filters, sorter, setState: setBookingTable, state: bookingTable, otherParams, customFilters: true })
        if (!tableParams) return
        setLoading(true)
        //Eliminar estos parametros innecesarios en la petición
        delete tableParams.finalParams.find_value
        delete tableParams.finalParams.find_by
        tableParams.finalParams.sort_value = tableParams.finalParams.sort_by

        //Si el sorter es profile_id, cambiarlo a profile_name ya que es el valor permitido como parámetro de orden
        if (tableParams.finalParams.sort_value === 'profile_id') {
            tableParams.newParams.sort_by = 'profile_name'
            tableParams.finalParams.sort_value = 'profile_name'
        }

        const { data, metadata } = await getAllBookingsService({ ...serviceParams, ...tableParams.finalParams })
        setBookingTable(prev => ({
            ...prev,
            dataTable: data.dataSource,
            metadata,
            currentParams: tableParams.newParams,
            currentFilters: tableParams.actualFilters,
        }))
        setLoading(false)

    }

    //Funcion que se ejecuta al montar el componente para establecer las columnas iniciales de la tabla y datos de la misma junto con su metadata
    const getInitialData = async () => {
        setLoading(true)
        const date_begin = moment().startOf('day').subtract(1, 'year').format('YYYY-MM-DD')
        const date_end = moment().startOf('day').add(1, 'year').format('YYYY-MM-DD')
        const statuses = await getBookingStatusesService({ date_begin, date_end })
        const { data, metadata } = await getAllBookingsService({ reservation_status_code: 'received', ...serviceParams, date_begin, date_end, limit: 10 })
        setBookingStatuses(statuses)
        setBookingTable(({
            ...defaulStateTable,
            currentParams: {
                ...defaulStateTable.currentParams,
                date_begin,
                date_end
            },
            dataTable: data.dataSource,
            metadata
        }))
        setLoading(false)
    }

    //Se define una función que retorna una CustomTable para utilizarse en cada una de los panel de la Tab, cada tabla con un nombre diferente
    const children = (tableName) => (<CustomTable className="custom-table-variant-1 bookings-table" tableName={tableName} onChange={handleChangeTable} columns={filterItems(initialColumns, keyColumns[columnKey])} loading={loading} dataSource={bookingTable.dataTable}
        pagination={{
            pageSize: bookingTable.metadata.limit,
            total: bookingTable.metadata.quantity,
            defaultPageSize: bookingTable.metadata.limit,
            current: bookingTable.currentPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            position: ["bottomRight"]
        }}
    />)

    //Se define el array de "paneles" para ser utilizado en la Tab
    const tabItems = [
        {
            label: `RECIBIDAS (${bookingStatuses.find(status => status.reservation_status_code === 'recieved')?.count ?? 0})`, //Nombre del panel
            key: 'received', //Clave para diferencia la tabla
            children: children('table-received') //Renderizar la tabla
        },
        {
            label: `APROBADAS (${bookingStatuses.find(status => status.reservation_status_code === 'approved')?.count ?? 0})`,
            key: 'approved',
            children: children('table-approved')
        },
        {
            label: `CANCELADAS (${bookingStatuses.find(status => status.reservation_status_code === 'canceled')?.count ?? 0})`,
            key: 'canceled',
            children: children('table-canceled')
        },
    ]

    return { getInitialData, handleChangeTabs, handleChangeDatePickerFilter, tabItems }
}

export default useBookings