import useCurrentLocation from "../../hooks/useCurrentLocation"
import HistoryAccessPage from "./components/HistoryAccess/HistoryAccessPage"
import { MonitoringContrators } from './components/MonitorContrators/MonitoringContrators'
import { MonitoringContratorsContextProvider } from "./contexts/MonitoringContratorsContext"

const MonitoringContratorstPage = () => {

  const { param } = useCurrentLocation()

  const pages = {
    history: <HistoryAccessPage />
  }
  return (
    <MonitoringContratorsContextProvider>
      {param?.contratorId ? pages[param.contratorId] : <MonitoringContrators />}
    </MonitoringContratorsContextProvider>
  )
}

export default MonitoringContratorstPage