import { axiosCreate } from "../../../../../config";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const CONTRATOR_PATH = 'access/by/invitations/report'

export const geAllContratorAccess = async ({
    token,
    company_id,
    residential_id,
    status = 'active',
    limit = 5,
    skip = 0,
    find_by,
    find_value,
    sort_by,
    sort_order,
    date_to,
    date_from
}) => {

    try {
        const params = {
            company_id,
            residential_id,
            status,
            limit,
            skip,
            find_by,
            find_value,
            sort_by,
            sort_order,
            date_to,
            date_from
        }
        
        const res = await axiosCreate.get(`${CONTRATOR_PATH}`, {
            params,
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }

}