import { useEffect, useState, useRef } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Layout, Select, Button, Form, Modal, Upload, message } from "antd";
import { toast } from "react-toastify";
import { Content, Footer } from "antd/lib/layout/layout";
import { AdminCobrosTuscaniaTable } from "./components/AdminCobrosTuscaniaTable";
import { getNomenclaturas } from "../../../store/nomenclaturas";
import { getNomenclaturasCasasHelper } from "../../../helpers/nomenclaturasHelper";
import { getNomenclaturasCasas } from "../../../store/nomenclaturas";
import { getProfileByHouseIdHelper } from "../../../helpers/nomenclaturasHelper";
import ExportHousesExcel from "../configuracionInicial/nomenclatura/components/ExportHousesExcel";
import createPaymentsByExcel from "./services/createPaymentsByExcel";
import moment from "moment";
import FormItem from "antd/lib/form/FormItem";
import { exportSheets } from '../../../assets/icons'
import { shape20 } from '../../../assets/images'
const { Option } = Select;

const AdminCobrosTuscaniaPage = () => {
	const dispatch = useDispatch();
	const [residencialActive, setResidencialActive] = useState(null);
	const [modalResidencialVisible, setModalResidencialVisible] = useState(false);
	const [nomenclaturaFull, setNomenclaturaFull] = useState([]);
	const [shouldReload, setShouldReload] = useState(false);
	const [houseData, setHouseData] = useState([]);
	const [index, setIndex] = useState("");
	const [selectOption, setSelectOption] = useState();
	const [selectHouseDataFull, setSelectHouseDataFull] = useState([]);
	const [isNomenclaturaFullUpdated, setIsNomenclaturaFullUpdated] =
		useState(false);
	const [generatePaymentsModalActive, setGeneratePaymentsModalActive] =
		useState(false);
	const [loading, setLoading] = useState(false);
	const { nomenclaturas } = useSelector((state) => state.nomenclaturas);
	const newFormRef = useRef();
	let userSelectReport = [];
	const [fileList, setFileList] = useState([])

	const { ExportHousesToExcel } = ExportHousesExcel();

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	useEffect(() => {
		dispatch(getNomenclaturas());
		//dispatch( getUser() );
	}, [dispatch]);
	/* useEffect(() => {
		if (shouldReload === true) {
			
		}
	}, [shouldReload]); */
	useEffect(() => {
		getHouses(nomenclaturas);
	}, [nomenclaturas.data]);

	const reload = async (index) => {
		setIsNomenclaturaFullUpdated(false);
		const updatedNomenclaturaFull = await getHouses(nomenclaturas);
		setSelectOption(updatedNomenclaturaFull[index]);
	};

	const handleGeneratePaymentsModal = async (statusModal, count = null) => {
		setTimeout(() => {
			setGeneratePaymentsModalActive(statusModal);
		}, 50);
	};

	const getCurrentMonthInSpanish = () => {
		const months = [
			"Enero",
			"Febrero",
			"Marzo",
			"Abril",
			"Mayo",
			"Junio",
			"Julio",
			"Agosto",
			"Septiembre",
			"Octubre",
			"Noviembre",
			"Diciembre",
		];

		const currentDate = new Date();
		const currentMonth = currentDate.getMonth();
		const monthInSpanish = months[currentMonth];

		return monthInSpanish;
	};

	function getDaysInCurrentMonth() {
		const now = new Date();
		return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
	}

	function DaySelect() {
		const days = getDaysInCurrentMonth();
		const options = [];

		for (let i = 1; i <= days; i++) {
			options.push(
				<Option key={i} value={i}>
					{i}
				</Option>
			);
		}

		return options;
	}
	// Peticion post para generar el pago
	const handleUploadExcel = async () => {
		setLoading(true)
		const formData = new FormData()
		fileList.forEach((file) => formData.append('file', file))

		const response = await createPaymentsByExcel(formData)

		if (response.success) {
			setFileList([])
			toast.success('Pago generado con exito')
			handleGeneratePaymentsModal(false)
			setLoading(false)
			return
		}

		toast.warn((response.data.errors[0].msg) ?? "Error al generar los pagos")
		setLoading(false)
	}

	const props = {
		onRemove: (file) => {
			const index = fileList?.indexOf(file)
			const newFileList = fileList.slice()
			newFileList.splice(index, 1)
			setFileList(newFileList)
		},
		beforeUpload: (file) => {
			const isFileExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

			if (!isFileExcel) {
				message.error(`${file.name} no es un archivo de Excel.`)
				return false
			}

			setFileList([file])
			return false
		},
		fileList,
		maxCount: 1,
	}

	const getHouses = async (nomenclaturas) => {
		let houses = nomenclaturas.data.map(async (item, index) => {
			let data = { ...item };
			let houses = await getNomenclaturasCasasHelper(
				"Yd5MV7jkA0MjQeCmzKrZ",
				nomenclaturas.data[index].id
			).then((houseData) => {
				data.houseData = houseData;
			});

			return data;
		});

		return Promise.all(houses).then((data) => {
			setNomenclaturaFull(data);
			setIsNomenclaturaFullUpdated(true);
			return data;
		});
	};
	const totalHousePay = (houses) => {
		let total = 0;
		if (houses.houseData) {
			houses.houseData.map((item) => {
				total += +parseFloat(item.total).toFixed(2);
			});
		}

		total = parseFloat(total).toFixed(2);
		return total;
	};
	const totalHousePayNomenclature = (nomenclatures) => {
		let total = 0;
		nomenclatures.map(async (item, index) => {
			if (item.houseData) {
				item.houseData.map((item) => {
					total += +parseFloat(item.total).toFixed(2);
				});
			}
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};
	const totalHousesNomenclature = (houseData) => {
		let total = 0;
		houseData.map(async (item, index) => {
			if (item.houseData) {
				total += +item.houseData.length;
			}
		});

		return total;
	};
	const totalHouses = (houseData) => {
		let total = 0;
		if (houseData.houseData) {
			total += +houseData.houseData.length;
		}

		return total;
	};
	nomenclaturaFull.map(async (item, index) => {
		userSelectReport.push(
			<Option key={index} value={index} label={item.name}>
				{item.name}
			</Option>
		);

		return item;
	});
	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	useEffect(() => {
		if (nomenclaturaFull.length > 0 && selectOption) {
			let profile = selectOption.houseData.map(async (item, index) => {
				let data = { ...item };
				if (data.idOwners) {
					const profile = await getProfileByHouseIdHelper(
						data.idOwners[0]
					).then((pro) => {
						data.userProfile = pro;
					});
				}
				return data;
			});
			Promise.all(profile).then((data) => {
				setSelectHouseDataFull(data);
			});
		}
	}, [nomenclaturaFull, selectOption]);

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row className="encabezado">
							<Col span={6}>
								{selectOption ? (
									<>
										<p>Nombre de la zona</p>
										<h1 className="">{selectOption.name}</h1>
									</>
								) : (
									<>
										<h1 className="">Seleccione zona en filtro</h1>
									</>
								)}
							</Col>
							<Col span={4}>
								{userSelectReport ? (
									<Select
										placeholder="Seleccione un filtro"
										style={{ width: "80%", fontSize: "12px" }}
										allowClear
										showSearch
										filterOption={(input, option) => {
											return (
												option?.label
													?.toLowerCase()
													?.indexOf(input.toLowerCase()) >= 0
											);
										}}
										onChange={(index) => {
											setIndex(index);
											setSelectOption(nomenclaturaFull[index]);
										}}
									>
										{userSelectReport}
									</Select>
								) : (
									<p>Datos no encontrados</p>
								)}
							</Col>
							<Col span={3} align="middle">
								{selectOption ? (
									<div className="bloqueEncuesta">
										TOTAL CASAS:
										<div
											className="numeroEncuesta"
											style={{ textAlign: "center" }}
										>
											{totalHouses(selectOption)}
										</div>
									</div>
								) : (
									<div className="bloqueEncuesta">
										TOTAL CASAS:
										<div
											className="numeroEncuesta"
											style={{ textAlign: "center" }}
										>
											{totalHousesNomenclature(nomenclaturaFull)}
										</div>
									</div>
								)}
							</Col>
							<Col span={3} align="middle">
								{selectOption ? (
									<div className="bloqueEncuesta">
										TOTAL A PAGAR:
										<div
											className="numeroEncuesta"
											style={{ textAlign: "center" }}
										>
											{currencyFormat(totalHousePay(selectOption))}
										</div>
									</div>
								) : (
									<div className="bloqueEncuesta">
										TOTAL A PAGAR:
										<div
											className="numeroEncuesta"
											style={{ textAlign: "center" }}
										>
											{currencyFormat(
												totalHousePayNomenclature(nomenclaturaFull).toString()
											)}
										</div>
									</div>
								)}
							</Col>
							<Col span={4}>
								{selectOption ? (
									<div className="bloqueEncuesta">
										<img
											src={exportSheets}
											alt="export-excel"
											style={{ cursor: "pointer" }}
											onClick={() => {
												ExportHousesToExcel(selectHouseDataFull);
											}}
										/>
									</div>
								) : (
									<div className="bloqueEncuesta"></div>
								)}
							</Col>
							<Col span={4}>
								<div className="bloqueEncuesta">
									<Button
										className="btn-primary-radio"
										onClick={() => {
											handleGeneratePaymentsModal(true);
										}}
									>
										<img src={shape20} alt="add-icon" />
										GENERAR PAGOS
									</Button>
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								{selectOption ? (
									<>
										<br />
										<br />
										<AdminCobrosTuscaniaTable
											reload={reload}
											index={index}
											nomenclatura={selectOption.houseData}
											id={selectOption.id}
										/>
									</>
								) : (
									<></>
								)}
							</Col>
						</Row>
					</div>
				</Content>
			</Layout>
			{/* MODAL PARA GENERAR RESULTADOS */}
			<Modal
				className="primary-modal"
				visible={generatePaymentsModalActive}
				onCancel={() => handleGeneratePaymentsModal(false)}
				footer={null}
				width={550}
			>
				<Row justify="center" align="middle">
					<Col span={24}>
						<h2 style={{ textAlign: "center", color: "#232C4A" }}>GENERAR PAGOS</h2>
					</Col>
					<Row justify="center" align="middle" style={{ marginTop: "1rem" }}>
						<Col span={24} style={{ width: "90%", margin: "auto" }}>
							<Upload {...props}>
								<Button icon={<UploadOutlined />}>SELECCIONAR ARCHIVO DE EXCEL</Button>
							</Upload>
							<br />

							<Row justify="center">
								<Button style={{ borderColor: "#10b48c", background: "#10b48c", paddingBottom: "15px", width: "250px" }}
									shape="round"
									size="middle"
									loading={loading}
									disabled={fileList.length === 0}
									onClick={handleUploadExcel}
								>
									<span style={{ color: "#FFFFFF" }}>
										{loading ? 'Subiendo archivo...' : 'GENERAR PAGO'}
									</span>
								</Button>
							</Row>
						</Col>
					</Row>
				</Row>
			</Modal>
		</>
	);
};

export default AdminCobrosTuscaniaPage;
