import { Col, Row, Layout } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import { ContainerTabs } from "./components/ContainerTabs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPayments } from "../../../store/payments/thunks";
import { PaymentFooter } from "./components/PaymentFooter";

export const PaymentsPage = () => {
	const [loadingBt, setLoadBt] = useState(false);
	const dispatch = useDispatch();
	//INICIO VARIABLES PARA FECHA AHORA
	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(24, 0, 0, 0));
		//dispatch
		dispatch(getPayments(fechaInicio, fechaFinal));
		setLoadBt(true);
	}, [dispatch]);

	//BUSCA FECHA INICIO
	const buscarFechas = async (fechaInicio, fechaFin) => {
		setLoadBt(true);
		//dispatch
		dispatch(getPayments(fechaInicio, fechaFin));
	};

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<h2 className="title-page">Pagos Recibidos</h2>

						<Row>
							<Col span={24}>
								<ContainerTabs
									buscarFechas={buscarFechas}
									loadingBt={loadingBt}
								/>
							</Col>
						</Row>
					</div>
				</Content>
				<Footer
					style={{
						textAlign: "center",
						backgroundColor: "#232C4A",
						height: "50%",
						paddingBottom: "0px",
					}}
				>
					<PaymentFooter />
				</Footer>
			</Layout>
		</>
	);
};
