import { Row, Col } from 'antd'
import CustomPopoverConfirm from '../../../../../UI/CustomPopoverConfirm'

//Componente que renderiza los mobilirarios incluidos en la solicitud de la reserva, esto está pendiente de ser completado con data dinámica
const Mobiliarity = ({ mobiliarytiData }) => (
  <section className='section-request-booking'>
    <h4>Mobiliario</h4>
    <Row gutter={[0, 16]}>
      {mobiliarytiData.length !== 0 ? (
        mobiliarytiData?.map((item, index) => (
          <Col key={index} span={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <CustomPopoverConfirm content={item.name}>
              <small style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '100%', whiteSpace: 'nowrap' }}>{item.name}</small>
            </CustomPopoverConfirm>
            <span>{item.quantity}</span>
          </Col>
        ))
      ) : <span>No hay mobilario agregado en la reserva</span>
      }
    </Row>
  </section>
)

export default Mobiliarity
