import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: 'checking', // 'checking', 'not-authenticated', 'authenticated'
    uid: null,
    email: null,
    displayName: null,
    photoURL: null,
    errorMessage: null,
    committee: null,
    rolResidential: null,
    currentRol: 6,
    typeRol: localStorage.getItem('typeRol') ?? 0,
    roles: [],
    profileId: '',
    idToken: '',
    companyName: '',
    currentEmployee: localStorage.getItem('currentEmployee') ?? 0,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, { payload }) => {
            state.status = payload.status || 'checking' // 'checking', 'not-authenticated', 'authenticated'
            state.uid = payload.uid
            state.email = payload.email
            state.displayName = payload.displayName
            state.photoURL = payload.photoURL
            state.errorMessage = null
            state.committee = payload.committee
            state.rolResidential = payload.rolResidential
            state.roles = payload.roles
            if ((!localStorage.getItem('currentRol') || !localStorage.getItem('typeRol')) && payload?.roles?.length) {
                state.currentRol = payload?.roles[0]?.companyId ?? 6
                state.typeRol = payload?.roles[0]?.companyType
                state.companyName = payload?.roles[0]?.companyName
            }
            state.profileId = payload.profileId;
            state.idToken = payload.idToken;
        },
        logout: (state, { payload }) => {
            state.status = 'not-authenticated'; // 'checking', 'not-authenticated', 'authenticated'
            state.uid = null;
            state.email = null;
            state.displayName = null;
            state.photoURL = null;
            state.errorMessage = payload?.errorMessage;
            state.committee = null;
            state.rolResidential = null;
            state.roles = [];
            state.typeRol = 0;
            state.profileId = '';
            state.idToken = '';
        },
        checkingCredentials: (state, {payload}) => {
            state.status = 'checking';          
        },
        authComplete: (state) => {
            state.status = 'authenticated';
        },
        changeRol: (state, { payload }) => {
            state.currentRol = payload?.currentRol;
            state.committee.residential_id = payload?.residential_id;
            state.typeRol = payload?.typeRol;
            state.currentEmployee = payload?.currentEmployee;
            state.companyName = payload?.companyName
            state.profileId = payload.profileId
        }
    }
});


// Action creators are generated for each case reducer function
export const { login, logout, checkingCredentials, changeRol, authComplete } = authSlice.actions;