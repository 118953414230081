import { Row, Col, Image, } from 'antd'
import { ProfileIcon, testAvatar } from '../../../../../assets'

//Componente para mostrar la información del usuario titular que ha solicitado una reserva, que incluye el nombre, la dirección y la imágen de perfil
const UserDetail = ({ profileData }) => (
  <Row className='profile-details' style={{ gap: 16 }}>
    <Image width={50} height={50} src={profileData?.image || testAvatar} alt='profile-image' />
    <Col>
      <Row align='middle' style={{ gap: 8 }}>
        <strong>{profileData?.profile_name}</strong>
        <ProfileIcon />
      </Row>
      <address>Tuscania, Valle, Casa #12</address>
    </Col>
  </Row>
)

export default UserDetail
