import React from "react";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { basura } from "../../../../assets/images";

export const UserProfileCards = ({ cardData, setShouldReloadCards }) => {
	const [cards, setCards] = useState([]);
	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};
	useEffect(() => {
		setCards(cardData);
	}, [cardData]);

	const deleteCard = (card) => {
		deleteCardApi(card);
	};

	const deleteCardApi = async (card) => {
		const url = "https://domodev.topiaapp.com/api/eliminar-tarjeta";
		const data_raw = JSON.stringify({
			tokenID: card.id,
		});

		const requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: data_raw,
		};

		const res = await fetch(url, requestOptions);
		const data = await res.json();
		if (data.status === "success") {
			setShouldReloadCards(true);
			return data;
		} else {
			setShouldReloadCards(true);
			return data;
		}
	};

	const columns = [
		{
			title: "TARJETA",
			dataIndex: "cardName",
			key: "cardName",
			align: "center",
		},
		{
			title: "TIPO TARJETA",
			dataIndex: "ccType",
			key: "ccType",
			align: "center",
		},
		{
			title: "PAGO PREFERIDO",
			dataIndex: "created_time",
			key: "created_time",
			align: "center",
		},
		{
			title: "ELIMINAR",
			dataIndex: "delete",
			key: "delete",
			render: (text, record) => (
				<img
					className="iconoPerfil"
					src={basura}
					style={{ cursor: "pointer", height: "26px", width: "26px" }}
					alt="trash-icon"
					onClick={() => {
						deleteCard(record);
					}}
				/>
			),

			width: "8%",
		},
	];
	return (
		<>
			<Table
				className="primary-table"
				pagination={{ defaultPageSize: 7, simple: true }}
				columns={columns}
				dataSource={cards}
				loading="true"
				rowKey="id"
				locale={locale}
			/>
		</>
	);
};
