import { axiosCreate } from "../../../../../config";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const BOOKING_APPROVED_STATUS_PATH = 'reservation/tuscania/status/approve'

//Endpoint de topia con el que se cambia el estado de una reserva de tuscania a aprobado enviado adicionalmente un comentario
export const updateApprovedStatusBooking = async ({
    token,
    formData
}) => {

    try {

        const res = await axiosCreate.put(`${BOOKING_APPROVED_STATUS_PATH}`, formData, {
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }

}