import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import { Col, Row, Button, DatePicker } from "antd";
import { ReportLiquidacionTable } from "./ReportLiquidacionTable";
const { RangePicker } = DatePicker;

export const ReportLiquidacionContainer = (props) => {
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDateMonthSelector, setEndDateMonthSelector] = useState("");
	const [startDateMonthSelector, setStartDateMonthSelector] = useState("");
	const [button1, setButton1] = useState(false);
	const [button2, setButton2] = useState(false);
	const [button3, setButton3] = useState(false);
	const [button4, setButton4] = useState(false);

	const { buscarFechas, data } = props;

	const onChangeFecha = (date, dateString) => {
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		handleSelectedButton(5);
		buscar(dateString[0], dateString[1]);
	};

	useEffect(() => {
		buscarMonth();
	}, [endDateMonthSelector]);

	const onChangeMonth = async (date, dateString) => {
		const selectedMonth = moment(date);

		const firstDayOfMonth = moment(date).startOf("month");
		const lastDayOfMonth = moment(date).endOf("month");

		const formattedFirstDate = firstDayOfMonth.format("DD/MM/YYYY");
		const formattedLastDate = lastDayOfMonth.format("DD/MM/YYYY");

		setStartDateMonthSelector(formattedFirstDate);
		setEndDateMonthSelector(formattedLastDate);

		setButton1(false);
		setButton2(false);
		setButton3(false);
		setButton4(false);

		//await buscarFechas(formattedFirstDate, formattedFirstDate);
	};

	const buscar = async (start, end) => {
		var fechaI = new Date(moment(start, "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(end, "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
		buscarFechas(fechaIn, fechaFi);
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const buscarMonth = async () => {
		if (startDateMonthSelector === "") {
			var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
			buscarFechas(fechaIn, fechaFi);
		} else {
			var fechaI = new Date(moment(startDateMonthSelector, "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(endDateMonthSelector, "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
			buscarFechas(fechaIn, fechaFi);
		}
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const buscarBoton = async (
		numOfDays,
		fechaI = new Date(),
		fechaF = new Date()
	) => {
		var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));

		fechaIn.setDate(fechaIn.getDate() - numOfDays);
		if (numOfDays === 1) {
			fechaFi.setDate(fechaFi.getDate() - numOfDays);
		}
		buscarFechas(fechaIn, fechaFi);
	};

	const handleSelectedButton = (button) => {
		if (button === 1) {
			setButton1(true);
			setButton2(false);
			setButton3(false);
			setButton4(false);
		}
		if (button === 2) {
			setButton1(false);
			setButton2(true);
			setButton3(false);
			setButton4(false);
		}
		if (button === 3) {
			setButton1(false);
			setButton2(false);
			setButton3(true);
			setButton4(false);
		}
		if (button === 4) {
			setButton1(false);
			setButton2(false);
			setButton3(false);
			setButton4(true);
		}
		if (button > 4 || button < 1) {
			setButton1(false);
			setButton2(false);
			setButton3(false);
			setButton4(false);
		}
	};

	return (
		<>
			<Row>
				<Col span={12}>
					<div>
						<Button
							style={{
								color: button1 ? "#FFFFFF" : "#ADB9BB",
								background: button1 ? "#10B48C" : "white",
								border: "10px",
								fontSize: "11px",
								fontWeight: "500px",
								width: "104px",
							}}
							onClick={() => {
								buscarBoton(0);
								handleSelectedButton(1);
							}}
							size="small"
						>
							<span className="title">ESTE DÍA</span>
						</Button>
						<Button
							style={{
								color: button2 ? "#FFFFFF" : "#ADB9BB",
								background: button2 ? "#10B48C" : "white",
								marginLeft: "20px",
								border: "none",
								fontSize: "11px",
								fontWeight: "500px",
								width: "104px",
							}}
							onClick={() => {
								buscarBoton(1);
								handleSelectedButton(2);
							}}
							size="small"
						>
							<span className="title">AYER</span>
						</Button>
						<Button
							style={{
								color: button3 ? "#FFFFFF" : "#ADB9BB",
								background: button3 ? "#10B48C" : "white",
								marginLeft: "20px",
								border: "none",
								fontSize: "11px",
								fontWeight: "500px",
								width: "104px",
							}}
							onClick={() => {
								buscarBoton(7);
								handleSelectedButton(3);
							}}
							size="small"
						>
							<span className="title">ESTA SEMANA</span>
						</Button>
						<Button
							style={{
								color: button4 ? "#FFFFFF" : "#ADB9BB",
								background: button4 ? "#10B48C" : "white",
								marginLeft: "20px",
								border: "none",
								fontSize: "11px",
								fontWeight: "500px",
								width: "104px",
							}}
							onClick={() => {
								buscarBoton(31);
								handleSelectedButton(4);
							}}
							size="small"
						>
							<span className="title">ESTE MES</span>
						</Button>
					</div>
				</Col>
				<Col span={6} align="right">
					<div>
						<DatePicker
							suffixIcon={null}
							onChange={onChangeMonth}
							picker="month"
						/>
					</div>
				</Col>
				<Col span={6} align="right">
					<RangePicker
						defaultValue={[
							moment(new Date(), dateFormat),
							moment(new Date(), dateFormat),
						]}
						format={customFormat}
						style={{
							width: "270px",
						}}
						onCalendarChange={onChangeFecha}
					/>
				</Col>
				<Col span={24}>
					<ReportLiquidacionTable transactionsData={data} />
				</Col>
			</Row>
		</>
	);
};
