import React from "react";
import { Col, Row, Button, Modal, Space, Table, Badge, Spin, Descriptions, } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getColumnSearchProps } from "../../../../utils/tables";
import { getPaymentsHelper } from "../../../../helpers/paymentsHelper";
import UserProfileModal from "../../../../components/UserProfileModal";
import {
	columnsAccessVisitorsTable,
	getAccessActiveVisitors,
	getAccessHistorialByProfileId,
} from "../../../../helpers/accessHelper";
import { columnsHistorialAccessTable } from "../../../../helpers/accessHelper";
import {
	getReferenceData,
	getResidentialsById,
	serializeUserData,
} from "../../../../helpers/globalHelper";
import { shape11, shape12, shape13, avatarUser, shape28, shape09 } from '../../../../assets/images'
import moment from "moment";

export const AccessActiveVisitorsTab = () => {
	const [activeVisitorList, setActiveVisitorList] = useState([]);
	const [fullActiveVisitors, setFullActiveVisitors] = useState([]);
	const [fullVisitorsWithHistory, setFullVisitorsWithHistory] = useState([]);
	const [activeVisitor, setActiveVisitor] = useState([]);
	const [accessVisitorModalActive, setAccessVisitorModalActive] =
		useState(false);
	const columns = columnsAccessVisitorsTable();
	const columnsHistorialAccess = columnsHistorialAccessTable();
	const [loading, setLoad] = useState(true);
	const today = new Date();
	const getActiveVisitorList = async () => {
		/* const getVisitors = await getAccessActiveVisitors(
			"Yd5MV7jkA0MjQeCmzKrZ",
			today
		).then((result) => setActiveVisitorList(result)); */
		const requestRaw = await getAccessActiveVisitors(
			"Yd5MV7jkA0MjQeCmzKrZ",
			today
		);

		let requestData = requestRaw.map(async (item, index) => {
			const residentials = await getResidentialsById(item.residential_id);
			var profile = {};
			if (item.profile_id != undefined) {
				const profileData = await getReferenceData(
					`/profile/${item.profile_id}`
				);
				profile = await serializeUserData(profileData);
			}

			let records = await getAccessHistorialByProfileId(item.profile_id);

			//let records = [];
			let house = null;

			if (item.house_id) {
				var nomencaturaSplit = item.house_id.path.split("/");
				var nomenclatura =
					nomencaturaSplit[0] +
					"/" +
					nomencaturaSplit[1] +
					"/" +
					nomencaturaSplit[2] +
					"/" +
					nomencaturaSplit[3];

				//var numeroCasa = await getReferenceData(item.house_id.path);
				var numeroZona = await getReferenceData(nomenclatura);
				house = await getReferenceData(item.house_id.path);
				house.zona = numeroZona;
			}
			if (house) {
				if (house.created_at) {
					house.created_at = moment(house.created_at.toDate()).format(
						"Y-MM-DD"
					);
				}
			}

			const dataKeys = Object.keys(item);
			let dataFiltered = {};

			dataKeys.map((key) => {
				if (key == "house_id") {
					if (item[key]) {
						dataFiltered[key] = item[key].id;
					} else {
						dataFiltered[key] = null;
					}
				} else {
					dataFiltered[key] = item[key];
				}
			});

			return {
				key: index,
				...dataFiltered,
				profile_data: profile,
				house_data: house,
				records: records,
				residential_data: residentials,
			};
		});
		Promise.all(requestData).then((data) => {
			let residentList = [];
			let visitorList = [];

			data.map((item, index) => {
				let itemData = { ...item };
				itemData.key = index;
				//itemData.time_action = itemData.time_arrive ? itemData.time_arrive : itemData.time_leave;
				itemData.time_action = itemData.created_at;

				itemData.phone = item.profile_data ? item.profile_data?.phone : "";
				itemData.visit_type = "";
				itemData.home_residence =
					item.house_data.zona.name + " No." + item.house_data?.name;
				switch (itemData.type) {
					case 1:
						itemData.visit_type = "Residente";
						break;
					case 2:
						itemData.visit_type = "Delivery";
						break;
					case 3:
						itemData.visit_type = "Visita programada";
						break;
					case 4:
						itemData.visit_type = "Proveedor o Empleado";
						break;
					case 5:
						itemData.visit_type = "Oficina";
						break;
					case 6:
						itemData.visit_type = "Familiar y amigos";
						break;
					case 7:
						itemData.visit_type = "Documento";
						break;
				}

				if (item.type == 1) {
					residentList.push(itemData);
				} else {
					visitorList.push(itemData);
				}
			});

			setActiveVisitorList(visitorList);
		});
	};

	useEffect(() => {
		getActiveVisitorList();
	}, []);

	useEffect(() => {
		const visitors = activeVisitorList.map((item, index) => {
			let data = { ...item };
			data.detail = (
				<img
					src={shape11}
					style={{ cursor: "pointer" }}
					alt="switch-arrows"
					onClick={() => handleStatusVisitorModal(true, index)}
				/>
			);
			data.action_user = data.arrive ? (
				<>
					<img src={shape12} alt="blue-left-arrow" />
					<span style={{ marginLeft: "10px" }}>Entró</span>
				</>
			) : (
				<>
					<img src={shape13} alt="green-right-arrow" />
					<span style={{ marginLeft: "10px" }}>Salió</span>
				</>
			);
			return data;
		});

		setFullActiveVisitors(visitors);
		setLoad(false);
	}, [activeVisitorList]);

	const addDataToActiveUser = (user) => {
		/* if (user.records.length === 0) {
			return user;
		} */
		let records = { ...user };
		records.history = user.records.map((item) => {
			let data = { ...item };
			data.action_user_history = item.arrive ? (
				<>
					<img src={shape12} alt="blue-left-arrow" />
					<span style={{ marginLeft: "10px" }}>Entró</span>
				</>
			) : (
				<>
					<img src={shape13} alt="green-right-arrow" />
					<span style={{ marginLeft: "10px" }}>Salió</span>
				</>
			);
			return data;
		});
		user = { ...records };
		setActiveVisitor(user);
	};

	/*  useEffect(() => {
		setFullVisitorsWithHistory(fullActiveVisitors);
		if ()
	}, [fullActiveVisitors]); */

	const handleStatusVisitorModal = async (statusModal, index = null) => {
		if (index != null) {
			let active = { ...activeVisitorList[index] };
			active.records = activeVisitorList[index].records.map((item) => {
				let data = { ...item };
				data.action_user_history = item.arrive ? (
					<>
						<img src={shape12} alt="blue-left-arrow" />
						<span style={{ marginLeft: "10px" }}>Entró</span>
					</>
				) : (
					<>
						<img src={shape13} alt="green-right-arrow" />
						<span style={{ marginLeft: "10px" }}>Salió</span>
					</>
				);
				return data;
			});
			setActiveVisitor(active);

			//addDataToActiveUser(activeVisitorList[index]);
		}

		setTimeout(() => {
			setAccessVisitorModalActive(statusModal);
		}, 50);
	};

	return (
		<>
			{loading ? (
				<div className={"loader"}>
					<Spin size="large" />
				</div>
			) : (
				<div>
					<Table
						className="primary-table"
						columns={columns}
						dataSource={fullActiveVisitors}
						pagination={{ defaultPageSize: 15, simple: true }}
					/>
				</div>
			)}
			<Modal
				className="side-modal"
				title="Datos del visitante"
				style={{ top: 0, right: 0 }}
				visible={accessVisitorModalActive}
				onCancel={() => handleStatusVisitorModal(false)}
				footer={null}
			>
				{activeVisitor && activeVisitor.profile_id ? (
					<>
						<Row className="section-modal">
							<Col span={24}>
								<div className="user-data">
									{activeVisitor.profile_data.image ? (
										<img
											className="user-image"
											src={activeVisitor.profile_data?.image}
											alt="Topia"
											style={{ marginRight: "5px" }}
										/>
									) : (
										<img
											className="user-image"
											src={avatarUser}
											alt="user-avatar-icon"
											style={{ marginRight: "5px" }}
										/>
									)}

									<div className="user-info">
										<span className="user-name">
											{activeVisitor.profile_data?.name}
										</span>
										<span
											style={{
												color: "#10B48C",
												display: "flex",
												alignItems: "flex-start",
												paddingTop: "10px",
											}}
										>
											<img
												style={{
													marginLeft: "10px",
													width: "20px",
													height: "19px",
												}}
												src={shape28}
												alt="carnet-icon"
											/>

											<span style={{ marginLeft: "3x" }}>
												{activeVisitor.visit_type}
											</span>
											<img
												style={{ marginLeft: "15px" }}
												src={shape09}
												alt="phone-icon"
											/>

											{activeVisitor.profile_data.phone ? (
												<span style={{ marginLeft: "1x" }}>
													{activeVisitor.profile_data?.phone}
												</span>
											) : (
												<span style={{ marginLeft: "1x" }}>N/A</span>
											)}
										</span>
									</div>
								</div>
							</Col>
						</Row>

						<Row className="section-modal">
							<Col span={14} style={{ display: "flex" }}>
								<div className="alert-modal-header">
									<span className="title">Residencial que visita:</span>
									<p>{activeVisitor.residential_data?.address}</p>
								</div>
							</Col>
							<Col span={10}></Col>
						</Row>

						<Row className="section-modal">
							<Col span={24} style={{ display: "flex" }}>
								<div className="alert-modal-header">
									<span className="title">
										Historial de entradas y salidas:
									</span>
								</div>
							</Col>
							<Col span={24}>
								<Table
									dataSource={activeVisitor.records}
									columns={columnsHistorialAccess}
									pagination={{ defaultPageSize: 4, simple: true }}
									rowKey="id"
									scroll={{ x: true }}
								/>
							</Col>
						</Row>
					</>
				) : (
					<>
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					</>
				)}
			</Modal>
		</>
	);
};
