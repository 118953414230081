import { Radio } from "antd";
import './styles.css'

export const CustomRadioButton = ({
  className = 'custom-radio',
  options,
  id,
  value,
  onChange,
  style,
  children,
  disabled
}) => {
  return (
    <Radio.Group
      disabled={disabled}
      id={id}
      options={options ? options : undefined}
      onChange={onChange}
      value={value}
      className={className} 
      style={style}
    >
      {children}
    </Radio.Group>
  );
};
