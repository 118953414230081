import React from "react";
import { Col, Modal, Row, Table, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveVisitor } from "../../../../store/access";
import {
	columnsAccessVisitorsTable,
	columnsHistorialAccessTable,
} from "../../../../helpers/accessHelper";
import { shape12, shape13, shape11, shape28, avatarUser, shape09 } from '../../../../assets/images'

export const AccessVisitorsTab = () => {
	const [residentList, setResidentList] = useState([]);
	const dispatch = useDispatch();
	const { visitors } = useSelector((state) => state.access);
	const columns = columnsAccessVisitorsTable();
	const [accessVisitorModalActive, setAccessVisitorModalActive] =
		useState(false);
	const columnsHistorialAccess = columnsHistorialAccessTable();
	const [visitorActive, seVisitorActive] = useState(null);
	const [loading, setLoad] = useState(true);

	useEffect(() => {
		setLoad(visitors.loadResidente);
	}, [visitors.loadResidente]);

	useEffect(() => {
		seVisitorActive(visitors.active);
		if (visitors.active) {
			if (visitors.active.records.length > 0) {
				let active = { ...visitors.active };
				active.records = visitors.active.records.map((item) => {
					let data = { ...item };
					data.action_user_history = item.arrive ? (
						<>
							<img src={shape12} alt="blue-left-arrow" />
							<span style={{ marginLeft: "10px" }}>Entró</span>
						</>
					) : (
						<>
							<img src={shape13} alt="green-right-arrow" />
							<span style={{ marginLeft: "10px" }}>Salió</span>
						</>
					);
					return data;
				});
				seVisitorActive(active);
			}
		}
		if (!visitors.active) {
			handleStatusVisitorModal(false);
		}
	}, [visitors.active]);

	useEffect(() => {
		let _data = visitors.data.map((item, index) => {
			let data = { ...item };
			data.detail = (
				<img
					src={shape11}
					style={{ cursor: "pointer" }}
					alt="blue-switch-arrows"
					onClick={() => handleStatusVisitorModal(true, index)}
				/>
			);
			data.action_user = data.arrive ? (
				<>
					<img src={shape12} alt="blue-left-arrow" />
					<span style={{ marginLeft: "10px" }}>Entró</span>
				</>
			) : (
				<>
					<img src={shape13} alt="green-right-arrow" />
					<span style={{ marginLeft: "10px" }}>Salió</span>
				</>
			);
			return data;
		});

		setResidentList(_data);
	}, [visitors.data]);

	const handleStatusVisitorModal = (statusModal, index = null) => {
		if (index != null) {
			dispatch(setActiveVisitor(visitors.data[index]));
		} else {
			dispatch(setActiveVisitor(null));
		}

		setTimeout(() => {
			setAccessVisitorModalActive(statusModal);
		}, 50);
	};

	return (
		<>
			{loading ? (
				<div className={"loader"}>
					<Spin size="large" />
				</div>
			) : (
				<div>
					<Table
						className="primary-table"
						columns={columns}
						dataSource={residentList}
						pagination={{ defaultPageSize: 15, simple: true }}
					/>
				</div>
			)}

			<Modal
				className="side-modal"
				title="Datos del visitante"
				style={{ top: 0, right: 0 }}
				visible={accessVisitorModalActive}
				onCancel={() => handleStatusVisitorModal(false)}
				footer={null}
			>
				{visitorActive && visitorActive.profile_id ? (
					<>
						<Row className="section-modal">
							<Col span={24}>
								<div className="user-data">
									{visitorActive.profile_data.image ? (
										<img
											className="user-image"
											src={visitorActive.profile_data?.image}
											alt="Topia"
											style={{ marginRight: "5px" }}
										/>
									) : (
										<img
											className="user-image"
											src={avatarUser}
											alt="avatar-user-icon"
											style={{ marginRight: "5px" }}
										/>
									)}

									<div className="user-info">
										<span className="user-name">
											{visitorActive.profile_data?.name}
										</span>
										<span
											style={{
												color: "#10B48C",
												display: "flex",
												alignItems: "flex-start",
												paddingTop: "10px",
											}}
										>
											<img
												style={{
													marginLeft: "10px",
													width: "20px",
													height: "19px",
												}}
												src={shape28}
												alt="carnet-icon"
											/>

											<span style={{ marginLeft: "3x" }}>
												{visitorActive.visit_type}
											</span>
											<img
												style={{ marginLeft: "15px" }}
												src={shape09}
												alt="phone-icon"
											/>

											{visitorActive.profile_data.phone ? (
												<span style={{ marginLeft: "1x" }}>
													{visitorActive.profile_data?.phone}
												</span>
											) : (
												<span style={{ marginLeft: "1x" }}>N/A</span>
											)}
										</span>
									</div>
								</div>
							</Col>
						</Row>

						<Row className="section-modal">
							<Col span={14} style={{ display: "flex" }}>
								<div className="alert-modal-header">
									<span className="title">Residencial que visita:</span>
									<p>{visitorActive.residential_data?.address}</p>
								</div>
							</Col>
							<Col span={10}></Col>
						</Row>

						<Row className="section-modal">
							<Col span={24} style={{ display: "flex" }}>
								<div className="alert-modal-header">
									<span className="title">
										Historial de entradas y salidas:
									</span>
								</div>
							</Col>
							<Col span={24}>
								<Table
									dataSource={visitorActive.records}
									columns={columnsHistorialAccess}
									pagination={{ defaultPageSize: 4, simple: true }}
									rowKey="id"
									scroll={{ x: true }}
								/>
							</Col>
						</Row>
					</>
				) : (
					<>
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					</>
				)}
			</Modal>
		</>
	);
};
