import { Row, Button } from 'antd'
import { Modal } from "antd";

const ConfirmContent = ({ description, onCancelButton = () => { }, onConfirmButton = () => { }, loading }) => <div className='confirm-layout-content'>
  {description}
  <Row style={{ gap: 16 }} justify='center'>
    <Button loading={loading} onClick={onCancelButton} className='btn-just-icon' style={{ color: '#FF601C' }}>CANCELAR</Button>
    <Button loading={loading} onClick={onConfirmButton} className='btn-standard-green' style={{ width: '152px' }} shape='round'>ACEPTAR</Button>
  </Row>
</div>

export const CustomModal = ({
  width = 600,
  centered = true,
  isOpenModal,
  onCancel,
  children,
  destroyOnClose,
  title,
  name,
  className = "primary-modal",
  wrapClassName,
  zIndex = 1000,
  confirmLoading = false,
  footer = null,
  confirmModalLayout = false,
  description,
  onConfirmButton,
  onCancelButton,
  loadingModalLayout,
  style
}) => (
  <Modal
    wrapClassName={wrapClassName}
    confirmLoading={confirmLoading}
    centered={centered}
    className={confirmModalLayout ? 'custom-modal-confirm-layout' : className}
    visible={isOpenModal}
    onCancel={onCancel}
    footer={footer}
    width={width}
    destroyOnClose={destroyOnClose}
    title={title}
    zIndex={zIndex}
    style={style}
  >
    {confirmModalLayout ? <ConfirmContent description={description} onCancelButton={onCancelButton} onConfirmButton={onConfirmButton} loading={loadingModalLayout} /> : children}
  </Modal>
);



export default CustomModal;
