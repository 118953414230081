import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { getAccess, getScheduleVisitors } from "../../../store/access";
import { AccessTabs } from "./components/AccessTabs";
import { useDispatch, useSelector } from "react-redux";

export const AccessPage = () => {
	const [loadingBt, setLoadBt] = useState(false);
	const dispatch = useDispatch();
	const { residents } = useSelector((state) => state.access);

	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(23, 59, 59, 0));

		dispatch(getAccess(fechaInicio, fechaFinal));
		dispatch(getScheduleVisitors(fechaInicio, fechaFinal));
		setLoadBt(true);
	}, [dispatch]);

	useEffect(() => {
		setLoadBt(residents.loadResidente);
	}, [residents.loadResidente]);

	const buscarFechas = async (fechaInicio, fechaFin) => {
		setLoadBt(true);
		dispatch(getAccess(fechaInicio, fechaFin));
		dispatch(getScheduleVisitors(fechaInicio, fechaFin));
	};

	return (
		<>
			<div className="wrapper-page">
				<h2 className="title-page">Accesos residencial</h2>

				<Row>
					<Col span={24}>
						<AccessTabs
							buscarFechas={buscarFechas}
							loadingBt={loadingBt}
						></AccessTabs>
					</Col>
				</Row>
			</div>
		</>
	);
};
