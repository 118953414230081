import React from 'react'
import { shape21, shape22 } from '../../../../assets/images'

export const AnnouncentCard = ({announcements, handleActiveDetail}) => {
  return (
    <ul className='primary-card-list'>
      {
        announcements.map((announcement, key) => {
          return (
            <li className='primary-card-card' key={key} onClick={() => {handleActiveDetail(announcement)}}>
              <img className='icon' src={shape21} alt="white-email-icon" />
    
              <div className='primary-card-info'>
                <h3>{announcement.about}</h3>
                <span>Fecha de envío:  {announcement.created_at}</span>
              </div>
    
              <img className='shape-next' src={shape22} alt="right-arrow-icon" />
            </li>
          )
        })
      }
    </ul>
  )
}
