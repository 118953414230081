import React from "react";
import { useState } from "react";
import moment from "moment";
import { Col, Row, Button, DatePicker, Tabs } from "antd";
import { UserProfilePayments } from "./UserProfilePayments";
import { UserProfilePermissions } from "./UserProfilePermissions";
import { UserProfileRequest } from "./UserProfileRequest";
import { AlertsTab } from "../../alertsReceived/components/AlertsTab";
import { UserProfileCards } from "./UserProfileCards";
import { metodoPago, shape01, shape02, shape03, shape32 } from "../../../../assets/images";

const { RangePicker } = DatePicker;

export const UserProfileContainerTabs = ({
	buscarFechas,
	loadingBt,
	data,
	permissionData,
	paymentData,
	cardData,
	setShouldReloadCards,
}) => {
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const { TabPane } = Tabs;

	const onChangeFecha = (date, dateString) => {
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		buscar(dateString[0], dateString[1]);
	};

	const buscar = async (start, end) => {
		var fechaI = new Date(moment(start, "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(end, "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
		buscarFechas(fechaIn, fechaFi);
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const buscarBoton = async (
		numOfDays,
		fechaI = new Date(),
		fechaF = new Date()
	) => {
		var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));

		fechaIn.setDate(fechaIn.getDate() - numOfDays);
		buscarFechas(fechaIn, fechaFi);
	};

	return (
		<>
			<Row type="flex" align="middle">
				<Col span={24}></Col>
				<Col span={12}>
					<div style={{ marginLeft: "20px" }}>
						<Button
							style={{
								color: "#10b48c",
								background: "white",
								border: "none",
								fontWeight: "bold",
							}}
							onClick={() => {
								buscarBoton(0);
							}}
							size="small"
						>
							<span className="title">ESTE DÍA</span>
						</Button>
						<Button
							style={{
								color: "#10b48c",
								background: "white",
								marginLeft: "20px",
								border: "none",
								fontWeight: "bold",
							}}
							onClick={() => {
								buscarBoton(7);
							}}
							size="small"
						>
							<span className="title">ESTA SEMANA</span>
						</Button>
						<Button
							style={{
								color: "#10b48c",
								background: "white",
								marginLeft: "20px",
								border: "none",
								fontWeight: "bold",
							}}
							onClick={() => {
								buscarBoton(31);
							}}
							size="small"
						>
							<span className="title">ESTE MES</span>
						</Button>
					</div>
				</Col>
				<Col span={6}></Col>
				<Col span={6} style={{ justifyContent: "right" }}>
					<RangePicker
						defaultValue={[
							moment(new Date(), dateFormat),
							moment(new Date(), dateFormat),
						]}
						format={customFormat}
						style={{
							width: "270px",
						}}
						onCalendarChange={onChangeFecha}
					/>
				</Col>
			</Row>
			<Row>
				<Col span="20" lg={24} xs={24}>
					<div className="wrapper" style={{ marginTop: "30px" }}>
						<Tabs
							className="general-tabs"
							defaultActiveKey="1"
							tabBarGutter={100}
						>
							{/* <TabPane
								tab={
									<>
										<img src="/assets/images/shape33.png" alt="Topia" />
										Accesos propios
									</>
								}
								key="1"
							>
								<UserProfileAccess id={id} data={data} />
							</TabPane> */}
							<TabPane
								tab={
									<>
										<img src={shape32} alt="authorized-car-icon" />
										Permisos creados
									</>
								}
								key="2"
							>
								<UserProfilePermissions permissionDataN={permissionData} />
							</TabPane>
							<TabPane
								tab={
									<>
										<img src={shape02} alt="alert-button-shape" />
										Solicitudes
									</>
								}
								key="3"
							>
								<UserProfileRequest />
							</TabPane>
							<TabPane
								tab={
									<>
										<img src={metodoPago} alt="payment-method-icon" />
										Pagos realizados
									</>
								}
								key="4"
							>
								<UserProfilePayments paymentData={paymentData} />
							</TabPane>
							<TabPane
								tab={
									<>
										<img src={shape01} alt="alert-shape" />
										Alertas
									</>
								}
								key="5"
							>
								{/* <UserProfileAlerts /> */}
								<AlertsTab />
							</TabPane>
							<TabPane
								tab={
									<>
										<img src={shape03} alt="credit-cards-icon" />
										Tarjetas
									</>
								}
								key="6"
							>
								<UserProfileCards
									cardData={cardData}
									setShouldReloadCards={setShouldReloadCards}
								/>
							</TabPane>
						</Tabs>
					</div>
				</Col>
			</Row>
		</>
	);
};
