import {
	getNomenclaturasHelper,
	getNomenclaturasCasasHelper,
	getNumeroEmpleados,
	getAllUserResidential,
	getProfileByHouseIdHelper,
} from "../../helpers/nomenclaturasHelper";
import {
	onCreateOrUpdate,
	onUpdateDocWithRef,
	onDeleteDoc,
	onUpdateDocWithRefInfo,
} from "../../helpers/globalHelper";

import { createNomenclatura, updateResidencial } from "./nomenclaturasSlice";
import moment from "moment";

import { setNomenclaturaData, setCasasData, setEmpleadosData } from ".";

export const getNomenclaturas = () => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		//CAMBIAR A COMITTE CUANDO SE ARREGLE BUG, SE ASIGNA RES_ID DE CDI EN VEZ DE TUSC
		const zonasData = await getNomenclaturasHelper("Yd5MV7jkA0MjQeCmzKrZ");

		Promise.all(zonasData).then((data) => {
			dispatch(setNomenclaturaData(data));
		});
	};
};

export const getNomenclaturasCasas = (nomenclatura_id) => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		const casasData = await getNomenclaturasCasasHelper(
			//CAMBIAR A COMITTE CUANDO SE ARREGLE BUG, SE ASIGNA RES_ID DE CDI EN VEZ DE TUSC
			"Yd5MV7jkA0MjQeCmzKrZ",
			nomenclatura_id
		);

		let casasDataFull = casasData.map(async (item) => {
			let data = { ...item };
			if (data.idOwners) {
				const profile = await getProfileByHouseIdHelper(data.idOwners[0]).then(
					(pro) => {
						data.userProfile = pro;
					}
				);
			}
			return data;
			/* const profile = await getProfileByHouseIdHelper(item.idOwners[0]);
			item.profile = profile; */
		});

		/* let sortedArr = casasDataFull.sort(function (a, b) {
			var aInt = parseInt(a.name);
			var bInt = parseInt(b.name);
			return aInt - bInt;
		}); */

		Promise.all(casasDataFull).then((data) => {
			dispatch(setCasasData(data));
		});
	};
};

export const getUser = () => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		const userData = await getAllUserResidential(committee.residential_id);

		Promise.all(userData).then((data) => {
			dispatch(setEmpleadosData(data));
		});
	};
};

export const saveResidencial = (data, path, id) => {
	return async (dispatch, getState) => {
		const residencialData = await onUpdateDocWithRefInfo(path, data);
		var dataSend = data;
		dataSend.id = id;
		dispatch(updateResidencial(dataSend));
	};
};

export const setNomenclaturas = (data) => {
	return async (dispatch, getState) => {
		if (data.nomenclaturaActive == null) {
			var item = {};
			item.name = data.name;
			item.active = true;
			item.number_residential = parseInt(data.numeroCasa);
			item.start_house = parseInt(data.desde);
			item.end_house = parseInt(data.hasta);
			item.created_at = moment(new Date(), "DD/MM/YYYY").toDate();
			item.updated_at = moment(new Date(), "DD/MM/YYYY").toDate();

			const { committee } = getState().auth;

			const createdNomenclatura = await onCreateOrUpdate(
				`/residentials/${committee.residential_id}/nomenclature/`,
				item
			);
			item.id = createdNomenclatura.id;
			item.created_at = moment(item.created_at).format("D-MM-Y");
			item.created_time = moment(item.created_at).format("HH:mm");
			item.residentialId = committee.residential_id;

			var inicioCasa = parseInt(data.desde);
			var finCasa = parseInt(data.hasta);

			for (var z = 0; z < parseInt(data.numeroCasa); z++) {
				var itemHouse = {};
				itemHouse.name = inicioCasa + z + "";
				itemHouse.active = true;
				itemHouse.id_profile_titular = "";
				itemHouse.titular_name = "";
				itemHouse.use_titular = "";

				const createdHouse = await onCreateOrUpdate(
					`/residentials/${committee.residential_id}/nomenclature/${item.id}/houses`,
					itemHouse
				);
			}

			dispatch(createNomenclatura(item));
		} else {
			var item = {};
			item.name = data.name;
			item.updated_at = moment(new Date(), "DD/MM/YYYY").toDate();

			await onUpdateDocWithRefInfo(
				`/residentials/${data.nomenclaturaActive.residentialId}/nomenclature/${data.nomenclaturaActive.id}`,
				item
			);
			var nomenclaturaActive = { ...data.nomenclaturaActive };
			nomenclaturaActive.name = data.name;
			nomenclaturaActive.code = data.code;
			dispatch(createNomenclatura(nomenclaturaActive));
		}
	};
};

export const setZonasPermisos = (data) => {
	return async (dispatch, getState) => {
		//eliminar permisos
		var z = 0;
		while (z < data.usuariosEliminar.length) {
			await onDeleteDoc(`/permissions`, data.usuariosEliminar[z].id);
			++z;
		}

		//agrego nuevos
		var i = 0;
		while (i < data.usuarioPermisoNuevos.length) {
			var itemPermiso = {};
			itemPermiso.house =
				"/residentials/" +
				data.zonaActive.residentialId +
				"/nomenclature/oficina/houses/" +
				data.zonaActive.id;
			itemPermiso.idProfile = data.usuarioPermisoNuevos[i].id;
			itemPermiso.idResidencial = data.zonaActive.residentialId;
			await onCreateOrUpdate(`/permissions/`, itemPermiso);

			++i;
		}

		let users = await getNumeroEmpleados(
			data.zonaActive.residentialId,
			data.zonaActive.id
		);
		var zonaActive = { ...data.zonaActive };
		zonaActive.users = users;

		dispatch(createNomenclatura(zonaActive));
	};
};
