import {
	collection,
	query,
	where,
	orderBy,
	getDocs,
	doc,
	getDoc,
	collectionGroup,
} from "firebase/firestore";
import { FirebaseDB, FirebaseApp } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import { serializeUserData } from "./globalHelper";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";

const db = getFirestore(FirebaseApp);

//CAMBIAR DE VUELTA RESIDENTIAL_ID CUANDO SE ARREGLE BUG
export const getAdminCobrosHelper = async (residential_id) => {
	const collectionRef = collection(FirebaseDB, `/payment_template`);
	const queryCollection = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", "Yd5MV7jkA0MjQeCmzKrZ"),
		orderBy("created_at", "desc")
	);
	const querySnapshot = await getDocs(queryCollection);

	const dataList = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		data.id = documment.id;
		data.created_at =
			data.created_at == null
				? ""
				: moment(data.created_at.toDate()).format("Y-MM-DD");
		data.update_at =
			data.update_at == null
				? ""
				: moment(data.update_at.toDate()).format("DD-MM-Y");
		data.dateEmision =
			data.dateEmision == null
				? ""
				: moment(data.dateEmision.toDate()).format("DD-MM-Y");
		data.dateVencimiento =
			data.dateVencimiento == null
				? ""
				: moment(data.dateVencimiento.toDate()).format("DD-MM-Y");
		dataList.push(data);
	});

	return dataList;
};

export const getAdminCobrosResponseHelper = async (
	residential_id,
	payment_id
) => {
	var respuesta = [];
	const collectionRefS = collection(FirebaseDB, `/value_payment_template`);
	const queryCollectionS = query(
		collectionRefS,
		where("idPayment", "==", payment_id),
		where("idResidential", "==", "Yd5MV7jkA0MjQeCmzKrZ")
	);

	const querySnapshotS = await getDocs(queryCollectionS);
	querySnapshotS.forEach(async (docummentR) => {

		let dataR = docummentR.data();
		dataR.idP = docummentR.id;
		respuesta.push(dataR);
	});

	return respuesta;
};

export const getSurveysQuestionHelper = async (surveys_id) => {
	var respuesta = [];
	const collectionRefS = collection(
		FirebaseDB,
		`/surveys/` + surveys_id + `/questions`
	);
	const queryCollectionS = query(collectionRefS);

	const querySnapshotS = await getDocs(queryCollectionS);
	querySnapshotS.forEach(async (docummentR) => {
		let dataR = docummentR.data();
		dataR.idR = docummentR.id;
		respuesta.push(dataR);
	});

	return respuesta;
};

export const getHouseByAlias = async (alias) => {
	const q = query(
		collectionGroup(FirebaseDB, "houses"),
		where("alias", "==", alias)
	);

	const querySnapshot = await getDocs(q);

	let house = [];
	querySnapshot.forEach((doc) => {
		let itemData = {
			path: doc.ref.path,
			data: doc.data(),
		};
		house.push(itemData);
	});
	return house;
};
export const getHouseById = async (id) => {
	const docRef = doc(FirebaseDB, "houses", id);

	const docSnapshot = await getDoc(docRef);

	let house = null;
	if (docSnapshot.exists()) {
		house = docSnapshot.data();
	}
	return house;
};

export const getAllUserPagos = async (residential_id) => {
	var user = [];
	const collectionRef = collection(FirebaseDB, `/profile`);
	const queryCollection = query(
		collectionRef,
		where("active", "==", true),
		where("residentialsIds", "array-contains", "Yd5MV7jkA0MjQeCmzKrZ")
	);

	const querySnapshot = await getDocs(queryCollection);

	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = await serializeUserData(data);
		var house = "";
		if (data.residentials) {
			let userResidential = false;
			data.residentials.map((residential) => {
				if (
					residential.idResidential == "Yd5MV7jkA0MjQeCmzKrZ" &&
					residential.homePrincipal == true
				) {
					userResidential = true;
					house = residential.house.path;
				}
			});

			if (userResidential) {
				user.push({
					id: documment.id,
					path: house,
					...itemData,
				});
			}
		}

		//address
		//user.push(itemData)
	});

	return user;
};

export const getAllNomenclaturas = async (residential_id) => {
	var nomenclaturas = [];
	const collectionRef = collection(
		FirebaseDB,
		`/residentials/` + "Yd5MV7jkA0MjQeCmzKrZ" + `/nomenclature/`
	);
	const queryCollection = query(collectionRef);

	const querySnapshot = await getDocs(queryCollection);

	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		data.id = documment.id;
		nomenclaturas.push(data);
	});

	return nomenclaturas;
};

export const getAllCasas = async (residential_id, nomenclatura) => {
	var nomenclaturas = [];
	const collectionRef = collection(
		FirebaseDB,
		`/residentials/` +
			"Yd5MV7jkA0MjQeCmzKrZ" +
			`/nomenclature/` +
			nomenclatura.id +
			`/houses/`
	);
	const queryCollection = query(collectionRef);

	const querySnapshot = await getDocs(queryCollection);

	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		data.id = documment.id;
		data.address = nomenclatura.name + " #" + data.name;
		data.path =
			"residentials/" +
			"Yd5MV7jkA0MjQeCmzKrZ" +
			"/nomenclature/" +
			nomenclatura.id +
			"/houses/" +
			data.id;
		nomenclaturas.push(data);
	});

	return nomenclaturas;
};

export const getPaymentType = async (docID) => {
	//const paytypesRef = collection(FirebaseDB, "payment_types");
	const docRef = doc(FirebaseDB, "payment_types", docID);

	return docRef;
};

export const columnsAlertTable = () => {
	return [
		{
			title: "Fecha",
			dataIndex: "created_at",
			key: "created_at",
		},
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Fecha Inicio",
			dataIndex: "start_date",
			key: "start_date",
		},
		{
			title: "Fecha Fin",
			dataIndex: "end_date",
			key: "end_date",
		},
		{
			title: "Tipo Encuesta",
			dataIndex: "survey_type",
			key: "survey_type",
		},
	];
};


export const getUserNameFromProfileId = async(profileId) => {
	const docRef = doc(FirebaseDB, "profile", profileId);

	const docSnapshot = await getDoc(docRef);

	const { name, lastname } = docSnapshot.data();

	return !!lastname ? `${name} ${lastname}` : name;
}