import React, { useRef } from "react";
import {
	Button,
	Table,
	Spin,
	Layout,
	Modal,
	Col,
	Row,
	Form,
	Input,
	Select,
	Space,
	InputNumber,
	Descriptions,
	Switch,
	Radio,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ExportHousesExcel from "./ExportHousesExcel";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	columnsAlertTable,
	getDataByNomenclatureIdAndHouseIdHelper,
} from "../../../../../helpers/nomenclaturasHelper";
import {
	getNomenclaturas,
	setActiveNomenclatura,
	setNomenclaturas,
	getNomenclaturasCasas,
	saveResidencial,
} from "../../../../../store/nomenclaturas";
import { ReactComponent as Edit } from "../../../../../assets/icons/Actions/edit.svg";
import "../style.css";
import { Spinner } from "react-activity";
import { useForm } from "../../../../../hooks";
import { toast } from "react-toastify";
import { getColumnSearchProps } from "../../../../../utils/tables";
import { getQueryDocByCollection } from "../../../../../helpers/globalHelper";
import UserProfileModal from "../../../../../components/UserProfileModal";

export const NomenclaturaTab = () => {
	const [form] = Form.useForm();
	const [sendingResidencial, handleSendingResidencial] = useState(false);
	const [loadingCasa, setLoadingCasa] = useState(false);
	const [visibleCasas, setVisibleCasa] = useState(false);
	const { TextArea } = Input;

	const dispatch = useDispatch();
	const { nomenclaturas } = useSelector((state) => state.nomenclaturas);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");

	const [nomenclaturaList, setNomenclaturaList] = useState([]);
	const [userList, setUserList] = useState([]);
	const [nomenclaturaActive, setNomenclaturaActive] = useState(null);
	const [residencialActive, setResidencialActive] = useState(null);
	const [nomenclaturaCasas, setNomenclaturaCasas] = useState([]);
	const [userActive, setUserActive] = useState([]);
	const [userModalActive, setUserModalActive] = useState(false);

	// Filtro de botones
	const [filteredInfo, setFilteredInfo] = useState({});
	const [sortedInfo, setSortedInfo] = useState({});
	const [modalVisible, setModalVisible] = useState(false);
	const [modalResidencialVisible, setModalResidencialVisible] = useState(false);
	const [modalVisibleZona, setModalVisibleZona] = useState(false);
	const columns = columnsAlertTable();
	//const columnsCasa = columnsCasaTable();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadSendData, setLoadSendData] = useState(false);
	const [propiedadValue, setPropiedadValue] = useState("");

	const { ExportHousesToExcel } = ExportHousesExcel();

	const getName = () => {
		const listName = [];
		nomenclaturaCasas.map((d) => {
			listName.push({
				text: d.name,
				value: d.name,
			});
		});
		return listName;
	};

	const searchName = (nameFind) => {
		let name = "-";
		const index = nomenclaturaCasas.findIndex((el) => el.name === nameFind);
		if (index > -1) {
			name = nomenclaturaCasas[index].name;
		}
		return name;
	};

	const columnsCasa = [
		{
			title: "Nombre titular",
			dataIndex: "userProfile.name",
			key: "userProfile.name",
			fixed: "left",
			width: 250,
			render: (text, record) => (
				<>
					{record.userProfile?.name} {record.userProfile?.lastname}
				</>
			),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<div style={{ padding: 8 }}>
							<Input
								autoFocus
								placeholder=""
								value={selectedKeys[0]}
								onChange={(e) => {
									setSelectedKeys(e.target.value ? [e.target.value] : []);
									confirm({ closeDropdown: false });
								}}
								onPressEnter={() => {
									confirm();
								}}
								onBlur={() => {
									confirm();
								}}
								style={{ marginBottom: 8, display: "block" }}
							></Input>
							<Space>
								<Button
									icon={<SearchOutlined />}
									onClick={() => {
										confirm();
									}}
									type="primary"
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => {
										clearFilters();
									}}
									size="small"
									style={{ width: 90 }}
								>
									Limpiar
								</Button>
							</Space>
						</div>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.userProfile?.name
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			title: "Alias",
			dataIndex: "name",
			key: "name",
			width: 80,
			fixed: "left",
			...getColumnSearchProps("name"),
		},
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: 150,
			...getColumnSearchProps("id"),
		},

		{
			title: "Perfil",
			dataIndex: "profile",
			key: "profile",
			align: "center",
			width: 100,
			render: (id, record) => (
				<img
					className="iconoPerfil"
					src="/assets/icons/Security/shape08.svg"
					style={{ cursor: "pointer" }}
					alt="security-credentials-icon"
					onClick={() => handleStatusResidentsModal(true, record)}
				/>
			),
		},
		/* {
			title: "Dirección",
			dataIndex: "address",
			key: "address",
			width: 300,
			...getColumnSearchProps("address"),
		}, */

		{
			title: "Area M2",
			dataIndex: "areaM2",
			key: "areaM2",
			width: 120,
			sorter: (a, b) => a.areaM2 - b.areaM2,
		},
		{
			title: "Area V2",
			dataIndex: "areaV2",
			key: "areaV2",
			width: 120,
			sorter: (a, b) => a.areaV2 - b.areaV2,
			render: (id, record, index) => (
				<div className="">{parseFloat(record.areaV2).toFixed(2)}</div>
			),
		},
		{
			title: "Propiedad",
			dataIndex: "houseType",
			key: "houseType",
			width: 120,
		},
		{
			title: "Categoría",
			dataIndex: "category",
			key: "category",
			width: 120,
			sorter: (a, b) => a.category - b.category,
		},
		{
			title: "Mantenimiento",
			dataIndex: "maintenanceFee",
			key: "maintenanceFee",
			width: 200,
			sorter: (a, b) => a.maintenanceFee - b.maintenanceFee,
			render: (id, record, index) => (
				<div className="">$ {parseFloat(record.maintenanceFee).toFixed(2)}</div>
			),
		},
		{
			title: "Construcción",
			dataIndex: "constructionFee",
			key: "constructionFee",
			width: 200,
			sorter: (a, b) => a.constructionFee - b.constructionFee,
			render: (id, record, index) => (
				<div className="">
					$ {parseFloat(record.constructionFee).toFixed(2)}
				</div>
			),
		},
		{
			title: "Residencia",
			dataIndex: "residenceFee",
			key: "residenceFee",
			width: 200,
			sorter: (a, b) => a.residenceFee - b.residenceFee,
			render: (id, record, index) => (
				<div className="">$ {parseFloat(record.residenceFee).toFixed(2)}</div>
			),
		},
		{
			title: "Desechos Sólidos",
			dataIndex: "alcaldia",
			key: "alcaldia",
			width: 200,
			sorter: (a, b) => a.alcaldia - b.alcaldia,
			render: (id, record, index) => (
				<div className="">$ {parseFloat(record.alcaldia).toFixed(2)}</div>
			),
		},
		{
			title: "Total",
			dataIndex: "total",
			key: "total",
			width: 120,
			render: (id, record, index) => (
				<div className="">$ {parseFloat(record.total).toFixed(2)}</div>
			),
			sorter: (a, b) => a.total - b.total,
		},
		{
			title: "Editar",
			dataIndex: "edit",
			key: "edit",
			align: "center",
			width: 100,
			render: (id, record) => (
				<div className="edit" onClick={() => openEditor(record)}>
					<Edit />
				</div>
			),
		},
	];

	const {
		name,
		numeroCasa,
		desde,
		hasta,
		resetValuesInput,
		onInputChange,
		onResetForm,
	} = useForm({
		name: "",
		numeroCasa: "",
		desde: "",
		hasta: "",
	});

	const openEditor = (item) => {
		setResidencialActive(item);
		const newValues = {
			direccion: item.address,
			numeroCasa: item.name,
			categoria: item.category,
			name: item.name,
			aream2: item.areaM2,
			areav2: parseFloat(item.areaV2).toFixed(2),
			mantenimiento: parseFloat(item.maintenanceFee).toFixed(2),
			construccion: parseFloat(item.constructionFee).toFixed(2),
			residencia: parseFloat(item.residenceFee).toFixed(2),
			alcaldia: parseFloat(item.alcaldia).toFixed(2),
			total: parseFloat(item.total).toFixed(2),
			propiedad: item.houseType,
		};
		form.setFieldsValue(newValues);

		setModalResidencialVisible(true);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	useEffect(() => {
		dispatch(getNomenclaturas());
		//dispatch( getUser() );
	}, [dispatch]);

	useEffect(() => {
		setNomenclaturaActive(nomenclaturas.active);
	}, [nomenclaturas.active]);

	useEffect(() => {
		let dataAlertTable = nomenclaturas.data.map((item, index) => {
			let data = { ...item };
			data.key = index;
			data.alert_type = item.notice_type_data?.name;
			data.edit = (
				<div
					className="linkEditar"
					onClick={() => handleStatusModalNomenclatura(true, index)}
				>
					<img src="/assets/images/Actions/iconEditar.png" alt="edit-icon" /> Editar
				</div>
			);
			data.verCasas = (
				<div
					className="linkEditar"
					onClick={() => handleStatusModalCasas(true, index)}
				>
					<img src="/assets/icons/Common/shape25.svg" alt="eye-scanner-icon" /> Ver Casas
				</div>
			);

			return data;
		});

		setNomenclaturaList(dataAlertTable);
		setLoadSendData(false);
		handleStatusModal(false);
		setNomenclaturaActive(null);
		setSelectedRowKeys([]);
		setUserList([]);
		setModalVisibleZona(false);
	}, [nomenclaturas.data]);

	useEffect(() => {
		let unsortedArr = [...nomenclaturas.casas];
		let sortedArr = unsortedArr.sort(function (a, b) {
			var aInt = parseInt(a.name);
			var bInt = parseInt(b.name);
			return aInt - bInt;
		});
		/* let _data = unsortedArr.map((item, index) => {
			let data = { ...item };
			data.profile = (
				<img
					className="iconoPerfil"
					src="/assets/icons/shape08.svg"
					style={{ cursor: "pointer" }}
					alt="Topia"
					onClick={() => handleStatusResidentsModal(true, index)}
				/>
			);
			return data;
		}); */
		setNomenclaturaCasas(sortedArr);
		handleSendingResidencial(false);
		setModalResidencialVisible(false);
		setResidencialActive(false);
		setLoadingCasa(false);
	}, [nomenclaturas.casas]);

	//Modal functions
	const handleStatusResidentsModal = async (statusModal, record = null) => {
		if (record != null) {
			let active_user = record.userProfile;

			if (active_user) {
				let id = record.idOwners[0];
				let maintenanceFee = parseFloat(record.maintenanceFee).toFixed(2);
				let constructionFee = parseFloat(record.constructionFee).toFixed(2);
				let residenceFee = parseFloat(record.residenceFee).toFixed(2);
				let alcaldia = parseFloat(record.alcaldia).toFixed(2);
				let total = parseFloat(record.total).toFixed(2);
				active_user = {
					...active_user,
					maintenanceFee: maintenanceFee,
					constructionFee: constructionFee,
					residenceFee: residenceFee,
					alcaldia: alcaldia,
					total: total,
					id: id,
				};
			}
			if (active_user.ownerHome) {
				// Agregamosal detalle los dependientes de los socios
				let relativesWithAccess = await getQueryDocByCollection(
					"/family_access",
					"idProfileMain",
					"==",
					active_user.id
				);

				let nomenclaturePath = active_user.residentials[0]?.house["path"];
				nomenclaturePath = nomenclaturePath.substring(47, 67);
				let housePath = active_user.residentials[0]?.house["path"];
				housePath = housePath.substring(75, 95);

				let houseData = await getDataByNomenclatureIdAndHouseIdHelper(
					"Yd5MV7jkA0MjQeCmzKrZ",
					nomenclaturePath,
					housePath
				);

				active_user = {
					...active_user,
					houseData: houseData,
					relativesWithAccess: relativesWithAccess,
				};
				/* if (active_user.origin_fam) {
					const billingDataHistory = await getPaymentsHelperByAccount(
						active_user.origin_fam
					).then((billingD) => {
						active_user.billingData = billingD;
					});
				}
			*/
			}
			setUserActive(active_user);
		}
		setTimeout(() => {
			setUserModalActive(statusModal);
		}, 50);
	};

	const handleChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters);
		setSortedInfo(sorter);
	};

	const handleStatusModalCasas = async (statusModal, index = null) => {
		setVisibleCasa(false);
		setNomenclaturaCasas([]);
		setVisibleCasa(true);
		setLoadingCasa(true);
		setNomenclaturaActive(nomenclaturas.data[index]);
		setModalVisible(true);

		dispatch(getNomenclaturasCasas(nomenclaturas.data[index].id));
	};

	const handleStatusModalNomenclatura = (statusModal, index = null) => {
		if (index != null) {
			var formInfo = {
				name: nomenclaturas.data[index].name,
				numeroCasa: nomenclaturas.data[index].number_residential,
				desde: nomenclaturas.data[index].start_house,
				hasta: nomenclaturas.data[index].end_house,
			};
			resetValuesInput(formInfo);

			dispatch(setActiveNomenclatura(nomenclaturas.data[index]));
		} else {
			dispatch(setActiveNomenclatura(null));
		}

		setTimeout(() => {
			setModalVisibleZona(statusModal);
		}, 50);
	};

	const handleStatusModal = (statusModal, index = null) => {
		if (index != null) {
			dispatch(setActiveNomenclatura(nomenclaturas.data[index]));

			//data de listado de usuarios
			let selectData = [];

			let dataAlertTable = nomenclaturas.user.map((item, indexChild) => {
				let data = {};
				data.key = indexChild;
				data.id = item.id;
				data.name = item.name;
				data.idRegistro = "";

				let findUser = nomenclaturas.data[index].users.find(
					(request) => request.idProfile === item.id
				);
				if (typeof findUser !== "undefined") {
					selectData.push(indexChild);
					data.idRegistro = findUser.id;
				}
				return data;
			});

			setSelectedRowKeys(selectData);
			setUserList(dataAlertTable);
		} else {
			dispatch(setActiveNomenclatura(null));
			setVisibleCasa(false);
		}

		setTimeout(() => {
			setModalVisible(statusModal);
		}, 50);
	};

	const onHandleZona = () => {
		if (!name) {
			toast.warn("Debe ingresar nombre de zona");
			return;
		}

		setLoadSendData(true);

		let data = {
			name,
			numeroCasa,
			desde,
			hasta,
			nomenclaturaActive,
		};

		dispatch(setNomenclaturas(data));
	};

	/* const onHouseFormChange = async() => {

  } */

	const onHandleGuardarResidencial = async () => {
		const values = await form.validateFields();
		if (values.numeroCasa === "") {
			toast.warn("Debe ingresar número de casa");
			return;
		} else if (values.categoria === "") {
			toast.warn("Debe ingresar categoría");
			return;
		} else if (values.aream2 === "") {
			toast.warn("Debe ingresar area m2");
			return;
		} else if (values.areav2 === "") {
			toast.warn("Debe ingresar area v2");
			return;
		} else if (values.mantenimiento === "") {
			toast.warn("Debe ingresar cuota de mantenimiento");
			return;
		} else if (values.construccion === "") {
			toast.warn("Debe ingresar cuota de construccion " + values.construccion);
			return;
		} else if (values.residencia === "") {
			toast.warn("Debe ingresar cuota de residencia");
			return;
		} else if (values.alcaldia === "") {
			toast.warn("Debe ingresar cuota de alcaldia");
			return;
		} else if (values.total === "") {
			toast.warn("Debe ingresar total a pagar");
			return;
		} else if (values.houseType === "") {
			toast.warn("Debe ingresar el tipo de propiedad");
			return;
		} else {
			var dataCasa = {
				name: values.numeroCasa + "",
				address: values.direccion,
				areaM2: values.aream2,
				areaV2: values.areav2,
				category: values.categoria,
				maintenanceFee: values.mantenimiento,
				constructionFee: values.construccion,
				residenceFee: values.residencia,
				total: values.total,
				alcaldia: values.alcaldia,
				houseType: values.propiedad,
			};
			//ARREGAR BUG ID
			var path = `/residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/${nomenclaturaActive.id}/houses/${residencialActive.id}`;

			handleSendingResidencial(true);
			dispatch(saveResidencial(dataCasa, path, residencialActive.id));
		}
	};

	const onHandleGuardar = () => {
		if (!name) {
			toast.warn("Debe ingresar nombre de zona");
			return;
		}

		if (nomenclaturaActive == null) {
			if (!numeroCasa) {
				toast.warn("Debe ingresar número de casas");
				return;
			}

			if (!desde) {
				toast.warn("Debe ingresar el número de inicio de las casas");
				return;
			}

			if (!hasta) {
				toast.warn("Debe ingresar el número de fin de las casas");
				return;
			}

			var contarCasa = parseInt(hasta) - parseInt(desde);
			if (contarCasa !== numeroCasa) {
				toast.warn(
					"El numero de casas ingresadas no coincide con el total de correlativos"
				);
				return;
			}
			setLoadSendData(true);

			let data = {
				name,
				numeroCasa,
				desde,
				hasta,
				nomenclaturaActive,
			};

			dispatch(setNomenclaturas(data));
		} else {
			setLoadSendData(true);
			let data = {
				name,
				nomenclaturaActive,
			};

			dispatch(setNomenclaturas(data));
		}
	};
	const totalHousePay = (houseData) => {
		let total = 0;
		houseData.map((item) => {
			total += +parseFloat(item.total).toFixed(2);
		});
		total = parseFloat(total).toFixed(2);
		return total;
	};

	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	return (
		<>
			<div className="contBotonZona">
				<Button
					className="btn-primary-radio"
					onClick={() => {
						onResetForm();
						handleStatusModalNomenclatura(true);
					}}
				>
					<img src="/assets/images/Actions/shape20.png"  alt="add-plus-icon"/>
					NUEVA ZONA
				</Button>
			</div>

			<Table
				columns={columns}
				scroll={{ y: "calc(100vh - 19em)" }}
				dataSource={nomenclaturaList}
				onChange={handleChange}
				pagination={{ pageSize: 20 }}
			/>

			<Modal
				className="primary-modal"
				title={"ZONA"}
				visible={modalVisibleZona}
				onCancel={() => {
					setModalVisibleZona(false);
				}}
				footer={
					<Row>
						<Col span={6} style={{ textAlign: "left" }}></Col>

						<Col span={12} style={{ textAlign: "right" }}>
							<Button
								// disabled={requests.isSaving}
								className="btn-primary"
								type="primary"
								shape="round"
								style={{ width: "100%" }}
								onClick={() => {
									onHandleGuardar();
								}}
							>
								{loadSendData ? (
									<div className="centerSpinner">
										{" "}
										<Spinner />
									</div>
								) : (
									"GUARDAR"
								)}
							</Button>
						</Col>
					</Row>
				}
			>
				{
					<Row className="section-modal">
						<Col span={24}>
							<form action="">
								<div className="form-group">
									<label htmlFor="">NOMBRE DE LA ZONA:</label>
									<input
										className="form-control"
										name="name"
										value={name}
										onChange={onInputChange}
									/>
								</div>
								<div className="espacio"></div>
								{nomenclaturaActive == null ? (
									<div>
										<div className="form-group">
											<label htmlFor="">NÚMERO DE CASAS:</label>
											<input
												className="form-control"
												name="numeroCasa"
												type="number"
												value={numeroCasa}
												onChange={onInputChange}
											/>
										</div>
										<div className="espacio"></div>
										<div className="form-group">
											<label htmlFor="">CORRELATIVO DE CASAS:</label>
											<div className="correlativo">
												<Row>
													<Col span={12} className="desdeHasta">
														<label htmlFor="">DESDE:</label>
														<input
															type="number"
															className="form-control"
															name="desde"
															value={desde}
															onChange={onInputChange}
														/>
													</Col>
													<Col span={12} className="desdeHasta">
														<label htmlFor="">HASTA:</label>
														<input
															type="number"
															className="form-control"
															name="hasta"
															value={hasta}
															onChange={onInputChange}
														/>
													</Col>
												</Row>
											</div>
										</div>
									</div>
								) : null}
							</form>
						</Col>
					</Row>
				}
			</Modal>

			{nomenclaturaActive ? (
				<Modal
					className="side-modal alert-modal modalEncuesta"
					bodyStyle={{
						overflowY: "auto",
						maxHeight: "calc(100vh - 100px)",
						paddingBottom: "0px",
					}}
					title={
						<Row
							className="encabezado"
							style={{
								textAlign: "left",
								paddingLeft: "25px",
								paddingRight: "75px",
							}}
						>
							<Col span={15}>
								<div>NOMBRE DE ZONA</div>
								<h2>{nomenclaturaActive.name}</h2>
							</Col>
							<Col span={3} style={{ display: "flex" }}>
								<div className="bloqueEncuesta">
									TOTAL CASAS:
									<div className="numeroEncuesta">
										{nomenclaturaActive.number_residential}
									</div>
								</div>
							</Col>
							<Col span={3} style={{ display: "flex" }}>
								<div className="bloqueEncuesta">
									TOTAL A PAGAR:
									<div className="numeroEncuesta">
										{currencyFormat(totalHousePay(nomenclaturaCasas))}
									</div>
								</div>
							</Col>
							<Col span={3} style={{ display: "flex" }}>
								<div className="bloqueEncuesta">
									<img
										src="/assets/icons/Documents/exportSheets.svg"
										alt="export-excel"
										style={{ cursor: "pointer" }}
										onClick={() => {
											ExportHousesToExcel(nomenclaturaCasas);
										}}
									/>
								</div>
							</Col>
						</Row>
					}
					style={{ top: 0, bottom: 0, right: 0, width: "100%" }}
					visible={modalVisible}
					onCancel={() => handleStatusModal(false)}
					footer={null}
					width={"95%"}
				>
					<div className="scroll">
						{nomenclaturaActive && (
							<>
								<div style={{ height: "85vh" }}>
									{visibleCasas ? (
										<Table
											className="primary-table"
											loading={loadingCasa}
											columns={columnsCasa}
											dataSource={nomenclaturaCasas}
											rowKey="id"
											pagination={{ defaultPageSize: 10 }}
											scroll={{ x: 2110, y: "calc(100vh - 19em)" }}
										/>
									) : null}
								</div>
							</>
						)}
					</div>
				</Modal>
			) : null}

			{residencialActive ? (
				<Modal
					className="primary-modal"
					title={"RESIDENCIAL"}
					visible={modalResidencialVisible}
					onCancel={() => {
						setModalResidencialVisible(false);
					}}
					footer={
						<Row>
							<Col span={6} style={{ textAlign: "left" }}></Col>

							<Col span={12} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									loading={sendingResidencial}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "100%" }}
									onClick={() => {
										onHandleGuardarResidencial();
									}}
								>
									{loadSendData ? (
										<div className="centerSpinner">
											{" "}
											<Spinner />
										</div>
									) : (
										"GUARDAR"
									)}
								</Button>
							</Col>
						</Row>
					}
				>
					{
						<Row className="section-modal">
							<Col span={24}>
								<Form
									form={form}
									layout="vertical"
									requiredMark={true}
									onValuesChange={async (changedValues, allValues) => {
										const mantenimiento = parseFloat(
											allValues.mantenimiento
										).toFixed(2);
										const construccion = parseFloat(
											allValues.construccion
										).toFixed(2);
										const residencia = parseFloat(allValues.residencia).toFixed(
											2
										);
										const alcaldia = parseFloat(allValues.alcaldia).toFixed(2);
										const values = [
											parseFloat(mantenimiento).toFixed(2),
											parseFloat(construccion).toFixed(2),
											parseFloat(residencia).toFixed(2),
											parseFloat(alcaldia).toFixed(2),
										];
										const mixedSum = (arr = []) => {
											let sum = 0;
											for (let i = 0; i < arr.length; i++) {
												const el = parseFloat(arr[i]).toFixed(2);
												sum += +el;
											}
											return parseFloat(sum).toFixed(2);
										};
										let total = mixedSum(values);
										form.setFieldsValue({ total: total });
									}}
								>
									<Form.Item
										label={<span>Dirección:</span>}
										name="direccion"
										className="form-group"
									>
										<TextArea
											rows={4}
											size="large"
											className="inputFormControl"
										/>
									</Form.Item>

									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Número Casa:</span>}
												name="numeroCasa"
												className="form-group"
												rules={[
													{ required: true, message: "Ingrese número de casa" },
												]}
											>
												<Input size="large" className="inputFormControl" />
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Categoría:</span>}
												name="categoria"
												className="form-group"
												rules={[
													{ required: true, message: "Ingrese categoría" },
												]}
											>
												<InputNumber
													size="large"
													min={0}
													max={100}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Area M2:</span>}
												name="aream2"
												className="form-group"
												rules={[{ required: true, message: "Ingrese Area M2" }]}
											>
												<InputNumber
													size="large"
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Area V2:</span>}
												name="areav2"
												className="form-group"
												rules={[{ required: true, message: "Ingrese Area V2" }]}
											>
												<InputNumber
													size="large"
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Cuota de Mantenimiento:</span>}
												name="mantenimiento"
												className="form-group"
												rules={[
													{
														required: true,
														message: "Ingrese cuota mantenimiento",
													},
												]}
											>
												<InputNumber
													size="large"
													precision={2}
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Cuota Construcción:</span>}
												name="construccion"
												className="form-group"
												rules={[
													{
														required: true,
														message: "Ingrese cuota mantenimiento",
													},
												]}
											>
												<InputNumber
													size="large"
													precision={2}
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={12}>
											<Form.Item
												label={<span>Cuota de Residencia:</span>}
												name="residencia"
												className="form-group"
												rules={[
													{
														required: true,
														message: "Ingrese cuota residencia",
													},
												]}
											>
												<InputNumber
													size="large"
													precision={2}
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={<span>Alcaldia / Recoleccion basura:</span>}
												name="alcaldia"
												className="form-group"
												rules={[
													{ required: true, message: "Ingrese alcaldia" },
												]}
											>
												<InputNumber
													size="large"
													precision={2}
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
									<div className="espacio"></div>
									<Row gutter={20}>
										<Col span={24}>
											<Form.Item
												label={<span>Total a pagar:</span>}
												name="total"
												className="form-group"
												rules={[
													{ required: true, message: "Ingrese total a pagar" },
												]}
											>
												<InputNumber
													size="large"
													disabled
													min={0}
													max={1000000}
													className="inputFormControl"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={20}>
										<Col span={24}>
											<Form.Item
												label={
													<span>Seleccione el estado de la propiedad:</span>
												}
												name="propiedad"
												className="form-group"
												rules={[
													{
														required: true,
														message: "Ingrese el estado de la propiedad",
													},
												]}
											>
												<Radio.Group>
													<Space>
														<Radio value={"Lote"}>Lote</Radio>
														<Radio value={"Residencia"}>Residencia</Radio>
														<Radio value={"Construccion"}>Construcción</Radio>
													</Space>
												</Radio.Group>
											</Form.Item>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					}
				</Modal>
			) : null}

			{/* MODAL USUARIO */}
			<UserProfileModal
				userActive={userActive}
				userModalActive={userModalActive}
				handleStatusResidentsModal={handleStatusResidentsModal}
			/>
		</>
	);
};
