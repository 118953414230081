import React from "react";
import { Col, Modal, Row } from "antd";
import { mensaje, phone } from "../assets/icons";
import { avatarUser } from "../assets/images";

const AdministratorProfileModal = ({
	userActive,
	userModalActive,
	handleStatusAdminModal,
}) => {
	return (
		<>
			<Modal
				className="side-modal"
				title="Datos del administrador"
				style={{ top: 0, right: 0 }}
				visible={userModalActive}
				onCancel={() => handleStatusAdminModal(false)}
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
				footer={null}
				width={600}
			>
				{userActive ? (
					<>
						<Row className="section-modal" style={{ paddingTop: "0px" }}>
							<Col span={24} style={{ paddingLeft: "20px" }}>
								<div className="user-data">
									{userActive.image ? (
										<div
											style={{
												backgroundImage: `url(` + userActive.image + `)`,
											}}
											className="user-image avatarUser"
											src={userActive.image}
											alt="Topia"
										/>
									) : (
										<img
											className="user-image"
											src={avatarUser}
											alt="avatarUser"
										/>
									)}

									<div className="user-info" style={{ paddingLeft: "20px" }}>
										<span className="user-name">
											{userActive.name} {userActive.lastname}
										</span>
										<span
											className="user-address"
											style={{ paddingTop: "5px", paddingBottom: "5px" }}
										>
											{"Administradora/Administrador"}
											{/* {userActive.role_id ? userActive.role_id : "N/A"} */}
										</span>
										{/* <span className="user-address">
											{" "}
											{userActive?.address}{" "}
										</span> */}
										<div style={{ display: "flex" }}>
											<span
												style={{
													color: "#10B48C",
													display: "flex",
													alignItems: "flex-start",
													margin: "0px",
												}}
											>
												<img
													className="iconoPerfil"
													src={phone}
													alt="phone-icon"
												/>

												<span style={{ marginLeft: "5x" }}>
													{userActive.phone ? userActive.phone : ""}
												</span>
											</span>

											<span className="contEmail">
												<img
													className="iconoPerfil"
													src={mensaje}
													alt="message-icon"
												/>
												<div className="contEmails">
													<span className="emails">{userActive.email}</span>
													{/* <span className="emails">{userActive.emailTwo}</span> */}
												</div>
											</span>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</>
				) : (
					<>
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					</>
				)}
			</Modal>
		</>
	);
};

export default AdministratorProfileModal;
