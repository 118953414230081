import { Row } from 'antd'

//Componentes que renderiza las especificaciones de una solicitud de la reserva en la parte izquierda de la pantalla
//que incluye de forma fija textos como la aninmación, la casa club, el lugar y el área adicional
const Specifications = ({ specificationsData }) => (
  <section className='section-request-booking'>
    <h4>Especificaciones</h4>
    <div className='specification-item-container'>
      <small>Animación:</small>
      <Row style={{ gap: '8px' }}>
        {
          specificationsData?.animation_items?.map((item, index) => (
            <div key={index} style={{ border: '1px solid #B6B6B6', padding: '4px 12px', borderRadius: '8px' }} >
              {item}
            </div>
          ))
        }
      </Row>
    </div>
    <div className='specification-item-container'>
      <small>Casa club:</small>
      <span>{specificationsData?.reservation_place_name}</span>
    </div>
    <div className='specification-item-container'>
      <small>Lugar:</small>
      <Row style={{ gap: '8px' }}>
        {
          specificationsData?.reservation_places?.map((item, index) => (
            <div key={index} style={{ border: '1px solid #B6B6B6', padding: '4px 12px', borderRadius: '8px' }} >
              {item}
            </div>
          ))
        }
      </Row>
    </div>
    <div className='specification-item-container'>
      <small>Area adicional:</small>
      <span>{specificationsData?.aditional_area?.[0]}</span>
    </div>
  </section>
)

export default Specifications
