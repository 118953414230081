
import { onCreateOrUpdate } from "../../helpers/globalHelper";
import moment from 'moment';
import { toast } from 'react-toastify';
import { getAnnouncementsHelper } from "../../helpers/announcementsHelper";
import { createAnnouncement, setAnnouncement } from "./announcementSlice";

export const getAnnouncements = () => {
    return async( dispatch, getState ) => {
        
        const { committee } = getState().auth;
        const announcements = await getAnnouncementsHelper(committee.residential_id);
        Promise.all(announcements).then( data => {
            dispatch(setAnnouncement(data))
        })

    }
}

export const setAnnouncements = (data) => {
    return async( dispatch, getState ) => {
        const { committee, uid } = getState().auth;
        
        data.createdBy = uid;
        data.residential_id = committee.residential_id;
        data.created_at = moment().format("DD-MM-Y");
        data.active = true;
        const createdAnnouncement = await onCreateOrUpdate(`/announcement/`, data);
        data.id = createdAnnouncement.id;

        dispatch( createAnnouncement(data) );



    }
}
