import {
	getAlertsHelper,
	getAlertsHelperByProfileId,
	getAlertsHelperByUserId,
	getNoticeTypeDataOfMessage,
} from "../../helpers/alertHelper";
import {
	getResidentialsById,
	getUserDataProfileById,
} from "../../helpers/globalHelper";

import { setAlertsData } from ".";

export const getAlerts = (fechaInicio, fechaFinal) => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		const messagesData = await getAlertsHelper(
			committee.residential_id,
			fechaInicio,
			fechaFinal
		);

		let alerts = messagesData.map(async (item, index) => {
			let noticeType = await getNoticeTypeDataOfMessage(item.notice_type);
			let residentials = await getResidentialsById(item.residential);
			let userFrom = await getUserDataProfileById(item.user_from);

			return {
				...messagesData[index],
				notice_type_data: noticeType,
				user_from_data: userFrom,
				residential_data: residentials,
			};
		});

		Promise.all(alerts).then((data) => {
			dispatch(setAlertsData(data));
		});
	};
};

export const getAlertsByUserId = (fechaInicio, fechaFinal, profile_id) => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		const messagesData = await getAlertsHelperByUserId(
			committee.residential_id,
			fechaInicio,
			fechaFinal,
			profile_id
		);

		let alerts = messagesData.map(async (item, index) => {
			let noticeType = await getNoticeTypeDataOfMessage(item.notice_type);
			let residentials = await getResidentialsById(item.residential);
			let userFrom = await getUserDataProfileById(item.user_from);

			return {
				...messagesData[index],
				notice_type_data: noticeType,
				user_from_data: userFrom,
				residential_data: residentials,
			};
		});

		Promise.all(alerts).then((data) => {
			dispatch(setAlertsData(data));
		});
	};
};
