import logoSidebar from "../assets/logo-sidebar.png";
import { paymentsIcon } from '../../../../assets/icons'

export const DefaultTheme = {
  //Definir los estilos por defecto de adminTopia...
  themeId: "DefaultSidebar",
  sidebarlogo: logoSidebar,
  sidebarBackground: "#10b48c",
  items: [
    {
      itemName: "homeItem",
      to: "/",
      image: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/default/c19671c8-76df-45ed-b895-2227a3c2dd83.png',
      activeImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/default/f1402638-bfc8-4d6f-aa9c-523db3082d7c.png',
      backgroundColor: "#10b48c",
      activeBackgroundColor: "#232C4A",
      color: "#fff",
      activeColor: "#fff",
      name: "INICIO",
    },
    {
      itemName: "accessItem",
      to: "/accesos",
      image: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/default/67b2f54b-95d3-40f3-a547-e6639a201973.png',
      activeImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/default/2d16b3df-6551-4748-9b9c-748b9f39d2b4.png',
      backgroundColor: "#10b48c",
      activeBackgroundColor: "#232C4A",
      color: "#fff",
      activeColor: "#fff",
      name: "ACCESOS",
      submenu: 'access'
    },
    {
      itemName: "messagesItem",
      to: "/noticias",
      image: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/default/a524ce4a-d5db-42fd-94a3-9063d4f8b298.png',
      activeImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/default/4da61bdf-625a-4ea6-85d0-a227ae1dfe9f.png',
      backgroundColor: "#10b48c",
      color: "#fff",
      activeBackgroundColor: "#232C4A",
      activeColor: "#fff",
      name: "MENSAJERÍA",
      submenu: "messages",
    },
    {
      itemName: "paymentsItem",
      to: "/pagos",
      image: paymentsIcon,
      activeImage: paymentsIcon,
      backgroundColor: "#10b48c",
      activeBackgroundColor: "#232C4A",
      color: "#fff",
      activeColor: "#fff",
      name: "COBROS Y PAGOS",
      submenu: "payments",
    },
    {
      itemName: "settingsItem",
      image: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/default/5935000a-2cfa-4aef-805e-205106320312.png',
      activeImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/default/ff1270f7-0460-4205-ad8f-c75245cd79ea.png',
      backgroundColor: "#10b48c",
      color: "#fff",
      activeBackgroundColor: "#232C4A",
      activeColor: "#fff",
      submenu: "settings",
      name: "AJUSTES",
    },
  ],
  submenuStyle: {
    backgroundColor: "#232c4a",
    titleColor: "#10b48c",
    color: "#bcccff",
  },
  navbar: {
    dropdownProfile: {
      activeBackgroundColor: '#10B48C',
      activeColor: "#fff",
      color: "#252525",
      backgroundColor: '#fff',
    }
  }
};
