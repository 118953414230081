import { ReactComponent as WorkerReportIcon } from '../assets/workerReportIcon.svg'
import { ReactComponent as ProviderReportIcon } from '../assets/workerReportIcon.svg'
import { Button } from 'antd'
import moment from 'moment'

export const historyAccessTableColumnsPrev = ({ handleWorkers, handleProviders }) => ([
    {
        title: 'FECHA DE ACCESO',
        dataIndex: 'accessDate',
        key: 'access_date',
        width: '15%',
        render: (text, record) => {
            return record.accessDate && <span>{moment(record.accessDate).format('DD/MM/YYYY')}</span>
        }
    },
    {
        title: 'INGRESO CONTRATISTA',
        dataIndex: 'contratorAccess',
        key: 'contratorAccess',
        width: '15%',
        align: 'center',
        render: (text, record) => {
            return <span>{record.contractorAccess ? 'SI' : 'NO'}</span>
        }

    },
    {
        title: 'TRABAJADORES QUE INGRESARON',
        dataIndex: 'workerAccess',
        key: 'workerAccess',
        width: '20%',
        align: 'center',
    },
    {
        title: 'REPORTE TRABAJADORES',
        dataIndex: 'workers',
        key: 'workers',
        width: '15%',
        align: 'center',
        render: (text, record) => {
            return <Button style={{ border: 'none', backgroundColor: 'transparent' }} className='btn-standard-green' shape='round' onClick={() => handleWorkers({ record })} icon={<WorkerReportIcon />} />
        }
    },
    {
        title: 'PROVEEDORES QUE INGRESARON',
        dataIndex: 'providerAccess',
        key: 'providerAccess',
        width: '20%',
        align: 'center',
    },
    {
        title: 'REPORTE PROVEEDORES',
        dataIndex: 'providers',
        key: 'providers',
        width: '20%',
        align: 'center',
        render: (text, record) => {
            return <Button style={{ border: 'none', backgroundColor: 'transparent' }} className='btn-standard-green' shape='round' onClick={() => handleProviders({ record })} icon={<ProviderReportIcon />} />
        }
    },
])

export const historyAccessTableFilters = ({ currentParams }) => ({
    accessDate: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'access_date' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    default: () => ({}),
})