import { getUserDataProfileById } from "../../../../../helpers/globalHelper";

export const getProfileData = async ({
    profile_id
}) => {

    try {
        const res = await getUserDataProfileById(profile_id)
        return {
            image: res.image,
        }

    } catch (error) {
        return error
    }

}