
import { onCreateOrUpdate,onUpdateDocWithRef,onDeleteDoc} from "../../helpers/globalHelper";
import moment from 'moment';
import { toast } from 'react-toastify';
import { getSurveysHelper,getSurveysResponseHelper,getSurveysQuestionHelper,getAllUserResidential } from "../../helpers/surveysHelper";
import { createSurveys, setSurvey } from "./surveysSlice";

export const getSurveys = () => {
    return async( dispatch, getState ) => {
        
        const { committee } = getState().auth;

        const allUserResidential = await getAllUserResidential(committee.residential_id);
        const surveys = await getSurveysHelper(committee.residential_id);

        var i = 0;
        while (i < surveys.length) {
            var encuestasContestadas = 0
            var idActual = ''
            surveys[i].respuesta = await getSurveysResponseHelper(committee.residential_id,surveys[i].id);
            surveys[i].allUserResidential = allUserResidential
            var j = 0;
            while (j < surveys[i].respuesta.length) {
                if(idActual != surveys[i].respuesta[j].profile_id){
                    idActual = surveys[i].respuesta[j].profile_id
                    encuestasContestadas++
                }
                ++j;
            }
            surveys[i].encuestasContestadas = encuestasContestadas
            surveys[i].opcionesGrafica = []
            surveys[i].preguntas = await getSurveysQuestionHelper(surveys[i].id);

            ++i;
        }
        //const surveys = await getSurveysHelper(committee.residential_id);

        
        Promise.all(surveys).then( data => {
            dispatch(setSurvey(data))
        })

    }
}

export const setSurveys = (data) => {
    return async( dispatch, getState ) => {
        
        const { committee, uid } = getState().auth;
        
        var startDate =  moment(data.startDate, 'DD/MM/YYYY').toDate();
        var endDate =  moment(data.endDate, 'DD/MM/YYYY').toDate();
        var listPreguntas =  data.listPreguntas;
        var listPreguntasEliminadas =  data.listPreguntasEliminadas;

        delete data.listPreguntas
        delete data.listPreguntasEliminadas

        data.createdBy = uid;
        data.residential_id = committee.residential_id;
        data.created_at = moment(new Date(), 'DD/MM/YYYY').toDate();
        data.active = true;
        if(data.eraser ===  false){
            data.startDate = startDate;
            data.endDate = endDate;
        }
        
        var idSurveys = ""

        if(data.id === ""){
            delete data.id
            const createdSurveys = await onCreateOrUpdate(`/surveys/`, data);
            idSurveys = createdSurveys.id

        }else{
            idSurveys = data.id
            await onUpdateDocWithRef(`/surveys/${idSurveys}`, data);
        }

      
         //eliminar preguntas
         var z = 0;
         while (z < listPreguntasEliminadas.length) {
            const deleteSurveys = await onDeleteDoc(`/surveys/`+idSurveys+`/questions`,listPreguntasEliminadas[z].idR);
            ++z;
        }


        var questions = []
        var i = 0;

        while (i < listPreguntas.length) {
          var itemQuestion = {}
          itemQuestion.order = i
          itemQuestion.type = listPreguntas[i].type
          itemQuestion.question = listPreguntas[i].question
          if(listPreguntas[i].type === "option"){
            var option = []
            var j = 0;
            while (j < listPreguntas[i].opciones.length) {
                option.push(listPreguntas[i].opciones[j].valor)
                ++j;
            }
            itemQuestion.option = option
          }

       
          if(listPreguntas[i].idR === 0){
            const createdSurveysQ = await onCreateOrUpdate(`/surveys/`+idSurveys+`/questions`, itemQuestion);
            itemQuestion.idR = createdSurveysQ.id

          }else{
            await onUpdateDocWithRef(`/surveys/`+idSurveys+`/questions/`+listPreguntas[i].idR, itemQuestion);
            itemQuestion.idR = listPreguntas[i].idR

          }

          questions.push(itemQuestion)

          ++i;
        }

       


        data.id = idSurveys;
        data.preguntas = questions
        data.respuesta = []
        data.opcionesGrafica = []
        dispatch( createSurveys(data) );



    }
}

