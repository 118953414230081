import aprobar from './Actions/aprobar.png';
import basura from './Actions/basura.png';
import botonAgregar from './Actions/botonAgregar.png';
import botonAgregarSmall from './Actions/botonAgregarSmall.png';
import cerrarIcon from './Actions/cerrarIcon.png';
import iconEditar from './Actions/iconEditar.png';
import plusIconWhite from './Actions/plusIconWhite.png';
import shape20 from './Actions/shape20.png';
import shape29 from './Actions/shape29.png';
import shape11 from './Arrows/shape11.png';
import shape12 from './Arrows/shape12.png';
import shape13 from './Arrows/shape13.png';
import shape22 from './Arrows/shape22.png';
import shape33 from './Arrows/shape33.png';
import avatarUser from './Avatar/avatarUser.png';
import userProfile from './Avatar/userProfile.png';
import backgroundLogin from './Backgrounds/backgroundLogin.png';
import horario from './Common/horario.png';
import iconEquipoTrabajo from './Common/iconEquipoTrabajo.png';
import news from './Common/news.png';
import shape06 from './Common/shape06.png';
import shape07 from './Common/shape07.png';
import shape15 from './Common/shape15.png';
import shape19 from './Common/shape19.png';
import shape25 from './Common/shape25.png';
import shape35 from './Common/shape35.png';
import vision from './Common/vision.png';
import hojaExcel from './Documents/hojaExcel.png';
import sheets from './Documents/sheets.png';
import shape from './Empty/shape.png';
import shape24 from './Empty/shape24.png';
import sinEncuesta from './Empty/sinEncuesta.png';
import docOnline from './Feedback/docOnline.png';
import pulgarArriba from './Feedback/pulgarArriba.png';
import shape01 from './Feedback/shape01.png';
import shape02 from './Feedback/shape02.png';
import shape10 from './Feedback/shape10.png';
import shape23 from './Feedback/shape23.png';
import shape34 from './Feedback/shape34.png';
import checkPago from './Form/checkPago.png';
import shape26 from './Form/shape26.png';
import shape27 from './Form/shape27.png';
import factura from './Money/factura.png';
import metodoPago from './Money/metodoPago.png';
import shape03 from './Money/shape03.png';
import shape14 from './Money/shape14.png';
import alfiler from './Security/alfiler.png';
import shape05 from './Security/shape05.png';
import shape08 from './Security/shape08.png';
import shape17 from './Security/shape17.png';
import shape18 from './Security/shape18.png';
import shape28 from './Security/shape28.png';
import shape32 from './Security/shape32.png';
import trackingIcon from './Security/trackingIcon.png';
import blueCircle from './Shapes/Circles/blueCircle.png';
import darkblueCircle from './Shapes/Circles/darkblueCircle.png';
import grayCircle from './Shapes/Circles/grayCircle.png';
import greenCircle from './Shapes/Circles/greenCircle.png';
import shape31 from './Shapes/Circles/shape31.png';
import senal from './Shapes/senal.png';
import accessIcon from './Sidebar/accessIcon.png';
import configIcon from './Sidebar/configIcon.png';
import homeIcon from './Sidebar/homeIcon.png';
import messagesIcon from './Sidebar/messagesIcon.png';
import paymentsIcon from './Sidebar/paymentsIcon.png';
import reporte from './Sidebar/reporte.png';
import reporteIcon from './Sidebar/reporteIcon.png';
import usersIcon from './Sidebar/usersIcon.png';
import mensaje from './Social/mensaje.png';
import phone from './Social/phone.png';
import shape04 from './Social/shape04.png';
import shape09 from './Social/shape09.png';
import shape16 from './Social/shape16.png';
import shape21 from './Social/shape21.png';
import shape30 from './Social/shape30.png';
import shape36 from './Social/shape36.png';
import telefonoNegro from './Social/telefonoNegro.png';

export {
  aprobar,
  basura,
  botonAgregar,
  botonAgregarSmall,
  cerrarIcon,
  iconEditar,
  plusIconWhite,
  shape20,
  shape29,
  shape11,
  shape12,
  shape13,
  shape22,
  shape33,
  avatarUser,
  userProfile,
  backgroundLogin,
  horario,
  iconEquipoTrabajo,
  news,
  shape06,
  shape07,
  shape15,
  shape19,
  shape25,
  shape35,
  vision,
  hojaExcel,
  sheets,
  shape,
  shape24,
  sinEncuesta,
  docOnline,
  pulgarArriba,
  shape01,
  shape02,
  shape10,
  shape23,
  shape34,
  checkPago,
  shape26,
  shape27,
  factura,
  metodoPago,
  shape03,
  shape14,
  alfiler,
  shape05,
  shape08,
  shape17,
  shape18,
  shape28,
  shape32,
  trackingIcon,
  blueCircle,
  darkblueCircle,
  grayCircle,
  greenCircle,
  shape31,
  senal,
  accessIcon,
  configIcon,
  homeIcon,
  messagesIcon,
  paymentsIcon,
  reporte,
  reporteIcon,
  usersIcon,
  mensaje,
  phone,
  shape04,
  shape09,
  shape16,
  shape21,
  shape30,
  shape36,
  telefonoNegro,
};
