import React from "react";
import { Col, Row, Table, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";

export const UserProfilePermissions = ({ permissionDataN }) => {
	const [permissionData, setPermissionData] = useState([]);
	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};
	const perm = permissionDataN;
	const columns = [
		{
			title: "Fecha",
			dataIndex: "event_date",
			key: "event_date",
			align: "center",
			/* render: (text, record) => (
				<>{moment(record.date_action).format("YY/MM/D")}</>
			), */
		},
		{
			title: "Hora",
			dataIndex: "event_time",
			key: "event_time",
			align: "center",
		},
		{
			title: "Nombre visitante",
			dataIndex: "name",
			key: "name",
			align: "center",
			/* render: (userData) => (
				<>
					{userData.arrive ? (
						<>
							<img src="/assets/images/shape12.png" />
							<span style={{ marginLeft: "10px" }}>Entró</span>
						</>
					) : (
						<>
							<img src="/assets/images/shape13.png" />
							<span style={{ marginLeft: "10px" }}>Salió</span>
						</>
					)}
				</>
			), */
		},
	];
	return (
		<>
			<Table
				className="primary-table"
				pagination={{ defaultPageSize: 7, simple: true }}
				columns={columns}
				dataSource={perm}
				loading="true"
				rowKey="id"
				locale={locale}
			/>
		</>
	);
};
