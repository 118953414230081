import {
	Col,
	Row,
	Button,
	Modal,
	Radio,
	Table,
	message,
	Upload,
	Input,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getAdminCobros,
	setActiveAdminCobro,
	getUserCobros,
	saveAdminCobro,
	saveCobroService,
	saveCobroAguaToPaymentCollection,
} from "../../../store/admincobros";
import "./style.css";
import { useForm } from "../../../hooks";
import TextArea from "antd/lib/input/TextArea";
import { toast } from "react-toastify";
import { Select } from "antd";
import { DatePicker, Space } from "antd";
import moment from "moment-timezone";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { utils, writeFile } from "xlsx";
import { async } from "@firebase/util";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { getColumnSearchProps } from "../../../utils/tables";
import {
	getNomenclaturasCasasHelper,
	getProfileByHouseIdHelper,
} from "../../../helpers/nomenclaturasHelper";
import { getHouseByAlias } from "../../../helpers/adminCobrosHelper";
import { getPaymentsHelperByTemplateId } from "../../../helpers/paymentsHelper";
import ExportCobroToExcel from "./ExportCobroToExcel";
import { getUserDataProfileById } from "../../../helpers/globalHelper";
import { SearchOutlined } from "@ant-design/icons";
import { laptopExcel } from '../../../assets/icons'
import { shape20, metodoPago, shape22, shape, factura, vision, reporte, sheets } from '../../../assets/images'

const { Option } = Select;
const { RangePicker } = DatePicker;

export const AdminCobrosPage = () => {
	const navigate = useNavigate();
	const [surveysList, setSurveysList] = useState([]);
	const [opcionesGrafica, setOpcionesGrafica] = useState([]);
	const [adminCobroDetail, setAdminCobroDetail] = useState(null);
	const [adminCobroNuevo, setAdminCobroNuevo] = useState(null);
	const { adminCobros } = useSelector((state) => state.adminCobros);
	const [isModalVisiblePasoUno, setIsModalVisiblePasoUno] = useState(false);
	const [isModalVisiblePasoDos, setIsModalVisiblePasoDos] = useState(false);
	const [isModalVisiblePasoTres, setIsModalVisiblePasoTres] = useState(false);
	const [isModalVisibleUser, setIsModalVisibleUser] = useState(false);
	const [listPreguntas, setListPreguntas] = useState([]);
	const [listPreguntasEliminadas, setListPreguntasEliminadas] = useState([]);
	const [tipoCobro, setTipoCobro] = useState("Mensual");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [modalVisible, setModalVisible] = useState(false);
	const [loadMoreData, setLoadMoreData] = useState(false);
	const [handleSelectTabs, setHandleSelectTabs] = useState(false);
	const [idBorrado, setIdBorrado] = useState("");
	const [diaEmision, setDiaEmision] = useState("");
	const [diaVencimiento, setDiaVencimiento] = useState("");
	const [userPagoList, setUserPagoList] = useState([]);
	const [onlyRead, setOnlyRead] = useState(false);
	const [visibleRegresarPasoDos, setVisibleRegresarPasoDos] = useState(false);
	const [moraModalActive, setMoraModalActive] = useState(false);
	const [excelDataWithHouseData, setExcelDataWithHouseData] = useState([]);
	const [fullExcelData, setFullExcelData] = useState([]);
	const [loadSendData, setLoadSendData] = useState(false);
	const [loadSendDataBorrador, setLoadSendDataBorrador] = useState(false);
	const [valueOpcionPago, setValueOpcionPago] = useState(2);
	const [loadingSave, setLoadingSave] = useState(false);
	const [excelData, setExcelData] = useState([]);
	const [flagUpload, setFlagUpload] = useState(false);
	const [cobroData, setCobroData] = useState([]);
	const [cobroDataFull, setCobroDataFull] = useState([]);
	const [extraData, setExtraData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [listDias, setListDias] = useState([
		1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
		22, 23, 24, 25, 26, 27, 28, 29, 30,
	]);
	const [fileData, setFileData] = useState([]);
	const [fileName, setFileName] = useState("");

	const { ExportCobro } = ExportCobroToExcel();

	const props = {
		beforeUpload: (file) => {
			const isExcel =
				file.type ===
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
			if (!isExcel) {
				message.error(`${file.name} No es un archivo excel`);
			}
			return isExcel || Upload.LIST_IGNORE;
		},
		onChange: (info) => {
		},
	};

	const handleExcel = async (e) => {
		const file = e.target.files[0];
		setFileName(file.name);
		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data);
		//Lee las primeras 4 lineas
		//const workbook = XLSX.readFile(data, {sheetRows: 5});

		const worksheet = workbook.Sheets[workbook.SheetNames[0]];
		const jsonData = XLSX.utils.sheet_to_json(worksheet, {
			raw: false,
		});
		let renamedData = [];
		jsonData.map((item, index) => {
			renamedData.push({
				/* address: item["Dirección residencia"],
				name: item["Nombre usuario"],
				phone: item["Telefono"],
				value: item["Valor de cobro"], */
				idClient: item["CODIGO"],
				houseNumber: item["LOTE"],
				name: item["CLIENTE"],
				billingNumber: item["N_FACTURA"],
				emission: item["EMISION"],
				expiration: item["VENCIMIENTO"],
				APMeasure: item["MEDIDOR_AP"],
				RGMeasure: item["MEDIDOR_RG"],

				chargeStart: item["COBRO_INICIO"],
				chargeEnd: item["COBRO_FIN"],
				APStart: item["AP_INICIO"],
				APEnd: item["AP_FIN"],
				totalAP: item["TOTAL_AP"],
				RGStart: item["RG_INICIO"],
				RGEnd: item["RG_FIN"],
				totalRG: item["TOTAL_RG"],
				subtotalAP: item["SUBTOTAL_AP"],
				subtotalRG: item["SUBTOTAL_RG"],
				taxes: item["INTERES"],
				totalToPay: item["TOTAL_PAGAR"],
				feeAP: item["VALOR_AP"],
				serviceAP: item["SERVICIO_AP"],
				valueRG: item["VALOR_RG"],
				serviceRG: item["SERVICIO_RG"],
			});
		});
		setFileData(renamedData);
		//convierte el archivo en arreglo en vez de objeto
		/* const jsonData = XLSX.utils.sheet_to_json(worksheet, {
	  header: 1,
	  defval: "",
	}); */
	};
	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	const columns = [
		{
			title: "CODIGO",
			dataIndex: "idClient",
			key: "idClient",
			width: 150,
			fixed: "left",
			align: "center",
			...getColumnSearchProps("idClient"),
		},
		{
			title: "LOTE",
			dataIndex: "houseNumber",
			key: "houseNumber",
			width: 150,
			fixed: "left",
			align: "center",
			...getColumnSearchProps("houseNumber"),
		},
		{
			title: "CLIENTE",
			dataIndex: "name",
			key: "name",
			width: 300,
			align: "center",
			onCell: (record) => ({ className: "table-name-cell-invited-no-padding" }),
			...getColumnSearchProps("name"),
		},
		{
			title: "N_FACTURA",
			dataIndex: "billingNumber",
			key: "billingNumber",
			width: 150,
			align: "center",
			...getColumnSearchProps("billingNumber"),
		},
		{
			title: "EMISION",
			dataIndex: "emission",
			key: "emission",
			width: 120,
			align: "center",
		},
		{
			title: "VENCIMIENTO",
			dataIndex: "expiration",
			key: "expiration",
			width: 150,
			align: "center",
		},
		{
			title: "MEDIDOR_AP",
			dataIndex: "APMeasure",
			key: "APMeasure",
			width: 200,
			align: "center",
			...getColumnSearchProps("APMeasure"),
		},
		{
			title: "MEDIDOR_RG",
			dataIndex: "RGMeasure",
			key: "RGMeasure",
			width: 200,
			align: "center",
			...getColumnSearchProps("RGMeasure"),
		},
		{
			title: "SERVICIO_AP",
			dataIndex: "serviceAP",
			key: "serviceAP",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.serviceAP
						? currencyFormat(parseFloat(record.serviceAP).toFixed(2).toString())
						: ""}
				</>
			),
		},
		{
			title: "SERVICIO_RG",
			dataIndex: "serviceRG",
			key: "serviceRG",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.serviceRG
						? currencyFormat(parseFloat(record.serviceRG).toFixed(2).toString())
						: ""}
				</>
			),
		},
		{
			title: "VALOR_AP",
			dataIndex: "feeAP",
			key: "feeAP",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.feeAP
						? currencyFormat(parseFloat(record.feeAP).toFixed(2).toString())
						: ""}
				</>
			),
			...getColumnSearchProps("feeAP"),
		},
		{
			title: "VALOR_RG",
			dataIndex: "valueRG",
			key: "valueRG",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.valueRG
						? currencyFormat(parseFloat(record.valueRG).toFixed(2).toString())
						: ""}
				</>
			),
		},
		{
			title: "COBRO_INICIO",
			dataIndex: "chargeStart",
			key: "chargeStart",
			width: 120,
			align: "center",
			...getColumnSearchProps("chargeStart"),
		},
		{
			title: "COBRO_FIN",
			dataIndex: "chargeEnd",
			key: "chargeEnd",
			width: 120,
			align: "center",
		},
		{
			title: "AP_INICIO",
			dataIndex: "APStart",
			key: "APStart",
			width: 120,
			align: "center",
			...getColumnSearchProps("APStart"),
		},
		{
			title: "AP_FIN",
			dataIndex: "APEnd",
			key: "APEnd",
			width: 120,
			align: "center",
			...getColumnSearchProps("APEnd"),
		},
		{
			title: "TOTAL_AP",
			dataIndex: "totalAP",
			key: "totalAP",
			width: 120,
			align: "center",
			...getColumnSearchProps("totalAP"),
		},
		{
			title: "RG_INICIO",
			dataIndex: "RGStart",
			key: "RGStart",
			width: 120,
			align: "center",
			...getColumnSearchProps("RGStart"),
		},
		{
			title: "RG_FIN",
			dataIndex: "RGEnd",
			key: "RGEnd",
			width: 120,
			align: "center",
			...getColumnSearchProps("RGEnd"),
		},
		{
			title: "TOTAL_RG",
			dataIndex: "totalRG",
			key: "totalRG",
			width: 120,
			align: "center",
			...getColumnSearchProps("totalRG"),
		},
		{
			title: "SUBTOTAL_AP",
			dataIndex: "subtotalAP",
			key: "subtotalAP",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.subtotalAP
						? currencyFormat(
							parseFloat(record.subtotalAP).toFixed(2).toString()
						)
						: ""}
				</>
			),
			...getColumnSearchProps("subtotalAP"),
		},
		{
			title: "SUBTOTAL_RG",
			dataIndex: "subtotalRG",
			key: "subtotalRG",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.subtotalRG
						? currencyFormat(
							parseFloat(record.subtotalRG).toFixed(2).toString()
						)
						: ""}
				</>
			),
			...getColumnSearchProps("subtotalRG"),
		},
		{
			title: "INTERES",
			dataIndex: "taxes",
			key: "taxes",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.taxes
						? currencyFormat(parseFloat(record.taxes).toFixed(2).toString())
						: ""}
				</>
			),
			...getColumnSearchProps("taxes"),
		},
		{
			title: "TOTAL_PAGAR",
			dataIndex: "totalToPay",
			key: "totalToPay",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.totalToPay
						? currencyFormat(
							parseFloat(record.totalToPay).toFixed(2).toString()
						)
						: ""}
				</>
			),
			/* render: (text, record) => (
				<>{currencyFormat(record.totalToPay.toString())}</>
			), */
			...getColumnSearchProps("totalToPay"),
		},
	];
	const columnsReadMode = [
		/* {
			title: "CODIGO",
			dataIndex: "idClient",
			key: "idClient",
			width: 150,
			fixed: "left",
			align: "center",
			...getColumnSearchProps("idClient"),
		},
		{
			title: "LOTE",
			dataIndex: "houseNumber",
			key: "houseNumber",
			width: 150,
			fixed: "left",
			align: "center",
			...getColumnSearchProps("houseNumber"),
		}, */
		/* {
			title: "CLIENTE",
			dataIndex: "name",
			key: "name",
			width: 300,
			align: "center",
			onCell: (record) => ({ className: "table-name-cell-invited-no-padding" }),
			...getColumnSearchProps("name"),
		}, */
		{
			title: "CLIENTE",
			dataIndex: "profile_name",
			key: "profile_name",
			width: 300,
			align: "center",
			render: (text, record) => (
				<>
					{record.profileData
						? record.profileData.name + " " + record.profileData.lastname
						: ""}
				</>
			),
			onCell: (record) => ({ className: "table-name-cell-invited-no-padding" }),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<div style={{ padding: 8 }}>
							<Input
								autoFocus
								placeholder=""
								value={selectedKeys[0]}
								onChange={(e) => {
									setSelectedKeys(e.target.value ? [e.target.value] : []);
									confirm({ closeDropdown: false });
								}}
								onPressEnter={() => {
									confirm();
								}}
								onBlur={() => {
									confirm();
								}}
								style={{ marginBottom: 8, display: "block" }}
							></Input>
							<Space>
								<Button
									icon={<SearchOutlined />}
									onClick={() => {
										confirm();
									}}
									type="primary"
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => {
										clearFilters();
									}}
									size="small"
									style={{ width: 90 }}
								>
									Limpiar
								</Button>
							</Space>
						</div>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.profileData?.name
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			title: "N_FACTURA",
			dataIndex: "billingNumber",
			key: "billingNumber",
			width: 150,
			align: "center",
			...getColumnSearchProps("billingNumber"),
		},
		{
			title: "EMISION",
			dataIndex: "created_at",
			key: "created_at",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.created_at
						? moment(record.created_at.toDate()).format("Y-MM-DD")
						: ""}
				</>
			),
		},
		{
			title: "VENCIMIENTO",
			dataIndex: "expiration_date",
			key: "expiration_date",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.expiration_date
						? moment(record.expiration_date.toDate()).format("Y-MM-DD")
						: ""}
				</>
			),
		},
		{
			title: "MEDIDOR_AP",
			dataIndex: "details.medidorPotable",
			key: "details.medidorPotable",
			width: 200,
			align: "center",
			render: (text, record) => (
				<>
					{record.details.medidorPotable ? record.details.medidorPotable : ""}
				</>
			),
		},
		{
			title: "MEDIDOR_RG",
			dataIndex: "details.medidorRiego",
			key: "details.medidorRiego",
			width: 200,
			align: "center",
			render: (text, record) => (
				<>{record.details.medidorRiego ? record.details.medidorRiego : ""}</>
			),
		},
		{
			title: "VALOR_RG",
			dataIndex: "details.aguaRiego",
			key: "details.aguaRiego",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.details.aguaRiego
						? currencyFormat(
							parseFloat(record.details.aguaRiego).toFixed(2).toString()
						)
						: ""}
				</>
			),
		},
		{
			title: "SERVICIO_AP",
			dataIndex: "details.servicioAP",
			key: "details.servicioAP",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.details.servicioAP
						? currencyFormat(
							parseFloat(record.details.servicioAP).toFixed(2).toString()
						)
						: ""}
				</>
			),
		},
		{
			title: "SERVICIO_RG",
			dataIndex: "details.servicioRG",
			key: "details.servicioRG",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.details.servicioRG
						? currencyFormat(
							parseFloat(record.details.servicioRG).toFixed(2).toString()
						)
						: ""}
				</>
			),
		},
		{
			title: "VALOR_AP",
			dataIndex: "details.aguaPotable",
			key: "details.aguaPotable",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.details.aguaPotable
						? currencyFormat(
							parseFloat(record.details.aguaPotable).toFixed(2).toString()
						)
						: ""}
				</>
			),
		},
		{
			title: "COBRO_INICIO",
			dataIndex: "details.fechaInicioCobro",
			key: "detailsfechaInicioCobro.",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.details.fechaInicioCobro
						? record.details.fechaInicioCobro
						: ""}
				</>
			),
		},
		{
			title: "COBRO_FIN",
			dataIndex: "details.",
			key: "details.",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>{record.details.fechaFinCobro ? record.details.fechaFinCobro : ""}</>
			),
		},
		{
			title: "AP_INICIO",
			dataIndex: "details.potableInicial",
			key: "details.potableInicial",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.details.potableInicial ? record.details.potableInicial : ""}
				</>
			),
		},
		{
			title: "AP_FIN",
			dataIndex: "details.potableFinal",
			key: "details.potableFinal",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>{record.details.potableFinal ? record.details.potableFinal : ""}</>
			),
		},
		/* {
			title: "TOTAL_AP",
			dataIndex: "totalAP",
			key: "totalAP",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.details.potableInicial
						? details.potableInicial
						: ""}
				</>
			),
		}, */
		{
			title: "RG_INICIO",
			dataIndex: "details.riegoInicial",
			key: "details.riegoInicial",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>{record.details.riegoInicial ? record.details.riegoInicial : ""}</>
			),
		},
		{
			title: "RG_FIN",
			dataIndex: "details.riegoFinal",
			key: "details.riegoFinal",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>{record.details.riegoFinal ? record.details.riegoFinal : ""}</>
			),
		},
		/* {
			title: "TOTAL_RG",
			dataIndex: "totalRG",
			key: "totalRG",
			width: 120,
			align: "center",
			render: (text, record) => (
				<>
					{record.details.potableInicial
						? details.potableInicial
						: ""}
				</>
			),
		}, */
		/* {
			title: "SUBTOTAL_AP",
			dataIndex: "subtotalAP",
			key: "subtotalAP",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.subtotalAP
						? currencyFormat(
								parseFloat(record.subtotalAP).toFixed(2).toString()
						  )
						: ""}
				</>
			),
			...getColumnSearchProps("subtotalAP"),
		}, */
		/* {
			title: "SUBTOTAL_RG",
			dataIndex: "subtotalRG",
			key: "subtotalRG",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.subtotalRG
						? currencyFormat(
								parseFloat(record.subtotalRG).toFixed(2).toString()
						  )
						: ""}
				</>
			),
			...getColumnSearchProps("subtotalRG"),
		}, */
		/* {
			title: "INTERES",
			dataIndex: "taxes",
			key: "taxes",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.taxes
						? currencyFormat(parseFloat(record.taxes).toFixed(2).toString())
						: ""}
				</>
			),
			...getColumnSearchProps("taxes"),
		}, */
		{
			title: "TOTAL_PAGAR",
			dataIndex: "amount",
			key: "amount",
			width: 150,
			align: "center",
			render: (text, record) => (
				<>
					{record.amount
						? currencyFormat(parseFloat(record.amount).toFixed(2).toString())
						: ""}
				</>
			),
			/* render: (text, record) => (
				<>{currencyFormat(record.totalToPay.toString())}</>
			), */
		},
	];

	const columnsMora = [
		{
			title: "NOMBRE",
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: "PAGOS EN MORA",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "SALDO",
			dataIndex: "address",
			key: "address",
		},
		{
			title: "DETALLE",
			dataIndex: "valor",
			key: "index",
			align: "center",
			width: 120,
		},
	];

	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [placement, SetPlacement] = useState("topLeft");

	const {
		titulo,
		valor,
		porcentaje,
		resetValuesInput,
		onInputChange,
		onResetForm,
	} = useForm({
		titulo: "",
		valor: "",
		porcentaje: "",
	});

	const onChangeRadio = (e) => {
		setValueOpcionPago(e.target.value);
	};

	const placementChange = (e) => {
		SetPlacement(e.target.value);
	};

	const dispatch = useDispatch();

	const disabledDate = (current) => {
		/*var today = new Date();
	var tomorrow = new Date(today);
	tomorrow.setDate(today.getDate()+1);
	tomorrow.toLocaleDateString();*/

		return current && current.valueOf() < Date.now();

		//const weekStart = moment().startOf('week');
		//const weekEnd = moment().endOf('week');
		//return !(weekStart.isSameOrBefore(current) && weekEnd.isAfter(current));
	};

	const handleChange = (value, key) => {
		let newArr = [...listPreguntas]; // copying the old datas array
		newArr[key].question = value;

		setListPreguntas(newArr);
	};

	const handleStatusModal = (statusModal, index = null) => {
		setTimeout(() => {
			setModalVisible(statusModal);
		}, 50);
	};

	useEffect(() => {
		dispatch(getAdminCobros());
		dispatch(getUserCobros());
	}, []);

	useEffect(() => {
		if (adminCobros.data.length > 0) {
			setSurveysList(adminCobros.data);
		}
		setIsModalVisiblePasoTres(false);
		setIsModalVisiblePasoTres(false);
		setIsModalVisiblePasoDos(false);
		setIsModalVisiblePasoUno(false);
		setIsModalVisibleUser(true);

		setLoadSendData(false);
		setLoadSendDataBorrador(false);
		setAdminCobroNuevo(null);

		onResetForm();
	}, [adminCobros.data]);

	/* useEffect(() => {

		setUserPagoList(adminCobros.userResidentes);
	}, [adminCobros.userResidentes]); */

	const descargarEncuesta = () => {

		var i = 0;
		var wb = utils.book_new();
		adminCobroDetail.preguntas.map((item, index) => {
			const dataSet = [];
			if (item.type === "qualify" || item.type === "option") {
				opcionesGrafica[index]?.map((itemP, index) => {
					dataSet.push({
						nombre: itemP.titulo,
						cantidad: itemP.cantidad,
						porcentaje: itemP.porcentaje + "%",
					});
				});
			} else if (item.type === "commentary") {
				opcionesGrafica[index]?.map((itemP, index) => {
					dataSet.push({
						respuesta: itemP.response,
					});
				});
			}

			var ws = utils.json_to_sheet([{}], {
				header: [item.question],
			});
			utils.sheet_add_json(ws, dataSet, { origin: "A3" });
			utils.book_append_sheet(wb, ws, "Pregunta " + (index + 1));
		});

		writeFile(wb, adminCobroDetail.name + ".xlsx");
	};

	const handleCancelPasoUno = () => {
		setIsModalVisiblePasoUno(false);
	};

	const handleCancelPasoDos = () => {
		setIsModalVisiblePasoDos(false);
	};

	const handleCancelPasoTres = () => {
		setIsModalVisiblePasoTres(false);
	};

	useEffect(() => {
		setAdminCobroDetail(adminCobros.active);
	}, [adminCobros.active]);

	const handleActiveDetail = (data) => {
		dispatch(setActiveAdminCobro(data));
		/* const getAdminCobroData = async (data) => {
			const payments = await getPaymentsHelperByTemplateId(
				data.payment_tempId
			).then((result) => setCobroData(result));
		};
		getAdminCobroData(data); */
	};

	const handleEdit = (data) => {
		var formInfo = { titulo: data.name, valor: data.descripcion };
		resetValuesInput(formInfo);

		let newArr = [];
		var i = 0;
		while (i < data.preguntas.length) {
			var item = data.preguntas[i];
			var itemNew = {
				idR: item.idR,
				question: item.question,
				type: item.type,
				posicion: newArr.length,
			};

			if (item.type === "option") {
				var j = 0;
				var opciones = [];
				while (j < item.option.length) {
					opciones.push({ posicion: j, valor: item.option[j] });
					++j;
				}

				itemNew.opciones = opciones;
			}

			newArr.push(itemNew);
			++i;
		}

		setListPreguntas(newArr);
		setStartDate("");
		setEndDate("");
		setIdBorrado(data.id);

		setIsModalVisiblePasoUno(true);
	};

	const eliminarPregunta = (posicion) => {
		let newArr = [...listPreguntas];
		let newArrEliminadas = [...listPreguntasEliminadas];
		newArrEliminadas.push(newArr[posicion]);
		delete newArr[posicion];

		var newArrPosition = [];
		var i = 0;
		while (i < newArr.length) {
			if (typeof newArr[i] !== "undefined") {
				newArr[i].posicion = newArrPosition.length;
				newArrPosition.push(newArr[i]);
			}
			++i;
		}

		setListPreguntas(newArrPosition);
		setListPreguntasEliminadas(newArrEliminadas);
	};

	const onChangeFecha = (date, dateString) => {
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		//const [startDate, setstartDate] = useState("");
		//const [endDate, setendDate] = useState("");
	};

	const actualizarCobro = async (lista, adminCobroD) => {
		setOnlyRead(lista);

		var dataNew = {};
		dataNew.new = false;
		dataNew.title = adminCobroD.title;
		dataNew.id = adminCobroD.id;
		dataNew.amount = adminCobroD.amount;
		dataNew.type = adminCobroD.type;
		dataNew.dateEmision = adminCobroD.dateEmision;
		dataNew.dateVencimiento = adminCobroD.dateVencimiento;
		dataNew.dayEmision = adminCobroD.dayEmision;
		dataNew.dayVencimiento = adminCobroD.dayVencimiento;
		dataNew.eraser = adminCobroD.eraser;
		var listadoUser = [];

		var i = 0;
		while (i < userPagoList.length) {
			var itemUser = { ...userPagoList[i] };
			let findUser = adminCobroD.respuesta.find(
				(request) => request.idProfile === itemUser.id
			);
			if (typeof findUser !== "undefined") {
				itemUser.valor = findUser.amount;
				itemUser.idP = findUser.idP;
			} else {
				itemUser.valor = valor;
				itemUser.idP = 0;
			}
			listadoUser.push(itemUser);
			++i;
		}
		dataNew.listadoUser = listadoUser;
		setAdminCobroNuevo(dataNew);
		setVisibleRegresarPasoDos(false);

		if (lista) {
			setIsModalVisibleUser(true);
		} else {
			var formInfo = { titulo: dataNew.title, valor: dataNew.amount };
			resetValuesInput(formInfo);
			setTipoCobro(dataNew.type === 1 ? "Única vez" : "Mensual");
			if (dataNew.type === 1) {
				setDiaEmision("");
				setDiaVencimiento("");
				setStartDate(dataNew.dateEmision);
				setEndDate(dataNew.dateVencimiento);
				//$(".contFechaEmision .ant-select-selection-item").html("hola");
			} else {
				setDiaEmision(dataNew.dayEmision);
				setDiaVencimiento(dataNew.dayVencimiento);
				setStartDate("");
				setEndDate("");
				$(".contDiaEmision .ant-select-selection-item").html(
					dataNew.dateEmision
				);
				$(".contDiaVencimiento .ant-select-selection-item").html(
					dataNew.dateVencimiento
				);
			}

			$(".contIdTipo .ant-select-selection-item").html(tipoCobro);

			setIsModalVisibleUser(false);
			setIsModalVisiblePasoDos(true);
		}
	};

	const actualizarCobro1 = async (lista, adminCobroD) => {
		setLoading(true);
		setOnlyRead(lista);

		const getAdminCobroData = async (adminCobroD) => {
			const payments = await getPaymentsHelperByTemplateId(
				adminCobroD.payment_tempId
			).then((result) => setCobroData(result));
		};
		getAdminCobroData(adminCobroD);
		setAdminCobroNuevo(adminCobroD);

		setVisibleRegresarPasoDos(false);
		if (lista) {
			setIsModalVisibleUser(true);
		}
	};

	useEffect(() => {
		const getProfileByID = async (data) => {
			const addProfiles = data.map(async (item, index) => {
				let data = { ...item };
				if (data.profile_id) {
					const profile = await getUserDataProfileById(data.profile_id).then(
						(result) => (data.profileData = result)
					);
				}
				return data;
			});
			Promise.all(addProfiles).then((data) => setCobroDataFull(data));
		};
		getProfileByID(cobroData);
	}, [cobroData]);

	useEffect(() => {
		if (cobroDataFull.length > 0) {
			setLoading(false);
		}
	}, [cobroDataFull]);

	const onHandleSubmit = () => {
		setIsModalVisiblePasoUno(false);
		setIsModalVisiblePasoDos(true);
		//onResetForm();
	};

	const onHandleGuardarEncuesta = async (eraser) => {
		if (eraser) {
			setLoadSendDataBorrador(true);
		} else {
			setLoadSendData(true);
		}

		var data = { ...adminCobroNuevo };
		data.active = true;
		data.eraser = eraser;

		dispatch(saveAdminCobro(data));
	};

	const onHandleGuardarEncuestaExcel = async (eraser) => {
		if (eraser) {
			setLoadSendDataBorrador(true);
		} else {
			setLoadSendData(true);
		}

		var data = { ...adminCobroNuevo };
		data.active = true;
		data.eraser = eraser;

		dispatch(saveAdminCobro(data));
	};

	const onHandleSubmitForm = async () => {
		if (!titulo) {
			toast.warn("Debe ingresar titulo del cobro");
			return;
		}

		if (!valor) {
			toast.warn("Debe ingresar valor del cobro");
			return;
		}

		//aqui
		setIsModalVisiblePasoDos(false);
		setIsModalVisiblePasoTres(true);
	};

	const onHandleSubmitFormExcel = async () => {
		if (!titulo) {
			toast.warn("Debe ingresar titulo del cobro");
			return;
		}

		if (fileData.length == 0) {
			toast.warn("Debe subir el archivo excel");
			return;
		}
		//aqui
		setIsModalVisiblePasoDos(false);
		setIsModalVisiblePasoTres(true);
	};

	const onHandleSubmitValidar = async (eraser) => {
		if (tipoCobro === "Mensual") {
			if (diaEmision === "") {
				toast.warn("Debe ingresar día de emisión del cobro");
				return;
			}

			if (diaVencimiento === "") {
				toast.warn("Debe ingresar día de vencimiento del cobro");
				return;
			}
		} else {
			if (startDate === "") {
				toast.warn("Debe ingresar fecha de emisión del cobro");
				return;
			}

			if (endDate === "") {
				toast.warn("Debe ingresar fecha de vencimiento del cobro");
				return;
			}
		}

		var dataNew = {};
		dataNew.new = true;
		dataNew.title = titulo;
		dataNew.id = "";
		dataNew.amount = valor;
		dataNew.type = tipoCobro === "Única vez" ? 1 : 2;
		dataNew.dateEmision = startDate;
		dataNew.dateVencimiento = endDate;
		dataNew.dayEmision = diaEmision;
		dataNew.dayVencimiento = diaVencimiento;
		dataNew.eraser = true;
		var listadoUser = [];

		var i = 0;
		while (i < userPagoList.length) {
			var itemUser = { ...userPagoList[i] };
			itemUser.valor = valor;
			itemUser.idP = 0;
			listadoUser.push(itemUser);
			++i;
		}
		dataNew.listadoUser = listadoUser;
		setAdminCobroNuevo(dataNew);
		setIsModalVisiblePasoTres(false);
		setIsModalVisibleUser(true);

		//const [adminCobroNew, setAdminCobroNew] = useState(null);

		//dispatch( setSurveys(data) );
	};

	const onHandleSubmitValidarExcel = async (eraser) => {
		if (tipoCobro === "Mensual") {
			if (diaEmision === "") {
				toast.warn("Debe ingresar día de emisión del cobro");
				return;
			}

			if (diaVencimiento === "") {
				toast.warn("Debe ingresar día de vencimiento del cobro");
				return;
			}
		} else {
			if (startDate === "") {
				toast.warn("Debe ingresar fecha de emisión del cobro");
				return;
			}

			if (endDate === "") {
				toast.warn("Debe ingresar fecha de vencimiento del cobro");
				return;
			}
		}
		if (porcentaje === "") {
			toast.warn("Debe ingresar el porcentaje de mora");
			return;
		}

		var dataNew = {};
		dataNew.new = true;
		dataNew.title = titulo;
		dataNew.id = "";
		dataNew.amount = fileData.value;
		dataNew.type = tipoCobro === "Única vez" ? 1 : 2;
		dataNew.dateEmision = startDate;
		dataNew.dateVencimiento = endDate;
		dataNew.dayEmision = diaEmision;
		dataNew.dayVencimiento = diaVencimiento;
		dataNew.eraser = true;
		dataNew.porcentajeMora = porcentaje;
		var listadoUser = [];

		var i = 0;
		while (i < fileData.length) {
			var itemUser = { ...fileData[i] };
			itemUser.valor = fileData.value;
			itemUser.idP = 0;
			listadoUser.push(itemUser);
			++i;
		}
		dataNew.listadoUser = listadoUser;
		setAdminCobroNuevo(dataNew);
		setIsModalVisiblePasoTres(false);
		handleCancelPasoDos(false);
		setIsModalVisibleUser(true);

		//const [adminCobroNew, setAdminCobroNew] = useState(null);

		//dispatch( setSurveys(data) );
	};

	const getDateFromString = (date) => {
		const time = new Date(Date.parse(date));

		const dateString = moment(time).format("Y-MM-DD");

		return dateString;
	};
	const getTimestampFromString = (date) => {
		const time = Date.parse(date);

		return time;
	};

	useEffect(() => {
		if (excelData.length >= 1 && flagUpload === true) {
			const getHouseData = async (array) => {
				const _data = array.map(async (item) => {
					let data = { ...item };
					const houseData = await getHouseByAlias(data.houseNumber).then(
						(houseData) => {
							data.houseData = houseData;
						}
					);

					return data;
				});
				Promise.all(_data).then((data) => {
					setFullExcelData(data);
				});
			};

			getHouseData(excelData);
		}
	}, [excelData]);

	useEffect(() => {
		if (fullExcelData.length >= 1 && flagUpload === true) {
			let fullData = [];
			moment.locale("es");
			fullExcelData.map((item) => {
				/* let count = Object.keys(item).length;
				 */
				fullData.push({
					active: true,
					billingNumber: item.billingNumber ? item.billingNumber : "",
					amount: parseFloat(parseFloat(item.totalToPay).toFixed(2)),
					mora: 0,
					porcentaje_mora: parseInt(adminCobroNuevo.porcentajeMora),
					automatic_payment: true,
					card: "",
					concept:
						"Pago servicio agua " +
						moment(new Date(getTimestampFromString(item.emission)))
							.subtract(1, "months")
							.tz("America/El_Salvador")
							.locale("es")
							.format("MMMM"),
					created_at: new Date(getTimestampFromString(item.emission)),
					expiration_date: new Date(getTimestampFromString(item.expiration)),
					num_auth: "",
					num_ref: "",
					house: item.houseData[0] ? item.houseData[0].path : "",
					profile_id: item.houseData[0]
						? item.houseData[0].data.idOwners[0]
						: "",
					residential_id: "Yd5MV7jkA0MjQeCmzKrZ",
					status: "Pendiente",
					type: "Servicio",
					details: {
						idCliente: item.idClient,
						casa: item.houseNumber,
						fechaInicioCobro: getDateFromString(item.chargeStart),
						fechaFinCobro: getDateFromString(item.chargeEnd),
						medidorPotable: item.APMeasure ? item.APMeasure : "",
						potableInicial: item.APStart ? item.APStart : "",
						potableFinal: item.APEnd ? item.APEnd : "",
						aguaPotable: item.feeAP ? item.feeAP : "",
						aguaRiego: item.valueRG ? item.valueRG : "",
						servicioAP: item.serviceAP ? item.serviceAP : "",
						servicioRG: item.serviceRG ? item.serviceRG : "",
						medidorRiego: item.RGMeasure ? item.RGMeasure : "",
						riegoInicial: item.RGStart ? item.RGStart : "",
						riegoFinal: item.RGEnd ? item.RGEnd : "",
					},
					user_name: "",
				});
			});

			setExtraData(fullData);
			//saveCobroAguaToPaymentCollection(fullData);
			/* 
			setFlagUpload(false);
			setLoadingSave(false); */
		}
	}, [fullExcelData]);

	const separateVarString = (string) => {
		const str = string.toString();
		let newStr = str.replace(/([a-z])([A-Z])/g, "$1 $2");
		newStr = newStr.toLowerCase();
		newStr = newStr.charAt(0).toUpperCase() + newStr.slice(1);
		return newStr;
	};

	useEffect(() => {
		if (extraData.length >= 1 && flagUpload == true) {
			//let secondData = [];
			let superData = [...extraData];
			let j = 0;
			extraData.map((item) => {
				superData[j].allDetails = {};
				let count = Object.keys(item.details).length;
				let objDetail = { ...item.details };
				let secondData = [];
				for (let i = 0; i < count; i++) {
					let detailData = {};
					const nameToChange = separateVarString(Object.keys(objDetail)[i]);
					detailData.name = nameToChange;
					let name = Object.keys(objDetail)[i];
					//detailData.value = item.details.medidorPotable;
					if (item.details[name] == "") {
						detailData.value = "N/A";
					} else {
						detailData.value = item.details[name];
					}
					secondData.push(detailData);
				}

				//let secondDataObj = { ...secondData };

				superData[j].allDetails = secondData;
				j++;
			});

			saveCobroAguaToPaymentCollection(superData);
			dispatch(getAdminCobros());
			dispatch(getUserCobros());
			setFlagUpload(false);
			setLoadingSave(false);
			setIsModalVisibleUser(false);
			setFileName("");
		}
	}, [extraData]);

	const onHandleSyncExcel = async () => {
		setLoadingSave(true);
		setExcelData([...adminCobroNuevo.listadoUser]);
		//let fullData = [];

		/* const getHouseData = async (array) => {
			const _data = array.map(async (item) => {
				let data = { ...item };
				const houseData = await getHouseByAlias(data.houseNumber).then(
					(houseData) => {
						data.houseData = houseData;
					}
				);

				return data;
			});
			Promise.all(_data).then((data) => {
				setFullExcelData(data);
			});
		};

		getHouseData(excelData); */

		/* const getProfileData = async (array) => {
			const _data = array.map(async (item, index) => {
				let data = { ...item };

				if (data.houseData[0]) {
					const profile = await getProfileByHouseIdHelper(
						data.houseData[0].idOwners[0]
					).then((pro) => {
						data.userProfileData = pro;
						data.profileID = data.houseData[0].idOwners[0];
					});
				}

				return data;
			});
			Promise.all(_data).then((data) => {
				setFullExcelData(data);
			});
		};

		getProfileData(excelDataWithHouseData); */

		/* fullExcelData.map((item) => {
			fullData.push({
				active: true,
				amount: parseFloat(item.totalToPay).toFixed(2),
				mora: 0,
				porcentaje_mora: parseInt(adminCobroNuevo.porcentajeMora),
				automatic_payment: false,
				card: "",
				concept: "Pago servicio agua",
				created_at: new Date(getTimestampFromString(item.emission)),
				expiration_date: new Date(getTimestampFromString(item.expiration)),
				num_auth: "",
				num_ref: "",
				payment_type: "",
				profile_id: item.profileID ? item.profileID : "",
				residential_id: "Yd5MV7jkA0MjQeCmzKrZ",
				status: "Pendiente",
				type: "Servicio",
				details: {
					fechaInicioCobro: new Date(getTimestampFromString(item.chargeStart)),
					fechaFinCobro: new Date(getTimestampFromString(item.chargeEnd)),
					medidorPotable: item.APMeasure ? item.APMeasure : "",
					potableInicial: item.APStart ? item.APStart : "",
					potableFinal: item.APEnd ? item.APEnd : "",
					valorAP: item.feeAP ? item.feeAP : "",
					medidorRiego: item.RGMeasure ? item.RGMeasure : "",
					riegoInicial: item.RGStart ? item.RGStart : "",
					riegoFinal: item.RGEnd ? item.RGEnd : "",
				},
				user_name: "",
			});
		});

		if (fullData.length > 0) {
			//saveCobroAguaToPaymentCollection(fullData);
		}

		setLoadingSave(false); */
	};

	const handleMoraModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setMoraModalActive(statusModal);
		}, 50);
	};

	return (
		<>
			<div className="wrapper-page">
				<h2 className="title-page">Administración de cobros</h2>

				<Row>
					<Col lg={9} sm={24}>
						<Button
							className="btn-primary-radio"
							onClick={() => {
								onResetForm();
								setIdBorrado("");
								setListPreguntas([]);
								setListPreguntasEliminadas([]);
								setIsModalVisiblePasoUno(false);
								setIsModalVisiblePasoDos(true);
								setOnlyRead(false);
								$(".ant-picker-input :input").val("");
							}}
						>
							<img src={shape20} alt="add-plus-icon" />
							NUEVO COBRO
						</Button>

						<Tabs style={{ marginTop: "10px" }} className="tabsMovil">
							<TabList>
								<Tab tabfor={0}>COBROS ACTIVOS</Tab>
								{/* <Tab tabfor={1}>COBROS INACTIVOS</Tab> */}
							</TabList>

							<TabPanel tabid={0}>
								<ul className="primary-card-list">
									{surveysList.map((survey, key) => {
										if (survey.eraser === false) {
											return (
												<li
													key={key}
													onClick={() => {
														handleActiveDetail(survey);
													}}
													className={
														adminCobroDetail == null
															? "primary-card-card"
															: adminCobroDetail.id == survey.id
																? "primary-card-card selectedSurvery"
																: "primary-card-card"
													}
												>
													<img
														className="iconMetodo"
														src={metodoPago}
														alt="payment-method"
													/>

													<div className="primary-card-info">
														<h3>{survey.title}</h3>
														<span>Última modificación: {survey.update_at}</span>
														<br />
													</div>

													<img
														className="shape-next"
														src={shape22}
														alt="right-arrow"
													/>
												</li>
											);
										}
									})}
								</ul>
							</TabPanel>
							{/* <TabPanel tabid={1}>
								<ul className="primary-card-list">
									{surveysList.map((survey, key) => {
										if (survey.eraser == true) {
											return (
												<li
													key={key}
													onClick={() => {
														actualizarCobro(false, survey);
													}}
													className={
														adminCobroDetail == null
															? "primary-card-card"
															: adminCobroDetail.id == survey.id
															? "primary-card-card selectedSurvery"
															: "primary-card-card"
													}
												>
													<img
														className="iconMetodo"
														src="/assets/images/metodo_pago.png"
														alt=""
													/>

													<div className="primary-card-info">
														<h3>{survey.title}</h3>
														<span>Última modificación: {survey.update_at}</span>
														<br />
													</div>

													<img
														className="shape-next"
														src="/assets/images/shape22.png"
														alt=""
													/>
												</li>
											);
										}
									})}
								</ul>
							</TabPanel> */}
						</Tabs>
					</Col>
					{/* <Col lg={{ span: 1 }} sm={24} className="secondColumn"></Col> */}
					<Col lg={{ span: 13, offset: 2 }} sm={24}>
						{adminCobroDetail == null ? (
							<div className="secondColumn">
								<div className="contCenter">
									<div className="contVacio">
										<img
											className="shape"
											src={shape}
											alt="empty-img"
										/>
										<img
											className="iconFactura"
											src={factura}
											alt="billing-icon"
										/>
									</div>
									<span>SELECCIONAR COBRO</span>
								</div>
							</div>
						) : (
							<div className="secondColumn">
								<div
									className="primary-card-detail survey-detail"
									style={{ marginTop: "6rem" }}
								>
									<div
										className="primary-card-section"
										style={{ lineHeight: "16px" }}
									>
										<h4>COBRO</h4>
										<h2>{adminCobroDetail.title}</h2>
									</div>

									<div className="primary-card-section">
										<Row style={{ padding: "0px" }}>
											<Col lg={12}>
												{/* {adminCobroDetail.title === "Mantenimiento" ? (
												<></>
											) : (
												<>
													<span className="tituloDetalle">VALOR DE COBRO:</span>
													<span className="montoDetalle">
														{" "}
														${adminCobroDetail.amount}
													</span>
												</>
											)} */}

												<span className="tituloDetalle">CREADO:</span>
												<span className="fechaDetalle">
													{adminCobroDetail.created_at}
												</span>

												<span className="tituloDetalle">
													ÚLTIMA MODIFICACIÓN:
												</span>
												<span className="fechaDetalle">
													{adminCobroDetail.update_at}
												</span>
											</Col>

											<Col lg={12} className="contCreacion">
												<Button
													onClick={() => {
														if (adminCobroDetail.title === "Mantenimiento") {
															navigate("/admin-cobros-tuscania", {
																replace: false,
															});
														}
														actualizarCobro1(true, adminCobroDetail);
													}}
													modalvisible="true"
													className="btn-primary-radio"
												>
													<div style={{ display: "flex" }}>
														<img
															className="iconButton"
															src={vision}
															alt="eye-scanner-icon"
														/>
														VISUALIZAR LISTADO
														{loadMoreData ? (
															<div style={{ marginLeft: 20 }}>
																<Spinner />
															</div>
														) : null}
													</div>
												</Button>

												<Button
													onClick={() => {
														handleMoraModal(true);
													}}
													modalvisible="true"
													className="btn-primary-radio"
												>
													<div style={{ display: "flex" }}>
														<img
															className="iconButton"
															src={reporte}
															alt="report-icon"
														/>
														USUARIOS EN MORA
														{loadMoreData ? (
															<div style={{ marginLeft: 20 }}>
																<Spinner />
															</div>
														) : null}
													</div>
												</Button>
											</Col>
										</Row>

										<Row
											style={{
												borderTop: "1px solid #ccc",
												borderBottom: "1px solid #ccc",
											}}
										>
											<Col lg={12}>
												<div style={{ paddingLeft: "50px" }}>
													<h4>TIPO DE COBRO:</h4>
													<span className="fechaDetalle">
														{adminCobroDetail.type == 1
															? "Única vez"
															: "Mensual"}
													</span>
												</div>
											</Col>
											<Col lg={12}>
												<div style={{ paddingLeft: "50px" }}>
													{/* <h4>COBRO DESDE:</h4>
												<span className="fechaDetalle">
													{adminCobroDetail.type == 1
														? adminCobroDetail.dateEmision
														: adminCobroDetail.dayEmision + " de cada mes"}
												</span> */}
												</div>
											</Col>
										</Row>

										<Row
											style={{
												textAlign: "center",
												margin: "auto",
												display: "block",
											}}
										>
											{/* <Button
											onClick={() => {
												actualizarCobro(false, adminCobroDetail);
											}}
											className="btn-primary"
											type="primary"
											shape="round"
										>
											<div style={{ display: "flex" }}>
												ACTUALIZAR COBRO
												{loadMoreData ? (
													<div style={{ marginLeft: 20 }}>
														<Spinner />
													</div>
												) : null}
											</div>
										</Button> */}
										</Row>
									</div>
								</div>
							</div>
						)}
					</Col>
				</Row>

				{/* <Modal
					className="primary-modal"
					title="CREAR NUEVO COBRO"
					visible={isModalVisiblePasoUno}
					onCancel={() => handleCancelPasoUno()}
					footer={null}
				>
					<div>
						<div className="pasos">PASO 1 DE 3</div>

						<Radio.Group
							onChange={onChangeRadio}
							value={valueOpcionPago}
							style={{ width: "100%" }}
						>
							<Row>
								<Col span={12} style={{ textAlign: "center" }}>
									<div
										className="opcionPago"
										onClick={() => {
											eliminarPregunta();
										}}
									>
										<img
											className="iconOpcionPago"
											src="/assets/images/doc_online.png"
											alt="Agregar"
										/>
										<Radio value={1} disabled={true}>
											Crear online
										</Radio>
									</div>
								</Col>

								<Col span={12} style={{ textAlign: "center" }}>
									<div
										className="opcionPago"
										onClick={() => {
											eliminarPregunta();
										}}
									>
										<img
											className="iconOpcionPago"
											src="/assets/images/hoja_excel.png"
											alt="Agregar"
										/>
										<Radio value={2}>Subir plantilla Excel</Radio>
									</div>
								</Col>
							</Row>
						</Radio.Group>
					</div>

					<div className="footer-buttons">
						<Button
							// disabled={requests.isSaving}
							className="btn-primary"
							type="primary"
							shape="round"
							style={{ width: "150px" }}
							onClick={onHandleSubmit}
						>
							SIGUIENTE
						</Button>
					</div>
				</Modal> */}

				<Modal
					className="primary-modal"
					title={
						<>
							<div className="ant-modal-title">
								<p>
									<img
										src={laptopExcel}
										alt="laptop-excel"
										style={{ paddingRight: "1rem" }}
									/>
									CREAR NUEVO COBRO
								</p>
							</div>
						</>
					}
					visible={isModalVisiblePasoDos}
					onCancel={() => handleCancelPasoDos()}
					footer={
						<Row>
							<Col span={6} style={{ textAlign: "left" }}>
								{/* {visibleRegresarPasoDos ? (
									<Button
										className="btn-cancel"
										type="primary"
										shape="round"
										style={{ width: "90%" }}
										onClick={() => {
											setIsModalVisiblePasoDos(false);
											setIsModalVisiblePasoUno(true);
										}}
									>
										REGRESAR
									</Button>
								) : null} */}
							</Col>

							<Col span={12} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										onHandleSubmitValidarExcel(false);
									}}
								>
									SIGUIENTE
								</Button>
							</Col>

							<Col span={6} style={{ textAlign: "right" }}></Col>
						</Row>
					}
				>
					{/* <form action="">
							<div className="pasos">PASO 2 DE 3</div>
							<div className="form-group">
								<label htmlFor="">TITULO DE COBRO:</label>
								<input
									className="form-control"
									name="titulo"
									value={titulo}
									onChange={onInputChange}
								/>
							</div>
							<div className="form-group">
								<label htmlFor="">VALOR DEL COBRO:</label>
								<input
									type="number"
									className="form-control"
									name="valor"
									placeholder="$ 0.0"
									value={valor}
									onChange={onInputChange}
								/>
							</div>
							<span className="leyenda">
								* Este valor puede editado posteriormente y además colocar
								valores diferentes de cobro para cada usuario residencial.
							</span>
						</form> */}

					<>
						<Row>
							<Col span={4}></Col>
							<Col span={16} style={{ width: "90%" }}>
								<form action="">
									<div className="form-group">
										<label htmlFor="">TITULO DE COBRO:</label>
										<input
											className="form-control"
											name="titulo"
											value={titulo}
											onChange={onInputChange}
											style={{ backgroundColor: "#F2F2F2" }}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">CARGAR LISTADO DE COBRO</label>
										{/* <input
										type="number"
										className="form-control"
										name="valor"
										placeholder="$ 0.0"
										value={valor}
										onChange={onInputChange}
									/> */}
										{/* <Upload {...props} maxCount={1}>
										<Button icon={<UploadOutlined />}>
											Solo archivo Excel
										</Button>
									</Upload> */}
										<input
											className="form-control"
											type="file"
											name="file"
											id="file"
											accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
											onChange={(e) => handleExcel(e)}
											style={{
												backgroundColor: "#F2F2F2",
												width: "0.1px",
												height: "0.1px",
												opacity: "0",
												overflow: "0",
												position: "absolute",
												zIndex: "-1",
											}}
										/>
										<label
											htmlFor="file"
											className="form-control"
											style={{
												backgroundColor: "#F2F2F2",
												textAlign: "center",
												cursor: "pointer",
											}}
										>
											{fileName ? fileName : "Seleccionar archivo excel"}
										</label>
									</div>

									{/* <span className="leyenda">
									* Este valor puede editado posteriormente y además colocar
									valores diferentes de cobro para cada usuario residencial.
								</span> */}

									<div className="form-group contIdTipo">
										<label htmlFor="">FRECUENCIA DE COBRO:</label>
										<Select
											id={"idTipo"}
											defaultValue={tipoCobro}
											style={{
												width: "100%",
												backgroundColor: "#F2F2F2",
											}}
											onChange={(value) => {
												setTipoCobro(value);
											}}
										>
											<Option
												style={{ backgroundColor: "#F2F2F2" }}
												value="Única vez"
											>
												Única vez
											</Option>
											<Option
												style={{ backgroundColor: "#F2F2F2" }}
												value="Mensual"
											>
												Mensual
											</Option>
										</Select>
									</div>
									{tipoCobro == "Mensual" ? (
										<Row>
											<Col span={12} style={{ textAlign: "left" }}>
												<div className="contOpciones">
													<div className="form-group contDiaEmision">
														<label htmlFor="">DíA DE EMISIÓN</label>
														<Select
															id={"diaEmision"}
															defaultValue={diaEmision}
															style={{
																width: "100%",
															}}
															onChange={(value) => {
																setDiaEmision(value);
															}}
														>
															{listDias.map((item, index) => {
																return <Option value={item}>{item}</Option>;
															})}
														</Select>
													</div>
												</div>
											</Col>

											<Col span={12} style={{ textAlign: "left" }}>
												<div className="contOpciones">
													<div className="form-group contDiaVencimiento">
														<label htmlFor="">DíA DE VENCIMIENTO</label>
														<Select
															id={"idDiaVencimiento"}
															defaultValue={diaVencimiento}
															style={{
																width: "100%",
															}}
															onChange={(value) => {
																setDiaVencimiento(value);
															}}
														>
															{listDias.map((item, index) => {
																return <Option value={item}>{item}</Option>;
															})}
														</Select>
													</div>
												</div>
											</Col>
										</Row>
									) : (
										<div className="contOpciones">
											<div className="form-group contFechaEmision">
												<Row>
													<Col span={24} style={{ textAlign: "left" }}>
														<label htmlFor="">
															FECHA DE EMISIÓN
															&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FECHA
															DE VENCIMIENTO
														</label>
														<RangePicker
															format={customFormat}
															locale={locale}
															style={{
																width: "100%",
															}}
															onChange={onChangeFecha}
														/>
													</Col>
												</Row>
											</div>
										</div>
									)}
									<div className="form-group">
										<label htmlFor="">PORCENTAJE MORA:</label>
										<input
											type="number"
											step="0.01"
											min="0"
											className="form-control"
											name="porcentaje"
											value={porcentaje}
											onChange={onInputChange}
											style={{ backgroundColor: "#F2F2F2" }}
										/>
									</div>
								</form>
							</Col>
							<Col span={4}></Col>
						</Row>
					</>
				</Modal>

				<Modal
					className="primary-modal"
					title={
						<>
							<div className="ant-modal-title">
								<p>
									<img
										src={laptopExcel}
										alt="laptop-excel"
										style={{ paddingRight: "1rem" }}
									/>
									CREAR NUEVO COBRO
								</p>
							</div>
						</>
					}
					visible={isModalVisiblePasoTres}
					onCancel={() => handleCancelPasoTres()}
					footer={
						<Row>
							<Col span={6} style={{ textAlign: "left" }}>
								<Button
									className="btn-cancel"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setIsModalVisiblePasoTres(false);
										setIsModalVisiblePasoDos(true);
									}}
								>
									REGRESAR
								</Button>
							</Col>

							<Col span={6} style={{ textAlign: "center" }}></Col>

							<Col span={12} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										onHandleSubmitValidarExcel(false);
									}}
								>
									{loadSendData ? (
										<div className="centerSpinner">
											{" "}
											<Spinner />
										</div>
									) : (
										"SIGUIENTE"
									)}
								</Button>
							</Col>
						</Row>
					}
				>
					<Row>
						<Col span={4}></Col>
						<Col span={16} style={{ width: "90%" }}>
							{/* <div className="form-group contIdTipo">
								<label htmlFor="">FRECUENCIA DE COBRO:</label>
								<Select
									id={"idTipo"}
									defaultValue={tipoCobro}
									style={{
										width: "100%",
										backgroundColor: "#F2F2F2",
									}}
									onChange={(value) => {
										setTipoCobro(value);
									}}
								>
									<Option value="Única vez">Única vez</Option>
									<Option value="Mensual">Mensual</Option>
								</Select>
							</div> */}

							{/* {tipoCobro == "Mensual" ? (
								<Row>
									<Col span={12} style={{ textAlign: "left" }}>
										<div className="contOpciones">
											<div className="form-group contDiaEmision">
												<label htmlFor="">DíA DE EMISIÓN</label>
												<Select
													id={"diaEmision"}
													defaultValue={diaEmision}
													style={{
														width: "100%",
													}}
													onChange={(value) => {
														setDiaEmision(value);
													}}
												>
													{listDias.map((item, index) => {
														return <Option value={item}>{item}</Option>;
													})}
												</Select>
											</div>
										</div>
									</Col>

									<Col span={12} style={{ textAlign: "left" }}>
										<div className="contOpciones">
											<div className="form-group contDiaVencimiento">
												<label htmlFor="">DíA DE VENCIMIENTO</label>
												<Select
													id={"idDiaVencimiento"}
													defaultValue={diaVencimiento}
													style={{
														width: "100%",
													}}
													onChange={(value) => {
														setDiaVencimiento(value);
													}}
												>
													{listDias.map((item, index) => {
														return <Option value={item}>{item}</Option>;
													})}
												</Select>
											</div>
										</div>
									</Col>
								</Row>
							) : (
								<div className="contOpciones">
									<div className="form-group contFechaEmision">
										<Row>
											<Col span={24} style={{ textAlign: "left" }}>
												<label htmlFor="">
													FECHA DE EMISIÓN
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FECHA
													DE VENCIMIENTO
												</label>
												<RangePicker
													format={customFormat}
													locale={locale}
													style={{
														width: "100%",
													}}
													onChange={onChangeFecha}
												/>
											</Col>
										</Row>
									</div>
								</div>
							)} */}
						</Col>
						<Col span={4}></Col>
					</Row>
				</Modal>
				{/* MODAL DE TABLA */}
				{adminCobroNuevo == null ? null : (
					<Modal
						className="side-modal alert-modal modalEncuesta"
						bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 170px)" }}
						visible={isModalVisibleUser}
						title={
							<Row
								className="encabezado"
								style={{ textAlign: "left", paddingLeft: "25px" }}
							>
								<Col span={20}>
									<div>NOMBRE DE COBRO</div>
									<h2>{adminCobroNuevo.title}</h2>
									<Row>
										<Col span={8}>
											<strong>FRECUENCIA DE COBRO:</strong>{" "}
											{adminCobroNuevo.type == 1 ? "Única vez" : "Mensual"}
										</Col>
										{/* <Col span={8}>
											<strong>INICIO DE COBRO:</strong>{" "}
											{adminCobroNuevo.type == 1
												? adminCobroNuevo.dateEmision
												: adminCobroNuevo.dayEmision + " de cada mes"}
										</Col>
										<Col span={8}>
											<strong>FINALIZACIÓN DE COBRO:</strong>{" "}
											{adminCobroNuevo.type == 1
												? adminCobroNuevo.dateVencimiento
												: adminCobroNuevo.dayVencimiento + " de cada mes"}
										</Col> */}
									</Row>
								</Col>

								{adminCobroNuevo.new == true ? null : (
									<Col span={4} style={{ display: "flex" }}>
										<div className="bloqueEncuesta">
											<div
												className="descargar"
												onClick={() => {
													ExportCobro(cobroDataFull);
												}}
											>
												<img
													className="sheets"
													src={sheets}
													alt="excel-icon"
												/>
												DESCARGAR ARCHIVO
											</div>
										</div>
									</Col>
								)}
							</Row>
						}
						style={{ top: 0, right: 0, width: "100%" }}
						onCancel={() => setIsModalVisibleUser(false)}
						footer={
							<Row className="widthFooter">
								<Col span={6} style={{ textAlign: "left" }}>
									{onlyRead === false ? (
										<Button
											className="btn-cancel"
											type="primary"
											shape="round"
											style={{ width: "90%" }}
											onClick={() => {
												setIsModalVisiblePasoTres(false);
												setIsModalVisiblePasoDos(true);
												setIsModalVisiblePasoUno(false);
												setIsModalVisibleUser(false);
											}}
										>
											REGRESAR
										</Button>
									) : null}
								</Col>

								<Col span={12} style={{ textAlign: "center" }}>
									{onlyRead === true ? (
										<Button
											className="btn-cancel"
											type="primary"
											shape="round"
											style={{ width: "90%" }}
											onClick={() => {
												setIsModalVisiblePasoTres(false);
												setIsModalVisiblePasoDos(false);
												setIsModalVisiblePasoUno(false);
												setIsModalVisibleUser(false);
											}}
										>
											CERRAR
										</Button>
									) : null}

									{/* {adminCobroNuevo.eraser == true ? (
										<Button
											// disabled={requests.isSaving}
											className="btn-borrador"
											type="primary"
											shape="round"
											style={{ width: "90%" }}
											onClick={() => {
												onHandleGuardarEncuestaExcel(true);
											}}
										>
											{loadSendDataBorrador ? (
												<div className="centerSpinner">
													{" "}
													<Spinner />
												</div>
											) : (
												"GUARDAR Y CONTINUAR DESPUÉS"
											)}
										</Button>
									) : null} */}
								</Col>

								<Col span={6} style={{ textAlign: "right" }}>
									{onlyRead === false ? (
										<Button
											// disabled={requests.isSaving}
											className="btn-primary"
											type="primary"
											shape="round"
											//disabled
											style={{ width: "90%" }}
											loading={loadingSave}
											onClick={() => {
												//onHandleGuardarEncuestaExcel(false);
												setFlagUpload(true);
												onHandleSyncExcel();
											}}
										>
											{loadSendData ? (
												<div className="centerSpinner">
													{" "}
													<Spinner />
												</div>
											) : (
												"CREAR COBRO"
											)}
										</Button>
									) : null}
								</Col>
							</Row>
						}
						width={"95%"}
					>
						<div style={{ height: "80vh" }}>
							{onlyRead === false ? (
								<Table
									className="primary-table"
									columns={columns}
									dataSource={adminCobroNuevo.listadoUser}
									pagination={{ defaultPageSize: 10, simple: true }}
									rowKey="idClient"
									scroll={{ x: 3460, y: "calc(100vh - 19em)" }}
								/>
							) : (
								<>
									<Table
										loading={loading}
										className="primary-table"
										columns={columnsReadMode}
										dataSource={cobroDataFull}
										pagination={{ defaultPageSize: 10, simple: true }}
										rowKey="idClient"
										scroll={{ x: 2470, y: "calc(100vh - 19em)" }}
									/>
								</>
							)}
						</div>
					</Modal>
				)}
			</div>

			{/* MODAL MORA */}
			<Modal
				className="side-modal"
				title="Detalle del pago"
				style={{ top: 0, right: 0 }}
				visible={moraModalActive}
				onCancel={() => handleMoraModal(false)}
				footer={null}
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
				width={600}
			>
				<>
					<Row className="section-modal" style={{ paddingTop: "0px" }}>
						<Col span={24}>
							<p>USUARIO</p>
						</Col>
						<Col span={18}>
							<h2>Mantenimiento Residencial</h2>
						</Col>
						<Col span={6} align="middle">
							<p>TOTAL:</p>
							<h3 style={{ color: "#10B48C" }}>$ 697.84</h3>
						</Col>
						<Col span={24}>
							<p>FECHA DE PAGO: 31/01/23</p>
						</Col>
					</Row>
					<Row className="section-modal" style={{ paddingTop: "0px" }}>
						<Col span={24}>
							<Table
								className="primary-table"
								columns={columnsMora}
								pagination={{ defaultPageSize: 8, simple: true }}
								rowKey="id"
							/>
						</Col>
					</Row>
				</>
			</Modal>
		</>
	);
};
