import { axiosCreate } from "../../../../../config";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const PROFILE_PATH = 'company/profile/user'

//Endpoint de topia con el que se puede recuperar los datos de los perfiles de usuario de una cuenta, trayendo datos como el id de perfil, el nombre y los apellidos
export const getProfilesByCompany = async ({
    token,
    company_id,
    limit = 5,
    skip = 0,
    find_by,
    find_value,
    sort_by,
    sort_order,
}) => {

    try {
        const params = {
            company_id,
            limit,
            skip,
            find_by,
            find_value,
            sort_by,
            sort_order,
        }

        const res = await axiosCreate.get(`${PROFILE_PATH}`, {
            params,
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }

}