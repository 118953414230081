import { axiosCreate } from "../../../../../config";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const AMENITIES_PATH = 'place/amenity'

//Endpoint de topia con el que se recuperan todas las amenidades disponibles de una cuenta
export const getAllAmenities = async ({
    token,
    company_id,
    limit = 5,
    skip = 0,
    find_by,
    find_value,
}) => {

    try {
        const params = {
            company_id,
            limit,
            skip,
            find_by,
            find_value,
        }

        const res = await axiosCreate.get(`${AMENITIES_PATH}`, {
            params,
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }

}