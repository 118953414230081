import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
//import { outsideClicks } from "../../helpers/useOutsideClicksHelper";
import { clearSubmenu } from "../../store/submenu/submenuSlice";

export const Submenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [endAnimation, setEndAnimation] = useState(false);
  const { menu } = useSelector((state) => state.submenu);
  const theme = useSelector((state) => state.sidebarTheme);
  const firstLoading = useRef(true)
  const menuRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (menu.items && menu.items.length) {
      firstLoading.current = false;
      setIsOpen(true)
    }

    if (firstLoading.current) return

    if (!menu.items) {
      setEndAnimation(true)
    }

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !document.getElementsByClassName('ant-layout-sider-children')[0].contains(event.target)) {
        dispatch(clearSubmenu());
      }
    };
    // Agregar los listeners
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    // Limpiar los listeners
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [menuRef, menu.items, dispatch]);

  const handleMenuItemClick = (item) => {
    dispatch(clearSubmenu());   
  };

  //Cada vez que se endAnimation sea true se ejecutará una animación y en cuanto esta termine
  //se detecta con "onAnimationEnd" y se ejecutará esta función, para finalmente cerrar el menú
  const handleCloseMenu = (e) => {
    if (endAnimation) {
      setEndAnimation(false);
      setIsOpen(false);
    }
    if (!isOpen) {
      dispatch(clearSubmenu());
    }
  };
  return (
    <>
      {isOpen && (
        <div
          style={{ backgroundColor: theme.submenuStyle.backgroundColor }}
          className={`submenu ${endAnimation ? "hide-submenu" : ""}`}
          onAnimationEnd={handleCloseMenu}
          ref={menuRef}
        >
          <div className="submenu-container">
            <div className="submenu-header">
              <h2 style={{ color: theme.submenuStyle.titleColor }}>
                {menu?.title}
              </h2>
            </div>
            <ul>
              {menu?.items?.map((item, key) => {
                return (
                  <li key={key}>
                    <NavLink
                      to={item.url}
                      style={{ color: theme.submenuStyle.color }}
                      onClick={() => handleMenuItemClick(item)}
                    >
                      {item.name}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
