import { Switch } from "antd"

const CustomSwitch = ({
  checked,
  className,
  disabled,
  loading,
  size,
  onChange,
  onClick,
  defaultChecked
}) => (
  <Switch      
    checked={checked}
    className={className}
    disabled={disabled}
    defaultChecked={defaultChecked}
    size={size}
    onChange={onChange}
    onClick={onClick}
    loading={loading}
  />
)

export default CustomSwitch
