import { useState } from "react";
import { Content } from "antd/lib/layout/layout";
import {
	Col,
	Row,
	Button,
	Modal,
	Radio,
	Table,
	Layout,
	Switch,
	Input,
	Form,
} from "antd";
import { Space } from "antd";
import "react-activity/dist/library.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./styles.css";
import FormItem from "antd/lib/form/FormItem";
import { getColumnSearchProps } from "../../../utils/tables";
import { printIcon, servicioAlCliente, smallEdit, greenLike, greenCalendar, pool } from '../../../assets/icons'
import { shape20, shape22, metodoPago, shape } from '../../../assets/images'

const AdminAmenidadesPage = () => {
	const [detail, setDetail] = useState(false);
	const { TextArea } = Input;
	const [valueOptionPago, setValueOptionPago] = useState(1);
	const [reservationsModalActive, setReservationsModalActive] = useState(false);
	const [historyModalActive, setHistoryModalActive] = useState(false);
	const [newAmenidadModalActive, setNewAmenidadModalActive] = useState(false);
	const [editAmountModalActive, setEditAmountModalActive] = useState(false);
	const [changeIconModalActive, setChangeIconModalActive] = useState(false);
	const [updateAmenidadModalActive, setUpdateAmenidadModalActive] =
		useState(false);

	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const IconButtonReport = () => (
		<img
			alt="print-icon"
			style={{ width: "20px", height: "20px" }}
			src={printIcon}
		/>
	);

	const onChangeRadio = (e) => {
		setValueOptionPago(e.target.value);
	};

	//Modal functions
	const handleDetailWindow = (statusModal, record = null) => {
		if (record != null) {
		}
		setTimeout(() => {
			setDetail(statusModal);
		}, 50);
	};
	const handleReservationModal = (statusModal, record = null) => {
		if (record != null) {
		}
		setTimeout(() => {
			setReservationsModalActive(statusModal);
		}, 50);
	};
	const handleHistoryModal = (statusModal, record = null) => {
		if (record != null) {
		}
		setTimeout(() => {
			setHistoryModalActive(statusModal);
		}, 50);
	};
	const handleNewAmenidadModal = (statusModal, record = null) => {
		if (record != null) {
		}
		setTimeout(() => {
			setNewAmenidadModalActive(statusModal);
		}, 50);
	};
	const handleEditAmountModal = (statusModal, record = null) => {
		if (record != null) {
		}
		setTimeout(() => {
			setEditAmountModalActive(statusModal);
		}, 50);
	};
	const handleChangeIconModal = (statusModal, record = null) => {
		if (record != null) {
		}
		setTimeout(() => {
			setChangeIconModalActive(statusModal);
		}, 50);
	};
	const handleUpdateAmenidadIconModal = (statusModal, record = null) => {
		if (record != null) {
		}
		setTimeout(() => {
			setUpdateAmenidadModalActive(statusModal);
		}, 50);
	};

	const onFinishNewAmenityForm = (e) => { };
	//
	const reservationsTable = [
		{
			title: "USUARIO",
			dataIndex: "residentName",
			key: "residentName",
			align: "left",
			...getColumnSearchProps("residentName"),
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},

		{
			title: "FECHA",
			dataIndex: "type",
			key: "type",
			/* sorter: (a, b) => a.type - b.type, */
		},
		{
			title: "HORARIO",
			dataIndex: "time",
			key: "time",
			align: "center",
			/* sorter: (a, b) => a.constructionFee - b.constructionFee,
				render: (text, record) => (
					<>{moment(record.date_action).format("YY/MM/D")}</>
				), */
		},
	];
	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<h2 className="title-page">Administración de amenidades</h2>

						<Row>
							<Col lg={10} sm={24}>
								<Button
									className="btn-primary-radio"
									onClick={() => {
										handleNewAmenidadModal(true);
									}}
								>
									<img src={shape20} alt="add-amenity-icon" />
									NUEVA AMENIDAD
								</Button>

								<Tabs style={{ marginTop: "10px" }} className="tabsMovil">
									<TabList>
										<Tab tabfor={0}>AMENIDADES CON COBRO</Tab>
										<Tab tabfor={0}>AMENIDADES SIN COBRO</Tab>
									</TabList>

									<TabPanel tabid={0}>
										<ul className="primary-card-list">
											<li
												key={"id"}
												onClick={() => {
													handleDetailWindow(true);
												}}
												className={"primary-card-card"}
											>
												<img
													className="iconMetodo"
													src={metodoPago}
													alt="payment-method-icon"
												/>
												<div className="primary-card-info">
													<h3>{"LAP POOL"}</h3>
													<span>Última modificación: {"30/01/2023"}</span>
													<br />
												</div>

												<img
													className="shape-next"
													src={shape22}
													alt="right-arrow"
												/>
											</li>
										</ul>
									</TabPanel>
									<TabPanel tabid={1}>
										<ul className="primary-card-list">
											<li
												key={"id"}
												onClick={() => { }}
												className={"primary-card-card"}
											>
												<img
													className="iconMetodo"
													src={metodoPago}
													alt="payment-method-icon"
												/>
												<div className="primary-card-info">
													<h3>{"OUTDOOR GYM"}</h3>
													<span>Última modificación: {"28/01/2023"}</span>
													<br />
												</div>

												<img
													className="shape-next"
													src={shape22}
													alt="right-arrow"
												/>
											</li>
										</ul>
									</TabPanel>
								</Tabs>
							</Col>

							<Col lg={{ span: 12, offset: 2 }} sm={24}>
								{detail === false ? (
									<div className="contCenter">
										<div className="contVacio">
											<img
												className="shape"
												src={shape}
												alt="empty-img"
											/>
											<img
												className="iconFactura"
												src={servicioAlCliente}
												alt="servicio-al-cliente"
											/>
										</div>
										<span style={{ color: "#92A5A8" }}>
											SELECCIONAR AMENIDAD
										</span>
									</div>
								) : (
									<>
										<div
											className="primary-card-detail survey-detail"
											style={{ width: "80%" }}
										>
											<Row
												style={{
													padding: "0px",
													borderBottom: "1px solid #ccc",
												}}
											>
												<Col span={24}>
													<div className="section-modal-active">
														<p>Disponible</p>
														<Switch
															defaultChecked
															style={{ backgroundColor: "#16A07F" }}
															onChange={(checked) => { }}
														/>
													</div>
													<div
														className="primary-card-section"
														style={{ lineHeight: "16px" }}
													>
														<h4>AMENIDAD</h4>
														<h2>{"LAP POOL"}</h2>
													</div>
												</Col>
											</Row>

											<div className="primary-card-section">
												<Row style={{ padding: "10px" }}>
													<Col lg={12}>
														<>
															<span className="tituloDetalle">
																TARIFA DE USO:
															</span>
															<span
																className="montoDetalle"
																style={{ fontWeight: "600" }}
															>
																{" "}
																${"5.00 x hora"}
															</span>
															<Button
																onClick={() => {
																	handleEditAmountModal(true);
																}}
																modalvisible="true"
																shape="round"
																style={{ width: "50%", borderColor: "#10b48c" }}
															>
																<div
																	style={{
																		display: "flex",
																		textAlign: "center",
																		color: "#404040",
																	}}
																>
																	<img
																		className="iconButton"
																		src={smallEdit}
																		alt="edit-icon"
																		style={{
																			marginLeft: "5px",
																			width: "16px",
																			height: "16px",
																		}}
																	/>
																	<>Editar</>
																	{/* {loadMoreData ? (
																<div style={{ marginLeft: 20 }}>
																	<Spinner />
																</div>
															) : null} */}
																</div>
															</Button>
														</>
														<div>
															<span className="tituloDetalle">
																ÚLTIMA MODIFICACIÓN:
															</span>
															<span className="fechaDetalle">
																{"30/01/2023 (Carlos Alvarez)"}
															</span>
														</div>
													</Col>

													<Col lg={12} className="contCreacion">
														<Button
															onClick={() => {
																handleReservationModal(true);
															}}
															modalvisible="true"
															className="btn-primary-radio"
															style={{ width: "100%", borderColor: "#10b48c" }}
														>
															<div
																style={{
																	display: "flex",
																	color: "#232C4A",
																	marginLeft: "1.5rem",
																}}
															>
																<img
																	className="iconButton"
																	src={greenLike}
																	alt="like-icon"
																/>
																RESERVACIONES
																{/* {loadMoreData ? (
																<div style={{ marginLeft: 20 }}>
																	<Spinner />
																</div>
															) : null} */}
															</div>
														</Button>

														<Button
															onClick={() => {
																handleHistoryModal(true);
															}}
															modalvisible="true"
															className="btn-primary-radio"
															style={{ width: "100%", borderColor: "#10b48c" }}
														>
															<div
																style={{
																	display: "flex",
																	textAlign: "center",
																	color: "#232C4A",
																	marginLeft: "1.5rem",
																}}
															>
																<img
																	className="iconButton"
																	src={greenCalendar}
																	alt="green-calendar-icon"
																/>
																<>HISTORIAL</>
																{/* {loadMoreData ? (
																<div style={{ marginLeft: 20 }}>
																	<Spinner />
																</div>
															) : null} */}
															</div>
														</Button>
													</Col>
												</Row>

												<Row
													style={{
														borderTop: "1px solid #ccc",
														borderBottom: "1px solid #ccc",
													}}
												>
													<Col lg={24}>
														<div style={{ paddingLeft: "0px" }}>
															<h4>DESCRIPCIÓN:</h4>
															<p>
																{/* {adminCobroDetail.type == 1
																? "Única vez"
																: "Mensual"} */}
																{
																	"Piscina de borde infinito con sala de estar hundida. Piscina de borde infinito con sala de estar hundida"
																}
															</p>
														</div>
													</Col>
												</Row>
												<Row
													style={{
														borderTop: "1px solid #ccc",
													}}
												>
													<Col lg={24}>
														<div style={{ paddingLeft: "0px" }}>
															<h4>ICONO PARA APP:</h4>
															<div
																style={{ display: "flex", paddingTop: "1rem" }}
															>
																<img
																	src={pool}
																	alt="pool-icon"
																	style={{ paddingRight: "2rem" }}
																/>
																<Button
																	onClick={() => {
																		handleChangeIconModal(true);
																	}}
																	modalvisible="true"
																	className="btn-primary-radio"
																	style={{
																		borderColor: "#10b48c",
																	}}
																>
																	<div
																		style={{
																			display: "flex",
																			textAlign: "center",
																			color: "#232C4A",
																		}}
																	>
																		<>Cambiar icono</>
																		{/* {loadMoreData ? (
																<div style={{ marginLeft: 20 }}>
																	<Spinner />
																</div>
															) : null} */}
																	</div>
																</Button>
															</div>
														</div>
													</Col>
													<Col
														span={24}
														align="middle"
														style={{ paddingTop: "3rem", paddingBottom: "0px" }}
													>
														<Button
															onClick={() => {
																handleUpdateAmenidadIconModal(true);
															}}
															modalvisible="true"
															shape="round"
															size="large"
															style={{
																borderColor: "#10b48c",
																backgroundColor: "#10b48c",
																width: "50%",
															}}
														>
															<div
																style={{
																	textAlign: "center",
																	color: "#FFFFFF",
																	fontSize: "14px",
																	fontWeight: "500",
																}}
															>
																ACTUALIZAR AMENIDAD
															</div>
														</Button>
													</Col>
												</Row>

												{/* <Row
													style={{
														textAlign: "center",
														margin: "auto",
														display: "block",
													}}
												>
													<Button
											onClick={() => {
												actualizarCobro(false, adminCobroDetail);
											}}
											className="btn-primary"
											type="primary"
											shape="round"
										>
											<div style={{ display: "flex" }}>
												ACTUALIZAR COBRO
												{loadMoreData ? (
													<div style={{ marginLeft: 20 }}>
														<Spinner />
													</div>
												) : null}
											</div>
										</Button>
												</Row> */}
											</div>
										</div>
									</>
								)}
							</Col>
						</Row>

						{/* MODAL RESERVACIONES */}

						<Modal
							className="side-modal"
							title="Lista de reservaciones"
							style={{ top: 0, right: 0 }}
							visible={reservationsModalActive}
							onCancel={() => handleReservationModal(false)}
							footer={null}
							bodyStyle={{
								overflowY: "auto",
								maxHeight: "calc(100vh - 100px)",
							}}
							width={600}
						>
							<>
								{
									//reportData ? (
									<>
										<Row
											className="section-modal"
											style={{ paddingTop: "0px" }}
										>
											<Col span={24}>
												<p>AMENIDAD</p>
												<h2>Reservaciones - Lap Pool</h2>
											</Col>
										</Row>
										<Row
											className="section-modal"
											style={{ borderBottom: "0px" }}
										>
											<Col span={24}>
												<Table
													className="primary-table"
													pagination={{ defaultPageSize: 20, simple: true }}
													columns={reservationsTable}
													loading="true"
													rowKey={"id"}
													locale={locale}
												/>
											</Col>
											<Col
												span={24}
												align="middle"
												style={{ paddingTop: "3rem" }}
											>
												<Button
													style={{
														color: "#10b48c",
														borderColor: "#10b48c",
														background: "white",
														paddingBottom: "15px",
														marginLeft: "15px",
														marginRight: "15px",
														width: "35%",
													}}
													shape="round"
													icon={<IconButtonReport />}
												>
													<span
														className="title"
														style={{ marginLeft: "10px", color: "#10b48c" }}
													>
														IMPRIMIR
													</span>
												</Button>
											</Col>
										</Row>
									</>
									//) : (
									//<></>
									//)
								}
							</>
						</Modal>

						{/* MODAL NUEVA AMENIDAD */}
						<Modal
							className="primary-modal"
							visible={newAmenidadModalActive}
							onCancel={() => setNewAmenidadModalActive(false)}
							footer={null}
							width={600}
						>
							<>
								<Row className="section-modal" style={{ textAlign: "center" }}>
									<Col span={24}>
										<h2 style={{ color: "#232C4A" }}>NUEVA AMENIDAD</h2>
									</Col>
								</Row>
								<Row className="section-modal" style={{ marginTop: "1rem" }}>
									<Col
										span={24}
										style={{
											width: "90%",
											paddingLeft: "2rem",
											paddingRight: "2rem",
										}}
									>
										<Form
											name="remember_user_form"
											onFinish={onFinishNewAmenityForm}
										>
											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>NOMBRE DE AMENIDAD:</p>
											</div>
											<FormItem name="title" {...config}>
												<Input
													style={{
														backgroundColor: "#F2F2F2",
														borderRadius: "5px",
													}}
												/>
											</FormItem>

											<div
												className="section-modal"
												style={{ paddingLeft: "5px" }}
											>
												<p>DESCRIPCIÓN CORTA:</p>
											</div>
											<FormItem name="description" {...config}>
												<TextArea
													rows={4}
													style={{
														backgroundColor: "#F2F2F2",
														borderRadius: "5px",
													}}
												/>
											</FormItem>

											<FormItem name="payment" {...config}>
												<Radio.Group
													onChange={onChangeRadio}
													value={valueOptionPago}
													style={{ width: "100%" }}
												>
													<Space>
														<Radio
															style={{ fontSize: "12px" }}
															value={1}
															defaultChecked={true}
														>
															Amenidad de paga
														</Radio>
														<Radio style={{ fontSize: "12px" }} value={2}>
															Amenidad gratuita
														</Radio>
													</Space>
												</Radio.Group>
											</FormItem>

											<FormItem>
												<Input
													placeholder="Seleccionar icono"
													style={{
														backgroundColor: "#F2F2F2",
														borderRadius: "5px",
														width: "40%",
													}}
												/>
											</FormItem>
										</Form>
									</Col>
									<Col span={24} align="middle">
										<Button
											style={{
												borderColor: "#10b48c",
												background: "#10b48c",
												paddingBottom: "15px",
												width: "250px",
											}}
											shape="round"
											size="large"
										>
											<span className="title" style={{ color: "#fff" }}>
												CREAR
											</span>
										</Button>
									</Col>
								</Row>
							</>
						</Modal>
					</div>
				</Content>
			</Layout>
		</>
	);
};

export default AdminAmenidadesPage;
