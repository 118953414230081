//import { API_URL } from '../../../../../../config'
import { DefaultTheme } from "../../../mocks/defaultTheme";
import { IntegroTheme } from "../../../mocks/integroTheme";

export const getTheme = async ({idTheme, login}) => {
  try {
    if(!idTheme)
    {
      localStorage.setItem("savedTheme", JSON.stringify(idTheme === "integro" ? IntegroTheme : DefaultTheme));  
      return { status: 200, data: idTheme === "integro" ? IntegroTheme : DefaultTheme };
    }
    if (localStorage.getItem("savedTheme")) {
      const res = JSON.parse(localStorage.getItem("savedTheme"));
      return { status: 200, data: res };
    }

    //const res = await axios.get('');
    return { status: 200, data: DefaultTheme };

  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      };
    }

    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      };
    }
  }
};
