import moment from 'moment'
import { getColumnsFilter } from '../../../../helpers/customTableHelper'
import { useMonitoringContratorsContext } from "../../contexts/MonitoringContratorsContext"
import { historyAccessTableColumnsPrev, historyAccessTableFilters } from "../../helpers/historyAccessTableHelper"
import useHistoryAccessServices from './useHistoryAccessServices'
import { defaulStateTable } from '../../mocks/defaultStates'
import { changeTableParams } from '../../helpers/tableFunctionsHelper'

export const useHistoryAccess = () => {

    const { monitoringContratorsTable, setHistoryAccessTable, historyAccessTable, setLoading, loading, param, handleRedirectTo } = useMonitoringContratorsContext()
    const { getContratorAccessDetailService } = useHistoryAccessServices()

    const serviceParams = { previousData: historyAccessTable.dataTable, actualLimit: historyAccessTable.metadata.limit, limit: historyAccessTable.metadata.limit, currentPage: historyAccessTable.currentPage, skip: 0 }
    const date_from = monitoringContratorsTable.active && moment(monitoringContratorsTable.active.dateBegin).format('YYYY-MM-DD')
    const date_to = monitoringContratorsTable.active && moment(monitoringContratorsTable.active.dateEnd).format('YYYY-MM-DD')

    const getInitialData = async () => {
        if (!param.accessId || !monitoringContratorsTable.active) return
        setLoading(true)
        const defaultData = structuredClone(defaulStateTable)
        const { data, metadata } = await getContratorAccessDetailService({ setLoading, currentPage: historyAccessTable.currentPage, previousData: historyAccessTable.dataTable, limit: 10, skip: 0, access_id: param?.accessId, date_from, date_to })
        setHistoryAccessTable({
            ...defaultData,
            dataTable: data.dataSource,
            currentParams: {
                ...defaultData.currentParams,
                date_from,
                date_to
            },
            metadata: {
                ...metadata,
            }
        })
        setLoading(false)
    }

    const handleWorkers = ({ record }) => {
        setHistoryAccessTable(prev => ({ ...prev, active: { ...monitoringContratorsTable.active, ...record, accessDate: moment(record.accessDate).format('DD/MM/YYYY') } }))
        handleRedirectTo(`workers/${record.accessId}`)
    }

    const handleProviders = ({ record }) => {
        setHistoryAccessTable(prev => ({ ...prev, active: { ...monitoringContratorsTable.active, ...record, accessDate: moment(record.accessDate).format('DD/MM/YYYY') } }))
        handleRedirectTo(`providers/${record.accessId}`)
    }

    const handleChangeDatePickerFilter = async (values) => {

        if (!values) return console.error('No hay fechas')
        const date_from = moment(values[0]).format('YYYY-MM-DD')
        const date_to = moment(values[1]).format('YYYY-MM-DD')

        setLoading(true)
        const { data, metadata } = await getContratorAccessDetailService({ setLoading, ...historyAccessTable.currentParams, date_from, date_to, access_id: param.accessId, limit: 10 })

        setHistoryAccessTable((prev) => ({
            ...prev,
            currentPage: 1,
            dataTable: data.dataSource,
            metadata,
            currentParams: {
                ...prev.currentParams,
                date_to,
                date_from
            },
            lastSkip: 0
        }))
        setLoading(false)
    }

    const handleChangeTable = async ({ pagination, filters, sorter }) => {
        const otherParams = {
            date_from: historyAccessTable.currentParams.date_from,
            date_to: historyAccessTable.currentParams.date_to,
        }
        const tableParams = changeTableParams({ serviceParams, pagination, filters, sorter, setState: setHistoryAccessTable, state: historyAccessTable, otherParams })
        if (!tableParams) return
        setLoading(true)

        const { data, metadata } = await getContratorAccessDetailService({ setLoading, ...tableParams.finalParams, access_id: param.accessId })
        setHistoryAccessTable(prev => ({
            ...prev,
            dataTable: data.dataSource,
            metadata,
            currentParams: { ...tableParams.newParams },
            currentFilter: tableParams.actualFilters,
            lastSkip: tableParams.finalParams.skip
        }))
        setLoading(false)
    }

    const columns = historyAccessTableColumnsPrev({ handleProviders, handleWorkers }).map(column => ({
        ...column,
        ...getColumnsFilter(column.dataIndex, historyAccessTableFilters({ currentParams: historyAccessTable.currentParams }))
    }))


    return {
        loading,
        monitoringContratorsTable,
        columns,
        historyAccessTable,
        date_from,
        date_to,
        getInitialData,
        handleChangeDatePickerFilter,
        handleChangeTable
    }
}

export default useHistoryAccess