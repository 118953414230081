import { clearSubmenu, setSubmenu } from "../../../../store/submenu/submenuSlice";
import { submenuHelper } from "../../../../helpers/submenuItemsHelper";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { capitalizeFirstLetterString } from "../helpers/capitalizeFirstLetterString";

export const useSidebar = () => {
  const dispatch = useDispatch();
  const submenu = useSelector((state) => state.submenu);
  const theme = useSelector((state) => state.sidebarTheme);
  const [template, setTemplate] = useState(null);
  const [hoverStyle, setHoverStyle] = useState({
    itemName: "",
    backgroundColor: "",
    image: "",
    color: "",
  });

  const onSubmenu = (submenuName) => {
    if (submenuName) {
      const menu = submenuHelper[submenuName];
      return dispatch(setSubmenu(menu));
    }
    dispatch(clearSubmenu())
  };

  const dynamicSidebarTheme = async () => {
    const module = await import(
      `../components/${theme.themeId}/${theme.themeId}`
    );
    const SidebarTheme = module[theme.themeId];
    const finalTemplate = <SidebarTheme dynamicData={theme} />;
    setTemplate(finalTemplate);
  };

  const getStyle = ({ item, isActive, style }) => {
    const activeStyle =
      (item.submenu && item.name === submenu.menu.title) ||
        (isActive && !submenu.menu.title)
        ? item[`active${capitalizeFirstLetterString({ string: style })}`]
        : item[style];
    if (
      activeStyle !== hoverStyle[style] &&
      hoverStyle[style] !== "" &&
      (((item.submenu && item.name !== submenu.menu.title) || (!isActive && !item.submenu)) && !isActive
      )
    ) {
      return hoverStyle[style];
    }
    return activeStyle;
  };

  const handleSetStyle = ({ reset = false, item }) => {
    if (reset) {
      setHoverStyle({
        itemName: item.name,
        backgroundColor: item.backgroundColor,
        image: item.image,
        color: item.color,
      });
      return;
    }

    setHoverStyle({
      itemName: item.name,
      backgroundColor: item.activeBackgroundColor,
      image: item.activeImage,
      color: item.activeColor,
    });
  };

  useEffect(() => {
    if (template === null) {
      dynamicSidebarTheme();
    }
  }, []);

  return {
    onSubmenu,
    template,
    submenu,
    setHoverStyle,
    getStyle,
    handleSetStyle,
  };
};
