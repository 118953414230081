import {
	onCreateOrUpdate,
	onUpdateDocWithRef,
} from "../../helpers/globalHelper";
import moment from "moment";
import {
	getAdminCobrosHelper,
	getAdminCobrosResponseHelper,
	getAllUserPagos,
	getAllNomenclaturas,
	getAllCasas,
	getPaymentType,
	getUserNameFromProfileId,
} from "../../helpers/adminCobrosHelper";
import {
	createAdminCobro,
	setAdminCobro,
	setAdminUserResidentes,
} from "./adminCobrosSlice";
import { FirebaseApp } from "../../firebase/config";
import { getFirestore } from "firebase/firestore/lite";

const db = getFirestore(FirebaseApp);

export const getAdminCobros = () => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;
		const allUserResidential = [];
		//const allUserResidential = await getAllUserResidential(committee.residential_id);
		const adminCobros = await getAdminCobrosHelper(committee.residential_id);

		var i = 0;
		while (i < adminCobros.length) {
			adminCobros[i].respuesta = await getAdminCobrosResponseHelper(
				committee.residential_id,
				adminCobros[i].id
			);
			++i;
		}

		Promise.all(adminCobros).then((data) => {
			dispatch(setAdminCobro(data));
		});
	};
};

export const getUserCobros = () => {
	return async (dispatch, getState) => {
		const { committee } = getState().auth;

		const nomenclaturas = await getAllNomenclaturas(committee.residential_id);
		const allDirecciones = [];
		const allUserResidential = await getAllUserPagos(committee.residential_id);

		var i = 0;
		while (i < nomenclaturas.length) {
			var item = nomenclaturas[i];
			var casas = await getAllCasas(committee.residential_id, item);
			var k = 0;
			while (k < casas.length) {
				allDirecciones.push(casas[k]);
				++k;
			}
			++i;
		}

		var j = 0;
		while (j < allUserResidential.length) {
			var item = allUserResidential[i];

			++j;
		}

		var k = 0;
		while (k < allUserResidential.length) {
			var item = allUserResidential[k];
			let findAddress = allDirecciones.find(
				(request) => request.path === item.path
			);
			if (typeof findAddress !== "undefined") {
				allUserResidential[k].address = findAddress.address;
			}

			++k;
			//address
		}

		Promise.all(allUserResidential).then((data) => {
			dispatch(setAdminUserResidentes(data));
		});
	};
};

export const saveAdminCobro = (data) => {
	return async (dispatch, getState) => {
		const { committee, uid } = getState().auth;

		delete data.new;
		data.amount = parseFloat(data.amount);

		data.createdBy = uid;
		data.residential_id = "Yd5MV7jkA0MjQeCmzKrZ";
		data.created_at = moment(new Date(), "DD/MM/YYYY").toDate();
		data.update_at = moment(new Date(), "DD/MM/YYYY").toDate();

		var listadoUser = data.listadoUser;
		delete data.listadoUser;
		var idPaymentTemplate = "";

		if (data.type === 1) {
			delete data.dayEmision;
			delete data.dayVencimiento;
			data.dateEmision = moment(data.dateEmision, "DD/MM/YYYY").toDate();
			data.dateVencimiento = moment(
				data.dateVencimiento,
				"DD/MM/YYYY"
			).toDate();
		} else {
			delete data.dateEmision;
			delete data.dateVencimiento;
		}

		if (data.id === "") {
			delete data.id;
			const paymentTemplate = await onCreateOrUpdate(
				`/payment_template/`,
				data
			);
			idPaymentTemplate = paymentTemplate.id;
		} else {
			idPaymentTemplate = data.id;
			await onUpdateDocWithRef(`/payment_template/${idPaymentTemplate}`, data);
		}

		var listadoPagos = [];
		var i = 0;

		while (i < listadoUser.length) {
			var itemPagoUser = {};
			itemPagoUser.idProfile = listadoUser[i].id;
			itemPagoUser.idResidential = "Yd5MV7jkA0MjQeCmzKrZ";
			itemPagoUser.idPayment = idPaymentTemplate;
			itemPagoUser.amount = parseFloat(listadoUser[i].valor);

			if (listadoUser[i].idP === 0) {
				const createdPayment = await onCreateOrUpdate(
					`/value_payment_template/`,
					itemPagoUser
				);
				itemPagoUser.idP = createdPayment.id;
			} else {
				await onUpdateDocWithRef(`/value_payment_template/`, itemPagoUser.idP);
			}

			listadoPagos.push(itemPagoUser);

			++i;
		}

		data.id = idPaymentTemplate;
		data.respuesta = listadoPagos;
		dispatch(createAdminCobro(data));
	};
};

const getTimestampFromString = (date) => {
	const time = Date.parse(date);

	return time;
};

export const saveCobroAguaToPaymentCollection = async (data) => {
	/* const { committee, uid } = getState().auth; */
	//PARA GENERAR EN PAYMENTS_TEMPLATE
	let arrTemp = {};
	arrTemp.active = true;
	arrTemp.created_at = moment(new Date(), "DD/MM/YYYY").toDate();
	arrTemp.payment_tempId = getTimestampFromString(
		arrTemp.created_at
	).toString();
	arrTemp.update_at = moment(new Date(), "DD/MM/YYYY").toDate();
	//arrTemp.payments = [];
	arrTemp.title =
		"Servicio Agua " +
		moment(arrTemp.created_at)
			.tz("America/El_Salvador")
			.locale("es")
			.format("MMMM");
	arrTemp.residential_id = "Yd5MV7jkA0MjQeCmzKrZ";
	arrTemp.eraser = false;
	const paymentTemplate = await onCreateOrUpdate(`/payment_template/`, arrTemp);

	//PARA GENERAR EN PAYMENTS
	data.map(async (item, index) => {
		let data = { ...item };
		data.payment_tempId = arrTemp.payment_tempId;
		//const payType = db.collection("payment_type").doc("4ll4c5y5pxn9AkukQx7S");
		const payType = await getPaymentType("4ll4c5y5pxn9AkukQx7S");
		data.payment_type = payType;
		var idPaymentTemplate = "";
		data.user_name = await getUserNameFromProfileId(data.profile_id);

		const paymentTemplate = await onCreateOrUpdate(`/payments/`, data);
		idPaymentTemplate = paymentTemplate.id;

		if (data.id) {
			idPaymentTemplate = data.id;
			await onUpdateDocWithRef(`/payments/${idPaymentTemplate}`, data);
		}
	});
	const dis = async (dispatch) => {
		dispatch(createAdminCobro(arrTemp));
	};

	return;
};
