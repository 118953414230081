import { Row, Col } from 'antd'
import { CustomRadioButton } from "../../../../../../../UI/CustomRadio/CustomRadioButton"
import { CustomSelect } from '../../../../../../../UI/CustomSelect'
import { CaretDownOutlined } from '@ant-design/icons'
import CustomTimePicker from '../../../../../../../UI/CustomTimePicker/CustomTimePicker'

//Componente del horario de la amenidad que muestras los detalles del mismo, para establcer un horario hábil para las reservas o hacerlas en cualquier momento y el periodo maximo de anticipacion para la reserva y la cantidad de reservas por año
const ScheduleDetails = ({ Form, generalRules }) => (
    <article>
        <h3>Horario hábil para hacer una reserva</h3>
        <Form.Item name='schedule_available' rules={[{ ...generalRules }]} >
            <CustomRadioButton options={[{ label: 'Establecer un horario hábil para reservar', value: 'schedule' }, { label: 'Podrán hacer reservas en cualquier momento', value: 'any_moment' },]} />
        </Form.Item>
        <h4>HORARIO DE RESERVAS</h4>
        <Row gutter={16} style={{ marginTop: 16, marginBottom: 32 }}>
            <Form.Item noStyle shouldUpdate>
                {
                    ({ getFieldValue }) => {
                        const isScheduleOption = getFieldValue('schedule_available') === 'schedule'

                        return (
                            <>
                                <Col span={6}>
                                    <Form.Item rules={[{ //Valida si la opcion seleccionada es "Establecer un horario hábil para una reserva" para validad el input de tiempo
                                        validator: async (_, value) => {
                                            if ((isScheduleOption && value) || (!isScheduleOption)) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error('El campo es necesario'))
                                        }
                                    }]} label={<small>Desde</small>} name='schecule_time_begin' >
                                        <CustomTimePicker disabled={!isScheduleOption} placeholder={'Inicio'} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item rules={[{ //Valida si la opcion seleccionada es "Establecer un horario hábil para una reserva" para validad el input de tiempo
                                        validator: async (_, value) => {
                                            if ((isScheduleOption && value) || (!isScheduleOption)) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error('El campo es necesario'))
                                        }
                                    }]} label={<small>Hasta</small>} name='schecule_time_end' >
                                        <CustomTimePicker disabled={!isScheduleOption} placeholder={'Fin'} />
                                    </Form.Item>
                                </Col>
                            </>
                        )
                    }
                }
            </Form.Item>
        </Row>
        <Form.Item rules={[{ ...generalRules }]} label={'Periodo máximo de anticipación para realizar reservas'} name={'max_time_amenity'}>
            <CustomSelect rules={[{ ...generalRules }]} svgIcon suffixIcon={<CaretDownOutlined />} placeholder={'Días / Meses'} />
        </Form.Item>
        <Form.Item rules={[{ ...generalRules }]} label={'Máximo de resevas por año'} name={'max_quantity_amenity'}>
            <CustomSelect rules={[{ ...generalRules }]} svgIcon suffixIcon={<CaretDownOutlined />} placeholder={'0 veces'} />
        </Form.Item>
    </article>
)

export default ScheduleDetails
