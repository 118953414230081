import { CustomNavigate } from "../../../../../../UI/CustomNavigate"

//Componente que renderiza el header de la pantalla inicial de Admon de Amenidades
const HeaderManagmentAmenities = ({ handleRedirectTo }) => (
  <header>
    <CustomNavigate title={'ADMÓN. AMENIDADES'} />
  </header>
)

export default HeaderManagmentAmenities
