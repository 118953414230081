import { Button, Divider, Typography } from 'antd'
import { ReactComponent as DeleteIcon } from '../assets/deleteIcon.svg'
const { Text, Paragraph } = Typography

const FilesList = ({ fileList, justImages, maxSizeInBytes, multipleFiles, verticalUpload, itemRender, getFileSize, handleRemoveFile, handleTextChange, handleOpenDialogFile }) => {
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>

                {
                    fileList?.map((file) => {

                        if (justImages) {
                            return (<div className='uploaded-image' key={file.uid}>
                                {itemRender(file)}
                                <div style={{ paddingRight:32 }}>
                                    <Text editable={{ onChange: (value) => handleTextChange({ id: file.uid, type: 'title', value }), autoSize: { maxRows: 1 }, tooltip: 'Editar' }} ellipsis>{file.title || `${file.name}`}</Text>
                                    <Paragraph editable={{ onChange: (value) => handleTextChange({ id: file.uid, type: 'description', value, }), autoSize: { maxRows: 3 }, tooltip: 'Editar' }} ellipsis={{ rows: 3 }}>{file.description || 'Descripción'}</Paragraph>
                                    <Button className='delete-file' icon={<DeleteIcon />} onClick={handleRemoveFile(file)} />
                                </div>
                            </div>)
                        }
                        return (
                            <div className='uploaded-file' key={file.uid} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <div className='uploade-file__file-info'>
                                    {itemRender(file)}
                                    <div>
                                        <span>
                                            <Paragraph ellipsis={{ rows: 2 }}>{file.name}</Paragraph>
                                        </span>
                                        <div>
                                            <small> Tamaño: {getFileSize(file.size)}</small>
                                            <Button className='delete-file' icon={<DeleteIcon />} onClick={handleRemoveFile(file)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Divider type={verticalUpload ? 'horizontal' : 'vertical'} />
            <div className='custom-upload-file__add-files'>
                <Button onClick={handleOpenDialogFile} shape='round'> {multipleFiles ? 'SUBIR MÁS ARCHIVOS' : 'SUBIR ARCHIVO OTRA VEZ'}</Button>
                <small>Tamaño máximo: {getFileSize(maxSizeInBytes, 0)}</small>
            </div>
        </>
    )
}

export default FilesList