import { checkParams, compareFiltersTable } from "../../../helpers/customTableHelper"

export const changeTableParams = ({ serviceParams, pagination, filters, sorter, state, setState, otherParams }) => {
    const filter = compareFiltersTable({ object1: state.currentFilters, object2: filters })
    const find_by = filter && Object.keys(filter)[0]
    const find_value = filter && filter[Object.keys(filter)[0]]
    const sort_order = sorter.order === 'descend' ? 'desc' : sorter.order === 'ascend' ? 'asc' : undefined
    const sort_by = sorter.column?.key
    const pageValue = pagination.current || state.currentPage
    const newSkip = (pageValue * state.metadata.limit) - state.metadata.limit
    const actualParams = { ...state.currentParams }
    const newParams = {
        ...otherParams,
        find_by,
        find_value,
        sort_order,
        sort_by,
        skip: 0
    }
    const finalParams = { ...serviceParams, ...newParams }

    let actualFilters = { ...state.currentFilters } ?? {}
    find_by ? actualFilters[find_by] = find_value : actualFilters = {}

    if (checkParams({ actualParams, newParams, difKey: 'skip' })) {
        setState(prev => ({ ...prev, currentPage: pageValue }))
        if (newSkip > state.lastSkip) {
            finalParams.skip = newSkip
        } else return null
    } else setState(prev => ({ ...prev, currentPage: 1 }))

    return { finalParams, newParams, actualFilters }
}