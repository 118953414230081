import { Form } from 'antd'
import { useManagmentAmenitiesContext } from "../context/ManagmentAmenitiesContext"
import { useRef } from 'react'
import moment from 'moment'

//Custom hook para utilizarlo en la pagina de crear/editar una amenidad
const useNewAmenity = () => {

    const { handleRedirectTo, param,
        amenityData, setAmenityData,
        showModal, setShowModal,
        fileList, setFileList,
        mobiliaryData, setMobiliaryData,
        restrictionsData, setRestrictionsData,
    } = useManagmentAmenitiesContext()

    const previousCheck = useRef([])

    const [form] = Form.useForm()

    //Función que se utilizar en el formulario principal para manipular los datos finales de cada item luego de ser validados, y para enviar los datos a algún endpoint
    const onSubmit = (values) => {
        console.log(values)
    }

    //Función que se utilizar en el formulario principal para mostrar un error más personalizado cuando hay al menos una validación que falló en el formulario
    const onFailedSubmit = (values) => {
        console.log(values)
    }

    //Función que se utiliza en los formulario del modal de mobiliario y restricciones informativas, según el name 
    //Donde además de cerrar el modal verifica si en los datos viene un id, si no viene es porque es un item nuevo, le añade el id único
    //Y luego añade el elemento al estado correspondiente, si existe el id busca el indice en el estado correspondiente y si es mayor o igual a 0 actualiza los datos de ese estado
    const onSubmitModal = ({ values, name, form }) => {
        const state = name === 'mobiliarityModal' ? [...mobiliaryData] : [...restrictionsData]
        const setState = name === 'mobiliarityModal' ? setMobiliaryData : setRestrictionsData

        form.resetFields()
        handleOpenCloseModal({ name, value: false })
        if (!values.id) {
            values.id = window.crypto.randomUUID()
            return setState(prev => [...prev, values])
        }
        const index = state.findIndex(item => item?.id === values?.id)
        if (index >= 0) {
            state[index] = values
            return setState(state)
        }
    }

    //Handle para poder eliminar un item de un mobiliario o de una restriccion informativa, donde devuelve un nuevo array donde no ya exista el id
    const handleDeleteItem = ({ actualItem, name }) => {
        const state = name === 'mobiliarityModal' ? [...mobiliaryData] : [...restrictionsData]
        const setState = name === 'mobiliarityModal' ? setMobiliaryData : setRestrictionsData
        const newState = state.filter(item => item?.id !== actualItem?.id)
        setState(newState)
    }

    //Handle para poder habilitar o deshabilitar los items de los días en el componente de Horarios donde se comprueba si está seleccionado el check de Horario universal
    //y/o está el check de Seleccionar todos
    const handleEnableDisableFormItem = ({ field, name, value }) => {
        if (name === 'days_options') {
            const days = form?.getFieldValue('days')
            previousCheck.current = value //Guarda el valor en la referencia
            //Se verifica si  actualmente hay algun check seleccionado y si solo está chequeado el horario universal y si anteriormente en una referencia no está incluido el check de seleccionar todos
            //o si actualmente no hay nada seleccionado y anteriormente en la referencia estába chequeado el horario universal
            //Si es así retorna la funcion
            if ((value && value[0] === 'universal' && !previousCheck.current.includes('all')) || (!value.length && previousCheck.current.includes('universal'))) {
                return
            }
            //Si no es así va a establecer en el formulario todos los días del horario como chequeado o no dependiendo si en el valor está incluido y reinicia las horas de inicio y fin si se está deschequeando los items
            const newDays = days.map(item => ({
                ...item,
                status: [...value].includes('all') ? 'checked' : 'unchecked',
                time_begin: [...value].includes('all') ? item.time_begin : null,
                time_end: [...value].includes('all') ? item.time_end : null,
            }))
            return form.setFieldsValue({ days: newDays })
        }

    }

    //Handle que se utiliza para actualizar un día del horario en específico, donde se verifica si está activo el check de seleccionar todos, si es así no se pueden desactivar los días
    //De lo contrario se busca el índice item en el array de días del horario y cambia el "status" dependiendo del estado actual que tenga
    const handleChangeDay = ({ field }) => {
        const days = form?.getFieldValue('days')
        const days_options = form?.getFieldValue('days_options')
        if (days_options?.includes('all')) return
        const actualItem = days[field.name]
        const itemIndex = days.findIndex(item => item === actualItem)
        actualItem.status = actualItem.status === 'checked' ? 'unchecked' : 'checked'
        days[itemIndex] = actualItem
        form.setFieldsValue({
            days
        })
    }

    //Handle que permite cambiar la hora de inicio o la hora de fin de un día del horario    
    const handleChangeTimeDay = ({ field, name, value }) => {
        const days = form?.getFieldValue('days')
        const days_options = form?.getFieldValue('days_options')
        const time_begin = days[field.name]?.time_begin
        const time_end = days[field.name]?.time_end

        //Se verifica que la hora de inicio no sea mayor a la hora de fin, si eso no es así entonces mostrará errores en los items correspondientes
        if (time_end && time_begin && time_begin?.clone().isSameOrAfter(time_end?.clone())) {
            form.setFields([
                {
                    name: ['days', field.name, 'time_end'],
                    errors: ['La hora de fin es menor a la hora de inicio']
                },
                {
                    name: ['days', field.name, 'time_begin'],
                    errors: ['La hora de inicio es mayor a la hora de fin']
                },
            ])
            return
        }


        let newDays = [...days]
        //Verifica si en los checks está activo el de horario universal, si eso es así entonces va a actualizar todos los días que estén activos con el valor de la hora de inicio o fin según el elemento que se esté modificando
        if (days_options?.includes('universal')) {
            newDays = [...newDays].map(item => {
                if (item.status === 'checked') {
                    item[name] = value.clone()
                }
                return item
            })
            form.setFieldsValue({ days: newDays })
        }

        //Resetea los errores 
        form.setFields([
            {
                name: ['days', field.name, 'time_end'],
                errors: []
            },
            {
                name: ['days', field.name, 'time_begin'],
                errors: []
            },
        ])
        return
        //Verificar las horas de inicio y fin que no se solapen

    }

    const handleOpenCloseModal = ({ name, value, data }) => {
        setShowModal(prev => ({
            ...prev,
            [name]: { status: value, data }
        }))
    }

    //Función para recuperar los datos iniciales del detalle de la amenidad
    const getInitialData = async () => {

    }

    //Objeto que se utiliza para las reglas básicas del formulario
    const generalRules = { required: true, message: 'El campo es necesario' }

    return { handleRedirectTo, handleChangeTimeDay, handleDeleteItem, onSubmit, onFailedSubmit, onSubmitModal, handleEnableDisableFormItem, handleChangeDay, handleOpenCloseModal, getInitialData, showModal, form, Form, fileList, mobiliaryData, restrictionsData, generalRules, setFileList }
}

export default useNewAmenity
