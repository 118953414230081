import React from 'react'
import { Tabs } from 'antd';
import { UsersTab } from './UsersTab';
import { BoardDirectorsTab } from './BoardDirectorsTab';

export const UsersTabs = () => {
  
    const { TabPane } = Tabs;
    return (
        <>
            <Tabs className='primary-tabs' defaultActiveKey="1">                
                <TabPane tab="Residentes" key="1">
                    <UsersTab />
                </TabPane>
                <TabPane tab="Junta directiva" key="2">
                    <BoardDirectorsTab />
                </TabPane>
            </Tabs>
        
        </>
    )
}
