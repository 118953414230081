import React, { useEffect, useState } from "react";
import {
	Space,
} from "antd";
import './ColorPicker.css';

const colors = [
	{	
		color: "#10B48C",
		icon: "/assets/images/green_circle.png"
	},
	{	
		color: "#232C4A",
		icon: "/assets/images/darkblue_circle.png"
	},
	{	
		color: "#1264E0",
		icon: "/assets/images/blue_circle.png"
	},
	{	
		color: "#686868",
		icon: "/assets/images/gray_circle.png"
	},
];

export default ({onPick, defaultColor = "#10B48C"}) => {

	const [selectedColor, setSelectedColor] = useState(defaultColor);

	useEffect(() => {
		onPick(selectedColor);
	}, [selectedColor]);

	useEffect(() => {
		setSelectedColor(defaultColor);
	}, [defaultColor]);

	return (
		<>
			<Space>
				{colors.map(({color, icon}) => (
					<div
						key={color} 
						className={`circle-color-item ${(color.toLowerCase() === selectedColor.toLowerCase() ? 'active-color' : "")}`}
						onClick={() => {
							setSelectedColor(color);
						}}>
						<div 
							className={`circle-color`}
							style={{backgroundColor: color}}
						></div>
					</div>
					
					// <img
					// 	className={`color-picker ${(color === selectedColor ? 'active-color' : "")}`}
					// 	src={icon}
					// 	style={{ cursor: "pointer" }}
					// 	onClick={() => {
					// 		setSelectedColor(color);
					// 	}}
					// />
				))}
				
			</Space>
		</>
	)
}