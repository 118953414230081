import { getTableDataHelper } from '../../../../helpers/getTableDataHelper'
import { useMonitoringContratorsContext } from '../../contexts/MonitoringContratorsContext'
import { getContratorAccessDetail } from '../../services/GET/getContratorAccessDetail'
import verifyResponse from '../../../../helpers/verifyResponse'

export const useHistoryAccessServices = () => {

    const { auth } = useMonitoringContratorsContext()

    const getContratorAccessDetailService = async ({ setLoading, previousData, currentPage, last, access_id, limit, skip, date_from, date_to, sort_by, sort_order }) => {

        const res = await getContratorAccessDetail({ token: auth.idToken, access_id, limit, skip, date_from, date_to, sort_by, sort_order })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error in getContratorAccessDetailService')
        }

        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, previousData, currentPage, last, actualLimit: limit, skip })
        return { data, metadata }
    }

    return {
        getContratorAccessDetailService
    }
}

export default useHistoryAccessServices