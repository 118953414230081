import { useState } from "react";
import { Tabs, DatePicker } from "antd";
import { AccessVisitorsTab } from "./AccessVisitorsTab";
import { AccessScheduleVisitorsTab } from "./AccessScheduleVisitorsTab";
import "../style.css";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import { AccessActiveVisitorsTab } from "./AccessActiveVisitorsTab";

const { RangePicker } = DatePicker;

export const AccessTabs = (props) => {
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const onChangeFecha = (date, dateString) => {
		if (dateString[0] && dateString[1]) buscar(dateString[0], dateString[1]);
	};

	const { buscarFechas, loadingBt } = props;

	const buscar = async (start, end) => {
		var fechaI = new Date(moment(start, "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(end, "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
		buscarFechas(fechaIn, fechaFi);
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const { TabPane } = Tabs;
	return (
		<>
			<Tabs className="primary-tabs" defaultActiveKey="1">
				<TabPane tab="VISITANTES" key="1">
					<AccessVisitorsTab></AccessVisitorsTab>
				</TabPane>

				<TabPane tab="VISITANTES ACTIVOS" key="2">
					<AccessActiveVisitorsTab></AccessActiveVisitorsTab>
				</TabPane>

				<TabPane tab="VISITANTES PROGRAMADOS" key="3">
					<AccessScheduleVisitorsTab></AccessScheduleVisitorsTab>
				</TabPane>

				{/* <TabPane tab="RESIDENTES" key="3">
					<AccessResidentsTab></AccessResidentsTab>
				</TabPane> */}
			</Tabs>
			<div className="contBusqueda">
				<RangePicker
					defaultValue={[
						moment(new Date(), dateFormat),
						moment(new Date(), dateFormat),
					]}
					format={customFormat}
					locale={locale}
					style={{
						width: "270px",
					}}
					onCalendarChange={onChangeFecha}
				/>

				{/* <Button
					disabled={loadingBt}
					onClick={() => {
						buscar();
					}}
					size="large"
					type="primary"
					className={"btn-primary btBuscar"}
				>
					BUSCAR
				</Button> */}
			</div>
		</>
	);
};
