import logoSidebar from "../assets/integroImages/integroLogo.svg";

export const IntegroTheme = {
  //Definir los estilos por defecto de adminTopia...
  themeId: "SidebarAdonis",
  sidebarlogo: logoSidebar,
  sidebarBackground: "#252525",
  items: [
    {
      itemName: "homeItem",
      to: "/",
      image: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/777d06cf-3907-406d-bd25-4485d7675e36.png',
      activeImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/2be3fccc-c57f-47f6-8d40-73f20cb9bba0.png',
      backgroundColor: "#252525",
      activeBackgroundColor: "#fff",
      color: "#fff",
      activeColor: "#000",
      name: "INICIO",
    },
    {
      itemName: "accessItem",
      to: "/accesos",
      image: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/5c8cc0e6-c316-4576-98b1-6e3bdf39428f.png',
      activeImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/b2028a9b-2ab0-42cd-b00e-19ca6ca7a27b.png',
      backgroundColor: "#252525",
      activeBackgroundColor: "#fff",
      color: "#fff",
      activeColor: "#000",
      name: "ACCESOS",
    },
    {
      itemName: "ticketsItem",
      to: "/tickets",
      image: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/b7279390-2c44-4999-b421-cd1a1534eb05.png',
      activeImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/c32c8fa5-cc40-4f00-9866-cdd4a9e9bbec.png',
      backgroundColor: "#252525",
      activeBackgroundColor: "#fff",
      color: "#fff",
      activeColor: "#000",
      name: "TICKETS",
      submenu: "tickets",
    },
    {
      itemName: "checkListItem",
      to: "/checklist",
      image: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/7e7e5043-b930-4d5c-8a3d-0992da49e112.png',
      activeImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/4ffbb239-ea5e-4291-b148-5ea25ef5c220.png',
      backgroundColor: "#252525",
      color: "#fff",
      activeBackgroundColor: "#fff",
      activeColor: "#000",
      name: "CHECKLIST",
      submenu: "checklist",
    },
    {
      itemName: "messagesItem",
      to: "/noticias",
      image: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/851df99e-8beb-48f9-8757-decf4c1f6057.png',
      activeImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/8a6d63ec-fe5a-4fb0-b8ae-87efe1b365f1.png',
      backgroundColor: "#252525",
      color: "#fff",
      activeBackgroundColor: "#fff",
      activeColor: "#000",
      name: "MENSAJERÍA",
      submenu: "messages",
    },
    {
      itemName: "settingsItem",
      image: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/55496153-fe29-494f-af7c-fa5c10fdbbc4.png',
      activeImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/icons/integro/eb020b02-7e2d-49f5-9113-0178862f43df.png',
      backgroundColor: "#252525",
      color: "#fff",
      activeBackgroundColor: "#fff",
      activeColor: "#000",
      submenu: "generalSettings",
      name: "AJUSTES",
    },
  ],
  submenuStyle: {
    backgroundColor: "#363636",
    titleColor: "#fff",
    color: "#ffff",
  },
  navbar: {
    dropdownProfile: {
      activeBackgroundColor: '#252525',
      activeColor: "#fff",
      color: "#252525",
      backgroundColor: '#fff',
    }
  }
};
