import {
	collection,
	query,
	where,
	getDocs,
	doc,
	getDoc,
	orderBy,
} from "firebase/firestore";
import { FirebaseDB, FirebaseApp } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import { getColumnSearchProps } from "../utils/tables";
import {
	ref,
	uploadBytes,
	getDownloadURL,
	listAll,
	list,
} from "firebase/storage";
import { getStorage } from "firebase/storage";
import { v4 } from "uuid";
import axios from 'axios';

const storage = getStorage(FirebaseApp);

export const getNewsHelper = async (
	residential_id,
	fechaInicio,
	fechaFinal
) => {
	const collectionRef = collection(FirebaseDB, `/banner`);

	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(23, 59, 59, 0);

	const queryMessages = query(
		collectionRef,
		where("residentials", "array-contains-any", ["todos", residential_id]),
		where("created_at", ">=", new Date(dateStart)),
		where("created_at", "<=", new Date(dateEnd)),
		orderBy("created_at", "desc")
	);

	const querySnapshot = await getDocs(queryMessages);
	const list = await newsBody(querySnapshot);
	return list;
};

export const getNewsByIdHelper = async (newsId) => {
	const collectionRef = doc(FirebaseDB, `/banner/` + newsId);

	const docSnap = await getDoc(collectionRef);
	const data = await newsBodySingle(docSnap);
	return data;
};

/* export const handleNewsImageUpload = async ({ file, onSuccess, onError }) => {
	const isSupported = file.type === "image/png" || file.type === "image/jpeg";

	if (!isSupported) {
		onError("Only PNG and JPEG images are supported!");
		return;
	}

	try {
		// Create a canvas element to resize the image
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const img = new Image();
		img.src = URL.createObjectURL(file);
		await new Promise((resolve) => (img.onload = resolve));
		canvas.width = 230;
		canvas.height = 295;
		ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

		// Convert the canvas to a blob with the correct format and quality
		canvas.toBlob(
			async (blob) => {
				const fileName = file.name.replace(/\.[^/.]+$/, ""); // Remove file extension
				const storageRef = storage.ref();
				const imagesRef = storageRef.child("banner/images");
				const imageRef = imagesRef.child(
					`${fileName}_${Date.now()}.${blob.type.split("/")[1]}`
				);

				// Upload the resized image to Firebase Storage
				const snapshot = await imageRef.put(blob);
				const downloadUrl = await snapshot.ref.getDownloadURL();
				onSuccess(downloadUrl);
			},
			file.type,
			0.8
		);
	} catch (error) {
		console.error(error);
		onError("An error occurred while uploading the image!");
	}
}; */

export const handleNewsImageUpload = async (imageUpload, setImageUrls) => {
	if (imageUpload == null) return;
	const imageRef = ref(storage, `banner/images/${imageUpload.name + v4()}`);
	const snapshot = await uploadBytes(imageRef, imageUpload);
	const url = await getDownloadURL(snapshot.ref);
	setImageUrls(url);
	return url;
};

const newsBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = {
			id: documment.id,
			active: data.active,
			action: data.action,
			color: "#" + data.color,
			created_at: moment(data.created_at.toDate()).format("Y-MM-DD"),
			created_time: moment(data.created_at.toDate()).format("HH:mm"),
			date: moment(data.date.toDate()).format("Y-MM-DD"),
			date_time: moment(data.date.toDate()).format("HH:mm"),
			subtitle: data.description,
			iconURL: data.icon,
			imgURL: data.img,
			inscription: data.inscription,
			description: data.large_description,
			place: data.place,
			residentials: data.residentials,
			title: data.title,
			type: data.type,
			url: data.url,
			promoter: data.who_invites,
		};

		list.push(itemData);
	});

	return list;
};

const newsBodySingle = async (docSnapshot) => {
	let data = docSnapshot.data();
	let itemData = {
		id: docSnapshot.id,
		active: data.active,
		action: data.action,
		color: "#" + data.color,
		created_at: moment(data.created_at.toDate()).format("Y-MM-DD"),
		created_time: moment(data.created_at.toDate()).format("HH:mm"),
		date: moment(data.date.toDate()).format("Y-MM-DD"),
		date_time: moment(data.date.toDate()).format("HH:mm"),
		subtitle: data.description,
		iconURL: data.icon,
		imgURL: data.img,
		inscription: data.inscription,
		description: data.large_description,
		place: data.place,
		residentials: data.residentials,
		title: data.title,
		type: data.type,
		url: data.url,
		promoter: data.who_invites,
	};
	return itemData;
};

/**
 * Get the full list of news/events/notifications
 * @param  {string} residentialId
 * @param  {date} from
 * @param  {date} to
 * @return {object}
 */
export const getAllNewsList = async(residentialId, from, to) => {
	// const URL_DEV = "https://domo.topiaapp.com/api/ticket";
	const URL_DEV = "https://domo.topiaapp.com/api/news/all";
	const params = {
		residential_id: residentialId,
		start_date: from,
		end_date: to
	}
	try {
		const { data } = await axios.get(URL_DEV, { params });
		return {
			success: true,
			...data,
		}
	} catch (errorResponse) {
		return {
			success: false,
			...errorResponse.response
		}
	}
}

/**
 * Get specific news/events/notifications by id
 * @param  {string} residentialId
 * @param  {date} from
 * @param  {date} to
 * @return {object}
 */
export const getNewsById = async(id) => {
	// const URL_DEV = "https://domo.topiaapp.com/api/ticket";
	const URL_DEV = `https://domo.topiaapp.com/api/news/${id}`;
	try {
		const { data } = await axios.get(URL_DEV);
		return {
			success: true,
			...data,
		}
	} catch (errorResponse) {
		return {
			success: false,
			...errorResponse.response
		}
	}
}

export const addNewNewsEntry = async (formData) => {
	const URL_DEV = `https://domo.topiaapp.com/api/news`;
	try {

		const { data } = axios.post(URL_DEV, formData, {
		 	headers: {
		 		'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
		 	}
		});
		return {
			success: true,
			...data,
		}

	} catch (errorResponse) {
		return {
			success: false,
			...errorResponse.response
		}
	}
}

export const updateNewsEntry = async (id, formData) => {
	const URL_DEV = `https://domo.topiaapp.com/api/news/${id}`;
	try {
		const { data } = axios.put(URL_DEV, formData, {
		 	headers: {
		 		'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
		 	}
		});
		return {
			success: true,
			...data,
		}
	} catch (errorResponse) {
		return {
			success: false,
			...errorResponse.response
		}
	}
}