import { Row, Col, Button } from 'antd'
import CustomTimePicker from '../../../../../../../../../UI/CustomTimePicker/CustomTimePicker'
import { ArrowLeft } from '../../../../../../../assets'

//Subcomponente de la lista de días del horario de una amenidad que contiene el bóton para habilitar ese día para el horario y establecer las horas de inicio y fin
const DayItem = ({ Form, form, field, handleChangeDay, handleChangeTimeDay }) => {

    const item = form?.getFieldValue('days')[field.name]

    return (
        <Row align='middle' style={{ gap: 0 }} gutter={16}>
            <Col span={6}>
                <Button onClick={() => handleChangeDay && handleChangeDay({ field })} className={`btn-standard-white`} style={{ borderRadius: 12, width: '100%', backgroundColor: item.status === 'checked' ? '#10b48c' : '#fff', border: '1px solid #16A07F' }}>
                    <span style={{ color: item.status === 'checked' ? '#ffffff' : '#16A07F', }}>
                        {item.fullDay}
                    </span>
                </Button>
            </Col>
            <Col span={4}>
                <ArrowLeft width={'100%'} />
            </Col>
            <Form.Item noStyle shouldUpdate>
                {
                    ({ getFieldValue }) => {
                        const status = getFieldValue('days')[field.name]?.status

                        return (
                            <>
                                <Col span={6}>
                                    <Form.Item rules={[{
                                        validator: async (_, value) => {
                                            if ((value && status === 'checked') || status !== 'checked') { //Valida el item si el día está activo
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error('El campo es necesario'))
                                        }
                                    }]} label={<small>Desde</small>} name={[field.name, 'time_begin']}>
                                        <CustomTimePicker allowClear={false} onChange={(value) => handleChangeTimeDay({ field, name: 'time_begin', value })} placeholder={'Inicio'} disabled={status !== 'checked'} />
                                    </Form.Item>

                                </Col>
                                <Col span={6}>
                                    <Form.Item rules={[{
                                        validator: async (_, value) => {
                                            if ((value && status === 'checked') || status !== 'checked') {//Valida el item si el día está activo
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error('El campo es necesario'))
                                        }
                                    }]} label={<small>Hasta</small>} name={[field.name, 'time_end']}>
                                        <CustomTimePicker allowClear={false} onChange={(value) => handleChangeTimeDay({ field, name: 'time_end', value })} placeholder={'Fin'} disabled={status !== 'checked'} />
                                    </Form.Item>
                                </Col>
                            </>
                        )
                    }
                }
            </Form.Item>
        </Row>
    )
}

export default DayItem
