import { Col, Row } from 'antd';
import { ContainerTabs } from './components/ContainerTabs';


export const ReservationsRequestPage = () => {




    return (
        <>
    
          <div className="wrapper-page">
            <h2 className='title-page'>
              Reserva de amenidades
            </h2>
    
              <Row>
    
                <Col span={24}>
                  <ContainerTabs></ContainerTabs>
                </Col>
    
    
              </Row>
          </div>
        </>
        
    )
}


