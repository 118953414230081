import { CloseCircleOutlined } from '@ant-design/icons'
import { Input, Button } from "antd";
import searchIcon from "./assets/searchIcon.svg";
import './styles.css'

export const CustomSearchInputFilter = ({
  dataIndex,
  selectedKeys,
  setSelectedKeys,
  clearFilters,
  handleSearch,
  handleReset,
  confirm,
  placeholder,
}) => {
  return (
    <div
      className="custom-table__filter-container"
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        prefix={
          <Button
            className="custom-table__filter__icon"
            onClick={handleSearch(confirm)}
            icon={<img src={searchIcon} width={14} height={14} alt='search-icon' />}
            size="small"
          />
        }
        suffix={
          <Button
            className="custom-table__filter__icon"
            onClick={() => clearFilters && handleReset(confirm, clearFilters)}
            icon={<CloseCircleOutlined />}
            size="small"
          />
        }
        className="custom-table__filter-search"
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
          handleSearch(confirm)(e);
        }}
        onPressEnter={handleSearch(confirm)}
      />
    </div>
  );
};