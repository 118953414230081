import { configureStore } from '@reduxjs/toolkit';
import { accessSlice } from './access';
import { alertSlice } from './alerts';
import { announcementSlice } from './announcement';
import { authSlice } from './auth';
import { paymentsSlice } from './payments/paymentsSlice';
import { requestSlice } from './requests';
import { submenuSlice } from './submenu/submenuSlice';
import { usersSlice } from './users/usersSlice';
import { surveysSlice } from './surveys';
import  {zonasSlice}  from './zonas';
import  {nomenclaturasSlice}  from './nomenclaturas';
import { adminCobrosSlice } from './admincobros/adminCobrosSlice';
import { sidebarSlice } from './sidebar/sidebarSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    alerts: alertSlice.reducer,
    requests: requestSlice.reducer,
    access: accessSlice.reducer,
    payments: paymentsSlice.reducer,
    users: usersSlice.reducer,
    submenu: submenuSlice.reducer,
    announcement: announcementSlice.reducer,
    surveys: surveysSlice.reducer,
    zonas: zonasSlice.reducer,
    adminCobros: adminCobrosSlice.reducer,
    nomenclaturas: nomenclaturasSlice.reducer,
    sidebarTheme: sidebarSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck : false
  })
});