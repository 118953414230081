import { Checkbox } from "antd";

export const CustomCheckbox = ({
  id,
  className,
  value,
  onChange,
  options,
  horizontal = false,
  group = true,
  children
}) => (
  <>
    {
      group ? <Checkbox.Group
        id={id}
        className={className || "custom-checkbox"
        }
        options={options}
        defaultValue={value}
        value={value}
        onChange={onChange}
        style={{ flexDirection: horizontal ? "row" : "column" }}
      /> : <Checkbox id={id} value={value} onChange={onChange} className="custom-single-checkbox">
        {children}
      </Checkbox>
    }
  </>
);
