import { Navigate } from "react-router-dom"
import { BookingsContextProvider } from "./context/BookingsContext"
import useCurrentLocation from "../../hooks/useCurrentLocation"
import BookingRequest from "./components/BookingRequest/BookingRequest"
import MainBookings from "./components/MainBookings/MainBookings"
import './styles.css'

//Component que renderiza la página de la reservas de Tuscania, y funciona además como un "enrutador" para mostrar diferentes páginas en el mismo componente compartiendo un contexto
const BookingsPage = () => {

  const { param } = useCurrentLocation()

  //Definir el objeto de páginas que se pueden renderizar dentro de este componente
  const pages = {
    request: <BookingRequest />
  }

  //Función que permite renderizar una página en específico a partir del id del parámetro, si este no existe redirige a la página principal del flujo
  const renderComponent = () => {
    if (param?.bookingId) {
      if (pages[param?.bookingId]) return pages[param.bookingId]
      return <Navigate to={'/settings/bookings'} />
    }
    return <MainBookings />
  }

  return (
    <BookingsContextProvider>
      {renderComponent()}
    </BookingsContextProvider>
  )
}

export default BookingsPage