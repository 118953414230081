import {
	collection,
	query,
	where,
	getDocs,
	doc,
	limit,
	orderBy,
	arrayUnion,
} from "firebase/firestore";
import { FirebaseDB, FirebaseStorage } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import {
	getQueryDocByCollection,
	getReferenceData,
	serializeUserData,
} from "./globalHelper";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
	getDocByCollectionAnId,
	onCreateOrUpdate,
	onUpdateDocWithRef,
	onUpdateDocWithRefInfo,
} from "./globalHelper";

export const getRolesHelper = async () => {
	const collectionRef = collection(FirebaseDB, `/committee_roles`);

	/* const queryMessages = query(collectionRef); */

	const querySnapshot = await getDocs(collectionRef);
	const list = await rolesBody(querySnapshot);

	return list;
};

const rolesBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (document) => {
		let data = document.data();
		let itemData = {
			id: document.id,
			name: data.name,
			description: data.description ? data.description : "N/A",
		};
		list.push(itemData);
	});

	return list;
};
