import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const zonasSlice = createSlice({
    name: 'zonas',
    initialState: {
        zonas: {
            data: [],
            user: [],
            active: null
        },
        errorMessage: null
    },
    reducers: {
        setZonasData: ( state, { payload } ) => {
            state.zonas.data = payload;
        },
        setEmpleadosData: ( state, { payload } ) => {
            state.zonas.user = payload;
        },
        setActiveZona: (state, { payload })=>{
            state.zonas.active = payload
        },
        createZona: ( state, { payload } ) => {
            var zonasList = state.zonas.data

           
            var indexZona = zonasList.findIndex((item) => item.id === payload.id)
            if(indexZona !== -1){
                zonasList[indexZona] = payload
                state.zonas.data = zonasList
            }else{
                state.zonas.data.unshift(payload);
            }

            toast.success("Datos guardados");
        },
    }
});


export const { 
    setZonasData,
    setEmpleadosData,
    setActiveZona,
    createZona,
} = zonasSlice.actions;