import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ImportantPage } from "../pages/important/ImportantPage";
import { AccessPage } from "../pages/access/AccessPage";
import { Sidebar } from '../components/Sidebar/Sidebar'
import { NavBar } from "../components/NavBar";
import { PaymentsPage } from "../pages/payments/PaymentsPage";
import { UsersPage } from "../pages/users/UsersPage";
import { Layout } from "antd";
import { Submenu } from "../components/Submenu";
import { AlertsReceivedPage } from "../pages/alertsReceived/AlertsReceivedPage";
import { ReservationsRequestPage } from "../pages/reservationsRequest/ReservationsRequestPage";
import { AnnouncementPage } from "../pages/announcement/AnnouncementPage";
import { SurveyPage } from "../pages/Surveys/SurveyPage";
import { AdminCobrosPage } from "../pages/adminCobros/AdminCobrosPage";
import { useDispatch, useSelector } from "react-redux";
import { ZonasReceivedPage } from "../pages/zonas/ZonasReceivedPage";
import { ConfiguracionInicialPage } from "../pages/configuracionInicial/ConfiguracionInicialPage";
import { NomenclaturaPage } from "../pages/configuracionInicial/nomenclatura/NomenclaturaPage";
import { AprobacionUsersPage } from "../pages/configuracionInicial/aprobacion/AprobacionUsersPage";
import UserProfilePage from "../pages/userProfilePage/UserProfilePage";
import PackagesPage from "../pages/packages/PackagesPage";
import LostObjectsPage from "../pages/lostObjects/LostObjectsPage";
import NewsPage from "../pages/news/NewsPage";
import ReportLiquidacionPage from "../pages/reporteLiquidacion/ReportLiquidacionPage";
import PerfilLiquidacionPage from "../pages/perfilLiquidacion/PerfilLiquidacionPage";
import DeactivatedUsersPage from "../pages/deactivatedUsers/DeactivatedUsersPage";
import UsuariosMoraPage from "../pages/usuariosMora/UsuariosMoraPage";
import AdminCobrosTuscaniaPage from "../pages/adminCobrosTuscania/AdminCobrosTuscaniaPage";
import { SpecificPaymentTab } from "../pages/payments/components/SpecificPaymentTab";
import AdminAmenidadesPage from "../pages/adminAmenidades/AdminAmenidadesPage";
import MembersBoardPage from "../pages/membersBoard/MembersBoardPage";
import SecuritytAgentsPage from "../pages/securityAgents/SecuritytAgentsPage";
import SurveillanceShiftsPage from "../pages/surveillanceShifts/SurveillanceShiftsPage";
import CreateNewsPage from "../pages/news/components/CreateNewsPage";
import { EditNewsPagePrincipal } from "../pages/news/editComponents/EditNewsPagePrincipal";
import { setSidebarTheme } from "../../store/sidebar/thunks";
import MonitoringContratorstPage from "../pages/monitoringContrators/MonitoringContratorstPage";
import BookingsPage from "../pages/bookings/BookingsPage";
import ManagmentAmenitiesPage from "../pages/managmentAmenities/ManagmentAmenitiesPage";

const { Header, Sider, Content } = Layout;

export const DashboardRouter = () => {
	const [collapsed, setCollapsed] = useState(true);
	const { committee } = useSelector((state) => state.auth);
	const theme = useSelector(state => state.sidebarTheme)

	return (
		<Layout className="wrapper-container alto">
			<div className="sidebar-wrapper">
				<Sider
					className="sidebar"
					trigger={null}
					collapsible
					collapsed={collapsed}
					style={{ height: "100vh", background: theme?.sidebarBackground }}
				>
					<Sidebar
						rol={
							typeof committee.residentialData === "undefined"
								? ""
								: committee.residentialData.entity_type.path
						}
					></Sidebar>
				</Sider>
			</div>
			<Submenu></Submenu>
			<Layout className="site-layout">
				<Header style={{ padding: 0 }}>
					<NavBar />
				</Header>

				<Content style={{ minHeight: 280 }}>
					<Routes>
						<Route
							path="configuracion-inicial"
							element={<ConfiguracionInicialPage />}
						/>
						<Route
							path="configuracion-inicial/nomenclatura"
							element={<NomenclaturaPage />}
						/>
						<Route
							path="configuracion-inicial/aprobacion-usuarios"
							element={<AprobacionUsersPage />}
						/>
						<Route path="destacados" element={<ImportantPage />} />
						<Route path="accesos" element={<AccessPage />} />
						<Route path="pagos" element={<ReportLiquidacionPage />} />
						{/* <Route path="pagos/reporte" element={<SpecificPaymentTab />} /> */}
						{/* <Route
							path="reporte-liquidaciones"
							element={<ReportLiquidacionPage />}
						/> */}
						<Route
							path="reporte-liquidaciones/:date"
							element={<PerfilLiquidacionPage />}
						/>
						<Route path="usuarios" element={<UsersPage />} />
						<Route
							path="usuarios-desactivados"
							element={<DeactivatedUsersPage />}
						/>
						<Route path="reporte-actividad/:id" element={<UserProfilePage />} />
						<Route path="alertas-recibidas" element={<AlertsReceivedPage />} />
						<Route
							path="zonas"
							element={
								<ZonasReceivedPage
									residentialData={committee.residentialData}
								/>
							}
						/>
						<Route
							path="solicitudes-de-reserva"
							element={<ReservationsRequestPage />}
						/>
						<Route path="convocatorias" element={<AnnouncementPage />} />
						<Route path="encuestas" element={<SurveyPage />} />
						{/* <Route path="objetos-perdidos" element={<LostObjectsPage />} /> */}
						<Route path="noticias" element={<NewsPage />} />
						<Route path="crear-noticia" element={<CreateNewsPage />} />
						<Route path="noticias/:id" element={<EditNewsPagePrincipal />} />
						{/* <Route path="paquetes-en-recepcion" element={<PackagesPage />} /> */}
						<Route path="admin-cobros" element={<AdminCobrosPage />} />
						<Route
							path="admin-cobros-tuscania"
							element={<AdminCobrosTuscaniaPage />}
						/>
						<Route path="usuarios-en-mora" element={<UsuariosMoraPage />} />
						<Route path="/*" element={<Navigate to="/destacados" />} />
						<Route path="/admin-amenidades" element={<AdminAmenidadesPage />} />
						<Route path="/junta-directiva" element={<MembersBoardPage />} />
						<Route
							path="/agentes-seguridad"
							element={<SecuritytAgentsPage />}
						/>
						<Route
							path="/turnos-vigilancia"
							element={<SurveillanceShiftsPage />}
						/>

						{/**ROUTES FOR MONITORING CONTRATORS */}
						<Route path="/access/monitoring-contrators" element={<MonitoringContratorstPage />} />
						<Route path="/access/monitoring-contrators/:contratorId/:accessId" element={<MonitoringContratorstPage />} />
						<Route path="/access/monitoring-contrators/:contratorId/:accessId/:historyPage/:historyId" element={<MonitoringContratorstPage />} />

						{/**ROUTER FOR BOOKINGS (TUSCANIA) */}
						<Route path="/bookings" element={<BookingsPage />} />
						<Route path="/bookings/:bookingId" element={<BookingsPage />} />
						<Route path="/bookings/:bookingId/:subId" element={<BookingsPage />} />

						{/**ROUTER FOR MANAGMENT AMENITIES (TUSCANIA) */}
						<Route path="/settings/managment-amenities" element={<ManagmentAmenitiesPage />} />
						<Route path="/settings/managment-amenities/:amenityId" element={<ManagmentAmenitiesPage />} />
						<Route path="/settings/managment-amenities/:amenityId/:subId" element={<ManagmentAmenitiesPage />} />

					</Routes>
				</Content>
			</Layout>
		</Layout>
	);
};
