import React from "react";
import {
	Table,
} from "antd";
import { useState, useEffect } from "react";
import "./styles.css";

export const ShiftsTable = ({ turns }) => {
	const [turnsTable, setTurnsTable] = useState([]);
	const [shouldUpdateTable, setShouldUpdateTable] = useState(false);

	const handleButtonClick = () => {
		setShouldUpdateTable(!shouldUpdateTable);
	};

	useEffect(() => {
		setTurnsTable(turns);
		setTimeout(() => {
			handleButtonClick();
		}, 1000);
	}, [turns]);

	/* useEffect(() => {
		getProfiles(turnsAgents);
	}, [turnsAgents]); */

	/* const mapSecurityList = (array) => {
		const names = array.list_security.map(async (item) => {
			const data = await getProfiles(item);
			const name = data.name + " " + data.lastname;
			return <span>{name}</span>;
		});

		Promise.all(names).then((data) => {
			let result = data;
			return result;
		});
	}; */

	const mapProfiles = (profiles) => {
		const map = profiles.map((item, index) => {
			return (
				<p key={index} className="turn-names">
					{item.name + " " + item.lastname}
				</p>
			);
		});
		return map;
	};

	const constructArray = (array, word) => {
		let render = <></>;

		if (array.days.includes(word)) {
			render = (
				<>
					<h1 className="turn-title">{array.name}</h1>
					<span className="turn-time">
						{getTimeDifference(array.time_begin, array.time_end)}
					</span>
					<br />

					{mapProfiles(array.profileData)}
				</>
			);
		}

		return render;
	};

	const getTimeDifference = (start, end) => {
		const startParts = start.split(":");
		const endParts = end.split(":");

		const startDate = new Date(0, 0, 0, startParts[0], startParts[1]);
		const endDate = new Date(0, 0, 0, endParts[0], endParts[1]);

		let diff = endDate.getTime() - startDate.getTime();
		if (diff < 0) {
			diff += 24 * 60 * 60 * 1000;
		}

		const hours = Math.floor(diff / (60 * 60 * 1000));
		const minutes = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));

		const startFormatted = formatDate(startDate);
		const endFormatted = formatDate(endDate);

		return `${startFormatted} - ${endFormatted}`;
	};
	function formatDate(date) {
		let hours = date.getHours();
		let minutes = date.getMinutes();
		let suffix = hours >= 12 ? "pm" : "am";

		hours = hours % 12;
		hours = hours ? hours : 12;
		minutes = minutes < 10 ? "0" + minutes : minutes;

		return `${hours}:${minutes} ${suffix}`;
	}

	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};
	const columns = [
		{
			title: "Lunes",
			dataIndex: "lun",
			key: "lun",
			width: "14.28%",
			align: "center",
			onCell: (record) => ({
				className: "table-name-cell-invited-no-padding",
			}),
			render: (text, record) => <>{constructArray(record, "Lu")}</>,
		},
		{
			title: "Martes",
			dataIndex: "mar",
			key: "mar",
			width: "14.28%",
			align: "center",
			onCell: (record) => ({
				className: "table-name-cell-invited-no-padding",
			}),
			render: (text, record) => <>{constructArray(record, "Ma")}</>,
		},
		{
			title: "Miércoles",
			dataIndex: "mie",
			key: "mie",
			width: "14.28%",
			align: "center",
			onCell: (record) => ({
				className: "table-name-cell-invited-no-padding",
			}),
			render: (text, record) => <>{constructArray(record, "Mi")}</>,
		},
		{
			title: "Jueves",
			dataIndex: "jue",
			key: "jue",
			width: "14.28%",
			align: "center",
			onCell: (record) => ({
				className: "table-name-cell-invited-no-padding",
			}),
			render: (text, record) => <>{constructArray(record, "Ju")}</>,
		},
		{
			title: "Viernes",
			dataIndex: "vie",
			key: "vie",
			width: "14.28%",
			align: "center",
			onCell: (record) => ({
				className: "table-name-cell-invited-no-padding",
			}),
			render: (text, record) => <>{constructArray(record, "Vi")}</>,
		},
		{
			title: "Sábado",
			dataIndex: "sab",
			key: "sab",
			width: "14.28%",
			align: "center",
			onCell: (record) => ({
				className: "table-name-cell-invited-no-padding",
			}),
			render: (text, record) => <>{constructArray(record, "Sa")}</>,
		},
		{
			title: "Domingo",
			dataIndex: "dom",
			key: "dom",
			width: "14.28%",
			align: "center",
			onCell: (record) => ({
				className: "table-name-cell-invited-no-padding",
			}),
			render: (text, record) => <>{constructArray(record, "Do")}</>,
		},
	];
	return (
		<>
			<Table
				className="primary-table"
				pagination={{ defaultPageSize: 3, simple: true }}
				columns={columns}
				dataSource={turnsTable}
				loading="true"
				rowKey="id"
				key={shouldUpdateTable}
				bordered={true}
				locale={locale}
			/>
		</>
	);
};
