import { Row, Col, Image } from 'antd'
import { bookingRequestStatuses } from '../../../../../constants/statuses'

//Componente que se renderiza el estado de la solicitud de la reserva, si está aprobada, si está en revisión, si falta comprobante de pago, etc
//Además si existe un comprobante de pago mostrará la miniatura junto con la fecha del mismo
const BookingStatus = ({ statusCode, paymentImage, paymentDate }) => (
  <Row className='status-container'>
    <Col span={12}>
      <small>Estado</small>
      <div className='request-status-container' style={{ backgroundColor: bookingRequestStatuses[statusCode].backgroundColor }}>
        {bookingRequestStatuses[statusCode].icon} <span style={{ color: bookingRequestStatuses[statusCode].color }}>{bookingRequestStatuses[statusCode].text}</span>
      </div>
    </Col>
    <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }} span={12}>
      <small style={{ marginRight: '16px', marginBottom: '16px' }}>Comprobante de pago</small>
      {
        statusCode === 'awaitingPayment' || statusCode === 'awaitingConfirmation' || (!paymentImage && !paymentDate)
          ? <div style={{ borderRadius: '8px', border: '1px solid #FF4E4E', padding: '4px 12px', color: '#FF4E4E' }}>
              No enviado
            </div>
          : <Row className='request-status-comprobant'>
              <Image src={paymentImage} />
              <Col>
                <span>{paymentDate}</span>
              </Col>
            </Row>
      }
    </Col>
  </Row>
)

export default BookingStatus
