import { getTheme } from "../../topia/components/Sidebar/services/sidebar/GET/getTheme";
import { setTheme } from "./sidebarSlice";
//Este es el "custom hook" de redux por lo que aquí se conectan las funciones de redux y los servicios

//Esta función debería llamarse cuando se obtiene los datos del login en caso de que no existan en el localStorage
export const setSidebarTheme = ({idTheme, login}) => {
  return async (dispatch) => {
    const { data, success } = await getTheme({idTheme, login});
    //verifyResponse(data,status);
    dispatch(setTheme({data}));
    return {success};
  };
};
