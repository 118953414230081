import { Button, Form, Row } from "antd"
import { CustomInput } from "../../../../../../UI/CustomInput"
import CustomModal from "../../../../../../UI/CustomModal"

//Componente que renderiza un modal para mostrar un formulario y escribir un comentario (opcional) para rechazar una solicitud de reserva
const RejectedBookingModal = ({ isOpenModal, onSubmit, handleOpenCloseModal, loading }) => {
    const [form] = Form.useForm()
    return (
        <CustomModal destroyOnClose={true} title={'CANCELAR RESERVACIÓN'} className="custom-modal-variant-2" isOpenModal={isOpenModal} onCancel={() => handleOpenCloseModal({ name: 'rejectedModal', status: false })}>
            <Form layout="vertical" form={form} onFinish={(values) => onSubmit({ values, name: 'canceled' })} className="confirm-layout-content">
                <Form.Item label='COMENTARIOS' name={'comment'}>
                    <CustomInput type="AREA" autoSize={{ minRows: 4 }} />
                </Form.Item>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 16 }}>
                    <small style={{ textAlign: 'center', fontSize: 12, color:'#252525' }}>¿Desea cancelar esta reservación?</small>
                    <Row style={{ gap: 16 }} justify='center'>
                        <Button loading={loading} htmlType="submit" className='btn-just-icon' type="text" style={{ color: '#FF601C' }}>CANCELAR RESERVA</Button>
                        <Button loading={loading} onClick={() => handleOpenCloseModal({ name: 'rejectedModal', status: false })} className='btn-standard-green' style={{ width: '152px' }} shape='round'>REGRESAR</Button>
                    </Row>
                </div>
            </Form>
        </CustomModal>
    )
}

export default RejectedBookingModal
