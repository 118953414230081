import { axiosCreate } from "../../../../../config";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const BOOKING_PATH = 'reservation/tuscania/all'

//Endpoint de topia con el que se recupera toda la información de las reservas de una cuenta, para ser mostradas en una tablas
//donde se puede filtrar por el código de estado de la reserva, por amenidad (place_id), por el id del perfil del solicitante de la reserva y por un rango de fechas
//además de poder ordenar los datos por nombre de perfil, fecha de reservacion o hora de reservación e indicar el limite de reservas por petición
export const getAllBookings = async ({
    token,
    company_id,
    reservation_status_code,
    place_id,
    profile_id,
    date_begin,
    date_end,
    sort_order,
    sort_value,
    limit = 5,
    skip = 0,
}) => {

    try {
        const params = {
            company_id,
            reservation_status_code,
            place_id,
            profile_id,
            sort_order,
            sort_value,
            date_begin,
            date_end,
            limit,
            skip,
        }
        
        const res = await axiosCreate.get(`${BOOKING_PATH}`, {
            params,
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }

}