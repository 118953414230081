import { TimePicker } from "antd"
import clockIcon from './assets/clockIcon.svg'
import moment from "moment"
import './styles.css'

export const CustomTimePicker = ({ allowClear, disabled = false, className = "custom-time-picker", placeholder, suffixIcon, onChange, value, showSecond, disableBeforeDate, minuteStep }) => {

    const disabledTime = () => {
        const currentHour = disableBeforeDate.hour()
        const isToday = disableBeforeDate.isSame(moment(), 'day')
        const hours = []
        const minutes = (selectedHour) => {
            const disableMinutes = []
            if (selectedHour !== currentHour) return
            for (let i = 0; i <= disableBeforeDate.minute(); i++) {
                disableMinutes.push(i)
            }
            return disableMinutes
        }

        if (!isToday) return

        for (let i = 0; i < currentHour; i++) {
            hours.push(i)
        }

        return {
            disabledHours: () => hours,
            disabledMinutes: (selectedHour) => minutes(selectedHour)
        }
    }

    return (
        <TimePicker
            allowClear={allowClear}
            minuteStep={minuteStep}
            showNow={false}
            format={'hh:mm a'}
            disabledTime={disableBeforeDate && disabledTime}
            disabled={disabled}
            showSecond={showSecond}
            className={className}
            placeholder={placeholder || 'Seleccionar'}
            onChange={onChange}
            value={value}
            suffixIcon={<img src={suffixIcon ? suffixIcon : clockIcon} alt="clock-icon" />}
        />
    )
}

export default CustomTimePicker