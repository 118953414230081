import { Row, Col, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { CustomInput } from '../../../../../../../../UI/CustomInput'

//Subcomponente de la lista de rangos de invitados para completar la información, tomando el minimo y el maximo de invitados por rango y el precio por dicho rango
const RangeItem = ({ remove, form, Form, field, disableElement }) => (
  <div>
    <Row justify='space-between'>
      <span>Rango #{field.name + 1}</span>
      { //A partir del segundo rango que se añada aparecferá el ícono para eliminar el rango si se necesita
        field.name > 0 ? <Button disabled={disableElement} onClick={() => remove(field.name)} icon={<CloseOutlined />} style={{ border: 'none', backgroundColor: 'transparent', boxShadow: 'none' }} /> : <></>
      }
    </Row>
    <Row align='middle' className="amenity-range-item" gutter={24} style={{ marginTop: 8 }}>
      <Col span={6}>
        <Form.Item name={[field.name, 'min_range_invitee']} label={<small>DESDE</small>} >
          <CustomInput disabled={disableElement} type='NUMBER' />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name={[field.name, 'max_range_invitee']} label={<small>HASTA</small>} >
          <CustomInput disabled={disableElement} type='NUMBER' />
        </Form.Item>
      </Col>
      <Col span={4} style={{ padding: 0, textAlign: 'center' }}>
        <small>invitados</small>
      </Col>
      <Col span={8}>
        <Form.Item name={[field.name, 'price_range_invitee']} label={<small>PRECIO POR RANGO</small>} >
          <CustomInput disabled={disableElement} type='NUMBER' />
        </Form.Item>
      </Col>
    </Row>
  </div>
)

export default RangeItem
