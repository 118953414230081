import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import { Col, Row, DatePicker, Tabs, Space, Select, Empty } from "antd";
import { NewsContainer } from "./NewsContainer";
const { RangePicker } = DatePicker;

export const MiddleNewsPage = (props) => {
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [news, setNews] = useState([]);
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [activeNews, setActiveNews] = useState([]);
	const [scratchNews, setScratchNews] = useState([]);

	const { buscarFechas, data } = props;

	useEffect(() => {
		const separateNews = async (news) => {
			let activeArray = [];
			let scratchArray = [];
			let newsArray = [...news];
			const Snews = newsArray.map((item) => {
				if (item.published === true) {
					activeArray.push(item);
				}
				if (item.published === false) {
					scratchArray.push(item);
				}
			});
			setActiveNews([...activeArray]);
			setScratchNews([...scratchArray]);
		};

		separateNews(news).catch(console.error);
	}, [news]);

	useEffect(() => {
		setNews(data);
	}, [data]);

	const onChangeFecha = (date, dateString) => {
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		buscar(dateString[0], dateString[1]);
	};

	const buscar = async (start, end) => {
		var fechaI = new Date(moment(start, "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(end, "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
		buscarFechas(fechaIn, fechaFi);
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};
	const { TabPane } = Tabs;

	const onChangeSelector = (value, data) => {
		if (value === "all") {
			setNews(data);
		} else if (value === "event") {
			const newData = data.reduce(function (filtered, option) {
				if (option.type === "event") {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setNews(newData);
		} else if (value === "notification") {
			const newData = data.reduce(function (filtered, option) {
				if (option.type === "notification") {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setNews(newData);
		} else if (value === "news") {
			const newData = data.reduce(function (filtered, option) {
				if (option.type === "news") {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setNews(newData);
		}
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<Tabs className="primary-tabs" defaultActiveKey="1">
						<TabPane tab="PUBLICADAS" key="1">
							<Row>
								<Col span={24} style={{  }}>
									<Space>
										<Select
											placeholder="Todas las noticias"
											style={{ width: "261px" }}
											options={[
												{
													value: "all",
													label: "Todos",
												},
												{
													value: "event",
													label: "Eventos",
												},
												{
													value: "notification",
													label: "Notificaciones",
												},
												{
													value: "news",
													label: "Noticias",
												},
											]}
											onChange={(e) => {
												onChangeSelector(e, data);
											}}
										></Select>
										{/* <Select
											placeholder="Activas"
											style={{ width: "209px" }}
										></Select> */}
										<RangePicker
											defaultValue={[
												moment(new Date(), dateFormat).subtract(1, 'months'),
												moment(new Date(), dateFormat),
											]}
											format={customFormat}
											style={{
												width: "270px",
											}}
											onCalendarChange={onChangeFecha}
										/>
									</Space>
								</Col>
								<Col span={24} style={{  }}>
									<Row justify="center">
										{activeNews.length ? (
											<>
												<Col span={24}>
													{activeNews.map((item, index) => {
														return <NewsContainer data={item} key={index} />;
													})}
												</Col>
											</>
										) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
									</Row>
								</Col>
							</Row>
						</TabPane>
						<TabPane tab="EN BORRADOR" key="2">
							<Row>
								<Col span={24} style={{  }}>
									<Space wrap>
										<Select
											borderRadiusLG
											placeholder="Todas las noticias"
											style={{ width: "261px", borderRadius: "10px" }}
											options={[
												{
													value: "all",
													label: "Todos",
												},
												{
													value: "event",
													label: "Eventos",
												},
												{
													value: "info",
													label: "Notificaciones",
												},
												{
													value: "news",
													label: "Noticias",
												},
											]}
											onChange={(e) => {
												onChangeSelector(e, data);
											}}
										></Select>
										{/* <Select
											placeholder="Activas"
											style={{ width: "209px" }}
										></Select> */}
										<RangePicker
											defaultValue={[
												moment(new Date(), dateFormat).subtract(1, 'months'),
												moment(new Date(), dateFormat),
											]}
											format={customFormat}
											style={{
												width: "270px",
											}}
											onCalendarChange={onChangeFecha}
										/>
									</Space>
								</Col>
								<Col span={24} style={{  }}>
									<Row>
										{scratchNews ? (
											<>
												<Col span={24}>
													{scratchNews.map((item, index) => {
														return <NewsContainer data={item} key={index} />;
													})}
												</Col>
											</>
										) : null}
									</Row>
								</Col>
							</Row>
						</TabPane>
					</Tabs>
				</Col>
				{/* <Col span={4}>
					<Button
						style={{
							background: "#10b48c",
                            width: "250px"
						}}
						shape="round"
						size="large"
						icon={<IconButtonEdit />}
					>
						<span className="title" style={{ marginLeft: "10px" }}>
							NUEVA NOTICIA
						</span>
					</Button>
				</Col> */}
			</Row>
		</>
	);
};
