import React from 'react'
import { Col, Modal, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { columnsAllPaymentsTable } from '../../../../helpers/paymentsHelper';
import { setActivePayment } from '../../../../store/payments/paymentsSlice';
import { getPaymentsByTypePaid } from '../../../../store/payments/thunks';
import { shape08, shape09, shape14 } from '../../../../assets/images';

export const RequestPaidTab = () => {
    
    const [ paymentList, setPaymentList ] = useState([]);
    const dispatch = useDispatch();
    const { payments } = useSelector( state => state.payments );
    const columns = columnsAllPaymentsTable();

    const [ paymentDetailModal, setPaymentDetailModal ] = useState(false);
    const [ paymentActive, sePaymentActive ] = useState(null);
    
    
    useEffect(() => {
        dispatch( getPaymentsByTypePaid("Amenidad") );
    }, [ dispatch ]);


    useEffect( () => {
        sePaymentActive(payments.active);
        if(!payments.active){
            setPaymentDetailModal(false);
        }
    }, [ payments.active ]);
    
    useEffect(() => {
        
        let _data = payments.amenityPaid.map( (item, index) => {
            let data = { ...item }
            data.action = ( <img src={shape14} style={{ cursor: 'pointer',width:"24px"  }} alt="money-phone" onClick={() => handleStatusVisitorModal(true, index)} />);
            data.amount = `$${data.amount}`
            return data;
        });

        setPaymentList( _data );

    }, [ payments.amenityPaid ]);

    
    
    const handleStatusVisitorModal = (statusModal, index = null) => {
    
        if(index != null){
          dispatch( setActivePayment( payments.amenityPaid[index] ) );
        }else{
          dispatch( setActivePayment( null ) );
        }
    
        
        setTimeout(() => {
            setPaymentDetailModal(statusModal);
        }, 50);
    
    }



    return (
        <>          

            <Table className='primary-table' columns={columns} dataSource={paymentList} pagination={{ defaultPageSize: 7 }} />

            <Modal
            className='side-modal'
            title="Datos de pago"
            style={{ top: 0, right:0 }}
            visible={paymentDetailModal}
            onCancel={() => handleStatusVisitorModal(false)}
            footer = { null }
            >
           {
                (paymentActive) ?
                <>
                    
                    <Row className='section-modal'>
                        <Col span={16}>
                            <span className='title'>Usuario que pago</span>
                            <div className='user-data' style={{marginTop: "0px"}}>                            
                                <div className='user-info'>
                                <span className='user-name' style={{marginLeft: "0px"}}> 
                                    { paymentActive.profile?.name } 
                                    <img style={{ marginLeft: "10px" }} className="iconPerfil"  src={shape08} alt="credential-security-icon" />
                                    </span>
                                </div>
                            </div>

                        </Col>
                        <Col span={8}>
                            <div>
                            
                            { paymentActive.profile?.contact_phone &&
                                <span style={{ color: "#10B48C", position: "relative", top: "25px" }}>
                                    <img 
                                    style={{ marginLeft: "10px" }} 
                                    src={shape09}
                                    alt="phone-icon" />
                                    
                                    <span style={{ marginLeft: "5x" }}>
                                    {paymentActive.profile?.phone}
                                    </span>
                                </span>}

                            
                            </div>
                        </Col>
                        
                        <Col style={{marginTop: "30px"}}>                               
                            {paymentActive.payment_date && 
                                
                                <div className='alert-modal-header' style={{paddingLeft: "0px"}}>
                                    <span className='title'>Fecha de pago: &nbsp; { paymentActive.payment_date }</span>
                                </div>
                            }
                        </Col>
                    </Row>

                    
                    <Row className='section-modal'>
                        <Col span={24} style={{display: "flex"}}>

                            <div className='alert-modal-header'>
                                <span className='title'>Concepto:</span>
                            <p>{ paymentActive.concept }</p>
                            </div>

                        </Col>
                    </Row>
                    
                    <Row className='section-modal'>
                        <Col span={24} style={{display: "flex"}}>

                            <div className='alert-modal-header'>
                                <span className='title'>Monto:</span>
                            <p>${ paymentActive.amount }</p>
                            </div>

                        </Col>
                    </Row>
                    <Row className='section-modal'>
                        <Col span={24} style={{display: "flex"}}>

                            <div className='alert-modal-header'>
                                <span className='title'>Estado:</span>
                            <p>{ paymentActive.status }</p>
                            </div>

                        </Col>
                    </Row>
                </>
                :
                <>
                    <Row className='section-modal'>
                        <Col span={24} style={{ textTransform: "uppercase", textAlign: "center" }}>
                          <b>
                            Datos de perfil no encontrados
                          </b>
                        </Col>
                    </Row>

                    </>

            }
            
            </Modal>
        </>
    )
}
