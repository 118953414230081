import { Col, Row } from 'antd';
import { ContainerTabs } from './components/ContainerTabs';
import { Link, NavLink } from 'react-router-dom';
import { pulgarArriba, senal } from '../../../assets/images';


export const ConfiguracionInicialPage = () => {




    return (
        <>
    
          <div className="wrapper-page">
            <h2 className='title-page'>
            CONFIGURACIÓN INICIAL

            </h2>
            <div className='contOpciones'>
             <ul className='primary-card-list'>
                <NavLink to="/configuracion-inicial/nomenclatura">
                  <li className={"primary-card-card"}>
                    <img className='iconOpcion' src={senal} alt="signal-shape" />
                    <div>NOMENCLATURA DE LA RESIDENCIAL</div>
                      
                  </li>  
                </NavLink>

                <NavLink to="/configuracion-inicial/aprobacion-usuarios">
                  <li className={"primary-card-card"}>
                      <img className='iconOpcion' src={pulgarArriba} alt="like-shape" />
                      
                      <div>APROBACIÓN DE USUARIOS</div>
                  </li>  
                </NavLink>
              </ul>

            

            </div>

            


              <Row>
    
                <Col span={24}>
                  
                </Col>
    
    
              </Row>
          </div>
        </>
        
    )
}


