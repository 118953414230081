import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const nomenclaturasSlice = createSlice({
	name: "nomenclaturas",
	initialState: {
		nomenclaturas: {
			data: [],
			active: null,
			casas: [],
		},
		errorMessage: null,
	},
	reducers: {
		setNomenclaturaData: (state, { payload }) => {
			state.nomenclaturas.data = payload;
		},
		setCasasData: (state, { payload }) => {
			state.nomenclaturas.casas = payload;
		},
		setEmpleadosData: (state, { payload }) => {
			state.nomenclaturas.user = payload;
		},
		setActiveNomenclatura: (state, { payload }) => {
			state.nomenclaturas.active = payload;
		},
		updateResidencial: (state, { payload }) => {
			var casasList = state.nomenclaturas.casas;

			var indexNomenclatura = casasList.findIndex(
				(item) => item.id === payload.id
			);
			if (indexNomenclatura !== -1) {
				var getCasa = casasList[indexNomenclatura];
				getCasa.name = payload.name;
				getCasa.address = payload.address;
				getCasa.areaM2 = payload.areaM2;
				getCasa.areaV2 = payload.areaV2;
				getCasa.category = payload.category;
				getCasa.maintenanceFee = payload.maintenanceFee;
				getCasa.constructionFee = payload.constructionFee;
				getCasa.residenceFee = payload.residenceFee;
				getCasa.total = payload.total;
				getCasa.alcaldia = payload.alcaldia;
				getCasa.houseType = payload.houseType;

				casasList[indexNomenclatura] = getCasa;
				state.nomenclaturas.casas = casasList;
			} else {
				state.nomenclaturas.casas.unshift(payload);
			}

			toast.success("Datos guardados");
		},
		createNomenclatura: (state, { payload }) => {
			var nomenclaturasList = state.nomenclaturas.data;

			var indexNomenclatura = nomenclaturasList.findIndex(
				(item) => item.id === payload.id
			);
			if (indexNomenclatura !== -1) {
				nomenclaturasList[indexNomenclatura] = payload;
				state.nomenclaturas.data = nomenclaturasList;
			} else {
				state.nomenclaturas.data.unshift(payload);
			}

			toast.success("Datos guardados");
		},
	},
});

export const {
	setNomenclaturaData,
	setCasasData,
	setEmpleadosData,
	setActiveNomenclatura,
	createNomenclatura,
	updateResidencial,
} = nomenclaturasSlice.actions;
