import { Col, Row, Layout } from "antd";
import { ContainerTabs } from "./components/ContainerTabs";
import { Content, Footer } from "antd/lib/layout/layout";
import { NomenclaturaFooter } from "./components/NomenclaturaFooter";

export const NomenclaturaPage = ({ residentialData }) => {
	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page noPaddingRight">
						<Row>
							<Col span={17}>
								<h2 className="title-page">NOMENCLATURA RESIDENCIAL</h2>

								<Row>
									<Col span={24}>
										<ContainerTabs></ContainerTabs>
									</Col>
								</Row>
							</Col>
							<Col span={1}></Col>
							<Col span={6}>
								<div className="leyendaRight">
									<div className="bienvenido">BIENVENIDO A </div>
									<div className="topia">TOPIA RESIDENCIAL</div>

									<div className="textoLeyenda">
										Para iniciar la implementación de los servicios Topia, es
										necesario contar con la nomenclatura de su residencial, para
										garantizará el proceso de registro de cada residente.
									</div>

									<div className="tituloLeyenda">Que es una zona?</div>
									<div className="textoLeyenda">
										Una zona puede ser una área, una calle, una senda, un pasaje
										o un grupo especifico de residecias.
									</div>

									<div className="tituloLeyenda">Alcance</div>
									<div className="textoLeyenda">
										La sumatoria de casas por zona debe ser el mismo que el
										número total de casas de la residencial.
									</div>

									<div className="tituloLeyenda">
										¿Tengo que agregar todas las zones de una vez?
									</div>

									<div className="textoLeyenda">
										Puedes ir guardando cada zona que agreges y al finalizar dar
										al boton "Finalizar".
									</div>

									<div className="tituloLeyenda">¿Y luego que sigue?</div>

									<div className="textoLeyenda">
										Una vez ingresadas todas las zonas de la residencial, se
										procederá al envío de mensajes MSN a cada usuario
										residencial, con el enlace web para que completen su
										formulario de registro.
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</Content>
				<Footer
					style={{
						textAlign: "center",
						backgroundColor: "#232C4A",
						height: "50%",
						paddingBottom: "0px",
					}}
				>
					<NomenclaturaFooter />
				</Footer>
			</Layout>
		</>
	);
};
