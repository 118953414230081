import React from "react";
import { Col, Row, Button, DatePicker, Tabs } from "antd";
import moment from "moment";
import { AllPaymentTab } from "./AllPaymentTab";
import { useState } from "react";
const { RangePicker } = DatePicker;

export const ContainerTabs = (props) => {
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");

	const { buscarFechas, loadingBt } = props;

	const onChangeFecha = (date, dateString) => {
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		buscar();
	};

	const buscar = async () => {
		if (startDate === "") {
			var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));
			buscarFechas(fechaIn, fechaFi);
		} else {
			var fechaI = new Date(moment(startDate, "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(endDate, "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));
			buscarFechas(fechaIn, fechaFi);
		}
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};
	const buscarBoton = async (
		numOfDays,
		fechaI = new Date(),
		fechaF = new Date()
	) => {
		var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));

		fechaIn.setDate(fechaIn.getDate() - numOfDays);
		buscarFechas(fechaIn, fechaFi);
	};
	const { TabPane } = Tabs;
	return (
		<>
			<Row>
				<Col span={12}>
					<div>
						<Button
							style={{
								color: "#ADB9BB",
								background: "white",
								border: "10px",
								fontSize: "11px",
								fontWeight: "500px",
								width: "104px",
							}}
							onClick={() => {
								buscarBoton(0);
							}}
							size="small"
						>
							<span className="title">ESTE DÍA</span>
						</Button>
						<Button
							style={{
								color: "#ADB9BB",
								background: "white",
								marginLeft: "20px",
								border: "none",
								fontSize: "11px",
								fontWeight: "500px",
								width: "104px",
							}}
							onClick={() => {
								buscarBoton(1);
							}}
							size="small"
						>
							<span className="title">AYER</span>
						</Button>
						<Button
							style={{
								color: "#ADB9BB",
								background: "white",
								marginLeft: "20px",
								border: "none",
								fontSize: "11px",
								fontWeight: "500px",
								width: "104px",
							}}
							onClick={() => {
								buscarBoton(7);
							}}
							size="small"
						>
							<span className="title">ESTA SEMANA</span>
						</Button>
						<Button
							style={{
								color: "#ADB9BB",
								background: "white",
								marginLeft: "20px",
								border: "none",
								fontSize: "11px",
								fontWeight: "500px",
								width: "104px",
							}}
							onClick={() => {
								buscarBoton(31);
							}}
							size="small"
						>
							<span className="title">ESTE MES</span>
						</Button>
					</div>
				</Col>
				<Col span={6} align="right">
					<div>
						<DatePicker
							suffixIcon={null}
							onChange={onChangeFecha}
							picker="month"
						/>
					</div>
				</Col>

				<Col span={6} align="right">
					<RangePicker
						defaultValue={[
							moment(new Date(), dateFormat),
							moment(new Date(), dateFormat),
						]}
						format={customFormat}
						style={{
							width: "270px",
						}}
						onCalendarChange={onChangeFecha}
					/>
				</Col>
			</Row>
			<Tabs className="primary-tabs" defaultActiveKey="1">
				<TabPane tab="Todo" key="1">
					<AllPaymentTab></AllPaymentTab>
				</TabPane>
			</Tabs>
		</>
	);
};
