import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import "../styles.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { calendarIcon, clockIcon, text, ubicacion3 } from "../../../../assets/icons";
import { grayCircle, greenCircle, iconEditar, shape25 } from "../../../../assets/images";

export const NewsContainer = ({ data }) => {
	const [news, setNews] = useState();
	const [newsDetailModalActive, setNewsDetailModalActive] = useState(false);
	const [descriptionModalActive, setDescriptionModalActive] = useState(false);
	const [editNewsModalActive, setEditNewsModalActive] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		setNews(data);
	}, [data]);
	const { TextArea } = Input;

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const IconButtonReport = () => (
		<img
			style={{ width: "40px", height: "40px" }}
			src={text}
			alt="text-icon"
		/>
	);

	const IconButtonEye = () => (
		<img
			style={{ width: "16px", height: "16px", marginLeft: "15px" }}
			src={shape25}
			alt="eye-scanner-icon"
		/>
	);

	const IconButtonEdit = () => (
		<img
			style={{ width: "16px", height: "16px", marginLeft: "15px" }}
			src={iconEditar}
			alt="edit-icon"
		/>
	);

	const handleEditModal = (statusModal, index = null) => {
		if (index != null) {
		}
		setTimeout(() => {
			setEditNewsModalActive(statusModal);
		}, 50);
	};

	const handleDetailsModal = (statusModal, index = null) => {
		if (index != null) {
		}
		setTimeout(() => {
			setNewsDetailModalActive(statusModal);
		}, 50);
	};

	const handleDescriptionModal = (statusModal, index = null) => {
		if (index != null) {
		}
		setTimeout(() => {
			setDescriptionModalActive(statusModal);
		}, 50);
	};

	const showCategory = (category) => {
		let name = "";
		if (category === "event") {
			name = "Evento";
		}
		if (category === "news") {
			name = "Noticia";
		}
		if (category === "notification") {
			name = "Notificación";
		}
		return name;
	};

	return (
		<>
			{news ? (
				<>
					<Card
						className="boxcontent-container"
						style={{
							width: "100%",
							borderRadius: "10px",
							boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
							marginTop: "30px",
							marginBottom: "30px",
						}}
						bordered
					>
						<Row>
							<Col span={2}>
								<Button
									type="link"
									style={{
										color: "#10b48c",
										background: "white",
										paddingBottom: "15px",
									}}
									shape="round"
									icon={<IconButtonReport />}
									size="large"
									disabled
								></Button>
							</Col>
							<Col span={10}>
								<div>
									<span className="card-title">
										{moment(news.type === "event" ? news.NEWS_INVITE.date : news.created_at).format("DD/MM/YYYY")}
									</span>
								</div>

								<div>
									<p className="card-description">
										{news?.NEWS_DETAIL?.title}
									</p>
								</div>
							</Col>
							<Col span={3}>
								<div>
									<span className="card-title">CATEGORÍA</span>
								</div>
								<div>
									<span
										className="card-title"
										style={{
											color: "#10b48c",
											fontSize: "14px",
											textTransform: "uppercase",
										}}
									>
										{news.type ? showCategory(news.type) : "N/A"}
									</span>
								</div>
							</Col>
							<Col span={3}>
								<div>
									<span className="card-title">ESTATUS</span>
								</div>
								<div style={{ marginLeft: "1rem" }}>
									{news.active ? (
										<img
											src={greenCircle}
											style={{ width: "14px", height: "14px" }}
											alt="estatus"
										/>
									) : (
										<img
											src={grayCircle}
											style={{ width: "14px", height: "14px" }}
											alt="estatus"
										/>
									)}
								</div>
							</Col>
							<Col span={3}>
								<div>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
											marginTop: "8px",
										}}
										shape="round"
										icon={<IconButtonEye />}
										size="small"
										onClick={() => {
											handleDescriptionModal(true);
										}}
									>
										<span
											className="title"
											style={{ marginLeft: "10px", marginRight: "15px" }}
										>
											Ver
										</span>
									</Button>
								</div>
							</Col>
							<Col span={3}>
								<div>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
											marginTop: "8px",
										}}
										shape="round"
										size="small"
										icon={<IconButtonEdit />}
										onClick={() => {
											navigate(`/noticias/${news.id}`, {
												replace: false,
											});
										}}
									>
										<span
											className="title"
											style={{ marginLeft: "10px", marginRight: "15px" }}
										>
											Editar
										</span>
									</Button>
								</div>
							</Col>
						</Row>
					</Card>

					<Modal
						className="side-modal"
						title={"detalle de " + showCategory(news.type) + ":"}
						style={{ top: 0, right: 0 }}
						visible={descriptionModalActive}
						width={400}
						onCancel={() => handleDescriptionModal(false)}
						footer={null}
						bodyStyle={{
							overflowY: "auto",
							maxHeight: "calc(100vh - 100px)",
						}}
					>
						<Row>
							<Col span={24} align="middle">
								{news.type === "notification" || !news.NEWS_DETAIL?.image ? (
									<></>
								) : (
									<img
										style={{
											width: "350px",
											height: "262px",
										}}
										src={news.NEWS_DETAIL.image}
										alt="news"
									/>
								)}
							</Col>
							<Col span={24}>
								<div className="despliegue" style={{ paddingTop: 20 }}>
									<span
										className="subtitle"
										style={{ textTransform: "uppercase", fontWeight: "600" }}
									>
										{showCategory(news.type)}:
									</span>
									<br />
									<span className="title">{news.NEWS_DETAIL.title}</span>
									<br />
									<span className="subtitle">
										{news.type === "event" ? (
											<>{"Invita: " + news.NEWS_INVITE?.inviteFrom}</>
										) : (
											<>{"Fecha: " + moment(news.created_at).format('dddd D [de] MMMM [de] YYYY')}</>
										)}
									</span>
									<br />
									<br />
									{news.type === "event" ? (
										<>
											<Space direction="vertical" style={{ marginLeft: "5px" }}>
												<div>
													<img
														style={{ width: "18px", height: "18px" }}
														src={calendarIcon}
														alt="calendar-icon"
													/>
													<span className="subtitle">FECHA:</span>
													<br />
													<span
														className="result"
														style={{ marginLeft: "18px" }}
													>
														{moment(news.NEWS_INVITE.date).format('dddd, D [de] MMMM [de] YYYY')}
													</span>
												</div>
												<div>
													<img
														style={{ width: "18px", height: "18px" }}
														src={clockIcon}
														alt="clock-icon"
													/>
													<span className="subtitle">HORA:</span>
													<br />
													<span
														className="result"
														style={{ marginLeft: "18px" }}
													>
														{moment(news.NEWS_INVITE.time, "HH:mm:ss").format("h:mm A")}
													</span>
												</div>
												<div>
													<img
														style={{ width: "18px", height: "18px" }}
														src={ubicacion3}
														alt="location-icon"
													/>
													<span className="subtitle">LUGAR:</span>
													<br />
													<span
														className="result"
														style={{ marginLeft: "18px" }}
													>
														{news.NEWS_INVITE.place}
													</span>
												</div>
											</Space>
											<br />
											<br />
										</>
									) : (
										<></>
									)}

									<span className="description">{news.NEWS_DETAIL.description}</span>
								</div>
							</Col>
							<Col span={24} align="middle"></Col>
						</Row>
					</Modal>

					{/* <Modal
					className="side-modal"
					title="Detalle de noticia"
					style={{ top: 0, right: 0 }}
					visible={newsDetailModalActive}
					width={700}
					onCancel={() => handleDetailsModal(false)}
					footer={null}
				>
					<>
						<Row className="section-modal">
							<Col span={24}>
								<div className="alert-modal-header">
									<span className="title">Caratula de banner:</span>
								</div>
								<div>
									<Card
										style={{
											width: "100%",
											borderRadius: "10px",
											borderColor: "#10B48C",
											marginTop: "20px",
											marginBottom: "20px",
										}}
										bordered
									>
										<div className="banner">
											<span className="title">Todo acerca de Topia</span>
											<br />
											<span className="paragraph">
												DESCUBRE TODOS LOS BENEFICIOS QUE TE TRAE TOPIA DÍA A
												DÍA
											</span>
										</div>
									</Card>
								</div>
							</Col>
							<Col span={24}></Col>
							<Col span={12}>
								<div className="alert-modal-header">
									<span className="title">
										ESTATUS:
										<span>ACTIVA</span>
									</span>
								</div>
							</Col>
							<Col span={12}>
								<div className="alert-modal-header">
									<span className="title">
										FECHA PUBLICACIÓN: 10/08/22 - 9:06AM
									</span>
								</div>
							</Col>
						</Row>
						<Row className="section-modal">
							<Col span={12}>
								<div>
									<span className="title">PAÍS</span>
								</div>
								<div>
									<span>EL SALVADOR</span>
								</div>
							</Col>
							<Col span={12}>
								<div>
									<span className="title">TIPO DE CUENTA</span>
								</div>
								<div>
									<span>RESIDENCIALES</span>
								</div>
							</Col>
							<Col span={12}>
								<div style={{ marginTop: "2rem" }}>
									<span className="title">ALCANCE</span>
								</div>
								<div>
									<span>TODAS LAS CUENTAS</span>
								</div>
							</Col>
							<Col span={12}>
								<div style={{ marginTop: "2rem" }}>
									<span className="title">CATEGORIA</span>
								</div>
								<div>
									<span>INFÓRMATICA</span>
								</div>
							</Col>
						</Row>
						<Row className="section-modal">
							<Col span={14}>
								<div>
									<span className="title">VISUALIZACIÓN EN LA APP</span>
									<img
										style={{
											width: "320px",
											height: "96px",
											cursor: "pointer",
										}}
										src="/assets/icons/app_banner.svg"
									/>
								</div>
							</Col>
							<Col span={10} align="middle">
								<Button
									style={{
										color: "#10b48c",
										borderColor: "#10b48c",
										background: "white",
										marginTop: "2rem",
										width: "220px",
										height: "40px",
									}}
									shape="round"
									icon={<IconButtonEye />}
									onClick={() => {
										handleDescriptionModal(true);
									}}
								>
									<span className="title" style={{ marginLeft: "10px" }}>
										Ver despliegue
									</span>
								</Button>
							</Col>
						</Row>
					</>
				</Modal> */}

					{/* <Modal
					className="primary-modal"
					visible={editNewsModalActive}
					onCancel={() => handleEditModal(false)}
					footer={null}
					width={550}
				>
					<>
						<Row className="section-modal" style={{ textAlign: "center" }}>
							<Col span={24}>
								<h2 style={{ color: "#232C4A" }}>EDITAR NOTICIA</h2>
							</Col>
						</Row>
						<Row className="section-modal" style={{ marginTop: "1rem" }}>
							<Col
								span={24}
								style={{
									width: "90%",
									paddingLeft: "2rem",
									paddingRight: "2rem",
								}}
							>
								<Form name="news_form" onFinish={onFinishEditForm}>
									<div className="section-modal" style={{ paddingLeft: "5px" }}>
										<p>TITULO:</p>
									</div>
									<FormItem name="title" {...config}>
										<Input
											style={{
												backgroundColor: "#F2F2F2",
												borderRadius: "5px",
											}}
										/>
									</FormItem>

									<div className="section-modal" style={{ paddingLeft: "5px" }}>
										<p>DESCRIPCIÓN:</p>
									</div>
									<FormItem name="description" {...config}>
										<TextArea
											rows={4}
											style={{
												backgroundColor: "#F2F2F2",
												borderRadius: "5px",
											}}
										/>
									</FormItem>
									<Row>
										<Col span={24} align="middle">
											<Button
												htmlType="submit"
												style={{
													borderColor: "#10b48c",
													background: "#10b48c",
													paddingBottom: "15px",
													width: "250px",
												}}
												shape="round"
												size="large"
											>
												<span className="title" style={{ color: "#fff" }}>
													GUARDAR
												</span>
											</Button>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</>
				</Modal> */}
				</>
			) : null}
		</>
	);
};
