import { getTableDataHelper } from "../../../../helpers/getTableDataHelper"
import { useMonitoringContratorsContext } from "../../contexts/MonitoringContratorsContext"
import { geAllContratorAccess } from '../../services/GET/getAllContratorAccess'
import verifyResponse from "../../../../helpers/verifyResponse"


export const useMonitoringContratorsServices = () => {
    const { auth } = useMonitoringContratorsContext()

    const getAllContratorAccessServices = async ({ setLoading, last, previousData, currentPage, status, limit, skip, sort_by, sort_order, find_by, find_value, date_to, date_from }) => {
        const res = await geAllContratorAccess({ token: auth.idToken, company_id: auth.currentRol ? auth.currentRol : undefined, residential_id: auth.currentRol ? undefined : auth?.committee?.residential_id, status, limit, skip, sort_by, sort_order, find_by, find_value, date_from, date_to })

        const statusRes = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!statusRes) {
            setLoading && setLoading(false)
            throw new Error('Error in getAllContratorAccessServices')
        }
        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, skip, last, previousData, currentPage, actualLimit: limit })

        return { data, metadata }
    }

    return { getAllContratorAccessServices }
}

export default useMonitoringContratorsServices