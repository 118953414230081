import { useCallback, useEffect, useRef } from 'react'
import { FirebaseAuth } from '../firebase/config'
import { useAppDispatch, useAppSelector } from './store'
import { setSidebarTheme } from '../store/sidebar/thunks'
import { getQueryDocByCollection } from '../helpers/globalHelper'
import { login, startLogout } from '../store/auth'
import verifyResponse from '../topia/helpers/verifyResponse'
import getRolesByUserLogged from '../services/getRolesByUserLogged'

const intervalTime = 600000

export const useCheckAuth = () => {
    const auth = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch()
    const currentUser = useRef(null)
    const status = auth.status


    const getConfigurationRoles = async ({ idToken, committee, profileId, user }) => {
        const { uid, email, displayName, photoURL } = user
        dispatch(setSidebarTheme({}));
        return dispatch(login({ uid, email, displayName, photoURL, committee, profileId, idToken, status: 'authenticated' }))

        // const response = await getRolesByUserLogged(idToken)

        // if (response.success) {
        //     const roles = response.data.data
        //     dispatch(setSidebarTheme({}));
        //     const { uid, email, displayName, photoURL } = user
        //     return dispatch(login({ uid, email, displayName, photoURL, committee, roles, profileId, idToken, status: 'authenticated' }))
        // }
        // verifyResponse(response.data, response.status, '')
        // dispatch(startLogout())
        // await FirebaseAuth.signOut()
    }

    const getAuthData = useCallback(async (user, updatingToken) => {
        if (auth.status === 'authenticated' && !updatingToken) return

        const committeeData = await getQueryDocByCollection('/committee', "user_id", "==", user.uid)
        
        // Verify committee
        if (committeeData.length < 1) return dispatch(startLogout())

        const newResidentialId = localStorage.getItem('currentResidential') ? localStorage.getItem('currentResidential') : committeeData[0]?.residential_id
        //Guardar el residentialId en el localstorage para utilizarlo en el refresco del token
        localStorage.setItem('currentResidential', newResidentialId)

        // Verify residential in committee
        if (!newResidentialId) return dispatch(startLogout())

        //verificar el rol de la residencial
        const residenciaData = await getQueryDocByCollection('/residentials', "idResidential", "==", newResidentialId)

        // idToken del usuario loggeado
        const idToken = await user.getIdToken(true)
        const rolResidential = residenciaData[0]?.entity_type?.path

        const profile = await getQueryDocByCollection('/profile', "user_id", "==", user.uid)
        const profileId = profile?.length > 0 ? profile[0].id : ""

        const committee = {
            lastname: committeeData[0].lastname,
            name: committeeData[0].name,
            superadmin: committeeData[0].superadmin,
            residential_id: newResidentialId,
            user_id: committeeData[0].user_id,
            residentialData: residenciaData[0],
            rolResidential: rolResidential
        }

        // Validacion para que el idToken no expire
        return await getConfigurationRoles({ idToken: idToken, committee: committee, profileId: profileId, user: user })
    }, [auth.status])


    useEffect(() => {
        if (auth.status === 'not-authenticated') return
        //Colocar el listener de firebase una vez para iniciar sesión y luego guardar el user en un ref para usarlo en el setInterval (refrescar el token)
        const unsuscribe = FirebaseAuth.onAuthStateChanged(user => {
            if (!user) {
                return dispatch(startLogout())
            }
            currentUser.current = user
            getAuthData(user, false)
        })
        //El setInterval se debe limpiar cada vez que se termina el tiempo para evitar acumulaciones en la función
        const intervalId = setInterval(async () => {
            if (auth.status !== 'authenticated') return
            getAuthData(currentUser.current, true)
        }, intervalTime)

        return () => {
            unsuscribe()
            clearInterval(intervalId)
        }
    }, [auth, getAuthData])

    return status
}