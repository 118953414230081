
import { getResidentialsById, getUserDataProfileById, onUpdateDocWithRefInfo,onUpdateDocWithRef} from "../../helpers/globalHelper";
import { getRequestHelper } from "../../helpers/requestHelper";
import { 
    setAlertsData, 
    setRequestData,
    setIsSavingRequest,
    onUpdateRequest,
    setRequestUnApproved,
    setRequestDenied
} from '.';
import { toast } from 'react-toastify';
import Http from '../../libs/http'
import { doc,collection} from "firebase/firestore";
import { FirebaseDB } from '../../firebase/config';
import moment from 'moment';
import { getPayments } from '../../store/payments/thunks';



export const getRequest = () => {
    return async( dispatch, getState ) => {
        const { committee } = getState().auth
        const requestRaw = await getRequestHelper(committee.residential_id);

        let requestData = requestRaw.map( async(item, index) => {

            let residentials = await getResidentialsById(item.residential_id);
            let userWho = await getUserDataProfileById(item.request_who_id);

            return {
                ...requestRaw[index],
                user_who: userWho,
                residential_data: residentials
            }

        } );

        
        Promise.all(requestData).then( data => {
            dispatch(setRequestData(data))

            let unApprovedRequests = data.filter( request => (request.status == "pending")  );
            dispatch( setRequestUnApproved(unApprovedRequests) );

            let deniedRequests = data.filter( request => request.status == "reject" );
            dispatch( setRequestDenied(deniedRequests) );
            


        })

    }
}

export const updateRequestAprobado = () => {
    return async( dispatch, getState) => {

        const { requests } = getState().requests;
        //requests.editing
        var requestsEdit = {... requests.editing}


        if(!requests.editing) { 
            toast.error("Intentelo nuevamente") 
        }else{
            dispatch(setIsSavingRequest());    

            if(requests.editing.amount === 0){
                //verificamos si creamos el evento
                if(requests.editing.event === true){
                    //let idEvento = self.db.collection("events").document().documentID
                    //const newCityRef = doc( FirebaseDB, "events" );
                    const newCityRef = doc(collection(FirebaseDB, "events"));

                    //const newCityRef = db.collection("events").doc();
                    var myId = newCityRef.id;

                    var dataEvent = {}
                    dataEvent["active"] = true
                    dataEvent["created_at"] = moment(new Date(), 'DD/MM/YYYY').toDate()
                    dataEvent["updated_at"] = moment(new Date(), 'DD/MM/YYYY').toDate()
                    dataEvent["category"] = ""
                    dataEvent["date"] =  new Date(requests.editing.requestDate)
                    dataEvent["name"] = "Evento de "+requests.editing.area_name
                    dataEvent["time_begin"] = requests.editing.time_begin
                    dataEvent["time_end"] = requests.editing.time_end
                    dataEvent["alert"] = false
                    dataEvent["drinks"] = false
                    dataEvent["food"] = false
                    dataEvent["pets"] = false
                    dataEvent["payment_event"] = false
                    dataEvent["type"] = 3
                    dataEvent["electricEquipment"] = false
                    dataEvent["user_id"] = requests.editing.user_who.user_id
                    dataEvent["id_event"] = myId
                    dataEvent["residential_id"] = requests.editing.residential_id
                    dataEvent["access_time"] = 10

                    var findResidential = requests.editing.user_who.residentials.find( request => (request.idResidential === requests.editing.residential_id));
                    if(typeof findResidential !== 'undefined'){
                        
                        if(findResidential.homePrincipal){
                            dataEvent["status"] = 1
                        }else{
                            dataEvent["status"] = 0
                            dataEvent["id_profile_titular"] =  requests.editing.user_who.id
                        }
                    }

                    onUpdateDocWithRef(`/events/${myId}`, dataEvent);
                    
                    onUpdateDocWithRefInfo(`/booking/${requests.editing.id}`, {
                        status: "approved"
                    });

                    var dataUserSend = {}
                    dataUserSend.NotiID = "2001"
                    dataUserSend.ProfileID = requests.editing.user_who.id
                    dataUserSend.ResidentialID = requests.editing.residential_id
                    dataUserSend.EventoID = ""
                    dataUserSend.ReservaID = requests.editing.id
                    dataUserSend.PayID = ""
                    dataUserSend.AccesoID = ""

                    await Http.instance.post("send-notification",JSON.stringify(dataUserSend));

                    requestsEdit.status = "Aprobado"
                    
                }else{
                    onUpdateDocWithRefInfo(`/booking/${requests.editing.id}`, {
                        status: "approved"
                    });

                    var dataUserSend = {}
                    dataUserSend.NotiID = "2001"
                    dataUserSend.ProfileID = requests.editing.user_who.id
                    dataUserSend.ResidentialID = requests.editing.residential_id
                    dataUserSend.EventoID = ""
                    dataUserSend.ReservaID = requests.editing.id
                    dataUserSend.PayID = ""
                    dataUserSend.AccesoID = ""

                    await Http.instance.post("send-notification",JSON.stringify(dataUserSend));

                    requestsEdit.status = "Aprobado"
                }
            }else{
              //  /payment_types/ulRIg5R9NKzerBLQNS97
              var user_name = requests.editing.user_who.name
              var lastname = typeof requests.editing.user_who.lastname == "undefined" ? "" : requests.editing.user_who.lastname
              user_name = user_name + lastname

              var payment_type = await doc( FirebaseDB, '/payment_types/ulRIg5R9NKzerBLQNS97')

              var paymentsData = {}
              paymentsData["amount"] = requests.editing.amount
              paymentsData["concept"] = "Pago evento "+requests.editing.area_name
              paymentsData["created_at"] = moment(new Date(), 'DD/MM/YYYY').toDate()
              paymentsData["expiration_date"] = new Date(requests.editing.requestDate)
              paymentsData["payment_type"] = payment_type
              paymentsData["profile_id"] = requests.editing.user_who.id
              paymentsData["residential_id"] = requests.editing.residential_id
              paymentsData["status"] = "Pendiente"
              paymentsData["type"] = "Evento"
              paymentsData["user_name"] = user_name
              paymentsData["automatic_payment"] = false
              paymentsData["id_booking"] = requests.editing.id
              paymentsData["active"] = true

              const newCityRef = doc(collection(FirebaseDB, "payments"));
              var myId = newCityRef.id;

                onUpdateDocWithRef(`/payments/${myId}`, paymentsData);

                onUpdateDocWithRefInfo(`/booking/${requests.editing.id}`, {
                    payment: "pending",
                    amount: requests.editing.amount
                });

                var dataUserSend = {}
                dataUserSend.NotiID = "2001"
                dataUserSend.ProfileID = requests.editing.user_who.id
                dataUserSend.ResidentialID = requests.editing.residential_id
                dataUserSend.EventoID = ""
                dataUserSend.ReservaID = requests.editing.id
                dataUserSend.PayID = ""
                dataUserSend.AccesoID = ""

                await Http.instance.post("send-notification",JSON.stringify(dataUserSend));

                requestsEdit.status = "Pendiente Pago"

            }

            dispatch(onUpdateRequest(requestsEdit));
            dispatch( getPayments() );
        }
    }
}

export const updateRequest = () => {
    return async( dispatch, getState) => {

        const { requests } = getState().requests;

        var requestsEdit = {...requests.editing}
        //requests.editing
        if(!requests.editing) { 
            toast.error("Intentelo nuevamente") 
        }else{
            dispatch(setIsSavingRequest());           

            if(requests.editing.status === "reject"){

                onUpdateDocWithRefInfo(`/booking/${requests.editing.id}`, {
                    status: requests.editing.status,
                    comment: requests.editing.comment || ""
                });
                
                var dataUserSend = {}
                dataUserSend.NotiID = "2002"
                dataUserSend.ProfileID = requests.editing.user_who.id
                dataUserSend.ResidentialID = requests.editing.residential_id
                dataUserSend.EventoID = ""
                dataUserSend.ReservaID = requests.editing.id
                dataUserSend.PayID = ""
                dataUserSend.AccesoID = ""
                
    
                await Http.instance.post("send-notification",JSON.stringify(dataUserSend));

                requestsEdit.status = "Rechazado"
            }
            dispatch(onUpdateRequest(requestsEdit));
        }
    }
}